/* eslint-disable linebreak-style */
import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';

const LaborDuration = ({
  children,
  toggleLaborWorkWorkField,
  isLaborWorkstationEnabled,
  typesEnabled = true,
}) => (

  <Card bg="dark" className="mb15">
    <Card.Header className="pd-exp inverse">
      <Row className="d-flex justify-content-between align-items-center">
        <Col xs={7}>
          <FormattedMessage
            id="laborNeedsForScheduling"
            defaultMessage="Labor Needs for Scheduling"
          />:
        </Col>
        <Col>
          <div className="d-flex align-items-center pull-right">
            <FormattedMessage
              id="enableLaborEnhancedWorkflow"
              defaultMessage="Enable Labor Enhanced Workflow"
            />
            <Fa
              className="ml15 pointer"
              name={isLaborWorkstationEnabled ? 'minus-circle removeLaborData' : 'plus-circle'}
              size="lg"
              onClick={toggleLaborWorkWorkField}
            />
          </div>
        </Col>
      </Row>
    </Card.Header>
    {isLaborWorkstationEnabled && (
      <div className="card-body-wrapper">
        <Card.Body>
          {typesEnabled && (
            <Card.Title>Specify type of manual labor needed for post processor type</Card.Title>
          )}
          {children}
        </Card.Body>
      </div>
    )}
  </Card>
);

LaborDuration.defaultProps = {
  typesEnabled: true,
};

LaborDuration.propTypes = {
  children: PropTypes.node.isRequired,
  typesEnabled: PropTypes.bool,
  toggleLaborWorkWorkField: PropTypes.func.isRequired,
  isLaborWorkstationEnabled: PropTypes.bool.isRequired,
};

export default LaborDuration;
