import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Stepper from 'rapidfab/components/stepper/Stepper';
import OrderSummary from 'rapidfab/components/records/order/restricted/edit/OrderSummary';
import { RESTRICTED_USER_ORDER_STEPS, RESTRICTED_USER_ORDER_STEP_ORDER_DETAILS } from 'rapidfab/constants';
import prepareStepperURI from 'rapidfab/utils/prepareStepperURI';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import { getRouteUUIDResource } from 'rapidfab/selectors';

const EditRestrictedOrder = ({ order = {} }) => {
  const id = order && order.id ? `${order.name} (${order.customer_id || getShortUUID(order.uuid)})` : '';
  const breadcrumbs = ['orders', id];
  const orderUuid = extractUuid(order.uri);

  return (
    <Container fluid>
      <BreadcrumbNav breadcrumbs={breadcrumbs} />
      <Stepper
        steps={prepareStepperURI(RESTRICTED_USER_ORDER_STEPS, { uuid: orderUuid })}
        activeStep={RESTRICTED_USER_ORDER_STEP_ORDER_DETAILS}
      />
      <OrderSummary orderUri={order.uri} />
    </Container>
  );
};

EditRestrictedOrder.propTypes = {
  order: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => (
  {
    order: getRouteUUIDResource(state),
  }
);

export default connect(mapStateToProps)(EditRestrictedOrder);
