import React from 'react';
import Libraries from 'rapidfab/components/manage/Libraries';

const LibrariesContainer = props => (
  <Libraries
    {...props}
  />
);

export default LibrariesContainer;
