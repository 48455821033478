import { createSelector } from 'reselect';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';

export const getShoppingCarts = createSelector(
  [baseStateSelectors.getStateShoppingCarts, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getShoppingCartsByUri = createSelector([getShoppingCarts], shoppingCarts =>
  _keyBy(shoppingCarts, 'uri'),
);

export const getShoppingCartItems = createSelector(
  [baseStateSelectors.getStateShoppingCartItems, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);
