import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import _filter from 'lodash/filter';
import _forEach from 'lodash/forEach';
import _indexOf from 'lodash/indexOf';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _flattenDeep from 'lodash/flattenDeep';
import _sum from 'lodash/sum';
import _find from 'lodash/find';
import _range from 'lodash/range';
import {
  Col,
  Row,
  Card,
  Container,
  FormGroup,
  FormControl,
  Button,
} from 'react-bootstrap';
import Chart, {
  SeriesStyle,
  randomColorByString,
} from 'rapidfab/components/chart';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import {
  BUILD_STATUS,
  BUILD_TIME_FIELD,
  ANALYTICS_REPORT_TEMPLATE,
  ANALYTICS_REPORT_BUTTON_LABEL,
} from 'rapidfab/constants';
import GaugeChart from 'rapidfab/components/guageChart';
import TextIndicator, { textColor } from 'rapidfab/components/TextIndicator';
import dayjs from 'dayjs';

class AnalyticsComponent extends Component {
  static addZeroToDigit(value) {
    return value < 10 ? `0${value}` : value;
  }

  static secondsToHours(seconds) {
    /**
     * Converts seconds to hh:mm:ss format
     *
     * 7300 seconds will convert to
     * 2 hours (it's 7200 seconds):01 minute (it's 60 seconds):40 seconds
     *
     * @param  {int} seconds
     */
    const hh = Math.floor(dayjs.duration(seconds, 'seconds').asHours());
    const mm = AnalyticsComponent.addZeroToDigit(
      dayjs.duration(seconds, 'seconds').minutes(),
    );
    const ss = AnalyticsComponent.addZeroToDigit(
      dayjs.duration(seconds, 'seconds').seconds(),
    );

    return `${hh}:${mm}:${ss}`;
  }

  static replaceBrokenFieldsDate(items) {
    /**
     * Builds with success status can be with wrong start_time AND/OR end_time field
     * Discussed with Far about it and decided, that we can use
     * build.create field instead of build.start_time (if start_time is null)
     * and
     * build.updated instead of end_time (if end_time is null)
     * Then, we will replace with these values and use everywhere START_TIME and END_TIME fields
     *
     * @param  {array} Array of all builds
     */
    let builds = _map(items, item => {
      /* eslint-disable no-param-reassign */
      if (item[BUILD_TIME_FIELD.START_TIME] === null) {
        item[BUILD_TIME_FIELD.START_TIME] = item[BUILD_TIME_FIELD.CREATED];
      }

      if (item[BUILD_TIME_FIELD.END_TIME] === null) {
        item[BUILD_TIME_FIELD.END_TIME] = item[BUILD_TIME_FIELD.UPDATED];
      }
      /* eslint-enable no-param-reassign */

      return item;
    });

    // Remove broken items with start_time > end_time
    builds = _filter(
      builds,
      build =>
        +dayjs(build[BUILD_TIME_FIELD.START_TIME]) <=
        +dayjs(build[BUILD_TIME_FIELD.END_TIME]),
    );

    return builds;
  }

  static sumTimeRange(items, startDateFieldName, endDateFieldName) {
    /**
     * Method which will return sum in seconds of all builds
     *
     * @param  {array} List of all items
     * @param  {string} Field name with datetime with event start
     * @param  {string} Field name with datetime with event end
     */
    return _reduce(
      items,
      (result, item) => {
        const start = item[startDateFieldName];
        const end = item[endDateFieldName];
        const total = AnalyticsComponent.getDateRange(start, end);

        return result + total;
      },
      0,
    );
  }

  static splitItemsByDays(items, startDateFieldName, endDateFieldName) {
    /**
     * Some items can be occurs for multiple days, eg:
     * starts at 2018.01.01 at 01:01:01 and ends by 2018.01.03 at 02:02:02
     * We need to create two items, the first one should be with:
     * start_date = 2018.01.01 at 01:01:01, end_date=2018.01.01 at 23:59:59
     *
     * The second one with
     * start_date = 2018.01.02 at 00:00:00, end_date=2018.01.02 at 23:59:59
     *
     * The third one with
     * start_date = 2018.01.03 at 00:00:00, end_date=2018.01.03 at 02:02:02
     *
     * @param  {array} List of all items
     * @param  {string} Field name with datetime with event start
     * @param  {string} Field name with datetime with event end
     */
    const result = [];

    _forEach(items, item => {
      const startDate = dayjs(item[startDateFieldName]);
      const endDate = dayjs(item[endDateFieldName]);
      const daysDiff = endDate.diff(startDate, 'days');

      for (let index = 0; index <= daysDiff; index++) {
        const letItemDates = { ...item };
        letItemDates[startDateFieldName] = startDate
          .clone()
          .add(index, 'd')
          .startOf('day');
        letItemDates[endDateFieldName] = startDate
          .clone()
          .add(index, 'd')
          .endOf('day');

        if (
          letItemDates[startDateFieldName].format('YYYY-MM-DD') ===
          startDate.format('YYYY-MM-DD')
        ) {
          letItemDates[startDateFieldName] = startDate;
        }

        if (
          letItemDates[endDateFieldName].format('YYYY-MM-DD') ===
          endDate.format('YYYY-MM-DD')
        ) {
          letItemDates[endDateFieldName] = endDate;
        }

        letItemDates[startDateFieldName] =
          letItemDates[startDateFieldName].format();
        letItemDates[endDateFieldName] =
          letItemDates[endDateFieldName].format();

        result.push(letItemDates);
      }
    });

    return result;
  }

  static filterByStatus(items, statuses) {
    /**
     * Filter all objects by status
     *
     * @param  {array} List with all items
     * @param  {array} List with all statuses which must be in result
     */
    return items.filter(item => _indexOf(statuses, item.status) !== -1);
  }

  constructor(props) {
    super(props);

    this.state = {
      builds: AnalyticsComponent.replaceBrokenFieldsDate(props.builds),
    };

    this.handleDownloadButtonClick = this.handleDownloadButtonClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { builds } = this.props;
    if (prevProps.builds.length !== builds.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        builds: AnalyticsComponent.replaceBrokenFieldsDate(builds),
      });
    }
  }

  static getDateRange(startDate, endDate) {
    /**
     * Returns difference between dates in seconds
     *
     * @param  {string} Start date in format which can be parsed by moment.js
     * @param  {string} End date
     */
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    return end.diff(start, 'seconds');
  }

  handleDownloadButtonClick(templateName) {
    const { fromDate, toDate } = this.props;
    this.props.handleDownload(templateName, fromDate, toDate);
  }

  getFromTimestamp() {
    /**
     * Method which returns timestamp of selected date in "From" field
     */
    return new Date(this.props.fromDate).getTime();
  }

  getToTimestamp() {
    /**
     * Method which returns timestamp of selected date in "To" field
     */
    return new Date(this.props.toDate).getTime();
  }

  getPreviousPeriodFromTimestamp() {
    /**
     * Method which returns FROM timestamp from previous the same period
     * of time
     *
     * Calculating:
     * time_difference_in_seconds = (toTimestamp - fromTimestamp);
     * and returns (fromTimestamp - time_difference_in_seconds)
     *
     * Example:
     * Start date: 01.01.2018 00:00:00
     * End date 03.01.2018 00:00:00
     *
     * Previous period date:
     * (03.01.2018-01.01.2018) = 2 days, in seconds (2*24*60*60)
     * 01.01.2018 - (2*24*60*60) seconds = 30.12.2017 00:00:00
     */
    const fromTimestamp = this.getFromTimestamp();
    const toTimestamp = this.getToTimestamp();
    const timestampRange = AnalyticsComponent.getDateRange(
      fromTimestamp,
      toTimestamp,
    );

    return +dayjs(fromTimestamp).subtract(timestampRange, 'seconds');
  }

  getPreviousPeriodToTimestamp() {
    /**
     * Method which returns previous TO timestamp
     *
     * Calculating:
     * just return (fromTimestamp - 1 second)
     * and returns (fromTimestamp - time_difference_in_seconds)
     *
     * Example:
     * Start date: 01.01.2018 00:00:00
     * End date 03.01.2018 00:00:00
     *
     * Previous TO date:
     * (01.01.2018 - 1 second) = 31.12.2017 23:59:59
     */
    const fromTimestamp = this.getFromTimestamp();
    return +dayjs(fromTimestamp).subtract(1, 'seconds');
  }

  getBuildErrorsByPeriod(isPreviousPeriod) {
    /**
     * Method which will return builds with error status
     * filtered by user selected date range
     *
     * @param  {bool} Is needed to filter by previous period
     */

    let builds = this.filterByDateRange(
      this.state.builds,
      BUILD_TIME_FIELD.CREATED,
      undefined,
      isPreviousPeriod,
    );
    const errorBuildStatus = [
      BUILD_STATUS.FAILED,
      BUILD_STATUS.MACHINE_ERROR,
      BUILD_STATUS.FAILED,
    ];
    builds = AnalyticsComponent.filterByStatus(builds, errorBuildStatus);
    return builds;
  }

  cropItemsTimeByRange(items, startDateFieldName, endDateFieldName) {
    /**
     * Will crop list of items with putDatetimeToFrame() method
     * Please look into doc about `putDatetimeToFrame` method
     * for further into
     *
     * @param  {array} List of all items
     * @param  {string} Field name with datetime with event start
     * @param  {string} Field name with datetime with event end
     */
    return _map(items, item =>
      this.putDatetimeToFrame(item, startDateFieldName, endDateFieldName),
    );
  }

  putDatetimeToFrame(item, startDateFieldName, endDateFieldName) {
    /**
     * Move start and end time to timeframe
     *
     * If user filters between date 01.30.2018 and 02.01.2018,
     * and item has start_time = 01.29.2018 23:50:00 and end_time 02.02.2018,
     * it's needed to replace start_time to 01.30.2018 00:00:00
     * and replace end_time to 02.02.2018:23:59:59
     *
     * @param  {object} Item object
     * @param  {string} Name of start datetime field
     * @param  {string} Name of end datetime field
     */
    const fromTimestamp = this.getFromTimestamp();
    const toTimestamp = this.getToTimestamp();

    const startDate = dayjs(item[startDateFieldName]);
    const endDate = dayjs(item[endDateFieldName]);

    /* eslint-disable no-param-reassign */
    if (+startDate < fromTimestamp) {
      item[startDateFieldName] = dayjs(fromTimestamp).startOf('day').format();
    }

    if (+endDate > toTimestamp) {
      item[endDateFieldName] = dayjs(toTimestamp).endOf('day').format();
    }
    /* eslint-enable no-param-reassign */

    return item;
  }

  filterByDateRange(
    items,
    startDateFieldName,
    endDateFieldName,
    isPreviousPeriod,
  ) {
    /**
     * Filter items and returns them which applixed to the range [fromTimestamp, toTimestamp]
     * Algorithm for filtering:
     * [toTimestamp > item.startTime > fromTimestamp]
     * OR
     * [toTimestamp > item.endTime > fromTimestamp]
     * OR
     * [item.startDateFieldName < fromTimestamp] AND [item.endDateFieldName > endTimestamp]
     *
     * @param  {array} List of all items
     * @param  {string} Field name with datetime with event start
     * @param  {string} (optional, can be undefined/null) Field name with datetime with event end
     * @param  {boolean} (optional) filter by the same previous period
     */
    let fromTimestamp = this.getFromTimestamp();
    let toTimestamp = this.getToTimestamp();

    if (isPreviousPeriod === true) {
      fromTimestamp = this.getPreviousPeriodFromTimestamp();
      toTimestamp = this.getPreviousPeriodToTimestamp();
    }

    return _filter(
      items,
      item =>
        (+dayjs(item[startDateFieldName]) >= fromTimestamp &&
          +dayjs(item[startDateFieldName]) <= toTimestamp) ||
        (typeof endDateFieldName !== 'undefined' &&
          endDateFieldName !== null &&
          ((+dayjs(item[endDateFieldName]) >= fromTimestamp &&
            +dayjs(item[endDateFieldName]) <= toTimestamp) ||
            (+dayjs(item[startDateFieldName]) <= fromTimestamp &&
              +dayjs(item[endDateFieldName]) >= toTimestamp))),
    );
  }

  calculateBuildTime(isPreviousPeriod) {
    /**
     * Method which will return array with build time for all succeed builds,
     * filtered by user selected date range
     *
     * @param  {bool} Is needed to filter by previous period
     */

    const builds = this.filterByDateRange(
      this.state.builds,
      BUILD_TIME_FIELD.START_TIME,
      BUILD_TIME_FIELD.END_TIME,
      isPreviousPeriod,
    );
    const completeBuildStatus = [BUILD_STATUS.COMPLETE];
    const successfullyBuilds = AnalyticsComponent.filterByStatus(
      builds,
      completeBuildStatus,
    );

    const buildTime = [];

    _forEach(successfullyBuilds, build => {
      const startDate = dayjs(build[BUILD_TIME_FIELD.START_TIME]);
      const endDate = dayjs(build[BUILD_TIME_FIELD.END_TIME]);
      const secondsBuildTime = endDate.diff(startDate, 'seconds');
      buildTime.push(secondsBuildTime);
    });

    return buildTime;
  }

  renderGauge() {
    const totalOperationTime = AnalyticsComponent.getDateRange(
      this.getFromTimestamp(),
      this.getToTimestamp(),
    );

    let { downtime } = this.props;
    downtime = this.filterByDateRange(downtime, 'start', 'finish');
    downtime = AnalyticsComponent.splitItemsByDays(downtime, 'start', 'finish');
    downtime = this.cropItemsTimeByRange(downtime, 'start', 'finish');

    const { modelers } = this.props;
    const downtimeSumTime = AnalyticsComponent.sumTimeRange(
      downtime,
      'start',
      'finish',
    );

    const operationTime =
      totalOperationTime * modelers.length - downtimeSumTime;

    const dateFilteredBuilds = this.filterByDateRange(
      this.state.builds,
      BUILD_TIME_FIELD.START_TIME,
      BUILD_TIME_FIELD.END_TIME,
    );
    const builds = this.cropItemsTimeByRange(
      dateFilteredBuilds,
      BUILD_TIME_FIELD.START_TIME,
      BUILD_TIME_FIELD.END_TIME,
    );

    const completeBuildStatus = [BUILD_STATUS.COMPLETE];
    const successfullyBuilds = AnalyticsComponent.filterByStatus(
      builds,
      completeBuildStatus,
    );
    const totalSuccessBuildTime = AnalyticsComponent.sumTimeRange(
      successfullyBuilds,
      BUILD_TIME_FIELD.START_TIME,
      BUILD_TIME_FIELD.END_TIME,
    );

    // OEE = 100% operation time vs actual operation hours
    // 100% operation time = 24 h - scheduled downtime
    let oee = Math.round((totalSuccessBuildTime / operationTime) * 100);
    // limit oee with >= 0 and <= 100
    oee = Math.max(oee, 0);
    oee = Math.min(oee, 100);

    const gaugeOptions = {
      staticLabels: {
        labels: [0, 100], // Print labels at these values
      },
    };
    return (
      <GaugeChart
        id="oee.gauge"
        gaugeTitle="OEE"
        minValue={0}
        maxValue={100}
        value={oee}
        options={gaugeOptions}
      />
    );
  }

  renderErrorsCounter() {
    const currentPeriodBuildErrors = this.getBuildErrorsByPeriod(false).length;
    const previousPeriodBuildErrors = this.getBuildErrorsByPeriod(true).length;

    const differenceWithPreviousPeriod =
      currentPeriodBuildErrors - previousPeriodBuildErrors;

    const title = `${currentPeriodBuildErrors} (count)`;
    const indicatorColor =
      differenceWithPreviousPeriod < 0
        ? textColor.Success.color
        : textColor.Danger.color;
    const subtext = `${
      differenceWithPreviousPeriod >= 0 ? '+' : ''
    }${differenceWithPreviousPeriod} vs prev`;

    return (
      <TextIndicator
        title="Errors"
        valueText={title}
        valueSubtext={subtext}
        color={indicatorColor}
      />
    );
  }

  renderAverageBuildTime() {
    let currentPeriodBuildTime = this.calculateBuildTime(false);
    let previousPeriodBuildTime = this.calculateBuildTime(true);

    if (currentPeriodBuildTime.length === 0) {
      currentPeriodBuildTime = 0;
    } else {
      currentPeriodBuildTime =
        _sum(currentPeriodBuildTime) / currentPeriodBuildTime.length;
    }

    if (previousPeriodBuildTime.length === 0) {
      previousPeriodBuildTime = 0;
    } else {
      previousPeriodBuildTime =
        _sum(previousPeriodBuildTime) / previousPeriodBuildTime.length;
    }

    const currentValue = AnalyticsComponent.secondsToHours(
      currentPeriodBuildTime,
    );
    const previousValue = `Prev: ${AnalyticsComponent.secondsToHours(
      previousPeriodBuildTime,
    )}`;

    return (
      <TextIndicator
        title="Av Build Time"
        valueText={currentValue}
        valueSubtext={previousValue}
      />
    );
  }

  renderTotalBuildCounter() {
    const currentPeriodBuildCounter = this.calculateBuildTime(false).length;
    const previousPeriodBuildCounter = this.calculateBuildTime(true).length;

    const differenceWithPreviousCounter =
      currentPeriodBuildCounter - previousPeriodBuildCounter;

    const title = `${currentPeriodBuildCounter} (count)`;
    const indicatorColor =
      differenceWithPreviousCounter > 0
        ? textColor.Success.color
        : textColor.Danger.color;
    const subtext = `${
      differenceWithPreviousCounter >= 0 ? '+' : ''
    }${differenceWithPreviousCounter} vs prev`;

    return (
      <TextIndicator
        title="Builds"
        valueText={title}
        valueSubtext={subtext}
        color={indicatorColor}
      />
    );
  }

  renderDownloadReportButton(reportType) {
    return (
      <Button
        type="button"
        value={reportType}
        onClick={() => this.handleDownloadButtonClick(reportType)}
        variant="success"
        className="ml5"
        size="xs"
      >
        <FormattedMessage
          id={`analytics.${reportType}`}
          defaultMessage={ANALYTICS_REPORT_BUTTON_LABEL[reportType]}
        />
      </Button>
    );
  }

  renderOperatingTime() {
    const { printers, modelers, fromDate, toDate } = this.props;

    const fromTimestamp = dayjs(this.getFromTimestamp());
    const toTimestamp = dayjs(this.getToTimestamp());

    const daysDiff = toTimestamp.diff(fromTimestamp, 'days');

    const completeBuildStatus = [BUILD_STATUS.COMPLETE];
    let builds = this.filterByDateRange(
      this.state.builds,
      BUILD_TIME_FIELD.START_TIME,
      BUILD_TIME_FIELD.END_TIME,
    );
    builds = AnalyticsComponent.filterByStatus(builds, completeBuildStatus);
    builds = AnalyticsComponent.splitItemsByDays(
      builds,
      BUILD_TIME_FIELD.START_TIME,
      BUILD_TIME_FIELD.END_TIME,
    );

    const timeFormat = 'MM/DD/YYYY';

    const chartDays = _reduce(
      _range(daysDiff),
      (result, index) => {
        const day = fromTimestamp
          .clone()
          .add(index, 'd')
          .startOf('day')
          .format(timeFormat);
        result.push(day);
        return result;
      },
      [],
    );

    const chartModelers = [];
    _forEach(printers, printer => {
      const modeler = _find(modelers, { uri: printer.modeler });

      if (modeler === undefined) {
        return;
      }

      const modelerBuilds = _filter(builds, ['modeler', modeler.uri]);
      const succeedBuildsByDays = [];

      for (let index = 0; index <= daysDiff; index++) {
        const currentDay = fromTimestamp.clone().add(index, 'd').startOf('day');

        const dailyBuilds = _filter(
          modelerBuilds,
          o =>
            // We are checking only with start time because there is no sense
            // to check also end_time
            // Because we already split items with `splitItemsByDays()` method
            dayjs(o[BUILD_TIME_FIELD.START_TIME]).format(timeFormat) ===
            currentDay.format(timeFormat),
        );

        let buildsTime = AnalyticsComponent.sumTimeRange(
          dailyBuilds,
          BUILD_TIME_FIELD.START_TIME,
          BUILD_TIME_FIELD.END_TIME,
        );

        buildsTime = Math.round(buildsTime / 60 / 60); // Convert seconds to minutes and hours
        succeedBuildsByDays.push(buildsTime);
      }

      const chartModelerColor = randomColorByString(printer.name);

      const chartModelerData = {
        label: printer.name,
        data: succeedBuildsByDays,
        backgroundColor: chartModelerColor,
        borderColor: chartModelerColor,
        hoverBackgroundColor: chartModelerColor,
      };

      chartModelers.push(chartModelerData);
    });

    // It's unique value needed for force chart update
    const sumHours = _sum(_flattenDeep(_map(chartModelers, 'data')));

    const chartOptions = {
      scales: {
        x: {
          type: 'time',
          display: true,
          time: {
            parser: timeFormat,
            displayFormats: {
              hour: 'MMM D',
            },
          },
        },

        y: {
          ticks: {
            callback(value) {
              return `${value} hrs`;
            },
          },
        },
      },
    };

    return (
      <Chart
        type="line"
        data={{
          labels: chartDays,
          datasets: chartModelers,
        }}
        height="200"
        options={chartOptions}
        key={`${fromDate}-${toDate}-${sumHours}`}
        /* this is unique value to force refresh Chart */
      />
    );
  }

  renderState() {
    const options = {
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback(value) {
              return `${value}%`;
            },
          },
        },

        y: {
          barPercentage: 1,
          categoryPercentage: 0.05,
          stacked: true,
        },
      },
    };

    const { modelers, printers } = this.props;

    const builds = this.filterByDateRange(
      this.state.builds,
      BUILD_TIME_FIELD.START_TIME,
      BUILD_TIME_FIELD.END_TIME,
    );

    const completeBuildStatus = [BUILD_STATUS.COMPLETE];
    const successBuilds = AnalyticsComponent.filterByStatus(
      builds,
      completeBuildStatus,
    );

    const errorBuildStatus = [
      BUILD_STATUS.FAILED,
      BUILD_STATUS.MACHINE_ERROR,
      BUILD_STATUS.FAILED,
    ];
    let errorBuilds = this.filterByDateRange(builds, BUILD_TIME_FIELD.CREATED);
    errorBuilds = AnalyticsComponent.filterByStatus(
      errorBuilds,
      errorBuildStatus,
    );

    const chartModelers = [];

    const totalOperationTime = AnalyticsComponent.getDateRange(
      this.getFromTimestamp(),
      this.getToTimestamp(),
    );
    let { downtime } = this.props;
    downtime = this.filterByDateRange(downtime, 'start', 'finish');
    downtime = AnalyticsComponent.splitItemsByDays(downtime, 'start', 'finish');
    downtime = this.cropItemsTimeByRange(downtime, 'start', 'finish');

    _forEach(printers, printer => {
      const modeler = _find(modelers, { uri: printer.modeler });

      let printerDowntime = _filter(downtime, ['printer', printer.uri]);
      printerDowntime = AnalyticsComponent.sumTimeRange(
        printerDowntime,
        'start',
        'finish',
      );
      const operationTime = totalOperationTime - printerDowntime;

      if (modeler === undefined) {
        return;
      }

      const succeedBuildsByModeler = _filter(successBuilds, [
        'modeler',
        modeler.uri,
      ]);
      const succeedBuildTime = AnalyticsComponent.sumTimeRange(
        succeedBuildsByModeler,
        BUILD_TIME_FIELD.START_TIME,
        BUILD_TIME_FIELD.END_TIME,
      );

      const errorBuildsByModeler = _filter(errorBuilds, [
        'modeler',
        modeler.uri,
      ]);
      const errorBuildTime = AnalyticsComponent.sumTimeRange(
        errorBuildsByModeler,
        BUILD_TIME_FIELD.START_TIME,
        BUILD_TIME_FIELD.END_TIME,
      );

      const totalModelerTime =
        totalOperationTime + succeedBuildTime + errorBuildTime;
      // totalModelerTime == 100% of all chart
      const modelerSuccessPercentage = Math.round(
        (succeedBuildTime / totalModelerTime) * 100,
      );
      const modelerErrorPercentage = Math.round(
        (errorBuildTime / totalModelerTime) * 100,
      );
      const modelerIdlePercentage = Math.round(
        (operationTime / totalModelerTime) * 100,
      );

      const chartModelerData = {
        uri: printer.uri,
        name: printer.name,
        uuid: printer.uuid,

        successPercentage: modelerSuccessPercentage,
        errorPercentage: modelerErrorPercentage,
        idlePercentage: modelerIdlePercentage,
      };

      chartModelers.push(chartModelerData);
    });

    // Bar Chart

    const datasets = [
      {
        label: 'Error',
        data: _map(chartModelers, 'errorPercentage'),
        backgroundColor: SeriesStyle.Danger.color,
        hoverBackgroundColor: SeriesStyle.Danger.hover,
      },
      {
        label: 'Idle',
        data: _map(chartModelers, 'idlePercentage'),
        backgroundColor: SeriesStyle.Warning.color,
        hoverBackgroundColor: SeriesStyle.Warning.hover,
      },
      {
        label: 'Operating',
        data: _map(chartModelers, 'successPercentage'),
        backgroundColor: SeriesStyle.Success.color,
        hoverBackgroundColor: SeriesStyle.Success.hover,
      },
    ];

    return (
      <Chart
        type="bar"
        options={options}
        data={{
          labels: _map(chartModelers, 'name'),
          datasets,
        }}
      />
    );
  }

  renderPrintsStatePieChart() {
    const options = {
      scales: {
        y: {
          ticks: {
            display: false,
          },
        },
      },
    };

    const builds = this.filterByDateRange(
      this.state.builds,
      BUILD_TIME_FIELD.START_TIME,
      BUILD_TIME_FIELD.END_TIME,
    );

    const errorBuilds = this.filterByDateRange(
      builds,
      BUILD_TIME_FIELD.CREATED,
    );

    const completeBuildStatus = [BUILD_STATUS.COMPLETE];
    const errorBuildStatus = [
      BUILD_STATUS.FAILED,
      BUILD_STATUS.MACHINE_ERROR,
      BUILD_STATUS.FAILED,
    ];

    const successBuildsCount = AnalyticsComponent.filterByStatus(
      builds,
      completeBuildStatus,
    ).length;
    const errorBuildsCount = AnalyticsComponent.filterByStatus(
      errorBuilds,
      errorBuildStatus,
    ).length;

    // Pie Chart
    const datasets = [
      {
        data: [successBuildsCount, errorBuildsCount],
        backgroundColor: [SeriesStyle.Success.color, SeriesStyle.Danger.color],
        hoverBackgroundColor: [
          SeriesStyle.Success.hover,
          SeriesStyle.Danger.hover,
        ],
      },
    ];

    return (
      <Chart
        type="pie"
        options={options}
        data={{
          labels: ['Succesfull Prints', 'Failed Prints'],
          datasets,
        }}
      />
    );
  }

  render() {
    const { fromDateValue, toDateValue } = this.props;

    const fromTimestamp = this.getFromTimestamp();
    const toTimestamp = this.getToTimestamp();
    const isValidDateRange = fromTimestamp < toTimestamp;

    return (
      <Container fluid>
        <Row>
          <Col xs={6}>
            <BreadcrumbNav breadcrumbs={['Analytics']} />
          </Col>
          <Col xs={6}>
            <form
              style={{ display: 'flex', alignItems: 'center' }}
              className="form-inline date-range pull-right"
            >
              <FormGroup controlId="uxAnalyticsFrom" className="mb0">
                <FormControl
                  name="fromDateValue"
                  pattern="^\d{4}-\d{2}-\d{2}$"
                  type="date"
                  value={fromDateValue}
                  onChange={this.props.handleChange}
                />
              </FormGroup>
              -
              <FormGroup controlId="uxAnalyticsTo" className="mb0">
                <FormControl
                  name="toDateValue"
                  pattern="^\d{4}-\d{2}-\dx{2}$"
                  type="date"
                  value={toDateValue}
                  onChange={this.props.handleChange}
                />
              </FormGroup>
              <Button
                type="button"
                onClick={this.props.onSubmit}
                variant="success"
                className="ml5"
              >
                Run Analysis
              </Button>
            </form>
          </Col>
        </Row>
        {isValidDateRange ? (
          <div>
            <Row>
              <Col xs={12} sm={3}>
                {this.renderGauge()}
              </Col>
              <Col xs={12} sm={3}>
                {this.renderErrorsCounter()}
              </Col>
              <Col xs={12} sm={3}>
                {this.renderAverageBuildTime()}
              </Col>
              <Col xs={12} sm={3}>
                {this.renderTotalBuildCounter()}
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Card bg="dark" className="mb15 p-a" border="secondary">
                  <h4 className="text-center">Operating Time</h4>
                  {this.renderOperatingTime()}
                  <div className="d-flex p-a">
                    {this.renderDownloadReportButton(
                      ANALYTICS_REPORT_TEMPLATE.OPERATING_TIME_PER_DAY,
                    )}
                    {this.renderDownloadReportButton(
                      ANALYTICS_REPORT_TEMPLATE.OPERATING_TIME_PER_WEEK,
                    )}
                  </div>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={6}>
                <Card bg="dark" className="mb15 p-a" border="secondary">
                  <h4 className="text-center">State</h4>
                  {this.renderState()}
                  <div className="d-flex p-a">
                    {this.renderDownloadReportButton(
                      ANALYTICS_REPORT_TEMPLATE.STATE,
                    )}
                  </div>
                </Card>
              </Col>

              <Col xs={12} sm={6}>
                <Card bg="dark" className="mb15 p-a" border="secondary">
                  <h4 className="text-center">Yield</h4>
                  {this.renderPrintsStatePieChart()}
                  <div className="d-flex p-a">
                    {this.renderDownloadReportButton(
                      ANALYTICS_REPORT_TEMPLATE.YIELD,
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        ) : (
          <h1 className="text-center">Not valid date</h1>
        )}
      </Container>
    );
  }
}

AnalyticsComponent.propTypes = {
  toDate: PropTypes.string.isRequired,
  toDateValue: PropTypes.string.isRequired,
  fromDate: PropTypes.string.isRequired,
  fromDateValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
  downtime: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  builds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modelers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  printers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default AnalyticsComponent;
