import React, { useEffect, useState } from 'react';
import { Picky } from 'react-picky';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import { FormattedMessage } from 'rapidfab/i18n';
import Loading from 'rapidfab/components/Loading';
import useElementScroll, { isScrolledToBottom } from 'rapidfab/hooks/useElementScroll';

const OFFSET = 20;

const SelectList = ({
  renderItem,
  items,
  selectValue,
  labelKey,
  valueKey,
  onFetchMore,
}) => {
  const [loading, setLoading] = useState(false);

  const [calledForMore, setCalledForMore] = useState(false);

  const anchor = React.useRef();
  const scrollPosition = useElementScroll(anchor);
  const scrolledToBottom = isScrolledToBottom(anchor, scrollPosition, OFFSET);

  useEffect(() => {
    if (!calledForMore && onFetchMore && scrolledToBottom && !loading) {
      setLoading(true);
      setCalledForMore(true);
      onFetchMore()
        .finally(() => setLoading(false));
    }
  }, [scrolledToBottom, calledForMore, loading]);

  useEffect(() => {
    if (calledForMore && !loading) {
      setCalledForMore(false);
    }
  }, [loading]);

  return (
    <div
      ref={anchor}
      style={{
        overflowY: 'scroll',
        maxHeight: '260px',
      }}
    >
      {items.map(item => (
        renderItem({
          item,
          selectValue,
          labelKey,
          valueKey,
        })
      ))}
      {loading && <Loading />}
    </div>
  );
};

SelectList.defaultProps = {
  onFetchMore: null,
};

SelectList.propTypes = {
  renderItem: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectValue: PropTypes.func.isRequired,
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  onFetchMore: PropTypes.func,
};

const SelectSingleLazy = props => {
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [placeholder, setPlaceholder] = useState(null);

  useEffect(() => {
    const {
      valueKey,
      labelKey,
      value,
      required,
    } = props;
    let {
      placeholder,
      data,
    } = props;
    if (!placeholder) {
      placeholder = required ?
        (<FormattedMessage id="field.choose" defaultMessage="Choose…" />)
        : (<FormattedMessage id="field.none" defaultMessage="None" />);
    }

    const selectedItem = _find(data, { [valueKey]: value });

    if (!required) {
      const emptyPlaceholder = {
        [labelKey]: placeholder,
        [valueKey]: '',
      };
      data = [emptyPlaceholder, ...data];
    }

    setPlaceholder(placeholder);
    setValue(selectedItem ? [selectedItem] : []);
    setOptions(data);
  }, [
    JSON.stringify([props.placeholder, props.required, props.data, props.value, props.valueKey, props.labelKey]),
  ]);

  const handleOnChange = selectedData => {
    const {
      name,
      valueKey,
      imitateOnChangeEvent,
      nullable,
    } = props;
    const value = selectedData ? selectedData[valueKey] : null;

    if (!imitateOnChangeEvent) {
      props.handleOnChange(name, value);
      return;
    }

    // Imitating regular select `onChange` event
    const fakeEvent = {
      target: {
        type: 'select',
        value: nullable && !value ? null : value,
        name,
      },
      stopPropagation: () => {},
      preventDefault: () => {},
    };
    props.handleOnChange(fakeEvent);
  };

  const renderItem = renderProps => {
    const {
      item,
      selectValue,
      labelKey,
      valueKey,
    } = renderProps;

    const {
      isOptionDisabledCallback,
      renderLabelCallback,
      renderOptionCallback,
    } = props;

    if (renderOptionCallback) {
      return renderOptionCallback(renderProps);
    }

    const isOptionsDisabled = isOptionDisabledCallback && isOptionDisabledCallback(item);

    const label = renderLabelCallback ? renderLabelCallback(item) : item[labelKey];

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        key={item[valueKey]}
        onClick={() => !isOptionsDisabled && selectValue(item)}
        className={isOptionsDisabled && 'text-muted'}
      >
        {label}
      </div>
    );
  };

  const {
    labelKey,
    valueKey,
    includeFilter,
    disabled,
    defaultFocusFilter,
    required,
  } = props;

  return (
    <div className="position-relative w-100">
      <Picky
        placeholder={placeholder}
        options={options}
        labelKey={labelKey}
        valueKey={valueKey}
        includeFilter={includeFilter}
        defaultFocusFilter={defaultFocusFilter}
        value={value}
        keepOpen={false}
        multiple={false}
        clearFilterOnClose
        onChange={values => handleOnChange(values)}
        disabled={disabled}
        className="wrap-text"
        buttonProps={{
          'data-testid': `picky-input-${props.dataTestId || props.name}`,
        }}
        renderList={({
          items,
          selectValue,
        }) => (
          <SelectList
            labelKey={labelKey}
            valueKey={valueKey}
            items={items}
            selectValue={selectValue}
            renderItem={renderItem}
            onFetchMore={props.onFetchMore}
          />
        )}
      />
      {/*
          Adding transparent input to add `required` browser validation when needed
          since Picky does not have validation options
          1px height is required to show browser validation popup right under the picky dropdown
        */}
      {required && (
        <input
          tabIndex={-1}
          autoComplete="off"
          className="position-absolute opacity-0 w-100 p-0 m-0 border-0 pe-none"
          style={{
            height: '1px',
          }}
          value={value}
          // onChange needed to prevent console warnings
          onChange={() => {}}
          required
        />
      )}
    </div>
  );
};

SelectSingleLazy.defaultProps = {
  placeholder: null,
  includeFilter: true,
  value: null,
  labelKey: 'name',
  valueKey: 'uri',
  // Made for backwards compatibility with the logic created for regular Selects
  // Do not use it for any new case. Better create appropriate onChange handler
  imitateOnChangeEvent: false,
  renderOptionCallback: null,
  renderLabelCallback: null,
  isOptionDisabledCallback: null,
  required: false,
  disabled: false,
  defaultFocusFilter: true,
  onFetchMore: null,
  dataTestId: '',
  nullable: false,
};

SelectSingleLazy.propTypes = {
  name: PropTypes.string.isRequired,
  // Used in getDerivedStateFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  placeholder: PropTypes.string,
  // Used in getDerivedStateFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  // Used in getDerivedStateFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleOnChange: PropTypes.func.isRequired,
  includeFilter: PropTypes.bool,
  imitateOnChangeEvent: PropTypes.bool,
  renderOptionCallback: PropTypes.func,
  renderLabelCallback: PropTypes.func,
  isOptionDisabledCallback: PropTypes.func,
  // Used in getDerivedStateFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultFocusFilter: PropTypes.bool,
  onFetchMore: PropTypes.func,
  dataTestId: PropTypes.string,
  nullable: PropTypes.bool,
};

export default SelectSingleLazy;
