import React, { useState, useEffect } from 'react';
import MaterialTests from 'rapidfab/components/admin/MaterialTests';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import * as Selectors from 'rapidfab/selectors';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import { FormattedMessage } from 'react-intl';
import extractUuid from 'rapidfab/utils/extractUuid';
import Alert from 'rapidfab/utils/alert';
import _isArray from 'lodash/isArray';

const MaterialTestsContainer = props => {
  const dispatch = useDispatch();

  const isSaving = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION].post.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION].put.fetching,
  );

  const [showCreateMaterialTestOperationModal,
    setShowCreateMaterialTestOperationModal,
  ] = useState({
    show: false,
    initialFormValues: null,
  });

  const [showManageTestUnitsModal,
    setShowManageTestUnitsModal,
  ] = useState(false);

  const materialTestOperations = useSelector(Selectors.getMaterialTestOperations);
  const materialTestUnits = useSelector(Selectors.getMaterialTestUnits);
  const materialTestUnitsByUri = useSelector(Selectors.getMaterialTestUnitsByUri);

  const handleMaterialTestTableRecordClick = materialTestData => {
    setShowCreateMaterialTestOperationModal(
      previous => ({
        ...previous,
        show: true,
        initialFormValues: materialTestData,
        uuid: materialTestData.uuid,
      }),
    );
  };

  const handleSaveMaterialTestOperation = formValues => {
    setShowCreateMaterialTestOperationModal(previous =>
      ({ ...previous, initialFormValues: null, show: false }));

    const payload = {
      name: formValues.name,
      description: formValues.description || '',
      unit: formValues.unit[0],
    };

    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION].post(payload))
      .then(response => Alert.success(
        <FormattedMessage
          id="toaster.materialTestOperation.created"
          defaultMessage="Material test operation {uuid} created successfully."
          values={{ uuid: extractUuid(response.headers.location) }}
        />,
      ))
      .catch(error => Alert.error(error));
  };

  const handleUpdateMaterialTestOperation = formValues => {
    const payload = {
      name: formValues.name,
      description: formValues.description || '',
      unit: _isArray(formValues.unit) ? formValues.unit[0] : formValues.unit,
    };

    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION]
      .put(formValues.uuid, payload))
      .then(() => Alert.success(
        <FormattedMessage
          id="toaster.materialTestOperation.updated"
          defaultMessage="Material test operation {uuid} updated successfully."
          values={{ uuid: formValues.uuid }}
        />,
      ))
      .then(() => setShowCreateMaterialTestOperationModal(previous =>
        ({ ...previous, show: false, initialFormValues: null })))
      .catch(error => Alert.error(error));
  };

  const handleSaveMaterialTestUnits = async newlyAddedTestUnits => {
    Promise.all(
      newlyAddedTestUnits.map(async newUnit => (
        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_UNIT].post({
          symbol: newUnit.symbol,
          description: newUnit.name,
        }))
      )),
    ).then(() => {
      Alert.success(
        <FormattedMessage
          id="toaster.materialTestUnits.saved"
          defaultMessage="Material test units successfully saved."
        />,
      );
    });
  };

  const selected = {
    isSaving,
    materialTestOperations,
    materialTestUnits,
    materialTestUnitsByUri,
  };

  const dispatched = {
    handleSaveMaterialTestUnits,
    handleSaveMaterialTestOperation,
    handleUpdateMaterialTestOperation,
    handleMaterialTestTableRecordClick,
  };

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_UNIT].list());
  };

  useEffect(() => onInitialize(), []);

  return (
    <MaterialTests
      {...props}
      {...selected}
      {...dispatched}
      createMaterialTestOperationModalState={[
        showCreateMaterialTestOperationModal,
        setShowCreateMaterialTestOperationModal,
      ]}
      manageTestUnitsModalState={[
        showManageTestUnitsModal,
        setShowManageTestUnitsModal,
      ]}
    />
  );
};

export default withRecordsListHandling(
  MaterialTestsContainer,
  Selectors.getMaterialTestOperations,
  [API_RESOURCES.MATERIAL_TEST_OPERATION],
  {
    defaultSort: null,
  },
);
