import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import Table from 'rapidfab/components/Tables/Table';
import Limit from 'rapidfab/components/RecordList/Limit';
import Search from 'rapidfab/components/RecordList/Search';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Pagination from 'rapidfab/components/RecordList/Pagination';
import SubLocations from 'rapidfab/components/SubLocations';
import Locations from '../locations';

const TableWithSearching = props => {
  const {
    tableID,
    data,
    limit,
    search,
    offset,
    navbar,
    columns,
    onSearch,
    listStore,
    subheader,
    locations,
    breadcrumbs,
    extraFilters,
    onPageChange,
    onLimitChange,
    withBreadcrumbs,
    showLocationsFilter,
    showSubLocationsFilter = false,
    isManualSoringEnabled,
    manualSortingFunc,
    initialSortedDesc,
    initialSortedColumn,
    isFetching,
    withoutDivider,
    isUpdatedColumnShown = true,
    isDebugModeEnabled = false,
  } = props;

  const areLocationsShown = showLocationsFilter && locations.length > 0;
  const showSubLocations = areLocationsShown && showSubLocationsFilter;

  return (
    <Container fluid>
      {
        withBreadcrumbs && breadcrumbs && (
          <BreadcrumbNav breadcrumbs={breadcrumbs} />
        )
      }
      {subheader && (
        <Row>
          <Col xs={12} className="mb8">
            {subheader}
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={showSubLocations ? 3 : 8}>
          {areLocationsShown ? (
            <Locations />
          ) : (
            <div />
          )}
        </Col>
        {
          showSubLocations && (
            <Col xs={5}>
              <SubLocations />
            </Col>
          )
        }
        {
          navbar && (
            <Col xs={4}>
              {navbar}
            </Col>
          )
        }
      </Row>

      {!withoutDivider && <hr />}

      <Row>
        <Col xs={12}>
          <Row>
            <Col sm={{ span: 12 }} lg={{ span: 6, offset: 1 }} className="mb8">
              <Search
                initialSearch={search}
                onSearch={onSearch}
              />
            </Col>
            {/* <Col xs={12} lg={2} className="mb8">
              <Limit
                limit={limit}
                onLimitChange={onLimitChange}
              />
            </Col> */}
          </Row>
          {!_isEmpty(extraFilters) && (
            <Row className="mb8">
              <Col xs={{ span: 12 }} lg={{ span: 10, offset: 1 }}>
                <div className="filters-label">Filters:</div>
                {extraFilters }
              </Col>

            </Row>
          )}

          <Table
            tableID={tableID}
            data={data}
            columns={columns}
            isFetching={isFetching}
            isFilteringEnabled={false}
            withDefaultPagination={false}
            isUpdatedColumnShown={isUpdatedColumnShown}
            isManualSoringEnabled={isManualSoringEnabled}
            manualSortingFunc={manualSortingFunc}
            initialSortedDesc={initialSortedDesc}
            initialSortedColumn={initialSortedColumn}
            isDebugModeEnabled={isDebugModeEnabled}
            PaginationComponent={(
              <Row>
                <Col>
                  <Pagination
                    limit={limit}
                    listStore={listStore}
                    offset={offset}
                    onPageChange={onPageChange}
                  />

                </Col>
                <Col lg={2}>
                  <Limit
                    limit={limit}
                    onLimitChange={onLimitChange}
                  />
                </Col>
              </Row>
            )}

          />
        </Col>
        {/* <Pagination
          limit={limit}
          listStore={listStore}
          offset={offset}
          onPageChange={onPageChange}
        /> */}
      </Row>
    </Container>
  );
};

/* eslint-disable react/require-default-props */

TableWithSearching.propTypes = {
  tableID: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    accessor: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    uri: PropTypes.string,
    defaultMessage: PropTypes.string,
    route: PropTypes.string,
    short: PropTypes.bool,
    slug: PropTypes.string,
    role: PropTypes.string,
    func: PropTypes.func,
    equals: PropTypes.string,
    users: PropTypes.shape({}),
    params: PropTypes.shape({}),
    resource: PropTypes.string,
    resources: PropTypes.objectOf(PropTypes.shape({
      uri: PropTypes.string,
      name: PropTypes.string,
    })),
    customFieldReference: PropTypes.string,
    modelers: PropTypes.objectOf(PropTypes.shape({})),
    shipment: PropTypes.string,
    suffix: PropTypes.string,
    mapping: PropTypes.objectOf(PropTypes.shape({})),
    coloured: PropTypes.bool,
    colorScheme: PropTypes.string,
  })).isRequired,
  isFetching: PropTypes.bool,
  initialSortedColumn: PropTypes.string,
  initialSortedDesc: PropTypes.bool,
  isManualSoringEnabled: PropTypes.bool,
  listStore: PropTypes.shape({}).isRequired,
  filters: PropTypes.shape({
    location: PropTypes.string,
  }).isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  search: PropTypes.string,
  manualSortingFunc: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  withoutDivider: PropTypes.bool,
  withBreadcrumbs: PropTypes.bool,
  showLocationsFilter: PropTypes.bool,
  extraFilters: PropTypes.arrayOf(PropTypes.shape({})),
  subheader: PropTypes.element,
  navbar: PropTypes.element,
  breadcrumbs: PropTypes.arrayOf(PropTypes.string),
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  isUpdatedColumnShown: PropTypes.bool,
  isDebugModeEnabled: PropTypes.bool,
  showSubLocationsFilter: PropTypes.bool,
};

export default TableWithSearching;
