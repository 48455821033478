import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import PrintersComponent from 'rapidfab/components/inventory/printers';
import {
  API_RESOURCES,
  FEATURES,
  PAGINATION_IGNORE_DEFAULT_LIMIT, RUN_STATUSES,
  VIEW_MODE_OPTIONS,
} from 'rapidfab/constants';
import {
  getMaterialBatches, getRunActualsKeyedByRunUri,
  getRunEstimatesByRunUri,
} from 'rapidfab/selectors';
import _flatMap from 'lodash/flatMap';
import dayjs from 'dayjs';
import usePrevious from 'rapidfab/hooks';

const PrintersContainer = memo(props => {
  const [viewMode, setViewMode] = useState(VIEW_MODE_OPTIONS.CARDS);
  const isCardsView = viewMode === VIEW_MODE_OPTIONS.CARDS;
  const [buildsFetched, setBuildsFetched] = useState(false);
  const [additionalResourcesFetched, setAdditionalResourcesFetched] = useState(false);
  const [filterValue, setFilterValue] = useState('');

  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MODELER].list.fetching ||
    state.ui.nautilus[API_RESOURCES.LOCATION].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PRINTER].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].list.fetching);

  const batchesFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH].list.fetching);
  const buildsFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.BUILD].list.fetching);
  const runsFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.SCHEDULE_RUNS].list.fetching ||
    state.ui.nautilus[API_RESOURCES.RUN_ACTUALS].list.fetching ||
    state.ui.nautilus[API_RESOURCES.RUN].list.fetching);

  const locations = useSelector(Selectors.getLocationsByUri);
  const modelers = useSelector(Selectors.getModelersByUri);
  const builds = useSelector(Selectors.getBuildsByUri);
  const printers = useSelector(Selectors.getPrinters);
  const printerTypes = useSelector(Selectors.getPrinterTypesByUri);
  const batches = useSelector(getMaterialBatches);
  const runs = useSelector(Selectors.getRunsByPrinterUri);
  const runEstimatesKeyedByRunUri = useSelector(getRunEstimatesByRunUri);
  const runActuals = useSelector(getRunActualsKeyedByRunUri);

  const isGroupQualificationsFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.GROUP_QUALIFICATIONS));
  const isGeneralMFGLanguageEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.GENERAL_MFG_LANGUAGE));
  const isMaterialManagementFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.MATERIAL_MANAGEMENT));

  const groupedPrinters = useSelector(Selectors.getPrintersGroupedByLocation);

  const [paginationState, setPaginationState] = useState({
    pageLimit: 15,
    offset: 0,
    activePage: 0,
    totalPrinters: 0,
  });

  const [globalFilter, setGlobalFilter] = useState('');

  const totalPaginatedPages = Math.ceil(paginationState.totalPrinters / paginationState.pageLimit);

  const selected = useMemo(() => ({
    fetching,
    locations,
    modelers,
    printers,
    printerTypes,
    isGroupQualificationsFeatureEnabled,
    isGeneralMFGLanguageEnabled,
    batches,
    isMaterialManagementFeatureEnabled,
    batchesFetching,
    groupedPrinters,
    viewMode,
    setViewMode,
    isCardsView,
    buildsFetching,
    builds,
    runsFetching,
  }), [
    batches,
    batchesFetching,
    builds,
    buildsFetching,
    fetching,
    groupedPrinters,
    isCardsView,
    isGeneralMFGLanguageEnabled,
    isGroupQualificationsFeatureEnabled,
    isMaterialManagementFeatureEnabled,
    locations,
    modelers,
    printerTypes,
    printers,
    runsFetching,
    viewMode,
  ]);

  const dispatch = useDispatch();

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list());
  };

  const loadAdditionalResources = printers => {
    const printerUris = _flatMap(printers, 'uri');

    if (isMaterialManagementFeatureEnabled && printerUris.length) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].list({ at_machine: printerUris }));
    }

    const runUris = _flatMap(printers, 'queue');

    if (runUris.length) {
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.RUN].list(
          { uri: runUris },
          { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
          {},
          {},
          true,
        ));

      dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].list(
        { run: runUris },
        { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
        {},
        {},
        true,
      ));

      dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_ACTUALS].list(
        { run: runUris },
        { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
        {},
        {},
        true,
      ));
    }
  };

  const loadPrinters = () => {
    const filter = {};

    if (globalFilter) {
      filter.multicolumn_search = globalFilter;
    }

    // Clear all the resources if we are going through the pagination
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].clear('list'));
    dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].clear('list'));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].clear('list'));

    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list({},
      { limit: paginationState.pageLimit,
        offset: paginationState.offset > 0 ? paginationState.offset : 0 }, {}, { ...filter }, true))
      .then(printersResponse => {
        setPaginationState(previous => ({
          ...previous,
          totalPrinters: printersResponse.json?.meta?.count,
          activePage: paginationState.activePage >= totalPaginatedPages && paginationState.activePage !== 0 ?
            totalPaginatedPages - 1 :
            paginationState.activePage,
        }));

        const printers = printersResponse.json?.resources;

        // Load additional resources only if we are in the cards view
        if (printers?.length && isCardsView) {
          loadAdditionalResources(printers);
          setAdditionalResourcesFetched(true);
          // Set "false" only if we had resources, to prevent additional API calls.
        } else if (printers.length) {
          // Reset if we are not in the cards view
          setAdditionalResourcesFetched(false);
        }
      });
  };

  const previousPage = usePrevious(paginationState.activePage);
  const previousGlobalFilter = usePrevious(globalFilter);

  useEffect(() => {
    /*
      We need to send API calls only if:
      1. Initial loading in the LIST view, sending the base Printer API calls
         without additional resources.
      2. Switching between the LIST and CARDS view but only ONCE, to prevent
         sending the same API calls multiple times.
      3. Pagination changes: In LIST view only Printer's data, in CARD view
         also all the additional resources.
      4. Global filter changes.
      5. There should not be any duplicated API calls.
    */

    if ((!additionalResourcesFetched && isCardsView)
      || previousPage !== paginationState.activePage
      || globalFilter !== previousGlobalFilter) {
      loadPrinters();
    }
  }, [
    paginationState.pageLimit,
    paginationState.offset,
    paginationState.activePage,
    globalFilter,
    isCardsView,
    additionalResourcesFetched,
  ]);

  useEffect(() => onInitialize(), []);

  useEffect(() => {
    if (isCardsView && !buildsFetched) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD].list({
        ...(Object.keys(modelers).length && { modeler: Object.keys(modelers) }),
      }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
      setBuildsFetched(true);
    }
  }, [buildsFetched, dispatch, isCardsView, JSON.stringify(Object.keys(modelers))]);

  const isRunActive = run => ((run.status === RUN_STATUSES.IN_PROGRESS) ||
    (run.status === RUN_STATUSES.PAUSED));

  /*
    This method is needed to find the Current Scheduled Run by the earliest estimates.start date,
    also find the "next" scheduled run which is the "latest run" which should have the latest
    estimates.end date.
  */

  const findScheduledRuns = useCallback(printer => {
    if (!printer?.uri) {
      return { earliestRun: null, latestRunDate: null };
    }

    // Get all runs for the current printer
    const printerRuns = runs[printer.uri];

    if (printerRuns) {
      // Find all the scheduled runs for the current printer
      const scheduledRuns = printerRuns
        .map(run => {
          // Get run estimates (scheduled_runs API)
          const runEstimate = runEstimatesKeyedByRunUri[run.uri];

          if (runEstimate) {
            // If we have estimates, return the run, its start and end dates in day.js format
            const startDate = dayjs(runEstimate.estimates?.start);
            const endDate = dayjs(runEstimate.estimates?.end);

            return { run, startDate, endDate };
          }
          return null;
        })
        /* Filter array if we have no estimates or the startDate is invalid
           in order to skip the rest of the logic */
        .filter(scheduledRun => scheduledRun &&
          (scheduledRun.startDate.isValid() || scheduledRun.endDate.isValid()));

      /* If we have some scheduled runs, find the earliest and latest runs
       they should not be the same runs as if the printer has only 1 run. */

      if (scheduledRuns.length) {
        const earliestRun = scheduledRuns
          // .slice() - to have a new copy of the array without modifying the original array.
          .slice()
          /* Using sort to find the earliest run by .diff()
             between the start dates and getting the first element. */
          .sort((a, b) => a.startDate.diff(b.startDate))[0].run;

        // If we have only 1 run or earliest run is the same as the latest run -> we should skip.
        const latestRun = scheduledRuns.length === 1 ? null :
          scheduledRuns.slice().sort((a, b) => b.endDate.diff(a.endDate))[0].run;

        const latestRunDate = latestRun?.uri ?
          scheduledRuns.find(scheduledRun => scheduledRun.run.uri === latestRun.uri)?.endDate :
          null;

        const endDate = latestRunDate && latestRunDate.isValid() ? latestRunDate : null;

        return { earliestRun, latestRunDate: endDate };
      }
    }

    // Return null if we have no scheduled runs for the current printer.

    return { earliestRun: null, latestRunDate: null };
  }, [runEstimatesKeyedByRunUri, runs]);

  const getPercentageOfTheCurrentScheduledRun = useCallback(run => {
    if (!run) {
      // Has not started yet or no run to show at all
      return 0;
    }

    const runEstimate = runEstimatesKeyedByRunUri[run.uri];
    const actuals = runActuals[run.uri];
    const actualsStartDate = dayjs(actuals?.start_in_progress_time);

    if ((runEstimate && actualsStartDate.isValid()) &&
      isRunActive(run)) {
      const startDate = Date.parse(runEstimate.estimates?.start);
      const endDate = Date.parse(runEstimate.estimates?.end);
      const current = Date.now();
      let percentage = ((current - actualsStartDate) / (endDate - startDate)) * 100;

      if (percentage < 0) {
        percentage = 0;
      } else if (percentage > 100) {
        percentage = 100;
      } else {
        percentage = Math.round(percentage);
      }

      return percentage;
    }

    return 0;
  }, [runEstimatesKeyedByRunUri]);

  const findCurrentRunStartDate = useCallback(run => {
    if (!run) {
      return null;
    }

    const runEstimate = runEstimatesKeyedByRunUri[run.uri];

    if (runEstimate) {
      return runEstimate.estimates?.start;
    }

    return null;
  }, [runEstimatesKeyedByRunUri]);

  const dispatched = {
    findScheduledRuns,
    getPercentage: getPercentageOfTheCurrentScheduledRun,
    scheduledStartDate: findCurrentRunStartDate,
    isRunActive,
  };

  return (
    <PrintersComponent
      {...props}
      {...selected}
      {...dispatched}
      filters={{
        globalFilter,
        setGlobalFilter,
      }}
      filterValues={{
        filterValue,
        setFilterValue,
      }}
      pagination={{ ...paginationState, setPaginationState, totalPaginatedPages }}
    />
  );
});

export default PrintersContainer;
