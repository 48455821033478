/* eslint-disable import/prefer-default-export, unicorn/no-array-reduce */
import Constants, { API_RESOURCES } from 'rapidfab/constants';
import { CACHED_RESOURCES } from 'rapidfab/api/index';

function makePost(api, host, resource) {
  return payload => ({
    api: {
      resource,
      host,
      method: 'POST',
    },
    types: [
      Constants.RESOURCE_POST_REQUEST,
      Constants.RESOURCE_POST_SUCCESS,
      Constants.RESOURCE_POST_FAILURE,
    ],
    callApi: () => api[host][resource].post(payload),
    payload,
  });
}

function makePut(api, host, resource) {
  return (uuid, payload) => ({
    api: {
      resource,
      host,
      method: 'PUT',
    },
    uuid,
    types: [
      Constants.RESOURCE_PUT_REQUEST,
      Constants.RESOURCE_PUT_SUCCESS,
      Constants.RESOURCE_PUT_FAILURE,
    ],
    callApi: () => api[host][resource].put(uuid, payload),
    payload,
  });
}

function makeClone(api, host, resource) {
  return (uuid, payload) => ({
    api: {
      resource,
      host,
      method: 'PUT',
    },
    uuid,
    types: [
      Constants.RESOURCE_CLONE_REQUEST,
      Constants.RESOURCE_CLONE_SUCCESS,
      Constants.RESOURCE_CLONE_FAILURE,
    ],
    callApi: () => api[host][resource].clone(uuid, payload),
    payload,
  });
}

function makeReplace(api, host, resource) {
  return (uuid, payload) => ({
    api: {
      resource,
      host,
      method: 'PUT',
    },
    uuid,
    types: [
      Constants.RESOURCE_REPLACE_REQUEST,
      Constants.RESOURCE_REPLACE_SUCCESS,
      Constants.RESOURCE_REPLACE_FAILURE,
    ],
    callApi: () => api[host][resource].replace(uuid, payload),
    payload,
  });
}

function makeList(api, host, resource) {
  // By default - use `_LIST_SUCCESS` action type
  let successType = Constants.RESOURCE_LIST_SUCCESS;

  if (resource === API_RESOURCES.ACCESS_INFO_FOR_RESOURCE) {
    // Use custom action success type for access-info results, since it requires custom handlinng
    successType = Constants.RESOURCE_ACCESS_INFO_SUCCESS;
  }

  return (
    filters,
    page,
    searchParams,
    queryParams,
    forced = false,
    apiVersion = null,
    config = null,
  ) => ({
    api: {
      resource,
      host,
      method: 'LIST',
    },
    callApi: () => api[host][resource].list(
      filters,
      page,
      config,
      searchParams,
      queryParams,
      apiVersion,
    ),
    filters,
    searchParams,
    queryParams,
    apiVersion,
    shouldCallAPI: state => {
      if (forced) {
        // eslint-disable-next-line no-console
        console.warn(`forced property is deprecated. Remove ${resource} endpoint from CACHED_RESOURCES list instead.`);
        return true;
      }
      if (!CACHED_RESOURCES.includes(resource)) return true;
      const request = state.ui[host][resource].list;
      const timeSinceLastRequest = Date.now() - request.finished;
      return !(request.fetching || timeSinceLastRequest < 3000);
    },
    types: [
      Constants.RESOURCE_LIST_REQUEST,
      successType,
      Constants.RESOURCE_LIST_FAILURE,
    ],
  });
}

function makeGet(api, host, resource) {
  return (uuid, forced = false, query) => ({
    api: {
      resource,
      host,
      method: 'GET',
    },
    callApi: () => api[host][resource].get(uuid, {}, query),
    shouldCallAPI: state => {
      if (forced) {
        // eslint-disable-next-line no-console
        console.warn(`forced property is deprecated. Remove ${resource} endpoint from CACHED_RESOURCES list instead.`);
        return true;
      }
      if (!CACHED_RESOURCES.includes(resource)) return true;
      const request = state.ui[host][resource].get;
      const timeSinceLastRequest = Date.now() - request.finished;
      return !(request.fetching || timeSinceLastRequest < 3000);
    },
    types: [
      Constants.RESOURCE_GET_REQUEST,
      Constants.RESOURCE_GET_SUCCESS,
      Constants.RESOURCE_GET_FAILURE,
    ],
    uuid,
  });
}

function makeDelete(api, host, resource) {
  return uuid => ({
    api: {
      resource,
      host,
      method: 'DELETE',
    },
    uuid,
    types: [
      Constants.RESOURCE_DELETE_REQUEST,
      Constants.RESOURCE_DELETE_SUCCESS,
      Constants.RESOURCE_DELETE_FAILURE,
    ],
    callApi: () => api[host][resource].delete(uuid),
  });
}

function makeRemove(api, host, resource) {
  return uuid => ({
    api: {
      resource,
      host,
      method: 'REMOVE',
    },
    uuid,
    type: Constants.RESOURCE_MANUAL_REMOVE,
  });
}

function makeClear(api, host, resource) {
  return resourceType => ({
    api: {
      resource,
      host,
      method: 'CLEAR',
    },
    resourceType,
    type: Constants.RESOURCE_MANUAL_CLEAR,
  });
}

export const makeApiActions = (api, endpoints) =>
  Object.keys(endpoints).reduce(
    (hosts, host) =>
      ({ ...hosts,
        [host]: endpoints[host].reduce(
          (resources, resource) =>
            ({ ...resources,
              [resource]: {
                post: makePost(api, host, resource),
                list: makeList(api, host, resource),
                delete: makeDelete(api, host, resource),
                put: makePut(api, host, resource),
                clone: makeClone(api, host, resource),
                replace: makeReplace(api, host, resource),
                get: makeGet(api, host, resource),
                remove: makeRemove(api, host, resource),
                clear: makeClear(api, host, resource), // clears only "api" store with list of uuids
              } }),
          {},
        ) }),
    {},
  );
