import { createSelector } from 'reselect';
import _filter from 'lodash/filter';
import { getPrinterTypes } from '../printerType';
import { getInitialValuesBureau } from '../bureau';

// eslint-disable-next-line import/prefer-default-export
export const getPrinterTypesWithMaterial = createSelector(
  [getInitialValuesBureau, getPrinterTypes],
  (material, printerTypes) => _filter(
    printerTypes, ({ materials }) => materials.includes(material.uri),
  ),
);
