import React, { Component } from 'react';
import PropTypes from 'prop-types';
import extractUuid from 'rapidfab/utils/extractUuid';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { connect } from 'react-redux';
import Loading from 'rapidfab/components/Loading';
import PrepTaskModal from 'rapidfab/components/manage/PrepTaskModal';
import _omit from 'lodash/omit';
import { API_RESOURCES } from 'rapidfab/constants';

import { PREP_TASK_MODAL_CONTAINER } from 'rapidfab/constants/forms';

class PrepTaskModalContainer extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const { onInitialize, prepTaskUri } = this.props;

    onInitialize(prepTaskUri);
  }

  handleClose() {
    this.props.onClose('tasks_in_workflow', null);
  }

  render() {
    const {
      isLoading,
    } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <PrepTaskModal
        {...this.props}
        onClose={this.handleClose}
      />
    );
  }
}

PrepTaskModalContainer.defaultProps = {
  prepTask: null,
  prepTaskUri: null,
};

PrepTaskModalContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  prepTask: PropTypes.shape({
    name: PropTypes.string.isRequired,
    shortname: PropTypes.string.isRequired,
    uri: PropTypes.string,
  }),
  prepTaskUri: PropTypes.string,
  onInitialize: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  const isLoading = state.ui.nautilus[API_RESOURCES.PREP_TASK].get.fetching;

  const isSubmitting = state.ui.nautilus[API_RESOURCES.PREP_TASK].post.fetching ||
    state.ui.nautilus[API_RESOURCES.PREP_TASK].put.fetching;

  const prepTask = props.prepTaskUri
    && Selectors.getUUIDResource(state, extractUuid(props.prepTaskUri));

  const customGroups = Selectors.getCustomGroups(state);

  const prepTasks = Selectors.getPrepTasks(state);

  const initialValues = prepTask || {};
  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => PREP_TASK_MODAL_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  return {
    isLoading,
    isSubmitting,
    prepTask,
    prepTasks,
    customGroups,
    initialFormValues,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onInitialize: prepTaskUri => {
      const prepTaskUUID = prepTaskUri && extractUuid(prepTaskUri);
      if (prepTaskUUID) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_TASK].get(prepTaskUUID));
      }

      dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].list());
    },
    onFormSubmit: async payload => {
      const updatedPayload = _omit(payload, 'copy');

      if (payload.copy) {
        delete updatedPayload.uuid;
        updatedPayload.source_task = payload.uri;
        delete updatedPayload.uri;
      }

      if (!updatedPayload.edit_group) {
        updatedPayload.edit_group = null;
      }

      let prepTaskUri = updatedPayload.uri;
      if (updatedPayload.uuid) {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_TASK].put(updatedPayload.uuid, updatedPayload));
      } else {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_TASK].post(updatedPayload))
          .then(prepTaskResponse => {
            prepTaskUri = prepTaskResponse.headers.location;
          });
      }
      await dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_TASK].get(extractUuid(prepTaskUri)));
      ownProps.onClose('tasks_in_workflow', prepTaskUri);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrepTaskModalContainer);
