import React, { useEffect } from 'react';
import Actions from 'rapidfab/actions';
import StockComponent from 'rapidfab/components/records/stock';
import Loading from 'rapidfab/components/Loading';
import * as Selectors from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import { API_RESOURCES, FEATURES, ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { getMaterialBatchesForStock, getMaterialLotsForStock } from 'rapidfab/selectors';
import _uniq from 'lodash/uniq';
import { useDispatch, useSelector } from 'react-redux';
import extractUuid from 'rapidfab/utils/extractUuid';

import _get from 'lodash/get';
import _set from 'lodash/set';

import { STOCK_CONTAINER } from 'rapidfab/constants/forms';
import { FormattedMessage } from 'react-intl';

function redirect() {
  window.location.hash = getRouteURI(ROUTES.STOCKS);
}

const StockContainer = props => {
  const uuid = useSelector(Selectors.getRouteUUID);
  const stock = useSelector(state => Selectors.getRouteUUIDResource(state, props));
  const initialValues = { track_quantity: true, ...stock };

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => STOCK_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  const batches = useSelector(state => getMaterialBatchesForStock(state, stock));
  const lots = useSelector(state => getMaterialLotsForStock(state, stock));
  const bureau = useSelector(Selectors.getBureauUri);
  const fetching = useSelector(state => state.ui.nautilus[API_RESOURCES.STOCK].get.fetching
    || state.ui.nautilus[API_RESOURCES.LOCATION].list.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL].list.fetching,
  );
  const loading = useSelector(state => state.ui.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL_CONTAINER].get.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH].get.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL_LOT].get.fetching,
  );
  const stockSummaryDataLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.STOCK_SUMMARY].get.fetching ||
    state.ui.nautilus[API_RESOURCES.SUB_LOCATION].list.fetching);
  const submitting = useSelector(state => Selectors.getResourceFetching(state, 'nautilus.stock'));
  const materials = useSelector(Selectors.getMaterials);
  const locations = useSelector(Selectors.getLocations);
  const subLocations = useSelector(Selectors.getSubLocations);
  const stockSummary = useSelector(Selectors.getStockSummary);

  const isMaterialManagementFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.MATERIAL_MANAGEMENT),
  );

  const selected = {
    bureau,
    uuid,
    stock,
    initialFormValues,
    fetching,
    loading,
    submitting,
    materials,
    locations,
    batches,
    lots,
    isMaterialManagementFeatureEnabled,
    subLocations,
    stockSummary,
    stockSummaryLoading: stockSummaryDataLoading,
  };

  const dispatch = useDispatch();

  const onInitialize = async (currentBureau, currentUUID) => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: currentBureau }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    if (currentBureau && currentUUID) {
      try {
        const stockData = await dispatch(Actions.Api.nautilus[API_RESOURCES.STOCK].get(currentUUID));
        const stockFields = stockData.json;

        if (stockFields) {
          const promises = [];
          const { material_batches, material_lots } = stockFields;
          const uniq_material_batches = _uniq(material_batches);
          const uniq_material_lots = _uniq(material_lots);

          if (isMaterialManagementFeatureEnabled) {
            const subLocationData = await dispatch(
              Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list({ location: stockFields?.location }),
            );

            const subLocations = subLocationData?.json.resources;

            if (subLocations.length) {
              subLocations.forEach(subLocation => {
                promises.push(
                  dispatch(
                    Actions.Api.nautilus[API_RESOURCES.STOCK_SUMMARY].clear('get')),
                  dispatch(
                    Actions.Api.nautilus[API_RESOURCES.STOCK_SUMMARY].get(
                      `${extractUuid(subLocation?.uri)}/stock/${currentUUID}`, true,
                    ),
                  ),
                );
              });
            }
          }

          if (uniq_material_batches.length) {
            promises.push(
              dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].list({ uri: uniq_material_batches })),
            );
          }
          if (uniq_material_lots.length) {
            promises.push(
              dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].list({ uri: uniq_material_lots })),
            );
          }
          await Promise.all(promises);
        }
      } catch (error) {
        Alert.error(
          <FormattedMessage
            id="toaster.error.stock.unableToLoad"
            defaultMessage="Unable to load stock materials. {errorMsg}"
            values={{ errorMsg: error.message }}
          />,
        );
      }
    }
  };
  const loadMaterialBatchesContainers = containers =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list(
      { uri: containers }, {}, {}, {}, true,
    ));
  const loadMaterialLotsContainers = uri =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list(
      { material_lot: uri }, {}, {}, {}, true,
    ));
  const onSave = ({ uuid: currentUUID, on_order_quantity, quantity, ...payload }, materialManagementFeatureEnabled) => {
    const modifiedPayload = payload;
    // need to explicitly coerce stock_max and stock_min to integers before comparing
    // otherwise character by character comparison is performed 123 > 12222
    if (
      payload.stock_max &&
      payload.stock_min &&
      Number.parseInt(payload.stock_max, 10) <= Number.parseInt(payload.stock_min, 10)
    ) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.stock.minMaterialLessThanMaxMaterial"
          defaultMessage="Please ensure minimum material is less than maximum material"
        />);
      return;
    }

    // quantity and on_order_quantity fields allowed to be edited for MM feature disabled case only
    // prevent sending these values otherwise
    if (!materialManagementFeatureEnabled) {
      modifiedPayload.on_order_quantity = on_order_quantity;
      modifiedPayload.quantity = quantity;
    }

    if (!modifiedPayload.track_quantity) {
      delete modifiedPayload.quantity;
    }

    if (modifiedPayload.material_batches) {
      delete modifiedPayload.material_batches;
    }

    if (modifiedPayload.material_lots) {
      delete modifiedPayload.material_lots;
    }

    STOCK_CONTAINER.FLOAT_FIELDS.forEach(
      fieldName => {
        const field = _get(modifiedPayload, fieldName);
        if (field) {
          _set(modifiedPayload, fieldName, Number.parseFloat(field));
        }
      },
    );

    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.STOCK].put(currentUUID, modifiedPayload)).then(redirect);
    } else {
      dispatch(Actions.Api.nautilus[API_RESOURCES.STOCK].post(modifiedPayload)).then(redirect);
    }
  };
  const onDelete = currentUUID => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.STOCK].delete(currentUUID)).then(redirect);
    }
  };

  useEffect(() => {
    if (bureau && uuid) {
      // eslint-disable-next-line no-return-await
      (async () => await onInitialize(bureau, uuid))();
    }
  }, [bureau, uuid]);

  const onSubmit = payload => {
    onSave(payload, isMaterialManagementFeatureEnabled);
  };

  if (fetching) {
    return (
      <Loading />
    );
  }
  return (
    <StockComponent
      {...props}
      {...selected}
      onDelete={onDelete}
      onSubmit={onSubmit}
      loadMaterialBatchesContainers={loadMaterialBatchesContainers}
      loadMaterialLotsContainers={loadMaterialLotsContainers}
    />
  );
};

export default StockContainer;
