import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import Tooltip from 'rapidfab/components/Tooltip';

const Labels = ({ labelUris, labelsByUri }) => (
  <p className="mb0 d-flex flex-wrap">
    {labelUris.map(labelUri => {
      const label = labelsByUri[labelUri];
      if (!label) return null;
      const { name, color } = label;
      return (
        <Badge
          className="spacer-right badge-sm hawking-badge-secondary mb-1 line-clamp-1 text-wrap py-0 w-fit-content text-start"
          key={labelUri}
          style={{ lineHeight: 1.7 }}
        >
          {color && (
            <span
              className="small-status-dot spacer-right"
              style={{ backgroundColor: color }}
            />
          )}
          {name}
        </Badge>
      );
    })}
  </p>
);
Labels.propTypes = {
  labelsByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
  })).isRequired,
  labelUris: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const TableCellLabels = ({ labelUris, labelsByUri }) => {
  const firstThreeLabelUris = labelUris.slice(0, 3);
  const restLabelUris = labelUris.slice(3);
  return (
    <div className="d-flex align-items-center">
      <Labels
        labelUris={firstThreeLabelUris}
        labelsByUri={labelsByUri}
      />
      {restLabelUris.length ? (
        <Tooltip
          id="labels-overflow"
          trigger={<div>{`+${restLabelUris.length}`}</div>}
        >
          <Labels
            labelUris={restLabelUris}
            labelsByUri={labelsByUri}
          />
        </Tooltip>
      ) : null}
    </div>
  );
};

TableCellLabels.defaultProps = {
  labelsByUri: {},
  labelUris: [],
};

TableCellLabels.propTypes = {
  labelsByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
  })),
  labelUris: PropTypes.arrayOf(PropTypes.string),
};

export default TableCellLabels;
