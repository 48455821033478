import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import getShortUUID from 'rapidfab/utils/getShortUUID';

import Fa from 'react-fontawesome';
import {
  Col, Row,
  ListGroup,
  ListGroupItem,
  Card,
  Button, FormControl,
  Form, InputGroup,
  OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import _get from 'lodash/get';
import Actions from 'rapidfab/actions';
import {
  ORDER_UNLOCKED_STATUSES,
  ORDER_QUOTE_DISCOUNT_TYPES,
  ORDER_QUOTE_STATUS,
  USER_HIDE_INFO_TYPES, API_RESOURCES,
} from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';
import * as Selectors from 'rapidfab/selectors';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import OrderQuoteExportButton from 'rapidfab/components/records/order/edit/OrderQuoteExportButton';
import extractUuid from 'rapidfab/utils/extractUuid';
import {
  FormControlTextArea,
  FormControlSelect,
  CustomFormControlCost,
} from 'rapidfab/components/formTools';
import { ORDER_QUOTE_DISCOUNT_TYPE_MAP } from 'rapidfab/mappings';
import { FormattedCost, FormattedMessageMappingOption } from 'rapidfab/i18n';
import { getRouteUUIDResource, getLineItemsForOrder } from 'rapidfab/selectors';
import OrderQuoteDownloadModal from './OrderQuoteDownloadModal';

class OrderQuote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerPo: '',
      discountType: ORDER_QUOTE_DISCOUNT_TYPES.AMOUNT,
      discount: '',
      notes: '',
      markup: null,
      orderQuoteApproved: false,
      showOrderQuoteDownload: false,
      /**
       * loadingReport means that offer object has been created, but
       * not generated yet (in background process)
       */
      loadingReport: false,
      /**
       * isNewOfferCreated means that offer has
       * been created in current component lifecycle
       */
      isNewOfferCreated: false,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.createOrderQuote = this.createOrderQuote.bind(this);
    this.toggleQuoteFeature = this.toggleQuoteFeature.bind(this);
    this.getNewOrderQuoteURI = this.getNewOrderQuoteURI.bind(this);
    this.isVisibleLatestOrderQuoteButton = this.isVisibleLatestOrderQuoteButton.bind(this);
    this.toggleOrderQuoteDownloadModal = this.toggleOrderQuoteDownloadModal.bind(this);
    this.getMaxDiscount = this.getMaxDiscount.bind(this);
  }

  componentDidMount() {
    const { dispatch, order } = this.props;

    if (order) {
      const initialFields = {};

      if (order.quote_required) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER_QUOTE].list({
          order: order.uri,
        }));
      }

      if (order.customer_po) {
        initialFields.customerPo = order.customer_po;
      }

      if (order.quote_discount_type) {
        initialFields.discountType = order.quote_discount_type;
      }

      if (order.quote_discount_value) {
        initialFields.discount = order.quote_discount_value;
      }

      if (order.quote_approved) {
        initialFields.orderQuoteApproved = order.quote_approved;
      }

      if (order.quote_notes) {
        initialFields.notes = order.quote_notes;
      }

      if (order.quote_markup_value) {
        initialFields.markup = order.quote_markup_value;
      }

      if (Object.keys(initialFields).length > 0) {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState(initialFields);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    const { dispatch, download } = this.props;

    if (
      props.order.quote_required &&
        props.order.quote_required !== prevProps.order.quote_required
    ) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER_QUOTE].list({
        order: props.order.uri,
      }));
    }

    if (props.order) {
      const updatedFields = {};
      if (props.order.customer_po !== prevProps.order.customer_po) {
        updatedFields.customerPo = props.order.customer_po;
      }
      if (props.order.quote_discount_type !== prevProps.order.quote_discount_type) {
        updatedFields.discountType = props.order.quote_discount_type;
      }
      if (props.order.quote_discount_value !== prevProps.order.quote_discount_value) {
        updatedFields.discount = props.order.quote_discount_value;
      }
      if (props.order.quote_approved !== prevProps.order.quote_approved) {
        updatedFields.orderQuoteApproved = props.order.quote_approved;
      }
      if (props.order.quote_notes !== prevProps.order.quote_notes) {
        updatedFields.notes = props.order.quote_notes;
      }
      if (props.order.quote_markup_value !== prevProps.order.quote_markup_value) {
        updatedFields.markup = props.order.quote_markup_value;
      }
      if (Object.keys(updatedFields).length > 0) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(updatedFields);
      }
    }

    if (
      props.orderQuote &&
      prevProps.orderQuote &&
      props.orderQuote.status !== prevProps.orderQuote.status && props.orderQuote.status === ORDER_QUOTE_STATUS.COMPLETE
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loadingReport: false,
        isNewOfferCreated: true,
      });

      if (download) {
        window.location = download;
      }
    }
  }

  onInputChange(event) {
    const { type, checked, name } = event.target;
    let { value } = event.target;

    value = type === 'checkbox' ? checked : value;

    this.setState({ [name]: value });
  }

  onFormSubmit(event) {
    const { customerPo, orderQuoteApproved, notes, markup, discountType } = this.state;
    let { discount } = this.state;

    event.preventDefault();
    event.stopPropagation();

    if (discount === '') {
      discount = null;
    }

    if (discount) {
      discount = Number.parseFloat(Number.parseFloat(discount).toFixed(2));
    }

    const data = {
      customer_po: customerPo,
      quote_discount_type: discountType,
      quote_discount_value: discount,
      quote_approved: orderQuoteApproved,
      quote_notes: notes,
      quote_markup_value: Number.parseFloat(markup) || 0,
    };

    this.updateOrder(data);
  }

  getNewOrderQuoteURI() {
    /**
     * Returns boolean if order is generated and ready to download
     * Don't mess with `isNewOfferCreated()` method.
     * This method also checks isNewOfferCreated state value which can be true
     * only if object is generated in current component lifecycle
     * (after refreshing of app it should be new component lifecycle)
     */

    const { download } = this.props;
    const { loadingReport, isNewOfferCreated } = this.state;

    if (!isNewOfferCreated || loadingReport) {
      return '';
    }

    return download;
  }

  getMaxDiscount() {
    return this.state.discountType === ORDER_QUOTE_DISCOUNT_TYPES.PERCENTAGE ? 100 : null;
  }

  isVisibleLatestOrderQuoteButton() {
    /**
     * Returns boolean if last generated order is available to download
     */

    const { orderQuoteDocuments } = this.props;
    const { loadingReport } = this.state;
    return !loadingReport && !!orderQuoteDocuments.length;
  }

  isUnconfirmedOrder() {
    /**
     * Returns true if order isn't confirmed yet
     */

    const { order } = this.props;
    const { status } = order;
    return ORDER_UNLOCKED_STATUSES.includes(status);
  }

  createOrderQuote() {
    /**
     * Generates request for new order quote object
     * and then performs list request to add this object to store
     */

    const { dispatch, order } = this.props;

    this.setState({
      loadingReport: true,
    });

    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER_QUOTE].post({
      order: order.uri,
    })).then(response => {
      const { location } = response.headers;
      const uuid = extractUuid(location);
      dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER_QUOTE].get(uuid, true));
    })
      .catch(error => {
        console.error('Error while creating order quote', error);
        this.setState({
          loadingReport: false,
        });
      });
  }

  updateOrder(updatedFields) {
    /**
     * Updates order object with data from `updatedFields` argument
     */

    const { dispatch, order } = this.props;

    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].put(order.uuid, updatedFields))
      .then(() => dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(order.uuid)))
      .catch(error => {
        console.error('Error while updating order', error);
      });
  }

  toggleQuoteFeature() {
    /**
     * Used only for swapping `quote_required` field with opposite
     */

    const { order } = this.props;
    const isConfirmedOrder = !this.isUnconfirmedOrder();
    if (isConfirmedOrder) {
      return false;
    }

    const updatedFields = {
      quote_required: !order.quote_required,
    };

    this.updateOrder(updatedFields);
    return true;
  }

  toggleOrderQuoteDownloadModal() {
    const { showOrderQuoteDownload } = this.state;
    this.setState({ showOrderQuoteDownload: !showOrderQuoteDownload });
  }

  render() {
    const {
      order,
      submitting,
      orderQuoteDocuments,
      notesPlaceholder,
      bureauCustomPrice,
      currency,
      hideFinancial,
      orderLineItems,

    } = this.props;
    const {
      loadingReport,
      customerPo,
      orderQuoteApproved,
      discountType,
      discount,
      notes,
      markup,
      showOrderQuoteDownload,
    } = this.state;

    const orderQuoteURI = this.getNewOrderQuoteURI();
    const isVisibleLatestOrderQuoteButton = this.isVisibleLatestOrderQuoteButton();
    const isConfirmedOrder = !this.isUnconfirmedOrder();

    const bureauDiscountedCustomPrice = bureauCustomPrice && ((bureauCustomPrice / 100) * discount);
    const allLineItemsHasWorkflow = orderLineItems.every(lineItem => lineItem.workflow);

    if (submitting) {
      return (
        <Card bg="dark">
          <Loading />
        </Card>
      );
    }

    if (!order.quote_required && isConfirmedOrder) {
      return (
        <Card bg="dark" border="primary">
          <Card.Header>
            <ListGroup>
              <ListGroupItem style={{ border: 'none' }} className="orderQuoteRequired">
                <Row className="align-items-center">
                  <Col xs={12} sm={11}>
                    <b>Order Quote Required</b>
                  </Col>
                  <Col xs={12} sm={1} className="text-right">
                    <Fa
                      role="button"
                      size="lg"
                      name="plus-circle"
                      style={{ cursor: 'not-allowed', opacity: 0.4 }}
                      disabled
                    />
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card.Header>
          <div className="card-body-wrapper-accent">
            <Card.Body>
              <FormattedMessage
                id="orderQuote.confirmedOrder"
                defaultMessage="An order quote cannot be set because the order has been confirmed."
              />
            </Card.Body>
          </div>
        </Card>
      );
    }

    return (
      <Card bg="dark" border="primary">
        <ListGroup fill style={{ border: 'transparent' }}>
          <ListGroupItem className="orderQuoteRequired">
            <Row className="align-items-center p-1" role="button">
              <Col xs={12} sm={10}>
                <b>Order Quote Required</b>
              </Col>
              <Col xs={12} sm={2} className="text-right">
                <Fa
                  role="button"
                  size="lg"
                  name={order.quote_required ? 'minus-circle' : 'plus-circle'}
                  style={{ color: order.quote_required ? 'red' : 'auto' }}
                  onClick={this.toggleQuoteFeature}
                  disabled={(isConfirmedOrder || !allLineItemsHasWorkflow)}
                />
              </Col>
              {!allLineItemsHasWorkflow && (
                <Col xs={12} sm={1}>
                  <OverlayTrigger
                    placement="top"
                    overlay={(
                      <Tooltip>
                        <FormattedMessage
                          id="orderQuoteToolTip"
                          defaultMessage="Please assign workflows for the following Line Items so that you can create an Order Quote: {name} - ({id})"
                          values={{ name: order.name, id: getShortUUID(order.uuid) }}
                        />
                      </Tooltip>
                    )}
                  >
                    <Fa className="spacer-left spacer-right" name="question-circle" />
                  </OverlayTrigger>
                </Col>
              )}
            </Row>
          </ListGroupItem>
        </ListGroup>

        {
          order.quote_required && (
            <Form onSubmit={this.onFormSubmit}>
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  {!hideFinancial && (
                    <OrderQuoteExportButton
                      download={orderQuoteURI}
                      loadingReport={loadingReport}
                      onExport={this.createOrderQuote}
                      disabled={isConfirmedOrder}
                      pullToLeft={false}
                    />
                  )}

                  {isVisibleLatestOrderQuoteButton && (
                    <>
                      <Button
                        variant="info"
                        className="pull-right mt15 mr15"
                        onClick={this.toggleOrderQuoteDownloadModal}
                      >
                        <FormattedMessage
                          id="lastVersion"
                          defaultMessage="Last Version"
                        />
                      </Button>
                      <OrderQuoteDownloadModal
                        close={this.toggleOrderQuoteDownloadModal}
                        orderQuoteDocuments={orderQuoteDocuments}
                        show={showOrderQuoteDownload}
                      />
                    </>
                  )}
                </div>

                {(isVisibleLatestOrderQuoteButton || !hideFinancial) && (
                  <hr />
                ) }

                <ListGroup fill className="p-a-sm">
                  <ListGroupItem>
                    <Row>
                      <Col xs={4}>
                        <FormattedMessage
                          id="quoteOrderAccepted"
                          defaultMessage="Quote Order Accepted"
                        />:
                      </Col>
                      <Col xs={8}>
                        <input
                          checked={orderQuoteApproved}
                          onChange={this.onInputChange}
                          type="checkbox"
                          name="orderQuoteApproved"
                          disabled={isConfirmedOrder}
                        />
                      </Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem>
                    <Row>
                      <Col xs={4}>
                        <FormattedMessage
                          id="field.customer_po"
                          defaultMessage="Customer PO"
                        />{orderQuoteApproved && '*'}:
                      </Col>
                      <Col xs={8}>
                        <FormControl
                          required={orderQuoteApproved}
                          name="customerPo"
                          value={customerPo}
                          onChange={this.onInputChange}
                          disabled={isConfirmedOrder}
                        />
                      </Col>
                    </Row>
                  </ListGroupItem>

                  {!hideFinancial && (
                    <>
                      <ListGroupItem>
                        <Row>
                          <Col xs={4}>
                            <FormattedMessage
                              id="field.quote_discount_value"
                              defaultMessage="Discount"
                            />:
                          </Col>
                          <Col xs={4}>
                            <FormControlSelect
                              id="discountType"
                              name="discountType"
                              value={discountType}
                              onChange={this.onInputChange}
                              disabled={isConfirmedOrder}
                            >
                              {Object.keys(ORDER_QUOTE_DISCOUNT_TYPE_MAP).map(type => (
                                <FormattedMessageMappingOption
                                  key={type}
                                  mapping={ORDER_QUOTE_DISCOUNT_TYPE_MAP}
                                  value={type}
                                />
                              ))}
                            </FormControlSelect>
                          </Col>
                          <Col xs={4}>
                            {discountType === ORDER_QUOTE_DISCOUNT_TYPES.PERCENTAGE && (
                              <FormControl
                                type="number"
                                min="0"
                                max={this.getMaxDiscount()}
                                name="discount"
                                value={discount}
                                onChange={this.onInputChange}
                                disabled={isConfirmedOrder}
                              />
                            )}
                            {discountType === ORDER_QUOTE_DISCOUNT_TYPES.AMOUNT && (
                              <CustomFormControlCost
                                type="number"
                                min="0"
                                max={this.getMaxDiscount()}
                                name="discount"
                                value={discount}
                                onChange={this.onInputChange}
                                disabled={isConfirmedOrder}
                                currency={currency}
                              />
                            )}
                          </Col>
                        </Row>
                        {discountType === ORDER_QUOTE_DISCOUNT_TYPES.PERCENTAGE && (
                          <Row>
                            <Col xs={4}>
                              <FormattedMessage
                                id="field.quote_discount_estimation"
                                defaultMessage="Discount Est."
                              />:
                            </Col>
                            <Col xs={8}>
                              {bureauDiscountedCustomPrice ? (
                                <FormattedCost currency={currency} value={bureauDiscountedCustomPrice} />
                              ) : (
                                <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                              )}
                            </Col>
                          </Row>
                        )}
                      </ListGroupItem>

                      <ListGroupItem>
                        <Row>
                          <Col xs={4}>
                            <FormattedMessage
                              id="field.markup"
                              defaultMessage="Mark-up"
                            />:
                          </Col>
                          <Col xs={8}>
                            <InputGroup>
                              <Form.Control
                                name="markup"
                                type="number"
                                as="input"
                                onChange={this.onInputChange}
                                disabled={isConfirmedOrder}
                                value={markup}
                              />
                              <InputGroup.Text id="markup-addon">%</InputGroup.Text>
                            </InputGroup>
                          </Col>
                        </Row>
                      </ListGroupItem>

                      <ListGroupItem>
                        <Row>
                          <Col xs={4}>
                            <FormattedMessage
                              id="field.notes"
                              defaultMessage="Notes"
                            />:
                          </Col>
                          <Col xs={8}>
                            <FormControlTextArea
                              required={orderQuoteApproved}
                              name="notes"
                              value={notes}
                              placeholder={notesPlaceholder}
                              onChange={this.onInputChange}
                              disabled={isConfirmedOrder}
                            />
                          </Col>
                        </Row>
                      </ListGroupItem>
                    </>
                  )}

                  {
                    (!isConfirmedOrder && !hideFinancial) && (
                      <ListGroupItem>
                        <Row>
                          <Col xs={{ span: 8, offset: 4 }}>
                            <Button
                              type="submit"
                              disabled={submitting}
                              variant="success"
                            >
                              <SaveButtonTitle resourceName="Order Quote" />
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    )
                  }

                </ListGroup>
              </div>
            </Form>
          )
        }
      </Card>
    );
  }
}

OrderQuote.defaultProps = {
  download: null,
  submitting: false,
  orderQuoteDocuments: [],
  orderQuote: null,
  bureauCustomPrice: null,
  currency: 'USD',
};

OrderQuote.propTypes = {
  dispatch: PropTypes.func.isRequired,
  order: PropTypes.shape({
    status: PropTypes.string,
    quote_required: PropTypes.bool,
    quote_approved: PropTypes.bool,
    quote_discount_type: PropTypes.string,
    quote_discount_value: PropTypes.number,
    customer_po: PropTypes.string,
    quote_notes: PropTypes.string,
    quote_markup_value: PropTypes.string,
    uri: PropTypes.string,
    uuid: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  orderQuote: PropTypes.shape({
    status: PropTypes.string,
  }),
  submitting: PropTypes.bool,
  download: PropTypes.string,
  orderQuoteDocuments: PropTypes.arrayOf(PropTypes.shape({})),
  notesPlaceholder: PropTypes.string.isRequired,
  bureauCustomPrice: PropTypes.number,
  currency: PropTypes.string,
  hideFinancial: PropTypes.bool.isRequired,
  orderLineItems: PropTypes.arrayOf(PropTypes.shape({ workflow: PropTypes.string })).isRequired,
};

const mapStateToProps = state => {
  const order = getRouteUUIDResource(state);
  const orderLineItems = getLineItemsForOrder(state, order);
  const currency = Selectors.getBureauDefaultCurrency(state);
  const bureauCustomPrice = _get(order, 'estimates.total_price', null);
  const orderQuoteDocuments = Selectors.getOrderQuoteDocumentsForOrder(state, order);
  const orderQuote = Selectors.getLatestOrderQuoteDocumentForOrder(state, order);
  const download = orderQuote && orderQuote.content;

  const submitting = state.ui.nautilus[API_RESOURCES.ORDER].put.fetching;

  const bureauSettings = Selectors.getBureauSettings(state);
  const role = Selectors.getCurrentUserRoleMax(state);
  const hideFinancial = role?.hide_info === USER_HIDE_INFO_TYPES.FINANCIAL;

  return {
    order,
    orderQuote,
    download,
    submitting,
    orderQuoteDocuments,
    notesPlaceholder: bureauSettings ? bureauSettings.order_quote_notes_placeholder : '',
    currency,
    bureauCustomPrice,
    hideFinancial,
    orderLineItems,
  };
};

export default connect(mapStateToProps)(OrderQuote);
