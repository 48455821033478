import planDA from './plan/da.json';
import workDA from './work/da.json';
import inventoryDA from './inventory/da.json';
import manageDA from './manage/da.json';
import profileDA from './profile/da.json';
import breadcrumbsDA from './breadcrumbs/da.json';

import planDE from './plan/de.json';
import workDE from './work/de.json';
import inventoryDE from './inventory/de.json';
import manageDE from './manage/de.json';
import profileDE from './profile/de.json';
import breadcrumbsDE from './breadcrumbs/de.json';

import planEN from './plan/en.json';
import workEN from './work/en.json';
import inventoryEN from './inventory/en.json';
import manageEN from './manage/en.json';
import profileEN from './profile/en.json';
import breadcrumbsEN from './breadcrumbs/en.json';

import planFR from './plan/fr.json';
import workFR from './work/fr.json';
import inventoryFR from './inventory/fr.json';
import manageFR from './manage/fr.json';
import profileFR from './profile/fr.json';
import breadcrumbsFR from './breadcrumbs/fr.json';

import planJA from './plan/ja.json';
import workJA from './work/ja.json';
import inventoryJA from './inventory/ja.json';
import manageJA from './manage/ja.json';
import profileJA from './profile/ja.json';
import breadcrumbsJA from './breadcrumbs/ja.json';

export const navbarDA = Object.assign(planDA, workDA, inventoryDA, manageDA, profileDA, breadcrumbsDA);

export const navbarDE = Object.assign(planDE, workDE, inventoryDE, manageDE, profileDE, breadcrumbsDE);

export const navbarEN = Object.assign(planEN, workEN, inventoryEN, manageEN, profileEN, breadcrumbsEN);

export const navbarFR = Object.assign(planFR, workFR, inventoryFR, manageFR, profileFR, breadcrumbsFR);

export const navbarJA = Object.assign(planJA, workJA, inventoryJA, manageJA, profileJA, breadcrumbsJA);
