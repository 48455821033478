import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _find from 'lodash/find';

import * as Selectors from 'rapidfab/selectors';
import userSort from 'rapidfab/utils/userSort';

import EditOrderForm from 'rapidfab/components/records/order/edit/EditOrderForm';
import {
  API_RESOURCES,
  FEATURES,
  LINE_ITEM_PRODUCTION_STAGE_STATUSES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  USER_ROLES,
} from 'rapidfab/constants';
import extractUuid from 'rapidfab/utils/extractUuid';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import { getCurrentUserRole } from 'rapidfab/selectors';

const EditOrderFormContainer = props => {
  const { initialValues, formValues } = props;
  const { created } = useSelector(Selectors.getRouteUUIDResource);
  const creatorName = useSelector(Selectors.getOrderCreator);
  const shippings = useSelector(Selectors.getShippingsAlphabetized);
  const users = useSelector(Selectors.getUsers).sort(userSort);
  const isRestricted = useSelector(Selectors.isCurrentUserRestricted);
  const locationsAvailable = useSelector(Selectors.getLocationsForOrder);
  const order = useSelector(Selectors.getRouteUUIDResource);
  const creator = useSelector(state =>
    order && Selectors.getUUIDResource(state, extractUuid(order.user)));
  const creatorRoles = useSelector(state => Selectors.getUserRoles(state, creator));
  const isCreatorGuest = !!_find(creatorRoles, { role: 'self-registered-guest' });
  const [fetchedUsers, setFetchedUsers] = React.useState(false);
  const currentUserRole = useSelector(getCurrentUserRole);
  const isManager = currentUserRole === USER_ROLES.MANAGER;

  const lineItems = useSelector(state =>
    Selectors.getLineItemsForOrder(state, order));
  const hasLineItemsInProductionStage = lineItems.some(
    lineItem => LINE_ITEM_PRODUCTION_STAGE_STATUSES.includes(lineItem.status),
  );

  const customOrderFieldReferences = useSelector(Selectors.getCustomOrderFieldReferences);
  const isOrderBusinessSegmentFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.ORDER_BUSINESS_SEGMENT));
  const isBoeingOrderFieldsFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.BOEING_ORDER_FIELDS));
  const is3MOrderFieldsFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.THREE_M_MAIN_BUREAU_ORDER_FIELDS));
  const isBeehiveOrderFieldsFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.BEEHIVE3D_ORDER_FIELDS));

  const selected = {
    created,
    creatorName,
    customOrderFieldReferences,
    isRestricted,
    shippings,
    users,
    locationsAvailable,
    isOrderBusinessSegmentFeatureEnabled,
    isBoeingOrderFieldsFeatureEnabled,
    is3MOrderFieldsFeatureEnabled,
    isBeehiveOrderFieldsFeatureEnabled,
    order,
    isCreatorGuest,
    formValues,
    initialValues,
    hasLineItemsInProductionStage,
    fetchedUsers,
  };

  const dispatch = useDispatch();

  const handleGetOrderOwner = () => {
    if (!order?.order_owner) {
      return null;
    }
    return dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].get(extractUuid(order.order_owner)));
  };

  const fetchAllUsers = useCallback(() =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list(
      (isManager ? { archived: false } : {}), { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
    ))
      .then(() => setFetchedUsers(true)), [dispatch]);

  const dispatched = {
    initCustomFieldValues: props.initCustomFieldValues,
    onCustomFieldChange: props.onCustomFieldChange,
    handleGetOrderOwner,
    fetchAllUsers,
  };

  return (
    <EditOrderForm
      {...props}
      {...selected}
      {...dispatched}
    />
  );
};

EditOrderFormContainer.propTypes = {
  initialValues: PropTypes.shape({}).isRequired,
  formValues: PropTypes.shape({}).isRequired,
  initCustomFieldValues: PropTypes.func.isRequired,
  onCustomFieldChange: PropTypes.func.isRequired,
};

export default EditOrderFormContainer;
