import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';

import Fa from 'react-fontawesome';
import { Form, ListGroup, ListGroupItem } from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';

const User = ({
  user,
  roles,
  handleSelectionChange,
  isUserSelected,
  multiSelect,
}) => {
  const [isSelecting, setIsSelecting] = useState(false);

  const handleUserSelection = async event => {
    event.preventDefault();
    setIsSelecting(true);
    await handleSelectionChange(user.uri);
    setIsSelecting(false);
  };

  const isGuest = roles && !!_find(roles, { role: 'self-registered-guest' });

  return (
    <ListGroupItem
      className={`clearfix ${isUserSelected ? 'active' : ''}`}
      key={user.uuid}
      onClick={handleUserSelection}
    >
      {isSelecting && (<Loading className="pull-left" inline />)}
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {multiSelect && !isSelecting && (
            <Form.Check
              className="pull-left m-t-0 m-b-0 spacer-right"
              checked={isUserSelected}
              // Click handled is needed to show cursor and prevent warnings.
              // List group item onClick handled is used instead
              onClick={() => {}}
              type="checkbox"
            />
          )}
          <Fa className="pull-left" name="user" size="2x" />
        </div>
        {isGuest && (
          <span className="label label-info pull-left">Guest</span>
        )}
        <span className="pull-right">{user.name || user.username}</span>
      </div>
    </ListGroupItem>
  );
};

User.propTypes = {
  handleSelectionChange: PropTypes.func.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
    uuid: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }).isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isUserSelected: PropTypes.bool.isRequired,
  multiSelect: PropTypes.bool.isRequired,
};

const Users = ({
  users,
  rolesGroupedByUsername,
  handleSelectionChange,
  selectedUsersURIs,
  multiSelect,
  readOnly,
}) => (
  <ListGroup>
    {users.map(user => (
      <User
        key={user.uri}
        user={user}
        roles={rolesGroupedByUsername[user.username]}
        handleSelectionChange={handleSelectionChange}
        multiSelect={!readOnly && multiSelect}
        isUserSelected={selectedUsersURIs.includes(user.uri)}
      />
    ))}
  </ListGroup>
);

Users.propTypes = {
  handleSelectionChange: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      username: PropTypes.string,
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
  ).isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
    uuid: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }).isRequired,
  rolesGroupedByUsername: PropTypes.shape({}).isRequired,
  selectedUsersURIs: PropTypes.arrayOf(PropTypes.string).isRequired,
  multiSelect: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default Users;
