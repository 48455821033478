import React from 'react';
import PropTypes from 'prop-types';
import Comments from './Comments';

const ReadOnlyComments = props => (
  <Comments
    {...props}
    isLoading={false}
    isSubmitting={false}
    onSubmit={null}
  />
);

ReadOnlyComments.defaultProps = {
  showCommentActionAssignment: false,
  showCommentRelatedResource: true,
};

ReadOnlyComments.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showCommentActionAssignment: PropTypes.bool,
  showCommentRelatedResource: PropTypes.bool,
};

export default ReadOnlyComments;
