import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  ButtonToolbar,
  Col,
  Form,
  FormControl,
  Row,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import Fa from 'react-fontawesome';
import Feature from 'rapidfab/components/Feature';
import FormRow from 'rapidfab/components/FormRow';
import FileInput from 'rapidfab/components/records/order/edit/FileInput';
import {
  FEATURES,
  WORKFLOW_USAGE_STATES,
  BEEHIVE_LINE_ITEM_CUSTOM_FIELDS,
  MODEL_UNITS,
} from 'rapidfab/constants';
import CustomFieldList from 'rapidfab/components/forms/CustomFieldList';
import CustomFieldComponent from 'rapidfab/components/forms/CustomFieldComponent';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import getVisibleCustomFieldReferencesWithOptions from 'rapidfab/utils/getVisibleCustomFieldReferencesWithOptions';
import getlineItemCustomFieldReferencesOverrides from 'rapidfab/utils/lineItemCustomFieldReferencesOverrides';
import { modelResourceType, orderResourceType } from 'rapidfab/types';
import ModalThreeScene from 'rapidfab/components/ModalThreeScene';
import _find from 'lodash/find';
import {
  MODEL_UNITS_MAP,
  MODEL_UNITS_MAP_IMPERIAL,
  MODEL_UNITS_MAP_METRIC,
  MODEL_UNITS_MAP_VIEW_MODEL_IN,
} from 'rapidfab/mappings';
import { FormattedMessageMappingOption } from 'rapidfab/i18n';
import VisibleFor from 'rapidfab/components/VisibleFor';
import SelectSingleLazy from 'rapidfab/components/forms/SelectSingleLazy';

const AddLineItemPresentation = ({
  model,
  baseMaterial,
  baseMaterials,
  customFieldValues,
  customLineItemFieldReferences,
  customOrderFieldReferences,
  documents,
  handleDocumentDelete,
  handleDocumentChange,
  handleInputChange,
  partName,
  customerId,
  onSubmit,
  onCustomFieldChange,
  notes,
  quantity,
  submitting,
  fetching,
  supportMaterial,
  supportMaterials,
  workflow,
  workflows,
  canSelectStrategies,
  supportStrategies,
  infillStrategies,
  supportStrategy,
  infillStrategy,
  options,
  isUserRestricted,
  modelFileUnits,
  modelUserUnits,
  order,
  locations,
  onFetchMoreWorkflows,
}) => {
  const isSubmittingButtonDisabled = submitting;
  const disableFormControls = submitting;

  const fieldOverrides = getlineItemCustomFieldReferencesOverrides({
    isNormalUser: !isUserRestricted,
  });

  const visiblelineItemCustomFieldReferencesWithOptions =
    getVisibleCustomFieldReferencesWithOptions({
      fieldReferences: customLineItemFieldReferences,
      fieldValues: customFieldValues,
      fieldOverrides,
      parentFieldReferences: customOrderFieldReferences,
      parentFieldValues: order.custom_field_values,
    });

  const revisionFieldReference = _find(customLineItemFieldReferences, {
    field_id: BEEHIVE_LINE_ITEM_CUSTOM_FIELDS.REVISION,
  });
  const revisionFieldValue = _find(
    customFieldValues,
    ({ custom_field }) =>
      revisionFieldReference && custom_field === revisionFieldReference.uri,
  );

  // Overlay callback is needed since tooltip has no access to intl
  const overlayCallback = tootltipContent => (
    <OverlayTrigger
      placement="left"
      overlay={
        <Tooltip id="locationFilteringMessage">{tootltipContent}</Tooltip>
      }
    >
      <Fa name="question-circle" className="spacer-left" />
    </OverlayTrigger>
  );

  return (
    <Form horizontal onSubmit={onSubmit}>
      <div>
        <FormRow id="field.model" defaultMessage="Model">
          <Form.Text>
            {model ? (
              <>
                {model.name}
                <div style={{ maxWidth: '200px' }}>
                  <ModalThreeScene
                    showMfgOrientationPanel={false}
                    snapshot={model.snapshot_content || 'LOADING'}
                    model={model.content}
                    unit={model.user_unit}
                    fileUnit={model.file_unit}
                    size={model.size}
                    volume={model.volume_mm}
                  />
                </div>
              </>
            ) : (
              <FormattedMessage
                id="record.itar"
                defaultMessage="No Design Upload (ITAR)"
              />
            )}
          </Form.Text>
        </FormRow>
        <div>
          <Feature featureName={FEATURES.LINE_ITEM_EXTENDED_DETAILS}>
            <>
              <FormRow id="field.partName" defaultMessage="Part Name">
                <FormControl
                  name="partName"
                  value={partName}
                  type="text"
                  onChange={handleInputChange}
                />
              </FormRow>
              <FormRow
                id={options.customerID.id}
                defaultMessage={options.customerID.defaultMessage}
              >
                <FormControl
                  name="customerId"
                  value={customerId}
                  type="text"
                  onChange={handleInputChange}
                />
              </FormRow>
            </>
          </Feature>
          {revisionFieldReference && (
            <CustomFieldComponent
              reference={revisionFieldReference}
              value={revisionFieldValue && revisionFieldValue.value}
              onChange={onCustomFieldChange}
              variant={CustomFieldComponent.variants.form}
            />
          )}
        </div>

        {options.baseMaterial.show && (
          <FormRow
            labelSuffix={baseMaterial && workflows.length === 0 && !fetching && (
              <OverlayTrigger
                overlay={(
                  <Tooltip>
                    <FormattedMessage
                      id="noMatchingWorkflowForMaterial"
                      defaultMessage="No workflow matches selected material at your current location."
                    />
                  </Tooltip>
                )}
                placement="top"
                show={baseMaterial && workflows.length === 0}
              >
                <Fa className="spacer-left" name="exclamation-circle" />
              </OverlayTrigger>
            )}
            id="field.material"
            defaultMessage="Material"
            isRequired
          >
            <div className="d-flex align-items-center">
              <SelectSingle
                name="baseMaterial"
                data={baseMaterials}
                value={baseMaterial}
                placeholder={baseMaterials.length > 0 ?
                  'Select material' :
                  'No materials available for this location'}
                handleOnChange={handleInputChange}
                required
                imitateOnChangeEvent
                disabled={disableFormControls}
              />
              <div className="spacer-left spacer-right">at</div>
              <SelectSingle
                name="location"
                data={locations}
                value={order.location}
                handleOnChange={() => {}}
                disabled
              />
              <FormattedMessage
                id="record.lineItem.locationFilterMessage"
                defaultMessage="The Materials you can select from are those Materials which are available at the Order's Location. To see other Materials that may be available, please change the Order's Location. To see All Materials available in your bureau, set the Order Location to Any."
              >
                {text => overlayCallback(text)}
              </FormattedMessage>
            </div>
          </FormRow>
        )}

        <FormRow id="modelFileUnits" defaultMessage="Model File Units" isRequired>
          <FormControl
            name="modelFileUnits"
            value={modelFileUnits}
            as="select"
            onChange={handleInputChange}
            required
          >
            <FormattedMessageMappingOption
              mapping={MODEL_UNITS_MAP}
              value="Automatic"
              key="automatic"
            />
            <optgroup label="Metric">
              {Object.keys(MODEL_UNITS_MAP_METRIC).map(modelUnit => (
                <FormattedMessageMappingOption
                  mapping={MODEL_UNITS_MAP_METRIC}
                  value={modelUnit}
                  key={modelUnit}
                />
              ))}
            </optgroup>
            <optgroup label="Imperial">
              {Object.keys(MODEL_UNITS_MAP_IMPERIAL).map(modelUnit => (
                <FormattedMessageMappingOption
                  mapping={MODEL_UNITS_MAP_IMPERIAL}
                  value={modelUnit}
                  key={modelUnit}
                />
              ))}
            </optgroup>
          </FormControl>
        </FormRow>

        <FormRow
          id="field.viewModelIn"
          defaultMessage="View Model In"
          isRequired
        >
          <FormControl
            name="modelUserUnits"
            value={modelUserUnits}
            as="select"
            onChange={handleInputChange}
            required
          >
            {Object.keys(MODEL_UNITS_MAP_VIEW_MODEL_IN).map(modelUnit => (
              <FormattedMessageMappingOption
                mapping={MODEL_UNITS_MAP_VIEW_MODEL_IN}
                value={modelUnit}
                key={modelUnit}
              />
            ))}
          </FormControl>
        </FormRow>

        {options.supportMaterial.show && (
          <FormRow id="field.supportMaterial" defaultMessage="Support Material">
            <SelectSingle
              name="supportMaterial"
              data={supportMaterials}
              value={supportMaterial}
              handleOnChange={handleInputChange}
              imitateOnChangeEvent
              disabled={disableFormControls}
            />
          </FormRow>
        )}

        <FormRow id="field.quantity" defaultMessage="Quantity" isRequired>
          <FormControl
            name="quantity"
            type="number"
            min="1"
            required
            onChange={handleInputChange}
            value={quantity}
            disabled={disableFormControls}
          />
        </FormRow>
        {options.workflow.show && (
          <FormRow
            id="workflow"
            defaultMessage="Production Workflow"
            isRequired={options.workflow.required}
          >
            <SelectSingleLazy
              name="workflow"
              dataTestId="lineItemBaseMaterialPresentation"
              placeholder={baseMaterial ? 'Choose...' : 'Please select a base material'}
              data={workflows}
              value={workflow}
              handleOnChange={handleInputChange}
              imitateOnChangeEvent
              required={options.workflow.required && options.workflow.show}
              disabled={disableFormControls}
              isOptionDisabledCallback={item => item.usage_state === WORKFLOW_USAGE_STATES.ARCHIVED}
              onFetchMore={onFetchMoreWorkflows}
            />
          </FormRow>
        )}

        <FormRow id="field.notes" defaultMessage="Notes">
          <FormControl
            name="notes"
            value={notes}
            as="textarea"
            placeholder={options.notes.placeholder}
            onChange={handleInputChange}
          />
        </FormRow>

        {options.infillStrategy.show && canSelectStrategies && (
          <Feature featureName={FEATURES.EXTERNAL_PRODUCTION_ESTIMATE}>
            <FormRow
              id="field.infill_strategy"
              defaultMessage="Infill Strategy"
            >
              <FormControl
                name="infill_strategy"
                as="select"
                onChange={handleInputChange}
                value={infillStrategy}
                disabled={disableFormControls}
              >
                {infillStrategies.map(infillStrategyOption => (
                  <option
                    key={infillStrategyOption.uri}
                    value={infillStrategyOption.uri}
                  >
                    {infillStrategyOption.name}
                  </option>
                ))}
              </FormControl>
            </FormRow>
          </Feature>
        )}

        {options.supportStrategy.show && canSelectStrategies && (
          <Feature featureName={FEATURES.EXTERNAL_PRODUCTION_ESTIMATE}>
            <FormRow
              id="field.support_strategy"
              defaultMessage="Support Strategy"
            >
              <FormControl
                name="support_strategy"
                as="select"
                onChange={handleInputChange}
                value={supportStrategy}
                disabled={disableFormControls}
              >
                {supportStrategies.map(supportStrategyOption => (
                  <option
                    key={supportStrategyOption.uri}
                    value={supportStrategyOption.uri}
                  >
                    {supportStrategyOption.name}
                  </option>
                ))}
              </FormControl>
            </FormRow>
          </Feature>
        )}

        <CustomFieldList
          customFieldReferences={
            visiblelineItemCustomFieldReferencesWithOptions
          }
          customFieldValues={customFieldValues}
          onChange={onCustomFieldChange}
        />

        <VisibleFor unrestricted>
          <Feature featureName={FEATURES.AUTORUN}>
            <FormRow id="field.autorun" defaultMessage="Auto-create Run">
              <Form.Check
                name="autorun"
                type="checkbox"
                onChange={handleInputChange}
                disabled={disableFormControls}
              />
            </FormRow>
          </Feature>
        </VisibleFor>

        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Col md={3}>
            <FileInput
              fileType={FileInput.fileTypes.document}
              handleFileChange={handleDocumentChange}
              disabled={disableFormControls}
            />
          </Col>
          <Col md={9}>
            {documents.map((document, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={String(index)}>
                {`${document.name} `}
                <Button
                  size="xs"
                  className="m-r"
                  onClick={() => handleDocumentDelete(index)}
                >
                  <Fa name="times" />
                </Button>
              </span>
            ))}
          </Col>
        </Row>

        <ButtonToolbar className="clearfix pull-right">
          <Button
            disabled={isSubmittingButtonDisabled}
            type="submit"
            variant="success"
            className="pull-right"
            style={{ marginTop: '2rem' }}
          >
            {' '}
            {submitting ? (
              <Fa name="spinner" spin />
            ) : (
              <FormattedMessage
                id="record.cadfile.saveDetails"
                defaultMessage="Save CAD File Details"
              />
            )}
          </Button>
        </ButtonToolbar>
      </div>
    </Form>
  );
};

const optionType = PropTypes.shape({
  id: PropTypes.string,
  defaultMessage: PropTypes.string,
  show: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
});

AddLineItemPresentation.propTypes = {
  baseMaterial: PropTypes.string,
  baseMaterials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customFieldValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customLineItemFieldReferences: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  customOrderFieldReferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleDocumentDelete: PropTypes.func.isRequired,
  handleDocumentChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  partName: PropTypes.string,
  customerId: PropTypes.string,
  notes: PropTypes.string,
  modelFileUnits: PropTypes.string,
  modelUserUnits: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modelLibrary: PropTypes.shape({
    name: PropTypes.string,
  }),
  onCustomFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  quantity: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  supportMaterial: PropTypes.string.isRequired,
  supportMaterials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  workflow: PropTypes.string,
  workflows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  canSelectStrategies: PropTypes.bool.isRequired,
  supportStrategy: PropTypes.string,
  infillStrategy: PropTypes.string,
  supportStrategies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  infillStrategies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  uploadModel: PropTypes.shape({
    name: PropTypes.string,
    percent: PropTypes.number,
  }),
  options: PropTypes.shape({
    partName: optionType,
    workflow: optionType,
    notes: optionType,
    customerID: optionType,
    modelFileUnits: optionType,
    modelUserUnits: optionType,
    baseMaterial: optionType,
    supportMaterial: optionType,
    infillStrategy: optionType,
    supportStrategy: optionType,
  }).isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  order: orderResourceType.isRequired,
  // Can be null when `no_model_upload` is selected
  model: modelResourceType,
  onFetchMoreWorkflows: PropTypes.func,
};

AddLineItemPresentation.defaultProps = {
  baseMaterial: null,
  uploadModel: null,
  notes: null,
  modelFileUnits: MODEL_UNITS.AUTOMATIC,
  modelUserUnits: MODEL_UNITS.AUTOMATIC,
  partName: null,
  customerId: null,
  modelLibrary: null,
  supportStrategy: null,
  infillStrategy: null,
  workflow: null,
  model: null,
  onFetchMoreWorkflows: null,
};

export default AddLineItemPresentation;
