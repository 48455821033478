import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { API_RESOURCES, QUOTE_PREVIEW_MODAL_FIELDS } from 'rapidfab/constants';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import QuoteProcessStepModal from 'rapidfab/components/records/order/edit/QuoteProcessStepModal';
import { convertHoursToSeconds } from 'rapidfab/utils/currentTime';
import _map from 'lodash/map';
import _find from 'lodash/find';
import { uniq } from 'lodash/array';

const QuoteProcessStepModalContainer = ({ close,
  pieces,
  show,
  savingLineItemQuote,
  processStep,
  lineItemQuote,
  saveQuoteDetails,
  updateAllTransformedSteps,
  fetching,
  setFetching,
  processSteps,
  handleSwitch,
  lineItemUri,
  fetchedScheduling,
  setFetchedScheduling,
  calculateTotalPricePerPiece }) => {
  const lineItemWorkstepEstimate = useSelector(Selectors.getLineItemWorkstepEstimatesByUri);
  const lineItemWorkstepCostEstimates = useSelector(Selectors.getLineItemWorkstepCostEstimates);
  const lineItemWorkstepCostEstimateForCurrentProcessStep =
    _find(lineItemWorkstepCostEstimates, { process_step: processStep.uuid });
  const usersByUri = useSelector(Selectors.getUsersByUri);
  const [editMode, setEditMode] = useState(false);
  const [fetchedUsers, setFetchedUsers] = useState(false);
  const [defaultData, setDefaultData] = useState([]);
  const [previewData, setPreviewData] = useState([...defaultData]);
  const [schedulingEstimatesEnabled, setSchedulingEstimatesEnabled] = useState(true);
  const [confirmClose, setConfirmClose] = useState(null);
  const [currentValue, setCurrentValue] = useState('');
  const [error, setError] = useState(false);

  const processStepChargeData = previewData.length ? previewData : defaultData;
  const lineItemWorkEstimateFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE].list.fetching);
  const lineItemQuoteFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].get.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].put.fetching);

  const fetchingUsers = useSelector(state => state.ui.nautilus[API_RESOURCES.USERS].list.fetching);

  const dispatch = useDispatch();

  const selected = {
    previewData,
    defaultData,
    processStepChargeData,
    editMode,
    currentValue,
    error,
    confirmClose,
    setConfirmClose,
    schedulingEstimatesEnabled,
    fetchingUsers,
  };

  const fetchUsers = (scheduledUsers, processStepsUsers) => {
    const scheduledUserUris = uniq(_map(scheduledUsers, 'updated_by').filter(Boolean));
    const processStepsUserUris = uniq(_map(processStepsUsers, 'updated_by').filter(Boolean));
    const allUsers = [...scheduledUserUris, ...processStepsUserUris];

    if (allUsers.length) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({ uri: allUsers }));
    }

    return setFetchedUsers(true);
  };

  useEffect(() => {
    if ((show && processStep?.data && Object.values(lineItemWorkstepEstimate)?.length) && !fetchedUsers) {
      fetchUsers(Object.values(lineItemWorkstepEstimate), _map(processSteps, 'work_steps_quote_details'));
    }
  }, [show, processStep?.data, fetchedUsers, lineItemWorkstepEstimate]);

  useEffect(() => {
    if (show && processStep?.data) {
      setDefaultData(processStep.data);
    }
  }, [show, processStep?.data]);

  useEffect(() => {
    if (show && processSteps.length && !fetchedScheduling) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE].list({
        line_item: lineItemUri,
        process_step: _map(processSteps, 'uri'),
      }));
      setFetchedScheduling(true);
    }
  }, [show, JSON.stringify(lineItemWorkstepEstimate), fetchedScheduling]);

  const toggleEditMode = () => {
    if (previewData.length) {
      if (error) {
        setError(false);
      }
      setPreviewData([]);
      setCurrentValue('');
    }
    setEditMode(previous => !previous);
  };

  const editModeOff = () => {
    if (previewData.length) {
      setPreviewData([]);
      setCurrentValue('');
    }
    setEditMode(false);
  };

  const onClose = () => {
    if (savingLineItemQuote) {
      // Prevent closing modal during save process
      return;
    }
    editModeOff();
    close();
  };

  const onCloseConfirm = () => {
    if (previewData.length) {
      return setConfirmClose(true);
    }
    return onClose();
  };

  const submitAdditionalCharges = useCallback(async () => {
    const dataToSend = previewData.filter(items => items.removable);
    if (dataToSend) {
      const transformSentData = dataToSend.map(item => ({
        charge_name: item.chargeName,
        charge_item_units: item.unit,
        unit_count: item.count,
        price_per_unit: item.pricePerUnit,
      }));

      const laborField = _find(previewData, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.LABOR });
      const workstationTimeField = _find(previewData, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.WORKSTATION_TIME });
      const pieceOverheadCostField = _find(previewData, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.PIECE_OVERHEAD_COST });
      const runOverheadCostField = _find(previewData, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.RUN_OVERHEAD_COST });

      /* Manually update the state for the current step in the key: `work_steps_quote_details`
      of `$SITE/line-item-quote/{...}` */
      const currentWorkstep =
        lineItemQuote.work_steps_quote_details.find(step => step.process_step === processStep.uuid);

      currentWorkstep.additional_charges = transformSentData;
      currentWorkstep.labor_price_per = laborField.price;
      currentWorkstep.workstation_price_per = workstationTimeField.price;
      currentWorkstep.overhead_cost_per_piece_in_run = pieceOverheadCostField?.pricePerUnit || 0;
      currentWorkstep.overhead_cost_per_run = runOverheadCostField?.pricePerUnit || 0;
      currentWorkstep.number_of_runs = runOverheadCostField?.count;

      return saveQuoteDetails(
        lineItemQuote.work_steps_quote_details,
        null,
        processStep,
        lineItemWorkstepCostEstimateForCurrentProcessStep,
      );
    }

    return null;
  }, [lineItemQuote.work_steps_quote_details, previewData, processStep.uuid, saveQuoteDetails]);

  const handleToggleSchedulingEstimates = async () => {
    const laborDuration = _find(processStepChargeData,
      { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.LABOR });
    const workstationDuration = _find(processStepChargeData,
      { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.WORKSTATION_TIME });
    const payload = {
      line_item: lineItemUri,
      process_step: processStep.uuid,
      labor_duration: convertHoursToSeconds(laborDuration?.count),
      workstation_duration: convertHoursToSeconds(workstationDuration?.count),
    };

    const workstep = lineItemWorkstepEstimate[processStep.uuid];

    if (workstep) {
      if (workstep.labor_duration === payload.labor_duration
        && workstep.workstation_duration === payload.workstation_duration) {
        // The durations are the same, so we can skip the update
        return null;
      }

      await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE]
        .put(lineItemWorkstepEstimate[processStep.uuid].uuid, payload));
      return setFetchedScheduling(false);
    }

    await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE].post(payload));
    return setFetchedScheduling(false);
  };

  const handleSubmit = async () => {
    setFetching(true);
    try {
      if (previewData.length) {
        await submitAdditionalCharges();
        setDefaultData(previewData);
        setPreviewData([]);
        updateAllTransformedSteps(processStep.id, previewData);
      }

      if (schedulingEstimatesEnabled) {
        /* If the checkbox is enabled -> we should either send POST and create a new resource,
             or PUT to update the existing one. */
        await handleToggleSchedulingEstimates();
      }

      if (!schedulingEstimatesEnabled && lineItemWorkstepEstimate[processStep.uuid]) {
        /* If the checkbox is disabled, but we already have the resource for this process step ->
             we should remove it by sending the DELETE request. */
        await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE]
          .delete(lineItemWorkstepEstimate[processStep.uuid].uuid));
        setFetchedScheduling(false);
      }

      toggleEditMode();
      setFetching(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setPreviewData(defaultData);
    } finally {
      setEditMode(false);
      setFetching(false);
      setFetchedScheduling(true);
    }
  };

  const onChangeUnit = (id, type, value) => {
    setCurrentValue({ [id]: {
      [type]: value,
    } });
  };

  const dispatched = {
    setDefaultData,
    setPreviewData,
    submitAdditionalCharges,
    onChangeUnit,
    onClose,
    onCloseConfirm,
    toggleEditMode,
    editModeOff,
    handleSubmit,
    setSchedulingEstimatesEnabled,
  };

  return (
    <QuoteProcessStepModal
      close={close}
      pieces={pieces}
      show={show}
      savingLineItemQuote={savingLineItemQuote}
      processStep={processStep}
      updateAllTransformedSteps={updateAllTransformedSteps}
      fetching={fetching}
      setFetching={setFetching}
      processSteps={processSteps}
      handleSwitch={handleSwitch}
      scheduledData={lineItemWorkstepEstimate[processStep.uuid]}
      usersByUri={usersByUri}
      workEstimateFetching={lineItemWorkEstimateFetching}
      lineItemQuoteFetching={lineItemQuoteFetching}
      calculateTotalPricePerPiece={calculateTotalPricePerPiece}
      {...selected}
      {...dispatched}
    />
  );
};

QuoteProcessStepModalContainer.defaultProps = {
  processStep: {},
};

QuoteProcessStepModalContainer.propTypes = {
  close: PropTypes.func.isRequired,
  pieces: PropTypes.number.isRequired,
  processStep: PropTypes.instanceOf(Object),
  show: PropTypes.bool.isRequired,
  saveQuoteDetails: PropTypes.func.isRequired,
  savingLineItemQuote: PropTypes.bool.isRequired,
  lineItemQuote: PropTypes.instanceOf(Object).isRequired,
  updateAllTransformedSteps: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  setFetching: PropTypes.func.isRequired,
  processSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSwitch: PropTypes.func.isRequired,
  lineItemUri: PropTypes.string.isRequired,
  fetchedScheduling: PropTypes.bool.isRequired,
  setFetchedScheduling: PropTypes.func.isRequired,
  calculateTotalPricePerPiece: PropTypes.func.isRequired,
};

export default QuoteProcessStepModalContainer;
