import React from 'react';
import PropTypes from 'prop-types';

import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import AddFromDB from 'rapidfab/containers/inventory/AddFromDBContainer';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';

import Table from 'rapidfab/components/Tables/Table';

const MaterialsGrid = ({ materials, externalMaterialDbFeatureEnabled }) => {
  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'material',
    },
    {
      type: 'text',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
    },
    {
      type: 'caps',
      uid: 'field.type',
      accessor: 'type',
      defaultMessage: 'Type',
      isSortable: true,
    },
    {
      type: 'color',
      uid: 'field.color',
      accessor: 'color',
      defaultMessage: 'Color',
      isSortable: true,
    },
    !externalMaterialDbFeatureEnabled && (
      {
        type: 'bool',
        id: 'field.thirdParty',
        accessor: 'third_party_fulfillment',
        defaultMessage: 'Third Party',
        isSortable: true,
      }
    ),
  ];

  return (
    <Table
      tableID="materials"
      data={materials}
      columns={columns}
      isFilteringEnabled
      withDefaultPagination
      isManualSoringEnabled={false}
      initialSortedColumn="name"
      initialSortedDesc={false}
      isUpdatedColumnShown={false}
    />
  );
};

MaterialsGrid.propTypes = {
  materials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  externalMaterialDbFeatureEnabled: PropTypes.bool.isRequired,
};

const Materials = ({ materials, fetching, externalMaterialDbFeatureEnabled }) => (
  <Container fluid>
    <BreadcrumbNav breadcrumbs={['manage', 'materials']} />

    <Row>
      <Col xs={12}>
        <AddFromDB
          type="material"
          buttonText={(
            <FormattedMessage
              id="record.material.add"
              defaultMessage="Add Material"
            />
          )}
          modalTitle={(
            <FormattedMessage
              id="materialdb.search"
              defaultMessage="Material Search"
            />
          )}
          itemLabel={<FormattedMessage id="field.material" defaultMessage="Material" />}
          manualCreateHref={getRouteURI(ROUTES.MATERIAL_NEW)}
          manualCreateText={(
            <FormattedMessage
              id="materialdb.notlisted"
              defaultMessage="My material isn’t listed"
            />
          )}
          className="pull-right"
        />
      </Col>
    </Row>

    <hr />

    <Row>
      <Col xs={12}>
        {fetching ? (
          <Loading />
        ) : (
          <MaterialsGrid
            materials={materials}
            externalMaterialDbFeatureEnabled={externalMaterialDbFeatureEnabled}
          />
        )}
      </Col>
    </Row>
  </Container>
);

Materials.propTypes = {
  fetching: PropTypes.bool.isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  externalMaterialDbFeatureEnabled: PropTypes.bool,
};

Materials.defaultProps = {
  externalMaterialDbFeatureEnabled: false,
};

export default Materials;
