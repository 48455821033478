import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';

import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import * as Selectors from 'rapidfab/selectors';

import ManufacturersComponent from 'rapidfab/components/manage/manufacturers';
import { API_RESOURCES } from 'rapidfab/constants';

const ManufacturersContainer = props => {
  const users = useSelector(Selectors.getUsers);
  const locations = useSelector(Selectors.getLocations);
  const manufacturers = useSelector(Selectors.getManufacturers);
  const fetching = useSelector(state => isFetchingInitial(state.ui.nautilus[API_RESOURCES.MANUFACTURER].list));

  const dispatch = useDispatch();
  const onInitialize = () => dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].list());

  useEffect(() => {
    (async () => onInitialize())();
  }, []);

  return (
    <ManufacturersComponent
      {...props}
      users={users}
      locations={locations}
      fetching={fetching}
      manufacturers={manufacturers}
    />
  );
};

export default ManufacturersContainer;
