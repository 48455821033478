import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getUUIDResource,
  getAssemblyPartMetaByAssemblyMetaUri,
  getAssemblyMetaForLineItem,
} from 'rapidfab/selectors';
import extractUuid from 'rapidfab/utils/extractUuid';
import CoPrintParts from 'rapidfab/components/records/order/edit/LineItem/CoPrintParts';
import { assemblyPartMetaResourceType } from 'rapidfab/types';

const CoPrintPartsContainer = ({ assemblyPartsMeta, isReadOnly }) => (
  <CoPrintParts
    assemblyPartsMeta={assemblyPartsMeta}
    isReadOnly={isReadOnly}
  />
);

CoPrintPartsContainer.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  coPrintUri: PropTypes.string.isRequired, // Used in mapStateToProps
  assemblyPartsMeta: PropTypes.arrayOf(assemblyPartMetaResourceType).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
  const { coPrintUri } = props;
  const coPrint = getUUIDResource(state, extractUuid(coPrintUri));
  const assemblyMeta = getAssemblyMetaForLineItem(state, coPrint);
  const assemblyPartsMetaByAssemblyMetaUri = getAssemblyPartMetaByAssemblyMetaUri(state);
  const assemblyPartsMeta = (assemblyMeta && assemblyPartsMetaByAssemblyMetaUri[assemblyMeta.uri]) || [];

  return {
    assemblyPartsMeta,
  };
};

export default connect(mapStateToProps)(CoPrintPartsContainer);
