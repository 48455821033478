import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Actions from 'rapidfab/actions';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import Fa from 'react-fontawesome';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import ResourceReadOnlyViewRow, {
  RESOURCE_READ_ONLY_VIEW_FIELD_TYPES,
} from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import { getMaterialPurchaseOrdersByUri } from 'rapidfab/selectors';
import extractUuid from 'rapidfab/utils/extractUuid';
import { API_RESOURCES, MATERIAL_LOT_STATUSES } from 'rapidfab/constants';
import { materialLotResourceType, materialPurchaseOrderResourceType } from 'rapidfab/types';
import _isEmpty from 'lodash/isEmpty';

const PanelHeader = ({ isNoPo, onOpenLinkModal, onOpenReceiveModal }) => (
  <div className="d-flex justify-content-between align-items-center">
    <div>
      <FormattedMessage
        id="materialLot.purchaseOrder"
        defaultMessage="Purchase Order"
      />
    </div>
    {isNoPo && (
      <div>
        <Button className="mr15" onClick={onOpenReceiveModal}>
          <FormattedMessage
            id="materialLot.receiveWithoutPo"
            defaultMessage="Receive without PO"
          />
        </Button>
        <Button variant="success" onClick={onOpenLinkModal}>
          <Fa name="plus" size="lg" className="spacer-right" />
          <FormattedMessage id="materialLot.addPo" defaultMessage="Add PO" />
        </Button>
      </div>
    )}
  </div>
);
PanelHeader.propTypes = {
  isNoPo: PropTypes.bool.isRequired,
  onOpenLinkModal: PropTypes.func.isRequired,
  onOpenReceiveModal: PropTypes.func.isRequired,
};

const MaterialLotPurchaseOrder = ({
  onOpenLinkModal,
  onOpenReceiveModal,
  materialPo,
  lot,
  onCancelPo,
  loading,
}) => {
  const { uri: lotUri, units, status } = lot;
  const { delivered_quantity, planned_full_quantity } = materialPo;
  const isOnOrder = status === MATERIAL_LOT_STATUSES.ON_ORDER;

  if (!isOnOrder) return null;

  if (loading) {
    return (
      <Card bg="dark">
        <Card.Header className="pd-exp inverse">Purchase Order</Card.Header>
        <div className="card-body-wrapper">
          <Card.Body>
            <div className="mt15 mb15 d-flex justify-content-center">
              <Fa name="spinner" spin />
            </div>
          </Card.Body>
        </div>
      </Card>
    );
  }

  return (
    <>
      <Card bg="dark">
        <Card.Header className="pd-exp inverse">
          <PanelHeader
            isNoPo={_isEmpty(materialPo)}
            onOpenLinkModal={onOpenLinkModal}
            onOpenReceiveModal={onOpenReceiveModal}
          />
        </Card.Header>
        <div className="card-body-wrapper">
          <Card.Body>
            {!_isEmpty(materialPo) && (
              <Row>
                <Col xs={12}>
                  <ResourceReadOnlyView entity={materialPo}>
                    <ResourceReadOnlyViewRow
                      name="external_id"
                      label="PO Number"
                    />
                    <ResourceReadOnlyViewRow
                      name="delivered_quantity"
                      label="Tracked Amount"
                      type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                      customValue={`${delivered_quantity} ${units || ''}`}
                    />
                    <ResourceReadOnlyViewRow
                      name="planned_full_quantity"
                      label="Planned Total Amount"
                      type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                      customValue={`${planned_full_quantity} ${units || ''}`}
                    />
                    <ResourceReadOnlyViewRow
                      name="created"
                      type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATE}
                    />
                  </ResourceReadOnlyView>
                  <Row className="mt30">
                    <Col xs={12} className="d-flex justify-content-end">
                      <Button onClick={() => onCancelPo(lotUri)} className="mr15">
                        <FormattedMessage
                          id="materialLot.cancelPo"
                          defaultMessage="Remove PO"
                        />
                      </Button>
                      <Button onClick={onOpenReceiveModal} variant="primary">
                        <FormattedMessage
                          id="materialLot.receiveLot"
                          defaultMessage="Receive Lot"
                        />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Card.Body>
        </div>
      </Card>
    </>
  );
};

MaterialLotPurchaseOrder.defaultProps = {
  materialPo: {},
};

MaterialLotPurchaseOrder.propTypes = {
  onOpenLinkModal: PropTypes.func.isRequired,
  onOpenReceiveModal: PropTypes.func.isRequired,
  materialPo: materialPurchaseOrderResourceType,
  lot: materialLotResourceType.isRequired,
  loading: PropTypes.bool.isRequired,
  onCancelPo: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const materialPoUri = props.lot.material_purchase_order;
  const materialPosByUri = getMaterialPurchaseOrdersByUri(state);
  const materialPo = materialPosByUri[materialPoUri];
  return {
    materialPo,
    loading:
      state.ui.nautilus[API_RESOURCES.MATERIAL_PURCHASE_ORDER].get.fetching ||
      state.ui.nautilus[API_RESOURCES.MATERIAL_LOT].get.fetching ||
      state.ui.nautilus[API_RESOURCES.MATERIAL_PURCHASE_ORDER].put.fetching ||
      state.ui.nautilus[API_RESOURCES.MATERIAL_LOT].put.fetching,
  };
};

const mapDispatchToProps = dispatch => ({
  onCancelPo: lotUri => {
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].put(
        extractUuid(lotUri),
        { material_purchase_order: null },
      ),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaterialLotPurchaseOrder);
