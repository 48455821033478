import { connect } from 'react-redux';

import Actions from 'rapidfab/actions';
import { API_RESOURCES, FEATURES, ORDER_DELETABLE_STATUSES } from 'rapidfab/constants';
import {
  getUsers,
  getLocationsForOrder,
  isFeatureEnabled,
  getShippingsAlphabetized,
  getCustomOrderFieldReferences,
  getRouteUUIDResource,
} from 'rapidfab/selectors';
import userSort from 'rapidfab/utils/userSort';

import EditOrderForm from 'rapidfab/components/records/order/edit/EditOrderForm';
import toISOStringWithoutTime from 'rapidfab/utils/toISOStringWithoutTime';

import { ORDER_SUMMARY_RESTRICTED } from 'rapidfab/constants/forms';

const mapDispatchToProps = dispatch => ({
  onSubmit: formValues => {
    const payload = formValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === 'none') {
        payload[key] = null;
      }
    });

    if (payload.due_date) {
      const date = new Date(payload.due_date);
      payload.due_date = date.toISOString();
    }

    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].put(payload.uuid, payload))
      .then(() => dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(payload.uuid)));
  },
});

const mapStateToProps = state => {
  const order = getRouteUUIDResource(state);
  const initialValues = order;

  // convert ISO date to yyyy-mm-dd for html input
  if (initialValues.due_date) {
    const date = new Date(initialValues.due_date);
    initialValues.due_date = toISOStringWithoutTime(date);
  }

  const users = getUsers(state).sort(userSort);
  const shippings = getShippingsAlphabetized(state);
  const locationsAvailable = getLocationsForOrder(state);
  // TODO: Historically only statuses from `deletable` list were used here.
  //  Anyway, looks like logically those should be ORDER_UNLOCKED_STATUSES
  //  Clarification task is sc-35748
  const inputsDisabled = order && !ORDER_DELETABLE_STATUSES.includes(order.status);

  const customOrderFieldReferences = getCustomOrderFieldReferences(state);

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => ORDER_SUMMARY_RESTRICTED.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  return {
    initialValues: initialFormValues,
    order,
    isRestricted: true,
    users,
    shippings,
    customOrderFieldReferences,
    locationsAvailable,
    isOrderBusinessSegmentFeatureEnabled: isFeatureEnabled(state, FEATURES.ORDER_BUSINESS_SEGMENT),
    is3MOrderFieldsFeatureEnabled: isFeatureEnabled(
      state,
      FEATURES.THREE_M_MAIN_BUREAU_ORDER_FIELDS,
    ),
    isBeehiveOrderFieldsFeatureEnabled: isFeatureEnabled(state, FEATURES.BEEHIVE3D_ORDER_FIELDS),
    inputsDisabled,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOrderForm);
