import React from 'react';
import PropTypes from 'prop-types';
import { XEROX_LOGIN_ROUTE_PREFIX, XEROX_BUSINESS_DOMAIN } from 'rapidfab/constants';
import { Image, Navbar } from 'react-bootstrap';
import ShiplineLogo from 'rapidfab/images/shipline-logo.png';
import AuthentiseLogo from 'rapidfab/images/logo-flat.png';
import { bureauResourceType } from 'rapidfab/types';
import { Link } from 'react-router-dom';
import { ROUTES } from 'rapidfab/constants/routes';
import _isEmpty from 'lodash/isEmpty';

const styles = {
  logo: { height: '30px', marginRight: '1rem', display: 'inline-block' },
  three_m_logo: { height: '30px', width: '180px', marginBottom: '10px' },
  hawking_logo: { height: '18px' },
};

const isHawkingLogin =
  window.location.hash.includes(XEROX_LOGIN_ROUTE_PREFIX) ||
  window.location.toString().includes(XEROX_BUSINESS_DOMAIN);

const HawkingNavLogo = () => (
  <div>
    <Image src={ShiplineLogo} alt="bureau logo" style={{ ...styles.hawking_logo, height: 40 }} />
  </div>
);

const StanleyXNavLogo = () => (
  <div>
    <Link to="/" href="/">
      <Image src={ShiplineLogo} alt="bureau logo" style={{ ...styles.hawking_logo, height: 45 }} />
    </Link>
  </div>
);

/* 👇🏼 To be removed when [sc-60174] has been merged and tested
const DanfossNavLogo = ({ isRestrictedUser }) => (
  <div>
    <Link to={isRestrictedUser ? '/digital-design-warehouse/restricted/home' : '/'}
    href={isRestrictedUser ? '/home' : '/'}>
      <Image src={DanfossLogo} alt="bureau logo" style={{ ...styles.hawking_logo, height: 45 }} />
    </Link>
  </div>
);
👆🏼 To be removed when [sc-60174] has been merged and tested */

const NavbarBranding = ({
  isAuthentisePDMUser,
  bureau,
  session,
  bureauBranding,
}) => {
  const bureauBrandingLogoExists = !_isEmpty(bureauBranding?.logo);

  let bureauName = 'Authentise';
  if (bureau) {
    bureauName = bureau.name;
  }

  // unlogged and at xerox route
  if (!session && isHawkingLogin) {
    return <HawkingNavLogo />;
  }

  // unlogged and at stanley route
  if (!session && window.location.hash.includes('stanley-select/login')) {
    return <StanleyXNavLogo />;
  }

  // unlogged and at non-xerox route
  if (!session || isAuthentisePDMUser) {
    return (
      <Navbar.Brand>
        <Image src={AuthentiseLogo} alt="Authentise" style={styles.logo} />{' '}Authentise
      </Navbar.Brand>
    );
  }

  /* 👇🏼 To be removed when [sc-60174] has been merged and tested
  if (isHawkingUser) {
    return <HawkingNavLogo />;
  }

  if (isDigitalDesignWarehouseFeatureEnabled) {
    return <DanfossNavLogo isRestrictedUser={isRestrictedUser} />;
  }

  if (isStanleyXUser) {
    return <StanleyXNavLogo />;
  }
  👆🏼 To be removed when [sc-60174] has been merged and tested */

  if (bureauBrandingLogoExists) {
    return (
      <Link to={ROUTES.HOME}>
        <Image src={bureauBranding?.logo_content} alt="bureau logo" style={{ ...styles.hawking_logo, height: 40 }} />
      </Link>
    );
  }

  return <Link to={ROUTES.HOME} className="navbar-brand">{bureauName}</Link>;
};

NavbarBranding.defaultProps = {
  bureau: null,
  session: null,
};

NavbarBranding.propTypes = {
  isAuthentisePDMUser: PropTypes.bool.isRequired,
  bureau: bureauResourceType,
  session: PropTypes.shape({}),
  bureauBranding: PropTypes.shape({
    logo_content: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
};

export default NavbarBranding;
