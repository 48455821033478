import { combineReducers } from 'redux';
import api from './api';
import downloadModel from './downloadModel';
import i18n from './i18n';
import locationFilter from './locationFilter';
import traceabilityReportFilters from './traceabilityReportFilters';
import submittedOrders from './submittedOrders';
import dynamicCurrency from './dynamicCurrency';
import modelRotation from './modelRotation';
import eventStream from './eventStream';
import pager from './pager';
import preferences from './preferences';
import routeUUID from './routeUUID';
import resources from './resources';
import uploadModel from './uploadModel';
import url from './url';
import ui from './ui';
import units from './units';
import changeImpersonationModal from './changeImpersonationModal';

import admin from './admin';

const Reducer = combineReducers({
  api,
  downloadModel,
  i18n,
  locationFilter,
  traceabilityReportFilters,
  submittedOrders,
  dynamicCurrency,
  modelRotation,
  eventStream,
  pager,
  preferences,
  resources,
  routeUUID,
  ui,
  uploadModel,
  url,
  units,
  changeImpersonationModal,
  admin,
});

export default Reducer;
