import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Fa from 'react-fontawesome';
import { Card } from 'react-bootstrap';
import Toggle from 'react-toggle';
import SmoothCollapse from 'react-smooth-collapse';
import Loading from 'rapidfab/components/Loading';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import Comment from 'rapidfab/components/comments/Comment';
import CommentForm from './CommentForm';

const PanelHeader = ({ collapsed, setCollapsed }) => {
  const onClickCollapse = () => setCollapsed(!collapsed);
  return (
    <div
      onClick={onClickCollapse}
      role="button"
      className="panel-header"
      tabIndex={0}
    >
      <FormattedMessage id="comments" defaultMessage="Comments" />
      <Fa name={collapsed ? 'chevron-down' : 'chevron-up'} />
    </div>
  );
};
PanelHeader.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
};

const ToggleCommentsSort = ({ isAscending, setIsAscending }) => {
  const onChangeToggle = () => setIsAscending(!isAscending);

  return (
    <span className="comment-sort-toggle-container">
      <span className="mr15">Sort newest first</span>
      <Toggle
        onChange={onChangeToggle}
        checked={isAscending}
        icons={false}
        className="comment-sort-toggle"
      />
      <span className="ml15">Sort oldest first</span>
    </span>
  );
};
ToggleCommentsSort.propTypes = {
  isAscending: PropTypes.bool.isRequired,
  setIsAscending: PropTypes.func.isRequired,
};

/*
 * If you need to render read-only comments
 * you might need to consider to use ReadOnlyComments
 */
const Comments = ({
  bgInverse,
  comments,
  onSubmit,
  isLoading,
  isSubmitting,
  usersByUsername,
  showCommentActionAssignment,
  showCommentRelatedResource,
  fetchAllUsers,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const [isAscending, setIsAscending] = useState(true);
  const sortedComments = _orderBy(comments, 'created', [
    isAscending ? 'asc' : 'desc',
  ]);

  return (
    <div className="comments">
      <Card bg={bgInverse ? 'light' : 'dark'} className="m-b">
        <Card.Header className="pd-exp inverse">
          <PanelHeader collapsed={collapsed} setCollapsed={setCollapsed} />
        </Card.Header>
        <div className="card-body-wrapper">
          <Card.Body>
            <SmoothCollapse expanded={!collapsed}>
              <div className="comments-container">
                {!_isEmpty(sortedComments) && (
                  <ToggleCommentsSort
                    isAscending={isAscending}
                    setIsAscending={setIsAscending}
                  />
                )}
                {sortedComments.map(commentObject => (
                  <Comment
                    comment={commentObject}
                    showCommentActionAssignment={showCommentActionAssignment}
                    showRelatedResource={showCommentRelatedResource}
                  />
                ))}
                {isLoading && <Loading />}
                {onSubmit && (
                  <CommentForm
                    isSubmitting={isSubmitting}
                    onSubmit={onSubmit}
                    usersByUsername={usersByUsername}
                    fetchAllUsers={fetchAllUsers}
                  />
                )}
              </div>
            </SmoothCollapse>
          </Card.Body>
        </div>
      </Card>
    </div>
  );
};

Comments.defaultProps = {
  showCommentActionAssignment: true,
  usersByUsername: {},
  onSubmit: null,
  showCommentRelatedResource: false,
  fetchAllUsers: () => {},
};

Comments.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // Required if user can add comments for this entity:
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  bgInverse: PropTypes.bool.isRequired,
  // Required when showCommentActionAssignment is true:
  usersByUsername: PropTypes.shape({}),
  showCommentActionAssignment: PropTypes.bool,
  showCommentRelatedResource: PropTypes.bool,
  fetchAllUsers: PropTypes.func,
};

export default Comments;
