import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from 'rapidfab/utils/alert';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import Actions from 'rapidfab/actions';
import {
  getOrdersByUri,
  getNextPrintWithProcessStep,
  isFeatureEnabled,
  getPrintsForLineItem,
  getUUIDResource,
  getPiecesByUri,
  getRunPrints,
  getRunTransformations,
  getOrderedRunPrintsGridData,
  getRunLayerThickness,
  getWorkflowsByUuid,
  getPrintUrisGroupedByWorkChecklistLinkingUris,
  getRunPieces,
  getBuildFilesForRun,
  getPostProcessorsByUri,
  getRunsByUri,
} from 'rapidfab/selectors';
import {
  REMANUFACTURE_REASONS,
  RUN_STATUSES,
  FEATURES,
  RUN_TRANSFORM_TYPES,
  API_RESOURCES,
  PRINT_STATUSES,
} from 'rapidfab/constants';
import BuildPlate from 'rapidfab/components/records/run/BuildPlate';
import extractUuid from 'rapidfab/utils/extractUuid';
import _xor from 'lodash/xor';
import { pieceResourceType, runType } from 'rapidfab/types';
import _reduce from 'lodash/reduce';
import { FormattedMessage } from 'react-intl';

class BuildPlateContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    //  initial values of state are set in initializeFieldValues method
    this.state = {
      selectedPrints: [],
    };

    this.onRemanufactureModalSubmit = this.onRemanufactureModalSubmit.bind(this);
    this.onNCReviewSubmit = this.onNCReviewSubmit.bind(this);
    this.invertRemanufactureModalStatus = this.invertRemanufactureModalStatus.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.runRemanufacture = this.runRemanufacture.bind(this);
    this.setTransformTypeForChangeWorkflow = this.setTransformTypeForChangeWorkflow.bind(this);
    this.setSelectedPrints = this.setSelectedPrints.bind(this);
    this.setChangeWorkflowNotes = this.setChangeWorkflowNotes.bind(this);
    this.initializeFieldValues = this.initializeFieldValues.bind(this);
    this.onScrapPieceSubmit = this.onScrapPieceSubmit.bind(this);

    this.initializeFieldValues();
  }

  componentDidMount() {
    const { run, onInitialize } = this.props;
    onInitialize(run);
  }

  componentDidUpdate(prevProps) {
    const {
      workflowUris,
      onInitializeWorkflows,
      loadAccessInfo,
      runPieces,
      isGroupQualificationsFeatureEnabled,
      isBuildPlateLoading,
    } = this.props;

    if (!isBuildPlateLoading && !_isEmpty(workflowUris)) {
      onInitializeWorkflows(workflowUris);
    }

    if (!isGroupQualificationsFeatureEnabled) {
      return;
    }

    // This logic is needed only if `group-qualifications` feature is enabled

    // Using `piece` resources here to make sure those are loaded into store,
    // before running `access-info` request
    const prepPropsPieceUris = prevProps.runPieces.map(piece => piece.uri);
    const pieceUris = runPieces.map(piece => piece.uri);
    const pieceUrisDiff = _xor(pieceUris, prepPropsPieceUris);
    if (pieceUrisDiff.length) {
      loadAccessInfo(pieceUris);
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({ [name]: value });
  }

  onRemanufactureModalSubmit({ processStepURIs } = {}, splitSchedules = false) {
    const { uri: runUri, gridData } = this.props;
    const {
      remanufactureReason,
      remanufactureNotes,
      selectedPrints,
      transformType,
    } = this.state;

    let notes = remanufactureNotes;

    if (transformType === RUN_TRANSFORM_TYPES.REMANUFACTURE) {
      notes = `${REMANUFACTURE_REASONS[remanufactureReason]}${remanufactureNotes && `: ${remanufactureNotes}`}`;
    }

    const payload = {
      type: transformType,
      prints: _map(selectedPrints, 'uri'),
      source_run: runUri,
      split_piece_schedules: splitSchedules,
      notes,
    };

    if (transformType === RUN_TRANSFORM_TYPES.CHANGE_WORKFLOW) {
      payload.process_steps = processStepURIs;
    }

    const remanufactureAvailablePrints = _filter(gridData, print => (
      !print.remanufactured_to
    ));

    return this.props.onCreateRunTransformation(payload).then(result => {
      if (
        remanufactureAvailablePrints.length === selectedPrints.length
        && transformType !== RUN_TRANSFORM_TYPES.CHANGE_WORKFLOW
      ) {
        // We need to set error status, only if current transformation
        // is not a change production workflow one
        // Change production workflow does not affect current run, only non-completed ones
        const runPayload = { status: RUN_STATUSES.ERROR };
        this.props.onUpdateRun(extractUuid(runUri), runPayload).then(() => {
          this.props.loadPrints(runUri);
        });
      } else {
        this.props.loadPrints(runUri);
      }

      return result;
    });
  }

  onNCReviewSubmit(splitSchedules = false) {
    const { uri: runUri } = this.props;
    const { selectedPrints } = this.state;

    const payload = {
      type: 'flag_non_conformance',
      prints: _map(selectedPrints, 'uri'),
      source_run: runUri,
      split_piece_schedules: splitSchedules,
    };

    return this.props.onCreateRunTransformation(payload)
      .then(result => {
        this.props.loadPrints(runUri);
        return result;
      })
      .catch(error => Alert.error(error.message));
  }

  onScrapPieceSubmit(notes) {
    const { uri: runUri } = this.props;
    const { selectedPrints } = this.state;

    const payload = {
      type: 'scrap',
      prints: _map(selectedPrints, 'uri'),
      source_run: runUri,
      notes,
    };

    return this.props.onCreateRunTransformation(payload)
      .then(result => {
        this.props.loadPrints(runUri);
        return result;
      })
      .catch(error => Alert.error(error.message));
  }

  setTransformTypeForChangeWorkflow() {
    this.setState({ transformType: RUN_TRANSFORM_TYPES.CHANGE_WORKFLOW });
  }

  setSelectedPrints(selectedPrints) {
    this.setState({ selectedPrints });
  }

  setChangeWorkflowNotes(event) {
    const { value } = event.target;

    this.setState({ remanufactureNotes: value });
  }

  // resets the values of state.
  initializeFieldValues() {
    this.setState({
      selectedPrints: [],
      isRemanufactureModalVisible: false,

      remanufactureNotes: '',
      remanufactureReason: '',

      transformType: '',
    });
  }

  /**
   * Sometimes a 'Print Run' can succeed, but individual items in that print
   * are wrong/bad or need to be flagged to re-print:
   *     - that the print ran,
   *     - overall the creation of the whole build was OK,
   *     - only a few individual pieces were wrong/bad/not-good-enough.
   * In that case, you can mark the whole run as Done, and mark print items as 'done'.
   * But also, you need create and mark a 'clone' item to be re-manufacture the clone -
   * so in this case some Prints in Run can be `remanufactured`.
   *
   * (Should only be available in `Complete` runs).
   */
  runRemanufacture() {
    const runStatus = this.props.run.status;

    if (runStatus === RUN_STATUSES.ERROR) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.prints.runResultedInError"
          defaultMessage="The prints in this run have automatically been marked for re-manufacture because the run resulted in an error."
        />);
      return;
    } if (runStatus !== RUN_STATUSES.COMPLETE) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.prints.onlyRemanufactureAfterCompletedRun"
          defaultMessage="Prints can only be re-manufactured after a completed run (successful or error)."
        />,
      );
      return;
    }

    this.setState({ isRemanufactureModalVisible: true });
  }

  // called when
  // 'Remanufacture' modal is closed OR
  // 'Redo Details' modal is closed.
  invertRemanufactureModalStatus() {
    if (this.state.isRemanufactureModalVisible) {
      // Clear modal data before close
      this.initializeFieldValues();
    } else {
      this.setState({ isRemanufactureModalVisible: true });
    }
  }

  render() {
    const isAllPrintsSelected = this.state.selectedPrints?.length === this.props.gridData?.length;

    return (
      <BuildPlate
        {...this.props}
        {...this.state}
        invertRemanufactureModalStatus={this.invertRemanufactureModalStatus}
        onRemanufactureModalSubmit={this.onRemanufactureModalSubmit}
        onNCReviewSubmit={this.onNCReviewSubmit}
        handleInputChange={this.handleInputChange}
        runRemanufacture={this.runRemanufacture}
        setTransformTypeForChangeWorkflow={this.setTransformTypeForChangeWorkflow}
        setSelectedPrints={this.setSelectedPrints}
        setChangeWorkflowNotes={this.setChangeWorkflowNotes}
        initializeFieldValues={this.initializeFieldValues}
        remanufactureNotes={this.state.remanufactureNotes}
        isAllPrintsSelected={isAllPrintsSelected}
        onScrapPieceSubmit={this.onScrapPieceSubmit}
      />
    );
  }
}

BuildPlateContainer.defaultProps = {
  run: {},
};

BuildPlateContainer.propTypes = {
  uri: PropTypes.string.isRequired,
  gridData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  piecesByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  runPieces: PropTypes.arrayOf(pieceResourceType).isRequired,
  printingRuns: PropTypes.shape({}).isRequired,
  isQRPrintTravelerFeatureEnabled: PropTypes.bool.isRequired,
  run: runType,
  nextPrintsWithProcessStep: PropTypes.shape({}).isRequired,
  onInitialize: PropTypes.func.isRequired,
  runTransformations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCreateRunTransformation: PropTypes.func.isRequired,
  onUpdateRun: PropTypes.func.isRequired,
  loadPrints: PropTypes.func.isRequired,
  loadAccessInfo: PropTypes.func.isRequired,
  isGroupQualificationsFeatureEnabled: PropTypes.bool.isRequired,
  buildFiles: PropTypes.arrayOf(PropTypes.shape({
    snapshot_content: PropTypes.string,
    uri: PropTypes.string,
  })).isRequired,
  postProcessorsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  workflowUris: PropTypes.arrayOf(PropTypes.string).isRequired,
  onInitializeWorkflows: PropTypes.func.isRequired,
  isBuildPlateLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
  const nextPrintsWithProcessStep = {};
  // const replacementPieces = {}
  const printingRuns = {};
  const piecesByUri = getPiecesByUri(state);

  const run = getUUIDResource(state, extractUuid(props.uri));
  const runPieces = getRunPieces(state, run);
  const prints = getRunPrints(state, run);
  const runTransformations = getRunTransformations(state, props.uri);
  const buildFiles = getBuildFilesForRun(state, run);
  const groupedPrintUrisByWorkChecklistLinkingUris = getPrintUrisGroupedByWorkChecklistLinkingUris(state, run);
  const postProcessorsByUri = getPostProcessorsByUri(state);
  const runsByUri = getRunsByUri(state);
  const workflowUris = _map(prints, 'workflow');

  prints.forEach(print => {
    nextPrintsWithProcessStep[print.uri] = getNextPrintWithProcessStep(state, print);

    let runUuid = extractUuid(print.run);

    if (print.process_step_position > 1) {
      runUuid = null;

      const lineItemPrints = getPrintsForLineItem(state, { uri: print.line_item });
      const firstPrint = lineItemPrints.find(
        p => p.process_step_position === 1 && p.copy === print.copy,
      );

      if (firstPrint) {
        runUuid = extractUuid(firstPrint.run);
      }
    }

    if (runUuid) {
      printingRuns[print.uri] = getUUIDResource(state, runUuid);
    }
  });

  const gridData = _reduce(getOrderedRunPrintsGridData(state, run), (result, print) => {
    if (print) {
      result.push(print);
    }
    return result;
  }, []).filter(print => print.status !== PRINT_STATUSES.CANCELLED);

  // const gridData = getOrderedRunPrintsGridData(state, run)
  const layerThickness = getRunLayerThickness(state, run);
  const workflowsByUuid = getWorkflowsByUuid(state);

  const isGroupQualificationsFeatureEnabled = isFeatureEnabled(state, FEATURES.GROUP_QUALIFICATIONS);

  return {
    isBuildPlateLoading: state.ui.nautilus[API_RESOURCES.RUN].get.fetching
      || state.ui.nautilus[API_RESOURCES.RUN].list.fetching
      || state.ui.nautilus[API_RESOURCES.PROCESS_STEP].list.fetching
      || state.ui.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list.fetching
      || state.ui.nautilus[API_RESOURCES.PRINT].list.fetching
      || state.ui.nautilus[API_RESOURCES.PIECE].list.fetching
      || state.ui.nautilus[API_RESOURCES.BUILD_FILE].list.fetching
      || state.ui.nautilus[API_RESOURCES.ACCESS_INFO_FOR_RESOURCE].list.fetching
      || state.ui.nautilus[API_RESOURCES.LINE_ITEM].list.fetching
      || state.ui.nautilus[API_RESOURCES.ORDER].list.fetching
      || state.ui.nautilus[API_RESOURCES.WORKFLOW].list.fetching,
    buildFiles,
    run,
    runTransformations,
    orders: getOrdersByUri(state),
    isRemanufactureLoading: state.ui.nautilus[API_RESOURCES.RUN_TRANSFORMATION].put.fetching,
    isQRPrintTravelerFeatureEnabled: isFeatureEnabled(state, FEATURES.QR_PRINTS_TRAVELER),
    piecesByUri,
    runPieces,
    nextPrintsWithProcessStep,
    printingRuns,
    gridData,
    layerThickness,
    workflowsByUuid,
    groupedPrintUrisByWorkChecklistLinkingUris,
    isGroupQualificationsFeatureEnabled,
    postProcessorsByUri,
    runsByUri,
    prints,
    workflowUris,
  };
};

const mapDispatchToProps = dispatch => ({
  onInitialize: ({ uri, printURIs, layout, uuid }) => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].get(uuid))
      .then(() => {
        dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_TRANSFORMATION].list({ source_run: uri }));

        if (layout) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.LAYOUT].get(extractUuid(layout)));
        }
        if (!_isEmpty(printURIs)) {
        // fetch work-checklist-linkings for use in RunPrints production change
          dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_CHECKLISTS_FOR_RESOURCE].list({ related_uri: printURIs }));
        }
      });
  },
  onInitializeWorkflows: workflowUris => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list({
      include_custom_workflows: true,
      uri: workflowUris,
    }));
  },
  loadAccessInfo: pieceUris =>
    // Load all access-info details for all pieces via 1 API call to be used in DisabledByAccessInfoCheck component
    dispatch(Actions.Api.nautilus[API_RESOURCES.ACCESS_INFO_FOR_RESOURCE].list({ target_uri: pieceUris })),
  onCreateRunTransformation: payload =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_TRANSFORMATION].post(payload)),
  onUpdateRun: (uuid, payload) =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].put(uuid, payload)),
});

const areStatePropsEqual = (next, previous) => JSON.stringify(previous) === JSON.stringify(next);

export default connect(mapStateToProps, mapDispatchToProps, null, { areStatePropsEqual })(BuildPlateContainer);
