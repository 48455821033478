import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { XEROX_HAWKING_BRANDING, ROUTES, API_RESOURCES, FEATURES } from 'rapidfab/constants';
import * as Selectors from 'rapidfab/selectors';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';

import Actions from 'rapidfab/actions';
import { useDispatch, useSelector } from 'react-redux';

const BureauFetchError = () => (
  <FormattedMessage
    id="bureauError.fetch"
    message="There was an unkown error fetching your bureau. Please try again."
  />
);

const BureauLengthError = () => (
  <FormattedMessage
    id="bureauError.multiple"
    message="You belong to multiple Bureaus. Only one Bureau is allowed when using MES. Please remove one of the Bureaus listed below."
  />
);

const BureauMissingError = () => (
  <FormattedMessage
    id="bureauError.empty"
    message="You are not assigned to any Bureaus. A Bureau is required to use MES."
  />
);

const BureauError = ({ bureaus, errors }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signingOut, setSigningOut] = useState(false);
  const session = useSelector(state => ({
    bureaus: Selectors.getBureausCurrentUserRoles(state),
    currentUser: Selectors.getSession(state),
    permissions: Selectors.getPermissions(state),
    roles: Selectors.getRolesCurrentUser(state),
    role: Selectors.getCurrentUserRole(state),
    // errors: Selectors.getErrors(state),
    errors: [
      ...state.ui.nautilus[API_RESOURCES.SESSIONS].get.errors,
      ...state.ui.nautilus[API_RESOURCES.BUREAU].list.errors,
    ],
    isHawkingUser: Selectors.isFeatureEnabled(
      state,
      FEATURES.HAWKING_DEPLOYMENT,
    ),
    isAuthentisePDMUser: Selectors.isFeatureEnabled(
      state,
      FEATURES.AUTHENTISE_PDM,
    ),
    isStanleyXUser: Selectors.isFeatureEnabled(
      state,
      FEATURES.STANLEY_X_DEPLOYMENT,
    ),
    isDigitalDesignWarehouseFeatureEnabled: Selectors.isFeatureEnabled(
      state,
      FEATURES.DIGITAL_DESIGN_WAREHOUSE,
    ),
    isDanfossUser: Selectors.isFeatureEnabled(
      state,
      FEATURES.ORDER_BUSINESS_SEGMENT,
    ),
    isRestrictedUser: Selectors.isCurrentUserRestricted(state),
    features: Selectors.getFeatures(state),
    fetching: isFetchingInitial(state.ui.nautilus[API_RESOURCES.SESSIONS].get) ||
                state.ui.nautilus[API_RESOURCES.BUREAU].list.fetching ||
                state.ui.nautilus[API_RESOURCES.FEATURE].list.fetching ||
                state.ui.nautilus[API_RESOURCES.ROLE].list.fetching,
  }));
  return (
    <Container fluid>
      <div className="jumbotron">
        <Row>
          <Col xs={12}>
            <h1>Bureau Error!</h1>
            {errors.length > 0 && <BureauFetchError />}
            {bureaus.size > 1 && <BureauLengthError />}
            {bureaus.size === 0 && <BureauMissingError />}
            {bureaus.size > 1 &&
              [...bureaus].map(bureau => (
                <p>
                  <strong>{bureau.name}</strong>
                </p>
              ))}
            <p>
              <FormattedMessage
                id="bureauError.docsMessage"
                defaultMessage="You can find the docs for this process"
              />
              <a href="http://docs.authentise.com/mes/index.html"> here.</a>
            </p>
            <Button
              disabled={signingOut}
              onClick={() => {
                setSigningOut(true);
                dispatch(Actions.Api.nautilus[API_RESOURCES.SESSIONS].clear());
                dispatch(Actions.Api.nautilus[API_RESOURCES.SESSIONS].delete(''))
                  .then(() => {
                    localStorage.removeItem(XEROX_HAWKING_BRANDING);
                    setSigningOut(false);
                    navigate(session?.isHawkingUser ? ROUTES.LOGIN_XEROX : ROUTES.LOGIN);
                    // Hard reload the page to clear all the stored data and start from scratch
                    window.location.reload();
                  });
              }}
            >
              <Fa name={signingOut ? 'spinner' : 'sign-out'} />{' '}
              Logout
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

BureauError.propTypes = {
  bureaus: PropTypes.shape({
    size: PropTypes.number.isRequired,
  }).isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default BureauError;
