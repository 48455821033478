import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  MODEL_LIBRARY_TYPES,
  MODEL_LAYER_THICKNESS_SETTINGS,
  FEATURES,
  ALLOWED_MODEL_EXTENSIONS,
} from 'rapidfab/constants';
import { MODEL_LIBRARY_TYPE_MAP, MODEL_UNITS_MAP } from 'rapidfab/mappings';
import {
  Modal,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
  FormText,
  Form as BSForm,
} from 'react-bootstrap';
import { FormattedMessage, FormattedMessageMappingOption } from 'rapidfab/i18n';
import NonHawkingFeature from 'rapidfab/components/hawking/NonHawkingFeature';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import Feature from 'rapidfab/components/Feature';
import { materialTypeResourceType, finalFormInputTypes } from 'rapidfab/types';
import SaveButton from 'rapidfab/components/SaveButton';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import Tooltip from 'rapidfab/components/Tooltip';
import 'rapidfab/styles/hawking/main.scss';
import { Form, Field } from 'react-final-form';
import _set from 'lodash/set';
import _toNumber from 'lodash/toNumber';

const ModelLibraryCreateModal = ({
  onClose,
  modelFile,
  otherModelFiles,
  onSubmitModelAndModelLibrary,
  baseMaterialsByUri,
  onSuccessSubmit,
  isHawkingUser,
  initialFormValues,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isApplyToAll, setIsApplyToAll] = useState(false);
  const [isProductType, setIsProductType] = useState(false);

  const onIsApplyToAllChange = ({ target: { checked } }) =>
    setIsApplyToAll(checked);

  const onFormSubmit = payload => {
    setIsSaving(true);
    _set(payload, 'type', isProductType ? MODEL_LIBRARY_TYPES.PRODUCT : MODEL_LIBRARY_TYPES.SPECIMEN);
    _set(payload, 'layer_thickness', _toNumber(payload.layer_thickness));
    onSubmitModelAndModelLibrary(payload, modelFile)
      .then(response => {
        if (isApplyToAll) {
          _forEach(otherModelFiles, async otherModelFile => {
            const fileNameWithoutExtension = otherModelFile.name.split('.').slice(0, -1).join('.');
            // Using same payload but current model file name without extension as Name
            const otherModelFilePayload = {
              ...payload,
              name: fileNameWithoutExtension,
            };
            await onSubmitModelAndModelLibrary(otherModelFilePayload, otherModelFile);
          });
        }
        return typeof onSuccessSubmit === 'function'
          // Call success submit callback if there is any
          ? onSuccessSubmit(response.payload, isApplyToAll)
          // Just close the modal otherwise
          : onClose();
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const showApplyToAllCheckbox = !!otherModelFiles.length;

  const otherFileNames = _map(otherModelFiles, 'name');

  const modelFileNameParts = modelFile.name.split('.');
  const extension = modelFileNameParts.pop().toLowerCase();

  return (
    <Modal show onHide={onClose} backdrop="static">
      <Form
        onSubmit={onFormSubmit}
        initialValues={initialFormValues}
        render={({ handleSubmit, values }) => {
          const isProductTypeSelected = values?.type === MODEL_LIBRARY_TYPES.PRODUCT;
          setIsProductType(isProductTypeSelected);
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  New Model Library Item
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormGroup controlId="mlFile">
                  <FormLabel className="spacer-right">
                    <FormattedMessage id="field.file" defaultMessage="File" />:
                  </FormLabel>
                  {modelFile.name}
                  <p>
                    <sup>
                      <b>
                        <FormattedMessage
                          id="field.note"
                          defaultMessage="Note"
                        />:{' '}
                      </b>
                      <i>
                        <FormattedMessage
                          id="uploadProductNote"
                          defaultMessage="Assemblies are not currently supported."
                        />
                      </i>
                    </sup>
                  </p>
                </FormGroup>
                <FormGroup className="form-group" controlId="mlName">
                  <FormLabel>
                    <FormattedMessage id="field.name" defaultMessage="Name" />: *
                  </FormLabel>
                  <Field
                    name="name"
                    type="text"
                    render={props => (
                      <FormControl
                        {...props.input}
                        required
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="mlPartNumber">
                  <FormLabel>
                    <FormattedMessage id="partNumber" defaultMessage="Part Number" />:
                  </FormLabel>
                  <Field
                    name="part_number"
                    render={props => (
                      <FormControl
                        {...props.input}
                      />
                    )}
                  />
                </FormGroup>
                <Feature featureName={FEATURES.HAWKING_DEPLOYMENT}>
                  <FormGroup className="form-group" controlId="mlMaterial">
                    <FormLabel>
                      <FormattedMessage id="field.baseMaterial" defaultMessage="Base Material" />: *
                    </FormLabel>
                    <Field
                      name="base_material"
                      render={props => (
                        <SelectSingle
                          required
                          data={Object.values(baseMaterialsByUri)}
                          {...props.input}
                          handleOnChange={props.input.onChange}
                          imitateOnChangeEvent
                        />
                      )}
                    />
                  </FormGroup>
                </Feature>
                <NonHawkingFeature>
                  <FormGroup className="form-group" controlId="mlType">
                    <FormLabel>
                      <FormattedMessage id="field.type" defaultMessage="Type" />: *
                    </FormLabel>
                    <Field
                      name="type"
                      render={props => (
                        <FormControl
                          required
                          as="select"
                          {...props.input}
                        >
                          {Object.values(MODEL_LIBRARY_TYPES).map(type => (
                            <FormattedMessageMappingOption
                              mapping={MODEL_LIBRARY_TYPE_MAP}
                              value={type}
                            />
                          ))}
                        </FormControl>
                      )}
                    />
                    <FormText>
                      <small>
                        <FormattedMessage
                          id="record.modelLibrary.cannotChangeType"
                          defaultMessage="Type cannot be changed once a model is added to the library."
                        />
                      </small>
                    </FormText>
                  </FormGroup>
                  {
                    isProductTypeSelected && (
                      <FormGroup className="form-group" controlId="mlLayerThickness">
                        <FormLabel>
                          <FormattedMessage
                            id="field.layer_thickness"
                            defaultMessage="Layer Thickness"
                          />
                        </FormLabel>: *
                        <Field
                          name="layer_thickness"
                          type="number"
                          render={props => (
                            <FormControl
                              {...props.input}
                              min={MODEL_LAYER_THICKNESS_SETTINGS.MIN}
                              max={MODEL_LAYER_THICKNESS_SETTINGS.MAX}
                              step={MODEL_LAYER_THICKNESS_SETTINGS.STEP}
                              required
                            />
                          )}
                        />
                      </FormGroup>
                    )
                  }
                </NonHawkingFeature>
                {/* file upladed is in allowed model extension (ie STL for now) */}
                {(ALLOWED_MODEL_EXTENSIONS.includes(extension)) && (
                  <>
                    <b><FormattedMessage id="modelFileUnits" defaultMessage="Model File Units" />: *</b>
                    <Field
                      name="file_unit"
                      render={props => (
                        <FormControl
                          {...props.input}
                          as="select"
                          required
                        >
                          {Object.keys(MODEL_UNITS_MAP).map(modelUnit => (
                            <FormattedMessageMappingOption
                              mapping={MODEL_UNITS_MAP}
                              value={modelUnit}
                              key={modelUnit}
                            />
                          ))}
                        </FormControl>
                      )}
                    />
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                {showApplyToAllCheckbox && (
                  <BSForm.Check
                    className="spacer-left"
                    checked={isApplyToAll}
                    onChange={onIsApplyToAllChange}
                    type="checkbox"
                    label={(
                      <>
                        Apply to all ({otherFileNames.length} more)
                        <Tooltip id="applyToAll">
                          <span>{otherFileNames.join(', ')}</span>
                        </Tooltip>
                      </>
                    )}
                  />
                )}
                <Button variant="default" onClick={onClose}>
                  <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
                </Button>
                <SaveButton isSaving={isSaving} className={isHawkingUser && 'hawking-primary'} variant={!isHawkingUser && 'success'} />
              </Modal.Footer>
            </form>
          );
        }}
      />
    </Modal>
  );
};

ModelLibraryCreateModal.defaultProps = {
  modelFile: null,
  onSuccessSubmit: null,
};

ModelLibraryCreateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  modelFile: PropTypes.shape({
    name: PropTypes.string,
  }),
  otherModelFiles: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
  onSubmitModelAndModelLibrary: PropTypes.func.isRequired,
  initialFormValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    layer_thickness: PropTypes.number.isRequired,
    base_material: PropTypes.string.isRequired,
    file_unit: PropTypes.string.isRequired,
  }).isRequired,
  values: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  input: finalFormInputTypes.isRequired,
  baseMaterialsByUri: PropTypes.objectOf(materialTypeResourceType).isRequired,
  onSuccessSubmit: PropTypes.func,
  isHawkingUser: PropTypes.bool.isRequired,
};

export default ModelLibraryCreateModal;
