import React from 'react';
import { Route, Routes } from 'react-router-dom';
import InventoryStocks from 'rapidfab/containers/inventory/stocks';
import InventoryPrinters from 'rapidfab/containers/inventory/printers';
import InventoryPostProcessors from 'rapidfab/containers/inventory/PostProcessorsContainer';
import PropTypes from 'prop-types';
import { FEATURES } from '../constants';
import { ROUTER_PERMISSION } from '../constantsRouter';
import PrivateRoute from './PrivateRoute';
import InventoryPostProcessorServices from '../containers/inventory/PostProcessorServices';
import MaterialBatchesContainer from '../containers/inventory/MaterialBatchesContainer';
import MaterialLotsContainer from '../containers/inventory/MaterialLotsContainer';
import MaterialBatchContainer from '../containers/records/MaterialBatchContainer';
import QrBatchContainersContainer from '../containers/qr/BatchContainersContainer';
import MaterialBatchGenealogyContainer from '../containers/records/MaterialBatchGenealogyContainer';
import MaterialLotContainer from '../containers/records/MaterialLotContainer';
import MaterialLotNewContainer from '../containers/records/MaterialLotNewContainer';
import QrLotContainersContainer from '../containers/qr/LotContainersContainer';
import NotFound from '../components/404';

const InventoryRoutes = ({ session }) => (
  <Routes>
    <Route
      path="/stocks"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryStocks}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          isRestrictedRoute
          features={[FEATURES.MANUFACTURING_EXECUTION_SYSTEM]}
        />
      )}
    />
    <Route
      path="/printers"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryPrinters}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={{
            [FEATURES.HAWKING_DEPLOYMENT]: false,
          }}
        />
      )}
    />
    <Route
      path="/post-processors"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryPostProcessors}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MANUFACTURING_EXECUTION_SYSTEM]}
        />
      )}
    />
    <Route
      // TODO: This route config is an edge-case and needs to be handled in some other way.
      // It is allowed for all for now
      // TODO: Route is hidden under `SERVICE_PROVIDER_AT_STEPS` for regular user
      //  but SP role has no such feature
      path="/post-processor-services"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryPostProcessorServices}
          permission={ROUTER_PERMISSION.LOGGED_IN_ONLY}
          isRestrictedRoute
          features={[FEATURES.MANUFACTURING_EXECUTION_SYSTEM]}
        />
      )}
    />
    <Route
      path="/batches"
      element={(
        <PrivateRoute
          session={session}
          component={MaterialBatchesContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/batch/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={MaterialBatchContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/batch/:uuid/qr"
      element={(
        <PrivateRoute
          session={session}
          hideLayout
          component={QrBatchContainersContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/batch/:uuid/qr/:container"
      element={(
        <PrivateRoute
          session={session}
          hideLayout
          component={QrBatchContainersContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/batch/:uuid/genealogy"
      element={(
        <PrivateRoute
          session={session}
          component={MaterialBatchGenealogyContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/lots"
      element={(
        <PrivateRoute
          session={session}
          component={MaterialLotsContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/lot/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={MaterialLotContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/lot"
      element={(
        <PrivateRoute
          session={session}
          component={MaterialLotNewContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/lot/:uuid/qr"
      element={(
        <PrivateRoute
          session={session}
          hideLayout
          component={QrLotContainersContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="/lot/:uuid/qr/:container"
      element={(
        <PrivateRoute
          session={session}
          hideLayout
          component={QrLotContainersContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MATERIAL_MANAGEMENT]}
        />
      )}
    />
    <Route
      path="*"
      element={<NotFound />}
    />
  </Routes>
);

InventoryRoutes.propTypes = {
  session: PropTypes.shape({}).isRequired,
};

export default InventoryRoutes;
