import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _values from 'lodash/values';
import _flatten from 'lodash/flatten';
import _find from 'lodash/find';
import _compact from 'lodash/compact';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';

import { API_RESOURCES, CAD_MODEL_REPLACED_TRACEABILITY_FILTER, TRACEABILITY_FILTERS } from 'rapidfab/constants';
import Actions from 'rapidfab/actions';
import {
  getEnabledFeatureNames,
  getEventRelatedResourcesForPieceByResourceUri,
  getModelForPiece,
  getRouteUUIDResource,
  getTraceabilityFilteredEvents,
  getTraceabilityReportFilters,
  getTraceabilityReportForPiece,
} from 'rapidfab/selectors';
import SelectMultiple from 'rapidfab/components/forms/SelectMultiple';

import Loading from 'rapidfab/components/Loading';
import Event from 'rapidfab/components/records/piece/Event';

export const ExportButton = ({
  download,
  loadingReport,
  onExport,
  asBlock,
}) => {
  if (download !== 'none') {
    return (
      // eslint-disable-next-line react/no-unknown-property
      <a href={download} download>
        <Button block={asBlock} variant="success" className="pull-right">
          <FormattedMessage
            id="record.print.download"
            defaultMessage="Download Report"
          />
        </Button>
      </a>
    );
  }
  if (loadingReport) {
    return (
      <Button block={asBlock} variant="primary" className="pull-right">
        <Fa name="spinner" spin />
      </Button>
    );
  }
  return (
    <Button
      block={asBlock}
      variant="primary"
      onClick={onExport}
      className="pull-right"
    >
      <FormattedMessage
        id="record.print.export"
        defaultMessage="Export Report"
      />
    </Button>
  );
};

ExportButton.defaultProps = {
  asBlock: false,
};

ExportButton.propTypes = {
  download: PropTypes.string.isRequired,
  loadingReport: PropTypes.bool.isRequired,
  onExport: PropTypes.func.isRequired,
  asBlock: PropTypes.bool,
};

const TraceabilityReport = ({ initialFilterByCADReplace }) => {
  const dispatch = useDispatch();
  const [loadingReport, setLoadingReport] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [visibleEvents, setVisibleEvents] = useState([]);
  const [fetchedUsers, setFetchedUsers] = useState([]);
  const traceabilityReportRef = useRef();

  const piece = useSelector(getRouteUUIDResource);
  const model = useSelector(state => getModelForPiece(state, piece));
  const report = useSelector(state => getTraceabilityReportForPiece(state, piece));
  const enabledFeatureNames = useSelector(getEnabledFeatureNames);
  const filteredEvents = useSelector(state => getTraceabilityFilteredEvents(state, piece));
  const eventRelatedResourcesByUri = useSelector(
    state => getEventRelatedResourcesForPieceByResourceUri(state, piece),
  );
  const traceabilityReportFilters = useSelector(getTraceabilityReportFilters);
  const fetching = useSelector(
    state => [
      state.ui.nautilus[API_RESOURCES.EVENT].list.fetching,
      state.ui.nautilus[API_RESOURCES.TRACEABILITY_REPORT].list.fetching,
      state.ui.nautilus[API_RESOURCES.PRINT].list.fetching,
    ].some(Boolean),
  );

  const download = report && report.content ? report.content : 'none';

  const onExport = () => {
    const traceabilityFilter = { piece: piece.uri };
    if (selectedFilters.length) {
      traceabilityFilter.filters = _map(selectedFilters, 'value');
    }
    dispatch(Actions.Api.nautilus[API_RESOURCES.TRACEABILITY_REPORT].post(traceabilityFilter));
    setLoadingReport(true);
  };

  const onFilterChange = values => {
    setSelectedFilters(values);
    dispatch(Actions.TraceabilityReportFilters.setFilters(values));
  };

  const getFilterOptions = () =>
    _map(TRACEABILITY_FILTERS, ({
      label,
      value,
      feature,
    }) => {
      if (feature && !enabledFeatureNames.includes(feature)) {
        // Do not render when feature is disabled
        return null;
      }

      const eventCount = filteredEvents[value].length;
      return {
        value,
        label: `${label} (${eventCount})`,
      };
    })
      .filter(Boolean); // remove feature-based options when feature is disabled

  const fetchUsers = users => {
    const usersUris = _uniq(_map(fetchedUsers, 'uri')).filter(Boolean).sort();
    // If we are changing the filters and the users are already fetched, we don't need to fetch them again
    if (!_isEqual(users, usersUris)) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({ uri: users }))
        .then(response => {
          const users = response?.json?.resources;
          if (users?.length) {
            setFetchedUsers(users);
          }
        });
    }
  };

  const filterEvents = () => {
    const selectedValues = _map(selectedFilters, 'value');

    if (_isEmpty(selectedValues)) {
      return _flatten(_values(filteredEvents));
    }

    let events = [];
    _forEach(selectedValues, key => {
      events = [...events, ...filteredEvents[key]];
    });
    return events;
  };

  // TODO for now implementing regex to find the uuid from the event key
  // will be done in the backend afterwards
  // refer to ticket "https://app.shortcut.com/authentise/story/34489/"
  const validateVisibleEvents = events => {
    const validatedEvents = [...events];

    validatedEvents.map(async (event_, index) => {
      const keyValue = event_.key;
      const re = new RegExp(/.done with (?:picture|document) value \s*([\dA-Za-z-]*)\s*by/g, 'i');
      const uuidList = re.exec(keyValue);
      const shouldRegexBeUsed = uuidList && uuidList[1];
      if (shouldRegexBeUsed) {
        const uuid = uuidList[1].trim();
        const documentPromise = dispatch(Actions.Api.nautilus[API_RESOURCES.DOCUMENT].get(uuid));

        await documentPromise.then(document_ => {
          validatedEvents[index].docUuid = uuid;
          validatedEvents[index].docContentUrl = document_?.json?.content;
        });
      }
      return event_;
    });
    return validatedEvents;
  };

  const memoSortedFilteredEvents = () => {
    const events = filterEvents();
    const validatedEventsList = validateVisibleEvents(_sortBy(events, 'created'));
    setVisibleEvents(validatedEventsList);
    const users = _uniq(_map(validatedEventsList, 'user')).filter(Boolean).sort();
    if (users.length) {
      fetchUsers(users);
    }
  };

  useEffect(() => {
    if (traceabilityReportFilters) {
      const options = getFilterOptions();
      const selectedFilters = _compact(_map(traceabilityReportFilters, ({ value }) =>
        _find(options, { value }),
      ));
      setSelectedFilters(selectedFilters);
    }

    if (initialFilterByCADReplace) {
      onFilterChange(CAD_MODEL_REPLACED_TRACEABILITY_FILTER);
      traceabilityReportRef.current.scrollIntoView();
    }
  }, [!!traceabilityReportFilters]);

  useEffect(() => {
    memoSortedFilteredEvents();
  }, [selectedFilters, filteredEvents]);

  const filterOptions = getFilterOptions();

  return (
    <div ref={traceabilityReportRef}>
      <Card bg="dark">
        <Card.Header className="pd-exp inverse">
          Traceability Report
        </Card.Header>
        <div className="card-body-wrapper">
          {(fetching && !visibleEvents.length) ? <Loading /> : (
            <>
              <Card.Body>
                <Row className="d-flex align-items-center" id="traceability-report">
                  <Col xs={12} lg={6}>
                    <SelectMultiple
                      title="Filter"
                      data={filterOptions}
                      labelKey="label"
                      valueKey="value"
                      selectedData={selectedFilters}
                      handleOnClose={values => onFilterChange(values)}
                    />
                  </Col>
                  <Col xs={5} lg={2}>
                    <b>{`${visibleEvents.length} Events`}</b>
                  </Col>
                  <Col
                    xs={{
                      span: 5,
                      offset: 2,
                    }}
                    lg={{
                      span: 3,
                      offset: 1,
                    }}
                  >
                    <ExportButton
                      download={download}
                      loadingReport={loadingReport}
                      onExport={onExport}
                    />
                  </Col>
                </Row>
              </Card.Body>
              {!visibleEvents.length && (
                <div className="p-a-sm">No events match your filters</div>
              )}
              <ListGroup fill>
                {visibleEvents.map(event =>
                  (
                    <Event
                      event={event}
                      key={event.uuid}
                      relatedResource={eventRelatedResourcesByUri[event.reference]}
                      model={model}
                    />
                  ),
                )}
              </ListGroup>
            </>
          )}

        </div>
      </Card>
    </div>
  );
};

TraceabilityReport.propTypes = {
  initialFilterByCADReplace: PropTypes.bool.isRequired,
};

export default React.memo(TraceabilityReport);
