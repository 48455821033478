import React from 'react';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';
import { Badge, Button } from 'react-bootstrap';
import {
  ORDER_CUSTOMER_GO_MAP,
  ORDER_PLANNING_STATUS_MAP,
  ORDER_BUILD_FILE_STATUS_MAP, ORDER_STATUS_MAP,
} from 'rapidfab/mappings';
import { FormattedMessage } from 'react-intl';
import OrderReportContainer from 'rapidfab/containers/OrderReportContainer';
import { ROUTES, STATUS_COLOR_CODE_TYPES } from 'rapidfab/constants';
import { customFieldReferenceType } from 'rapidfab/types';
import _keyBy from 'lodash/keyBy';
import getRouteURI from 'rapidfab/utils/getRouteURI';

import TableWithSearching from '../Tables/TableWithSearching';

const BUSINESS_SEGMENT_FIELD_ID = 'business_segment';

const Orders = componentProps => {
  const {
    data,
    onSort,
    fetching,
    isBoeingUser,
    isDanfossUser,
    isRestrictedUser,
    customFieldReferences,
    isOrderDueDateFeatureEnabled,
    isOrderOwnerColumnEnabled,
    isOrderQuoteFeatureEnabled,
    isDebugModeEnabled,
  } = componentProps;
  const customFieldReferencesByFieldId = _keyBy(customFieldReferences, 'field_id');
  const businessSegmentCustomField = customFieldReferencesByFieldId[BUSINESS_SEGMENT_FIELD_ID];

  const NavbarLinks = (
    <div className="pull-right" style={{ display: 'flex', flexDirection: 'space-evenly: flex-end' }}>
      <OrderReportContainer defaultMessage="Download CSV" />
      <Button
        variant="primary"
        size="sm"
        href={getRouteURI(ROUTES.ORDER_NEW)}
        className="pull-right"
      >
        <Fa name="plus" />{' '}
        <FormattedMessage id="record.order.add" defaultMessage="Add Order" />
      </Button>
    </div>
  );

  const breadcrumbs = isRestrictedUser ? ['orders'] : ['plan', 'orders'];

  const columns = [
    isDebugModeEnabled && {
      type: 'custom',
      uid: 'field.line_items',
      accessor: 'line_items',
      defaultMessage: 'Line Items Amount',
      Header: () => (
        <>
          <Badge className="badge badge-sm" bg="warning">Debug Mode</Badge>
          <p>Line Items Count</p>
        </>
      ),
      Cell: ({ row: { original: data } }) => (
        <>
          <p>{data?.line_items?.length ?? 0}</p>
        </>
      ),
    },
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'order',
      route: isRestrictedUser ? ROUTES.ORDER_RESTRICTED_EDIT : ROUTES.ORDER_EDIT,
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: ORDER_STATUS_MAP,
      coloured: true,
      colorScheme: STATUS_COLOR_CODE_TYPES.ORDER_STATUS,
      isSortable: true,
    },
    {
      type: 'time',
      uid: 'created',
      accessor: 'created',
      defaultMessage: 'Created',
      isSortable: true,
    },
    isBoeingUser && (
      {
        type: 'translatable',
        uid: 'fields.customer_go',
        accessor: 'customer_go',
        defaultMessage: 'Customer Go',
        mapping: ORDER_CUSTOMER_GO_MAP,
        isSortable: true,
      }
    ),
    isBoeingUser && (
      {
        type: 'translatable',
        uid: 'fields.build_file_status',
        accessor: 'build_file_status',
        defaultMessage: 'Build File Status',
        mapping: ORDER_BUILD_FILE_STATUS_MAP,
        isSortable: true,
      }
    ),
    isBoeingUser && (
      {
        type: 'translatable',
        uid: 'fields.planning_status',
        accessor: 'planning_status',
        defaultMessage: 'Planning Status',
        mapping: ORDER_PLANNING_STATUS_MAP,
        isSortable: true,
      }
    ),
    {
      type: 'record',
      uid: 'orderName',
      accessor: 'name',
      defaultMessage: 'Order Name',
      resource: 'order',
      uri: 'uri',
      isSortable: true,
      route: isRestrictedUser ? ROUTES.REVIEW_RESTRICTED_ORDER : null,
    },
    isOrderOwnerColumnEnabled && (
      {
        type: 'text',
        uid: 'field.owner',
        accessor: 'order_owner_name',
        defaultMessage: 'Owner',
        isSortable: true,
      }
    ),
    {
      type: 'text',
      uid: 'field.customer_name',
      accessor: 'customer_name',
      defaultMessage: 'Customer Name',
      isSortable: true,
    },
    isOrderQuoteFeatureEnabled && (
      {
        type: 'text',
        uid: 'field.customer_po',
        accessor: 'customer_po',
        defaultMessage: 'Customer PO',
        isSortable: true,
      }
    ),
    isDanfossUser && businessSegmentCustomField && (
      {
        type: 'field',
        uid: 'field.businessSegments',
        accessor: 'custom_field_values',
        defaultMessage: 'Business Segments',
        customFieldReference: businessSegmentCustomField,
        isSortable: true,
      }
    ),
    isOrderDueDateFeatureEnabled && (
      {
        type: 'time',
        uid: 'field.dueDate',
        accessor: 'due_date',
        defaultMessage: 'Due Date',
        isSortable: true,
      }
    ),
  ];

  return (
    <TableWithSearching
      {...componentProps}
      isFetching={fetching}
      withBreadcrumbs
      breadcrumbs={breadcrumbs}
      navbar={NavbarLinks}
      data={data}
      columns={columns}
      isFilteringEnabled={false}
      withDefaultPagination={false}
      isManualSoringEnabled
      manualSortingFunc={onSort}
      initialSortedDesc
      initialSortedColumn={isDanfossUser ? 'created' : 'updated'}
    />
  );
};

Orders.defaultProps = {
  sort: '',
  search: '',
};

Orders.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  recordListItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isBoeingUser: PropTypes.bool.isRequired,
  isDanfossUser: PropTypes.bool.isRequired,
  isRestrictedUser: PropTypes.bool.isRequired,
  customFieldReferences: PropTypes.arrayOf(customFieldReferenceType).isRequired,
  filters: PropTypes.shape({}).isRequired,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.string,
  search: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  recordListStore: PropTypes.shape({}).isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  isOrderDueDateFeatureEnabled: PropTypes.bool.isRequired,
  isOrderOwnerColumnEnabled: PropTypes.bool.isRequired,
  isOrderQuoteFeatureEnabled: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      line_items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default Orders;
