import anatomicalModelDA from './anatomicalModel/da.json';
import locationDA from './location/da.json';
import materialDA from './material/da.json';
import orderDA from './order/da.json';
import postProcessorTypeDA from './postProcessorType/da.json';
import printerTypeDA from './printerType/da.json';
import runDA from './run/da.json';
import shipmentDA from './shipment/da.json';
import serviceProviderDA from './serviceProvider/da.json';
import shippingDA from './shipping/da.json';
import stockDA from './stock/da.json';
import workflowDA from './workflow/da.json';

import anatomicalModelDE from './anatomicalModel/de.json';
import locationDE from './location/de.json';
import materialDE from './material/de.json';
import orderDE from './order/de.json';
import postProcessorTypeDE from './postProcessorType/de.json';
import printerTypeDE from './printerType/de.json';
import runDE from './run/de.json';
import shipmentDE from './shipment/de.json';
import serviceProviderDE from './serviceProvider/de.json';
import shippingDE from './shipping/de.json';
import stockDE from './stock/de.json';
import workflowDE from './workflow/de.json';

import anatomicalModelEN from './anatomicalModel/en.json';
import locationEN from './location/en.json';
import materialEN from './material/en.json';
import orderEN from './order/en.json';
import postProcessorTypeEN from './postProcessorType/en.json';
import printerTypeEN from './printerType/en.json';
import runEN from './run/en.json';
import shipmentEN from './shipment/en.json';
import serviceProviderEN from './serviceProvider/en.json';
import shippingEN from './shipping/en.json';
import stockEN from './stock/en.json';
import workflowEN from './workflow/en.json';

import anatomicalModelFR from './anatomicalModel/fr.json';
import locationFR from './location/fr.json';
import materialFR from './material/fr.json';
import orderFR from './order/fr.json';
import postProcessorTypeFR from './postProcessorType/fr.json';
import printerTypeFR from './printerType/fr.json';
import runFR from './run/fr.json';
import shipmentFR from './shipment/fr.json';
import serviceProviderFR from './serviceProvider/fr.json';
import shippingFR from './shipping/fr.json';
import stockFR from './stock/fr.json';
import workflowFR from './workflow/fr.json';

import anatomicalModelJA from './anatomicalModel/ja.json';
import locationJA from './location/ja.json';
import materialJA from './material/ja.json';
import orderJA from './order/ja.json';
import postProcessorTypeJA from './postProcessorType/ja.json';
import printerTypeJA from './printerType/ja.json';
import runJA from './run/ja.json';
import shipmentJA from './shipment/ja.json';
import serviceProviderJA from './serviceProvider/ja.json';
import shippingJA from './shipping/ja.json';
import stockJA from './stock/ja.json';
import workflowJA from './workflow/ja.json';

export const recordsDA = Object.assign(
  anatomicalModelDA,
  locationDA,
  materialDA,
  orderDA,
  postProcessorTypeDA,
  printerTypeDA,
  runDA,
  shipmentDA,
  serviceProviderDA,
  shippingDA,
  stockDA,
  workflowDA,
);

export const recordsDE = Object.assign(
  anatomicalModelDE,
  locationDE,
  materialDE,
  orderDE,
  postProcessorTypeDE,
  printerTypeDE,
  runDE,
  shipmentDE,
  serviceProviderDE,
  shippingDE,
  stockDE,
  workflowDE,
);

export const recordsEN = Object.assign(
  anatomicalModelEN,
  locationEN,
  materialEN,
  orderEN,
  postProcessorTypeEN,
  printerTypeEN,
  runEN,
  shipmentEN,
  serviceProviderEN,
  shippingEN,
  stockEN,
  workflowEN,
);

export const recordsFR = Object.assign(
  anatomicalModelFR,
  locationFR,
  materialFR,
  orderFR,
  postProcessorTypeFR,
  printerTypeFR,
  runFR,
  shipmentFR,
  serviceProviderFR,
  shippingFR,
  stockFR,
  workflowFR,
);

export const recordsJA = Object.assign(
  anatomicalModelJA,
  locationJA,
  materialJA,
  orderJA,
  postProcessorTypeJA,
  printerTypeJA,
  runJA,
  shipmentJA,
  serviceProviderJA,
  shippingJA,
  stockJA,
  workflowJA,
);
