import React from 'react';
import { Button, Col, Row, Container, SplitButton, Dropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Fa from 'react-fontawesome';
import {
  FEATURES,
  RESTRICTED_USER_ORDER_STEP_REVIEW_ORDER,
  RESTRICTED_USER_ORDER_STEPS,
  ORDER_UNLOCKED_STATUSES,
  COMMENT_RELATED_TABLE_NAMES, ROUTES, API_RESOURCES,
} from 'rapidfab/constants';
import Actions from 'rapidfab/actions';
import CommentsContainer from 'rapidfab/containers/CommentsContainer';
import Stepper from 'rapidfab/components/stepper/Stepper';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import PropTypes from 'prop-types';
import * as Selectors from 'rapidfab/selectors';
import { connect } from 'react-redux';
import ReviewPreviewOrder from 'rapidfab/components/records/order/restricted/review/ReviewPreviewOrder';
import OrderQuote from 'rapidfab/components/records/order/edit/OrderQuote';
import Feature from 'rapidfab/components/Feature';
import extractUuid from 'rapidfab/utils/extractUuid';
import prepareStepperURI from 'rapidfab/utils/prepareStepperURI';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import isOrderQuoteAvailable from 'rapidfab/utils/isOrderQuoteAvailable';
import { orderResourceType } from 'rapidfab/types';
import OrderProductsContainer from 'rapidfab/containers/OrderProductsContainer';

const EditButton = ({ order, onDuplicate }) => (
  <SplitButton
    size="sm"
    variant="success"
    title="Edit This Order"
    href={getRouteURI(ROUTES.ORDER_RESTRICTED_EDIT, { uuid: order.uuid })}
  >
    <Dropdown.Item eventKey={0} onClick={() => onDuplicate(order.uuid)}>
      <Fa name="clone" />{' '}
      <FormattedMessage id="button.duplicate" defaultMessage="Duplicate" />
    </Dropdown.Item>
  </SplitButton>
);
EditButton.propTypes = {
  order: orderResourceType.isRequired,
  onDuplicate: PropTypes.func.isRequired,
};

const ReorderButton = ({ onDuplicate, order }) => (
  <Button size="sm" variant="success" onClick={() => onDuplicate(order.uuid)}>
    Reorder
  </Button>
);
ReorderButton.propTypes = {
  order: orderResourceType.isRequired,
  onDuplicate: PropTypes.func.isRequired,
};

const ReviewOrder = ({
  users,
  locationsAvailable,
  order,
  isOrderBusinessSegmentFeatureEnabled,
  isBoeingOrderFieldsFeatureEnabled,
  is3MOrderFieldsFeatureEnabled,
  orderQuoteFeatureEnabled,
  orderQuoteSpecialProcessingFeatureEnabled,
  onDuplicate,
}) => {
  const orderQuoteAvailable = isOrderQuoteAvailable(
    order,
    orderQuoteFeatureEnabled,
    orderQuoteSpecialProcessingFeatureEnabled,
  );

  const isOrderUnlocked = ORDER_UNLOCKED_STATUSES.includes(order.status);

  return (
    <Container fluid>
      <BreadcrumbNav
        breadcrumbs={[
          'orders',
          order.id
            ? `${order.name} (${order.customer_id || getShortUUID(order.uuid)})`
            : '',
        ]}
      />

      <Stepper
        steps={prepareStepperURI(RESTRICTED_USER_ORDER_STEPS, { uuid: order.uuid })}
        activeStep={RESTRICTED_USER_ORDER_STEP_REVIEW_ORDER}
      />

      <div className="jumbotron">
        <h2>Review Order</h2>
        <p>Your order has been successfully submitted.</p>
        <p>
          <Button className="m-r btn-default" size="sm" href={getRouteURI(ROUTES.ORDERS)}>
            Return to Orders
          </Button>
          <Button
            className="m-r btn-default"
            size="sm"
            href={getRouteURI(ROUTES.ORDER_NEW)}
          >
            Create New Order
          </Button>
          {isOrderUnlocked ? (
            <EditButton order={order} onDuplicate={onDuplicate} />
          ) : (
            <ReorderButton order={order} onDuplicate={onDuplicate} />
          )}
        </p>

        <hr />

        <Row>
          <Col xs={12} md={7}>
            <ReviewPreviewOrder
              isRestricted
              fields={order}
              locationsAvailable={locationsAvailable}
              users={users}
              isOrderBusinessSegmentFeatureEnabled={isOrderBusinessSegmentFeatureEnabled}
              isBoeingOrderFieldsFeatureEnabled={isBoeingOrderFieldsFeatureEnabled}
              is3MOrderFieldsFeatureEnabled={is3MOrderFieldsFeatureEnabled}
            />
          </Col>
          <Feature featureName={FEATURES.ORDER_QUOTE_AVAILABLE_FOR_RESTRICTED}>
            <Col xs={12} md={5}>
              {
                orderQuoteAvailable &&
                <OrderQuote />
              }
            </Col>
          </Feature>
        </Row>
      </div>

      <CommentsContainer
        resourceTableName={COMMENT_RELATED_TABLE_NAMES.ORDER}
        resourceUUID={order.uuid}
      />
      <OrderProductsContainer
        orderUri={order.uri}
        readOnlyLineItemsOrCallback
      />
    </Container>
  );
};
ReviewOrder.defaultProps = {
  order: {},
};

ReviewOrder.propTypes = {
  order: orderResourceType,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locationsAvailable: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOrderBusinessSegmentFeatureEnabled: PropTypes.bool.isRequired,
  isBoeingOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  is3MOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  orderQuoteFeatureEnabled: PropTypes.bool.isRequired,
  orderQuoteSpecialProcessingFeatureEnabled: PropTypes.bool.isRequired,
  onDuplicate: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const order = Selectors.getRouteUUIDResource(state);
  const users = Selectors.getUsersByUri(state);
  const boeingOrderFieldsFeature = Selectors.isFeatureEnabled(state, FEATURES.BOEING_ORDER_FIELDS);
  const threeMOrderFieldsFeature = Selectors.isFeatureEnabled(
    state,
    FEATURES.THREE_M_MAIN_BUREAU_ORDER_FIELDS,
  );

  return {
    order,
    users,
    locationsAvailable: Selectors.getLocationsByUri(state),
    isOrderBusinessSegmentFeatureEnabled: Selectors.isFeatureEnabled(
      state, FEATURES.ORDER_BUSINESS_SEGMENT,
    ),
    is3MOrderFieldsFeatureEnabled: threeMOrderFieldsFeature,
    isBoeingOrderFieldsFeatureEnabled: boeingOrderFieldsFeature,
    orderQuoteFeatureEnabled: Selectors.isFeatureEnabled(state, FEATURES.ORDER_QUOTE),
  };
};

const mapDispatchToProps = dispatch => ({
  onDuplicate: uuid => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].clone(uuid))
      .then(response => {
        const { headers: { location } } = response;
        const duplicatedOrderUUID = extractUuid(location);
        window.location.hash = getRouteURI(ROUTES.ORDER_RESTRICTED_EDIT, { uuid: duplicatedOrderUUID });
      })
      .catch(error => console.error(error));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewOrder);
