import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import {
  ButtonToolbar,
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
} from 'react-bootstrap';
import { FormControlSelect, FormControlTextArea } from 'rapidfab/components/formTools';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import Fa from 'react-fontawesome';
import { MATERIAL_LOT_STATUS_MAP } from 'rapidfab/mappings';
import { MATERIAL_LOT_STATUS_TRANSFORMATIONS } from 'rapidfab/transformations';
import { FormattedMessage, FormattedMessageMappingOption } from 'rapidfab/i18n';
import Loading from 'rapidfab/components/Loading';
import ContainersEditableGrid from 'rapidfab/components/records/material_management/ContainersEditableGrid';
import MaterialInfo from 'rapidfab/components/records/material_management/MaterialInfo';
import Documents from 'rapidfab/components/records/Documents';
import MaterialLotInitialBatches from 'rapidfab/components/records/material_management/MaterialLotInitialBatches';
import MaterialLotPurchaseOrder from 'rapidfab/components/records/material_management/MaterialLotPurchaseOrder';
import MaterialLotLinkPOModal from 'rapidfab/components/records/material_management/MaterialLotLinkPOModal';
import MaterialLotCreatePOModal from 'rapidfab/components/records/material_management/MaterialLotCreatePOModal';
import MaterialLotReceiveModal from 'rapidfab/components/records/material_management/MaterialLotReceiveModal';
import { DOCUMENT_RELATED_TABLE_NAMES, ROUTES, MATERIAL_LOT_STATUSES } from 'rapidfab/constants';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import ResourceReadOnlyViewRow, { RESOURCE_READ_ONLY_VIEW_FIELD_TYPES } from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import {
  materialLotResourceType,
  materialTypeResourceType,
  finalFormInputTypes, subLocationResourceType,
} from 'rapidfab/types';
import Tooltip from 'rapidfab/components/Tooltip';
import { Link } from 'react-router-dom';
import getRouteURI from 'rapidfab/utils/getRouteURI';

import { Form, Field } from 'react-final-form';

const MODALS = {
  LINK_PO: 'link-po',
  CREATE_PO: 'create-po',
  RECEIVE: 'receive',
};

const MaterialLotDetailsPanelHeader = ({ isLotSaving }) => (
  <Row className="d-flex align-items-center">
    <Col xs={6}>Lot Details</Col>
    <Col xs={6} className="d-flex justify-content-end">
      <Button form="lot" type="submit" variant="success" size="sm" disabled={isLotSaving}>
        {isLotSaving ? (
          <Loading />
        ) : (
          <FormattedMessage id="button.save" defaultMessage="Save" />
        )}
      </Button>
    </Col>
  </Row>
);
MaterialLotDetailsPanelHeader.propTypes = {
  isLotSaving: PropTypes.bool.isRequired,
};

const MaterialLot = ({
  containers,
  initialBatches,
  material,
  lot,
  onSaveLot,
  onSaveContainers,
  onReceiveLot,
  isLotSaving,
  isLotActionSaving,
  initialFormValues,
  subLocations,
}) => {
  const isOnOrderLot = lot.status === MATERIAL_LOT_STATUSES.ON_ORDER;

  const [visibleModal, setVisibleModal] = useState(null);
  const onOpenLinkModal = () => {
    setVisibleModal(MODALS.LINK_PO);
  };
  const onOpenCreateModal = () => {
    setVisibleModal(MODALS.CREATE_PO);
  };
  const onOpenReceiveModal = () => {
    setVisibleModal(MODALS.RECEIVE);
  };
  const onCloseModals = () => setVisibleModal(null);

  const onContainersSubmit = (bulkContainersOperations, reasonCode) =>
    onSaveContainers({
      uri: lot.uri,
      bulkContainersOperations,
      reasonCode,
    });

  const isOnOrder = lot.status === MATERIAL_LOT_STATUSES.ON_ORDER;

  const statusOptions = MATERIAL_LOT_STATUS_TRANSFORMATIONS[lot.status] || [];
  const defaultSubLocation = subLocations.find(object => object.is_default);

  return (
    <Container fluid className="p-b">
      <BreadcrumbNav
        breadcrumbs={[
          'inventory',
          'materialLots',
          `${lot.name} (${getShortUUID(lot.uuid)})`,
        ]}
      />

      <ButtonToolbar>
        <div className="pull-left btn-toolbar-item">
          <div>
            <Link
              to={getRouteURI(ROUTES.PRINT_MATERIAL_LOT_CONTAINERS_QR_CODES, { uuid: lot.uuid }, {}, true)}
            >
              <Fa name="qrcode" size="lg" className="spacer-right" />
              Print Containers QR Codes
            </Link>
          </div>
        </div>
      </ButtonToolbar>
      <hr />
      <Row>
        <Col xs={12} sm={6}>
          <Form
            onSubmit={onSaveLot}
            initialValues={initialFormValues}
            render={({ handleSubmit }) => (
              <form id="lot" onSubmit={handleSubmit}>
                <Card bg="dark" className="m-b">
                  <Card.Header className="pd-exp accent">
                    <MaterialLotDetailsPanelHeader isLotSaving={isLotSaving} />
                  </Card.Header>
                  <div className="card-body-wrapper-accent">
                    <Card.Body>
                      <FormGroup>
                        <FormLabel>
                          <FormattedMessage id="field.name" defaultMessage="Name" />:
                          *
                        </FormLabel>
                        <Field
                          name="name"
                          render={props => (
                            <FormControl
                              {...props.input}
                              required
                            />
                          )}
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormLabel>
                          <FormattedMessage
                            id="field.deliveryId"
                            defaultMessage="Delivery ID"
                          />
                          :
                        </FormLabel>
                        <Field
                          name="delivery_id"
                          render={props => (
                            <FormControl {...props.input} />
                          )}
                        />
                      </FormGroup>

                      <FormGroup>
                        <FormLabel>
                          <FormattedMessage
                            id="field.status"
                            defaultMessage="Status"
                          />
                          : *
                        </FormLabel>
                        <div className="d-flex align-items-center">
                          <Field
                            name="status"
                            render={props => (
                              <FormControlSelect
                                {...props.input}
                                required
                                disabled={isOnOrder}
                              >
                                {statusOptions.map(statusOption => (
                                  <FormattedMessageMappingOption
                                    value={statusOption}
                                    mapping={MATERIAL_LOT_STATUS_MAP}
                                  />
                                ))}
                              </FormControlSelect>
                            )}
                          />
                          {isOnOrder && (
                            <FormattedMessage
                              id="materialLot.onOrder.statustooltip"
                              defaultMessage="In order to change the status of an On Order lot, receive the lot using the Purchase Order section."
                            >
                              {text => (
                                <Tooltip id="onOrderStatusMessage" placement="left">
                                  {text}
                                </Tooltip>
                              )}
                            </FormattedMessage>
                          )}
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <FormLabel>
                          <FormattedMessage id="field.notes" defaultMessage="Notes" />
                          :
                        </FormLabel>
                        <FormControlTextArea name="notes" initialValue={initialFormValues?.notes} />
                      </FormGroup>
                      <hr />
                      <ResourceReadOnlyView entity={lot}>
                        {isOnOrder && (
                          <ResourceReadOnlyViewRow
                            name="status"
                            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                            customValue={
                              lot && (
                                <FormattedMessage
                                  {...MATERIAL_LOT_STATUS_MAP[lot.status]}
                                />
                              )
                            }
                          />
                        )}
                        <ResourceReadOnlyViewRow
                          name="material_name"
                          label="Material"
                        />
                        <ResourceReadOnlyViewRow
                          name="quantity"
                          type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                          customValue={lot && `${lot.quantity} ${lot.units}`}
                        />
                        <ResourceReadOnlyViewRow
                          name="location_name"
                          label="Location"
                          type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                          customValue={
                            lot && `${lot.location_name} ${defaultSubLocation ? `(${defaultSubLocation.name})` : ''}`
                          }
                        />
                        <hr />
                        {isOnOrder ? (
                          <ResourceReadOnlyViewRow
                            name="estimated_delivery_date"
                            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATE}
                          />
                        ) : (
                          <ResourceReadOnlyViewRow
                            name="actual_delivery_date"
                            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATE}
                          />
                        )}
                        <ResourceReadOnlyViewRow
                          name="expiration_date"
                          type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATE}
                        />
                        <ResourceReadOnlyViewRow name="usage_cycles_limit" />
                        <ResourceReadOnlyViewRow
                          name="updated"
                          type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATETIME}
                        />
                      </ResourceReadOnlyView>
                    </Card.Body>
                  </div>
                </Card>
              </form>
            )}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Documents
            relatedTable={DOCUMENT_RELATED_TABLE_NAMES.MATERIAL_LOT}
            relatedUUID={lot.uuid}
          />
          <div className="m-b">
            <MaterialInfo material={material} />
          </div>
          <MaterialLotInitialBatches
            initialBatches={initialBatches}
            lot={lot}
          />
          <MaterialLotPurchaseOrder
            onOpenLinkModal={onOpenLinkModal}
            onOpenReceiveModal={onOpenReceiveModal}
            lot={lot}
          />
        </Col>
      </Row>
      <ContainersEditableGrid
        isOnOrderLot={isOnOrderLot}
        panelHeading="Material Lot Containers"
        deleteContainersAllowed
        addContainersAllowed
        showAddedToBatchColumn
        showReasonInput={false}
        containers={containers}
        units={lot.units}
        onSaveContainers={onContainersSubmit}
        isSaving={isLotActionSaving}
        isContainerEditable={container =>
          container.is_available_for_initial_batches}
        uuid={lot.uuid}
        route={ROUTES.PRINT_MATERIAL_LOT_CONTAINER_QR_CODE}
      />

      {visibleModal === MODALS.LINK_PO && (
        <MaterialLotLinkPOModal
          onClose={onCloseModals}
          onOpenCreateModal={onOpenCreateModal}
          material={material}
          lotUri={lot.uri}
        />
      )}

      {visibleModal === MODALS.CREATE_PO && (
        <MaterialLotCreatePOModal
          onClose={onCloseModals}
          material={material}
          lotUri={lot.uri}
        />
      )}

      {visibleModal === MODALS.RECEIVE && (
        <MaterialLotReceiveModal
          onClose={onCloseModals}
          lot={lot}
          containers={containers}
          onSaveContainers={onSaveContainers}
          onReceiveLot={onReceiveLot}
        />
      )}
    </Container>
  );
};

MaterialLot.propTypes = {
  containers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initialBatches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  lot: materialLotResourceType.isRequired,
  material: materialTypeResourceType.isRequired,
  onSaveLot: PropTypes.func.isRequired,
  onSaveContainers: PropTypes.func.isRequired,
  onReceiveLot: PropTypes.func.isRequired,
  isLotActionSaving: PropTypes.bool.isRequired,
  isLotSaving: PropTypes.bool.isRequired,
  initialFormValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    notes: PropTypes.string.isRequired,
    delivery_id: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  input: finalFormInputTypes.isRequired,
  subLocations: PropTypes.arrayOf(subLocationResourceType).isRequired,
};

export default MaterialLot;
