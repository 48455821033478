import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import { getMaterialBatchesTemporary } from 'rapidfab/selectors';

import MaterialBatches from 'rapidfab/components/inventory/MaterialBatches';
import _difference from 'lodash/difference';

import { MATERIAL_BATCH_STATUSES, API_RESOURCES, MATERIAL_CONTAINER_STATUSES, LIST_BY_URIS_CHUNK_SIZE } from 'rapidfab/constants';

import { Form } from 'react-bootstrap';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';

import { useDispatch, useSelector } from 'react-redux';
import _chunk from 'lodash/chunk';
import _uniq from 'lodash/uniq';
import _flatMapDeep from 'lodash/flatMapDeep';
import * as Selectors from 'rapidfab/selectors';

const { DONE, CONSUMED, ...ACTIVE_MATERIAL_BATCH_STATUSES } = MATERIAL_BATCH_STATUSES;
const activeMaterialBatchStatuses = Object.values(ACTIVE_MATERIAL_BATCH_STATUSES);

const MaterialBatchesContainer = ({ filters, onFilterUpdate, ...restProps }) => {
  const subLocations = useSelector(Selectors.getSubLocations);

  const onCheckboxChange = event => {
    const { checked, name } = event.target;
    const changedFilters = { ...filters };

    switch (name) {
      case 'hideNonInitial':
        if (checked) {
          changedFilters.is_initial_batch = true;
        } else {
          delete changedFilters.is_initial_batch;
        }
        break;
      case 'onlyActive':
        if (checked) {
          changedFilters.status = activeMaterialBatchStatuses;
        } else {
          delete changedFilters.status;
        }
        break;
      case 'onlyDone':
        if (checked) {
          changedFilters.status = 'done';
        } else {
          delete changedFilters.status;
        }
        break;
      default:
        break;
    }
    onFilterUpdate(changedFilters);
  };

  const dispatch = useDispatch();
  const loadMaterialContainers = uris => {
    _chunk(uris, LIST_BY_URIS_CHUNK_SIZE).forEach(urisChunk => {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list(
        { status: [MATERIAL_CONTAINER_STATUSES.NEW, MATERIAL_CONTAINER_STATUSES.IN_USE], uri: urisChunk },
        {},
        {},
        {},
        true,
      ),
      );
    });
  };

  useEffect(() => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list());
  }, []);

  useEffect(() => {
    if (restProps?.data.length) {
      const uris = _uniq(_flatMapDeep(restProps.data.map(item => item.containers)));
      loadMaterialContainers(uris);
    }
  }, [JSON.stringify(restProps?.data.map(item => item.uri))]);

  return (
    <MaterialBatches
      {...restProps}
      subLocations={subLocations}
      extraFilters={[
        <Form.Check
          name="onlyDone"
          checked={filters.status === 'done'}
          onChange={onCheckboxChange}
          type="checkbox"
          label="Only Done Batches"
        />,
        <Form.Check
          name="hideNonInitial"
          checked={filters.is_initial_batch}
          onChange={onCheckboxChange}
          type="checkbox"
          label="Only Initial Batches"
        />,
        <Form.Check
          name="onlyActive"
          checked={!_difference(activeMaterialBatchStatuses, filters.status).length}
          onChange={onCheckboxChange}
          type="checkbox"
          label="Only Active"
        />,
      ]}
    />
  );
};

MaterialBatchesContainer.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    is_initial_batch: PropTypes.bool,
    status: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default withRecordsListHandling(
  MaterialBatchesContainer,
  getMaterialBatchesTemporary,
  ['material-batch'],
);
