export default function isURI(uri) {
  try {
    // eslint-disable-next-line no-unused-vars
    const urlFromUri = new URL(uri);
  } catch {
    return false;
  }
  return true;
}

export const isURIRegex = uri => {
  if (typeof uri !== 'string') {
    return false;
  }

  const match = uri.match(/(http(s)?:\/\/.)?(www\.)?[\w#%+.:=@~-]{2,256}\.[a-z]{2,6}\b([\w#%&+./:=?@~-]*)/g);
  return (match !== null);
};

export const isURLRegex = url => {
  if (typeof url !== 'string') {
    return false;
  }

  const regex = new RegExp('((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)');
  const match = url.match(regex);
  return (match !== null);
};
