import React, { useState } from 'react';
import Actions from 'rapidfab/actions';
import Fa from 'react-fontawesome';
import FileInput from 'rapidfab/components/records/order/edit/FileInput';
import PropTypes from 'prop-types';
import { Button, Col, FormControl, OverlayTrigger,
  Card, Row, Table, Tooltip } from 'react-bootstrap';
import { FormattedMessage, FormattedMessageMappingOption } from 'rapidfab/i18n';
import { MODEL_UNITS_MAP, MODEL_UNITS_MAP_VIEW_MODEL_IN } from 'rapidfab/mappings';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';
import getLineItemFormOptions from 'rapidfab/utils/lineItemFormOptions';
import {
  API_RESOURCES, ROUTES,
  FEATURES, FILE_EXTENSIONS, MODEL_TYPES, MODEL_UNITS,
} from 'rapidfab/constants';
import { Form, Field } from 'react-final-form';
import convertLengthToOtherUnit from 'rapidfab/utils/convertLengthToOtherUnit';
import extractUuid from 'rapidfab/utils/extractUuid';
import Alert from 'rapidfab/utils/alert';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { useIntl } from 'react-intl';

const FileDetailsField = ({ leadingText, content, enableTopMargin = true }) => (
  <div className={enableTopMargin && 'mt15'}>
    <b>{leadingText}</b>
    { content }
  </div>
);

FileDetailsField.defaultProps = {
  enableTopMargin: true,
};

FileDetailsField.propTypes = {
  leadingText: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
  enableTopMargin: PropTypes.bool,
};

const LineItemFileDetails = ({
  dispatch,
  model,
  modelUpload,
  handleFileRemove,
  handleFileChange,
  editMode,
  setEditMode,
  lineItem,
  isCADToSTLConversionFeatureEnabled,
  isCurrentUserRestricted,
  isRestrictedUploadModelLibraryFeatureEnabled,
  readOnly,
  onSubmitComplete,
  pieces,
  features,
  workflowTypeKey,
}) => {
  const isReadOnly = typeof readOnly === 'function' ? readOnly(lineItem) : readOnly;

  const options = getLineItemFormOptions({
    features,
    isReadOnly,
  });

  const formatDigit = (value, decimalPlaces) => (Number(value).toFixed(decimalPlaces));

  let allowFileChange = true;

  if (isRestrictedUploadModelLibraryFeatureEnabled) {
    allowFileChange = !isCurrentUserRestricted;
  }

  const AXES = [
    { label: 'X', value: 'theta_x', letter: 'x' },
    { label: 'Y', value: 'theta_y', letter: 'y' },
    { label: 'Z', value: 'theta_z', letter: 'z' },
  ];

  const intl = useIntl();
  const [isSaving, setIsSaving] = useState(false);

  const conversionMultiplier = convertLengthToOtherUnit(1, model?.file_unit, MODEL_UNITS.MM);
  const onSubmit = async ({ modelUserUnits, modelFileUnits }) => {
    if (
      model && !modelUpload && (modelUserUnits !== model.user_unit
      || modelFileUnits !== model.file_unit)
    ) {
      setIsSaving(true);
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.MODEL].put(extractUuid(lineItem[workflowTypeKey].model), {
          file_unit: modelFileUnits === MODEL_UNITS.AUTOMATIC ? null : modelFileUnits,
          user_unit: modelUserUnits === MODEL_UNITS.AUTOMATIC ? null : modelUserUnits,
        }),
      )
        .then(onSubmitComplete)
        .then(() => setIsSaving(false))
        .then(() => Alert.success(intl.formatMessage(
          { id: 'toaster.lineItem.successfullyUpdated', defaultMessage: 'Line Item successfully updated.' },
        )))
        .then(() => setEditMode(false))
        .catch(error => {
          console.error(error);
          //
          Alert.error(error);
        })
        .finally(() => dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL]
          .get(extractUuid(lineItem[workflowTypeKey].model, true))));
    }

    if (modelUpload && !lineItem[workflowTypeKey].no_model_upload) {
      const modelFileNameParts = modelUpload.name.split('.');
      const extension = modelFileNameParts.pop().toLowerCase();
      const fileNameWithoutExtension = modelFileNameParts.join('.');
      const modelPayload = {
        name: `${fileNameWithoutExtension}.${FILE_EXTENSIONS.STL}`,
        file_unit: modelFileUnits === MODEL_UNITS.AUTOMATIC ? null : modelFileUnits,
        user_unit: modelUserUnits === MODEL_UNITS.AUTOMATIC ? null : modelUserUnits,
        type: MODEL_TYPES.STL,
      };
      if (extension !== FILE_EXTENSIONS.STL) {
        modelPayload.conversion_original_type = extension;
        modelPayload.conversion_original_filename = modelUpload.name;
      }
      dispatch(Actions.UploadModel.uploadProgress(0));
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.MODEL].post(modelPayload),
      )
        .then(args => {
          const { location, uploadLocation } = args.headers;
          const payload = { additive: { model: location } };
          return dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].put(lineItem.uuid, payload))
            .then(() => dispatch(
              Actions.UploadModel.upload(uploadLocation, modelUpload),
            ))
            .finally(onSubmitComplete);
        });
    }
  };

  return (
    <Form
      initialValues={{
        modelFileUnits: model?.file_unit || MODEL_UNITS.AUTOMATIC,
        modelUserUnits: model?.user_unit || MODEL_UNITS.AUTOMATIC,
      }}
      onSubmit={async values => {
        await onSubmit(values);
      }}
    >
      {({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit}>
          <Card className="card-body-wrapper-info" bg="dark">
            <Card.Header className="card-header-info">
              <div className="d-flex justify-content-between align-items-center">
                <p className="fs-3">
                  <FormattedMessage id="record.modelLibrary.fileDetails" defaultMessage="File Details" />
                </p>
                {
                  editMode ?
                    (
                      <div className="d-flex">
                        <Button
                          variant="primary"
                          size="sm"
                          className="spacer-right"
                          type="button"
                          onClick={() => {
                            form.reset();
                            setEditMode(false);
                            setIsSaving(false);
                          }}
                        >
                          <Fa name="close" />
                        </Button>
                        <Button
                          variant="primary"
                          size="sm"
                          className="pull-right"
                          type="submit"
                          disabled={isSaving}
                        >
                          { isSaving ? <Fa name="spinner" spin /> : <Fa name="save" /> }
                        </Button>
                      </div>
                    )
                    :
                    (
                      <Button
                        variant="primary"
                        size="sm"
                        className="pull-right"
                        type="button"
                        onClick={() => setEditMode(true)}
                      >
                        <FormattedMessage id="button.edit" defaultMessage="Edit" />
                      </Button>
                    )
                }
              </div>
            </Card.Header>
            <Card.Body>
              {!lineItem?.no_model_upload && (
                <FileDetailsField
                  enableTopMargin={false}
                  leadingText={isCADToSTLConversionFeatureEnabled ? 'Model File' : 'Model'}
                  content={
                    model && (
                      <>
                        <br />
                        <Button
                          className="p-a-0"
                          variant="link"
                          // the model.content endpoint is invoked only when the manufacturing_orientation field has
                          // null value or when the theta angles are = (0.0, 0.0, 0.0) or (null, null, null),
                          // the model.mfg_oriented_content is invoked in all other cases.
                          onClick={() => {
                            const { uri: modelUri, manufacturing_orientation: mfg, mfg_oriented_content } = model;
                            const downloadOriginalContent = [
                              mfg.theta_x, mfg.theta_y, mfg.theta_z,
                            ].every(v => !v) || !mfg_oriented_content;
                            const contentURL = downloadOriginalContent ? model.content : mfg_oriented_content;
                            dispatch(Actions.DownloadModel.fetchModel(modelUri)).then(() => {
                              dispatch(
                                Actions.DownloadModel.downloadContent(
                                  model.name,
                                  contentURL,
                                ),
                              );
                            });
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <p className="text-break">{model.name}</p>
                        </Button>
                        <div className="pull-right">
                          <OverlayTrigger
                            placement="top"
                            overlay={(
                              <Tooltip>
                                <p>Last updated: {dayjs(model?.updated).format(
                                  'DD MMM YYYY, HH:mm',
                                )}
                                </p>
                                <p>({`Version ${(model?.replaced_models?.length || 0) + 1}`})</p>
                              </Tooltip>
                            )}
                          >
                            <a href={getRouteURI(
                              ROUTES.PIECE_EDIT,
                              { uuid: pieces[0]?.uuid },
                              { initialFilterByCADReplace: true },
                            )}
                            >
                              <Fa name="info-circle" />
                            </a>
                          </OverlayTrigger>
                        </div>
                      </>
                    )
                  }
                />
              )}

              {allowFileChange && (
                <FileDetailsField
                  leadingText="Replace Model:"
                  content={
                    modelUpload ? (
                      <div className="d-flex justify-content-between">
                        <p>{modelUpload?.name}</p>
                        <Button
                          size="xs"
                          link="danger"
                          onClick={handleFileRemove}
                        >
                          <Fa name="times" />
                        </Button>
                      </div>
                    )
                      : (
                        <FileInput
                          name="model"
                          fileType={FileInput.fileTypes.model}
                          required={false}
                          onlyFileInput
                          handleFileChange={handleFileChange}
                        />
                      )
                  }
                />
              )}

              <Row>
                <Col md={6}>
                  {options.modelFileUnits && (
                    <FileDetailsField
                      leadingText="File Units:"
                      content={(
                        editMode ? (
                          <Field
                            name="modelFileUnits"
                            render={({ input }) => (
                              <FormControl
                                {...input}
                                as="select"
                                size="sm"
                                required
                                disabled={isReadOnly}
                              >
                                {Object.keys(MODEL_UNITS_MAP).map(modelUnit => (
                                  <FormattedMessageMappingOption
                                    mapping={MODEL_UNITS_MAP}
                                    value={modelUnit}
                                    key={modelUnit}
                                  />
                                ))}
                              </FormControl>
                            )}
                          />

                        )
                          : (
                            <p>{MODEL_UNITS_MAP[values.modelFileUnits]?.defaultMessage}</p>
                          )
                      )}
                    />
                  )}
                </Col>
                <Col md={6}>
                  {options.modelUserUnits && (
                    <FileDetailsField
                      leadingText="View In:"
                      content={
                        editMode ? (
                          <div>
                            <Field
                              name="modelUserUnits"
                              render={({ input }) => (
                                <FormControl
                                  {...input}
                                  as="select"
                                  required
                                  disabled={isReadOnly}
                                >
                                  {Object.keys(MODEL_UNITS_MAP_VIEW_MODEL_IN).map(modelUnit => (
                                    <FormattedMessageMappingOption
                                      mapping={MODEL_UNITS_MAP_VIEW_MODEL_IN}
                                      value={modelUnit}
                                      key={modelUnit}
                                    />
                                  ))}
                                </FormControl>
                              )}
                            />

                          </div>
                        )
                          : (
                            <p>{MODEL_UNITS_MAP[values.modelUserUnits]?.defaultMessage}</p>
                          )
                      }
                    />
                  )}
                </Col>
              </Row>

              {model && model.volume_mm && (
                <FileDetailsField
                  leadingText="Volume:"
                  content={(
                    <p>{Math.round(model?.volume_mm)} {MODEL_UNITS.MM}<sup>3</sup>
                    </p>
                  )}
                />
              )}

              <FileDetailsField
                leadingText="Dimensions:"
                content={(
                  <p>
                    {
                      AXES
                        .map((axis, index) => (
                          <React.Fragment key={`axis${axis.letter}`}>
                            {formatDigit(+model?.size?.[axis.letter] * conversionMultiplier, 1)} {MODEL_UNITS.MM}
                            {index !== 2 && ' x '}
                          </React.Fragment>
                        ))
                    }
                  </p>
                )}
              />

              <FileDetailsField
                leadingText="Orientation:"
                content={(
                  <Table className="spacer-top" bordered condensed fill size="sm">
                    <thead>
                      <tr>
                        {
                          AXES
                            .map(axis => (
                              <th key={axis.label}>{axis.label}</th>
                            ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {
                          AXES
                            .map(axis => {
                              const value = model?.manufacturing_orientation?.[axis.value];
                              return (
                                <th key={`axes-row${axis.label}`} className="text-center">{
                                  value === null ? 'Null' : <>{value}&#176;</>
                                }
                                </th>
                              );
                            })
                        }
                      </tr>
                    </tbody>
                  </Table>
                )}
              />
            </Card.Body>
          </Card>
        </form>
      )}
    </Form>
  );
};

LineItemFileDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  model: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    volume_mm: PropTypes.number.isRequired,
    uuid: PropTypes.string.isRequired,
    size: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      z: PropTypes.number.isRequired,
    }).isRequired,
    file_unit: PropTypes.string.isRequired,
    user_unit: PropTypes.string.isRequired,
    updated: PropTypes.instanceOf(Date).isRequired,
    manufacturing_orientation: PropTypes.shape({
      theta_x: PropTypes.number,
      theta_y: PropTypes.number,
      theta_z: PropTypes.number,
      status: PropTypes.string,
    }).isRequired,
    mfg_oriented_content: PropTypes.string,
    replaced_models: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  modelUpload: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  handleFileChange: PropTypes.func.isRequired,
  handleFileRemove: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
  lineItem: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    no_model_upload: PropTypes.bool.isRequired,
    model: PropTypes.string.isRequired,
  }).isRequired,
  isCADToSTLConversionFeatureEnabled: PropTypes.bool.isRequired,
  isCurrentUserRestricted: PropTypes.bool.isRequired,
  isRestrictedUploadModelLibraryFeatureEnabled: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onSubmitComplete: PropTypes.func.isRequired,
  pieces: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string,
  })).isRequired,
  features: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  workflowTypeKey: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  const {
    base: baseMaterials,
    support: supportMaterials,
  } = Selectors.getBaseAndSupportMaterials(state);
  const baseMaterialsByLocationUri = Selectors.getBaseMaterialsByLocationUri(state);
  const model = Selectors.getModelForLineItem(state, props.lineItem);
  const compatibleWorkflowUrisByMaterialUris = Selectors.getWorkflowUrisByMaterialUri(state);
  const compatibleWorkflowUrisByShippingUris = Selectors.getWorkflowUrisByShippingUri(state);
  const modelLibraries = Selectors.getModelLibraries(state);
  const workflows = Selectors.getAvailableWorkflowsForLineItem(state, props.lineItem);
  const isRecalculationTriggerEnabled =
    Selectors.isFeatureEnabled(state, FEATURES.RECALCULATION_TRIGGER);
  const orderUuid = Selectors.getRouteUUID(state);
  const order = Selectors.getUUIDResource(state, orderUuid);
  const processSteps = Selectors.getProcessSteps(state);
  const printerTypes = Selectors.getPrinterTypes(state);
  const supportStrategies = Selectors.getSupportStrategies(state);
  const infillStrategies = Selectors.getInfillStrategies(state);
  const uploadModel = Selectors.getUploadModel(state);
  const isCurrentUserRestricted = Selectors.isCurrentUserRestricted(state);
  const customLineItemFieldReferences = Selectors.getCustomLineItemFieldReferences(state);
  const customOrderFieldReferences = Selectors.getCustomOrderFieldReferences(state);
  const pieces = Selectors.getPiecesForLineItem(state, props.lineItem);
  const shippingsByUri = Selectors.getShippingsByUri(state);
  const isShipmentForOrderFeatureEnabled = Selectors.isFeatureEnabled(state, FEATURES.SHIPMENT_FOR_ORDER);
  const orderBusinessSegmentFeatureEnabled = Selectors.isFeatureEnabled(
    state,
    FEATURES.ORDER_BUSINESS_SEGMENT,
  );

  const boeingOrderFieldsFeature = Selectors.isFeatureEnabled(state, FEATURES.BOEING_ORDER_FIELDS);

  const threeMOrderFieldsFeature = Selectors.isFeatureEnabled(
    state,
    FEATURES.THREE_M_MAIN_BUREAU_ORDER_FIELDS,
  );

  const restrictedUploadModelLibraryOnlyFeature = Selectors.isFeatureEnabled(
    state,
    FEATURES.RESTRICTED_USER_UPLOAD_FROM_MODEL_LIBRARY_ONLY,
  );
  const workChecklistLinkings = Selectors.getRelatedWorkChecklistLinking(state, props.lineItem.uri);

  const isCADToSTLConversionFeatureEnabled = Selectors.isFeatureEnabled(
    state,
    FEATURES.NATIVE_CAD_TO_STL_CONVERSION,
  );
  const features = Selectors.getFeatures(state);

  return {
    baseMaterials,
    baseMaterialsByLocationUri,
    customLineItemFieldReferences,
    customOrderFieldReferences,
    compatibleWorkflowUrisByMaterialUris,
    compatibleWorkflowUrisByShippingUris,
    model,
    modelLibraries,
    orderUuid,
    order,
    supportMaterials,
    workflows,
    workChecklistLinkings,
    processSteps,
    printerTypes,
    supportStrategies,
    infillStrategies,
    uploadModel,
    pieces,
    isRecalculationTriggerEnabled,
    isCurrentUserRestricted,
    isOrderBusinessSegmentFeatureEnabled: orderBusinessSegmentFeatureEnabled,
    isBoeingOrderFieldsFeatureEnabled: boeingOrderFieldsFeature,
    is3MOrderFieldsFeatureEnabled: threeMOrderFieldsFeature,
    isRestrictedUploadModelLibraryFeatureEnabled: restrictedUploadModelLibraryOnlyFeature,
    isUserRestricted: Selectors.isCurrentUserRestricted(state),
    isShipmentForOrderFeatureEnabled,
    shippingsByUri,
    isCADToSTLConversionFeatureEnabled,
    features,
  };
};

export default connect(mapStateToProps)(LineItemFileDetails);
