import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import usePrevious from 'rapidfab/hooks';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import PrinterForm from 'rapidfab/components/records/PrinterForm';
import extractUuid from 'rapidfab/utils/extractUuid';
import { API_RESOURCES, FEATURES, ROUTES } from 'rapidfab/constants';
import isFeatureEnabled from 'rapidfab/utils/isFeatureEnabled';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import Alert from 'rapidfab/utils/alert';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

function redirect() {
  window.location.hash = getRouteURI(ROUTES.PRINTERS);
}

function redirectToPrinter(uri) {
  window.location.hash = getRouteURI(ROUTES.PRINTER_EDIT, { uuid: extractUuid(uri) });
}

const PrinterFormContainer = props => {
  const uuid = useSelector(Selectors.getRouteUUID);
  const printer = useSelector(state =>
    ((props.route && props.route.uuid)
      ? Selectors.getRouteUUIDResource(state)
      : null));
  const locations = useSelector(Selectors.getLocationOptions);
  const printerTypes = useSelector(Selectors.getPrinterTypes);
  const features = useSelector(Selectors.getFeatures);

  const modeler = useSelector(state => ((printer
    && printer.modeler) ? Selectors.getUUIDResource(state, extractUuid(printer.modeler)) : null));

  const loadedBatch = useSelector(state => Selectors.getLoadedMaterialBatchForPrinter(state, printer));
  const loadedBatchLot =
    useSelector(state =>
      (loadedBatch ? Selectors.getUUIDResource(state, extractUuid(loadedBatch.material_lot)) : null));

  const submitting = useSelector(state => state.ui.nautilus[API_RESOURCES.PRINTER].put.fetching);
  const modelerBuild = useSelector(state =>
    modeler?.current_build && Selectors.getUUIDResource(state, extractUuid(modeler.current_build)),
  );
  const selected = {
    uuid,
    locations,
    printerTypes,
    features,
    loadedBatch,
    loadedBatchLot,
    modeler,
    printer,
    modelerBuild,
    submitting,
  };

  const [name, setName] = useState(() => (printer ? printer.name : ''));
  const [printerType, setPrinterType] = useState(() => (printer
    ? printer.printer_type
    : (printerTypes.length ? printerTypes[0].uri : '')));
  const [loading, setLoading] = useState(() => !printer);
  const [location, setLocation] = useState(() => (printer
    ? printer.location
    : (locations.length ? locations[0].uri : '')));

  const dispatch = useDispatch();

  const onInitialize = (currentUUID, currentFeatures) => {
    const promises = [
      dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list()),
      dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list()),
    ];

    if (currentUUID) {
      promises.push(
        dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].get(currentUUID)).then(response => {
          const { uri, modeler: currentModeler } = response.json;
          const isMaterialManagementEnabled = isFeatureEnabled(
            currentFeatures,
            FEATURES.MATERIAL_MANAGEMENT,
          );

          if (currentModeler) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER].get(extractUuid(currentModeler)))
              .then(modelerResponse => {
                const { current_build } = modelerResponse.json;
                if (current_build) {
                  dispatch(Actions.Api.nautilus.build.get(extractUuid(current_build)));
                }
              });
          }

          if (isMaterialManagementEnabled) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].list({ at_machine: uri }))
              .then(batchResponse => {
                const { resources } = batchResponse.json;
                const lotUri = resources[0] && resources[0].material_lot;
                if (lotUri) {
                  dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].get(extractUuid(lotUri)));
                }
              });
          }
        }),
      );
    }
    return Promise.all(promises);
  };
  const onDelete = currentUUID =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].delete(currentUUID));

  const onSubmit = (currentUUID, payload) => (
    currentUUID
      ? dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].put(currentUUID, payload))
        .then(() => {
          // Last Updated (user/date) info is set on the backend, and there is no
          // event-stream event for workflows. So GET request is required right
          // after PUT
          dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].get(currentUUID));
        })
      : dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].post(payload))
  );
  const onModelerSubmit = (currentUUID, modelerUri) => Promise.all([
    // modelerUri can be empty in case user deleted a modeler
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].put(currentUUID, { modeler: modelerUri })),
    // Loading modeler only if uri is set
    modelerUri && dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER].get(modelerUri)),
  ]);

  useEffect(() => {
    (async () => {
      await onInitialize(uuid, features);
      setLoading(false);
    })();
  }, []);

  const previousUUID = usePrevious(uuid);
  const previousPrinter = usePrevious(printer);
  const previousLocations = usePrevious(locations);
  const previousPrinterTypes = usePrevious(printerTypes);

  useEffect(() => {
    if (previousUUID && !previousPrinter && printer) {
      setName(printer.name);
      setPrinterType(printer.printer_type);
      setLocation(printer.location);
    } else if (
      !previousUUID &&
      previousLocations && previousLocations.length === 0 &&
      locations.length > 0
    ) {
      setLocation(locations[0].uri);
    } else if (
      !previousUUID &&
      previousPrinterTypes && previousPrinterTypes.length === 0 &&
      printerTypes.length > 0
    ) {
      setPrinterType(printerTypes[0].uri);
    }
  }, [printer, locations, uuid, printerTypes]);

  const handleDelete = () => {
    onDelete(uuid)
      .then(() => Alert.success(
        <FormattedMessage
          id="toaster.printer.deleted"
          defaultMessage="Printer {uuid} successfully deleted."
          values={{ uuid }}
        />,
      ))
      .finally(redirect);
  };

  const handleInputChange = event => {
    // eslint-disable-next-line no-shadow
    const { value, name } = event.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'printerType':
        setPrinterType(value);
        break;
      case 'location':
        setLocation(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    const payload = {
      name,
      printer_type: printerType,
      location,
    };
    onSubmit(uuid, payload).then(printerResponse => {
      if (uuid) {
        Alert.success(<FormattedMessage
          id="toaster.printer.updated"
          defaultMessage="Printer successfully updated"
        />);
      } else {
        const { uri } = printerResponse.payload;
        redirectToPrinter(uri);
        Alert.success(<FormattedMessage
          id="toaster.printer.created"
          defaultMessage="Printer successfully created"
        />);
      }
    });
  };

  const setModelerUri = modelerUri => {
    if (printer && printer.modeler === modelerUri) {
      // Modeler is already set
      return Promise.resolve();
    }
    return onModelerSubmit(uuid, modelerUri);
  };

  return (
    <PrinterForm
      name={name}
      location={location}
      loading={loading}
      printerType={printerType}
      {...props}
      {...selected}
      handleDelete={handleDelete}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      setModelerUri={setModelerUri}
    />
  );
};

PrinterFormContainer.propTypes = {
  route: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
};

export default PrinterFormContainer;
