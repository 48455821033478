import React, { useState } from 'react';
import { Button, Card, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import Fa from 'react-fontawesome';
import PropTypes from 'prop-types';
import truncateText from 'rapidfab/utils/truncateText';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { COLORS, GUIDELINES_EXPRESSION_FIELD_TYPES } from 'rapidfab/constants';

const SuggestionBlock = ({
  onEditSuggestionBeforeAdd,
  suggestionData,
  suggestionType,
  existingWorkInstructions,
  existingProcessSteps,
}) => {
  const [truncatedDescriptionState, setTruncatedDescriptionState] = useState({
    isTruncated: true,
    suggestion: null,
  });
  const [showTable, setShowTable] = useState(true);

  return (
    <Card border="info" bg="light" className="my-2">
      <Card.Header style={{ backgroundColor: COLORS.PURPLE }}>
        <b className="text-white">
          <Fa name="lightbulb-o" className="spacer-right" />
          {(suggestionType === GUIDELINES_EXPRESSION_FIELD_TYPES.WORK_INSTRUCTION && 'Work Instruction') ||
          (GUIDELINES_EXPRESSION_FIELD_TYPES.PROCESS_STEP && 'Process Step')} Suggestions ({suggestionData.length})
        </b>
        <Button
          size="xs"
          className="pull-right"
          variant="info"
          onClick={() => setShowTable(!showTable)}
        >
          {showTable ? 'Dismiss' : 'Show'}
          <Fa className="spacer-left" name={showTable ? 'chevron-up' : 'chevron-down'} />
        </Button>
      </Card.Header>
      {
        showTable && (
          <Card.Body>
            <Table size="sm">
              <thead>
                <tr className="text-black">
                  <TableHeaderColumn>
                    <p className="text-start">Suggested {
                      (suggestionType === GUIDELINES_EXPRESSION_FIELD_TYPES.WORK_INSTRUCTION && 'Work Instructions') ||
                    (suggestionType === GUIDELINES_EXPRESSION_FIELD_TYPES.PROCESS_STEP && 'Process Steps')
                    }
                    </p>
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    <p className="text-start">Description</p>
                  </TableHeaderColumn>
                </tr>
              </thead>
              <tbody>
                {
                  suggestionData?.map((suggestion, index) => {
                    const existingWorkInstructionDescriptions = existingWorkInstructions?.map(
                      instruction => instruction.description);

                    const existingProcessStepURIs = existingProcessSteps?.map(
                      processStep => processStep.workstation_type_uri,
                    );

                    const suggestionAlreadyUsed = existingWorkInstructionDescriptions?.includes(
                      suggestion.work_instruction_template?.description) ||
                      existingProcessStepURIs?.includes(
                        suggestion.process_step_template.workstation_type_uri,
                      );

                    return (
                      <tr>
                        <td>
                          <p
                            className={suggestionAlreadyUsed ? 'text-gray' : 'text-black'}
                            style={{ textAlign: 'left' }}
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={(
                                <Tooltip>
                                  <p>{suggestion.summary}</p>
                                </Tooltip>
                              )}
                            >
                              <Fa
                                className="spacer-right"
                                name="question-circle"
                              />
                            </OverlayTrigger>
                            {suggestionType === GUIDELINES_EXPRESSION_FIELD_TYPES.WORK_INSTRUCTION &&
                              suggestion.work_instruction_template?.description}
                            {suggestionType === GUIDELINES_EXPRESSION_FIELD_TYPES.PROCESS_STEP &&
                              suggestion.process_step_template.name}
                            {suggestionAlreadyUsed && '(Added)'}
                          </p>
                        </td>
                        <td>
                          <p
                            className={suggestionAlreadyUsed ? 'text-gray' : 'text-black'}
                            style={{ textAlign: 'left' }}
                          >
                            <Fa
                              role="button"
                              className="spacer-right"
                              name={!truncatedDescriptionState.isTruncated &&
                              truncatedDescriptionState.suggestion === index ?
                                'eye' : 'eye-slash'}
                              onClick={() => setTruncatedDescriptionState({
                                ...truncatedDescriptionState,
                                isTruncated: !truncatedDescriptionState.isTruncated,
                                suggestion: index,
                              })}
                            />
                            {
                              !truncatedDescriptionState.isTruncated &&
                            truncatedDescriptionState.suggestion === index ?
                                suggestion.name :
                                truncateText(suggestion.name, 30)
                            }
                          </p>
                        </td>
                        <td>
                          <Button
                            size="xs"
                            className="pull-right"
                            variant="success"
                            onClick={() => onEditSuggestionBeforeAdd(suggestion)}
                          >
                            <Fa name="plus" />
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          </Card.Body>
        )
      }
    </Card>
  );
};

export default SuggestionBlock;

SuggestionBlock.propTypes = {
  onEditSuggestionBeforeAdd: PropTypes.func.isRequired,
  suggestionData: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  suggestionType: PropTypes.string.isRequired,
  existingWorkInstructions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  existingProcessSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
