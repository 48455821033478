import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const remarkEmoji = require('remark-emoji');
const remarkBreaks = require('remark-breaks');

const Markdown = ({ source }) => (
  <ReactMarkdown
    className="markdown-styling"
    source={source}
    disallowedTypes={['image', 'html']}
    plugins={[
      [remarkEmoji, {}],
      [remarkBreaks, {}],
    ]}
  />
);
Markdown.propTypes = {
  source: PropTypes.string.isRequired,
};

export default Markdown;
