import React from 'react';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import {
  ButtonToolbar,
  FormLabel,
  Col,
  FormControl,
  FormGroup,
  Container,
  Dropdown,
  Row,
  SplitButton,
} from 'react-bootstrap';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import CostTooltip from 'rapidfab/components/CostTooltip';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import WorkChecklistContainer from 'rapidfab/containers/records/WorkChecklistContainer';
import { FormControlCost, FormControlSelect } from 'rapidfab/components/formTools';
import Loading from 'rapidfab/components/Loading';

import { Form, Field } from 'react-final-form';
import { finalFormInputTypes } from 'rapidfab/types';
import BatchType from 'rapidfab/components/records/BatchType';

const ShippingForm = ({ onDelete, defaultCurrency, initialFormValues, onFormSubmit, submitting }) => (
  <Form
    onSubmit={onFormSubmit}
    initialValues={initialFormValues}
    render={({ handleSubmit }) => (
      <form id="shipping" onSubmit={handleSubmit}>
        <Container fluid>
          <BreadcrumbNav
            breadcrumbs={['manage', 'shipping', initialFormValues && initialFormValues?.id ? `${initialFormValues.name} (${getShortUUID(initialFormValues.uuid)})` : 'New']}
          />
          <div className="clearfix">
            <ButtonToolbar className="pull-right">
              <SplitButton
                form="shipping"
                id="uxSaveDropdown"
                type="submit"
                variant="success"
                size="sm"
                title={submitting ? <Loading /> : <SaveButtonTitle />}
                pullRight
                disabled={submitting}
              >
                <Dropdown.Item
                  eventKey={1}
                  onClick={() => onDelete(initialFormValues.uuid)}
                  disabled={!initialFormValues.id}
                >
                  <Fa name="ban" />{' '}
                  <FormattedMessage id="button.delete" defaultMessage="Delete" />
                </Dropdown.Item>
              </SplitButton>
            </ButtonToolbar>
          </div>

          <hr />

          <Row>
            <Col xs={12} sm={6}>
              <FormGroup className="form-group" controlId="uxRegion">
                <FormGroup className="form-group" controlId="uxName">
                  <FormLabel>
                    <FormattedMessage id="field.name" defaultMessage="Name" />: *
                  </FormLabel>
                  <Field
                    name="name"
                    type="text"
                    render={props => (
                      <FormControl
                        {...props.input}
                        required
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxDescription">
                  <FormLabel>
                    <FormattedMessage
                      id="field.description"
                      defaultMessage="Description"
                    />
                    : *
                  </FormLabel>
                  <Field
                    name="description"
                    render={props => (
                      <FormControl
                        {...props.input}
                        as="textarea"
                        required
                      />
                    )}
                  />
                </FormGroup>
                <FormLabel>Region: *</FormLabel>
                <Field
                  name="region"
                  render={props => (
                    <FormControlSelect
                      {...props.input}
                      required
                    >
                      <option key="placeholder" value="" selected disabled>
                        Select a Region
                      </option>
                      <option value="africa">Africa</option>
                      <option value="antarctica">Antarctica</option>
                      <option value="asia">Asia</option>
                      <option value="australia">Australia</option>
                      <option value="europe">Europe</option>
                      <option value="north-america">North America</option>
                      <option value="south-america">South America</option>
                    </FormControlSelect>
                  )}
                />
              </FormGroup>
              <FormGroup className="form-group" controlId="uxCost">
                <FormLabel>
                  Cost: * <CostTooltip />
                </FormLabel>
                <FormControlCost
                  name="cost"
                  type="number"
                  required
                  initialValue={initialFormValues?.cost}
                  currency={defaultCurrency}
                />
              </FormGroup>
              <BatchType
                isBatchingStrategyDisabled
                fields={initialFormValues}
              />
            </Col>
            <Col sm={6}>
              {
                initialFormValues.uri && (
                  <WorkChecklistContainer
                    workstep={initialFormValues.uri}
                    wrapPanel
                  />
                )
              }
            </Col>
          </Row>
        </Container>
      </form>
    )}
  />
);

ShippingForm.propTypes = {
  onDelete: PropTypes.func.isRequired,
  defaultCurrency: PropTypes.string.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  input: finalFormInputTypes.isRequired,
  initialFormValues: PropTypes.shape({
    uri: PropTypes.string,
    cost: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    region: PropTypes.string,
    id: PropTypes.string,
    uuid: PropTypes.string,
    batching_strategy: PropTypes.string,
  }).isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default ShippingForm;
