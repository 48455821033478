import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import Loading from 'rapidfab/components/Loading';

const OwnerPanelHeader = ({
  isManager,
  isHawkingUser,
  isSaving,
  isEditingMode,
  setIsEditingMode,
  isStanleyXUser,
  isDigitalDesignWarehouseFeatureEnabled,
}) => {
  if (isManager && (isHawkingUser || isStanleyXUser || isDigitalDesignWarehouseFeatureEnabled)) {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <FormattedMessage
          id="field.owner"
          defaultMessage="Owner"
        />
        {isSaving && <Loading />}
        {isEditingMode && !isSaving && (
          <div>
            <Button size="xs" className="mr15 btn-default" onClick={() => setIsEditingMode(false)}>
              <Fa name="times" />
            </Button>
            <Button size="xs" className="btn-default" type="submit">
              <Fa name="save" />
            </Button>
          </div>
        )}
        {!isEditingMode && !isSaving && (
          <Button size="xs" className="btn-default" onClick={() => setIsEditingMode(true)}>
            <Fa name="edit" />
          </Button>
        )}
      </div>
    );
  }

  return <FormattedMessage id="field.owner" defaultMessage="Owner" />;
};
OwnerPanelHeader.propTypes = {
  setIsEditingMode: PropTypes.func.isRequired,
  isManager: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isEditingMode: PropTypes.bool.isRequired,
  isHawkingUser: PropTypes.bool.isRequired,
  isStanleyXUser: PropTypes.bool.isRequired,
  isDigitalDesignWarehouseFeatureEnabled: PropTypes.bool.isRequired,
};

export default OwnerPanelHeader;
