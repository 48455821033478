import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROUTER_PERMISSION } from '../constantsRouter';
import PrivateRoute from './PrivateRoute';
import { FEATURES } from '../constants';
import Orders from '../containers/plan/orders';
import Runs from '../containers/plan/runs';
import PiecesContainer from '../containers/plan/PiecesContainer';
import ServiceProviderJobsContainer from '../containers/plan/ServiceProviderJobsContainer';
import CreateRunsContainer from '../containers/plan/CreateRunsContainer';
import Prints from '../containers/plan/prints';
import NotFound from '../components/404';

const PlanRoutes = ({ session }) => (
  <Routes>
    <Route
      path="/orders"
      element={(
        <PrivateRoute
          session={session}
          component={Orders}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MANUFACTURING_EXECUTION_SYSTEM]}
        />
      )}
    />
    <Route
      path="/runs"
      element={(
        <PrivateRoute
          session={session}
          component={Runs}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          isRestrictedRoute
          features={{
            [FEATURES.HAWKING_DEPLOYMENT]: false,
          }}
        />
      )}
    />
    <Route
      path="/pieces"
      element={(
        <PrivateRoute
          session={session}
          component={PiecesContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={{
            [FEATURES.HAWKING_DEPLOYMENT]: false,
          }}
        />
      )}
    />
    <Route
      path="/service-provider-jobs"
      element={(
        <PrivateRoute
          session={session}
          component={ServiceProviderJobsContainer}
          permission={ROUTER_PERMISSION.LOGGED_IN_ONLY}
          features={[FEATURES.SERVICE_PROVIDERS]}
        />
      )}
    />
    <Route
      path="/create-runs"
      element={(
        <PrivateRoute
          session={session}
          component={CreateRunsContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={{
            [FEATURES.HAWKING_DEPLOYMENT]: false,
          }}
        />
      )}
    />
    <Route
      path="/prints"
      element={(
        <PrivateRoute
          session={session}
          component={Prints}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={{
            [FEATURES.HAWKING_DEPLOYMENT]: false,
          }}
        />
      )}
    />
    <Route
      path="*"
      element={<NotFound />}
    />
  </Routes>
);

PlanRoutes.propTypes = {
  session: PropTypes.shape({}).isRequired,
};

export default PlanRoutes;
