import { navbarDE } from './locales/navbar/index';
import { recordsDE } from './locales/records/index';
import { mappingsDE } from './locales/mappings/index';
import { componentsDE } from './locales/components/index';

import { planDE } from './locales/plan/index';
import adminDE from './locales/admin/de.json';
import workDE from './locales/work/de.json';
import inventoryDE from './locales/inventory/de.json';
import manageDE from './locales/manage/de.json';
import mfg from './locales/mfg.json';
import toasterDE from './locales/toaster/de.json';
import restDE from './locales/rest/de.json';

const translation = Object.assign(
  adminDE,
  navbarDE,
  planDE,
  workDE,
  inventoryDE,
  manageDE,
  recordsDE,
  mappingsDE,
  componentsDE,
  mfg,
  toasterDE,
  restDE,
);

export default translation;
