import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import Downtimes from 'rapidfab/components/downtime/Downtimes';
import { API_RESOURCES } from 'rapidfab/constants';
import PropTypes from 'prop-types';

const DowntimesContainer = props => {
  const downtimes = useSelector(state =>
    Selectors.getDowntimesForMachine(state, props.machineUri));
  const isFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.DOWNTIME].list.fetching);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.DOWNTIME].list());
  }, []);

  return (
    <Downtimes
      {...props}
      loading={isFetching}
      downtimes={downtimes}
    />
  );
};

DowntimesContainer.propTypes = {
  machineUri: PropTypes.string.isRequired,
};

export default DowntimesContainer;
