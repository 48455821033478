import React, { useEffect, useState } from 'react';
import {
  Col,
  FormLabel,
  FormControl,
  Row, Container, Card,
} from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import Fa from 'react-fontawesome';
import { connect } from 'react-redux';
import Actions from 'rapidfab/actions';
import {
  API_RESOURCES,
  CASTOR_COSTING_DEFAULT_YEARS_OF_DEMAND,
  CASTOR_COSTING_DEFAULT_QUANTITY,
} from 'rapidfab/constants';
import PropTypes from 'prop-types';
import * as Selectors from 'rapidfab/selectors';

const AnalysisSettings = ({ castorCostingConfigIntegration, castorGotoOncloseUrl, bureau }) => {
  const [analysisSettingsUri, setAnalysisSettingsUri] = useState('');

  useEffect(() => {
    if (bureau?.uri) {
      castorCostingConfigIntegration(bureau.uri).then(data => {
        const context = data?.json?.resources[0]?.context;
        if (context) {
          setAnalysisSettingsUri(context?.settings_url);
        }
      });
    }
  }, [bureau?.uri]);

  return (
    <Container fluid>
      <div className="page-header">
        <h1>
          <FormattedMessage
            id="hawking.analysisSettings"
            defaultMessage="Analysis Settings"
          />
        </h1>
      </div>

      <div className="jumbotron">
        <p>Control the settings used for the initial Part Scenario analysis which is generated
          when adding a Product to the library.
          These settings can be reconfigured in each Product by editing the individual Part Scenario.
        </p>
      </div>

      <Card className="analysisReportDefaults panel-light">
        <Card.Header className="pd-exp inverse"><FormattedMessage id="hawking.reportDefaults" defaultMessage="Report Defaults" /></Card.Header>
        <div className="card-body-wrapper">
          <Card.Body>
            <Row className="mb15">
              {/* While the endpoint is still in progress, all the Form Controls will be disabled (read-only) */}
              <Col xs={6}>
                <FormLabel>Base Material</FormLabel>{' '}
                <FormControl
                  disabled
                  as="select"
                  value=""
                >
                  <option value="">Use Product&apos;s Material</option>
                </FormControl>
              </Col>
              <Col xs={6}>
                <FormLabel>Quantity</FormLabel>{' '}
                <FormControl
                  disabled
                  name="field.quantity"
                  value={CASTOR_COSTING_DEFAULT_QUANTITY}
                  type="number"
                  min="0"
                />
              </Col>
            </Row>
            <Row className="mb15">
              <Col xs={6}>
                <FormLabel>Years of Demand</FormLabel>{' '}
                <FormControl
                  name="field.yearsOfDemand"
                  disabled
                  value={CASTOR_COSTING_DEFAULT_YEARS_OF_DEMAND}
                  type="number"
                  min="0"
                />
              </Col>
            </Row>
            <Row className="mb15 mt30">
              <Col xs={6}>
                <a
                  className="btn btn-default w-md"
                  href={`${analysisSettingsUri}}&back=${castorGotoOncloseUrl}`}
                >
                  <Fa name="cube" /> Advanced
                </a>
              </Col>
            </Row>
          </Card.Body>
        </div>
      </Card>
    </Container>
  );
};

AnalysisSettings.propTypes = {
  castorCostingConfigIntegration: PropTypes.func.isRequired,
  castorGotoOncloseUrl: PropTypes.string.isRequired,
  bureau: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => {
  const bureau = Selectors.getBureau(state);
  const plainCastorGotoOncloseUrl = `${window.location.origin}/${window.location.hash}`;
  const castorGotoOncloseUrl = encodeURIComponent(plainCastorGotoOncloseUrl);

  return {
    castorGotoOncloseUrl,
    bureau,
  };
};

const mapDispatchToProps = dispatch => ({
  castorCostingConfigIntegration: bureauURI => dispatch(Actions.Api.nautilus[API_RESOURCES.CASTOR_INTEGRATION]
    .list({ bureau: bureauURI })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisSettings);
