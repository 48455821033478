import React, { useEffect, useState } from 'react';
import { Col, Row, FormControl, Button, Card, Container, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _capitalize from 'lodash/capitalize';
import { Link } from 'react-router-dom';
import {
  ROUTES,
  COLORS,
  API_RESOURCES,
  PRIORITIES,
  WIP_MATRIX_TIMING_STATUSES,
  WIP_MATRIX_SORT, WIP_MATRIX_FILTERS,
} from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import Loading from 'rapidfab/components/Loading';
import OrdersGanttChartContainer from 'rapidfab/containers/records/order/OrdersGanttChartContainer';
import { useSelector } from 'react-redux';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Locations from 'rapidfab/components/locations';
import useElementScroll, { isScrolledToBottom } from 'rapidfab/hooks/useElementScroll';
import truncateText from 'rapidfab/utils/truncateText';
import { PRIORITIES_MAP } from 'rapidfab/mappings';
import { Picky } from 'react-picky';
import Search from 'rapidfab/components/RecordList/Search';
import { getIsDebugModeEnabled } from 'rapidfab/selectors';

const INDICATOR_STYLE_CLASS = 'wip-matrix-indicator';

const TimingIndicatorCircle = ({ timingKey }) => {
  switch (timingKey) {
    case WIP_MATRIX_TIMING_STATUSES.ON_TIME:
      return (
        <div
          className={INDICATOR_STYLE_CLASS}
          style={{ backgroundColor: COLORS.GREEN }}
        >
          <Fa name="check" />
        </div>
      );
    case WIP_MATRIX_TIMING_STATUSES.BEHIND:
      return (
        <div
          className={INDICATOR_STYLE_CLASS}
          style={{ backgroundColor: COLORS.ORANGE }}
        >
          <Fa name="clock-o" />
        </div>
      );
    case WIP_MATRIX_TIMING_STATUSES.LATE:
      return (
        <div
          className={INDICATOR_STYLE_CLASS}
          style={{ backgroundColor: COLORS.RED }}
        >
          <Fa name="exclamation" />
        </div>
      );
    default:
      break;
  }

  return (
    <div
      className={INDICATOR_STYLE_CLASS}
      style={{
        backgroundColor: COLORS.GRAY,
      }}
    >
      <span>
        ?
      </span>
    </div>
  );
};

TimingIndicatorCircle.propTypes = {
  timingKey: PropTypes.string.isRequired,
};

const PriorityIndicatorCircle = ({ priority }) => {
  if (priority < PRIORITIES.NORMAL) {
    return (
      <div
        className={INDICATOR_STYLE_CLASS}
        style={{ backgroundColor: COLORS.BLUE }}
      >
        <span>L</span>
      </div>
    );
  }
  if (priority < PRIORITIES.HIGH && priority >= PRIORITIES.NORMAL) {
    return (
      <div
        className={INDICATOR_STYLE_CLASS}
        style={{ backgroundColor: COLORS.PURPLE }}
      >
        <span>N</span>
      </div>
    );
  }
  if (priority < PRIORITIES.CRITICAL && priority >= PRIORITIES.HIGH) {
    return (
      <div
        className={INDICATOR_STYLE_CLASS}
        style={{ backgroundColor: COLORS.ORANGE }}
      >
        <span>H</span>
      </div>
    );
  }
  if (priority >= PRIORITIES.CRITICAL) {
    return (
      <div
        className={INDICATOR_STYLE_CLASS}
        style={{ backgroundColor: COLORS.RED }}
      >
        <span>C</span>
      </div>
    );
  }

  return (
    <div
      className={INDICATOR_STYLE_CLASS}
      style={{
        backgroundColor: COLORS.GRAY,
      }}
    >
      <span>
        ?
      </span>
    </div>
  );
};

const WIPMatrixTooltip = ({ children, body }) => (
  <OverlayTrigger
    overlay={(
      <Tooltip>
        {body}
      </Tooltip>
    )}
  >
    {children}
  </OverlayTrigger>
);

WIPMatrixTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
};

PriorityIndicatorCircle.propTypes = {
  priority: PropTypes.string.isRequired,
};

const Cell = ({ type, style, onClick, tooltip }) => {
  const WHITE_BORDER = `1px solid ${COLORS.WHITE}`;

  if (type === 'done' || type === 'complete') {
    return (
      <WIPMatrixTooltip
        body={tooltip}
      >
        <td
          {...(onClick ? { onClick } : {})}
          style={{
            ...style,
            border: WHITE_BORDER,
            backgroundColor: COLORS.GREEN,
            color: COLORS.WHITE,
            cursor: onClick ? 'pointer' : 'initial',
          }}
        >
          Done
        </td>
      </WIPMatrixTooltip>
    );
  }

  switch (type) {
    case 'in-progress':
      return (
        <WIPMatrixTooltip
          body={tooltip}
        >
          <td
            {...(onClick ? { onClick } : {})}
            style={{
              ...style,
              border: WHITE_BORDER,
              backgroundColor: COLORS.ORANGE,
              color: COLORS.WHITE,
              cursor: onClick ? 'pointer' : 'initial',
            }}
          >
            In Progress
          </td>
        </WIPMatrixTooltip>
      );
    case 'queued':
      return (
        <WIPMatrixTooltip
          body={tooltip}
        >
          <td
            {...(onClick ? { onClick } : {})}
            style={{
              ...style,
              border: WHITE_BORDER,
              backgroundColor: COLORS.BLUE,
              color: COLORS.WHITE,
              cursor: onClick ? 'pointer' : 'initial',
            }}
          >
            To do
          </td>
        </WIPMatrixTooltip>
      );
    case 'error':
      return (
        <WIPMatrixTooltip
          body={tooltip}
        >
          <td
            {...(onClick ? { onClick } : {})}
            style={{
              ...style,
              border: WHITE_BORDER,
              backgroundColor: COLORS.RED,
              color: COLORS.WHITE,
              cursor: onClick ? 'pointer' : 'initial',
            }}
          >
            Error
          </td>
        </WIPMatrixTooltip>
      );
    default:
      break;
  }

  return (
    <td
      style={{
        ...style,
        border: WHITE_BORDER,
        backgroundColor: COLORS.GRAY,
        color: COLORS.WHITE,
      }}
    >
      N/A
    </td>
  );
};

Cell.defaultProps = {
  onClick: null,
};

Cell.propTypes = {
  type: PropTypes.string.isRequired,
  style: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func,
  tooltip: PropTypes.node.isRequired,
};

const WIPMatrix = ({
  postProcessorTypes,
  orders,
  runsForOrders,
  fetchMoreData,
  handleSearchOrders,
  onSortByChange,
  handleFilterOrders,
  orderSortKey,
  openCurrentRunsForOrder,
  getTimingKey,
  ascendingState,
  orderSearch,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const selectedOrderExists = selectedOrder && orders.find(({ uuid }) => uuid === selectedOrder.uuid);
  const fetchingGantt =
    useSelector(state => state.ui.nautilus[API_RESOURCES.PIECE].list.fetching
      || state.ui.nautilus[API_RESOURCES.PRINT].list.fetching
      || state.ui.nautilus[API_RESOURCES.RUN_ACTUALS].list.fetching
      || state.ui.nautilus[API_RESOURCES.SCHEDULE_RUNS].list.fetching);

  const isDebugModeEnabled = useSelector(getIsDebugModeEnabled);

  const handleSetOrder = order => {
    if ((selectedOrder && order) && (selectedOrder.uuid === order.uuid)) {
      return window.scrollTo(0, document.body.scrollHeight);
    }
    return setSelectedOrder(order);
  };

  useEffect(() => {
    if (selectedOrder && !fetchingGantt) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [selectedOrder, fetchingGantt]);

  const [loading, setLoading] = useState(false);
  const { isAscending, setIsAscending } = ascendingState;

  const [calledForMore, setCalledForMore] = useState(false);
  const [lastScrollLeftOffset, setLastScrollLeftOffset] = useState(0);
  const [isOrderColumnSticky, setIsOrderColumnSticky] = useState(false);

  const anchor = React.useRef();
  const scrollPosition = useElementScroll(anchor);
  const scrolledToBottom = isScrolledToBottom(anchor, scrollPosition, 20);

  useEffect(() => {
    if (!calledForMore && fetchMoreData && scrolledToBottom && !loading) {
      setLoading(true);
      setCalledForMore(true);
      fetchMoreData()
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        });
    }
  }, [scrolledToBottom, calledForMore, loading]);

  useEffect(() => {
    if (calledForMore && !loading) {
      setCalledForMore(false);
    }
  }, [loading]);

  const handleScrollSticky = () => {
    const wipMatrixContainerElement = document.querySelector('#wipMatrixContainer');
    const orderColumnElements = document.querySelectorAll('#wipMatrixOrderColumn');
    const currentScrollLeftOffset = wipMatrixContainerElement.scrollLeft;
    const isScrollingHorizontally = lastScrollLeftOffset !== currentScrollLeftOffset;
    if (isScrollingHorizontally &&
      currentScrollLeftOffset > 10) {
      setLastScrollLeftOffset(currentScrollLeftOffset - 10);
      setIsOrderColumnSticky(true);
      orderColumnElements.forEach(element => {
        element.classList.add('wip-matrix-order-col-fixed');
      });
    } else if (isScrollingHorizontally &&
      currentScrollLeftOffset < 10) {
      setIsOrderColumnSticky(false);
      orderColumnElements.forEach(element => {
        element.classList.remove('wip-matrix-order-col-fixed');
      });
    }
  };

  const sortValue = orderSortKey.replace('-', '');

  const renderFilterValues = () => WIP_MATRIX_FILTERS.map(({ label, value }) => (
    <option key={label} value={value}>
      {label}
    </option>
  ));

  return (
    <Container fluid>
      <BreadcrumbNav breadcrumbs={['work', 'WIP Matrix']} />
      <Locations />
      <hr />
      <Container onScroll={handleScrollSticky} id="wipMatrixContainer" className="wip_matrix_container">
        <div className="wip-matrix-sticky-header">
          <h1>
            <FormattedMessage
              id="wip.matrix"
              defaultMessage="Work in Progress Matrix"
            />
          </h1>
          <Row className="my-2">
            <Col xs={3}>
              <Search
                initialSearch={orderSearch}
                onSearch={handleSearchOrders}
              />
            </Col>
            <Col xs={isDebugModeEnabled ? 5 : 7} />
            {
              isDebugModeEnabled && (
                <Col xs={2}>
                  <FormControl
                    as="select"
                    type="select"
                    className="pull-right"
                    onChange={handleFilterOrders}
                  >
                    {renderFilterValues()}
                  </FormControl>
                </Col>
              )
            }
            <Col xs={2}>
              <Picky
                options={WIP_MATRIX_SORT}
                labelKey="label"
                valueKey="value"
                keepOpen={false}
                className="wip_matrix_sort"
                value={WIP_MATRIX_SORT[WIP_MATRIX_SORT.findIndex(item => item.value === sortValue)]}
                onChange={onSortByChange}
                buttonProps={{
                  'data-testid': 'picky-input-wip-matrix-sort',
                }}
                renderList={({
                  items,
                  selectValue,
                }) => items.map(item => {
                  let chevron = null;
                  if (item.value && item.value === sortValue) {
                    if (isAscending) {
                      chevron = <Fa name="chevron-up" />;
                    } else {
                      chevron = <Fa name="chevron-down" />;
                    }
                  }
                  return (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                    <li
                      key={item.value}
                      onClick={() => {
                        setIsAscending(previous => !previous);
                        selectValue(item.value);
                      }}
                    >
                      {chevron} {item.label}
                    </li>
                  );
                })}
              />
            </Col>
          </Row>
        </div>
        <Table
          bordered
          size="sm"
          className={isCollapsed ? 'wip_matrix_table' : 'wip_matrix_table_expanded wip_matrix_table'}
        >
          <thead>
            <th />
            <th />
            {
              isCollapsed ? (
                <th
                  role="button"
                  onClick={() => setIsCollapsed(previous => !previous)}
                  style={{
                    backgroundColor: COLORS.PURPLE,
                    border: `2px solid ${COLORS.PURPLE}`,
                  }}
                >
                  {
                    isCollapsed ? (
                      <p className="d-flex align-items-center">
                        Expand
                        <Fa className="spacer-left" name="plus-circle" />
                      </p>
                    )
                      :
                      (
                        <p className="d-flex align-items-center">
                          Collapse
                          <Fa className="spacer-left" name="minus-circle" />
                        </p>
                      )
                  }
                </th>
              )
                :
                _map(postProcessorTypes, (_, index) => (
                  <th
                    role="button"
                    onClick={() => setIsCollapsed(previous => !previous)}
                    style={{
                      backgroundColor: COLORS.PURPLE,
                      border: `2px solid ${COLORS.PURPLE}`,
                    }}
                  >
                    {
                      index === 0 && (
                        isCollapsed ? (
                          <p className="d-flex align-items-center">
                            Expand
                            <Fa className="spacer-left" name="plus-circle" />
                          </p>
                        )
                          :
                          (
                            <p className="d-flex align-items-center">
                              Collapse
                              <Fa className="spacer-left" name="minus-circle" />
                            </p>
                          )
                      )
                    }
                  </th>
                ))
            }
            <th />
            <th />
          </thead>
          <thead>
            <tr>
              <th
                id="wipMatrixOrderColumn"
                style={isOrderColumnSticky ? {
                  position: 'relative',
                  left: lastScrollLeftOffset,
                } : {}}
              >
                Order
              </th>
              <th>Printing</th>
              {
                isCollapsed ? (
                  <th
                    style={{
                      borderLeft: `2px solid ${COLORS.PURPLE}`,
                      borderRight: `2px solid ${COLORS.PURPLE}`,
                    }}
                  >Post Processing ({postProcessorTypes.length})
                  </th>
                )
                  :
                  _map(postProcessorTypes, (postProcessorType, index) => (
                    <th
                      style={{
                        borderLeft: index === 0 && `2px solid ${COLORS.PURPLE}`,
                        borderRight: index === (postProcessorTypes.length - 1) && `2px solid ${COLORS.PURPLE}`,
                        borderTop: `2px solid ${COLORS.PURPLE}`,
                      }}
                    >
                      {postProcessorType.name}
                    </th>
                  ))
              }
              <th>NC Review</th>
              <th>Shipping</th>
            </tr>
          </thead>
          <tbody
            id="matrixScrollableArea"
            ref={anchor}
            style={{ height: orders.length < 20 ? orders.length * 50 - 10 : '100%' }}
          >
            {
              (!orders.length && !loading) ? (
                <h3
                  className="d-flex align-items-center justify-content-center mb30 mt30"
                >
                  Orders not found
                </h3>
              ) : _map(orders, order => {
                const runsForOrderRow = _filter(runsForOrders,
                  run => run.orders.includes(order.uri));
                const printerForOrderRow = _find(runsForOrderRow, run => !_isEmpty(run.printer));

                const ncReviewPostProcessorTypes = _filter(postProcessorTypes, { special_type: 'non_conformance_review' });
                const ncReviewForOrderRow = _find(runsForOrderRow, run => (
                  _map(ncReviewPostProcessorTypes, 'uri').includes(run.post_processor_type)
                ));

                const shippingForOrderRow = _find(runsForOrderRow, run => !_isEmpty(run.shipping));
                const runsForOrderRowStatuses = _map(
                  _filter(runsForOrderRow, run => _isEmpty(run.shipping) && _isEmpty(run.printer)),
                  'status');

                const getAggregatedValue = statuses => {
                  if (_isEmpty(statuses)) {
                    return null;
                  } if (statuses.includes('error')) {
                    return 'error';
                  } if (statuses?.some(status => _isEmpty(status))) {
                    return 'error';
                  } if (statuses.includes('in-progress')) {
                    return 'in-progress';
                  } if (statuses.includes('queued')) {
                    return 'queued';
                  } if (
                    statuses.every(status => status === 'done') ||
                statuses.every(status => status === 'complete')) {
                    return 'done';
                  }

                  return null;
                };

                return (
                  <tr>
                    {order && (
                      <td
                        id="wipMatrixOrderColumn"
                        className={!isCollapsed && 'w-100'}
                        style={isOrderColumnSticky ? {
                          position: 'relative',
                          left: lastScrollLeftOffset,
                        } : {}}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <Link
                            className={!isCollapsed ? 'wip_matrix_table_link' : ''}
                            to={getRouteURI(ROUTES.ORDER_EDIT, { uuid: order.uuid }, {}, true)}
                          >
                            {
                              order && order.name.length > 20 ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={(
                                    <Tooltip id="tableOrderName">
                                      <span>{order.name}</span>
                                    </Tooltip>
                                  )}
                                >
                                  <span>{truncateText(order.name, 20)}</span>
                                </OverlayTrigger>
                              ) : <span>{order.name}</span>
                            }
                          </Link>
                          <div className="d-flex align-items-center">
                            <Fa
                              className="spacer-right"
                              role="button"
                              name="external-link"
                              onClick={() => openCurrentRunsForOrder(order)}
                            />
                            <OverlayTrigger
                              placement="top"
                              overlay={(
                                <Tooltip id="priorityTooltip">
                                  <span>This priority is:
                                    {' '}
                                    <b>{PRIORITIES_MAP[order.priority].defaultMessage}</b>
                                  </span>
                                </Tooltip>
                              )}
                            >
                              <div className="spacer-right">
                                <PriorityIndicatorCircle priority={order.priority} />
                              </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={(
                                <Tooltip id="timingTooltip">
                                  <span>
                                    This is
                                    {' '}
                                    <b>{_capitalize(getTimingKey(order.due_date))}</b>
                                    {' '}
                                    for the scheduled delivery for the due date.
                                  </span>
                                </Tooltip>
                              )}
                            >
                              <div>
                                <TimingIndicatorCircle timingKey={getTimingKey(order.due_date)} />
                              </div>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </td>
                    )}
                    {/* Printer Column */}
                    {
                      !_isEmpty(printerForOrderRow) ? (
                        <Cell
                          type={printerForOrderRow.status}
                          onClick={() => handleSetOrder(order)}
                          tooltip={
                            <p>{printerForOrderRow.name}</p>
                          }
                        />
                      )
                        :
                        <Cell />
                    }
                    {/* Post Processors Column */}
                    {
                      isCollapsed ?
                        (runsForOrderRow ? (
                          <Cell
                            type={getAggregatedValue(runsForOrderRowStatuses)}
                            onClick={() => handleSetOrder(order)}
                            tooltip={
                              <p>{`There are ${runsForOrderRow.length} runs contained in this aggregated cell.`}</p>
                            }
                          />
                        )
                          :
                          <Cell />)
                        :
                        _map(postProcessorTypes, postProcessorType => {
                          const currentRunsAtPostProcessorType = runsForOrderRow
                            .filter(run => run.post_processor_type === postProcessorType.uri);
                          return (
                            !_isEmpty(currentRunsAtPostProcessorType) &&
                        currentRunsAtPostProcessorType.every(({ status }) =>
                          status === currentRunsAtPostProcessorType[0].status) ? (
                              // eslint-disable-next-line react/jsx-indent
                                <Cell
                                  type={
                                    currentRunsAtPostProcessorType[currentRunsAtPostProcessorType.length - 1].status
                                  }
                                  onClick={() => handleSetOrder(order)}
                                  tooltip={(
                                    <p>{
                                      currentRunsAtPostProcessorType[currentRunsAtPostProcessorType.length - 1].name
                                    }
                                    </p>
                                  )}
                                />
                              )
                              :
                            // eslint-disable-next-line react/jsx-indent
                              <Cell />
                          );
                        })
                    }
                    {/* NC Review Column */}
                    {
                      !_isEmpty(ncReviewForOrderRow) ? (
                        <Cell
                          type={ncReviewForOrderRow.status}
                          onClick={() => handleSetOrder(order)}
                          tooltip={
                            <p>{ncReviewForOrderRow.name}</p>
                          }
                        />
                      )
                        :
                        <Cell />
                    }
                    {/* Shipping Column */}
                    {
                      shippingForOrderRow ? (
                        <Cell
                          type={shippingForOrderRow.status}
                          onClick={() => handleSetOrder(order)}
                          tooltip={
                            <p>{shippingForOrderRow.name}</p>
                          }
                        />
                      )
                        :
                        <Cell />
                    }
                  </tr>
                );
              })
            }
            {loading && (
              <div style={{ padding: '20px' }}>
                <Loading />
              </div>
            )}
          </tbody>
        </Table>
        {
          selectedOrder && selectedOrderExists ? (
            <Card bg="dark">
              <Card.Header className="d-flex justify-content-between align-items-center pd-exp inverse wip_matrix_card">
                {
                  selectedOrder && selectedOrder.name.length > 150 ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={(
                        <Tooltip id="selectedOrderName">
                          <span>{selectedOrder.name}</span>
                        </Tooltip>
                      )}
                    >
                      <span>Production Gantt Peak View for &quot;{selectedOrder.name}&quot;</span>
                    </OverlayTrigger>
                  ) : (
                    <span>Production Gantt Peak View for &quot;{selectedOrder.name}&quot;</span>
                  )
                }
                <Button
                  className="me-1"
                  variant="primary"
                  size="xs"
                  title="Hide"
                  onClick={() => setSelectedOrder(null)}
                >
                  <span className="spacer-right">Hide</span>
                  <Fa name="eye-slash" />
                </Button>
              </Card.Header>
              <div className="card-body-wrapper">
                <Card.Body>
                  <OrdersGanttChartContainer
                    orders={[selectedOrder]}
                    customFetching={selectedOrder && !fetchingGantt ? false : fetchingGantt}
                    lineHeight={160}
                    lineHeightRatio={0.3}
                  />
                </Card.Body>
              </div>
            </Card>
          ) : null
        }
      </Container>
    </Container>

  );
};

export default WIPMatrix;

WIPMatrix.propTypes = {
  postProcessorTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape).isRequired,
  runsForOrders: PropTypes.arrayOf(PropTypes.shape).isRequired,
  handleSearchOrders: PropTypes.func.isRequired,
  onSortByChange: PropTypes.func.isRequired,
  handleFilterOrders: PropTypes.func.isRequired,
  fetchMoreData: PropTypes.func.isRequired,
  orderSortKey: PropTypes.string.isRequired,
  openCurrentRunsForOrder: PropTypes.func.isRequired,
  getTimingKey: PropTypes.func.isRequired,
  ascendingState: PropTypes.shape({
    isAscending: PropTypes.bool.isRequired,
    setIsAscending: PropTypes.func.isRequired,
  }).isRequired,
  orderSearch: PropTypes.string.isRequired,
};
