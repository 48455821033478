import React from 'react';
import PropTypes from 'prop-types';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import extractUuid from 'rapidfab/utils/extractUuid';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import ResourceReadOnlyViewRow, { RESOURCE_READ_ONLY_VIEW_FIELD_TYPES } from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import { ROUTES } from 'rapidfab/constants';

const MaterialInfo = ({ material }) => {
  if (!material) {
    return null;
  }
  return (
    <ResourceReadOnlyView
      withPanelWrapper
      title="Material Type"
      entity={material}
    >
      <ResourceReadOnlyViewRow
        name="name"
        className="wrap-text"
        type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
        customValue={(
          <a href={getRouteURI(ROUTES.MATERIAL_EDIT, { uuid: extractUuid(material.uri) })}>
            {material.name}
          </a>
        )}
      />
      <ResourceReadOnlyViewRow name="type" />
      <ResourceReadOnlyViewRow name="units" />
      <ResourceReadOnlyViewRow
        name="cost"
        type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.COST}
      />
    </ResourceReadOnlyView>
  );
};

MaterialInfo.defaultProps = {
  material: null,
};

MaterialInfo.propTypes = {
  material: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    units: PropTypes.string.isRequired,
    cost: PropTypes.number.isRequired,
  }),
};

export default MaterialInfo;
