import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';

const CostTooltip = () => (
  <OverlayTrigger
    placement="top"
    overlay={(
      <Tooltip>
        <FormattedMessage
          id="costTooltip"
          defaultMessage="This currency is based on your Bureau Default Currency. Please contact Authentise Support to change this default."
        />
      </Tooltip>
    )}
  >
    <Fa className="spacer-left spacer-right" name="question-circle" />
  </OverlayTrigger>
);

export default CostTooltip;
