import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { FormattedCostRange, FormattedMessage } from 'rapidfab/i18n';
import Tooltip from 'rapidfab/components/Tooltip';
import { CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS } from 'rapidfab/constants';
import { castorCostingConfigResourceType } from 'rapidfab/types';
import { CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS_MAP } from 'rapidfab/mappings';
import Icon, { ICON_NAMES } from 'rapidfab/icons';

const ManufacturabilityIcon = ({ manufacturable }) => {
  if (!manufacturable) {
    return null;
  }

  let triggerIcon;

  switch (manufacturable) {
    case CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS.PRINTABLE:
      triggerIcon = <Icon name={ICON_NAMES.CHECK} size="large" />;
      break;
    case CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS.NOT_PRINTABLE:
      triggerIcon = <Icon name={ICON_NAMES.WARNING} size="large" />;
      break;
    case CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS.PRINTABLE_WITH_SUPPORT:
      triggerIcon = <Icon name={ICON_NAMES.CHECK_WITH_WARNING} size="large" />;
      break;
    default:
      break;
  }

  if (!CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS_MAP[manufacturable]) {
    return null;
  }

  return (
    <FormattedMessage {...CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS_MAP[manufacturable]}>
      {text => (
        <Tooltip
          id="manufacturability"
          trigger={triggerIcon}
        >
          {text}
        </Tooltip>
      )}
    </FormattedMessage>
  );
};

ManufacturabilityIcon.propTypes = {
  manufacturable: PropTypes.oneOf(Object.values(CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS)).isRequired,
};

const PartAnalysisMethodsPanel = ({ castorCostingConfig, currency, castorGotoOncloseUrl }) => {
  const { additive_manufacturable: additiveManufacturable, configuration_ui_url } = castorCostingConfig;

  const rangeCastorTotalValue = value => {
    if (!value) return null;

    return {
      start: Math.floor(0.9 * value),
      end: Math.floor(1.1 * value),
    };
  };

  const printTimeRange = rangeCastorTotalValue(castorCostingConfig.additive_lead_time_days);
  const additivePrintTimeRange = printTimeRange ? `${printTimeRange.start}-${printTimeRange.end} days` : 'N/A';
  const additiveRangeCost = rangeCastorTotalValue(castorCostingConfig.additive_total_cost);
  const traditionalRangeCost = rangeCastorTotalValue(castorCostingConfig.traditional_total_cost);
  const currentStatus = additiveManufacturable || 'not-printable';
  const printableStatus = CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS_MAP[currentStatus]?.defaultMessage;
  const alternativeStyle = !traditionalRangeCost ? { flexBasis: '70%' } : null;

  const renderAlternativeText = () => {
    if (!traditionalRangeCost) return <p>N/A</p>;

    return (
      <div>Part could be manufactured by <strong>{castorCostingConfig.traditional_method_name}</strong>
        &nbsp;for approximately&nbsp;
        <p style={{ display: 'inline-block' }}>
          <FormattedCostRange
            values={traditionalRangeCost}
            currency={currency}
          />
        </p>
        {' '}per part
      </div>
    );
  };

  return (
    <a
      href={`${configuration_ui_url}&back=${castorGotoOncloseUrl}`}
      rel="noopener noreferrer"
      className="analysisMethodPanelLink"
    >
      <Card style={{ margin: '10px 0 0 10px' }}>
        <div className="analysisMethodPanel">
          <div className="analysisMethodPanelCol" style={alternativeStyle}>
            <div className="analysisMethodPanelStatus">
              <ManufacturabilityIcon manufacturable={currentStatus} />
              <p className="manufacturabilityIconText">{printableStatus}</p>
            </div>
            <div className="analysisMethodPanelRecommended">
              <b>
                <FormattedMessage
                  id="workflowType.additive_manufacturing"
                  defaultMessage="Additive Manufacturing"
                />
              </b>
              <p>
                {currentStatus === CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS.NOT_PRINTABLE ?
                  'Click for more details' :
                  `${castorCostingConfig.additive_target_printer_company}
                ${castorCostingConfig.additive_target_printer},
                ${castorCostingConfig.additive_material_name}`}
              </p>
            </div>
          </div>
          <div className="analysisMethodPanelCol" style={alternativeStyle}>
            <div className="analysisMethodPanelCostRangeRow">
              <div className="analysisMethodPanelCostRange">
                <p>Cost range:</p>
                <p style={{ display: 'inline-block' }}>
                  <FormattedCostRange
                    values={additiveRangeCost}
                    currency={currency}
                  />
                </p>
                <span> per part</span>
              </div>
              <div className="analysisMethodPanelPrintTime">
                <p>Total print time:</p>
                {additivePrintTimeRange}
              </div>
            </div>
            <div className="analysisMethodPanelAlternative">
              <b>
                <FormattedMessage
                  id="traditionalManufacturing"
                  defaultMessage="Alternative"
                />
              </b>
              {renderAlternativeText()}
            </div>
          </div>
        </div>
      </Card>
    </a>
  );
};
PartAnalysisMethodsPanel.propTypes = {
  castorCostingConfig: castorCostingConfigResourceType.isRequired,
  currency: PropTypes.string.isRequired,
  castorGotoOncloseUrl: PropTypes.string.isRequired,
};

export default PartAnalysisMethodsPanel;
