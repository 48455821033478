import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, ListGroup, ListGroupItem, Card, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import {
  FORMATTED_DURATION_TYPES,
  FormattedMessage, FormattedOptionalDuration,
} from 'rapidfab/i18n';
import {
  QUOTE_PREVIEW_MODAL_FIELDS,
  API_RESOURCES,
} from 'rapidfab/constants';
import PropTypes from 'prop-types';
import nl2br from 'rapidfab/utils/nl2br';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import Fa from 'react-fontawesome';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _compact from 'lodash/compact';
import _sum from 'lodash/sum';
import _map from 'lodash/map';
import Loading from 'rapidfab/components/Loading';
import * as Selectors from 'rapidfab/selectors';
import { useSelector } from 'react-redux';
import QuoteProcessStepModalContainer from 'rapidfab/containers/records/order/QuoteProcessStepModalContainer';
import { convertSecondsToHours, convertHoursToSeconds } from 'rapidfab/utils/currentTime';
import LineItemQuoteEditModal from './LineItemQuoteEditModal';

// Not sure if this code is relevant as we do not use these values below
function calculateProcessStepTotal(processStep, lineItemQuantity) {
  const { work_steps_quote_details: { estimated_cost_per_instance: costPerInstance } } =
    processStep;
  return costPerInstance ? costPerInstance * lineItemQuantity : 0;
}

const convertFromPriceToCount = (price, pricePerCount) => Number((price / pricePerCount).toFixed(2));

const LineItemQuotePreview = ({
  processSteps,
  processStepTypesByUri,
  lineItemQuantity,
  saveQuoteDetails,
  savingLineItemQuote,
  publicNotes,
  lineItemQuote,
  lineItemUri,
  workstepCostEstimates,
}) => {
  const bureauIntakeSettings = useSelector(Selectors.getBureauIntakeSettings);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showQuoteProcessModal, setShowQuoteProcessModal] = useState(false);
  const [totalsByProcessStep, setTotalsByProcessStep] = useState({});
  const [currentProcessStep, setCurrentProcessStep] = useState({});
  const [transformedSteps, setTransformedSteps] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [fetchedScheduling, setFetchedScheduling] = useState(false);

  const transformWorkstepQuoteDetailsForProcessStep = processStep => {
    /* This function merges the cost-estimate data into the line-item-quote data
    at keys `overhead_cost_per_piece_in_run` and `overhead_cost_per_run` */
    const workstepQuoteDetailsForProcessStep = _find(
      lineItemQuote.work_steps_quote_details,
      { process_step: processStep.uri });
    const workstepCostEstimateForProcessStep = _find(
      workstepCostEstimates,
      { process_step: processStep.uri, line_item: lineItemUri },
    );

    if (workstepQuoteDetailsForProcessStep && workstepCostEstimateForProcessStep) {
      return {
        ...workstepQuoteDetailsForProcessStep,
        overhead_cost_per_piece_in_run:
            workstepCostEstimateForProcessStep.overhead_cost_per_piece_in_run,
        overhead_cost_per_run:
            workstepCostEstimateForProcessStep.overhead_cost_per_run,
      };
    }

    return { ...workstepQuoteDetailsForProcessStep,
      overhead_cost_per_piece_in_run:
        workstepQuoteDetailsForProcessStep.overhead_cost_per_piece_in_run,
      overhead_cost_per_run:
        workstepQuoteDetailsForProcessStep.overhead_cost_per_run };
  };

  const isProcessStepTypeEqualTo = (currentProcessType, type) => currentProcessType.uri.includes(type);

  const getWorkstationTimePricePerUnit = currentProcessType => {
    if (isProcessStepTypeEqualTo(currentProcessType, API_RESOURCES.POST_PROCESSOR_TYPE)) {
      return currentProcessType?.cost * 60;
    } if (isProcessStepTypeEqualTo(currentProcessType, API_RESOURCES.PRINTER_TYPE)) {
      return currentProcessType?.running_cost_per_hour;
    } if (isProcessStepTypeEqualTo(currentProcessType, API_RESOURCES.SHIPPING)) {
      return currentProcessType?.cost * 60;
    }
    return null;
  };

  const renderInitialData = settings => {
    const allSteps = processSteps.map(processStep => {
      const quoteDetail = processStep.work_steps_quote_details;
      const laborSetPrice = quoteDetail.labor_price_per;
      const notCounted = !quoteDetail.in_quote || !quoteDetail.in_price;
      const transformedWorkstepQuoteDetailsForProcessStep =
        transformWorkstepQuoteDetailsForProcessStep(processStep);
      /* Process-step types are a combination of `post-processor`, `printer`, `shipping` types */
      const currentProcessStepType = processStepTypesByUri[processStep.workstation_type_uri];
      const isCurrentProcessStepPostProcessorType = isProcessStepTypeEqualTo(currentProcessStepType,
        API_RESOURCES.POST_PROCESSOR_TYPE);
      const workstationTimePricePerUnit = getWorkstationTimePricePerUnit(currentProcessStepType);
      const dataToRender = {
        data: _compact([
          {
            chargeName: 'Labor',
            unit: 'Hour',
            count: laborSetPrice ?
              convertFromPriceToCount(laborSetPrice, settings?.default_labor_charge_per) :
              convertSecondsToHours(settings?.default_labor_time),
            pricePerUnit: settings?.default_labor_charge_per,
            price: laborSetPrice ||
              convertSecondsToHours(settings?.default_labor_time) *
            (settings?.default_labor_charge_per),
            id: Math.random() * 2,
          },
          {
            chargeName: 'Workstation Time',
            unit: 'Hour',
            count: isCurrentProcessStepPostProcessorType ?
              (currentProcessStepType?.duration / 3600).toFixed(2) :
              (settings?.default_workstation_time / 3600),
            pricePerUnit: workstationTimePricePerUnit,
            price: isCurrentProcessStepPostProcessorType ?
              Math.ceil((currentProcessStepType?.duration / 3600).toFixed(2) *
              workstationTimePricePerUnit) :
              (settings?.default_workstation_time / 3600).toFixed(2) *
              workstationTimePricePerUnit,
            id: Math.random() * 2,
          },
          isCurrentProcessStepPostProcessorType && ({
            chargeName: 'Piece Overhead Cost',
            unit: 'Piece',
            count: lineItemQuantity,
            pricePerUnit: transformedWorkstepQuoteDetailsForProcessStep?.overhead_cost_per_piece_in_run,
            price:
              transformedWorkstepQuoteDetailsForProcessStep?.overhead_cost_per_piece_in_run *
              lineItemQuantity,
            id: Math.random() * 2,
          }),
          isCurrentProcessStepPostProcessorType && ({
            chargeName: 'Run Overhead Cost',
            unit: 'Run',
            count: transformedWorkstepQuoteDetailsForProcessStep?.number_of_runs,
            pricePerUnit: transformedWorkstepQuoteDetailsForProcessStep?.overhead_cost_per_run,
            price:
              transformedWorkstepQuoteDetailsForProcessStep?.overhead_cost_per_run *
              transformedWorkstepQuoteDetailsForProcessStep?.number_of_runs,
            id: Math.random() * 2,
          }),
        ]),
        id: processStep.id,
        uuid: quoteDetail.process_step,
        updated: quoteDetail.updated,
        updated_by: quoteDetail.updated_by,
        name: processStep.name,
        notCounted,
      };

      if (quoteDetail.additional_charges.length) {
        const transformedAdditionalCharges = quoteDetail.additional_charges.map(item => ({
          chargeName: item.charge_name,
          unit: item.charge_item_units,
          pricePerUnit: item.price_per_unit,
          count: item.unit_count,
          price: item.unit_count * item.price_per_unit,
          id: Math.random() * 2,
          removable: true,
        }));

        dataToRender.data = [...dataToRender.data, ...transformedAdditionalCharges];
      }
      return dataToRender;
    });
    setTransformedSteps(allSteps);
  };

  // TODO: Check if still relevant as the API was changed.
  const calculateTotalsByProcessStep = () => {
    const totalsByProcessStepLocal = {};
    _forEach(
      processSteps,
      processStep => {
        const total = calculateProcessStepTotal(processStep, lineItemQuantity);
        if (total) {
          totalsByProcessStepLocal[processStep.uuid] = total;
        }
      },
    );
    setTotalsByProcessStep(totalsByProcessStepLocal);
  };

  useEffect(() => {
    if (processSteps) {
      calculateTotalsByProcessStep();
    }

    renderInitialData(bureauIntakeSettings);
  }, [currentProcessStep, JSON.stringify(processSteps), lineItemQuote]);

  const getTransformedStep = useCallback(processStep => {
    if (processStep) {
      return transformedSteps.find(step => step.id === processStep.id);
    }
    return null;
  }, [transformedSteps]);

  const calculateTotalPricePerPiece = useCallback(({
    laborField,
    workstationTimeField,
    additionalFields,
    pieceOverheadField,
    runOverheadField,
  }) => laborField?.pricePerUnit *
      laborField?.count +
      Math.ceil(workstationTimeField?.count *
      workstationTimeField?.pricePerUnit) +
      _sum(_map(additionalFields, 'price')) +
      (
        pieceOverheadField && runOverheadField ? (
          pieceOverheadField?.pricePerUnit +
          (runOverheadField?.pricePerUnit * runOverheadField?.count) /
          pieceOverheadField?.count
        ) : 0
      ), [getTransformedStep]);

  const getTotalPricePerPiece = useCallback(processStep => {
    const currentStep = getTransformedStep(processStep);
    if (currentStep) {
      return calculateTotalPricePerPiece({
        laborField: _find(currentStep.data, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.LABOR }),
        workstationTimeField: _find(currentStep.data, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.WORKSTATION_TIME }),
        additionalFields: _filter(currentStep.data, { removable: true }),
        pieceOverheadField: _find(currentStep.data, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.PIECE_OVERHEAD_COST }),
        runOverheadField: _find(currentStep.data, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.RUN_OVERHEAD_COST }),
      });
      // // eslint-disable-next-line unicorn/no-array-reduce,no-param-reassign,no-return-assign
      // OLD LOGIC |-> return currentStep.data.reduce((total, item) => total += item.price, 0);
    }
    return null;
  }, [getTransformedStep]);

  const getTotalPriceLineItem = processStep => {
    if (processStep.notCounted) {
      return 0;
    }
    return getTotalPricePerPiece(processStep) * lineItemQuantity;
  };

  const getModalTransformedStep = processStep => {
    const currentStep = getTransformedStep(processStep);
    if (currentStep) {
      currentStep.totalPerPiece = getTotalPricePerPiece(processStep);
      currentStep.totalPerLineItem = getTotalPriceLineItem(processStep);
      return currentStep;
    }
    return [];
  };

  const updateAllTransformedSteps = (id, data) => {
    const stepToUpdate = transformedSteps.find(step => step.id === id);
    if (stepToUpdate) {
      stepToUpdate.data = data;
    }
  };

  const toggleEditModal = () => setShowEditModal(previous => !previous);

  const toggleQuoteProcessModal = currentStep => {
    setShowQuoteProcessModal(previous => !previous);
    setCurrentProcessStep(currentStep);
  };

  const renderTotalProcessData = () => transformedSteps.map(step =>
    getTotalPriceLineItem(step))
    // eslint-disable-next-line unicorn/no-array-reduce,no-param-reassign,no-return-assign
    .reduce((total, item) => total += item, 0);

  const visibilityAffectPrice = steps => {
    // to update values if we change "Visibility Settings":
    const shouldBeTransformed = steps.map(step => {
      if (!step.in_price || !step.in_quote) {
        // some steps' price should not be counted in total
        return step.process_step;
      }
      return null;
    }).filter(step => step);

    const shallowSteps = JSON.parse(JSON.stringify(transformedSteps));
    const transformed = shallowSteps.map(step => {
      if (shouldBeTransformed.length) {
        const findToTransform = shouldBeTransformed.includes(step.uuid);
        // eslint-disable-next-line no-param-reassign
        step.notCounted = !!findToTransform;
      } else {
        // eslint-disable-next-line no-param-reassign
        step.notCounted = false;
      }

      return step;
    });
    setTransformedSteps(transformed);
    return transformed;
  };

  const handleSwitchProcessStep = processStepId => {
    const processStep = processSteps.find(({ id }) => id === processStepId);

    if (!processStep) {
      return;
    }

    setCurrentProcessStep(getModalTransformedStep(processStep));
  };

  const panelTitle = (
    <>
      <FormattedMessage
        id="line_item_quote.preview"
        defaultMessage="Quote Preview"
      />
      <Button className="pull-right" variant="success" size="xs" onClick={toggleEditModal}>
        <FormattedMessage
          id="setVisibility"
          defaultMessage="Set Visibility"
        />
      </Button>
    </>
  );

  return (
    <Card bg="dark" border="success" className="mb15">
      <Card.Header style={{ backgroundColor: '#1BC98E', color: '#fff' }} className="pd-exp">{panelTitle}</Card.Header>
      <Card.Body>
        <ListGroup fill>
          {!!publicNotes && (
            <ListGroupItem className="wrap-text">
              <div>
                <b>
                  <FormattedMessage
                    id="field.notes"
                    defaultMessage="Notes"
                  />:
                </b>
              </div>
              { nl2br(publicNotes) }
            </ListGroupItem>
          )}
          {processSteps.length > 0 && (
            <>
              <ListGroupItem>
                <Row>
                  <Col xs={5}>
                    <b>
                      <FormattedMessage
                        id="line_item.process_step"
                        defaultMessage="Process Step"
                      />
                    </b>
                  </Col>
                  <Col xs={2}>
                    <b>
                      <FormattedMessage
                        id="line_item_quote.unit_price"
                        defaultMessage="Unit Price"
                      />
                    </b>
                  </Col>
                  <Col xs={2}>
                    <b>
                      <FormattedMessage
                        id="process_step.flowTime"
                        defaultMessage="Flow Time"
                      />
                    </b>
                  </Col>
                  <Col xs={2}>
                    <b>
                      <FormattedMessage
                        id="line_item_quote.total"
                        defaultMessage="Total"
                      />
                    </b>
                  </Col>
                  <Col xs={1}>
                    <> </>
                  </Col>
                </Row>
              </ListGroupItem>
              {processSteps.map(processStep => (
                <ListGroupItem key={processStep.id}>
                  <Row>
                    <Col xs={5} className="wrap-text">
                      {processStep.name}
                    </Col>
                    <Col xs={2} className="wrap-text">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={!processStep.work_steps_quote_details.in_price
                          ? (
                            <Tooltip>
                              <div>
                                <FormattedMessage
                                  id="unitPriceBreakdown"
                                  defaultMessage="Unit Price Breakdown"
                                />
                              </div>
                              <div>
                                (<FormattedMessage
                                  id="excludedFromPrice"
                                  defaultMessage="Excluded from Price"
                                />)
                              </div>
                              <div>
                                <FormattedMessage
                                  id="field.duration"
                                  defaultMessage="Duration"
                                />: --- hours
                              </div>
                              <div>
                                <FormattedMessage
                                  id="rate"
                                  defaultMessage="Rate"
                                />: --- per hour
                              </div>
                            </Tooltip>
                          )
                          : <></>}
                      >
                        <div>
                          <span className={!processStep.work_steps_quote_details.in_price && 'crossed-line'}>
                            {processStep ? (
                              <FormattedLocalizedCost
                                value={getTotalPricePerPiece(
                                  processStep)}
                              />
                            ) : (
                              <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                            )}
                          </span>
                          {!processStep.work_steps_quote_details.in_price && (
                            <span className="ml5">
                              $0
                            </span>
                          )}
                        </div>
                      </OverlayTrigger>
                    </Col>
                    <Col xs={2} className="wrap-text">
                      {processStep ? (
                        fetching ?
                          <Loading inline />
                          : (
                            <span className={!processStep.work_steps_quote_details.in_price && 'crossed-line'}>
                              <FormattedOptionalDuration
                                intervalFormat={FORMATTED_DURATION_TYPES.DAYS}
                                value={processStep?.flow_time && convertHoursToSeconds(processStep.flow_time)}
                              /> <span>days</span>
                            </span>
                          )

                      ) : (
                        <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                      )}
                      {!processStep.work_steps_quote_details.in_price && (
                        <span className="ml5">
                          $0
                        </span>
                      )}
                    </Col>
                    <Col xs={2} className="wrap-text">
                      {processStep.work_steps_quote_details.separate && (
                        <OverlayTrigger
                          placement="top"
                          overlay={(
                            <Tooltip>
                              <FormattedMessage
                                id="lineSeparateInQuote"
                                defaultMessage="Line separate in quote"
                              />
                            </Tooltip>
                          )}
                        >
                          <Fa className="pr-1" name="info-circle" />
                        </OverlayTrigger>
                      )}
                      {processStep ? (
                        fetching && processStep.id === currentProcessStep?.id ?
                          <Loading inline />
                          : (
                            <span className={!processStep.work_steps_quote_details.in_price && 'crossed-line'}>
                              <FormattedLocalizedCost
                                value={getTotalPriceLineItem(
                                  processStep)}
                              />
                            </span>
                          )

                      ) : (
                        <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                      )}
                      {!processStep.work_steps_quote_details.in_price && (
                        <span className="ml5">
                          $0
                        </span>
                      )}
                    </Col>
                    <Col xs={1} onClick={() => toggleQuoteProcessModal(getModalTransformedStep(processStep))}>
                      <Fa name="edit" />
                    </Col>
                  </Row>

                </ListGroupItem>
              ))}
              <ListGroupItem>
                <Row>
                  <Col xs={8}>
                    <b>
                      <FormattedMessage
                        id="line_item_quote.total_for_lineitem"
                        defaultMessage="Total for Line Item"
                      />
                    </b>
                  </Col>
                  <Col xs={3} className="wrap-text">
                    {processSteps.length ? (
                      fetching ? <Loading inline /> : (
                        <FormattedLocalizedCost
                          value={renderTotalProcessData()}
                        />
                      )
                    ) : (
                      <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                    )}
                  </Col>
                  <Col xs={1} />
                </Row>
              </ListGroupItem>
            </>
          )}
          {processSteps.length === 0 && (
            <>
              No Process Steps
            </>
          )}

          <LineItemQuoteEditModal
            close={toggleEditModal}
            processSteps={processSteps}
            currentProcessStep={currentProcessStep}
            show={showEditModal}
            saveQuoteDetails={saveQuoteDetails}
            savingLineItemQuote={savingLineItemQuote}
            publicNotes={publicNotes}
            totalsByProcessStep={totalsByProcessStep}
            totalPrice={getTotalPricePerPiece}
            visibilityAffectPrice={visibilityAffectPrice}
          />

          <QuoteProcessStepModalContainer
            close={toggleQuoteProcessModal}
            pieces={lineItemQuantity}
            processStep={currentProcessStep}
            show={showQuoteProcessModal}
            saveQuoteDetails={saveQuoteDetails}
            savingLineItemQuote={savingLineItemQuote}
            publicNotes={publicNotes}
            lineItemQuote={lineItemQuote}
            updateAllTransformedSteps={updateAllTransformedSteps}
            fetching={fetching}
            setFetching={setFetching}
            processSteps={processSteps}
            handleSwitch={handleSwitchProcessStep}
            lineItemUri={lineItemUri}
            fetchedScheduling={fetchedScheduling}
            setFetchedScheduling={setFetchedScheduling}
            calculateTotalPricePerPiece={calculateTotalPricePerPiece}
          />
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

LineItemQuotePreview.defaultProps = {
  lineItemQuote: {},
};

LineItemQuotePreview.propTypes = {
  processSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // !IMPORTANT -> ********** Commenting some code for now as it will be used by other tasks *********
  // separateWorkflowProcessSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  // nonSeparateWorkflowProcessSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  saveQuoteDetails: PropTypes.func.isRequired,
  savingLineItemQuote: PropTypes.bool.isRequired,
  publicNotes: PropTypes.string.isRequired,
  lineItemQuantity: PropTypes.number.isRequired,
  lineItemQuote: PropTypes.instanceOf(Object),
  lineItemUri: PropTypes.string.isRequired,
  workstepCostEstimates: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  processStepTypesByUri: PropTypes.shape({}).isRequired,
};

export default LineItemQuotePreview;
