import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  getPrintersGroupedByPrinterType,
  getPostProcessorsGroupedByPostProcessorType,
  isFeatureEnabled,
  getWorkstationsByUri,
  getPrinterTypesByUri,
  getPostProcessorTypesByUri,
  getShippingsByUri,
  getBureauUri,
} from 'rapidfab/selectors';
import { connect } from 'react-redux';
import WorkflowPickListModal from 'rapidfab/components/records/workflow/WorkflowPickListModal';
import {
  postProcessorTypeResourceType,
  printerTypeResourceType,
} from 'rapidfab/types';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import _uniq from 'lodash/uniq';

const WorkflowPickListModalContainer = ({
  loadAccessInfoDetails,
  fetching,
  accessInfoLoading,
  isGroupQualificationsFeatureEnabled,
  ...props
}) => {
  const { printerTypes, postProcessorTypes } = props;

  useEffect(() => {
    if (isGroupQualificationsFeatureEnabled) {
      const workstationTypeUris =
        [...printerTypes, ...postProcessorTypes].map(workstationType => workstationType.uri);
      loadAccessInfoDetails(workstationTypeUris);
    }
  }, []);

  return (
    <WorkflowPickListModal
      fetching={fetching || accessInfoLoading}
      {...props}
    />
  );
};

WorkflowPickListModalContainer.propTypes = {
  workstationsGroupedByType: PropTypes.shape({}).isRequired,
  printerTypes: PropTypes.arrayOf(printerTypeResourceType).isRequired,
  postProcessorTypes: PropTypes.arrayOf(postProcessorTypeResourceType).isRequired,
  loadAccessInfoDetails: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  accessInfoLoading: PropTypes.bool.isRequired,
  isGroupQualificationsFeatureEnabled: PropTypes.bool.isRequired,
  isEditingDisabled: PropTypes.bool,
};

WorkflowPickListModalContainer.defaultProps = {
  fetching: false,
  isEditingDisabled: false,
};

function mapStateToProps(state) {
  return {
    workstationsGroupedByType: {
      ...getPrintersGroupedByPrinterType(state),
      ...getPostProcessorsGroupedByPostProcessorType(state),
    },
    workstationsByUri: getWorkstationsByUri(state),
    processTypesByUri: {
      ...getPrinterTypesByUri(state),
      ...getPostProcessorTypesByUri(state),
      ...getShippingsByUri(state),
    },
    accessInfoLoading: state.ui.nautilus[API_RESOURCES.ACCESS_INFO_FOR_RESOURCE].list.fetching
      || state.ui.nautilus[API_RESOURCES.CUSTOM_GROUP].list.fetching,
    isGroupQualificationsFeatureEnabled: isFeatureEnabled(state, FEATURES.GROUP_QUALIFICATIONS),
    bureauUri: getBureauUri(state),
    isGeneralMFGLanguageEnabled: isFeatureEnabled(state, FEATURES.GENERAL_MFG_LANGUAGE),
  };
}

const mapDispatchToProps = dispatch => ({
  loadAccessInfoDetails: workstationTypeUris =>
    // Load `access-info` details to disable workstation types not available for usage to the current user
    dispatch(Actions.Api.nautilus[API_RESOURCES.ACCESS_INFO_FOR_RESOURCE]
      .list({ target_uri: workstationTypeUris }))
      .then(
        response => {
          const customGroupUris = _uniq(
            response.json.resources.flatMap(
              resource => resource.actions
                // Ignore group uris for allowed actions
                .filter(action => !action.allowed)
                // disallow_by_edit_groups is a list of custom group URIs
                .flatMap(action => action.disallow_by_edit_groups),
            ),
          );
          if (customGroupUris.length) {
            // Load custom groups listed in prohibited actions (group names used in a tooltip)
            dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].list({ uri: customGroupUris }));
          }
        },
      ),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPickListModalContainer);
