import { createSelector } from 'reselect';
import { getStateAssemblyMeta } from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _groupBy from 'lodash/groupBy';

export const getAssemblyMeta = createSelector(
  [getStateAssemblyMeta, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getAssemblyMetaByProductUri = createSelector(
  [getAssemblyMeta],
  assemblyMeta => _groupBy(assemblyMeta, 'product'),
);
