import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import * as Selectors from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import extractUuid from 'rapidfab/utils/extractUuid';
import ShipmentComponent from 'rapidfab/components/records/Shipment';
import _pickBy from 'lodash/pickBy';
import _get from 'lodash/get';
import _set from 'lodash/set';
import dayjs from 'dayjs';

import { SHIPMENT_CONTAINER } from 'rapidfab/constants/forms';
import { FormattedMessage } from 'react-intl';

const ShipmentContainer = props => {
  const uuid = useSelector(Selectors.getRouteUUID);
  const { uuid: ID } = useParams();
  const shipment = useSelector(Selectors.getRouteUUIDResource) || {};
  const order = useSelector(state => state.resources[extractUuid(shipment.order)]);
  const run = useSelector(state => state.resources[extractUuid(shipment.run)]);
  const defaultCurrency = useSelector(Selectors.getBureauDefaultCurrency);
  const availableCurrencies = useSelector(Selectors.getConversions);
  const submitting = useSelector(state => state.ui.nautilus[API_RESOURCES.SHIPMENT].put.fetching);

  if (shipment) {
    // Remove timezone and convert just to genetic date format (YYYY-MM-DD)
    SHIPMENT_CONTAINER.DATE_FIELDS.forEach(
      fieldName => {
        const field = _get(shipment, fieldName) || '';
        if (field) {
          shipment[fieldName] = field.slice(0, 10);
        }
      },
    );
  }
  const initialValues = shipment || {};
  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => SHIPMENT_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  const selected = {
    uuid,
    initialFormValues,
    shipment,
    order,
    run,
    defaultCurrency,
    availableCurrencies,
    submitting,
  };

  const dispatch = useDispatch();

  const onInitialize = currentUUID => {
    dispatch(Actions.Api.nautilus.shipment.get(currentUUID))
      .then(resp => {
        const { order: currentOrder, run: currentRun } = resp.json;
        if (currentOrder) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(extractUuid(currentOrder)));
        }
        if (currentRun) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].get(extractUuid(currentRun)));
        }
      });
  };
  const onSave = payload => {
    const putPayload = _pickBy(payload);

    // if the delivery date was set to be before the ship date, don't submit and
    // alert
    if (dayjs(putPayload.actual_shipment_date).isAfter(putPayload.actual_delivery_date)) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.shipment.receiveDateAfterShipDate"
          defaultMessage="Please ensure actual receive date is after actual ship date"
        />);
      return false;
    }

    // Nullable fields must be submitted even if null value is here
    // If null value is not in payload, nautilus will not update it
    SHIPMENT_CONTAINER.NULL_FIELDS.forEach(
      fieldName => {
        const field = _get(payload, fieldName);
        if (!field) {
          _set(putPayload, fieldName, null);
        }
      },
    );

    SHIPMENT_CONTAINER.FIELDS.forEach(
      fieldName => {
        const field = _get(payload, fieldName);
        if (fieldName === 'actual_cost') {
          _set(putPayload, fieldName, Number.parseFloat(field));
        }
      },
    );

    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPMENT].put(putPayload.uuid, putPayload))
      .then(() => {
        Alert.success(<FormattedMessage
          id="toaster.shipment.updated"
          defaultMessage="Shipment successfully updated"
        />);
      });
    return true;
  };

  useEffect(() => {
    if (uuid) {
      onInitialize(uuid);
    } else if (ID) {
      onInitialize(ID);
    }
  }, []);

  return (
    <ShipmentComponent {...props} {...selected} onSave={onSave} />
  );
};

export default ShipmentContainer;
