import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'rapidfab/i18n';
import {
  Button,
  Modal,
} from 'react-bootstrap';
import { Form } from 'react-final-form';
import FormGroupField from 'rapidfab/components/forms/FormGroupField';
import SaveButton from 'rapidfab/components/SaveButton';
import {
  MATERIAL_CONTAINER_NUMBER_FIELD_STEP,
  MATERIAL_CONTAINER_STATUSES,
} from 'rapidfab/constants';
import _fill from 'lodash/fill';

const NewContainersModal = ({
  onClose,
  onAddNewContainers,
  materialUnitOfMeasure,
}) => {
  const formInitialValues = { numberOfContainers: 1, quantityPerContainer: 0 };
  const fields = {
    numberOfContainers: 'numberOfContainers',
    quantityPerContainer: 'quantityPerContainer',
  };

  const onSubmit = ({ numberOfContainers, quantityPerContainer }) => {
    // eslint-disable-next-line unicorn/no-new-array
    const newContainers = _fill(new Array(Number(numberOfContainers)), {
      uuid: null,
      quantity: Number(quantityPerContainer),
      status: MATERIAL_CONTAINER_STATUSES.NEW,
    });
    onAddNewContainers(newContainers);
    onClose();
  };

  return (
    <Modal show onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="container.add" defaultMessage="Add Containers" />
        </Modal.Title>
      </Modal.Header>
      <Form
        initialValues={formInitialValues}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <FormGroupField
                name={fields.numberOfContainers}
                label={(
                  <FormattedMessage
                    id="field.number_of_containers"
                    defaultMessage="Number of Containers"
                  />
                )}
                type="number"
                min="1"
                step="1"
                isRequired
              />
              <FormGroupField
                name={fields.quantityPerContainer}
                label={(
                  <FormattedMessage
                    id="field.quantityPerContainer"
                    defaultMessage="Quantity Per Container ({materialUnitOfMeasure})"
                    values={{ materialUnitOfMeasure }}
                  />
                )}
                type="number"
                min="0"
                step={MATERIAL_CONTAINER_NUMBER_FIELD_STEP}
                isRequired
              />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onClose}>
                <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
              </Button>
              <SaveButton
                variant="primary"
                label={<FormattedMessage id="button.add" defaultMessage="Add" />}
                showSaveIcon={false}
              />
            </Modal.Footer>
          </form>
        )}
      </Form>
    </Modal>
  );
};

NewContainersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAddNewContainers: PropTypes.func.isRequired,
  materialUnitOfMeasure: PropTypes.string.isRequired,
};

export default NewContainersModal;
