import Home from 'rapidfab/containers/home';
import AdminContainer from 'rapidfab/containers/admin/AdminContainer';
import AdminUsersContainer from 'rapidfab/containers/admin/AdminUsersContainer';
import AdminUserGroupsContainer from 'rapidfab/containers/admin/AdminUserGroupsContainer';
import AnalyticsContainer from 'rapidfab/containers/analytics/AnalyticsContainer';
import BannerContainer from 'rapidfab/containers/admin/BannerContainer';
import RecalculationContainer from 'rapidfab/containers/admin/RecalculationContainer';
import AdminSettingsContainer from 'rapidfab/containers/admin/AdminSettingsContainer';
import AdminImpersonationContainer from 'rapidfab/containers/admin/AdminImpersonationContainer';
import LabelsContainer from 'rapidfab/containers/admin/LabelsContainer';
import ProfileContainer from 'rapidfab/containers/profile/ProfileContainer';
import About from 'rapidfab/components/about';
import InventoryLocations from 'rapidfab/containers/manage/locations';
import InventoryManufacturers from 'rapidfab/containers/manage/manufacturers';
import InventoryShipping from 'rapidfab/containers/manage/shipping';
import AssemblyWorkflowsContainer from 'rapidfab/containers/manage/AssemblyWorkflowsContainer';
import PrepWorkflowsContainer from 'rapidfab/containers/manage/PrepWorkflowsContainer';
import ProductionWorkflowsContainer from 'rapidfab/containers/manage/ProductionWorkflowsContainer';
import InventoryMaterials from 'rapidfab/containers/manage/materials';
import InventoryStocks from 'rapidfab/containers/inventory/stocks';
import InventoryPostProcessors from 'rapidfab/containers/inventory/PostProcessorsContainer';
import InventoryPostProcessorServices from 'rapidfab/containers/inventory/PostProcessorServices';
import InventoryPostProcessorTypes from 'rapidfab/containers/manage/postProcessorTypes';
import LoginContainer from 'rapidfab/containers/LoginContainer';
import ForgotPasswordContainer from 'rapidfab/containers/ForgotPasswordContainer';
import ResetPasswordContainer from 'rapidfab/containers/ResetPasswordContainer';
import ModelLibrariesContainer from 'rapidfab/containers/manage/ModelLibrariesContainer';
import ServiceProviderContainer from 'rapidfab/containers/manage/ServiceProviderContainer';
import InventoryServiceProvidersContainer from 'rapidfab/containers/manage/ServiceProvidersContainer';
import InventoryPrinters from 'rapidfab/containers/inventory/printers';
import InventoryPrinterTypes from 'rapidfab/containers/manage/printerTypes';
import MaterialBatchesContainer from 'rapidfab/containers/inventory/MaterialBatchesContainer';
import MaterialBatchContainer from 'rapidfab/containers/records/MaterialBatchContainer';
import MaterialBatchGenealogyContainer from 'rapidfab/containers/records/MaterialBatchGenealogyContainer';
import LegacyEndpointRedirect from 'rapidfab/containers/LegacyEndpointRedirect';
import ManageConversions from 'rapidfab/containers/manage/conversions';
import Shipments from 'rapidfab/containers/manage/Shipments';
import Orders from 'rapidfab/containers/plan/orders';
import Runs from 'rapidfab/containers/plan/runs';
import CreateRunsContainer from 'rapidfab/containers/plan/CreateRunsContainer';
import Prints from 'rapidfab/containers/plan/prints';
import ServiceProviderJobsContainer from 'rapidfab/containers/plan/ServiceProviderJobsContainer';
import PiecesContainer from 'rapidfab/containers/plan/PiecesContainer';
import QueuesContainer from 'rapidfab/containers/work/QueuesContainer';
import OrderEdit from 'rapidfab/containers/records/order/edit';
import OrderNew from 'rapidfab/containers/records/order/new';
import OrderRestrictedNew from 'rapidfab/containers/records/order/restricted/NewRestrictedOrder';
import OrderRestrictedEdit from 'rapidfab/containers/records/order/restricted/EditRestrictedOrder';
import EditRestrictedLineItemOrder from 'rapidfab/containers/records/order/restricted/EditRestrictedLineItemOrder';
import ReviewRestrictedOrder from 'rapidfab/containers/records/order/restricted/ReviewRestrictedOrder';
import Location from 'rapidfab/containers/records/location';
import Manufacturer from 'rapidfab/containers/records/manufacturer';
import conversion from 'rapidfab/containers/records/conversion';
import ShippingContainer from 'rapidfab/containers/records/ShippingContainer';
import ShipmentContainer from 'rapidfab/containers/records/ShipmentContainer';
import Material from 'rapidfab/containers/records/material';
import Print from 'rapidfab/containers/records/print';
import PieceContainer from 'rapidfab/containers/records/PieceContainer';
import PrepTasksContainer from 'rapidfab/containers/manage/PrepTasksContainer';
import PrepTaskRecordsContainer from 'rapidfab/containers/plan/PrepTaskRecordsContainer';
import PrinterContainer from 'rapidfab/containers/records/PrinterContainer';
import QrPrinterContainer from 'rapidfab/containers/qr/PrinterContainer';
import QrBatchContainersContainer from 'rapidfab/containers/qr/BatchContainersContainer';
import QrLotContainersContainer from 'rapidfab/containers/qr/LotContainersContainer';
import PrinterType from 'rapidfab/containers/records/printerType';
import PostProcessorContainer from 'rapidfab/containers/records/PostProcessorContainer';
import PostProcessorType from 'rapidfab/containers/records/postProcessorType';
import PostProcessorService from 'rapidfab/containers/records/PostProcessorService';
import StockContainer from 'rapidfab/containers/records/StockContainer';
import MaterialLotNewContainer from 'rapidfab/containers/records/MaterialLotNewContainer';
import WorkflowContainer from 'rapidfab/containers/records/WorkflowContainer';
import RunRecordContainer from 'rapidfab/containers/records/run/RunRecordContainer';
import RunRecordScheduleContainer from 'rapidfab/containers/records/run/RunRecordScheduleContainer';
import RunNewContainer from 'rapidfab/containers/records/run/RunNewContainer';

import SentryTest from 'rapidfab/components/SentryTest';
import JobListContainer from 'rapidfab/containers/service_providers/JobListContainer';
import JobContainer from 'rapidfab/containers/service_providers/JobContainer';
import MaterialLotsContainer from 'rapidfab/containers/inventory/MaterialLotsContainer';
import MaterialLotContainer from 'rapidfab/containers/records/MaterialLotContainer';
import CommentContainer from 'rapidfab/containers/CommentContainer';
import CommentActionContainer from 'rapidfab/containers/CommentActionContainer';
import CapturesContainer from 'rapidfab/containers/work/CapturesContainer';
import CaptureContainer from 'rapidfab/containers/records/CaptureContainer';

import HawkingDashboardContainer from 'rapidfab/containers/hawking/HawkingDashboardContainer';
import HawkingMyLibraryContainer from 'rapidfab/containers/hawking/HawkingMyLibraryContainer';
import HawkingCompanyLibraryContainer from 'rapidfab/containers/hawking/HawkingCompanyLibraryContainer';
import HawkingAdministratorLibraryContainer from 'rapidfab/containers/hawking/HawkingAdministratorLibraryContainer';

import { FEATURES, ROUTES } from 'rapidfab/constants';
import AnatomicalModelsContainer from 'rapidfab/containers/AnatomicalModel/AnatomicalModelsContainer';
import AnatomicalModelContainer from 'rapidfab/containers/AnatomicalModel/AnatomicalModelContainer';
import CustomFieldsContainer from 'rapidfab/containers/admin/CustomFieldsContainer';

import GuidelinesEngineContainer from 'rapidfab/containers/admin/GuidelinesEngineContainer';
import GuidelineContainer from 'rapidfab/containers/admin/GuidelineContainer';

import AnalysisSettings from 'rapidfab/containers/admin/hawking/AnalysisSettings';
import DanfossDDWViewContainer from 'rapidfab/containers/danfossDDW/DanfossDDWViewContainer';
import DanfossDDWCustomLibraryContainer from 'rapidfab/containers/danfossDDW/DanfossDDWCustomLibraryContainer';
import { ROUTER_PERMISSION } from './constantsRouter';
import WIPMatrixContainer from './containers/work/WIPMatrixContainer';
import DanfossDDWDashboardContainer from './containers/danfossDDW/DanfossDDWDashboardContainer';
import MaterialTestsContainer from './containers/admin/MaterialTestsContainer';

/**
 * @param features: []|{}
 *   Route.features can be an array of feature names to be enabled
 *   or an object with key-value
 *     where key is feature name
 *     and value is boolean whether the feature needs to be enabled or disabled
 *
 * @returns {} Key-value pair of feature name and boolean
 */
export const convertRouteFeaturesToObject = features => {
  if (!Array.isArray(features)) {
    return features;
  }
  const featuresEntries = features.map(
    // For array-like config - all provided features need to be enabled
    featureName => [featureName, true],
  );
  return Object.fromEntries(featuresEntries);
};

/*
 * Contains list of all routes in the application.
 *
 * Supported keys for each route:
 * - route: path of route name. Can contains dynamic parameters (see :uuid, for example)
 * - component: component to render
 * - permission: permission value from PERMISSIONS object.
 *    Users without permissions will see 404 error
 */

const NAMED_ROUTES = {
  About: {
    route: ROUTES.ABOUT,
    component: About,
    permission: ROUTER_PERMISSION.ALL,
    // Supported for any combination of features
  },
  Admin: {
    route: ROUTES.ADMIN,
    component: AdminContainer,
    permission: ROUTER_PERMISSION.MANAGER,
    // Supported for any combination of features
  },
  AdminBanner: {
    route: ROUTES.ADMIN_BANNER,
    component: BannerContainer,
    permission: ROUTER_PERMISSION.MANAGER,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  AdminLabels: {
    route: ROUTES.ADMIN_LABELS,
    component: LabelsContainer,
    permission: ROUTER_PERMISSION.MANAGER,
    // Supported for any combination of features
  },
  AdminAnalysisSettings: {
    route: ROUTES.ADMIN_ANALYSIS_SETTINGS,
    component: AnalysisSettings,
    permission: ROUTER_PERMISSION.MANAGER,
    features: [FEATURES.HAWKING_DEPLOYMENT],
    // Supported for any combination of features
  },
  AdminRecalculation: {
    route: ROUTES.ADMIN_RECALCULATION,
    component: RecalculationContainer,
    permission: ROUTER_PERMISSION.MANAGER,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  AdminSettings: {
    route: ROUTES.ADMIN_SETTINGS,
    component: AdminSettingsContainer,
    permission: ROUTER_PERMISSION.MANAGER,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  AdminUsers: {
    route: ROUTES.ADMIN_USERS,
    component: AdminUsersContainer,
    permission: ROUTER_PERMISSION.MANAGER,
    // Supported for any combination of features
  },
  AdminUserGroups: {
    route: ROUTES.ADMIN_USER_GROUPS,
    component: AdminUserGroupsContainer,
    permission: ROUTER_PERMISSION.MANAGER,
    decidingFeatures: [FEATURES.PREP_WORKFLOW, FEATURES.GROUP_QUALIFICATIONS],
  },
  AdminImpersonation: {
    route: ROUTES.ADMIN_IMPERSONATION,
    component: AdminImpersonationContainer,
    // [!] We have to allow to visit this route by any user
    // since it can be impersonated user
    permission: ROUTER_PERMISSION.LOGGED_IN_ONLY,
    // [!] No feature-based limitation should be here as well
    // since original user and impersonating user may have different list of features
  },
  AdminCustomFields: {
    route: ROUTES.ADMIN_CUSTOM_FIELDS,
    component: CustomFieldsContainer,
    permission: ROUTER_PERMISSION.ALL,
  },
  // Material Tests
  AdminMaterialTests: {
    route: ROUTES.MATERIAL_TESTS,
    component: MaterialTestsContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    // features: [FEATURES.GUIDELINES_ENGINE],
    // decidingFeatures: [FEATURES.MATERIAL_TEST],
  },
  AdminNewMaterialTest: {
    route: ROUTES.MATERIAL_TESTS_NEW,
    component: MaterialTestsContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    // features: [FEATURES.GUIDELINES_ENGINE],
    // decidingFeatures: [FEATURES.MATERIAL_TEST],
  },
  AdminEditMaterialTest: {
    route: ROUTES.MATERIAL_TESTS_EDIT,
    component: MaterialTestsContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    // features: [FEATURES.GUIDELINES_ENGINE],
    // decidingFeatures: [FEATURES.MATERIAL_TEST],
  },
  // Guidelines engine
  AdminGuidelinesEngine: {
    route: ROUTES.GUIDELINES_ENGINE,
    component: GuidelinesEngineContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    // features: [FEATURES.GUIDELINES_ENGINE],
    decidingFeatures: [FEATURES.GUIDELINES_ENGINE],
  },
  AdminNewGuideline: {
    route: ROUTES.GUIDELINES_ENGINE_NEW,
    component: GuidelineContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.GUIDELINES_ENGINE],
    decidingFeatures: [FEATURES.GUIDELINES_ENGINE],
  },
  AdminEditGuideline: {
    route: ROUTES.GUIDELINES_ENGINE_EDIT,
    component: GuidelineContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.GUIDELINES_ENGINE],
    decidingFeatures: [FEATURES.GUIDELINES_ENGINE],
  },
  Analytics: {
    route: ROUTES.ANALYTICS,
    component: AnalyticsContainer,
    permission: ROUTER_PERMISSION.LOGGED_IN_ONLY,
    features: [FEATURES.MACHINE_ANALYTICS],
  },
  BuildsLibrary: {
    route: ROUTES.BUILDS_LIBRARY,
    component: ModelLibrariesContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  Captures: {
    route: ROUTES.CAPTURES,
    component: CapturesContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  Capture: {
    route: ROUTES.CAPTURE,
    component: CaptureContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  ConversionEdit: {
    route: ROUTES.CONVERSION_EDIT,
    component: conversion,
    permission: ROUTER_PERMISSION.BUREAU_UNRESTRICTED,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  ConversionNew: {
    route: ROUTES.CONVERSION_NEW,
    component: conversion,
    permission: ROUTER_PERMISSION.BUREAU_UNRESTRICTED,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  Conversions: {
    route: ROUTES.CONVERSIONS,
    component: ManageConversions,
    permission: ROUTER_PERMISSION.BUREAU_UNRESTRICTED,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  ForgotPassword: {
    route: ROUTES.FORGOT_PASSWORD,
    component: ForgotPasswordContainer,
    permission: ROUTER_PERMISSION.GUEST,
    // Guest routes do not require feature limitations
  },
  Home: {
    route: ROUTES.HOME,
    component: Home,
    // Restricted or Service Provider users will be redirected
    // to proper endpoint (see render() method of Home component)
    permission: ROUTER_PERMISSION.LOGGED_IN_ONLY,
    // No Feature-based limitation needed since Home route has redirects based on features
  },
  Login: {
    route: ROUTES.LOGIN,
    component: LoginContainer,
    permission: ROUTER_PERMISSION.GUEST,
    // Guest routes do not require feature limitations
  },
  Comment: {
    route: ROUTES.COMMENT,
    component: CommentContainer,
    permission: ROUTER_PERMISSION.LOGGED_IN_ONLY,
    // Comments are expected to be used for any combination of features
  },
  CommentAction: {
    // Entity is called CommentAction, while for users it is shown as Assignment to Comment
    route: ROUTES.COMMENT_ACTION,
    component: CommentActionContainer,
    permission: ROUTER_PERMISSION.LOGGED_IN_ONLY,
    // Expected to be used for any combination of features (along with Comments)
  },
  LocationEdit: {
    route: ROUTES.LOCATION_EDIT,
    component: Location,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  LocationNew: {
    route: ROUTES.LOCATION_NEW,
    component: Location,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  Locations: {
    route: ROUTES.LOCATIONS,
    component: InventoryLocations,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  ManufacturerEdit: {
    route: ROUTES.MANUFACTURER_EDIT,
    component: Manufacturer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  ManufacturerNew: {
    route: ROUTES.MANUFACTURER_NEW,
    component: Manufacturer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  Manufacturers: {
    route: ROUTES.MANUFACTURERS,
    component: InventoryManufacturers,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  MaterialBatch: {
    route: ROUTES.MATERIAL_BATCH,
    component: MaterialBatchContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MATERIAL_MANAGEMENT],
  },
  MaterialBatches: {
    route: ROUTES.MATERIAL_BATCHES,
    component: MaterialBatchesContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MATERIAL_MANAGEMENT],
  },
  MaterialBatchGenealogy: {
    route: ROUTES.MATERIAL_BATCH_GENEALOGY,
    component: MaterialBatchGenealogyContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MATERIAL_MANAGEMENT],
  },
  MaterialEdit: {
    route: ROUTES.MATERIAL_EDIT,
    component: Material,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  MaterialNew: {
    route: ROUTES.MATERIAL_NEW,
    component: Material,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  Materials: {
    route: ROUTES.MATERIALS,
    component: InventoryMaterials,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  ModelLibrary: {
    route: ROUTES.MODEL_LIBRARY,
    component: ModelLibrariesContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
      [FEATURES.SPECIMEN_LIBRARY]: true,
    },
  },
  MaterialLot: {
    route: ROUTES.MATERIAL_LOT,
    component: MaterialLotContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MATERIAL_MANAGEMENT],
  },
  MaterialLotNew: {
    route: ROUTES.MATERIAL_LOT_NEW,
    component: MaterialLotNewContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MATERIAL_MANAGEMENT],
  },
  MaterialLots: {
    route: ROUTES.MATERIAL_LOTS,
    component: MaterialLotsContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MATERIAL_MANAGEMENT],
  },
  OrderEdit: {
    route: ROUTES.ORDER_EDIT,
    component: OrderEdit,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  OrderNew: {
    route: ROUTES.ORDER_NEW,
    component: OrderNew,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  Orders: {
    route: ROUTES.ORDERS,
    component: Orders,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  Pieces: {
    route: ROUTES.PIECES,
    component: PiecesContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  PieceEdit: {
    route: ROUTES.PIECE_EDIT,
    component: PieceContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  PrintBatchContainersQRCodes: {
    route: ROUTES.PRINT_BATCH_CONTAINERS_QR_CODES,
    component: QrBatchContainersContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    hideLayout: true,
    features: [FEATURES.MATERIAL_MANAGEMENT],
  },
  PrintBatchQRCode: {
    route: ROUTES.PRINT_BATCH_CONTAINER_QR_CODE,
    component: QrBatchContainersContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    hideLayout: true,
    features: [FEATURES.MATERIAL_MANAGEMENT],
  },
  PrintEdit: {
    route: ROUTES.PRINT_EDIT,
    component: Print,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  PrinterEdit: {
    route: ROUTES.PRINTER_EDIT,
    component: PrinterContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  PrinterNew: {
    route: ROUTES.PRINTER_NEW,
    component: PrinterContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  Printers: {
    route: ROUTES.PRINTERS,
    component: InventoryPrinters,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  PrinterTypeEdit: {
    route: ROUTES.PRINTER_TYPE_EDIT,
    component: PrinterType,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  PrinterTypeNew: {
    route: ROUTES.PRINTER_TYPE_NEW,
    component: PrinterType,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  PrinterTypes: {
    route: ROUTES.PRINTER_TYPES,
    component: InventoryPrinterTypes,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  PrintMaterialLotContainersQRCodes: {
    route: ROUTES.PRINT_MATERIAL_LOT_CONTAINERS_QR_CODES,
    component: QrLotContainersContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    hideLayout: true,
    features: [FEATURES.MATERIAL_MANAGEMENT],
  },
  PrintMaterialLotQRCode: {
    route: ROUTES.PRINT_MATERIAL_LOT_CONTAINER_QR_CODE,
    component: QrLotContainersContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    hideLayout: true,
    features: [FEATURES.MATERIAL_MANAGEMENT],
  },
  PrintPrinterQR: {
    route: ROUTES.PRINT_PRINTER_QR,
    component: QrPrinterContainer,
    hideLayout: true,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MATERIAL_MANAGEMENT],
  },
  Prints: {
    route: ROUTES.PRINTS,
    component: Prints,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  Profile: {
    route: ROUTES.PROFILE,
    component: ProfileContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    // TODO: Do we need to hide it for Hawking users?
  },
  PostProcessorEdit: {
    route: ROUTES.POST_PROCESSOR_EDIT,
    component: PostProcessorContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  PostProcessorNew: {
    route: ROUTES.POST_PROCESSOR_NEW,
    component: PostProcessorContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  PostProcessors: {
    route: ROUTES.POST_PROCESSORS,
    component: InventoryPostProcessors,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  // TODO: This route config is an edge-case and needs to be handled in some other way. It is allowed for all for now
  PostProcessorServices: {
    route: ROUTES.POST_PROCESSOR_SERVICES,
    component: InventoryPostProcessorServices,
    // TODO: Looks like `bureu` + `sp` should be here instead of `all logged in`
    permission: ROUTER_PERMISSION.LOGGED_IN_ONLY,
    // TODO: Route is hidden under `SERVICE_PROVIDER_AT_STEPS` for regular user
    //  but SP role has no such feature
  },
  PostProcessorTypeEdit: {
    route: ROUTES.POST_PROCESSOR_TYPE_EDIT,
    component: PostProcessorType,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  PostProcessorTypeNew: {
    route: ROUTES.POST_PROCESSOR_TYPE_NEW,
    component: PostProcessorType,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  PostProcessorTypes: {
    route: ROUTES.POST_PROCESSOR_TYPES,
    component: InventoryPostProcessorTypes,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  PostProcessorServiceEdit: {
    route: ROUTES.POST_PROCESSOR_SERVICE_EDIT,
    component: PostProcessorService,
    permission: ROUTER_PERMISSION.LOGGED_IN_ONLY,
    // TODO: See PostProcessorServices route
  },
  PostProcessorServiceNew: {
    route: ROUTES.POST_PROCESSOR_SERVICE_NEW,
    component: PostProcessorService,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    // TODO: See PostProcessorServices route
  },
  PrepTasks: {
    route: ROUTES.PREP_TASKS,
    component: PrepTasksContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.PREP_WORKFLOW],
  },
  PrepTaskRecords: {
    route: ROUTES.PREP_TASK_RECORDS,
    component: PrepTaskRecordsContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.PREP_WORKFLOW],
  },
  Queues: {
    route: ROUTES.QUEUES,
    component: QueuesContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  ResetPassword: {
    route: ROUTES.RESET_PASSWORD,
    component: ResetPasswordContainer,
    permission: ROUTER_PERMISSION.GUEST,
    // Guest routes do not require feature limitations
  },
  RunCreate: {
    route: ROUTES.RUN_CREATE,
    component: RunNewContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  RunEdit: {
    route: ROUTES.RUN_EDIT,
    component: RunRecordContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  Runs: {
    route: ROUTES.RUNS,
    component: Runs,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  CreateRuns: {
    route: ROUTES.CREATE_RUNS,
    component: CreateRunsContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  RunSchedule: {
    route: ROUTES.RUN_SCHEDULE,
    component: RunRecordScheduleContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: {
      [FEATURES.HAWKING_DEPLOYMENT]: false,
    },
  },
  SentryTest: {
    route: ROUTES.SENTRY_TEST,
    component: SentryTest,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    // No limitations by feature is needed
  },
  ServiceProviderEdit: {
    route: ROUTES.SERVICE_PROVIDER_EDIT,
    component: ServiceProviderContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.SERVICE_PROVIDERS],
  },
  ServiceProviderJobs: {
    route: ROUTES.SERVICE_PROVIDER_JOBS,
    component: ServiceProviderJobsContainer,
    permission: ROUTER_PERMISSION.LOGGED_IN_ONLY,
    features: [FEATURES.SERVICE_PROVIDERS],
  },
  ServiceProviderNew: {
    route: ROUTES.SERVICE_PROVIDER_NEW,
    component: ServiceProviderContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.SERVICE_PROVIDERS],
  },
  ServiceProviders: {
    route: ROUTES.SERVICE_PROVIDERS,
    component: InventoryServiceProvidersContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.SERVICE_PROVIDERS],
  },
  ShipmentEdit: {
    route: ROUTES.SHIPMENT_EDIT,
    component: ShipmentContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    // TODO: See Shipments route
    decidingFeatures: [
      FEATURES.SHIPMENT_FOR_ORDER,
      FEATURES.SERVICE_PROVIDER_AT_STEPS,
    ],
  },
  Shipments: {
    route: ROUTES.SHIPMENTS,
    component: Shipments,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    // TODO: master-ricoh branch has additional features where this route is required
    decidingFeatures: [
      FEATURES.SHIPMENT_FOR_ORDER,
      FEATURES.SERVICE_PROVIDER_AT_STEPS,
    ],
  },
  ShippingEdit: {
    route: ROUTES.SHIPPING_EDIT,
    component: ShippingContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  ShippingNew: {
    route: ROUTES.SHIPPING_NEW,
    component: ShippingContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  Shippings: {
    route: ROUTES.SHIPPINGS,
    component: InventoryShipping,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  StockEdit: {
    route: ROUTES.STOCK_EDIT,
    component: StockContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  StockNew: {
    route: ROUTES.STOCK_NEW,
    component: StockContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  Stocks: {
    route: ROUTES.STOCKS,
    component: InventoryStocks,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  WorkflowCreate: {
    route: ROUTES.WORKFLOW_CREATE,
    component: WorkflowContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  WorkflowEdit: {
    route: ROUTES.WORKFLOW_EDIT,
    component: WorkflowContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  AssemblyWorkflowList: {
    route: ROUTES.ASSEMBLY_WORKFLOW_LIST,
    component: AssemblyWorkflowsContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
  },
  ProductionWorkflowList: {
    route: ROUTES.PRODUCTION_WORKFLOW_LIST,
    component: ProductionWorkflowsContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  PrepWorkflowList: {
    route: ROUTES.PREP_WORKFLOW_LIST,
    component: PrepWorkflowsContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.PREP_WORKFLOW],
  },
  WIPMatrix: {
    route: ROUTES.WIP_MATRIX,
    component: WIPMatrixContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
  },

  // Routes below available ONLY for "restricted" roles
  EditRestrictedLineItemOrder: {
    route: ROUTES.EDIT_RESTRICTED_LINE_ITEM_ORDER,
    component: EditRestrictedLineItemOrder,
    permission: ROUTER_PERMISSION.RESTRICTED,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  OrderRestrictedEdit: {
    route: ROUTES.ORDER_RESTRICTED_EDIT,
    component: OrderRestrictedEdit,
    permission: ROUTER_PERMISSION.RESTRICTED,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  OrderRestrictedNew: {
    route: ROUTES.ORDER_RESTRICTED_NEW,
    component: OrderRestrictedNew,
    permission: ROUTER_PERMISSION.RESTRICTED,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },
  ReviewRestrictedOrder: {
    route: ROUTES.REVIEW_RESTRICTED_ORDER,
    component: ReviewRestrictedOrder,
    permission: ROUTER_PERMISSION.RESTRICTED,
    features: [FEATURES.MANUFACTURING_EXECUTION_SYSTEM],
  },

  // Routes below available ONLY for Service Provider group
  ServiceProviderJobList: {
    route: ROUTES.SERVICE_PROVIDER_JOB_LIST,
    component: JobListContainer,
    permission: ROUTER_PERMISSION.SERVICE_PROVIDER,
    // TODO: Do we need any limitations by features for SP role?
  },
  ServiceProviderJob: {
    route: ROUTES.SERVICE_PROVIDER_JOB,
    component: JobContainer,
    permission: ROUTER_PERMISSION.SERVICE_PROVIDER,
    // TODO: See ServiceProviderJobList route
  },

  // Routes only available for stanley-x users
  StanleyXLogin: {
    route: ROUTES.LOGIN_STANLEY_X,
    component: LoginContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.STANLEY_X_DEPLOYMENT],
  },

  // Routes only available for Danfoss users
  DanfossDDW: {
    route: ROUTES.DIGITAL_DESIGN_WAREHOUSE,
    component: LegacyEndpointRedirect,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.ORDER_BUSINESS_SEGMENT],
  },
  DanfossDDWRestricted: {
    route: ROUTES.DIGITAL_DESIGN_WAREHOUSE_RESTRICTED,
    component: LegacyEndpointRedirect,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.ORDER_BUSINESS_SEGMENT],
  },
  DanfossDDWDashboard: {
    route: ROUTES.DDW_RESTRICTED_HOME,
    component: DanfossDDWDashboardContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.ORDER_BUSINESS_SEGMENT],
  },
  DanfossDDWCompanyLibrary: {
    route: ROUTES.DDW_COMPANY_LIBRARY,
    component: DanfossDDWViewContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.HAWKING_DEPLOYMENT || FEATURES.AUTHENTISE_PDM],
  },
  DanfossDDWCustomLibrary: {
    route: ROUTES.DDW_CUSTOM_LIBRARY,
    component: DanfossDDWViewContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.HAWKING_DEPLOYMENT || FEATURES.AUTHENTISE_PDM],
  },
  DanfossDDWMyLibrary: {
    route: ROUTES.DDW_MY_LIBRARY,
    component: DanfossDDWViewContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.HAWKING_DEPLOYMENT || FEATURES.AUTHENTISE_PDM],
  },
  DanfossDDWAdministratorLibrary: {
    route: ROUTES.DDW_ALL_DESIGNS,
    component: DanfossDDWViewContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.HAWKING_DEPLOYMENT || FEATURES.AUTHENTISE_PDM],
  },
  // Danfoss DDW (restricted)
  DanfossDDWRestrictedCompanyLibrary: {
    route: ROUTES.DDW_RESTRICTED_COMPANY_LIBRARY,
    component: HawkingCompanyLibraryContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.HAWKING_DEPLOYMENT || FEATURES.AUTHENTISE_PDM],
  },
  DanfossDDWRestrictedMyLibrary: {
    route: ROUTES.DDW_RESTRICTED_MY_LIBRARY,
    component: HawkingMyLibraryContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.HAWKING_DEPLOYMENT || FEATURES.AUTHENTISE_PDM],
  },
  DanfossDDWCustomLibraryRestricted: {
    route: ROUTES.DDW_CUSTOM_LIBRARY_RESTRICTED,
    component: DanfossDDWCustomLibraryContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.HAWKING_DEPLOYMENT || FEATURES.AUTHENTISE_PDM],
  },
  DanfossDDWRestrictedAdministratorLibrary: {
    route: ROUTES.DDW_RESTRICTED_ADMINISTRATOR_LIBRARY,
    component: HawkingAdministratorLibraryContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.HAWKING_DEPLOYMENT || FEATURES.AUTHENTISE_PDM],
  },

  // Routes only available for xerox/hawking users
  HawkingDashboard: {
    route: ROUTES.HAWKING_DASHBOARD,
    component: HawkingDashboardContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.HAWKING_DEPLOYMENT || FEATURES.AUTHENTISE_PDM],
  },
  HawkingCompanyLibrary: {
    route: ROUTES.HAWKING_COMPANY_LIBRARY,
    component: HawkingCompanyLibraryContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.HAWKING_DEPLOYMENT || FEATURES.AUTHENTISE_PDM],
  },
  HawkingMyLibrary: {
    route: ROUTES.HAWKING_MY_LIBRARY,
    component: HawkingMyLibraryContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.HAWKING_DEPLOYMENT || FEATURES.AUTHENTISE_PDM],
  },
  HawkingAdministratorLibrary: {
    route: ROUTES.HAWKING_ADMINISTRATOR_LIBRARY,
    component: HawkingAdministratorLibraryContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.HAWKING_DEPLOYMENT || FEATURES.AUTHENTISE_PDM],
  },
  HawkingLogin: {
    route: ROUTES.LOGIN_XEROX,
    component: LoginContainer,
    permission: ROUTER_PERMISSION.GUEST,
    // Guest routes do not require feature limitations
  },
  HawkingResetPassword: {
    route: ROUTES.RESET_PASSWORD_XEROX,
    component: ResetPasswordContainer,
    permission: ROUTER_PERMISSION.GUEST,
    // Guest routes do not require feature limitations
  },
  HawkingForgotPassword: {
    route: ROUTES.FORGOT_PASSWORD_XEROX,
    component: ForgotPasswordContainer,
    permission: ROUTER_PERMISSION.GUEST,
    // Guest routes do not require feature limitations
  },

  // Temporary endpoints. Will be removed once the functionality is no longer needed.
  // Hidden under `anatomical-model` feature
  AnatomicalModelList: {
    route: ROUTES.ANATOMICAL_MODELS,
    component: AnatomicalModelsContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.ANATOMICAL_MODEL],
  },
  AnatomicalModelNew: {
    route: ROUTES.ANATOMICAL_MODEL_NEW,
    component: AnatomicalModelContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.ANATOMICAL_MODEL],
  },
  AnatomicalModelEdit: {
    route: ROUTES.ANATOMICAL_MODEL_EDIT,
    component: AnatomicalModelContainer,
    permission: ROUTER_PERMISSION.BUREAU_ONLY,
    features: [FEATURES.ANATOMICAL_MODEL],
  },

  // Deprecated endpoints
  TemplateNew: {
    route: ROUTES.TEMPLATE_NEW,
    component: LegacyEndpointRedirect,
    permission: ROUTER_PERMISSION.LOGGED_IN_ONLY,
  },
  TemplateEdit: {
    route: ROUTES.TEMPLATE_EDIT,
    component: LegacyEndpointRedirect,
    permission: ROUTER_PERMISSION.LOGGED_IN_ONLY,
  },
};

export default NAMED_ROUTES;
