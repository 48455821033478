import getEndpointFromUri from 'rapidfab/utils/getEndpointFromURI';
import { ENTITY_TYPE_TO_NON_ENTITY_API_RESOURCE_MAP } from 'rapidfab/mappings';
import _findKey from 'lodash/findKey';
import { ENTITY_TYPES } from 'rapidfab/constants';

export default function getEntityTypeFromUri(uri) {
  const { endpointName } = getEndpointFromUri(uri);

  // Search in aliases
  let entityType = _findKey(
    ENTITY_TYPE_TO_NON_ENTITY_API_RESOURCE_MAP,
    apiResources => apiResources.includes(endpointName),
  );

  if (Object.values(ENTITY_TYPES).includes(endpointName)) {
    // Endpoint name is an entity
    entityType = endpointName;
  }

  if (!entityType) {
    throw new Error(`Entity Type for ${endpointName} endpoint not found`);
  }

  return entityType;
}
