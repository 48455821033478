import React, { Component } from 'react';
import { Picky } from 'react-picky';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import _isPlainObject from 'lodash/isPlainObject';

class SelectMultiple extends Component {
  static normalizeSelectedData(data) {
    let selectedData = data;
    if (_isPlainObject(data)) {
      selectedData = [selectedData];
    }
    if (selectedData === null) {
      selectedData = [];
    }
    return selectedData;
  }

  constructor(props) {
    super(props);

    const selectedData = SelectMultiple.normalizeSelectedData(this.props.selectedData);
    this.state = {
      selectedData,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { selectedData } = this.props;
    const normalizedSelectedData = SelectMultiple.normalizeSelectedData(selectedData);
    if (
      !_isEqual(
        normalizedSelectedData,
        SelectMultiple.normalizeSelectedData(prevProps.selectedData),
      )
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedData: normalizedSelectedData });
    }
  }

  handleOnChange(data) {
    this.setState({ selectedData: SelectMultiple.normalizeSelectedData(data) });
  }

  handleOnClose() {
    const { multiple } = this.props;
    let { selectedData } = this.state;

    selectedData = SelectMultiple.normalizeSelectedData(selectedData);

    if (!multiple) {
      if (selectedData && selectedData.length > 0) {
        this.props.handleOnClose(selectedData[0]);
      } else {
        this.props.handleOnClose(null);
      }
      return false;
    }

    this.props.handleOnClose(selectedData);
    return true;
  }

  render() {
    const {
      title, data, labelKey, valueKey,
      className, multiple, showBadge, keepOpen,
      includeFilter,
      disabled,
      selectAllOption,
    } = this.props;
    const { selectedData } = this.state;
    const selectedDataCount = selectedData && selectedData.length;

    return (
      <span className={className}>
        {
          showBadge &&
            <sup className="badge-pull-right"><span className={`pickyNumber ${!selectedDataCount ? 'pickyNumber__hidden' : ''}`}>{selectedDataCount}</span></sup>
        }
        <Picky
          placeholder={title}
          manySelectedPlaceholder={title}
          allSelectedPlaceholder={title}
          options={data}
          labelKey={labelKey}
          valueKey={valueKey}
          includeFilter={includeFilter}
          value={selectedData}
          keepOpen={keepOpen}
          disabled={disabled}
          multiple={multiple}
          onChange={values => this.handleOnChange(values)}
          onClose={values => this.handleOnClose(values)}
          dropdownHeight={600}
          numberDisplayed={0}
          className="wrap-text"
          renderSelectAll={selectAllOption ? ({
            filtered,
            tabIndex,
            allSelected,
            toggleSelectAll,
            // eslint-disable-next-line consistent-return
          }) => {
            if (multiple && !filtered) {
              return (
                <div
                  tabIndex={tabIndex}
                  role="option"
                  aria-selected
                  className={allSelected ? 'option selected' : 'option'}
                  onClick={toggleSelectAll}
                  onKeyPress={toggleSelectAll}
                >
                  <h5 style={{ color: '#000' }}>Select All</h5>
                </div>
              );
            }
          } : null}
        />
      </span>
    );
  }
}

SelectMultiple.defaultProps = {
  className: null,
  multiple: true,
  selectedData: [],
  showBadge: true,
  keepOpen: true,
  includeFilter: true,
  disabled: false,
  selectAllOption: false,
};

SelectMultiple.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  selectedData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  handleOnClose: PropTypes.func.isRequired,
  showBadge: PropTypes.bool,
  keepOpen: PropTypes.bool,
  includeFilter: PropTypes.bool,
  disabled: PropTypes.bool,
  selectAllOption: PropTypes.bool,
};

export default SelectMultiple;
