import React from 'react';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';

const Loading = ({ size, className, inline, style }) => {
  const styles = {
    ...style,
  };
  if (inline) {
    styles.display = 'inline-block';
  }

  return (
    <div style={styles} className={`text-center ${className}`}>
      <Fa name="spinner" spin size={size} />
    </div>
  );
};

Loading.defaultProps = {
  size: null,
  className: null,
  inline: false,
  style: {},
};

Loading.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  inline: PropTypes.bool,
  style: PropTypes.shape({}),
};

export default Loading;
