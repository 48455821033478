import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PREP_TASK_RECORD_STATUSES_MAP } from 'rapidfab/mappings';
import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import PrepTaskRecordModalContainer from 'rapidfab/containers/work/PrepTaskRecordModalContainer';

const PrepTaskRecords = ({ initialPrepTaskRecordUUID, ...componentProps }) => {
  const { data, fetching, onSort } = componentProps;
  const [isModalShown, setIsModalShown] = useState(false);
  const [currentPrepTaskRecordUUID, setCurrentPrepTaskRecordUUID] = useState(null);

  const closeModal = () => {
    setIsModalShown(false);
    setCurrentPrepTaskRecordUUID(null);
  };

  const showModal = prepTaskUUID => {
    setIsModalShown(true);
    setCurrentPrepTaskRecordUUID(prepTaskUUID);
  };

  useEffect(() => {
    if (initialPrepTaskRecordUUID) {
      showModal(initialPrepTaskRecordUUID);
    }
  }, [initialPrepTaskRecordUUID]);

  const columns = [
    {
      type: 'modal',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      func: showModal,
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: PREP_TASK_RECORD_STATUSES_MAP,
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'task',
      accessor: 'name',
      defaultMessage: 'Task',
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'shortName',
      accessor: 'shortname',
      defaultMessage: 'Short Name',
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'orderName',
      accessor: 'order_name',
      defaultMessage: 'Order Name',
      isSortable: true,
    },
  ];

  return (
    <>
      <TableWithSearching
        {...componentProps}
        data={data}
        columns={columns}
        withBreadcrumbs
        breadcrumbs={['work', 'prepTaskRecords']}
        isFetching={fetching}
        isManualSoringEnabled
        isUpdatedColumnShown={false}
        manualSortingFunc={onSort}
      />
      {isModalShown && (
        <PrepTaskRecordModalContainer
          prepTaskRecordUUID={currentPrepTaskRecordUUID}
          onClose={closeModal}
        />
      )}
    </>
  );
};

PrepTaskRecords.propTypes = {
  initialPrepTaskRecordUUID: PropTypes.string,
};

PrepTaskRecords.defaultProps = {
  initialPrepTaskRecordUUID: null,
};

export default PrepTaskRecords;
