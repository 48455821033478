import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import { useSelector, useDispatch } from 'react-redux';
import Fa from 'react-fontawesome';

import {
  getBureau,
  getCustomOrderFieldReferences,
  getOrders,
  isCurrentUserRestricted,
  isFeatureEnabled,
  getUsers,
  getIsDebugModeEnabled,
} from 'rapidfab/selectors';
import userSort from 'rapidfab/utils/userSort';

import Orders from 'rapidfab/components/plan/orders';
import {
  API_RESOURCES,
  FEATURES,
  ORDER_STATUS,
  ORDERS_VIEW_MODE_CHART_LIMIT,
  ORDERS_VIEW_MODE_LIST_LIMIT,
  ROUTES, VIEW_MODE_OPTIONS,
} from 'rapidfab/constants';
import { Col, FormLabel, FormControl, Container, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { FormattedMessage, FormattedMessageMappingOption } from 'rapidfab/i18n';
import { MATERIAL_FAMILY_MAP, ORDER_STATUS_MAP } from 'rapidfab/mappings';
import SelectMultiple from 'rapidfab/components/forms/SelectMultiple';
import _mapValues from 'lodash/mapValues';
import RouterContext from 'rapidfab/context/RouterContext';
import { useNavigate } from 'react-router-dom';
import Feature from 'rapidfab/components/Feature';
import Loading from 'rapidfab/components/Loading';
import withRecordsListHandling from '../hocs/withRecordsListHandling';
import { orderResourceType } from '../../types';
import OrdersGanttChartHeader from '../../components/records/order/OrdersGanttChartHeader';
import OrdersGanttChartContainer from '../records/order/OrdersGanttChartContainer';

const OrdersContainer = props => {
  const bureau = useSelector(getBureau);
  const isBoeingUser = useSelector(state => isFeatureEnabled(state, FEATURES.BOEING_ORDER_FIELDS));
  const isOrderQuoteFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.ORDER_QUOTE));
  const isDanfossUser = useSelector(state => isFeatureEnabled(state, FEATURES.ORDER_BUSINESS_SEGMENT));
  const isRestrictedUser = useSelector(isCurrentUserRestricted);
  const customFieldReferences = useSelector(getCustomOrderFieldReferences);
  const isOrderOwnerColumnEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.ORDER_ORDER_OWNER));
  const isDebugModeEnabled = useSelector(state => getIsDebugModeEnabled(state));
  const navigate = useNavigate();
  const fetchingUsers = useSelector(state => state.ui.nautilus[API_RESOURCES.USERS].list.fetching);
  const [fetchedUsers, setFetchedUsers] = useState(false);

  // Prepare options list based on order statuses for SelectMultiple
  const availableOrderStatusOptions = _mapValues(ORDER_STATUS_MAP, (statusOption, status) => ({
    label: statusOption.defaultMessage,
    status,
  }));

  const isPrepWorkflowFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.PREP_WORKFLOW));
  if (!isPrepWorkflowFeatureEnabled) {
    // Exclude IN_PREPARATION status when `prep-workflow` feature is disabled
    delete availableOrderStatusOptions[ORDER_STATUS.IN_PREPARATION];
  }

  const isOrderDueDateFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.ORDER_DUE_DATE));

  const users = useSelector(getUsers).sort(userSort);

  const dispatch = useDispatch();

  const onFetchUsers = useCallback(() =>
    !fetchedUsers && dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list())
      .then(() => setFetchedUsers(true)), [dispatch, fetchedUsers]);

  const routerContext = useContext(RouterContext);
  const [viewMode, setViewMode] = useState(VIEW_MODE_OPTIONS[routerContext.ordersViewMode]);
  const { ordersViewMode } = routerContext;

  useEffect(() => {
    if (ordersViewMode) {
      setViewMode(VIEW_MODE_OPTIONS[ordersViewMode]);
    }
  }, [ordersViewMode]);

  useEffect(() => {
    if (viewMode) {
      props.onLimitChange(viewMode === VIEW_MODE_OPTIONS.CHART ?
        ORDERS_VIEW_MODE_CHART_LIMIT :
        ORDERS_VIEW_MODE_LIST_LIMIT);
    }
  }, [viewMode]);

  const onSelectChange = event => {
    const { value } = event.target;

    const { used_material_families, ...changedFilters } = props.filters;
    if (value) {
      changedFilters.used_material_families = [value];
    }

    props.onFilterUpdate(changedFilters);
  };

  const onStatusChange = selectedStatuses => {
    const { status, ...changedFilters } = props.filters;
    if (selectedStatuses.length) {
      changedFilters.status = selectedStatuses.map(statusItem => statusItem.status);
    }

    props.onFilterUpdate(changedFilters);
  };

  const onOwnerChange = event => {
    const { value } = event.target;
    const { order_owner_name, ...changedFilters } = props.filters;
    if (value) {
      changedFilters.order_owner_name = [value];
    }
    props.onFilterUpdate(changedFilters);
  };

  const onSetViewMode = currentViewMode => {
    if (currentViewMode !== VIEW_MODE_OPTIONS.CHART && !window.location.hash.includes('?view_mode=chart')) {
      navigate(`${ROUTES.ORDERS}?view_mode=chart`);
    } else {
      navigate(ROUTES.ORDERS);
    }

    setViewMode(currentViewMode);
  };

  const { used_material_families: materialFamily,
    status: statuses = [],
    order_owner_name: orderOwner } = props.filters;

  // Converting array of string to options list for SelectMultiple to work properly
  const selectedStatuses = statuses.map(status => availableOrderStatusOptions[status]);
  const toggleViewModeButtons = (
    <ToggleButtonGroup
      name="toggle-chart-list"
      value={viewMode}
    >
      <ToggleButton variant="default" value={VIEW_MODE_OPTIONS.CHART} onClick={onSetViewMode} className={viewMode === VIEW_MODE_OPTIONS.CHART && 'btn-active'}>
        <Fa name="calendar" size="lg" />
      </ToggleButton>
      <ToggleButton variant="default" value={VIEW_MODE_OPTIONS.LIST} onClick={onSetViewMode} className={viewMode === VIEW_MODE_OPTIONS.LIST && 'btn-active'}>
        <Fa name="list" size="lg" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
  if (viewMode === VIEW_MODE_OPTIONS.CHART) {
    return (
      <Container fluid>
        <OrdersGanttChartHeader
          {...props}
          bureau={bureau}
          availableOrderStatusOptions={availableOrderStatusOptions}
          isBoeingUser={isBoeingUser}
          isDanfossUser={isDanfossUser}
          isRestrictedUser={isRestrictedUser}
          customFielsReferences={customFieldReferences}
          isOrderDueDateFeatureEnabled={isOrderDueDateFeatureEnabled}
          isOrderOwnerColumnEnabled={isOrderOwnerColumnEnabled}
          toggleViewModeButtons={toggleViewModeButtons}
          onStatusChange={onStatusChange}
          selectedStatuses={selectedStatuses}
          materialFamily={materialFamily}
          onChangeMaterialFamily={onSelectChange}
        />
        <Row>
          <Col xs={{ span: 12 }} lg={{ span: 10, offset: 1 }} className="mt15">
            <OrdersGanttChartContainer orders={props.recordListItems} {...props} />
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <Orders
      {...props}
      bureau={bureau}
      isBoeingUser={isBoeingUser}
      isDanfossUser={isDanfossUser}
      isOrderQuoteFeatureEnabled={isOrderQuoteFeatureEnabled}
      isRestrictedUser={isRestrictedUser}
      customFielsReferences={customFieldReferences}
      isOrderDueDateFeatureEnabled={isOrderDueDateFeatureEnabled}
      isOrderOwnerColumnEnabled={isOrderOwnerColumnEnabled}
      isDebugModeEnabled={isDebugModeEnabled}
      extraFilters={[
        <div key="ordersExtraFilters" className="form-inline" style={{ lineHeight: '40px' }}>
          <div className="form-group mr15">
            <FormLabel htmlFor="materialFamilyFilter">
              Material Family:
            </FormLabel>
            <FormControl
              className="spacer-left"
              id="materialFamilyFilter"
              as="select"
              name="materialFamily"
              value={materialFamily}
              onChange={onSelectChange}
            >
              <FormattedMessage id="all" defaultMessage="All">{text =>
                <option value="">{text}</option>}
              </FormattedMessage>
              {Object.keys(MATERIAL_FAMILY_MAP).map(family => (
                <FormattedMessageMappingOption
                  key={family}
                  mapping={MATERIAL_FAMILY_MAP}
                  value={family}
                />
              ))}
            </FormControl>
          </div>
          <div className="form-group mr15">
            <FormLabel htmlFor="statusFilter">
              Status:
            </FormLabel>
            <div className="spacer-left form-control inline-picky-wrapper">
              <SelectMultiple
                title="Status"
                data={Object.values(availableOrderStatusOptions)}
                labelKey="label"
                valueKey="status"
                selectedData={selectedStatuses}
                handleOnClose={onStatusChange}
              />
            </div>
          </div>
          <Feature featureName={FEATURES.ORDER_ORDER_OWNER}>
            <div className="form-group mr15">
              <FormLabel htmlFor="orderOwnerFilter" onClick={event => event.preventDefault()}>
                Order Owner:
              </FormLabel>
              <FormControl
                className="spacer-left"
                id="orderOwnerFilter"
                as="select"
                name="orderOwner"
                value={orderOwner}
                onClick={onFetchUsers}
                onChange={onOwnerChange}
              >
                <FormattedMessage id="all" defaultMessage="All">{text =>
                  <option value="">{text}</option>}
                </FormattedMessage>
                {users.map(user => (
                  <option key={user.uuid} value={user.name}>
                    {user.name}
                  </option>
                ))}
              </FormControl>
              {fetchingUsers && (<Loading inline className="spacer-left" />)}
            </div>
          </Feature>
          <div className="pull-right">
            {toggleViewModeButtons}
          </div>
        </div>,
      ]}
    />
  );
};

OrdersContainer.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    used_material_families: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.arrayOf(PropTypes.string),
    order_owner_name: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  recordListItems: PropTypes.arrayOf(orderResourceType).isRequired,
  refreshRecordsList: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const getOrdersWithAdditionalData = state => getOrders(state);

export default withRecordsListHandling(
  OrdersContainer,
  getOrdersWithAdditionalData,
  ['order'],
  { defaultLimit:
      window.location.hash.includes(`view_mode=${VIEW_MODE_OPTIONS.CHART}`) ?
        ORDERS_VIEW_MODE_CHART_LIMIT :
        ORDERS_VIEW_MODE_LIST_LIMIT },
);
