import React from 'react';
import Fa from 'react-fontawesome';
import { COLORS } from 'rapidfab/constants';

const ChipNote = () => (
  <div className="chip-note" style={{ backgroundColor: COLORS.BLUE }}>
    <p className="text-white">
      <Fa name="commenting-o" className="chip-note-icon" /> Review
    </p>
  </div>
);

export default ChipNote;
