import React, { useEffect, useState } from 'react';
import ChangeImpersonationModal from 'rapidfab/components/modals/ChangeImpersonationModal';
import { useSelector, useDispatch } from 'react-redux';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, KEY_USERS_FOR_IMPERSONATION } from 'rapidfab/constants';
import * as Selectors from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import usePrevious from 'rapidfab/hooks/usePrevious';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import {
  transformUsernameSearchString,
  findImpersonationUser,
} from 'rapidfab/utils/impersonation';

const ChangeImpersonationModalContainer = ({ show }) => {
  const dispatch = useDispatch();

  const isSearchingForUsers = useSelector(state => state.ui.nautilus[API_RESOURCES.USERS].list.fetching);
  const users = useSelector(Selectors.getUsers);
  const session = useSelector(Selectors.getSession);

  const selected = {
    isSearchingForUsers,
    session,
  };

  const [enteredBureau, setEnteredBureau] = useState(null);
  const previousEnteredBureau = usePrevious(enteredBureau);
  const loading = useSelector(state => state.ui.nautilus[API_RESOURCES.SESSIONS].put.fetching);

  const handleCloseChangeImpersonationModal = () => {
    dispatch(Actions.ChangeImpersonationModal.setChangeImpersonationModalState(false));
  };

  const handleEndImpersonation = async () => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.SESSIONS].put(
      0,
      { impersonating: null },
    ));
    handleCloseChangeImpersonationModal();
    window.location.reload();
  };

  const handleOnImpersonate = async uri => {
    const user = await findImpersonationUser(
      dispatch,
      transformUsernameSearchString(enteredBureau),
    );

    if (!uri && !user) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.bureau.notFound"
          defaultMessage="Bureau {enteredBureau} could not be found"
          values={{ enteredBureau }}
        />,
      );
      return;
    }

    try {
      // Set the impersonation to null
      await dispatch(Actions.Api.nautilus[API_RESOURCES.SESSIONS].put(
        0,
        { impersonating: null },
      ));
      // Impersonate entered user
      await dispatch(Actions.Api.nautilus[API_RESOURCES.SESSIONS].put(
        0, // Looks like it's ignored on backend, but it should be PUT
        { impersonating: uri || user?.uri },
      ));
      window.location.reload();
    } catch {
      // Add some error handling;
    }
    handleCloseChangeImpersonationModal();
  };

  const handleSearchUsers = () => {
    if (previousEnteredBureau !== enteredBureau) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({ username: enteredBureau }));
    }
  };

  const handleEnterPressSearch = event => {
    if (event.key === 'Enter') {
      handleOnImpersonate();
    }
  };

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({}, { limit: 100 }));
  };

  useEffect(() => {
    onInitialize();
  }, []);

  const handleChangeBureauEntryField = event => setEnteredBureau(event.target.value);

  return (
    <>
      <ChangeImpersonationModal
        {...selected}
        show={show}
        onHide={handleCloseChangeImpersonationModal}
        modalState={{
          enteredBureau,
          setEnteredBureau,
        }}
        handleOnImpersonate={handleOnImpersonate}
        handleEndImpersonation={handleEndImpersonation}
        keyUsersForImpersonation={KEY_USERS_FOR_IMPERSONATION}
        users={_filter(users, ({ name }) => !_isEmpty(name))}
        handleSearchUsers={handleSearchUsers}
        onInitialize={onInitialize}
        handleEnterPressSearch={handleEnterPressSearch}
        isLoading={loading}
        handleChangeBureauEntryField={handleChangeBureauEntryField}
      />
    </>
  );
};

ChangeImpersonationModalContainer.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default ChangeImpersonationModalContainer;
