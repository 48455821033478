import React from 'react';
import { Button, Container } from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';
import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faTasks } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import AddMaterialTestOperationModal from 'rapidfab/components/modals/AddMaterialTestOperationModal';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ManageMaterialTestUnitsModal from 'rapidfab/components/modals/ManageMaterialTestUnitsModal';

const MaterialTests = props => {
  const {
    isSaving,
    fetching,
    breadcrumbs,
    materialTestOperations,
    materialTestUnits,
    materialTestUnitsByUri,
    createMaterialTestOperationModalState: [
      showCreateMaterialTestOperationModal,
      setShowCreateMaterialTestOperationModal,
    ],
    manageTestUnitsModalState: [
      showManageTestUnitsModal,
      setShowManageTestUnitsModal,
    ],
    handleSaveMaterialTestOperation,
    handleSaveMaterialTestUnits,
    handleUpdateMaterialTestOperation,
    handleMaterialTestTableRecordClick,
  } = props;

  const NavbarLinks = (
    <div className="pull-right d-flex">
      <Button
        variant="primary"
        size="sm"
        onClick={() => setShowManageTestUnitsModal(true)}
        className="pull-right spacer-right"
      >
        <FontAwesomeIcon icon={faTasks} className="spacer-right" />
        <FormattedMessage id="materialTests.manageTestUnits" defaultMessage="Manage Test Units" />
      </Button>
      <Button
        variant="success"
        size="sm"
        onClick={() => setShowCreateMaterialTestOperationModal(previous =>
          ({ ...previous, initialFormValues: null, show: true }),
        )}
        className="pull-right"
      >
        <FontAwesomeIcon icon={faAdd} className="spacer-right" />
        <FormattedMessage id="materialTests.addTest" defaultMessage="Add Material Test" />
      </Button>
    </div>
  );

  const columns = [
    {
      type: 'custom',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      Cell: cellData => {
        const { row: { original: data } } = cellData;
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            to="#"
            onClick={event => {
              event.preventDefault();
              handleMaterialTestTableRecordClick(data);
            }}
          >
            {/* eslint-disable-next-line react/prop-types */}
            <p>{data?.name}</p>
          </Link>
        );
      },
    },
    {
      type: 'custom',
      uid: 'field.resultType',
      accessor: 'result_type',
      defaultMessage: 'Result Type',
      Cell: () => (
        <p>Numeric</p>
      ),
    },
    {
      type: 'custom',
      uid: 'field.units',
      accessor: 'unit',
      defaultMessage: 'Units',
      Cell: cellData => {
        const { row: { original: data } } = cellData;
        return (
          // eslint-disable-next-line react/prop-types
          <p>{materialTestUnitsByUri[data.unit]?.symbol}</p>
        );
      },
    },
  ];

  return ([
    showManageTestUnitsModal && (
      <ManageMaterialTestUnitsModal
        materialTestUnits={materialTestUnits}
        show
        onClose={() => setShowManageTestUnitsModal(false)}
        handleSaveMaterialTestUnits={handleSaveMaterialTestUnits}
      />
    ),
    showCreateMaterialTestOperationModal.show && (
      <AddMaterialTestOperationModal
        isSaving={isSaving}
        initialFormValues={showCreateMaterialTestOperationModal.initialFormValues}
        uuid={showCreateMaterialTestOperationModal.uuid}
        materialTestUnits={materialTestUnits}
        show
        onClose={() => setShowCreateMaterialTestOperationModal(previous =>
          ({ ...previous, initialFormValues: null, show: false }),
        )}
        handleSaveMaterialTestOperation={formValues => handleSaveMaterialTestOperation(formValues)}
        handleSegueToManageTestUnitsModal={() => {
          /* Hide add material test operation modal; show manage test units modal. */
          setShowCreateMaterialTestOperationModal(previous => ({ ...previous, show: false }));
          setShowManageTestUnitsModal(true);
        }}
        handleUpdateMaterialTestOperation={formValues => handleUpdateMaterialTestOperation(formValues)}
      />
    ),
    <Container fluid>
      <BreadcrumbNav breadcrumbs={['admin', 'materialTests']} />
      {fetching ? (
        <Loading />
      ) : (
        <TableWithSearching
          {...props}
          isFetching={fetching}
          withBreadcrumbs
          breadcrumbs={breadcrumbs}
          data={materialTestOperations}
          navbar={NavbarLinks}
          columns={columns}
          isFilteringEnabled={false}
          withDefaultPagination={false}
          isManualSoringEnabled
          // manualSortingFunc={onSort}
          isUpdatedColumnShown={false}
          initialSortedDesc
        />
      )}
    </Container>,
  ]);
};

export default MaterialTests;

MaterialTests.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      unit: PropTypes.shape({}),
      name: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
