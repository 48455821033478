// Object with supported entities with colored status code
// Used internally (nautilus doesn't have this constant) mostly for StatusColorCode component
export const STATUS_COLOR_CODE_TYPES = {
  BOEING_FIELD_STATUS: 'boeing_field_status',
  BOEING_FINANCE_REVIEW_STATUS: 'boeing_finance_review_status',
  YES_NO: 'yes_no',
  ORDER_STATUS: 'order_status',
  SERVICE_PROVIDER_JOB: 'service_provider_job',
  SHIPMENT: 'shipment',
  PREP_TASK_RECORD_STATUS: 'prep_task_record_status',
  COMMENT_ACTION_STATUS: 'comment_action_status',
  STOCK_STATUS: 'stock_status',
  PRODUCT: 'product',
};

export const COLORS = {
  PURPLE: '#9F86FF',
  BLUE: '#1CA8DD',
  YELLOW: '#E4D836',
  ORANGE: '#FFA500',
  GRAY: '#555555',
  GREEN: '#1BC98E',
  RED: '#E64759',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
};

export const TEXT_COLOR_CONTRAST = {
  '#9F86FF': '#FFF', // PURPLE
  '#1CA8DD': '#FFF', // BLUE
  '#E4D836': '#000', // YELLOW
  '#FFA500': '#000', // ORANGE
  '#555555': '#FFF', // GRAY
  '#1BC98E': '#FFF', // GREEN
  '#E64759': '#FFF', // RED
  '#FFFFFF': '#000', // WHITE
  '#000000': '#FFF', // BLACK
};

export const DEFAULT_LABEL_COLOR = COLORS.WHITE;

export const DEFAULT_MATERIAL_TYPE_COLOR = COLORS.BLACK;
