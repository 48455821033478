import _findIndex from 'lodash/findIndex';

export default function createOrReplaceArray(objectList, findBy, replacedFields) {
  /*
  Method will foreach for `objectList` with the same params which are provided by `findBy`,
  and then will replace with values from `replacedFields`.
  If there are any records is exists, then record will be appended to objectList
  */
  const replacedObjectList = [...objectList];

  const objectFieldIndex = _findIndex(
    replacedObjectList,
    findBy,
  );

  if (objectFieldIndex !== -1) {
    replacedObjectList[objectFieldIndex] = {

      ...replacedObjectList[objectFieldIndex],
      ...replacedFields,
    };
  } else {
    const objectField = {

      ...findBy,
      ...replacedFields,
    };
    replacedObjectList.push(objectField);
  }

  return replacedObjectList;
}
