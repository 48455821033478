import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import ServiceProvider from 'rapidfab/components/manage/ServiceProvider';
import { API_RESOURCES, PROTOCOL_REGEX, ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { SERVICE_PROVIDER_CONTAINER } from 'rapidfab/constants/forms';
import { FormattedMessage } from 'react-intl';
import Alert from 'rapidfab/utils/alert';
import extractUuid from 'rapidfab/utils/extractUuid';

const redirect = () => {
  window.location.hash = getRouteURI(ROUTES.SERVICE_PROVIDERS);
};

const redirectToNewServiceProvider = uri => {
  window.location.hash = getRouteURI(ROUTES.SERVICE_PROVIDER_EDIT, { uuid: extractUuid(uri) });
};

const ServiceProviderContainer = props => {
  const uuid = useSelector(state => Selectors.getRouteUUID(state));
  const initialValues = useSelector(state => Selectors.getInitialValuesBureau(state, props));
  const submitting = useSelector(state => Selectors.getResourceFetching(state, 'nautilus.service-provider'));
  const apiErrors = useSelector(state => Selectors.getResourceErrors(state, 'nautilus.service-provider'));

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => SERVICE_PROVIDER_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  const dispatch = useDispatch();

  const onInitialize = currentUUID => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER].get(currentUUID));
    }
  };

  const onFormSubmit = payload => {
    // eslint-disable-next-line no-param-reassign
    payload.website = PROTOCOL_REGEX.test(payload.website) ? payload.website : `http://${payload.website}`;

    if (payload.phone_number === null) {
      // eslint-disable-next-line no-param-reassign
      payload.phone_number = '';
    }

    if (payload.uuid) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER].put(payload.uuid, payload))
        .then(() => Alert.success(
          <FormattedMessage
            id="toaster.serviceProvider.updated"
            defaultMessage="Service Provider {uuid} successfully updated."
            values={{ uuid: payload.uuid }}
          />,
        ));
    } else {
      dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER].post(payload))
        .then(response => {
          Alert.success(
            <FormattedMessage
              id="toaster.serviceProvider.created"
              defaultMessage="Service Provider successfully created."
            />,
          );

          if (response) redirectToNewServiceProvider(response.headers.location);
        });
    }
  };

  const onDelete = currentUUID => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER].delete(currentUUID))
        .then(() => {
          Alert.success(
            <FormattedMessage
              id="toaster.serviceProvider.deleted"
              defaultMessage="Service Provider {uuid} successfully deleted."
              values={{ uuid: currentUUID }}
            />,
          );
        })
        .then(redirect);
    }
  };

  useEffect(() => onInitialize(uuid), []);

  return (
    <ServiceProvider
      {...props}
      uuid={uuid}
      submitting={submitting}
      apiErrors={apiErrors}
      onDelete={onDelete}
      onFormSubmit={onFormSubmit}
      initialFormValues={initialFormValues}
    />
  );
};

export default ServiceProviderContainer;
