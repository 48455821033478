import {
  Button,
  Card,
  Col,
  FormControl,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  NavLink, OverlayTrigger,
  Row, Tooltip,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArchive,
  faCheck,
  faExternalLink,
  faIndustry,
  faPencil,
  faPlus,
  faStore, faTrash,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import Loading from 'rapidfab/components/Loading';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import PropTypes from 'prop-types';
import SubLocationsAddModal from 'rapidfab/components/records/SubLocationsAddModal';
import { SUB_LOCATIONS_FIELDS_MODAL } from 'rapidfab/constants/forms';
import _get from 'lodash/get';
import _set from 'lodash/set';
import Alert from 'react-s-alert';
import { Link } from 'react-router-dom';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import _sum from 'lodash/sum';

const LOCATION_TABS = {
  ALL: 'all',
};

/* Here we can add all the resources we would like to fetch
   to show their count in the Resource Table view. As many resources
   you will add here, as many API calls will be sent to fetch the related
   resources counts stored in the DB to show in the Table.

   So all you need to do to get more data -> just put the new resource here.
   The rest will be covered automatically. */

const resourcesToFetch = [
  {
    resource: API_RESOURCES.MATERIAL_LOT,
  },
  {
    resource: API_RESOURCES.MATERIAL_BATCH,
  },
];

const ResourceListItem = ({
  resourceType,
  route,
  resourceCount,
  isLoading,
  locationState,
  handleSetLocationFilters,
}) => {
  const { locationUri, subLocationUUID, subLocationUri } = locationState;
  return (
    <ListGroupItem>
      <Row>
        <Col xs={4}>
          <span>{resourceType}</span>
        </Col>
        <Col xs={4}>
          <span>{isLoading ? <Loading inline /> : resourceCount}</span>
        </Col>
        <Col xs={4}>
          {
            isLoading ? (
              <Loading inline />
            ) : (
              <Link
                onClick={() => handleSetLocationFilters(locationUri, subLocationUri)}
                rel="noopener noreferrer"
                to={{
                  pathname: getRouteURI(route,
                    { uuid: subLocationUUID },
                    {}, true),
                }}
              >
                Go <FontAwesomeIcon icon={faExternalLink} />
              </Link>
            )
          }
        </Col>
      </Row>
    </ListGroupItem>
  );
};

ResourceListItem.defaultProps = {
  resourceCount: null,
  isLoading: false,
};

ResourceListItem.propTypes = {
  resourceType: PropTypes.string.isRequired,
  resourceCount: PropTypes.number,
  isLoading: PropTypes.bool,
  route: PropTypes.string.isRequired,
  locationState: PropTypes.shape({
    locationUri: PropTypes.string.isRequired,
    subLocationUUID: PropTypes.string.isRequired,
    subLocationUri: PropTypes.string.isRequired,
  }).isRequired,
  handleSetLocationFilters: PropTypes.func.isRequired,
};

const SubLocations = memo(({
  locationUri,
  subLocations,
  subLocationsFetching,
  subLocationsByUri,
  onSaveSubLocationName,
  onArchiveSubLocation,
  handleFetchRelatedResource,
  handleAddSubLocation,
  materialResourcesFetching,
  subLocationsSubmitting,
  subLocationDeleting,
  handleSetLocationFilters,
  isMaterialManagementFeatureEnabled,
}) => {
  const [tab, setTab] = useState(LOCATION_TABS.ALL);
  const [selectedSubLocation, setSelectedSubLocation] = useState(null);
  const [resourcesCountState, setResourcesCountState] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [addSubLocationModal, setAddSubLocationModal] = useState(false);
  const subLocationSameName = (selectedSubLocation && subLocationsByUri[tab])
    && (selectedSubLocation.name === subLocationsByUri[tab].name);
  const isAllTab = tab === LOCATION_TABS.ALL;
  // Check which URI we should pass to the API call (All tab or Sub-Location tab).
  const relatedLocationUri = isAllTab ? locationUri : selectedSubLocation?.uri;

  /* In order to render either Archive or Delete button for the sub-location
     action, we need to check if there are some resources related to this sub-location.
     If we have more than 0 -> we will need to show the Archive button. If 0 ->
     we will show the Delete button. The method above works to get this number. */
  const handleCheckRelatedResources = subLocationUri =>
    (resourcesCountState[subLocationUri] ? _sum(Object.values(resourcesCountState[subLocationUri])) : 0);

  const hasRelatedResources = handleCheckRelatedResources(selectedSubLocation?.uri);

  /* This method is needed to prevent calling same API calls to the Sub-Location tabs
     which we already called. No need to call the same 2 or more calls when just switching
     between the tabs as it will cause more delays taking into account the number of resources
     will be increasing. Instead, we can store the result of the API call and if get back to
     the previous tab or the tab we already opened, the Resources Count in the Resources Table
     will be already stored in the state. */
  const fetchAndUpdateResourceCount = useCallback(
    async (resourceName, relatedLocationUri) => {
      if (!relatedLocationUri) return;

      /* Based on the conditions:

         - If we are on the "All" tab or not.
         - Which resource API call we should send.
         - Which URI we are passing (location, or sub_location.

         We will send the appropriate API call and get the number of resources
         to show in the Resources Table.
         */
      const resourceCount = await handleFetchRelatedResource(
        isAllTab,
        resourceName,
        relatedLocationUri,
      );

      /* Then we will store this number by the following schema:

         {
           [URI]: {
              [resourceName]: [resourceCount],
           }
         }

        This way, we will be able to get this number stored on every
        Tab change, and we will not need to call the same API call again.

         */

      setResourcesCountState(previous => ({
        ...previous,
        [relatedLocationUri]: {
          ...previous[relatedLocationUri],
          [resourceName]: resourceCount,
        },
      }));
    },
    [isAllTab, handleFetchRelatedResource],
  );

  /* This method will decide whether we should actually send an API call
     to get the Resources Count. If we already stored this number, we will skip. */
  const shouldFetchResource = useCallback((resourceName, relatedLocationUri) => {
    // Check if we already have the resources by the related location (or sub.) URI.
    const resourcesCountKey = resourcesCountState?.[relatedLocationUri];
    // Check if we have the number of resources for the current resource name.
    const count = resourcesCountKey?.[resourceName];

    /* If we don't have the resources by URI, or we do not have the count
       for the resource name, we will send an API call. */
    return !resourcesCountKey || (count === undefined);
  }, [resourcesCountState]);

  useEffect(() => {
    if (isMaterialManagementFeatureEnabled) {
      // Go through all the resources and send the API call if needed.
      resourcesToFetch.forEach(({ resource: resourceName }) => {
        if (shouldFetchResource(resourceName, relatedLocationUri)) {
          fetchAndUpdateResourceCount(resourceName, relatedLocationUri);
        }
      });
    }
  }, [
    isMaterialManagementFeatureEnabled,
    isAllTab,
    tab,
    locationUri,
    selectedSubLocation?.uri]);

  const onSelectSubLocationTab = selectedTab => {
    setTab(selectedTab);
    setSelectedSubLocation(subLocationsByUri[selectedTab]);
    setIsEditMode(false);
  };

  const renderResourceCount = resource => {
    if (materialResourcesFetching) {
      return <Loading inline />;
    }

    if (resourcesCountState?.[relatedLocationUri]) {
      return resourcesCountState[relatedLocationUri][resource];
    }

    return null;
  };

  const renderResourcesList = () => (
    <Col xs={6}>
      <Card bg="dark" border="info" className="mb15">
        <ListGroup fill>
          <ListGroupItem key="header">
            <b>
              <FormattedMessage
                id="materials"
                defaultMessage="Materials"
              />
            </b>
          </ListGroupItem>

          <ResourceListItem
            resourceType="Lots"
            route={ROUTES.MATERIAL_LOTS}
            locationState={{
              locationUri,
              subLocationUri: selectedSubLocation?.uri,
              subLocationUUID: selectedSubLocation?.uuid,
            }}
            resourceCount={renderResourceCount(API_RESOURCES.MATERIAL_LOT)}
            isLoading={materialResourcesFetching}
            handleSetLocationFilters={handleSetLocationFilters}
          />

          <ResourceListItem
            resourceType="Batches"
            route={ROUTES.MATERIAL_BATCHES}
            locationState={{
              locationUri,
              subLocationUri: selectedSubLocation?.uri,
              subLocationUUID: selectedSubLocation?.uuid,
            }}
            resourceCount={renderResourceCount(API_RESOURCES.MATERIAL_BATCH)}
            isLoading={materialResourcesFetching}
            handleSetLocationFilters={handleSetLocationFilters}
          />

        </ListGroup>
      </Card>
    </Col>
  );

  const handleChangeSubLocationName = () => {
    if (!isEditMode) {
      return setIsEditMode(previous => !previous);
    }

    if (subLocationSameName) {
      return setIsEditMode(previous => !previous);
    }

    return onSaveSubLocationName(selectedSubLocation.uuid, selectedSubLocation.name)
      .then(response => setIsEditMode(!response));
  };

  const handleArchiveSubLocation = async () => {
    try {
      await onArchiveSubLocation(selectedSubLocation.uuid, hasRelatedResources);
      const previousTab = subLocations.findIndex(
        ({ uri }) => uri === selectedSubLocation.uri,
      ) - 1;

      onSelectSubLocationTab(subLocations[previousTab]?.uri || LOCATION_TABS.ALL);
    } catch (error) {
      Alert.error(error.message);
    }
  };

  const renderEditIconState = () => {
    if (isEditMode) {
      if (subLocationsSubmitting) {
        return <Loading inline />;
      }

      return subLocationSameName ?
        <FontAwesomeIcon icon={faXmark} /> :
        <FontAwesomeIcon icon={faCheck} />;
    }

    return <FontAwesomeIcon icon={faPencil} />;
  };

  const onCloseAddSubLocationModal = () => setAddSubLocationModal(false);

  const onAddSubLocation = async values => {
    setSubmitting(true);
    const payload = {
      ...values,
      location: locationUri,
    };

    SUB_LOCATIONS_FIELDS_MODAL.STRING_FIELDS.forEach(
      fieldName => {
        const field = _get(payload, fieldName);
        if (field === null) {
          _set(payload, fieldName, '');
        }
      },
    );

    if (!payload.location) {
      setSubmitting(false);
      return;
    }

    handleAddSubLocation(payload).then(() => {
      setSubmitting(false);
      onCloseAddSubLocationModal();
    });
  };

  const handleCloseArchiveModal = () =>
    setShowConfirmationModal(false);
  const handleConfirmArchive = () => handleArchiveSubLocation()
    .then(handleCloseArchiveModal);

  const renderArchiveButtonIconsState = () => {
    if (subLocationDeleting) {
      return <Loading inline />;
    }

    if (hasRelatedResources) {
      return <FontAwesomeIcon icon={faArchive} />;
    }
    return <FontAwesomeIcon icon={faTrash} />;
  };

  return (
    <>
      <Col xs={12} sm={7}>
        <Card bg="dark">
          <Card.Header className="pd-exp inverse d-flex align-items-center justify-content-between">
            <FormattedMessage
              id="field.subLocations"
              defaultMessage="Sub-Locations"
            />
            <Button
              variant="primary"
              size="sm"
              onClick={() => setAddSubLocationModal(true)}
            >
              <FontAwesomeIcon icon={faPlus} className="spacer-right" />
              <FormattedMessage id="button.add" defaultMessage="Add" />
            </Button>
          </Card.Header>
          <Card.Body className="pd-exp">
            <Nav
              variant="tabs"
              activeKey={tab}
              onSelect={onSelectSubLocationTab}
            >
              <NavItem>
                <NavLink eventKey={LOCATION_TABS.ALL}>
                  <FontAwesomeIcon icon={faIndustry} className="spacer-right" />
                  <FormattedMessage
                    id="all"
                    defaultMessage="All"
                  />
                </NavLink>
              </NavItem>
              {
                subLocationsFetching ?
                  <Loading inline style={{ padding: '7px 15px' }} /> :
                  subLocations.map(({ name, uri }) => (
                    <NavItem key={uri}>
                      <NavLink eventKey={uri} className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faStore} className="spacer-right" />
                        {
                          name.length >= 20 ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={(
                                <Tooltip>
                                  <span>{name}</span>
                                </Tooltip>
                              )}
                            >
                              <span className="subLocationsTruncatedName">{name}</span>
                            </OverlayTrigger>
                          )
                            : (<span className="subLocationsTruncatedName">{name}</span>)
                        }
                      </NavLink>
                    </NavItem>
                  ))
              }
            </Nav>
            {
              !isMaterialManagementFeatureEnabled && tab === LOCATION_TABS.ALL ? null : (
                <Card bg="dark">
                  <div className="card-body-wrapper">
                    <Card.Body>
                      {(tab !== LOCATION_TABS.ALL && selectedSubLocation) && (
                        <Row className="mb15 w-full">
                          <Col xs={6}>
                            <div className="d-flex align-items-center">
                              {
                                isEditMode ?
                                  (
                                    <FormControl
                                      name="sub_location"
                                      type="text"
                                      value={selectedSubLocation.name}
                                      onChange={({ target }) => setSelectedSubLocation(previous =>
                                        ({ ...previous, name: target.value }),
                                      )}
                                    />
                                  ) : (<span className="break-word">{selectedSubLocation.name}</span>)
                              }

                              <Button
                                size="sm"
                                variant="primary"
                                name="sub_location_name"
                                onClick={handleChangeSubLocationName}
                                className="ml15"
                              >
                                {renderEditIconState()}
                              </Button>
                              {
                                materialResourcesFetching ? (
                                  <Loading inline className="spacer-left" />
                                ) : (
                                  <Button
                                    size="sm"
                                    disabled={selectedSubLocation?.is_default}
                                    variant={hasRelatedResources ? 'warning' : 'danger'}
                                    name="archive_sub_location"
                                    onClick={() => setShowConfirmationModal(true)}
                                    className="ml5"
                                  >
                                    {renderArchiveButtonIconsState()}
                                  </Button>
                                )
                              }

                            </div>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        {
                          isMaterialManagementFeatureEnabled && renderResourcesList()
                        }
                      </Row>
                    </Card.Body>
                  </div>
                </Card>
              )
            }
          </Card.Body>
        </Card>
      </Col>
      {
        addSubLocationModal && (
          <SubLocationsAddModal
            onClose={onCloseAddSubLocationModal}
            onSubmit={onAddSubLocation}
            isAdding={subLocationsSubmitting || submitting}
          />
        )
      }
      {
        showConfirmationModal && (
          <ConfirmationModal
            handleCancel={handleCloseArchiveModal}
            handleConfirm={handleConfirmArchive}
            confirmButtonContent={hasRelatedResources ?
              <FormattedMessage id="button.archive" defaultMessage="Archive" /> :
              <FormattedMessage id="button.delete" defaultMessage="Delete" />}
            message={hasRelatedResources ?
              <FormattedMessage id="message.archiveSubLocation" defaultMessage="Are you sure you want to archive this sub-location?" /> :
              <FormattedMessage id="message.deleteSubLocation" defaultMessage="Are you sure you want to delete this sub-location?" />}
          />
        )
      }
    </>
  );
});

SubLocations.propTypes = {
  subLocations: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
  })).isRequired,
  subLocationsFetching: PropTypes.bool.isRequired,
  subLocationsByUri: PropTypes.shape({}).isRequired,
  onSaveSubLocationName: PropTypes.func.isRequired,
  onArchiveSubLocation: PropTypes.func.isRequired,
  handleFetchRelatedResource: PropTypes.func.isRequired,
  locationUri: PropTypes.string.isRequired,
  handleAddSubLocation: PropTypes.func.isRequired,
  materialResourcesFetching: PropTypes.bool.isRequired,
  subLocationsSubmitting: PropTypes.bool.isRequired,
  subLocationDeleting: PropTypes.bool.isRequired,
  handleSetLocationFilters: PropTypes.func.isRequired,
  isMaterialManagementFeatureEnabled: PropTypes.bool.isRequired,
};

export default SubLocations;
