import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  OverlayTrigger,
  Row,
  Tooltip as BSTooltip,
} from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import extractUuid from 'rapidfab/utils/extractUuid';
import { Link } from 'react-router-dom';
import { MODELER_STATUSES, ROUTES } from 'rapidfab/constants';

import toPairs from 'lodash/toPairs';
import 'rapidfab/styles/componentStyles/printersCards.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCubes,
  faObjectGroup,
  faExclamationCircle,
  faPrint,
  faArrowUpShortWide,
  faList,
  faBarsStaggered,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import ModelerStatusDot from 'rapidfab/components/modelerStatusDot';
import HalfCircleProgressBar from 'rapidfab/components/ProgressBars/HalfCircleProgressBar';
import 'rapidfab/styles/componentStyles/progressBars.scss';
import _find from 'lodash/find';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import LinearProgressBar from 'rapidfab/components/ProgressBars/LinearProgressBar';
import dayjs from 'dayjs';
import truncateText from 'rapidfab/utils/truncateText';
import Tooltip from 'rapidfab/components/Tooltip';
import _round from 'lodash/round';
import { MODELER_STATUS_MAP } from 'rapidfab/mappings';
import { FormattedMessage } from 'react-intl';

const PrintersCards = memo(({
  locations,
  printerTypes,
  modelers,
  batches,
  isMaterialManagementFeatureEnabled,
  batchesFetching,
  groupedPrinters,
  builds,
  buildsFetching,
  findScheduledRuns,
  getPercentage,
  runsFetching,
  scheduledStartDate,
  renderHeaderView,
  isRunActive,
}) => {
  const showTooltipCondition = (type, text) => {
    switch (type) {
      case 'printerType':
        return text.length >= 25;
      case 'printerName':
        return text.length >= 21;
      case 'runName':
        return text.length >= 24;
      case 'runNameLong':
        return text.length >= 46;
      case 'batchName':
        return text.length >= 20;
      default:
        return false;
    }
  };

  const renderMaterialBatchInfo = (batch, hasMaterialResource) => {
    if (batchesFetching) {
      return <Loading />;
    }

    if (!hasMaterialResource) {
      return null;
    }

    const singleMaterialName = batch.materials[0]?.name;
    const materialName = singleMaterialName?.toLowerCase() || 'N/A';

    return (
      <div className="PrintersCardBottomViewCard">
        <div>
          <FontAwesomeIcon className="spacer-right" icon={faCubes} />
          <Link
            to={getRouteURI(
              ROUTES.MATERIAL_BATCH,
              { uuid: batch.uuid },
              {}, true)}
          >
            {getShortUUID(batch.uuid)}
          </Link>
        </div>
        <div className="mt-2 d-flex align-items-center">
          <FontAwesomeIcon className="spacer-right" icon={faObjectGroup} />
          {
            batch.materials.length > 1 ? (
              <OverlayTrigger
                placement="right"
                overlay={(
                  <BSTooltip>
                    <div className="text-left">
                      Materials:
                      <ul className="mt-1">
                        {batch.materials.map(material => (
                          <li className="capitalize" key={material.uri}>{material.name.toLowerCase()}</li>
                        ))}
                      </ul>
                    </div>
                  </BSTooltip>
                )}
              >
                <div>
                  <span>Blend</span>
                  <FontAwesomeIcon className="spacer-left" icon={faExclamationCircle} />
                </div>
              </OverlayTrigger>
            ) : (
              showTooltipCondition('batchName', materialName) ? (
                <Tooltip
                  id="batchNameTooltip"
                  placement="top"
                  trigger={(<span className="truncate capitalize PrintersCardMaterialName">{materialName}</span>)}
                >
                  <span className="wrap-text">{materialName}</span>
                </Tooltip>
              ) : (
                <span className="truncate capitalize PrintersCardMaterialName">{materialName}</span>
              )
            )
          }
        </div>
        <div className="mt-2 d-flex align-items-center">
          <FontAwesomeIcon className="spacer-right" icon={faArrowUpShortWide} />
          <span className="truncate PrintersCardBatchQuantity">{batch.quantity}</span>
          <span>&nbsp;{batch.units}</span>
        </div>
      </div>
    );
  };

  const renderRunData = (printer, queue, hasMaterialResource) => {
    if (runsFetching) {
      return <Loading />;
    }

    const { earliestRun, latestRunDate } = findScheduledRuns(printer);
    const currentRunPercentage = getPercentage(earliestRun);
    const currentRunScheduledTime = scheduledStartDate(earliestRun);
    const formattedCurrentRunScheduledTime = currentRunScheduledTime ?
      `Scheduled for ${dayjs(currentRunScheduledTime).format('h A, MM/DD')}` :
      'Not Started';
    const formattedDate = latestRunDate ?
      dayjs(latestRunDate).format('h A, MM/DD') :
      'N/A';
    const runName = earliestRun ? earliestRun.name.toLowerCase() : null;
    const runsLength = queue?.length || 0;
    const hasStarted = earliestRun && isRunActive(earliestRun);

    return (
      <div className="PrintersCardBottomViewCard">
        <div className="d-flex align-items-center mb-2">
          <FontAwesomeIcon className="spacer-right" icon={faList} />
          {
            earliestRun?.uri ?
              (showTooltipCondition(hasMaterialResource ? 'runName' : 'runNameLong', runName) ? (
                <Tooltip
                  id="printerNameTooltip"
                  placement="top"
                  trigger={(
                    <Link
                      className={`truncate capitalize
                ${hasMaterialResource ?
                      'PrintersCardCurrentRunNameShort' :
                      'PrintersCardCurrentRunNameLong'}`}
                      to={getRouteURI(
                        ROUTES.RUN_EDIT,
                        { uuid: extractUuid(earliestRun.uri) },
                        {}, true)}
                    >
                      <span>{runName}
                      </span>
                    </Link>
                  )}
                >
                  <span className="wrap-text">{runName}</span>
                </Tooltip>
              ) : (
                <Link
                  className={`truncate capitalize
                ${hasMaterialResource ?
                  'PrintersCardCurrentRunNameShort' :
                  'PrintersCardCurrentRunNameLong'}`}
                  to={getRouteURI(
                    ROUTES.RUN_EDIT,
                    { uuid: extractUuid(earliestRun.uri) },
                    {}, true)}
                >
                  <span>{runName}</span>
                </Link>
              )) : (
                <FormattedMessage id="noRunsScheduled" defaultMessage="No Runs Scheduled" />
              )
          }
        </div>
        <div className="mb-2">
          <LinearProgressBar
            active={hasStarted}
            percentage={currentRunPercentage}
            notStartedText={formattedCurrentRunScheduledTime}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon className="spacer-right" icon={faBarsStaggered} />
            {
              runsLength ? (
                <div className="d-flex align-items-center">
                  <span>{truncateText(runsLength.toString(), 3)}</span>
                  <span>&nbsp;{runsLength === 1 ? 'Run' : 'Runs'}</span>
                </div>
              ) : <span>Queue Empty</span>
            }
          </div>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon className="spacer-right" icon={faClock} />
            <span className="truncate">{formattedDate}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderModelerStatus = modeler => {
    if (modeler && modeler.status) {
      const content = !modeler.error && !modeler.latest_upload ?
        MODELER_STATUS_MAP[MODELER_STATUSES.NOT_CONNECTED].defaultMessage :
        MODELER_STATUS_MAP[modeler.status].defaultMessage;

      return (
        <div className="PrintersCardPrinterStatus">
          <div className="d-flex align-items-center">
            <ModelerStatusDot modeler={modeler} /> <span className="spacer-left">{content}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderCompletionBar = percentage => {
    if (buildsFetching) {
      return <Loading />;
    }

    return percentage ? (
      <HalfCircleProgressBar percentage={percentage} />
    ) : null;
  };

  return (
    <>
      {renderHeaderView()}
      <div className="PrintersCardListCards">
        {
          Object.keys(groupedPrinters).length ?
            toPairs(groupedPrinters).map(([locationUri, printers]) => {
              const location = locations[locationUri];

              return (
                <>
                  <div className="PrintersCardListLocation">
                    <span className="spacer-right">Location</span>
                    <Link
                      to={getRouteURI(
                        ROUTES.LOCATION_EDIT,
                        { uuid: extractUuid(location?.uri) },
                        {}, true)}
                    >
                      <span className="capitalize">{location?.name}</span>
                    </Link>
                  </div>

                  <div className="card-list PrintersCardList mb30">
                    {printers.map(printer => {
                      const batch = _find(batches, { at_machine: printer.uri }) || null;
                      const hasMaterialResource = isMaterialManagementFeatureEnabled && batch;
                      const printerName = printer.name?.toLowerCase();
                      const printerType = printerTypes[printer.printer_type]?.name?.toLowerCase();

                      const modeler = modelers[printer.modeler];
                      const build = builds[modeler?.current_build];
                      const statusPercent = build
                      && build.current_layer !== null
                      && build.layers > 0 ? _round((build.current_layer / build.layers) * 100) : null;

                      return (
                        <div
                          className="card PrintersCard"
                          key={printer.uri}
                        >

                          <Row className="PrintersCardTopView">
                            <Col xs={5}>
                              <div className="PrintersCardPrinters">
                                <div className="spacer-right">
                                  <FontAwesomeIcon icon={faPrint} />
                                </div>
                                <div className="PrintersCardPrintersData">
                                  <div className="truncate PrintersCardPrinterName">
                                    {
                                      showTooltipCondition('printerName', printerName) ? (
                                        <Tooltip
                                          id="printerNameTooltip"
                                          placement="top"
                                          trigger={(
                                            <Link
                                              className="capitalize"
                                              to={getRouteURI(
                                                ROUTES.PRINTER_EDIT,
                                                { uuid: extractUuid(printer.uri) },
                                                {}, true)}
                                            >
                                              <span>{printerName}</span>
                                            </Link>
                                          )}
                                        >
                                          <span className="wrap-text">{printerName}</span>
                                        </Tooltip>
                                      ) : (
                                        <Link
                                          className="capitalize"
                                          to={getRouteURI(
                                            ROUTES.PRINTER_EDIT,
                                            { uuid: extractUuid(printer.uri) },
                                            {}, true)}
                                        >
                                          <span>{printerName}</span>
                                        </Link>
                                      )
                                    }
                                  </div>
                                  <div className="truncate PrintersCardPrinterType">
                                    {
                                      showTooltipCondition('printerType', printerType) ? (
                                        <Tooltip
                                          id="printerNameTooltip"
                                          placement="top"
                                          trigger={(
                                            <Link
                                              to={getRouteURI(
                                                ROUTES.PRINTER_TYPE_EDIT,
                                                { uuid: extractUuid(printer.printer_type) },
                                                {}, true)}
                                            >
                                              <span className="capitalize">{printerType}</span>
                                            </Link>
                                          )}
                                        >
                                          <span className="wrap-text">{printerType}</span>
                                        </Tooltip>
                                      ) : (
                                        <Link
                                          to={getRouteURI(
                                            ROUTES.PRINTER_TYPE_EDIT,
                                            { uuid: extractUuid(printer.printer_type) },
                                            {}, true)}
                                        >
                                          <span className="capitalize">{printerType}</span>
                                        </Link>
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xs={statusPercent || buildsFetching ? 7 : 3}>
                              <div className="PrintersCardModelerStatuses">
                                {renderCompletionBar(statusPercent)}
                                {renderModelerStatus(modeler)}
                              </div>
                            </Col>
                          </Row>
                          <Row className="PrintersCardBottomView">
                            <Col xs={5}>
                              {renderMaterialBatchInfo(batch, hasMaterialResource)}
                            </Col>
                            <Col xs={hasMaterialResource ? 7 : 13}>
                              {renderRunData(printer, printer?.queue, hasMaterialResource)}
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })
            : (
              <h1 className="text-center mt-2">Nothing found</h1>
            )
        }
      </div>
    </>

  );
});

PrintersCards.defaultProps = {
  builds: [],
};

PrintersCards.propTypes = {
  locations: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
  })).isRequired,
  modelers: PropTypes.objectOf(PropTypes.shape({
    status: PropTypes.oneOf(Object.values(MODELER_STATUSES)),
  })).isRequired,
  printerTypes: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  builds: PropTypes.objectOf(PropTypes.shape({
    current_layer: PropTypes.number,
    layers: PropTypes.number,
  })),
  batches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMaterialManagementFeatureEnabled: PropTypes.bool.isRequired,
  batchesFetching: PropTypes.bool.isRequired,
  groupedPrinters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
  buildsFetching: PropTypes.bool.isRequired,
  findScheduledRuns: PropTypes.func.isRequired,
  getPercentage: PropTypes.func.isRequired,
  runsFetching: PropTypes.bool.isRequired,
  scheduledStartDate: PropTypes.oneOfType([
    PropTypes.string, null, undefined,
  ]).isRequired,
  renderHeaderView: PropTypes.func.isRequired,
  isRunActive: PropTypes.func.isRequired,
};

export default PrintersCards;
