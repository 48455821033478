import React from 'react';
import PropTypes from 'prop-types';
import extractUuid from 'rapidfab/utils/extractUuid';
import { FEATURES, LINE_ITEM_COMPOSITION_TYPES, ROUTES } from 'rapidfab/constants';
import { FormattedDate, FormattedLength, FormattedMessage } from 'rapidfab/i18n';
import { Draggable } from 'react-drag-and-drop';
import Feature from 'rapidfab/components/Feature';
import PriorityLabel from 'rapidfab/components/records/run/PriorityLabel';
import ModalThreeScene from 'rapidfab/components/ModalThreeScene';
import { lineItemResourceType, materialTypeResourceType, modelResourceType, pieceResourceType } from 'rapidfab/types';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import Tooltip from 'rapidfab/components/Tooltip';
import { Image } from 'react-bootstrap';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItem';

export const TYPES = {
  LINE_ITEM: 'buildplatelineitem',
  PIECE: 'buildplatepiece',
};

const ModelSizes = ({ model }) => (
  <div>
    <FormattedLength
      length={model.size.x}
      roundPrecision={0}
      lengthUnits={model.user_unit}
    />{' × '}
    <FormattedLength
      length={model.size.y}
      roundPrecision={0}
      lengthUnits={model.user_unit}
    />{' × '}
    <FormattedLength
      length={model.size.z}
      roundPrecision={0}
      lengthUnits={model.user_unit}
    />
  </div>
);

ModelSizes.propTypes = {
  model: modelResourceType.isRequired,
};

const AndMoreTooltip = ({ id, itemsCount, children }) => (
  <Tooltip
    id={id}
    placement="right"
    trigger={(
      <div className="badge">+ {itemsCount} more</div>
    )}
  >
    {children}
  </Tooltip>
);

AndMoreTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  itemsCount: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

const MaterialLink = ({ material }) => (
  <a
    href={getRouteURI(ROUTES.MATERIAL_EDIT, { uuid: material.uuid })}
  >
    {material.name}
  </a>
);

MaterialLink.propTypes = {
  material: materialTypeResourceType.isRequired,
};

const RunItemRow = ({ rowItem, lineItem, selected, handleClick, pieces, type, order }) => {
  const {
    composition_type: compositionType,
    // Available for Co-Print Line items only
    baseMaterialsForCoPrint,
    modelsForCoPrint,
  } = lineItem;
  const showPiecesCount = type === TYPES.LINE_ITEM;
  const isCoPrintLineItem = compositionType === LINE_ITEM_COMPOSITION_TYPES.CO_PRINT;
  const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
  const { model, materials } = lineItem[workflowTypeKey];
  return (
    <Draggable type={type} data={rowItem.uri}>
      <tr
        onClick={event => handleClick(event, rowItem, order)}
        className={selected ? 'selected-row ' : null}
      >
        <td className="hidden">{rowItem.uuid}</td>
        <td className="render-column">
          {
            model
              ? (
                <ModalThreeScene
                  snapshot={model.snapshot_content}
                  model={model.content}
                  unit={model.user_unit}
                  fileUnit={model.file_unit}
                  size={model.size}
                  volume={model.volume_mm}
                  showMfgOrientationPanel={false}
                />
              )
              : (<FormattedMessage id="notAvailable" defaultMessage="N/A" />)
          }
          {
            (isCoPrintLineItem && modelsForCoPrint.length > 1)
            && (
              <AndMoreTooltip
                id={`multiModelSnapshotTooltip-${rowItem.uuid}`}
                itemsCount={modelsForCoPrint.length - 1}
              >
                {modelsForCoPrint.map(modelForCoPrint => (
                  <Image src={modelForCoPrint.snapshot_content} />
                ))}
              </AndMoreTooltip>
            )
          }
          {showPiecesCount && (<span className="printItemCount">x{pieces.length}</span>)}
        </td>
        <td>
          <a href={`#/records/order/${extractUuid(lineItem.order)}`}>
            {`${lineItem.order_name} (${lineItem.name})`}
            {showPiecesCount && (` ${pieces.length}`)}
          </a>
        </td>
        <td>
          {!model && (<FormattedMessage id="notAvailable" defaultMessage="N/A" />)}
          {
            // model is set for Co-Print as well, but we use all co-print models for rendering instead
            model && (
              isCoPrintLineItem
                ? (
                  modelsForCoPrint.map(modelForCoPrint => (
                    <ModelSizes model={modelForCoPrint} />
                  ))
                )
                : (<ModelSizes model={model} />)
            )
          }
        </td>
        <td>
          {
            materials.base
              ? (
                <>
                  <MaterialLink material={materials.base} />
                  {(isCoPrintLineItem && baseMaterialsForCoPrint.length > 1) && (
                    <div>
                      <AndMoreTooltip
                        id={`multiMaterialsTooltip-${rowItem.uuid}`}
                        itemsCount={baseMaterialsForCoPrint.length - 1}
                      >
                        {baseMaterialsForCoPrint.map(baseMaterialForCoPrint => (
                          <div>
                            <MaterialLink material={baseMaterialForCoPrint} />
                          </div>
                        ))}
                      </AndMoreTooltip>
                    </div>
                  )}
                </>
              )
              : (<FormattedMessage id="notAvailable" defaultMessage="N/A" />)
          }
        </td>
        <td>
          {lineItem[workflowTypeKey].layer_thickness}
        </td>
        <td>
          {lineItem.order_due_date ? (
            <FormattedDate value={lineItem.order_due_date} />
          ) : (
            <FormattedMessage id="notAvailable" defaultMessage="N/A" />
          )}
        </td>
        <Feature featureName={FEATURES.PRIORITY}>
          <td>
            <PriorityLabel value={rowItem.priority} />
          </td>
        </Feature>
      </tr>
    </Draggable>
  );
};

RunItemRow.defaultProps = {
  // Pieces are used only for type = Line Item
  pieces: [],
};

RunItemRow.propTypes = {
  rowItem: PropTypes.oneOfType([lineItemResourceType, pieceResourceType]).isRequired,
  lineItem: lineItemResourceType.isRequired,
  handleClick: PropTypes.func.isRequired,
  pieces: PropTypes.arrayOf(PropTypes.shape({})),
  selected: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
  order: PropTypes.shape({}).isRequired,
};

export default RunItemRow;
