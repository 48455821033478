import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Container,
  Row,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import Fa from 'react-fontawesome';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import { FormattedMessage } from 'rapidfab/i18n';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import {
  DOCUMENT_RELATED_TABLE_NAMES,
  RUN_OPERATIONS,
  COLORS,
} from 'rapidfab/constants';
import BuildPlateContainer from 'rapidfab/containers/records/run/BuildPlateContainer';
import RunRecordFormContainer from 'rapidfab/containers/records/run/RunRecordFormContainer';
import Documents from 'rapidfab/components/records/Documents';
import RunRecordHeader from 'rapidfab/components/records/run/RunRecordHeader';
import RunData from './RunData';
import RunRequeueButton from './RunRequeueButton';
import RunScheduleButton from './RunScheduleButton';

const styles = {
  spacingTop: { marginTop: '1rem' },
};

const RunRecord = ({
  id,
  name,
  status,
  printCount,
  isRunEmpty,
  operation,
  uuid,
  uri,
  estimations,
  estimatesPrintTime,
  toggleEstimationModal,
  showEstimationTimeEditModal,
  onEstimationTimeChange,
  onEstimationTimeSubmit,
  isEstimationsSubmitting,
  loadPrints,
  build,
  canEditActuals,
  toggleActualsModal,
  showActualsEditModal,
  isUserManagedPrinterType,
  runMaterialData,
}) => (
  <Container fluid className="p-b">
    <BreadcrumbNav
      breadcrumbs={['plan', 'runs', id ? `${name} (${getShortUUID(id)})` : '']}
    />

    <RunRecordHeader
      isUserManagedPrinterType={isUserManagedPrinterType}
      operation={operation}
      name={name}
      estimations={estimations}
      printCount={printCount}
      status={status}
    />

    <Col xs={12} style={styles.spacingTop}>
      {isRunEmpty ? (
        <Loading />
      ) : (
        <Row>
          <Col xs={12} sm={6} md={4}>
            {operation === RUN_OPERATIONS.PRINTING && (
              <div className="mb15 d-flex align-items-center">
                <b className="spacer-right">
                  <FormattedMessage
                    id="record.run.linkedToBuild"
                    defaultMessage="Linked to build"
                  />:
                </b>
                <OverlayTrigger
                  placement="right"
                  overlay={(
                    <Tooltip id="isRunLinkedToBuildTooltip">
                      {build
                        ? (
                          <>
                            <FormattedMessage
                              id="record.run.buildLinkedTooltip"
                              defaultMessage="Run is linked to Build:"
                            />
                            {` ${build.name} (${build.uuid})`}
                          </>
                        )
                        : (
                          <FormattedMessage
                            id="record.run.buildNotLinkedTooltip"
                            defaultMessage="Run is not linked to a Build."
                          />
                        )}
                    </Tooltip>
                  )}
                >
                  <Fa
                    name={build ? 'check-circle' : 'times-circle'}
                    style={{ color: build ? COLORS.GREEN : COLORS.GRAY, fontSize: 24 }}
                  />
                </OverlayTrigger>
              </div>
            )}
            <RunRequeueButton />
            {/* uuid || id ? */}
            <RunScheduleButton uuid={uuid} />
            <RunData
              isUserManagedPrinterType={isUserManagedPrinterType}
              estimates={estimations}
              estimatesPrintTime={estimatesPrintTime}
              toggleEstimationModal={toggleEstimationModal}
              showEstimationTimeEditModal={showEstimationTimeEditModal}
              handleEstimatesInputChange={onEstimationTimeChange}
              onEstimatesSubmit={onEstimationTimeSubmit}
              isEstimationsSubmitting={isEstimationsSubmitting}
              canEditActuals={canEditActuals}
              toggleActualsModal={toggleActualsModal}
              showActualsEditModal={showActualsEditModal}
              runMaterialData={runMaterialData}
            />
          </Col>

          <Col xs={12} sm={6} md={8}>
            <RunRecordFormContainer runURI={uri} />
          </Col>
          <Col xs={12}>
            <BuildPlateContainer uri={uri} loadPrints={loadPrints} />
          </Col>
          <Col xs={12} sm={6} md={8} className="flexed-pull-right">
            <Documents
              panelStyle="default"
              relatedTable={DOCUMENT_RELATED_TABLE_NAMES.RUN}
              relatedUUID={uuid}
            />
          </Col>
        </Row>
      )}
    </Col>
  </Container>
);

RunRecord.defaultProps = {
  id: null,
  name: null,
  status: null,
  printCount: null,
  operation: null,
  uuid: null,
  uri: null,
  estimations: {},
  toggleEstimationModal: () => true,
  onEstimationTimeChange: () => true,
  onEstimationTimeSubmit: () => true,
  showEstimationTimeEditModal: false,
  isEstimationsSubmitting: false,
  estimatesPrintTime: null,
  build: null,
  canEditActuals: false,
  toggleActualsModal: () => true,
  showActualsEditModal: false,
  isUserManagedPrinterType: false,
};

RunRecord.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  printCount: PropTypes.number,
  isRunEmpty: PropTypes.bool.isRequired,
  operation: PropTypes.string,
  estimatesPrintTime: PropTypes.string,
  estimations: PropTypes.shape({
    end: PropTypes.string,
    start: PropTypes.string,
    baseMaterial: PropTypes.number,
    supportMaterial: PropTypes.number,
    time: PropTypes.shape({
      post_processing: PropTypes.number,
      print: PropTypes.number,
      shipping: PropTypes.number,
    }),
  }),
  onEstimationTimeSubmit: PropTypes.func,
  onEstimationTimeChange: PropTypes.func,
  toggleEstimationModal: PropTypes.func,
  showEstimationTimeEditModal: PropTypes.bool,
  isEstimationsSubmitting: PropTypes.bool,
  loadPrints: PropTypes.func.isRequired,
  uuid: PropTypes.string,
  uri: PropTypes.string,
  build: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
  }),
  canEditActuals: PropTypes.bool,
  toggleActualsModal: PropTypes.func,
  showActualsEditModal: PropTypes.bool,
  isUserManagedPrinterType: PropTypes.bool,
  runMaterialData: PropTypes.shape({
    runMaterial: PropTypes.shape({}),
    runMaterialFetching: PropTypes.bool,
  }).isRequired,
};

export default RunRecord;
