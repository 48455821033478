import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Actions from 'rapidfab/actions';
import Alert from 'rapidfab/utils/alert';
import { getBureauDefaultCurrency, getCurrentUserRoleMax, getUUIDResource } from 'rapidfab/selectors';
import extractUuid from 'rapidfab/utils/extractUuid';
import { connect } from 'react-redux';
import hhmmss, { parseHhmmss } from 'rapidfab/utils/hhmmss';
import _clone from 'lodash/clone';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import { lineItemResourceType } from 'rapidfab/types';
import { API_RESOURCES, USER_HIDE_INFO_TYPES, LINE_ITEM_COMPOSITION_TYPES } from 'rapidfab/constants';
import EditEstimationTimeModal from 'rapidfab/components/modals/EditEstimationTimeModal';
import { FormLabel, FormControl, FormGroup, Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import { CustomFormControlCost } from 'rapidfab/components/formTools';
import { WORKFLOW_TYPES_ESTIMATES_KEY_MAP } from 'rapidfab/mappings';

const EditLineItemEstimationModal = ({
  lineItem,
  submitEstimates,
  onClose,
  isSubmitting,
  currency,
  hideFinancial,
  workflowTypeEstimatesKey,
}) => {
  const [estimates, setEstimates] = useState(() => ({
    estimatesPrintTime: hhmmss(lineItem[workflowTypeEstimatesKey].printing_time || 0, true),
    estimatesBureauCost: lineItem.estimates.total_cost || 0,
    estimatesBureauPrice: lineItem.estimates.total_price || 0,
    additionalCosts: lineItem.additional_costs || [],
  }));

  const onAddAdditionalCost = () => {
    const newAdditionalCost = { cost_name: '', cost_value: 0 };
    setEstimates({
      ...estimates,
      additionalCosts: [...estimates.additionalCosts, newAdditionalCost],
    });
  };

  const onRemoveAdditionalCost = indexToRemove => {
    const newAdditionalCosts = _clone(estimates.additionalCosts);
    newAdditionalCosts.splice(indexToRemove, 1);
    setEstimates({ ...estimates, additionalCosts: newAdditionalCosts });
  };

  const handleInputChange = event => {
    const { target: { name, value } } = event;
    setEstimates({
      ...estimates,
      [name]: value,
    });
  };

  const handleAdditionalCostInputChange = (event, index) => {
    const newAdditionalCosts = _clone(estimates.additionalCosts);
    newAdditionalCosts[index][event.target.name] = event.target.value;
    setEstimates({ ...estimates, additionalCosts: newAdditionalCosts });
  };

  const onSubmit = event => {
    event.preventDefault();
    submitEstimates(estimates, lineItem);
  };

  // Assembly Workflows have no Printing step
  const showPrintTimeField = lineItem.composition_type !== LINE_ITEM_COMPOSITION_TYPES.ASSEMBLY;

  const renderExtraFields = () => {
    if (hideFinancial) return null;
    return (
      <Row>
        <Col xs={12}>
          <FormGroup className="form-group">
            <FormLabel htmlFor="estimatesBureauCost">
              <FormattedMessage
                id="overrideEstimationBureauCost"
                defaultMessage="Override the estimated bureau cost"
              />:
              <OverlayTrigger
                placement="right"
                overlay={(
                  <Tooltip>
                    This will override the estimated
                    <em className="spacer-left spacer-right">bureau custom</em>
                    cost for producing all pieces in this line-item
                  </Tooltip>
                )}
              >
                <FontAwesomeIcon className="spacer-left spacer-right" icon={faCircleInfo} />
              </OverlayTrigger>
            </FormLabel>
            <CustomFormControlCost
              id="estimatesBureauCost"
              name="estimatesBureauCost"
              type="number"
              value={estimates.estimatesBureauCost}
              onChange={handleInputChange}
              currency={currency}
            />
          </FormGroup>
          <FormGroup className="form-group">
            <FormLabel for="estimatesBureauCost">
              <FormattedMessage
                id="overrideEstimationsBureauPrice"
                defaultMessage="Override the estimated bureau price"
              />:
              <OverlayTrigger
                placement="right"
                overlay={(
                  <Tooltip>
                    This will override the estimated total price the
                    bureau will charge for producing all pieces in this line item.
                  </Tooltip>
                )}
              >
                <FontAwesomeIcon className="spacer-left spacer-right" icon={faCircleInfo} />
              </OverlayTrigger>
            </FormLabel>
            <CustomFormControlCost
              id="estimatesBureauPrice"
              name="estimatesBureauPrice"
              type="number"
              value={estimates.estimatesBureauPrice}
              onChange={handleInputChange}
              currency={currency}
            />
          </FormGroup>
          <FormLabel>
            <FormattedMessage
              id="field.additionalCostsPerPiece"
              defaultMessage="Additional Costs Per Piece"
            />:
          </FormLabel>
          {estimates.additionalCosts.map(({ cost_name, cost_value }, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <Row key={index} className="d-flex align-items-center mb-1">
              <Col className="flex-1">
                <FormGroup>
                  <FormControl
                    placeholder="Description"
                    name="cost_name"
                    value={cost_name}
                    onChange={event => handleAdditionalCostInputChange(event, index)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col className="flex-1">
                <FormGroup>
                  <CustomFormControlCost
                    name="cost_value"
                    type="number"
                    value={cost_value}
                    onChange={event => handleAdditionalCostInputChange(event, index)}
                    currency={currency}
                    required
                  />
                </FormGroup>
              </Col>
              <Col className="flex-initial" style={{ width: '40px' }}>
                <Button
                  variant="danger"
                  size="xs"
                  onClick={() => onRemoveAdditionalCost(index)}
                >
                  <Fa name="times" />
                </Button>
              </Col>
            </Row>
          ))}
          <Button
            className="pull-right mt-2"
            variant="primary"
            onClick={onAddAdditionalCost}
          >
            <FormattedMessage id="button.addAdditionalCost" defaultMessage="Add Additional Cost" />
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <EditEstimationTimeModal
      show
      showPrintTimeField={showPrintTimeField}
      printTime={estimates.estimatesPrintTime}
      onChange={handleInputChange}
      submit={onSubmit}
      onHide={onClose}
      loading={isSubmitting}
      extraFields={renderExtraFields()}
    />
  );
};

EditLineItemEstimationModal.propTypes = {
  lineItem: lineItemResourceType.isRequired,
  // Used in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  lineItemUri: PropTypes.string.isRequired,
  submitEstimates: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  hideFinancial: PropTypes.bool.isRequired,
  workflowTypeEstimatesKey: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch, { onClose, workflowTypeEstimatesKey }) => ({
  submitEstimates: (estimates, lineItem) => {
    const estimatedPrintSeconds = dayjs.duration(parseHhmmss(estimates.estimatesPrintTime)).asSeconds();
    const payload = {
      estimates: {},
      additional_costs: _map(estimates.additionalCosts, ({ cost_name, cost_value }) => ({
        cost_name, cost_value: Number(cost_value),
      })),
    };

    // do not resubmit unless edited. When these are submitted, auto-recalculate price stops
    const parsedPrintingTime = Number.parseInt(estimatedPrintSeconds, 10);
    if (parsedPrintingTime !== lineItem[workflowTypeEstimatesKey].printing_time) {
      payload[workflowTypeEstimatesKey].printing_time = parsedPrintingTime;
    }

    // do not resubmit unless edited. When these are submitted, auto-recalculate price stops
    const parsedBureauCost = Number.parseFloat(estimates.estimatesBureauCost);
    if (parsedBureauCost !== lineItem.estimates.total_cost) {
      payload.estimates.total_cost = parsedBureauCost;
    }

    // do not resubmit unless edited. When these are submitted, auto-recalculate price stops
    const parsedBureauPrice = Number.parseFloat(estimates.estimatesBureauPrice);
    if (parsedBureauPrice !== lineItem.estimates.total_price) {
      payload.estimates.total_price = parsedBureauPrice;
    }

    if (_isEmpty(payload.estimates)) delete payload.estimates;

    dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].put(lineItem.uuid, payload)).then(() => {
      Alert.success(
        <FormattedMessage
          id="toaster.estimationsUpdated"
          defaultMessage="Estimations successfully updated."
        />,
      );
      onClose();
    });
  },
});

const mapStateToProps = (state, { lineItemUri }) => {
  const lineItem = getUUIDResource(state, extractUuid(lineItemUri));
  const workflowTypeEstimatesKey = WORKFLOW_TYPES_ESTIMATES_KEY_MAP[lineItem?.workflow_type];
  const isSubmitting = state.ui.nautilus[API_RESOURCES.LINE_ITEM].put.fetching;
  const currency = getBureauDefaultCurrency(state);
  const role = getCurrentUserRoleMax(state);
  const hideFinancial = role?.hide_info === USER_HIDE_INFO_TYPES.FINANCIAL;

  return {
    lineItem,
    isSubmitting,
    currency,
    hideFinancial,
    workflowTypeEstimatesKey,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLineItemEstimationModal);
