import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import extractUuid from 'rapidfab/utils/extractUuid';
import Alert from 'rapidfab/utils/alert';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { RUN_ACTUALS_MODAL } from 'rapidfab/constants/forms';
import EditRunActualsModal from '../../modals/EditRunActualsModal';

const Layout = ({ Table }) => <Table />;
Layout.propTypes = { Table: PropTypes.func.isRequired };

const EditRunActualsModalContainer = ({ handleSubmit, ...props }) => {
  const dispatch = useDispatch();

  const startDate = dayjs(props.actuals.start)
    .format('YYYY-MM-DD');
  const startTime = dayjs(props.actuals.start)
    .format('HH:mm:ss');
  const endDate = dayjs(props.actuals.end)
    .format('YYYY-MM-DD');
  const endTime = dayjs(props.actuals.end)
    .format('HH:mm:ss');
  const initialValues = { startDate, startTime, endDate, endTime };

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => RUN_ACTUALS_MODAL.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  const handleRunActualsSubmit = payload => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_ACTUALS].put(
      extractUuid(props.actuals.uri),
      payload,
    )).then(runActualsSubmitResponse => {
      if (runActualsSubmitResponse.payload) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_ACTUALS].list(
          null,
          { limit: 5000 },
        ));
        Alert.success(
          <FormattedMessage
            id="toaster.run.actualsUpdatedSuccessfully"
            defaultMessage="Runs Actuals updated successfully"
          />,
        );
        props.onHide();
      } else {
        Alert.success(<FormattedMessage
          id="toaster.run.actualsUpdatedUnsuccessfully"
          defaultMessage="Runs Actuals updated unsuccessfully"
        />);
      }
    });
  };

  const onSubmit = async values => {
    const { startDate, startTime, endDate, endTime } = values;
    const startInProgressTime = dayjs(`${startDate} ${startTime}`).toISOString();
    const endInProgressTime = dayjs(`${endDate} ${endTime}`).toISOString();

    const payload = {
      start_in_progress_time: startInProgressTime,
      end_in_progress_time: endInProgressTime,
    };
    // add api request(s) and update actual data on run page (maybe redux state)
    handleRunActualsSubmit(payload);
  };

  return (
    <EditRunActualsModal {...props} initialValues={initialFormValues} onSubmit={onSubmit} />
  );
};

EditRunActualsModalContainer.defaultProps = {
  loading: false,
  show: false,
  onHide: () => true,
  actuals: {
    end: null,
    start: null,
    uri: null,
  },
};

EditRunActualsModalContainer.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  actuals: PropTypes.shape({
    end: PropTypes.string,
    start: PropTypes.string,
    uri: PropTypes.string,
  }),
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

export default EditRunActualsModalContainer;
