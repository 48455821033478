/**
 * Current file contains all previously created selectors.
 * All selectors require moving to separate files based on resource type.
 * E.g. `printer.js`, `run.js` etc. for selectors working with appropriate resources
 *
 * Some helpers that work with different resources
 * but based on other ones should be moved to `helpers` folder
 * See `./helpers/run.js` as an example.
 *
 * Each selector requires tests wherever possible.
 * See `./helpers/run.test.js` and `./legacy-selectors.test.js` as examples
 *
 * Final task is here (in case new design is not finalized until then)
 * https://app.clubhouse.io/authentise/story/12464/design-for-rapidfab-selectors
 */

import _assign from 'lodash/assign';
import _concat from 'lodash/concat';
import _countBy from 'lodash/countBy';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import { createSelector } from 'reselect';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';

import { getStateResources, getPredicate } from 'rapidfab/selectors/helpers/base';
import * as baseStateSelectors from './baseStateSelectors';
import { getRuns } from './run';
import { getPostProcessors } from './postProcessor';
import { getPrinters } from './printer';
import { getPrinterTypes } from './printerType';
import { getDowntimes } from './downtimes';
import { getMaterials } from './material';
import { getLocations } from './location';

export const getRouteUUID = state => state?.routeUUID;

export const getResourceFetching = (state, path) => {
  const methods = _get(state.ui, path);
  if (!methods) {
    throw new Error(`Could not find methods by path: ${path}`);
  }
  return !!_find(methods, method => method.fetching);
};

export const getRouteUUIDResource = createSelector(
  [getRouteUUID, getStateResources],
  (routeUUID, resources) => resources[routeUUID],
);

export const getUUIDResource = createSelector(
  [getPredicate, getStateResources],
  (uuid, resources) => resources[uuid],
);

export const getGroups = createSelector(
  [baseStateSelectors.getStateGroups, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getMemberships = createSelector(
  [baseStateSelectors.getStateMemberships, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getLabels = createSelector(
  [baseStateSelectors.getStateLabels, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getLabelsByUri = createSelector([getLabels], labels =>
  _keyBy(labels, 'uri'),
);

export const getLocationOptions = createSelector([getLocations], locations =>
  locations.map(location => ({
    name: location.name,
    uri: location.uri,
  })),
);

export const getManufacturers = createSelector(
  [baseStateSelectors.getStateManufacturers, getStateResources],
  (uuids, resources) => {
    const manufacturers = _map(uuids, uuid => resources[uuid]);
    return _filter(manufacturers, { is_template: false });
  },
);

export const getTemplateManufacturers = createSelector(
  [baseStateSelectors.getStateManufacturers, getStateResources],
  (uuids, resources) => {
    const manufacturers = _map(uuids, uuid => resources[uuid]);
    return _filter(manufacturers, { is_template: true });
  },
);

export const getMaterialBatchTraceabilityReports = createSelector(
  [baseStateSelectors.getStateMaterialBatchTraceabilityReports, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getTemplateMaterials = createSelector(
  [baseStateSelectors.getStateMaterials, getStateResources],
  (uuids, resources) => {
    const materials = _map(uuids, uuid => resources[uuid]);
    return _filter(materials, { is_template: true });
  },
);

export const getInfillStrategies = createSelector(
  [baseStateSelectors.getStateInfillStrategies, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getSupportStrategies = createSelector(
  [baseStateSelectors.getStateSupportStrategies, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getMaterialsForPrinterType = createSelector(
  [getPredicate, getMaterials],
  (printerType, materials) => {
    if (!printerType) {
      return [];
    }
    return _filter(materials, material => _includes(printerType.materials, material.uri));
  },
);

export const getLineItemQuotes = createSelector(
  [baseStateSelectors.getStateLineItemQuotes, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getOrderCreator = createSelector(
  [getRouteUUIDResource],
  order => {
    if (!order) {
      return null;
    }

    return order.created_by;
  },
);

export const getPrintersGroupedByPrinterType = createSelector(
  [getPrinters],
  printers => _groupBy(printers, 'printer_type'),
);

export const getPrintersGroupedByLocation = createSelector(
  [getPrinters],
  printers => _groupBy(printers, 'location'),
);

export const getModelers = createSelector(
  [baseStateSelectors.getStateModelers, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getModelersByUri = createSelector([getModelers], modelers =>
  _keyBy(modelers, 'uri'),
);

export const getUploadModel = createSelector(
  [baseStateSelectors.getStateUploadModel],
  uploadModel => uploadModel,
);

export const getTemplatePrinterTypes = createSelector(
  [baseStateSelectors.getStatePrinterTypes, getStateResources],
  (uuids, resources) => {
    const printerTypes = _map(uuids, uuid => resources[uuid]);
    return _filter(printerTypes, { is_template: true });
  },
);

export const getAvailablePrinterTypes = createSelector(
  [getPrinterTypes, getPrinters],
  (printerTypes, printers) => {
    const typeCount = _countBy(printers, 'printer_type');
    return printerTypes.filter(({ uri }) => typeCount[uri] > 0);
  },
);

export const getLocationsForOrder = createSelector(
  [getRouteUUIDResource, getStateResources],
  (order, resources) => {
    if (!order) {
      return [];
    }

    const locationsAvailable = order.locations_available || [];
    return _map(locationsAvailable, locationUri => resources[extractUuid(locationUri)]);
  },
);

export const getTraceabilityReportForBatch = createSelector(
  [getPredicate, getMaterialBatchTraceabilityReports],
  (batch, reports) => {
    if (batch) {
      return _find(reports, { material_batch: batch.uri });
    }
    return null;
  },
);

export const getMaterialBatches = createSelector(
  [baseStateSelectors.getStateMaterialBatches, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

/* TODO: Delete when UI for multiple materials is ready */
export const getMaterialBatchesTemporary = createSelector(
  [baseStateSelectors.getStateMaterialBatches, getStateResources],
  (uuids, resources) => _map(uuids, uuid => ({ ...resources[uuid], materials: resources[uuid].materials[0].name })),
);

export const getLoadedMaterialBatchForPrinter = createSelector(
  [getPredicate, getMaterialBatches],
  (printer, batches) => {
    if (!printer) {
      return null;
    }
    return _find(batches, { at_machine: printer.uri });
  },
);

export const getInitialMaterialBatchesForLot = createSelector(
  [getPredicate, getMaterialBatches],
  (lot, batches) => {
    if (!lot) {
      return [];
    }
    return _filter(batches, batch => batch.is_initial_batch && batch.material_lots.includes(lot.uri));
  },
);
export const getMaterialBatchesForStock = createSelector(
  [getPredicate, getMaterialBatches],
  (stock, batches) => {
    if (!stock) {
      return [];
    }
    return _filter(batches, batch => stock.material_batches?.includes(batch.uri));
  },
);

export const getDowntimesForMachine = createSelector(
  [getPredicate, getDowntimes],
  (uri, downtimes) =>
    downtimes.filter(
      downtime =>
        downtime.printer === uri ||
        downtime.post_processor === uri ||
        downtime.shipping === uri,
    ),
);

export const getPrintersForRunNew = createSelector(
  [getPrinters, getPrinterTypes],
  (printers, printerTypes) => {
    if (printers.length && printerTypes.length) {
      return _reduce(
        printers,
        (result, printer) => {
          const printerType = _find(printerTypes, [
            'uri',
            printer.printer_type,
          ]);
          if (printerType) {
            const hydratedRecord = _assign({}, printer, {
              printer_type: printerType,
            });
            result.push(hydratedRecord);
          }
          return result;
        },
        [],
      );
    }
    return [];
  },
);

export const getNetfabbLineItems = createSelector(
  [baseStateSelectors.getStateNetfabbLineItems, getStateResources],
  (uuids, resources) => uuids.map(uuid => resources[uuid]),
);

export const getMachinesForQueues = createSelector(
  [getPrinters, getPostProcessors, getModelers],
  (printers, postProcessors, modelers) =>
    _map(_concat(printers, postProcessors), machine => {
      const modeler = _find(modelers, { uri: machine.modeler });
      return { ...machine, status: modeler && modeler.status };
    }),
);

export const getLineItemQuoteByLineItemUri = createSelector(
  [getPredicate, getLineItemQuotes],
  (lineItemUri, lineItemQuotes) => _find(lineItemQuotes, { line_item: lineItemUri }),
);

export const getRunRescheduleQueue = createSelector(
  [getRouteUUIDResource, getPrinters, getRuns],
  (run, printers, runs) => {
    if (!run) return null;
    const runPrinter = printers.find(currentPrinter => currentPrinter.uri === run.printer);
    return runPrinter
      // TODO Replace when moved out from legacy selectors
      // eslint-disable-next-line unicorn/no-array-reduce
      ? runPrinter.queue.reduce((queue, runUri) => {
        const queueRun = runs.find(run => run.uri === runUri);
        return queueRun ? [...queue, queueRun] : queue;
      }, [])
      : [];
  },
);

export const getSpecimensForWorkflow = createSelector(
  [getPredicate, baseStateSelectors.getStateSpecimens, getStateResources],
  (workflow, uuids, resources) => _map(
    uuids, uuid => resources[uuid]).filter(
    specimen => specimen.parent_workflow === workflow.uri,
  ),
);
