import Constants from 'rapidfab/constants';

const initialState = {
  disconnected: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case Constants.STORE_EVENTSTREAM_DISCONNECTED:
      return { ...state, disconnected: action.payload };
    default:
      return state;
  }
}

export default reducer;
