import React, { useMemo } from 'react';
import { Badge, Button, Card, FormControl, FormGroup, ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import _isEmpty from 'lodash/isEmpty';

const ChangeImpersonationModal = ({
  show,
  onHide,
  session,
  modalState,
  handleOnImpersonate,
  handleEndImpersonation,
  keyUsersForImpersonation,
  users,
  handleSearchUsers,
  handleEnterPressSearch,
  isLoading,
  isSearchingForUsers,
  handleChangeBureauEntryField,
}) => {
  const filteredUsers = useMemo(() => users.filter(user =>
    user.username.includes(modalState.enteredBureau)), [users, modalState.enteredBureau]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        Change Impersonation
      </Modal.Header>
      <Modal.Body>
        <Card bg="light">
          <Card.Body className="pd-exp inverse d-flex justify-content-between align-items-start">
            <span>You are currently impersonating {session.username}</span>
            <Button
              variant="danger"
              size="xs"
              onClick={handleEndImpersonation}
              disabled={isLoading}
            >
              <FormattedMessage
                id="end"
                defaultMessage="End"
              />
            </Button>
          </Card.Body>
        </Card>
        <FormGroup className="d-flex align-items-end justify-content-between form-group mt15">
          <FormControl
            type="text"
            required={false}
            value={modalState.enteredBureau}
            placeholder="Bureau"
            className="spacer-top resize-vertical w-100"
            onChange={handleChangeBureauEntryField}
            onKeyDown={handleEnterPressSearch}
            onBlur={handleSearchUsers}
            autoComplete="username"
          />
          <Button
            className="spacer-left"
            onClick={handleSearchUsers}
            disabled={isSearchingForUsers}
          >
            <FormattedMessage
              id="record.run.search"
              defaultMessage="Search"
            />
          </Button>
        </FormGroup>
        <Card className="spacer-top" bg="light">
          <Card.Body className="pd-exp inverse">
            {
              isSearchingForUsers || isLoading ?
                <Loading />
                : (
                  <ListGroup style={{ maxHeight: 250, overflowY: 'auto' }}>
                    {
                      (_isEmpty(modalState.enteredBureau) ?
                        users :
                        filteredUsers)
                        .map(user => (
                          <ListGroupItem
                            className="text-black bg-white"
                          >
                            <Button
                              size="xs spacer-right"
                              onClick={() => handleOnImpersonate(user.uri)}
                            >
                              <FormattedMessage
                                id="impersonate"
                                defaultMessage="Impersonate"
                              />
                            </Button>
                            {user.username}
                          </ListGroupItem>
                        ))
                    }
                  </ListGroup>
                )
            }
          </Card.Body>
        </Card>
        <Card bg="white" className="mt15">
          <Card.Header className="pd-exp inverse">
            Quick Impersonation
          </Card.Header>
          {
            isLoading ? (
              <Loading />
            ) : (
              <Card.Body>
                {keyUsersForImpersonation
                  .map(bureau => (
                    <Badge
                      role="button"
                      className="badge text-white spacer-right spacer-top"
                      bg="info"
                      onClick={() => {
                        handleOnImpersonate(bureau.uri);
                      }}
                    >
                      {bureau.name}
                    </Badge>
                  ))}
              </Card.Body>
            )
          }
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>
          <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          disabled={isLoading || modalState.enteredBureau === null || !modalState.enteredBureau}
          onClick={() => handleOnImpersonate()}
          variant="success"
          type="submit"
        >
          Impersonate
          {isLoading && <Loading />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ChangeImpersonationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  modalState: PropTypes.shape({
    enteredBureau: PropTypes.string,
    setEnteredBureau: PropTypes.func,
    bureauIsRestrictedChecked: PropTypes.bool,
    setBureauIsRestrictedChecked: PropTypes.func,
  }).isRequired,
  handleOnImpersonate: PropTypes.func.isRequired,
  keyUsersForImpersonation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSearchUsers: PropTypes.func.isRequired,
  handleEnterPressSearch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSearchingForUsers: PropTypes.bool.isRequired,
  handleChangeBureauEntryField: PropTypes.func.isRequired,
  session: PropTypes.shape({
    username: PropTypes.string,
  }).isRequired,
  handleEndImpersonation: PropTypes.func.isRequired,
};

export default ChangeImpersonationModal;
