import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

import { MODEL_LIBRARY_TYPES } from 'rapidfab/constants';
import { FormattedMessage } from 'rapidfab/i18n';
import ModelLibrariesContainer from 'rapidfab/containers/manage/ModelLibrariesContainer';

const ModelLibraryModal = ({
  onHide,
  handleSelect,
}) => (
  <Modal size="lg" show onHide={() => onHide()} backdrop="static">
    <Modal.Header closeButton>
      <Modal.Title>
        Please select a model:
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ModelLibrariesContainer
        showHeader={false}
        showModelDropZone={false}
        handleSelect={handleSelect}
        typeFilter={MODEL_LIBRARY_TYPES.PRODUCT}
      />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={() => onHide('')}>
        <FormattedMessage id="close" defaultMessage="Close" />
      </Button>
    </Modal.Footer>
  </Modal>
);

ModelLibraryModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default ModelLibraryModal;
