import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Card, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import EditEstimationTimeModal from 'rapidfab/components/modals/EditEstimationTimeModal';
import Feature from 'rapidfab/components/Feature';

import { FEATURES, MATERIAL_UNITS, RUN_OPERATIONS, RUN_STATUSES } from 'rapidfab/constants';
import { getRouteUUIDResource, getRunActualsForRun } from 'rapidfab/selectors';
import {
  FormattedDateTime,
  FormattedDuration,
  FormattedMessage,
  FormattedVolume,
} from 'rapidfab/i18n';
import _get from 'lodash/get';
import dayjs from 'dayjs';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import EditRunActualsModalContainer from 'rapidfab/components/records/run/EditRunActualsModalContainer';
import LastUpdated from 'rapidfab/components/LastUpdated';
import Fa from 'react-fontawesome';
import _groupBy from 'lodash/groupBy';
import Loading from 'rapidfab/components/Loading';

const RunData = ({
  estimates,
  estimatesPrintTime,
  handleEstimatesInputChange,
  showEstimationTimeEditModal,
  toggleEstimationModal,
  isEstimationsSubmitting,
  onEstimatesSubmit,
  canEditActuals,
  toggleActualsModal,
  showActualsEditModal,
  isUserManagedPrinterType,
  runMaterialData,
}) => {
  const { runMaterial, runMaterialFetching } = runMaterialData;
  const materials = _groupBy(runMaterial?.materials, 'type');

  const run = useSelector(getRouteUUIDResource);
  const runActuals = useSelector(state => getRunActualsForRun(state, run));
  const startTime =
    runActuals && runActuals.start_in_progress_time && dayjs(runActuals.start_in_progress_time);
  const endTime =
    runActuals && runActuals.end_in_progress_time && dayjs(runActuals.end_in_progress_time);
  const time = (startTime && endTime) ? endTime.diff(startTime, 'seconds') : null;
  const isRunComplete = run?.status === RUN_STATUSES.COMPLETE;

  const actuals = {
    base: _get(runActuals, 'base_material_used'),
    end: _get(runActuals, 'end_in_progress_time'),
    time,
    start: _get(runActuals, 'start_in_progress_time'),
    support: _get(runActuals, 'support_material_used'),
    uri: _get(runActuals, 'uri'),
  };
  const operation = run?.operation;

  const userManagedPrinterTooltip = (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip>
          <p>Printer Types using a User Managed packing type must have their schedule estimates entered manually.</p>
        </Tooltip>
      )}
    >
      <Fa className="spacer-left spacer-right" name="question-circle" />
    </OverlayTrigger>
  );

  const renderMaterialsTooltip = materials => {
    const tooltipText = materials.length > 1 ?
      'This run contains multiple material types' :
      'This run contains a single material type';
    return (
      runMaterialFetching ?
        <Loading inline className="spacer-left" /> :
        (
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip>
                <ul>
                  <p className="text-left">
                    <strong>{tooltipText}</strong>
                  </p>
                  {
                    materials.map(material => {
                      const name = material.material;

                      if (name) {
                        return (
                          <li className="text-left" key={name}>
                            {name}&nbsp;
                          </li>
                        );
                      }
                      return null;
                    })
                  }
                </ul>
              </Tooltip>
            )}
          >
            <Fa
              name="info-circle spacer-left"
            />
          </OverlayTrigger>
        )
    );
  };

  return (
    <>
      <Card bg="dark" className="mb15">
        <div className="card-body-wrapper">
          <Feature featureName={FEATURES.USER_CAN_UPDATE_ESTIMATES}>
            <EditEstimationTimeModal
              show={showEstimationTimeEditModal}
              printTime={estimatesPrintTime}
              onChange={handleEstimatesInputChange}
              submit={onEstimatesSubmit}
              onHide={toggleEstimationModal}
              loading={isEstimationsSubmitting}
              modelName="run"
            />
          </Feature>

          <EditRunActualsModalContainer
            show={showActualsEditModal}
            onHide={toggleActualsModal}
            actuals={actuals}
          />

          <Table bordered size="sm" className="m-b-0">
            <thead>
              <tr>
                <th />
                <th>
                  <FormattedMessage id="estimates" defaultMessage="Estimates" />
                  {isUserManagedPrinterType && userManagedPrinterTooltip}
                </th>
                <th>
                  <div className="d-flex justify-content-between">
                    <FormattedMessage id="actuals" defaultMessage="Actuals" />
                    {canEditActuals && (
                      <Button size="xs" onClick={toggleActualsModal}>
                        <FormattedMessage id="button.edit" defaultMessage="Edit" />
                      </Button>
                    )}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>
                    <FormattedMessage id="start" defaultMessage="Start" />
                  </strong>
                </td>
                <td>
                  {
                    estimates.start && (
                      <FormattedDateTime value={estimates.start} />
                    )
                  }
                </td>
                <td>
                  {actuals.start && (
                    <FormattedDateTime value={actuals.start} />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>
                    <FormattedMessage id="end" defaultMessage="End" />
                  </strong>
                </td>
                <td>
                  {
                    estimates.end && (
                      <FormattedDateTime value={estimates.end} />
                    )
                  }
                </td>
                <td>
                  {actuals.end && (
                    <FormattedDateTime value={actuals.end} />
                  )}
                </td>
              </tr>
              {operation === RUN_OPERATIONS.PRINTING && (
                <tr>
                  <td>
                    <strong>
                      <UseNonMfgLanguageFeature
                        mfgLanguageComponent={<FormattedMessage id="printTime" defaultMessage="Print Time" />}
                        nonMfgLanguageComponent={(
                          <FormattedMessage
                            id="mfg.printTime.productionTime"
                            defaultMessage="Production Time"
                          />
                        )}
                      />
                    </strong>
                  </td>
                  <td>
                    {
                      (estimates?.time?.run_duration) &&
                      <FormattedDuration value={estimates?.time?.run_duration} />
                    }
                    {' '}
                    <Feature featureName={FEATURES.USER_CAN_UPDATE_ESTIMATES}>
                      <Button size="xs" onClick={toggleEstimationModal}>
                        <FormattedMessage id="button.edit" defaultMessage="Edit" />
                      </Button>
                    </Feature>
                  </td>
                  <td>
                    {actuals.time && <FormattedDuration value={actuals.time} />}
                  </td>
                </tr>
              )}
              {operation === RUN_OPERATIONS.POST_PROCESSING && (
                <tr>
                  <td>
                    <strong>
                      <FormattedMessage
                        id="postProcessingTime"
                        defaultMessage="Post-Processing Time"
                      />
                    </strong>
                  </td>
                  <td>
                    {estimates?.time?.run_duration && (
                      <FormattedDuration value={estimates.time.run_duration} />
                    )}
                  </td>
                  <td>
                    {actuals.time && (
                      <FormattedDuration value={actuals.time} />
                    )}
                  </td>
                </tr>
              )}
              {operation === RUN_OPERATIONS.SHIPPING && (
                <tr>
                  <td>
                    <strong>
                      <FormattedMessage
                        id="shippingTime"
                        defaultMessage="Shipping Time"
                      />
                    </strong>
                  </td>
                  <td>
                    {
                      estimates?.time?.run_duration && (
                        <FormattedDuration value={estimates.time.run_duration} />
                      )
                    }
                  </td>
                  <td>
                    {actuals.time && (
                      <FormattedDuration value={actuals.time} />
                    )}
                  </td>
                </tr>
              )}
              {operation === RUN_OPERATIONS.PRINTING && (
                <tr>
                  <td>
                    <strong>
                      <FormattedMessage
                        id="materialUsed"
                        defaultMessage="Material Used"
                      />
                    </strong>
                  </td>
                  <td>
                    {estimates && estimates.baseMaterial && (
                      <FormattedVolume
                        value={estimates.baseMaterial}
                        valueUnits={
                        // `run.materials_base is always returned in cm3
                          MATERIAL_UNITS.CM3
                        }
                      />
                    )}
                    {
                      materials && materials?.base &&
                      renderMaterialsTooltip(materials.base, 'quantity_estimate')
                    }
                  </td>
                  <td>
                    {isRunComplete && actuals.base && (
                      <FormattedVolume
                        value={actuals.base}
                        // `run-actuals.base_material_used` is always returned in cm3
                        valueUnits={MATERIAL_UNITS.CM3}
                      />
                    )}
                    {
                      materials && materials?.base &&
                      renderMaterialsTooltip(materials.base, 'quantity_actual')
                    }
                  </td>
                </tr>
              )}
              {operation === RUN_OPERATIONS.PRINTING && (
                <tr>
                  <td>
                    <strong>
                      <FormattedMessage
                        id="supportUsed"
                        defaultMessage="Support Used"
                      />
                    </strong>
                  </td>
                  <td>
                    {
                      estimates && estimates.supportMaterial && (
                        <FormattedVolume
                          value={estimates.supportMaterial}
                          valueUnits={
                          // `run.materials_support is always returned in cm3
                            MATERIAL_UNITS.CM3
                          }
                        />
                      )
                    }
                    {
                      materials && materials?.support &&
                      renderMaterialsTooltip(materials.support, 'quantity_estimate')
                    }
                  </td>
                  <td>
                    {isRunComplete && actuals.support && (
                      <FormattedVolume
                        value={actuals.support}
                        // `run-actuals.support_material_used` is always returned in cm3
                        valueUnits={MATERIAL_UNITS.CM3}
                      />
                    )}
                    {
                      materials && materials?.support &&
                      renderMaterialsTooltip(materials.support, 'quantity_actual')
                    }
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Card>
      <div className="d-flex"> Actuals&nbsp;<LastUpdated resource={runActuals} capitalized={false} /></div>
    </>
  );
};

RunData.defaultProps = {
  estimates: {
    end: null,
    start: null,
    materials: {
      base: null,
      support: null,
    },
    time: {
      post_processing: null,
      print: null,
      shipping: null,
    },
  },
  showEstimationTimeEditModal: false,
  isEstimationsSubmitting: false,
  onEstimatesSubmit: () => true,
  handleEstimatesInputChange: () => true,
  toggleEstimationModal: () => true,
  estimatesPrintTime: null,
  canEditActuals: false,
  toggleActualsModal: () => true,
  showActualsEditModal: false,
  runActuals: {},
  isUserManagedPrinterType: false,
  runMaterialData: {},
};

RunData.propTypes = {
  estimates: PropTypes.shape({
    end: PropTypes.string,
    start: PropTypes.string,
    baseMaterial: PropTypes.number,
    supportMaterial: PropTypes.number,
    time: PropTypes.shape({
      run_duration: PropTypes.number,
      pre_run_duration: PropTypes.number,
      post_run_duration: PropTypes.number,
    }),
  }),
  onEstimatesSubmit: PropTypes.func,
  handleEstimatesInputChange: PropTypes.func,
  isEstimationsSubmitting: PropTypes.bool,
  showEstimationTimeEditModal: PropTypes.bool,
  toggleEstimationModal: PropTypes.func,
  estimatesPrintTime: PropTypes.string,
  canEditActuals: PropTypes.bool,
  toggleActualsModal: PropTypes.func,
  showActualsEditModal: PropTypes.bool,
  runActuals: PropTypes.shape({}),
  isUserManagedPrinterType: PropTypes.bool,
  runMaterialData: PropTypes.shape({
    runMaterial: PropTypes.shape({
      materials: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    runMaterialFetching: PropTypes.bool,
  }),
};

export default RunData;
