import React from 'react';
import PropTypes from 'prop-types';

import { Container, ListGroup, ListGroupItem, Card } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { FormattedMessage } from 'rapidfab/i18n';
import Feature from 'rapidfab/components/Feature';
import NonHawkingFeature from 'rapidfab/components/hawking/NonHawkingFeature';
import { FEATURES, ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { Outlet } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVial } from '@fortawesome/free-solid-svg-icons';

const Admin = ({ bureauName, isHawkingUser }) => (
  <Container fluid>
    <BreadcrumbNav breadcrumbs={['admin']} />
    <div className="page-header">
      <h1 className="font-size-36">
        <FormattedMessage
          id="bureauAdministration"
          defaultMessage="Bureau Administration"
        />
      </h1>
    </div>
    <Card bg={isHawkingUser ? 'light' : 'dark'}>
      <Card.Header className="pd-exp inverse">{bureauName}</Card.Header>
      <div className="card-body-wrapper">
        <Card.Body className="p-a-0">
          <ListGroup>
            <ListGroupItem action href={getRouteURI(ROUTES.ADMIN_USERS)}>
              <FontAwesome name="user" className="spacer-right" />
              Users
            </ListGroupItem>
            <Feature featureNames={[FEATURES.STANLEY_X_DEPLOYMENT]} isInverted>
              <Feature
                featureNames={[
                  FEATURES.PREP_WORKFLOW,
                  FEATURES.GROUP_QUALIFICATIONS,
                  FEATURES.DIGITAL_DESIGN_WAREHOUSE,
                ]}
              >
                <ListGroupItem action href={getRouteURI(ROUTES.ADMIN_USER_GROUPS)}>
                  <FontAwesome name="group" className="spacer-right" />
                  Groups
                </ListGroupItem>
              </Feature>

              <NonHawkingFeature>
                <ListGroupItem action href={getRouteURI(ROUTES.ADMIN_BANNER)}>
                  <FontAwesome name="commenting-o" className="spacer-right" />
                  Banner
                </ListGroupItem>
              </NonHawkingFeature>

              <NonHawkingFeature>
                <ListGroupItem action href={getRouteURI(ROUTES.ADMIN_RECALCULATION)}>
                  <FontAwesome name="refresh" className="spacer-right" />
                  Recalculation Trigger
                </ListGroupItem>
              </NonHawkingFeature>

            </Feature>
            <ListGroupItem action href={getRouteURI(ROUTES.ADMIN_LABELS)}>
              <FontAwesome name="tags" className="spacer-right" />
              Labels
            </ListGroupItem>
            <Feature featureNames={[FEATURES.STANLEY_X_DEPLOYMENT]} isInverted>
              <NonHawkingFeature>
                <ListGroupItem action href={getRouteURI(ROUTES.ADMIN_SETTINGS)}>
                  <FontAwesome name="sliders" className="spacer-right" />
                  Settings
                </ListGroupItem>
              </NonHawkingFeature>
              <ListGroupItem action href={getRouteURI(ROUTES.ADMIN_CUSTOM_FIELDS)}>
                <FontAwesome name="list" className="spacer-right" />
                Custom Fields
              </ListGroupItem>
            </Feature>
            <Feature featureName={FEATURES.GUIDELINES_ENGINE}>
              <ListGroupItem action href={getRouteURI(ROUTES.GUIDELINES_ENGINE)}>
                <FontAwesome name="list-alt" className="spacer-right" />
                <FormattedMessage
                  id="guidelinesEngine"
                  defaultMessage="Guidelines Engine"
                />
              </ListGroupItem>
            </Feature>
            <Feature featureName={FEATURES.MATERIAL_TEST_PANEL}>
              <ListGroupItem action href={getRouteURI(ROUTES.MATERIAL_TESTS)}>
                <FontAwesomeIcon icon={faVial} className="spacer-right" />
                <FormattedMessage
                  id="materialTests"
                  defaultMessage="Material Tests"
                />
              </ListGroupItem>
            </Feature>
          </ListGroup>
        </Card.Body>
      </div>
    </Card>
    <Outlet />
  </Container>
);

Admin.propTypes = { bureauName: PropTypes.string.isRequired, isHawkingUser: PropTypes.bool.isRequired };

export default Admin;
