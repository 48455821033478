import React, { useEffect, useState } from 'react';
import Actions from 'rapidfab/actions';
import PostProcessorType from 'rapidfab/components/records/postProcessorType';
import _get from 'lodash/get';
import _set from 'lodash/set';
import * as Selectors from 'rapidfab/selectors';
import {
  API_RESOURCES,
  BATCHING_TYPES,
  FEATURES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  ROUTES,
  WORK_SCHEDULE_TYPES,
} from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import Alert from 'rapidfab/utils/alert';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { POST_PROCESSOR_TYPE_CONTAINER } from 'rapidfab/constants/forms';
import { REVIEW_TYPES } from 'rapidfab/constants/reviewTypes';
import { weekDays, laborAvailableScheduleDefaultWorkDays } from 'rapidfab/constants/dates';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import _capitalize from 'lodash/capitalize';
import extractUuid from 'rapidfab/utils/extractUuid';
import { getDurationSecondsFromHoursAndMinutes, getHoursAndMinutesFromDurationSecs } from 'rapidfab/utils/isHoursMinutesFormat';
import { FormattedMessage } from 'react-intl';

const PostProcessorTypeContainer = props => {
  const laborsByProcessorType = useSelector(Selectors.getLaborByPostPocessorType);

  const uuid = useSelector(Selectors.getRouteUUID);
  const bureau = useSelector(Selectors.getBureauUri);
  const submitting = useSelector(state => Selectors.getResourceFetching(
    state,
    'nautilus.post-processor-type',
  ));
  const materials = useSelector(Selectors.getMaterials);
  const postProcessorType = useSelector(state => Selectors.getUUIDResource(state, uuid));
  const manufacturers = useSelector(Selectors.getManufacturers);
  const customGroups = useSelector(Selectors.getCustomGroups);
  const isGroupQualificationsFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.GROUP_QUALIFICATIONS));
  const navigate = useNavigate();

  const postProcessorTypeLabor = {
    workstation_duration: laborsByProcessorType[postProcessorType?.uri]?.duration,
    laborType: laborsByProcessorType[postProcessorType?.uri]?.type,
  };

  const initialFormValues = { ...postProcessorType, ...postProcessorTypeLabor };
  initialFormValues.duration = getHoursAndMinutesFromDurationSecs(initialFormValues.duration);
  initialFormValues.workstation_duration = getHoursAndMinutesFromDurationSecs(initialFormValues.workstation_duration);

  const isWorkScheduleFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.WORK_SCHEDULE));
  const workSchedule = useSelector(state => (isWorkScheduleFeatureEnabled ?
    Selectors.getWorkSchedulesForPostProcessorType(state, postProcessorType?.uri) : null));
  const [workScheduleEnabled, setWorkScheduleEnabled] = useState(workSchedule);

  initialFormValues.batching_strategy =
        initialFormValues.batching_strategy || BATCHING_TYPES[0].value;
  POST_PROCESSOR_TYPE_CONTAINER.NULL_FIELDS.forEach(fieldName => {
    if (initialFormValues[fieldName] === null) {
      initialFormValues[fieldName] = '';
    }
  });

  const weekDaysTimeMapping = {};

  // Condition added to prevent actions of block of unused code if the feature is disabled
  if (isWorkScheduleFeatureEnabled) {
    dayjs.extend(objectSupport);
    weekDays.forEach(day => {
      weekDaysTimeMapping[day] = {
        start: dayjs({
          h: 9,
          m: 0,
        }).format('HH:mm'),
        finish: dayjs({
          h: 17,
          m: 0,
        }).format('HH:mm'),
      };
    });
  }

  const [isLaborWorkflowEnabled, setIsLaborWorkflowEnabled] = useState(
    initialFormValues.laborType !== undefined,
  );

  useEffect(() => {
    setIsLaborWorkflowEnabled(initialFormValues.laborType !== undefined);
  }, [initialFormValues.laborType]);

  const [laborScheduleTime, setLaborScheduleTime] = useState(weekDaysTimeMapping);
  const [laborNonStopEnabled, setLaborNonStopEnabled] = useState(false);
  const [workDays, setWorkDays] = useState([]);
  const [UTC_TimeZone, setUTC_TimeZone] = useState(workSchedule?.utc_offset || 0);

  const defaultCurrency = useSelector(Selectors.getBureauDefaultCurrency);
  const fetching =
    useSelector(state => state.ui.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].get.fetching
      || state.ui.nautilus[API_RESOURCES.MATERIAL].list.fetching
      || state.ui.nautilus[API_RESOURCES.MANUFACTURER].list.fetching
      || state.ui.nautilus[API_RESOURCES.CUSTOM_GROUP].list.fetching);

  const initialValues = {};
  Object.keys(initialFormValues)
    .filter(key => POST_PROCESSOR_TYPE_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialValues[key] = initialFormValues[key];
    });

  const selected = {
    bureau,
    uuid,
    initialFormValues: initialValues,
    submitting,
    materials,
    manufacturers,
    customGroups,
    isGroupQualificationsFeatureEnabled,
    defaultCurrency,
    postProcessorType,
    fetching,
    laborScheduleTime,
    laborNonStopEnabled,
    workScheduleEnabled,
    workDays,
    UTC_TimeZone,
  };

  const dispatch = useDispatch();

  const redirect = () => navigate(getRouteURI(ROUTES.POST_PROCESSOR_TYPES, {}, {}, true));

  const onInitialize = (currentBureau, currentUUID, currentPostProcessorTypeURI) => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: currentBureau }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].get(currentUUID));
      if (isWorkScheduleFeatureEnabled && currentPostProcessorTypeURI) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_SCHEDULE].list({
          post_processor_type: currentPostProcessorTypeURI,
        }));
        dispatch(Actions.Api.nautilus[API_RESOURCES.LABOR].list({
          post_processor_type: currentPostProcessorTypeURI,
        }));
      }
    }
  };

  const handleChangeLaborTime = (event, day, type) => {
    const { value } = event.target;

    const parsedValue = dayjs({
      hour: value.split(':')[0],
      minute: value.split(':')[1],
    }).format('HH:mm');

    setLaborScheduleTime(previous => ({
      ...previous,
      [day]: {
        ...previous[day],
        [type]: parsedValue,
      },
    }));
    return true;
  };

  const handleSetLaborNonStopTime = () => setLaborNonStopEnabled(previous => !previous);

  const handleTriggerWorkSchedule = () => setWorkScheduleEnabled(previous => !previous);

  const handleUTC_TimezoneSetting = value => setUTC_TimeZone(value);

  const handleParseLaborPayload = postProcessorTypeURI => {
    const week = Object.entries(laborScheduleTime).map(([day, time]) => {
      const { start, finish } = time;

      // If no such workday is checked -> skip sending to the BE
      if (!workDays.includes(day)) {
        return null;
      }

      // Send the only week: {} contains the days checked as "[x] Workday"
      return {
        [day.toLowerCase()]: {
          start: {
            h: Number(start.split(':')[0]),
            m: Number(start.split(':')[1]),
          },
          finish: {
            h: Number(finish.split(':')[0]),
            m: Number(finish.split(':')[1]),
          },
        },
      };
    }).filter(Boolean);

    const payload = {
      type: WORK_SCHEDULE_TYPES.CALENDAR,
      post_processor_type: postProcessorTypeURI,
      utc_offset: +UTC_TimeZone,
    };

    if (laborNonStopEnabled) {
      payload.type = WORK_SCHEDULE_TYPES.NON_STOP;
    } else {
      payload.week = Object.assign({}, ...week);
    }

    return payload;
  };

  const handleTransformLaborPayload = payload => {
    if (payload.type === WORK_SCHEDULE_TYPES.NON_STOP) {
      return setLaborNonStopEnabled(true);
    }

    // Transform the API data first
    const weekPayloadData = Object.assign({},
      ...Object.entries(payload.week).map(([day, time]) => {
        const { start, finish } = time;

        return {
          [_capitalize(day)]: {
            start: dayjs({
              h: start.h,
              m: start.m,
            }).format('HH:mm'),
            finish: dayjs({
              h: finish.h,
              m: finish.m,
            }).format('HH:mm'),
          },
        };
      }));

    /* Taking into account the BE data could have only particular days and not the whole
       list of the weekdays, we should fill in the rest of the list with the default data in order
       to get all the weekdays and display the API data time and default time so the user could change it. */

    const defaultWeekData = Object.assign({},
      ...Object.entries(weekDaysTimeMapping).map(([day, time]) => {
        const { start, finish } = time;
        if (!Object.keys(weekPayloadData).includes(day)) {
          return {
            [day]: {
              start,
              finish,
            },
          };
        }
        return null;
      }).filter(Boolean));

    /* Combine API days and if it is not all the list of the weekdays
        -> fill in the rest of it by the default data contains of days. */
    const week = { ...weekPayloadData, ...defaultWeekData };

    // Set the work days checked as "[x] Workday" from API.
    setWorkDays(Object.keys(weekPayloadData));
    // Fill in the labor schedule time with all the data (API + default).
    return setLaborScheduleTime(week);
  };

  const handleCreateLaborScheduleTime = postProcessorTypeUri => {
    if (!isWorkScheduleFeatureEnabled || !postProcessorTypeUri) {
      // No need to do any actions, we either do not have URi or the feature is disabled
      return;
    }

    if (workSchedule && !workScheduleEnabled) {
      /* This means we have some data of work schedule but the customer manually
         disabled this component which means we should remove all the work-schedule data
         related to this Post Processor Type (DELETE) */
      dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_SCHEDULE]
        .delete(extractUuid(workSchedule.uri)));
    }

    if (workScheduleEnabled) {
      /* In this case the customer manually set the "Labor Available Schedule" as Enabled
         (clicked on checkbox) */
      const payload = handleParseLaborPayload(postProcessorTypeUri);

      if (workSchedule) {
        /* This means the customer had already created the schedule, and we need to
           update the values on the BE (PUT) */
        dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_SCHEDULE]
          .put(extractUuid(workSchedule.uri), payload));
      } else {
        /* No schedule was created and the customer would like to create the new one
           (previously set some date time or left default values) (POST) */
        dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_SCHEDULE].post(payload));
      }
    }
  };

  const handleSetWorkday = day => {
    if (workDays.includes(day)) {
      setWorkDays(previous => previous.filter(d => d !== day));
    } else {
      setWorkDays(previous => [...previous, day]);
    }
  };

  const createLaborData = (uri, data) => {
    const laborPayload = {
      type: data.laborType,
      duration: getDurationSecondsFromHoursAndMinutes(data.workstation_duration),
      post_processor_type: uri,
    };

    if (isLaborWorkflowEnabled) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LABOR].post(laborPayload));
    }
  };

  const updateLaborData = (uri, data) => {
    const laborPayload = {
      type: data.laborType,
      duration: getDurationSecondsFromHoursAndMinutes(data.workstation_duration),
      post_processor_type: uri,
    };

    if (isLaborWorkflowEnabled) {
      if (laborsByProcessorType[uri]) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.LABOR].put(laborsByProcessorType[uri].uuid, laborPayload));
      } else {
        dispatch(Actions.Api.nautilus[API_RESOURCES.LABOR].post(laborPayload));
      }
    }

    if (!isLaborWorkflowEnabled && laborsByProcessorType[uri]) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LABOR].delete(laborsByProcessorType[uri].uuid));
    }
  };

  const onSubmit = (formValues, shouldRedirect = true) => {
    /* If the user clicked on the (+) button near Labor Available Schedule and had not selected
       either Workday or the main checkbox to work [x] 24/7, we should ask him to check something first. */
    if (workScheduleEnabled && (!workDays.length && !laborNonStopEnabled)) {
      return Alert.error(<FormattedMessage
        id="toaster.error.labor.requireAtLeastOneDay"
        defaultMessage="Labor settings requires that at least one day is selected"
      />);
    }

    const payload = { ...formValues };
    const { laborType, workstation_duration } = payload;

    const laborPayload = { laborType, workstation_duration: laborType === 'labor_to_run' ? payload.duration : workstation_duration };
    payload.duration = getDurationSecondsFromHoursAndMinutes(payload.duration);

    /* After creating payload for labor API request deletes them from original payload */
    delete payload.workstation_duration;
    delete payload.laborType;

    const {
      build_volume,
      batching_strategy,
    } = payload;
    const units = build_volume ? Object.entries(build_volume) : {};

    let hasSmallVolume = null;

    if (!build_volume) {
      _set(payload, 'build_volume', {});
    }

    if (!payload.edit_group) {
      _set(payload, 'edit_group', null);
    }

    if (batching_strategy === 'by_size_xy') {
      hasSmallVolume = units.slice(0, 2).some(([, value]) => Number(value) && Number(value) < 50);
    }

    if (batching_strategy === 'by_size_xyz') {
      hasSmallVolume = units.some(([, value]) => Number(value) && Number(value) < 50);
    }

    if (payload.overhead_cost_per_run) {
      _set(payload, 'overhead_cost_per_run',
        Number.parseFloat(payload.overhead_cost_per_run));
    } else payload.overhead_cost_per_run = 0;

    if (payload.overhead_cost_per_piece_in_run) {
      _set(payload, 'overhead_cost_per_piece_in_run',
        Number.parseFloat(payload.overhead_cost_per_piece_in_run));
    } else payload.overhead_cost_per_piece_in_run = 0;

    if (hasSmallVolume) {
      Alert.warning(
        <FormattedMessage
          id="toaster.warning.batchingStrategyMayPreventPostProcessingRuns"
          defaultMessage="Warning: Your chosen Batching Strategy settings may prevent Post Processing Runs from being created. Please review your settings carefully."
        />);
    }

    POST_PROCESSOR_TYPE_CONTAINER.FLOAT_FIELDS.forEach(
      fieldName => {
        const field = _get(payload, fieldName);
        if (field) {
          _set(payload, fieldName, Number.parseFloat(field));
        }
      },
    );

    POST_PROCESSOR_TYPE_CONTAINER.NULL_FIELDS.forEach(fieldName => {
      const field = _get(payload, fieldName);
      if (field === '') {
        _set(payload, fieldName, null);
      }
    });
    // editing existing post processor type
    if (payload.uuid) {
      delete payload.special_type; // the field is not updatable
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].put(payload.uuid, payload),
      ).then(() => handleCreateLaborScheduleTime(postProcessorType?.uri))
        .then(() => Alert.success(
          <FormattedMessage
            id="toaster.postProcessorType.updated"
            defaultMessage="Post Processor Type {uuid} successfully updated."
            values={{ uuid: payload.uuid }}
          />,
        ))
        .then(() => updateLaborData(postProcessorType.uri, laborPayload));
      // creating new post processor type
    } else {
      // preventing special_type from being send without value
      if (payload.special_type !== REVIEW_TYPES.NON_CONFORMANCE_REVIEW) {
        delete payload.special_type;
      }
      dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].post(payload))
        // BE will return postProcessorType URI as a part of the response headers (location)
        .then(response => {
          handleCreateLaborScheduleTime(response?.headers?.location);
          createLaborData(response?.headers?.location, laborPayload);

          Alert.success(
            <FormattedMessage
              id="toaster.postProcessorType.created"
              defaultMessage="Post Processor Type successfully created."
            />,
          );

          if (response && shouldRedirect) {
            window.location =
              getRouteURI(ROUTES.POST_PROCESSOR_TYPE_EDIT, { uuid: extractUuid(response.headers.location) });
          }
        });
    }
    return true;
  };

  const onDelete = currentUUID => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].delete(currentUUID)).then(
        redirect,
      );
    }
  };
  const onArchive = currentUUID => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].delete(currentUUID))
        .then(() => {
          dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].get(currentUUID));
        })
        .then(() => Alert.success(
          <FormattedMessage
            id="toaster.postProcessorType.archived"
            defaultMessage="Post Processor Type {uuid} successfully archived."
            values={{ uuid: currentUUID }}
          />,
        ))
        .then(redirect);
    }
  };
  const onUnarchive = formValues => {
    onSubmit({ ...formValues, archived: null }, false);
  };
  const onSelectAllMaterials = (args, state) => state?.fields.materials.change(materials.map(material => material.uri));

  const onDeselectAllMaterials = (args, state) => state?.fields.materials.change([]);

  useEffect(() => {
    onInitialize(bureau, uuid, postProcessorType?.uri);
  }, [bureau, uuid, postProcessorType]);

  useEffect(() => {
    if (workSchedule) {
      /* If we have work-schedule data on BE, we should transform its values
         to access them in our component. */
      setUTC_TimeZone(workSchedule?.utc_offset);
      handleTransformLaborPayload(workSchedule);
      setWorkScheduleEnabled(true);
    } else {
      /* If we don't have a work-schedule, by default workdays should be Mon-Fri
      9am-5pm. */
      setWorkDays(laborAvailableScheduleDefaultWorkDays);
    }
  }, [workSchedule]);

  if (fetching) {
    return <Loading />;
  }

  return (

    <PostProcessorType
      {...props}
      {...selected}
      isEditing={!!uuid}
      onInitialize={onInitialize}
      onDelete={onDelete}
      onArchive={onArchive}
      onUnarchive={onUnarchive}
      handleSubmit={onSubmit}
      onSubmit={onSubmit}
      onSelectAllMaterials={onSelectAllMaterials}
      onDeselectAllMaterials={onDeselectAllMaterials}
      laborWorkstationEnable={() => {
        setIsLaborWorkflowEnabled(!isLaborWorkflowEnabled);
      }}
      isLaborWorkflowEnabled={isLaborWorkflowEnabled}
      handleChangeLaborTime={handleChangeLaborTime}
      handleSetLaborNonStopTime={handleSetLaborNonStopTime}
      handleTriggerWorkSchedule={handleTriggerWorkSchedule}
      handleUTC_TimezoneSetting={handleUTC_TimezoneSetting}
      isWorkScheduleFeatureEnabled={isWorkScheduleFeatureEnabled}
      handleSetWorkday={handleSetWorkday}
    />

  );
};

export default PostProcessorTypeContainer;
