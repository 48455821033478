import React, { useEffect, useState } from 'react';
import { Button, FormControl, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import FormRow from 'rapidfab/components/FormRow';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import extractUuid from 'rapidfab/utils/extractUuid';
import CancelOrDeleteModal from 'rapidfab/components/CancelOrDeleteModal';
import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import _isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';
import TableCellLabels from 'rapidfab/components/TableCellLabels';

const ViewCartModal = ({
  shoppingCart,
  shoppingCartItems,
  modelsByUri,
  labelsByUri,
  show,
  onClose,
  initialFormValues,
  handleSaveCartOrder,
  isFetchingShoppingCart,
  handleDeleteCartItem,
  handleAbandonCart,
  handleSaveCart,
}) => {
  const modelLibraries = useSelector(Selectors.getModelLibraries);
  const modelLibrariesByUri = _keyBy(modelLibraries, 'uri');

  const [showConfirmDeleteCartItemModal, setShowConfirmDeleteCartItemModal] = useState(false);
  const [updatedShoppingCartItemsState, setUpdatedShoppingCartItemsState] = useState([]);
  const [deletedCartItemUri, setDeletedCartItemUri] = useState('');

  const isNoShoppingCartItems = _isEmpty(shoppingCartItems);

  useEffect(() => {
    setUpdatedShoppingCartItemsState(shoppingCartItems);
  }, [shoppingCartItems]);

  return (
    <Modal size="lg" show={show} onHide={onClose} backdrop="static">
      <Form
        onSubmit={() => handleSaveCartOrder(shoppingCart?.uri)}
        initialValues={initialFormValues}
        render={({ handleSubmit, values }) => (
          <>
            <Modal.Header onHide={onClose} closeButton>
              <FormattedMessage
                id="modelLibrary.shoppingCart.viewCart"
                defaultMessage="View Cart"
              />
            </Modal.Header>
            <Modal.Body>
              <div
                className="d-flex justify-content-between mb15 align-items-start"
              >
                <h1>
                  Shopping Cart
                </h1>
                <div
                  className="d-flex"
                >
                  <OverlayTrigger
                    overlay={(
                      <Tooltip>
                        <FormattedMessage
                          id="modelLibrary.shoppingCart.abandonCart"
                          defaultMessage="Abandon Cart"
                        />
                      </Tooltip>
                    )}
                  >
                    <Button
                      bg="danger"
                      variant="danger"
                      onClick={() => handleAbandonCart(shoppingCart?.uri)}
                    >
                      {isFetchingShoppingCart ?
                        <Loading /> :
                        <FontAwesomeIcon icon={faTimesCircle} />}
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    overlay={(
                      <Tooltip>
                        Save Shopping Cart
                      </Tooltip>
                    )}
                  >
                    <Button
                      className="spacer-left"
                      onClick={() => handleSaveCart(shoppingCart?.uri, updatedShoppingCartItemsState, values)}
                      disabled={_isEqual(shoppingCartItems, updatedShoppingCartItemsState)
                        && _isEqual(initialFormValues.order_name, values.order_name)}
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </Button>
                  </OverlayTrigger>
                </div>
              </div>
              <Field
                name="order_name"
                render={({ input }) => (
                  <FormRow id="orderName" defaultMessage="Order Name">
                    <FormControl
                      type="text"
                      value={input.value}
                      onChange={event => {
                        input.onChange(event);
                      }}
                    />
                  </FormRow>
                )}
              />
              <div
                className="card-list mb30"
                style={{ overflowY: 'auto' }}
              >
                {isNoShoppingCartItems && (
                  <p>Shopping cart is currently empty.</p>
                )}
                {shoppingCartItems.map((cartItem, cartItemIndex) => {
                  const cartItemModelLibrary = modelLibrariesByUri[cartItem.source];
                  const cartItemModelLibraryModel = modelsByUri[cartItemModelLibrary?.additive.model];

                  return (
                    <div
                      style={{ maxWidth: 600 }}
                      className="card"
                      key={cartItem.uri}
                      role="button"
                      tabIndex={0}
                    >
                      <img className="card-img-top" src={cartItemModelLibraryModel?.snapshot_content} alt="" />
                      <div className="card-body d-flex align-items-center justify-content-between">
                        <h5 className="card-title" style={{ maxWidth: '80px' }}>{cartItemModelLibrary?.name}</h5>
                        {
                          cartItem && cartItem.labels && cartItem.labels.length > 0 && (
                            <div className="spacer-top">
                              <TableCellLabels
                                labelUris={cartItem.labels}
                                labelsByUri={labelsByUri}
                              />
                            </div>
                          )
                        }
                        <Field
                          name={`${extractUuid(cartItem.uri)}_quantity`}
                          initialValue={cartItem.quantity}
                          render={({ input }) => (
                            <FormControl
                              onChange={event => {
                                input.onChange(event);

                                const newShoppingCartItemsState = [
                                  ...updatedShoppingCartItemsState,
                                ];

                                const newShoppingCartItem = {
                                  ...newShoppingCartItemsState[cartItemIndex],
                                  quantity: Number(event.target.value),
                                };

                                newShoppingCartItemsState[cartItemIndex] = newShoppingCartItem;

                                // Set new local state on what the user has set the quantity to.
                                setUpdatedShoppingCartItemsState(newShoppingCartItemsState);
                              }}
                              style={{ maxWidth: 70 }}
                              className="ml10"
                              size="sm"
                              type="number"
                              min={1}
                              defaultValue={cartItem.quantity}
                            />
                          )}
                        />
                        {showConfirmDeleteCartItemModal && (

                          <CancelOrDeleteModal
                            modalType="delete"
                            handleConfirm={() => {
                              handleDeleteCartItem(deletedCartItemUri);
                              setDeletedCartItemUri('');
                            }}
                            /* handleOpen actually handles the onClose */
                            handleOpen={() => setShowConfirmDeleteCartItemModal(false)}
                          />

                        )}
                        <Button
                          variant="danger"
                          bg="danger"
                          className="ml0"
                          onClick={() => {
                            setDeletedCartItemUri(cartItem.uri);
                            setShowConfirmDeleteCartItemModal(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                bg="danger"
                variant="danger"
                onClick={onClose}
              >
                <FormattedMessage
                  id="button.close"
                  defaultMessage="Close"
                />
              </Button>
              <Button
                bg="success"
                variant="success"
                className="spacer-left"
                onClick={handleSubmit}
                disabled={isFetchingShoppingCart
                || isNoShoppingCartItems}
              >
                {isFetchingShoppingCart ?
                  <Loading /> : (
                    <FormattedMessage
                      id="modelLibrary.createOrder"
                      defaultMessage="Create Order"
                    />
                  )}
              </Button>
              {/* </div> */}
            </Modal.Footer>
          </>
        )}
      />
    </Modal>
  );
};

export default ViewCartModal;

ViewCartModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialFormValues: PropTypes.shape({
    order_name: PropTypes.string,
  }).isRequired,
  handleSaveCartOrder: PropTypes.func.isRequired,
  shoppingCartItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modelsByUri: PropTypes.shape({}).isRequired,
  shoppingCart: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
  labelsByUri: PropTypes.shape({}).isRequired,
  modelLibrariesByUri: PropTypes.shape({}).isRequired,
  isFetchingShoppingCart: PropTypes.bool.isRequired,
  handleDeleteCartItem: PropTypes.func.isRequired,
  handleAbandonCart: PropTypes.func.isRequired,
  handleSaveCart: PropTypes.func.isRequired,
};
