import { BUILD_PACKER_TYPES } from 'rapidfab/constants';
import _sumBy from 'lodash/sumBy';

function calculate2dBoundingBoxVolume(models = [], printerType) {
  const { min_packing_distance: spacing } = printerType;

  return _sumBy(
    models,
    model => {
      const { x, y } = model.size;
      return (x + spacing) * (y + spacing);
    },
  );
}

/**
 * Simplified packing calculations for 2D Pack printer.
 * Uses `spacing` on each side of the model (X,Y) to reduce amount of false-fit cases
 * More complex calculation is done on the backend
 *
 * @param models [] List of models
 * @param printerType {build_volume, min_packing_distance} Printer type entity
 * @returns number
 */
export function calculate2dPack(models, printerType) {
  const { x: buildSizeX, y: buildSizeY } = printerType.build_volume;

  const buildVolume = buildSizeX * buildSizeY;
  const boundingVolume = calculate2dBoundingBoxVolume(models, printerType);

  return boundingVolume / buildVolume;
}

function calculate3dVolume(models = []) {
  return _sumBy(models, 'volume_mm');
}

function calculate3dBoundingBoxVolume(models = [], printerType) {
  const { min_packing_distance: spacing } = printerType;

  return _sumBy(
    models,
    model => {
      const { x, y, z } = model.size;
      return (x + spacing) * (y + spacing) * (z + spacing);
    },
  );
}

/**
 * Simplified packing calculations for 3D Pack printer.
 * Uses `spacing` on each side of the model (X,Y,Z) to show maximum `High` percentage possible.
 * More complex calculation is done on the backend
 *
 * @param models [] List of models
 * @param printerType {build_volume, min_packing_distance} Printer type entity
 * @returns {{high: number, low: number}}
 */
export function calculate3dPack(models, printerType) {
  const { x: buildSizeX, y: buildSizeY, z: buildSizeZ } = printerType.build_volume;

  const buildVolume = buildSizeX * buildSizeY * buildSizeZ;
  const modelVolume = calculate3dVolume(models);
  const boundingVolume = calculate3dBoundingBoxVolume(models, printerType);

  return {
    low: modelVolume / buildVolume,
    high: boundingVolume / buildVolume,
  };
}

export function getRawAndFormattedFillPercentage(models, printerType) {
  if (!printerType) {
    return {};
  }

  if (printerType.build_packer_type === BUILD_PACKER_TYPES.PACK3D
    || printerType.build_packer_type === BUILD_PACKER_TYPES.PACK3D_PREMIUM) {
    const { low, high } = calculate3dPack(models, printerType);
    const lowPercent = (low * 100).toFixed(2);
    const highPercent = (high * 100).toFixed(2);
    // highPercent is returned as value so that user can be warned of overfill
    // at upper bound (i.e. if range is 75-110%, we want to warn the user)
    return { formatted: `${lowPercent}% - ${highPercent}%`, value: highPercent };
  }

  const bedFill = calculate2dPack(models, printerType);
  const bedFillPercent = (bedFill * 100).toFixed(2);
  return { formatted: `${bedFillPercent}%`, value: bedFillPercent };
}
