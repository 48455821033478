import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _map from 'lodash/map';
import Actions from 'rapidfab/actions';
import { Form, FormGroup, FormLabel, FormControl, Row } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getLocationFilter, getLocations } from 'rapidfab/selectors';
import { LOCATION_FILTER_DEFAULT_VALUES } from 'rapidfab/constants';

const Locations = ({
  locationFilter,
  locations,
  handleLocationStateChange,
  intl,
  disabled,
}) => {
  if (!locations.length) return null;
  // Temporary fix to hide the Location Filter on Shipments page while the BE's side is not ready.
  if (window.location.hash.includes('#/manage/shipments')) return null;

  // `null` is ignored when used in `<option value=`. Adding this hack to handle null values
  const defaultValue =
    locationFilter === null
      ? LOCATION_FILTER_DEFAULT_VALUES.UNASSIGNED
      : locationFilter || LOCATION_FILTER_DEFAULT_VALUES.ALL;

  return (
    <Form>
      <FormGroup as={Row} controlId="location">
        <FormLabel column style={{ flex: 0 }}>
          <FormattedMessage id="field.location" defaultMessage="Location" />
        </FormLabel>{' '}
        <FormControl
          className="truncate-data"
          onChange={event => {
            // Sending `null` when `unassigned` value changed
            const value =
              event.target.value === LOCATION_FILTER_DEFAULT_VALUES.UNASSIGNED
                ? null
                : event.target.value;
            handleLocationStateChange(value);
          }}
          defaultValue={defaultValue}
          as="select"
          disabled={disabled}
        >
          <option key="placeholder" value={LOCATION_FILTER_DEFAULT_VALUES.ALL}>
            {intl.formatMessage({
              id: 'all',
              defaultMessage: 'All',
            })}
          </option>
          {_map(locations, location => (
            <option key={location.uri} value={location.uri}>
              {location.name}
            </option>
          ))}
          <option
            key="unassigned"
            value={LOCATION_FILTER_DEFAULT_VALUES.UNASSIGNED}
          >
            {intl.formatMessage({
              id: 'field.location.unassigned',
              defaultMessage: 'Unassigned',
            })}
          </option>
        </FormControl>
      </FormGroup>
    </Form>
  );
};

Locations.defaultProps = {
  locationFilter: undefined,
  disabled: false,
};

Locations.propTypes = {
  locationFilter: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleLocationStateChange: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
};

const mapStateToProps = state => {
  const locationFilter = getLocationFilter(state);
  const locations = getLocations(state);
  return {
    locationFilter,
    locations,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    handleLocationStateChange: location => {
      // On each Location Filter change, clear the current Sub-Location state
      dispatch(Actions.LocationFilter.setSubLocation(null));
      dispatch(Actions.LocationFilter.setLocation(location));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Locations));
