import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import { PRIORITIES, FEATURES, MATERIAL_MODES } from 'rapidfab/constants';
import {
  Row,
  Col,
  FormGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Form,
  Button,
  Badge,
} from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedDateTime, FormattedDuration, FormattedMessage } from 'rapidfab/i18n';
import Feature from 'rapidfab/components/Feature';
import PriorityLabel from './PriorityLabel';
import PrimaryRunDurationModal from '../../modals/PrimaryRunDurationModal';
import hhmmss from '../../../utils/hhmmss';

const ActivePiecesMetaData = ({
  pieces,
  onChangeRunName,
  name,
  priority,
  onToggleOverrideBuildPlateChecks,
  isBuildPlateExceeded,
  overrideBuildPlateChecks,
  formattedFillPercentage,
  startDate,
  dueDate,
  primaryRunDuration,
  onChangePrintTime,
  printer,
}) => {
  const [showPrintTimeModal, setShowPrintTimeModal] = React.useState(false);
  const materialNames = _uniq(_map(pieces, 'material_name'));
  const materialMessage = materialNames.length === 1 ? materialNames[0] : 'Multiple';
  const isMoreThanOneMaterial = materialNames.length > 1;

  const layerThicknesses = _uniq(_map(pieces, 'layer_thickness'));
  const layerThicknessMessage = layerThicknesses.length === 1 ? layerThicknesses[0] : 'Multiple';
  const isMoreThanOneLayerThickness = layerThicknesses.length > 1;

  return (
    <>
      <PrimaryRunDurationModal
        show={showPrintTimeModal}
        primaryRunDuration={hhmmss(primaryRunDuration, true)}
        onHide={() => setShowPrintTimeModal(false)}
        submit={values => {
          onChangePrintTime(values.time);
          setShowPrintTimeModal(false);
        }}
      />
      <Row className="mb8 d-flex align-items-center">
        <Col xs={6}>
          <div>Run Name</div>
          <FormGroup size="small">
            <FormControl
              type="text"
              value={name}
              onChange={onChangeRunName}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <div>
            <b className="spacer-right">
              <FormattedMessage id="record.run.piecesInPrimaryRun" defaultMessage="Pieces in Primary Run" />:
            </b>
            {pieces.length}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Feature featureName={FEATURES.PRIORITY}>
            <div className="mb8 d-flex">
              <b className="spacer-right">
                <FormattedMessage id="field.priority" defaultMessage="Priority" />:
              </b>
              {
                _isEmpty(pieces)
                  ? <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                  : <PriorityLabel labelOnly value={priority} labelClassName="spacer-left" />
              }
            </div>
          </Feature>
          <div className="mb8 d-flex align-items-center">
            <b className="spacer-right">
              <FormattedMessage id="field.material" defaultMessage="Material" />:
            </b>
            {
              _isEmpty(materialNames)
                ? <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                : materialMessage
            }
            {
              (isMoreThanOneMaterial && printer?.printer_type?.material_mode !== MATERIAL_MODES.MULTI_MATERIAL) && (
                <OverlayTrigger
                  placement="right"
                  overlay={(
                    <Tooltip>
                      <FormattedMessage
                        id="record.run.multiMaterialWarn"
                        defaultMessage="This printer may not support multiple materials"
                      />
                    </Tooltip>
                  )}
                >
                  <Fa name="exclamation-circle" className="spacer-left" />
                </OverlayTrigger>
              )
            }

          </div>
          { printer?.printer_type?.material_mode === MATERIAL_MODES.MULTI_MATERIAL && (
            <div className="mb8">
              <b className="spacer-right">
                <Badge className="badge badge-sm" pill text="light" bg="primary">
                  <FormattedMessage className="" id="record.run.materialMode" defaultMessage="This Printer supports Multiple Materials." />
                </Badge>
              </b>

            </div>
          )}
          <div className="mb8 d-flex align-items-center">
            <b className="spacer-right">
              <FormattedMessage id="field.layer_thickness" defaultMessage="Layer Thickness" />:
            </b>
            {
              _isEmpty(layerThicknesses)
                ? <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                : layerThicknessMessage
            }
            {
              isMoreThanOneLayerThickness && (
                <OverlayTrigger
                  placement="right"
                  overlay={(
                    <Tooltip>
                      <FormattedMessage
                        id="record.run.multiLayerThicknessWarn"
                        defaultMessage="This printer may not support multiple layer thicknesses"
                      />
                    </Tooltip>
                  )}
                >
                  <Fa name="exclamation-circle" className="spacer-left" />
                </OverlayTrigger>
              )
            }
          </div>

        </Col>

        <Col xs={6}>
          <div className="mb8">
            <b className="spacer-right">
              <FormattedMessage
                id="record.run.estStartDate"
                defaultMessage="Est. Start Date"
              />:
            </b>
            {
              (pieces.length > 0)
                /* TODO: replace currentTime() */
                ? <FormattedDateTime value={startDate} />
                : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
            }
          </div>
          <div className="mb8">
            <b className="spacer-right">
              <FormattedMessage
                id="record.run.estDueDate"
                defaultMessage="Est. Due Date"
              />:
            </b>
            {
              (pieces.length > 0)
                ? <FormattedDateTime value={dueDate} />
                : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
            }
          </div>
          <div className="mb8">
            <b className="spacer-right">
              <FormattedMessage id="record.run.estDuration" defaultMessage="Est. Duration" />:
            </b>
            {
              (pieces.length > 0)
                ? (
                  <>
                    <FormattedDuration value={primaryRunDuration} />
                    {' '}
                    <Feature featureName={FEATURES.USER_CAN_UPDATE_ESTIMATES}>
                      <Button size="xs" onClick={() => setShowPrintTimeModal(true)}>
                        Edit
                      </Button>
                    </Feature>
                  </>
                )
                : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
            }
          </div>
          <div className="mb8 d-flex flex-direction-column">
            <div>
              <b className="spacer-right">
                <FormattedMessage id="record.run.estBuildFill" defaultMessage="Est. Build Fill" />:
              </b>
              {
                formattedFillPercentage || <FormattedMessage id="notAvailable" defaultMessage="N/A" />
              }
              { isBuildPlateExceeded && <Fa name="exclamation-circle" className="spacer-left" /> }
            </div>
            <Form.Check
              className="spacer-top"
              name="build-fill-warning"
              checked={overrideBuildPlateChecks}
              onChange={onToggleOverrideBuildPlateChecks}
              type="checkbox"
              label={
                isBuildPlateExceeded ? (
                  <FormattedMessage
                    id="record.run.buildFillCheckboxMsg"
                    defaultMessage="Build fill may exceed printer's size limits. Check this box to create this run anyways."
                  />
                ) : (
                  <FormattedMessage
                    id="record.run.overrideChecks"
                    defaultMessage="Override printer size checks"
                  />
                )
              }
            />

          </div>
        </Col>
      </Row>
    </>
  );
};

ActivePiecesMetaData.defaultProps = {
  onChangeRunName: () => true,
  onChangePrintTime: () => true,
  priority: PRIORITIES.NORMAL,
  formattedFillPercentage: '',
};

ActivePiecesMetaData.propTypes = {
  pieces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  priority: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChangeRunName: PropTypes.func,
  isBuildPlateExceeded: PropTypes.bool.isRequired,
  overrideBuildPlateChecks: PropTypes.bool.isRequired,
  onToggleOverrideBuildPlateChecks: PropTypes.func.isRequired,
  formattedFillPercentage: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,
  primaryRunDuration: PropTypes.number.isRequired,
  onChangePrintTime: PropTypes.func,
  printer: PropTypes.shape({ printer_type: { material_mode: PropTypes.string } }).isRequired,
};

export default ActivePiecesMetaData;
