import React from 'react';
import PropTypes from 'prop-types';

import FontAwesome from 'react-fontawesome';
import { FormattedDateTime, FormattedMessage } from 'rapidfab/i18n';
import {
  Button,
  Col,
  FormLabel,
  Form,
  FormControl,
  FormGroup,
  Container,
  FormText,
  Card,
  Row,
  Table,
} from 'react-bootstrap';

import extractUuid from 'rapidfab/utils/extractUuid';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import { RUN_OPERATION_MAP } from 'rapidfab/mappings';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import { Link } from 'react-router-dom';
import { ROUTES, RUN_OPERATIONS } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';

const getQueueHeader = operation => {
  switch (operation) {
    case RUN_OPERATIONS.POST_PROCESSING:
      return 'Post Processor Queue';
    case RUN_OPERATIONS.SHIPPING:
      return 'Shipping Queue';
    default:
      return 'Printer Queue';
  }
};

const RunRecordSchedule = ({
  currentStart,
  currentFinish,
  handleBack,
  handleInputChange,
  handleSubmit,
  id,
  isStartValid,
  isFinishValid,
  operation,
  estimateQueue,
  startDate,
  startTime,
  finishDate,
  finishTime,
  submitting,
  uri,
  runEstimatesKeyedByRunUri,
}) => (
  <Container>
    <BreadcrumbNav breadcrumbs={['runs', getShortUUID(id)]} />
    <div className="page-header">
      Rescheduling{' '}
      {operation ? <FormattedMessage {...RUN_OPERATION_MAP[operation]} /> : ''}{' '}
      Run
    </div>
    <Button onClick={() => handleBack(uri)} style={{ marginBottom: '3rem' }}>
      <FontAwesome name="arrow-left" /> Back to Run
    </Button>
    <Row>
      <Col xs={12} lg={6}>
        <Card bg="dark">
          <Card.Header className="pd-exp inverse">{getQueueHeader(operation)}</Card.Header>
          <div className="card-body-wrapper">
            <Card.Body>
              {/* fill commented */}
              <Table responsive>
                <thead>
                  <tr>
                    <th>Run ID</th>
                    <th>Start</th>
                    <th>End</th>
                  </tr>
                </thead>
                <tbody>
                  {estimateQueue.map(runEvent => {
                    const runEstimate = runEstimatesKeyedByRunUri[runEvent.uri];
                    const estimates = runEvent.estimates || (runEstimate && runEstimate.estimates);
                    return (
                      <tr key={runEvent.uri}>
                        <td>
                          <Link
                            to={getRouteURI(ROUTES.RUN_EDIT, { uuid: extractUuid(runEvent.uri) }, {}, true)}
                          >
                            {getShortUUID(runEvent.uri)}
                          </Link>
                        </td>
                        <td>
                          {estimates && estimates.start ? (
                            <FormattedDateTime value={estimates.start} />
                          ) : (
                            <FormattedMessage
                              id="notAvailable"
                              defaultMessage="N/A"
                            />
                          )}
                        </td>
                        <td>
                          {estimates && estimates.end ? (
                            <FormattedDateTime value={estimates.end} />
                          ) : (
                            <FormattedMessage
                              id="notAvailable"
                              defaultMessage="N/A"
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </div>
        </Card>
      </Col>
      <Col xs={12} lg={6}>
        <Card bg="dark" className="p-a">
          <div>
            <strong>Current Start:</strong>{' '}
            {currentStart ? (
              <FormattedDateTime value={currentStart} />
            ) : (
              <FormattedMessage id="notAvailable" defaultMessage="N/A" />
            )}
          </div>
          <div>
            <strong>Current Finish:</strong>{' '}
            {currentFinish ? (
              <FormattedDateTime value={currentFinish} />
            ) : (
              <FormattedMessage id="notAvailable" defaultMessage="N/A" />
            )}
          </div>
          <hr />
          <Form onSubmit={handleSubmit}>
            <FormGroup
              controlId="uxName"
              validationState={isStartValid ? null : 'error'}
            >
              <FormLabel>New Start Date</FormLabel>
              <FormControl
                name="startDate"
                onChange={handleInputChange}
                required
                type="date"
                value={startDate}
              />
            </FormGroup>
            <FormGroup
              controlId="uxName"
              validationState={isStartValid ? null : 'error'}
            >
              <FormLabel>New Start Time</FormLabel>
              <FormControl
                name="startTime"
                onChange={handleInputChange}
                required
                type="time"
                value={startTime}
              />
              {!isStartValid && (
                <FormText>
                  <FormattedMessage
                    id="validateStartTime"
                    defaultValue="Must begin in the future"
                  />
                </FormText>
              )}
            </FormGroup>
            <hr />
            <FormGroup
              controlId="uxName"
              validationState={isFinishValid ? null : 'error'}
            >
              <FormLabel>New Finish Date</FormLabel>
              <FormControl
                name="finishDate"
                onChange={handleInputChange}
                required
                type="date"
                value={finishDate}
              />
            </FormGroup>
            <FormGroup
              controlId="uxName"
              validationState={isFinishValid ? null : 'error'}
              className="mb15"
            >
              <FormLabel>New Finish Time</FormLabel>
              <FormControl
                name="finishTime"
                onChange={handleInputChange}
                required
                type="time"
                value={finishTime}
              />
              {!isFinishValid && (
                <FormText>
                  <FormattedMessage
                    id="validateRunFinishTime"
                    defaultValue="Must be later than the run's start date."
                  />
                </FormText>
              )}
            </FormGroup>
            <Button
              block
              disabled={!isStartValid || !isFinishValid}
              type="submit"
            >
              {submitting ? <Loading /> : 'Reschedule Run'}
            </Button>
          </Form>
        </Card>
      </Col>
    </Row>
  </Container>
);

RunRecordSchedule.defaultProps = {
  currentStart: null,
  currentFinish: null,
};

RunRecordSchedule.propTypes = {
  currentStart: PropTypes.string,
  currentFinish: PropTypes.string,
  handleBack: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isStartValid: PropTypes.bool.isRequired,
  isFinishValid: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  operation: PropTypes.string.isRequired,
  estimateQueue: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  startDate: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  finishDate: PropTypes.string.isRequired,
  finishTime: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  uri: PropTypes.string.isRequired,
  runEstimatesKeyedByRunUri: PropTypes.objectOf(
    PropTypes.shape({
      estimates: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
      }).isRequired,
    }),
  ).isRequired,
};

export default RunRecordSchedule;
