import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import {
  Button,
  FormLabel,
  Form,
  FormControl,
  FormGroup,
  FormText,
} from 'react-bootstrap';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import Fa from 'react-fontawesome';
import { COLORS } from 'rapidfab/constants';

const DowntimeForm = ({
  description,
  downtime,
  finishDate,
  finishTime,
  handleInputChange,
  handleSubmit,
  isFinishValid,
  initialValues,
  startDate,
  startTime,
  isStartValid,
  pastTimeConfirmation,
  handlePastValue,
}) => (
  <div>
    <h2 className="wrap-text">
      {downtime
        ? `Editing ${initialValues.description}`
        : 'Create New Downtime'}
    </h2>
    <Form onSubmit={handleSubmit}>
      <FormGroup controlId="uxDescription">
        <FormLabel>
          <FormattedMessage
            id="field.description"
            defaultMessage="Description"
          />
        </FormLabel>
        <FormControl
          name="description"
          onChange={handleInputChange}
          required
          type="text"
          value={description}
        />
      </FormGroup>

      <hr />
      <FormGroup
        controlId="uxStartDate"
        // validationState={isStartValid ? null : 'error'}
      >
        <FormLabel>Start Date</FormLabel>
        <FormControl
          name="startDate"
          onChange={handleInputChange}
          required
          type="date"
          value={startDate}
        />
      </FormGroup>

      <FormGroup
        controlId="uxStartTime"
        // validationState={isStartValid ? null : 'error'}
      >
        <FormLabel>Start Time</FormLabel>
        <FormControl
          name="startTime"
          onChange={handleInputChange}
          required
          type="time"
          value={startTime}
        />
        {
          pastTimeConfirmation && (
            <ConfirmationModal
              handleCancel={() => handlePastValue(false)}
              handleConfirm={() => handlePastValue(true)}
              message="Warning: you have selected a date in the past. Would you like to continue with this date?"
            />
          )
        }
        {!isStartValid && (
          <FormText>
            <Fa className="spacer-left spacer-right" name="warning" style={{ color: COLORS.ORANGE }} />
            <FormattedMessage
              id="validateStartTimeDowntimes"
              defaultValue="Start Date is in the past"
            />
          </FormText>
        )}
      </FormGroup>

      <hr />
      <FormGroup
        controlId="uxFinishDate"
        validationState={isFinishValid ? null : 'error'}
      >
        <FormLabel>End Date</FormLabel>
        <FormControl
          name="finishDate"
          onChange={handleInputChange}
          required
          type="date"
          value={finishDate}
        />
      </FormGroup>

      <FormGroup
        controlId="uxFinishTime"
        validationState={isFinishValid ? null : 'error'}
      >
        <FormLabel>End Time</FormLabel>
        <FormControl
          name="finishTime"
          onChange={handleInputChange}
          required
          type="time"
          value={finishTime}
        />
        {!isFinishValid && (
          <FormText>
            <FormattedMessage
              id="validateFinishTime"
              defaultMessage="Must begin after the start time"
            />
          </FormText>
        )}
      </FormGroup>

      <hr />

      <Button block disabled={!isFinishValid} type="submit">
        {downtime ? 'Update Downtime' : 'Create New Downtime'}
      </Button>
    </Form>
  </div>
);

DowntimeForm.defaultProps = {
  downtime: null,
  initialValues: null,
};

DowntimeForm.propTypes = {
  description: PropTypes.string.isRequired,
  downtime: PropTypes.string,
  finishDate: PropTypes.string.isRequired,
  finishTime: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    description: PropTypes.string,
  }),
  isFinishValid: PropTypes.func.isRequired,
  isStartValid: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  pastTimeConfirmation: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Object),
  ]).isRequired,
  handlePastValue: PropTypes.func.isRequired,
};

export default DowntimeForm;
