import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import {
  Button, Col, FormLabel,
  FormControl,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle, OverlayTrigger, Row, Tooltip,
} from 'react-bootstrap';
import Fa from 'react-fontawesome';
import _upperFirst from 'lodash/upperFirst';
import _startCase from 'lodash/startCase';
import { FormattedMessage } from 'react-intl';
import { FormControlSelect, FormControlTextAreaWithCustomHandlers } from 'rapidfab/components/formTools';
import PositionTable from 'rapidfab/components/manage/PositionTable';
import PrepTaskModalContainer from 'rapidfab/containers/manage/PrepTaskModalContainer';
import getEndpointFromURI from 'rapidfab/utils/getEndpointFromURI';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';

import { Form, Field } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import { finalFormInputTypes } from 'rapidfab/types';

const mutators = {};

const ADD_NEW_TASK = 'add-new-task';
const PREP_WORKFLOW_ACTIONS_VERBOSE = {
  CREATE: 'Create',
  EDIT: 'Edit',
  CUSTOMIZE: 'Customize',
};

const MODALS = {
  MAIN: 'main',
  DELETE_CONFIRMATION: 'delete-confirmation',
  PREP_TASK_EDIT: 'prep-task-edit',
};

const PrepWorkflowModal = ({
  currentActivePrepTaskIndex,
  onClose,
  isLoading,
  isSubmitting,
  prepWorkflow,
  onFormSubmit,
  prepTasks,
  customizeForUri,
  initialFormValues,
  withDeleteConfirmation,
}) => {
  const [visibleModal, setVisibleModal] = useState(MODALS.MAIN);
  const [selectedNewTask, setSelectedNewTasks] = useState(ADD_NEW_TASK);
  const [currentModalPrepTaskIndex, setCurrentModalPrepTaskIndex] = useState(null);

  const customizeForResource =
    useMemo(() => customizeForUri && getEndpointFromURI(customizeForUri), [customizeForUri]);

  const getWorkflowVerboseAction = () => {
    if (customizeForUri) {
      return PREP_WORKFLOW_ACTIONS_VERBOSE.CUSTOMIZE;
    }
    if (prepWorkflow) {
      return PREP_WORKFLOW_ACTIONS_VERBOSE.EDIT;
    }
    return PREP_WORKFLOW_ACTIONS_VERBOSE.CREATE;
  };
  const workflowVerboseAction = getWorkflowVerboseAction();

  const showPrepTaskModal = prepTaskIndex => {
    setVisibleModal(MODALS.PREP_TASK_EDIT);
    setCurrentModalPrepTaskIndex(prepTaskIndex);
  };

  const onSelectedNewTaskChange = event => {
    const { target: { value } } = event;
    setSelectedNewTasks(value);
  };

  const moveTask = ([fieldName, task], state, { changeValue }) => {
    const updatedCurrentTasks = [...state.formState.values.tasks_in_workflow];
    const newPosition = task.index + task.offset;

    const temporary = updatedCurrentTasks[newPosition];
    updatedCurrentTasks[newPosition] = updatedCurrentTasks[task.index];
    updatedCurrentTasks[task.index] = temporary;

    changeValue(state, fieldName, () => updatedCurrentTasks);
  };

  const deleteTaskFromList = ([fieldName, index], state, { changeValue }) => {
    if (withDeleteConfirmation) {
      if (visibleModal === MODALS.MAIN) {
        // Delete confirmation is required,
        // show this confirmation modal.
        setCurrentModalPrepTaskIndex(index);
        setVisibleModal(MODALS.DELETE_CONFIRMATION);
        return;
      }

      if (visibleModal === MODALS.DELETE_CONFIRMATION) {
        // Looks like user confirmed delete,
        // so we have to close that modal
        // and remove task
        setVisibleModal(MODALS.MAIN);
        setCurrentModalPrepTaskIndex(null);
      }
    }
    const updatedCurrentTasks = [...state.formState.values.tasks_in_workflow];
    updatedCurrentTasks.splice(index, 1);
    changeValue(state, fieldName, () => updatedCurrentTasks);
  };

  const editTask = index => {
    showPrepTaskModal(index);
  };

  const addNewPrepTaskToWorkflow = ([fieldName, prepTaskUri], state, { changeValue }) => {
    const updatedCurrentTasks = [...state.formState.values.tasks_in_workflow];
    updatedCurrentTasks.push({
      prep_task: prepTaskUri,
      // Adding unique key to make sure same `prep_tasks` are not mixed up
      key: Math.random().toString(8),
    });
    changeValue(state, fieldName, () => updatedCurrentTasks);
  };

  const closePrepTaskModal = ([fieldName, prepTaskUri], state, { changeValue }) => {
    setVisibleModal(MODALS.MAIN);
    setCurrentModalPrepTaskIndex(null);

    if (!prepTaskUri) {
      return;
    }

    if (currentModalPrepTaskIndex === null) {
      // When New Task option was chosen
      mutators.prep_workflow.addNewPrepTaskToWorkflow('tasks_in_workflow', prepTaskUri);
      return;
    }
    if (state.formState.values.tasks_in_workflow[currentModalPrepTaskIndex].prep_task !== prepTaskUri) {
      // When Task was edited new PrepTask URI might be returned
      // in case `change only this item` checkbox was chosen
      const updatedCurrentTasks = [...state.formState.values.tasks_in_workflow];
      updatedCurrentTasks[currentModalPrepTaskIndex].prep_task = prepTaskUri;
      changeValue(state, fieldName, () => updatedCurrentTasks);
    }
  };

  const onSelectedNewTaskConfirmed = () => {
    if (selectedNewTask === ADD_NEW_TASK) {
      showPrepTaskModal(null);
    } else {
      mutators.prep_workflow.addNewPrepTaskToWorkflow('tasks_in_workflow', selectedNewTask);
    }
    // Reset dropdown in any case
    setSelectedNewTasks(ADD_NEW_TASK);
  };

  if (isLoading) {
    return (
      <Modal show onHide={onClose} backdrop="static">
        <ModalHeader closeButton>
          <ModalTitle>
            {_upperFirst(workflowVerboseAction)} Prep Workflow
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Loading />
        </ModalBody>
      </Modal>
    );
  }
  return (
    <>
      <Modal
        className={visibleModal === MODALS.MAIN ? 'visible' : 'invisible'}
        onHide={onClose}
        backdrop="static"
        show
      >
        <Form
          onSubmit={onFormSubmit}
          initialValues={initialFormValues}
          initialValuesEqual={isEqual}
          mutators={{
            moveTask,
            editTask,
            deleteTaskFromList,
            closePrepTaskModal,
            addNewPrepTaskToWorkflow,
            onSelectedNewTaskConfirmed,
          }}
          render={({ handleSubmit, form, values }) => {
            mutators.prep_workflow = form.mutators;
            return (
              <form onSubmit={handleSubmit}>
                <ModalHeader closeButton>
                  <ModalTitle>
                    {_upperFirst(workflowVerboseAction)} Prep Workflow
                  </ModalTitle>
                </ModalHeader>
                <ModalBody>
                  <FormGroup controlId="prepWorkflowName">
                    <FormLabel>
                      <FormattedMessage
                        id="field.name"
                        defaultMessage="Name"
                      />: *
                    </FormLabel>
                    <Field
                      name="name"
                      render={props => (
                        <FormControl
                          required
                          {...props.input}
                        />
                      )}
                    />
                  </FormGroup>
                  {
                    customizeForResource && (
                      <FormGroup>
                        <FormLabel>
                          <FormattedMessage
                            id="field.customizeFor"
                            defaultMessage="Customize For"
                          />:
                        </FormLabel>
                        <FormControl as="select" disabled>
                          <option key="placeholder" value="" selected disabled>
                            {_startCase(customizeForResource.endpointName)} {customizeForResource.shortUUID}
                          </option>
                        </FormControl>
                      </FormGroup>
                    )
                  }
                  <FormGroup>
                    <FormLabel>
                      <FormattedMessage
                        id="field.description"
                        defaultMessage="Description"
                      />:
                    </FormLabel>
                    <Field
                      name="description"
                      render={props => (
                        <FormControlTextAreaWithCustomHandlers
                          {...props.input}
                        />
                      )}
                    />
                  </FormGroup>
                  <PositionTable
                    showDeleteColumn
                    showEditColumn
                    emptyDataPlaceholder={`Please add Tasks to ${workflowVerboseAction} a prep workflow`}
                    currentActivePrepTaskIndex={currentActivePrepTaskIndex}
                    onMoveUp={index => form.mutators.moveTask('tasks_in_workflow', {
                      index,
                      offset: -1,
                    })}
                    onMoveDown={index => form.mutators.moveTask('tasks_in_workflow', {
                      index,
                      offset: 1,
                    })}
                    renderDataRowItemsCallback={prepTaskInWorkflow => {
                      const prepTask = prepTasks.find(item => item.uri === prepTaskInWorkflow.prep_task);
                      if (!prepTask) {
                        return (
                          <>
                            <td><FormattedMessage id="notAvailable" defaultMessage="N/A" /></td>
                            <td><FormattedMessage id="notAvailable" defaultMessage="N/A" /></td>
                          </>
                        );
                      }
                      return (
                        <>
                          <td>{prepTask.shortname}</td>
                          <td>
                            {(prepTask.description)
                              ? (
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id="prepTaskDescription">{prepTask.description}</Tooltip>
                                  }
                                >
                                  <Fa name="question-circle" />
                                </OverlayTrigger>
                              ) : (
                                <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                              )}
                          </td>
                        </>
                      );
                    }}
                    data={values.tasks_in_workflow}
                    onDelete={form.mutators.deleteTaskFromList}
                    onEdit={editTask}
                    headerRowItems={[
                      'Name',
                      'Description',
                    ]}
                  />
                  <Row>
                    <Col lg={10}>
                      <FormControlSelect
                        id="newTaskSelector"
                        name="selectedNewTask"
                        value={selectedNewTask}
                        onChange={onSelectedNewTaskChange}
                      >
                        <FormattedMessage id="addTask" defaultMessage="Add Task">{text =>
                          <option value={ADD_NEW_TASK}>{text}</option>}
                        </FormattedMessage>
                        {prepTasks.map(task => (
                          <option
                            value={task.uri}
                            key={task.uuid}
                          >
                            {task.shortname}
                          </option>
                        ))}
                      </FormControlSelect>
                    </Col>
                    <Col lg={2}>
                      <Button
                        block
                        onClick={onSelectedNewTaskConfirmed}
                      >
                        <Fa name="plus" />
                      </Button>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="submit"
                    variant="success"
                    disabled={isSubmitting || values.tasks_in_workflow.length === 0}
                  >
                    { isSubmitting ? <Loading /> : 'Save' }
                  </Button>
                  <Button variant="primary" disabled={isSubmitting} onClick={onClose}>
                    Cancel
                  </Button>
                </ModalFooter>
                {visibleModal === MODALS.PREP_TASK_EDIT && (
                  <PrepTaskModalContainer
                    prepTaskUri={
                      form.getState().values.tasks_in_workflow[currentModalPrepTaskIndex]
                      && form.getState().values.tasks_in_workflow[currentModalPrepTaskIndex].prep_task
                    }
                    onClose={form.mutators.closePrepTaskModal}
                  />
                )}
                {
                  visibleModal === MODALS.DELETE_CONFIRMATION && (
                    <ConfirmationModal
                      handleCancel={() => { setVisibleModal(MODALS.MAIN); setCurrentModalPrepTaskIndex(null); }}
                      handleConfirm={() => form.mutators.deleteTaskFromList('tasks_in_workflow', currentModalPrepTaskIndex)}
                      message="Remove this Task? This will delete any comments on this task."
                    />
                  )
                }
              </form>
            );
          }}
        />
      </Modal>
    </>
  );
};

PrepWorkflowModal.defaultProps = {
  prepWorkflow: null,
  isLoading: false,
  isSubmitting: false,
  currentActivePrepTaskIndex: null,
  customizeForUri: null,
  withDeleteConfirmation: false,
};

PrepWorkflowModal.propTypes = {
  currentActivePrepTaskIndex: PropTypes.number,
  customizeForUri: PropTypes.string,
  onFormSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  prepWorkflow: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  withDeleteConfirmation: PropTypes.bool,
  fields: PropTypes.shape({}).isRequired,
  input: finalFormInputTypes.isRequired,
  initialFormValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    tasks_in_workflow: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  prepTasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PrepWorkflowModal;
