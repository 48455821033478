import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import UserForm from 'rapidfab/components/admin/UserForm';
import { API_RESOURCES, USER_ROLES } from 'rapidfab/constants';

const UserFormContainer = ({ user, handleSelectionChange }) => {
  const roles = useSelector(Selectors.getRoles);
  const bureauUri = useSelector(Selectors.getBureauUri);
  const { uuid, name: nickname, emails, username } = user;
  const isEditing = !!username;

  const [name, setName] = useState(() => {
    if (username && nickname) {
      return nickname;
    }
    return '';
  });
  const [email, setEmail] = useState(() => {
    if (username && emails) {
      return emails[0];
    }
    return '';
  });

  const handleInputChange = event => {
    const { name: field, value } = event.target;
    const normalized = value.trim();
    switch (field) {
      case 'name':
        setName(value);
        break;
      case 'email':
        setEmail(normalized);
        break;
      default:
        break;
    }
  };

  const dispatch = useDispatch();
  const createUser = async payload => {
    try {
      const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].post(payload));
      await dispatch(Actions.Api.nautilus[API_RESOURCES.ROLE].post({ username: response.json.username,
        role: USER_ROLES.RESTRICTED }));
      handleSelectionChange('none');
      dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list());
    } catch {
      dispatch(Actions.UI.clearUIErrors());
      const userHasRoles = roles.some(({ username: nick }) => nick === email.toLowerCase());
      if (userHasRoles) {
        return;
      }
      const rolePayload = {
        username: email.toLowerCase(),
        role: 'restricted',
      };
      try {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.ROLE].post(rolePayload));
        dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list());
        handleSelectionChange('none');
      } catch (error) {
        console.error(error);
      }
      dispatch(Actions.UI.clearUIErrors());
    }
  };

  const updateUser = async payload => {
    try {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].put(uuid, payload));
      handleSelectionChange('none');
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    const updatePayload = { email: email.toLowerCase(), name };
    const createPayload = { ...updatePayload, bureau: bureauUri, login: false };

    return isEditing ? updateUser(updatePayload) : createUser(createPayload);
  };

  return (
    <UserForm
      name={name}
      email={email}
      isEditing={isEditing}
      handleSubmit={handleSubmit}
      handleInputChange={handleInputChange}
    />
  );
};

UserFormContainer.defaultProps = { user: {} };

UserFormContainer.propTypes = {
  handleSelectionChange: PropTypes.func.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
    emails: PropTypes.arrayOf(PropTypes.string),
    username: PropTypes.string,
    uuid: PropTypes.string,
  }),
};

export default UserFormContainer;
