import React from 'react';
import PropTypes from 'prop-types';

import Fa from 'react-fontawesome';

import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';

const ExportButton = ({
  download,
  loadingReport,
  onExport,
  asLink,
  pullToLeft,
  downloadLabelText,
  disabled,
}) => {
  const buttonClassName = pullToLeft && 'pull-left';

  if (download && asLink) {
    return (
      // eslint-disable-next-line react/no-unknown-property
      <a href={download} download>
        <span>{downloadLabelText}</span>
      </a>
    );
  }

  if (loadingReport) {
    return (
      <Button variant="primary" disabled className={`${buttonClassName} ml15 mt15`}>
        <Fa name="spinner" spin />
      </Button>
    );
  }

  return (
    <Button variant="primary" disabled={disabled} onClick={onExport} className={`${buttonClassName} ml15 mt15`}>
      { download ?
        <FormattedMessage id="record.order.reissue" defaultMessage="Reissue Order Quote" />
        :
        <FormattedMessage id="record.order.export" defaultMessage="Export Order Quote" />}
    </Button>
  );
};

ExportButton.defaultProps = {
  asLink: false,
  pullToLeft: true,
  disabled: false,
  loadingReport: false,
  onExport: () => true,
  downloadLabelText: 'Download Order Quote',
};

ExportButton.propTypes = {
  download: PropTypes.string.isRequired,
  asLink: PropTypes.bool,
  pullToLeft: PropTypes.bool,
  disabled: PropTypes.bool,
  downloadLabelText: PropTypes.string,
  loadingReport: PropTypes.bool,
  onExport: PropTypes.func,
};

export default ExportButton;
