import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-s-alert';
import WorkChecklistModal from 'rapidfab/components/records/WorkChecklistModal';
import _isEqual from 'lodash/isEqual';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _some from 'lodash/some';
import _keyBy from 'lodash/keyBy';
import Actions from 'rapidfab/actions';
import getEndpointFromURI from 'rapidfab/utils/getEndpointFromURI';
import createOrReplaceArray from 'rapidfab/utils/createOrReplaceArray';
import extractUuid from 'rapidfab/utils/extractUuid';
import * as Selectors from 'rapidfab/selectors';
import cleanChecklistLinking from 'rapidfab/utils/cleanChecklistLinking';
import validateWorkInstructionPayload from 'rapidfab/utils/validateWorkInstructionPayload';
import {
  ENTITY_TYPES,
  LINE_ITEM_DESIGN_STAGE_STATUSES,
  API_RESOURCES, PRINT_STATUSES, PROCESS_STEPS_BY_ENDPOINT,
} from 'rapidfab/constants';
import getEntityTypeFromUri from 'rapidfab/utils/getEntityTypeFromURI';
import * as Sentry from '@sentry/react';
import usePrevious from 'rapidfab/hooks';
import GuidelineSuggestionContext from 'rapidfab/context/GuidelineSuggestionContext';
import _clone from 'lodash/clone';
import _omit from 'lodash/omit';
import { FormattedMessage } from 'react-intl';
import _reduce from 'lodash/reduce';
import _includes from 'lodash/includes';
import _map from 'lodash/map';

/*
                   +---------------------------------------------------------------------------+
 Line-item view => |WorkChecklistModalContainer                                                |
 Workflow view     |                                                                           |
 Print (run page)  |  +-----------------------------------------------------------------------+|
 + where checklist |  | WorkChecklistModal                                                    ||
   modal is used   |  |                                                                       ||
                   |  | +-------------------------------------------------------------------+ ||
                   |  | | RelatedWorkChecklist                                              | ||
                   |  | |                                                                   | ||
                   |  | | +----------------------------------------------------------------+| ||
 Workstation ====> |  | | | WorkChecklistContainer                                         || ||
 type page         |  | | |                                                                || ||
                   |  | | | +-------------------------------------------------------------+|| ||
                   |  | | | |  WorkChecklist                                              ||| ||
                   |  | | | |                                                             ||| ||
                   |  | | | |  +------------------------------------------------------+   ||| ||
                   |  | | | |  |WorkInstruction                                       |   ||| ||
                   |  | | | |  +------------------------------------------------------+   ||| ||
                   |  | | | |                                                             ||| ||
                   |  | | | |  +------------------------------------------------------+   ||| ||
                   |  | | | |  |WorkInstruction                                       |   ||| ||
                   |  | | | |  +------------------------------------------------------+   ||| ||
                   |  | | | |                                                             ||| ||
                   |  | | | +-------------------------------------------------------------+|| ||
                   |  | | |                                                                || ||
                   |  | | +----------------------------------------------------------------+| ||
                   |  | +-------------------------------------------------------------------+ ||
                   |  +-----------------------------------------------------------------------+|
                   +---------------------------------------------------------------------------+
 */

const getChecklistLinkingTemplate = (processStep, relatedUri) => ({
  process_step: processStep.uri,
  related_uri: relatedUri,
  work_checklist: {
    // Name is always process step name and can't be changed for now
    // since it is often expected to be process step name in the UI historically
    name: processStep.name,
    work_instructions: [],
  },
});

const WorkChecklistModalContainer = props => {
  const guidelineSuggestionContext = useContext(GuidelineSuggestionContext) ?? {};
  const { selectedGuidelineSuggestion } = guidelineSuggestionContext;
  const { selectedProcessStep, selectProcessStep } = props;

  const [cleanedChecklistLinkings, setCleanedChecklistLinkings] = useState([]);
  const [initialChecklistLinkings, setInitialChecklistLinkings] = useState([]);
  const [selectedAvailableProcessStep, setSelectedAvailableProcessStep] = useState([]);

  const [changedSteps, setChangedSteps] = useState([]);

  const relatedEntity = useSelector(state => Selectors.getUUIDResource(state, extractUuid(props.relatedURI)));
  const relatedEntityType = getEntityTypeFromUri(props.relatedURI);
  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.WORK_CHECKLISTS_FOR_RESOURCE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.WORKFLOW].get.fetching ||
    state.ui.nautilus[API_RESOURCES.PROCESS_STEP].list.fetching);
  const fetchingWorkChecklistLinkings = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.WORK_CHECKLISTS_FOR_RESOURCE].list.fetching);
  const sending = useSelector(state => state.ui.nautilus[API_RESOURCES.WORK_CHECKLISTS_FOR].put.fetching);
  const workflow = useSelector(state => Selectors.getUUIDResource(state, extractUuid(props.workflowURI)));
  const workChecklistLinkings = useSelector(state => Selectors.getWorkChecklistLinkingsForResource(
    state,
    props.relatedURI,
  ));

  const [updatedWorkChecklist, setUpdatedWorkChecklist] = useState(workChecklistLinkings);

  let processSteps = useSelector(state => Selectors.getProcessStepsForWorkflow(state, workflow));
  if (props.onlyProcessStepURI) {
  // If props.onlyProcessStepURI is defined,
  //  we need to limit all process steps of related object with single one
    processSteps = _filter(processSteps, ['uri', props.onlyProcessStepURI]);

    // If modal is opened for single process step and there is no checklist exists
    // create one from template
    if (!_find(workChecklistLinkings, { process_step: props.onlyProcessStepURI })) {
      // When `onlyProcessStepURI` - there is only 1 process step
      const currentProcessStep = processSteps[0];

      workChecklistLinkings.push(
        getChecklistLinkingTemplate(currentProcessStep, props.relatedURI),
      );
    }
  }

  // Contains already existing checklist linkings for the current resource
  // (not the ones to other resources
  // like Workflow or Workstation Type for Line Item,
  // or Workstation Type for Workflow)
  const relatedChecklistLinkings = useSelector(state =>
    Selectors.getRelatedWorkChecklistLinkingsForResource(state, props.relatedURI));

  // Flag which defined if linking group (not a real entity, means a group of checklists)
  // exist or not (checklist linking existing for at least one of the process steps)
  // Used generally for title (Create|Update) Work Instructions
  const isAnyRelatedChecklistLinkingExists = props.onlyProcessStepURI
  // When only 1 process step is shown, use that checklist linking only
    ? _some(
      relatedChecklistLinkings,
      ({
        work_checklist: { initial_related_uri: initialRelatedUri },
        process_step: processStep,
      }) =>
        initialRelatedUri === props.relatedURI && processStep === props.onlyProcessStepURI,
    )
  // Use all existing linkings otherwise
    : !!relatedChecklistLinkings.length;

  const prints = useSelector(Selectors.getPrints);

  const completeProcessSteps = _filter(
    workflow?.process_steps,
    processStepUri => {
      const print = _find(prints, { process_step: processStepUri });
      return print && print.status === PRINT_STATUSES.COMPLETE;
    },
  );

  const dispatch = useDispatch();

  const loadAllChecklistLinkingsForResource = relatedURI => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_CHECKLISTS_FOR_RESOURCE].list({ related_uri: relatedURI }));
  };

  const patchProcessTypeNames = (availableProcessSteps, usedProcessSteps) => {
    // Rejects all process steps which are already used in checklist modal and
    // adds new objects {groupName: ...} to result with process step group names
    let lastUsedProcessStep = null;
    return _reduce(
      availableProcessSteps,
      (result, processStep) => {
        if (_includes(usedProcessSteps, processStep.uri)) {
          // If this process step is already used in checklist,
          // we need to skip it
          return result;
        }

        const { endpointName } = getEndpointFromURI(
          processStep.workstation_type_uri,
        );
        if (lastUsedProcessStep !== endpointName) {
          // If it's new process step group, we add this groupName
          // to list of all process steps based by endpoint of this process type
          lastUsedProcessStep = endpointName;

          const groupName = PROCESS_STEPS_BY_ENDPOINT[endpointName];
          result.push({ groupName });
        }
        result.push(processStep);
        return result;
      },
      [],
    );
  };

  const processStepsURIs = _map(processSteps, 'uri');
  // Limit all linkings by process steps URIs
  // It's used only when needed to return only one checklist linking
  // but can be useful where linkings are exists, but process steps - not
  const checklistLinkings = _filter(initialChecklistLinkings, linking =>
    processStepsURIs.includes(linking.process_step),
  );

  const usedProcessSteps = _map(checklistLinkings, 'process_step');
  const availableProcessSteps = patchProcessTypeNames(
    processSteps,
    usedProcessSteps,
  );

  // Add available steps here, map them, run this method below and fill in everything in once

  const onAvailableProcessStepSelect = selectedStep => {
    /*
      Method finds process step from `work-checklist-for-resource` endpoint data,
      adds it to modal form and sort all data by process step position
     */

    if (!selectedStep) {
      /*
        If there is no process step, it means we opened the modal window and there
        was no method to select the particular step. In this case, we need to select
        the first step from the list of available steps (by default) when the Modal Window
        appears.
      */
      selectProcessStep(processSteps[0]?.uri);
    }
    /*
      Here we will check if we have the available process steps to create a "template".
      If there is no -> we will just skip running this method as the main purpose of it
      is to create a "template" for the non-saved, new checklist.
    */
    const hasAvailableProcessSteps = selectedStep && availableProcessSteps
      .find(processStep => processStep.uri === selectedStep);

    if (!availableProcessSteps.length || !hasAvailableProcessSteps) {
      return;
    }

    let currentCleanedChecklistLinkings = [...cleanedChecklistLinkings];
    const { relatedURI } = props;

    let processStepChecklist = _find(
      workChecklistLinkings,
      { process_step: selectedStep },
    );

    if (!processStepChecklist) {
      // If no checklist exists - create a template
      const currentProcessStep = _find(processSteps, { uri: selectedStep });
      processStepChecklist =
        getChecklistLinkingTemplate(currentProcessStep, relatedURI);
      setUpdatedWorkChecklist(previous => [...previous, processStepChecklist]);
    }

    currentCleanedChecklistLinkings.push(processStepChecklist);

    // Sorting checklists based on theirs Process Step's Position
    // (checklistLinking.position is re-calculated on the backend automatically on each submit
    // and can't be used here)

    const processStepsByUri = _keyBy(processSteps, 'uri');
    currentCleanedChecklistLinkings = _sortBy(
      currentCleanedChecklistLinkings,
      ({ process_step: checklistLinkingProcessStepUri }) => {
        let processStepPosition = 0;
        try {
          processStepPosition = processStepsByUri[checklistLinkingProcessStepUri].step_position;
        } catch (error) {
          // It may happen for legacy line item linkings
          // where workflow was replaced to another one
          Sentry.captureException(error);
        }
        return processStepPosition;
      },
    );

    setSelectedAvailableProcessStep('');
    setInitialChecklistLinkings(currentCleanedChecklistLinkings);
    setCleanedChecklistLinkings(currentCleanedChecklistLinkings);
  };

  const onInitialize = (relatedURI, workflowURI) => {
    loadAllChecklistLinkingsForResource(relatedURI);
    // Run USERS list only once when the modal window is active.
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list());

    if (workflowURI) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].get(extractUuid(workflowURI)));
      dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list({ workflows: workflowURI }));
    }
  };

  useEffect(() => {
    /*
      Previously we used this method only when we manually added the Process Step
      from the Available Process Steps list.

      Now, on each "Selected Step" change, we need to check if there is a template
      for the Workflow Step which has no checklist created yet, as now we need to
      create it when the user selects (clicks) the particular Workflow Step in the list
      (the left side of the modal). If it exists, we will skip this step.
     */
    onAvailableProcessStepSelect(selectedProcessStep);
  }, [selectedProcessStep,
    JSON.stringify(availableProcessSteps),
    JSON.stringify(processSteps),
    initialChecklistLinkings]);

  useEffect(() => {
    if (!fetchingWorkChecklistLinkings) {
      // We need to set the initial checklist linkings only once all the data is feched.
      setUpdatedWorkChecklist(workChecklistLinkings);
    }
  }, [fetchingWorkChecklistLinkings]);

  const onUpdate = (relatedURI, payload) => {
    const { endpointName, uuid } = getEndpointFromURI(relatedURI);
    const checklistWorkstep = `${endpointName}/${uuid}`;

    return dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_CHECKLISTS_FOR].put(
      checklistWorkstep, payload,
    )).then(() => {
      loadAllChecklistLinkingsForResource(relatedURI);

      Alert.success(
        <FormattedMessage
          id="toaster.workChecklistsFor.updated"
          defaultMessage="Checklist successfully updated"
        />);
    });
  };

  /*
     cleanedChecklistLinkings - it's line-item-checklist object.
      It contains all initial data from `work-checklist-for-resource` endpoint for form and
      updates only when data in form will be changed. Be aware that it's used only
      for submitting, and it's not passed into WorkChecklistModal component

     initialChecklistLinkings - initially it's based on the same prop name
      Will be changed only when user wants to add new workstep with
      cleanedChecklistLinkings + [new process-step checklist].
      It will force child component rerender

     selectedAvailableProcessStep - stores selected uri of process step
      from "Select Process Step" modal
     */

  useEffect(() => {
    const { relatedURI, workflowURI } = props;
    onInitialize(relatedURI, workflowURI);
  }, [props.relatedURI, props.workflowURI]);

  const previousWorkChecklistLinkings = usePrevious(workChecklistLinkings);

  useEffect(() => {
    let workChecklistLinkingsWithWorkInstructionGuidelineSuggestions = _clone(workChecklistLinkings);

    if (selectedGuidelineSuggestion) {
      const linking = workChecklistLinkings
        .find(linking => linking.process_step === selectedGuidelineSuggestion.processStep.uri);
      if (linking?.work_checklist) {
        const payload = {
          ...selectedGuidelineSuggestion.suggestion.work_instruction_template,
          position: linking.work_checklist?.work_instructions?.length + 1,
        };

        const newLinking = {
          ...linking,
          work_checklist: {
            ...linking?.work_checklist,
            work_instructions: [
              ...linking?.work_checklist?.work_instructions,
              _omit(
                payload,
                'uuid',
              ),
            ],
          },
        };
        workChecklistLinkingsWithWorkInstructionGuidelineSuggestions = workChecklistLinkings.map(linking => {
          if (linking.process_step === selectedGuidelineSuggestion.processStep.uri) {
            return newLinking;
          }
          return linking;
        });
      }
    }

    if (!_isEqual(workChecklistLinkings, previousWorkChecklistLinkings)) {
      setCleanedChecklistLinkings(selectedGuidelineSuggestion ?
        workChecklistLinkingsWithWorkInstructionGuidelineSuggestions :
        workChecklistLinkings);
      setInitialChecklistLinkings(selectedGuidelineSuggestion ?
        workChecklistLinkingsWithWorkInstructionGuidelineSuggestions :
        workChecklistLinkings);
    }
  }, [workChecklistLinkings]);

  const onChecklistChange = (processStepURI, checklistFields) => {
    const checklistLinkings = [...cleanedChecklistLinkings];

    const checklistLinking = {
      ..._find(checklistLinkings, { process_step: processStepURI }),
      work_checklist: checklistFields,
    };

    const updatedChecklistLinkings = createOrReplaceArray(
      checklistLinkings,
      { process_step: processStepURI },
      checklistLinking,
    );

    /*
      This way we will check if some data was changed in the Work Checklist,
      and if yes -> we will set the "changedSteps" to have the ['<uri>'] of the processStep
      which was changed.
    */
    updatedChecklistLinkings.filter(item => {
      const foundItem = updatedWorkChecklist.find(workItem => workItem.process_step === item.process_step);
      if (foundItem
        && !_isEqual(JSON.stringify(foundItem), JSON.stringify(item)) && !changedSteps.includes(item.process_step)) {
        setChangedSteps(dirtyValues => [...dirtyValues, item.process_step]);
      }

      if (foundItem
        && _isEqual(JSON.stringify(foundItem), JSON.stringify(item)) && changedSteps.includes(item.process_step)) {
        setChangedSteps(dirtyValues => dirtyValues.filter(dirtyValue => dirtyValue !== item.process_step));
      }

      return true;
    });

    setCleanedChecklistLinkings(updatedChecklistLinkings);
  };

  const onChecklistDelete = processStepURI => {
    let currentCleanedChecklistLinkings = [...cleanedChecklistLinkings];

    // Step 1: We will remove the checklist from the list
    currentCleanedChecklistLinkings = _filter(
      currentCleanedChecklistLinkings,
      checklist => checklist.process_step !== processStepURI,
    );

    // Step 2: We will create the default template as it is a new checklist
    const currentProcessStep = _find(processSteps, { uri: processStepURI });
    const templateStep =
      getChecklistLinkingTemplate(currentProcessStep, processStepURI);

    // Step 3: Update the checklist (exclude the removed one) + add the new template
    setCleanedChecklistLinkings(() => [...currentCleanedChecklistLinkings, templateStep]);
    setInitialChecklistLinkings(() => [...currentCleanedChecklistLinkings, templateStep]);
  };

  const onAvailableProcessStepChange = processStepURI => {
    setSelectedAvailableProcessStep(processStepURI);
  };

  const onSave = () => {
    const { relatedURI } = props;

    const payload = cleanChecklistLinking(
      cleanedChecklistLinkings,
      relatedURI,
    );

    try {
      validateWorkInstructionPayload(payload);
    } catch (error) {
      Alert.error(error.message);
      return false;
    }

    if (!payload.length) {
      payload.push({});
    }

    onUpdate(relatedURI, payload).finally(props.onClose);

    return true;
  };

  const {
    onClose,
    showDelete,
    onCustomWorkflowSelect,
    relatedURI,
  } = props;

  const isLineItemCustomWorkflow = workflow && workflow.line_item !== null;

  const isEditingDisabled =
      relatedEntityType === ENTITY_TYPES.LINE_ITEM
      && !LINE_ITEM_DESIGN_STAGE_STATUSES.includes(relatedEntity.status);

  return (
    <WorkChecklistModal
      {...props}
      relatedURI={relatedURI}
      readOnly={isEditingDisabled}
      initialChecklistLinkings={initialChecklistLinkings}
      isAnyRelatedChecklistLinkingExists={isAnyRelatedChecklistLinkingExists}
      onCustomWorkflowSelect={onCustomWorkflowSelect}
      isLineItemCustomWorkflow={isLineItemCustomWorkflow}
      processSteps={processSteps}
      selectedAvailableProcessStep={selectedAvailableProcessStep}
      onAvailableProcessStepChange={onAvailableProcessStepChange}
      onAvailableProcessStepSelect={onAvailableProcessStepSelect}
      onChecklistChange={onChecklistChange}
      onChecklistDelete={onChecklistDelete}
      onSave={onSave}
      onClose={onClose}
      showDelete={showDelete}
      sending={sending}
      fetching={fetching}
      completeProcessSteps={completeProcessSteps}

      selectedProcessStep={selectedProcessStep}
      selectProcessStep={selectProcessStep}
      changedSteps={changedSteps}
    />
  );
};

WorkChecklistModalContainer.defaultProps = {
  onCustomWorkflowSelect: null,
  onlyProcessStepURI: null,
  showDelete: true,
  selectedProcessStep: '',
};

WorkChecklistModalContainer.propTypes = {
  /**
   *  isAnyRelatedChecklistLinkingExists - flag which defines if at least one linking
   *  to the current resource exists
   */
  /** onInitialize() and onUpdate() are methods from mapDispatchToProps() */
  onCustomWorkflowSelect: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  // It used only in mapStateToProps(),
  // let's leave it in propTypes for consistency ¯\_(ツ)_/¯
  // eslint-disable-next-line react/no-unused-prop-types
  onlyProcessStepURI: PropTypes.string,
  relatedURI: PropTypes.string.isRequired,
  showDelete: PropTypes.bool,
  workflow: PropTypes.shape({
    line_item: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  workflowURI: PropTypes.string.isRequired, // Used in mapStateToProps()
  relatedEntity: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  selectedProcessStep: PropTypes.string,
  selectProcessStep: PropTypes.func.isRequired,
};

export default WorkChecklistModalContainer;
