import _filter from 'lodash/filter';
import { createSelector } from 'reselect';
import { getStateResources, getPredicate } from 'rapidfab/selectors/helpers/base';
import { MATERIAL_BATCH_ACTION_TYPES, MATERIAL_CONTAINER_STATUSES } from 'rapidfab/constants';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';

export const getMaterialContainers = createSelector(
  [baseStateSelectors.getStateMaterialContainers, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getMaterialContainersForBatch = createSelector(
  [getPredicate, getMaterialContainers],
  (batch, containers) => {
    if (!batch) {
      return [];
    }
    return _filter(containers, { current_batch: batch.uri });
  },
);

export const getMaterialBatchActions = createSelector(
  [baseStateSelectors.getStateMaterialBatchActions, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getMaterialBatchActionsForBatch = createSelector(
  [getPredicate, getMaterialBatchActions],
  (batch, actions) => {
    if (!batch) {
      return [];
    }
    return _filter(actions, { source_batch: batch.uri });
  },
);

export const getMaterialBatchTestActionsForBatch = createSelector(
  [getMaterialBatchActionsForBatch],
  actions => _filter(actions, { action_type: MATERIAL_BATCH_ACTION_TYPES.TEST }),
);

export const getNonEmptyMaterialContainersForBatch = createSelector(
  [getMaterialContainersForBatch],
  containers => {
    const { EMPTY, ...containerStatusesExceptEmpty } = MATERIAL_CONTAINER_STATUSES;
    return _filter(
      containers,
      container => _includes(containerStatusesExceptEmpty, container.status),
    );
  },
);

export const getMaterialContainersForLot = createSelector(
  [getPredicate, getMaterialContainers],
  (lot, containers) => {
    if (!lot) {
      return [];
    }
    return _filter(containers, { material_lot: lot.uri });
  },
);

export const getMaterialGenealogy = createSelector(
  [baseStateSelectors.getStateUIMaterialGenealogy],
  materialGenealogy => materialGenealogy,
);
