import React from 'react';
import PropTypes from 'prop-types';

function ColorChits({ color, completed, total, label }) {
  // getting the color state
  // For each  state classes are defined in main.less
  const getColorState = () => {
    // when 100% done(dark)
    if (completed === total) return 3;

    //  when 1-99% done(lighter)
    if (total > completed && completed > 0) return 2;

    // when 0% done(lightest)
    return 1;
  };
  const getChitClass = () => `order-status-number-new ${color + getColorState()}`;

  return (
    <div className="order-status-item">
      <div className={getChitClass()}>
        <b>
          <span className="num">{`${completed}/${total}`}</span>
        </b>
      </div>
      {label}
    </div>
  );
}
ColorChits.defaultProps = {
  label: '',
  total: 0,
  completed: 0,
  color: 'blue',
};

ColorChits.propTypes = {
  label: PropTypes.node,
  total: PropTypes.number,
  completed: PropTypes.number,
  color: PropTypes.string,
};

export default ColorChits;
