import React, { Component } from 'react';
import { Picky } from 'react-picky';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import { FormattedMessage } from 'rapidfab/i18n';

class SelectSingle extends Component {
  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
    this.renderItem = this.renderItem.bind(this);

    this.state = {
      value: [],
      options: [],
      placeholder: null,
    };
  }

  static getDerivedStateFromProps(props) {
    const { valueKey, labelKey, value, required } = props;

    let { placeholder } = props;
    if (!placeholder) {
      placeholder = required ?
        (<FormattedMessage id="field.choose" defaultMessage="Choose…" />)
        : (<FormattedMessage id="field.none" defaultMessage="None" />);
    }

    let { data } = props;

    const selectedItem = _find(data, { [valueKey]: value });

    if (!required) {
      const emptyPlaceholder = {
        [labelKey]: placeholder,
        [valueKey]: '',
      };
      data = [emptyPlaceholder, ...data];
    }

    return {
      // Hack for Picky to select item from list properly
      // Item is selected when it is an array of object(s)
      value: selectedItem ? [selectedItem] : [],
      options: data,
      placeholder,
    };
  }

  handleOnChange(selectedData) {
    const { name, valueKey, imitateOnChangeEvent, nullable } = this.props;
    const value = selectedData ? selectedData[valueKey] : null;

    if (!imitateOnChangeEvent) {
      this.props.handleOnChange(name, value);
      return;
    }

    // Imitating regular select `onChange` event
    const fakeEvent = {
      target: {
        type: 'select',
        value: nullable && !value ? null : value,
        name,
      },
      stopPropagation: () => {},
      preventDefault: () => {},
    };
    this.props.handleOnChange(fakeEvent);
  }

  renderItem(renderProps) {
    const {
      item,
      selectValue,
      labelKey,
      valueKey,
    } = renderProps;

    const { isOptionDisabledCallback, renderLabelCallback, renderOptionCallback } = this.props;

    if (renderOptionCallback) {
      return renderOptionCallback(renderProps);
    }

    const isOptionsDisabled = isOptionDisabledCallback && isOptionDisabledCallback(item);

    const label = renderLabelCallback ? renderLabelCallback(item) : item[labelKey];

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        key={item[valueKey]}
        onClick={() => !isOptionsDisabled && selectValue(item)}
        className={isOptionsDisabled ? 'text-muted' : null}
      >
        {label}
      </div>
    );
  }

  render() {
    const {
      labelKey,
      valueKey,
      includeFilter,
      disabled,
      defaultFocusFilter,
      required,
    } = this.props;

    const { value, options, placeholder } = this.state;

    const className = `wrap-text ${this.props.className ? this.props.className : ''}`;

    return (
      <div className="position-relative w-full">
        <Picky
          placeholder={placeholder}
          options={options}
          labelKey={labelKey}
          valueKey={valueKey}
          includeFilter={includeFilter}
          defaultFocusFilter={defaultFocusFilter}
          value={value}
          keepOpen={false}
          multiple={false}
          clearFilterOnClose
          onChange={values => this.handleOnChange(values)}
          render={this.renderItem}
          disabled={disabled}
          className={className}
          buttonProps={{
            'data-testid': `picky-input-${this.props.name || labelKey}`,
          }}
        />
        {/*
          Adding transparent input to add `required` browser validation when needed
          since Picky does not have validation options
          1px height is required to show browser validation popup right under the picky dropdown
        */}
        {required && (
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              height: '1px',
              width: '100%',
              position: 'absolute',
              padding: 0,
              margin: 0,
              border: 0,
            }}
            value={value}
            // onChange needed to prevent console warnings
            onChange={() => {}}
            required
          />
        )}
      </div>
    );
  }
}

SelectSingle.defaultProps = {
  placeholder: null,
  includeFilter: true,
  value: null,
  labelKey: 'name',
  valueKey: 'uri',
  // Made for backwards compatibility with the logic created for regular Selects
  // Do not use it for any new case. Better create appropriate onChange handler
  imitateOnChangeEvent: false,
  renderOptionCallback: null,
  renderLabelCallback: null,
  isOptionDisabledCallback: null,
  required: false,
  disabled: false,
  defaultFocusFilter: true,
  nullable: false,
  className: '',
};

SelectSingle.propTypes = {
  name: PropTypes.string.isRequired,
  // Used in getDerivedStateFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  placeholder: PropTypes.string,
  // Used in getDerivedStateFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  // Used in getDerivedStateFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleOnChange: PropTypes.func.isRequired,
  includeFilter: PropTypes.bool,
  imitateOnChangeEvent: PropTypes.bool,
  renderOptionCallback: PropTypes.func,
  renderLabelCallback: PropTypes.func,
  isOptionDisabledCallback: PropTypes.func,
  // Used in getDerivedStateFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultFocusFilter: PropTypes.bool,
  nullable: PropTypes.bool,
  className: PropTypes.string,
};

export default SelectSingle;
