import { MODEL_UNITS } from 'rapidfab/constants';

const UNIT_COEFFICIENTS = {
  [MODEL_UNITS.MM]: {
    [MODEL_UNITS.M]: 1000,
    [MODEL_UNITS.CM]: 10,
    [MODEL_UNITS.MM]: 1,
    // 1 mm = 0.039370078740157
    [MODEL_UNITS.FEET]: 0.00328084,
    [MODEL_UNITS.INCHES]: 0.039370078740157,
  },
  [MODEL_UNITS.CM]: {
    [MODEL_UNITS.M]: 0.01,
    [MODEL_UNITS.CM]: 1,
    [MODEL_UNITS.MM]: 10,
    // 1 mm = 0.039370078740157
    [MODEL_UNITS.FEET]: 0.0328084,
    [MODEL_UNITS.INCHES]: 0.39370078740157,
  },
  [MODEL_UNITS.M]: {
    [MODEL_UNITS.M]: 1,
    [MODEL_UNITS.CM]: 100,
    [MODEL_UNITS.MM]: 1000,
    // 1 mm = 0.039370078740157
    [MODEL_UNITS.FEET]: 3.28084,
    [MODEL_UNITS.INCHES]: 39.370078740157,
  },
  [MODEL_UNITS.INCHES]: {
    // 1 in = 25.4 mm
    [MODEL_UNITS.M]: 0.254,
    [MODEL_UNITS.CM]: 2.54,
    [MODEL_UNITS.MM]: 25.4,
    [MODEL_UNITS.FEET]: 0.08333,
    [MODEL_UNITS.INCHES]: 1,
  },
  [MODEL_UNITS.FEET]: {
    // 1 in = 25.4 mm
    [MODEL_UNITS.M]: 0.3048,
    [MODEL_UNITS.CM]: 30.48,
    [MODEL_UNITS.MM]: 304.8,
    [MODEL_UNITS.FEET]: 1,
    [MODEL_UNITS.INCHES]: 12,
  },
};

export default function convertLengthToOtherUnit(length, lengthUnit, requestedUnit) {
  if (!UNIT_COEFFICIENTS[lengthUnit]) {
    return null;
  }

  if (!UNIT_COEFFICIENTS[lengthUnit][requestedUnit]) {
    return null;
  }

  const coef = UNIT_COEFFICIENTS[lengthUnit][requestedUnit];

  return length * coef;
}
