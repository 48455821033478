import React from 'react';
import { PrepWorkflows } from 'rapidfab/components/manage';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import { getNonCustomPrepWorkflows } from 'rapidfab/selectors';
import PropTypes from 'prop-types';

const PrepWorkflowsContainer = ({ queryParams, ...restProps }) => (
  <PrepWorkflows
    initialPrepWorkflowUUID={queryParams?.uuid}
    {...restProps}
  />
);

PrepWorkflowsContainer.propTypes = {
  queryParams: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
};

export default withRecordsListHandling(
  PrepWorkflowsContainer,
  getNonCustomPrepWorkflows,
  ['prep-workflow'],
);
