import { API_RESOURCES } from 'rapidfab/constants';

/**
 * returns Options for list of records page
 * based on current user feature flags and provided entity key
 *
 * @param {Object} params - initialization params
 * @param {string} params.storeKey - oneOf RESOURCES.nautilus entity keys
 * @param {boolean} params.isDanfossUser - whether current user is Danfoss user or not
 *
 * @returns {
 *   {
 *     useLocationFiltering: boolean,
 *     defaultLimit: number,
 *     searchBy: string,
 *     defaultSort: string,
 *   }
 * }
 */
export default ({
  storeKey,
  isDanfossUser = false,
}) => {
  const options = {
    defaultLimit: 25,
    searchBy: 'name',
    defaultSort: '-updated',
    useLocationFiltering: true,
    multicolumnSearch: false,
  };

  switch (storeKey) {
    case API_RESOURCES.ORDER:
      options.defaultLimit = 50;
      delete options.searchBy;
      options.multicolumnSearch = true;
      if (isDanfossUser) {
        options.defaultSort = '-created';
      }
      break;
    case API_RESOURCES.WORKFLOW:
      options.useLocationFiltering = false;
      break;
    case API_RESOURCES.PREP_WORKFLOW:
      options.useLocationFiltering = false;
      break;
    case API_RESOURCES.MATERIAL_BATCH:
      delete options.searchBy;
      options.multicolumnSearch = true;
      break;
    case API_RESOURCES.SERVICE_PROVIDER_JOB:
      options.searchBy = 'order_name';
      delete options.searchBy;
      options.multicolumnSearch = true;
      options.useLocationFiltering = false;
      break;
    case API_RESOURCES.RUN:
      delete options.searchBy;
      options.multicolumnSearch = true;
      break;
    case API_RESOURCES.POST_PROCESSOR:
      options.defaultSort = 'name';
      delete options.searchBy;
      options.multicolumnSearch = true;
      break;
    case API_RESOURCES.MATERIAL_LOT:
      delete options.searchBy;
      options.multicolumnSearch = true;
      break;
    case API_RESOURCES.PIECE:
      delete options.searchBy;
      options.multicolumnSearch = true;
      break;
    case 'piece':
      options.multicolumnSearch = true;
      break;
    case 'stock':
      options.multicolumnSearch = true;
      break;
    default:
      break;
  }

  return options;
};
