import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import {
  Button,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  Row,
} from 'react-bootstrap';
import Actions from 'rapidfab/actions';
import { getDocumentsForResourceUuid, getUploadModel, getUsersByUri, isFeatureEnabled } from 'rapidfab/selectors';
import extractUuid from 'rapidfab/utils/extractUuid';
import { API_RESOURCES, DOCUMENT_RELATED_TABLE_NAMES, FEATURES } from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';
import Alert from 'rapidfab/utils/alert';
import FileInput from 'rapidfab/components/records/order/edit/FileInput';
import { FormattedMessage } from 'rapidfab/i18n';
import Fa from 'react-fontawesome';
import _map from 'lodash/map';
import { uniq } from 'lodash/array';
import DocumentVersionsModal from './DocumentVersionsModal';
import DocumentItem from './DocumentItem';

class Documents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      upload: null,
      uploadLocation: null,

      isUploading: false,
      activeDocumentModalUri: null,
    };

    this.onChange = this.onChange.bind(this);
    this.onPreventRefresh = this.onPreventRefresh.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.uploadDocument = this.uploadDocument.bind(this);
    this.initialize = this.initialize.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
    this.setActiveDocumentModalUri = this.setActiveDocumentModalUri.bind(this);
  }

  componentDidMount() {
    if (!this.props.skipInitialize) {
      this.initialize();
    }
  }

  componentDidUpdate(prevProps) {
    const { upload, uploadLocation, isUploading } = this.state;
    const { uploadModel, relatedUUID } = this.props;

    this.onPreventRefresh();

    if (prevProps.relatedUUID && relatedUUID !== prevProps.relatedUUID) {
      this.initialize();
    }

    if (
      uploadLocation &&
      uploadModel.uploadLocation === uploadLocation &&
      !_isEqual(uploadModel, prevProps.uploadModel) && uploadModel.uploading !== isUploading
    ) {
      const updatedFields = { isUploading: uploadModel.uploading };

      if (!uploadModel.uploading) {
        Alert.success(
          <FormattedMessage
            id="toaster.fileUploadSuccessfullyUpdated"
            defaultMessage="File {uploadName} successfully uploaded"
            values={{ uploadName: upload.name }}
          />);
        updatedFields.upload = null;
        updatedFields.uploadLocation = null;
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(updatedFields);
    }
  }

  onChange(event) {
    const upload = event.target.files[0];
    this.setState({
      upload,
    });
  }

  onPreventRefresh() {
    window.onbeforeunload = () => {
      if (this.state.isUploading) {
        return 'Reloading this site will interrupt active file uploads, and changes may not be saved.';
      }
      return null;
    };
  }

  async onDelete(uuid) {
    const { dispatch } = this.props;
    await dispatch(Actions.Api.nautilus[API_RESOURCES.DOCUMENT].delete(uuid));
  }

  setActiveDocumentModalUri(activeDocumentModalUri) {
    this.setState({ activeDocumentModalUri });
  }

  initialize() {
    const { relatedTable, relatedUUID } = this.props;
    this.props.dispatch(
      Actions.Api.nautilus[API_RESOURCES.DOCUMENT].list({
        related_table_name: relatedTable,
        related_uuid: relatedUUID,
      }),
    ).then(response => {
      const { resources } = response.json;
      this.fetchUsers(resources);
    });
  }

  fetchUsers(users) {
    const userUris = uniq(_map(users, 'user'));

    if (userUris.length) {
      this.props.dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({ uri: userUris }));
    }
  }

  async uploadDocument() {
    const { dispatch, relatedUUID, relatedTable, usersByUri } = this.props;
    const { upload } = this.state;
    const { name } = upload;

    const runDocumentPostResponse = await dispatch(
      Actions.Api.nautilus[API_RESOURCES.DOCUMENT].post({
        name,
        related_uuid: relatedUUID,
        related_table_name: relatedTable,
      }),
    );

    const uuid = extractUuid(runDocumentPostResponse.headers.location);
    const { uploadLocation } = runDocumentPostResponse.headers;
    this.setState({ uploadLocation });

    await dispatch(Actions.UploadModel.upload(uploadLocation, upload));
    const documentResponse = await dispatch(
      Actions.Api.nautilus[API_RESOURCES.DOCUMENT].get(uuid, true),
    );
    const shouldFetchNewUsers = !usersByUri[documentResponse?.json?.user];

    if (shouldFetchNewUsers) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS]
        .list({ uri: documentResponse?.json?.user }));
    }
  }

  render() {
    const { onChange, onDelete, uploadDocument, setActiveDocumentModalUri } = this;
    const { upload, isUploading, activeDocumentModalUri } = this.state;

    const { documents,
      panelTitle = 'Additional Documents',
      panelStyle,
      usersByUri,
      uploadButtonIconOnly,
      isHawkingUser,
      isInverse } = this.props;

    const uploadPrompt = uploadButtonIconOnly
      ? <Fa name="upload" />
      : <FormattedMessage id="button.upload" defaultMessage="Upload" />;

    return (
      <Card bg={isInverse ? 'light' : 'dark'} className="m-b">
        <Card.Header className={isInverse ? 'pd-exp inverse' : 'pd-exp accent'}>{panelTitle}</Card.Header>
        <div className={isInverse ? 'card-body-wrapper' : 'card-body-wrapper-accent'}>
          <Card.Body>
            <ListGroup fill>
              {documents.map(document => (
                <ListGroupItem key={document.uri}>
                  <DocumentItem
                    document={document}
                    onDelete={onDelete}
                    key={document.uri}
                    openVersionModal={() =>
                      setActiveDocumentModalUri(document.uri)}
                    usersByUri={usersByUri}
                    isHawkingUser={isHawkingUser}
                  />
                </ListGroupItem>
              ))}
            </ListGroup>

            <Row>
              <Col sm={{ span: 9 }}>
                <FileInput
                  fileType={FileInput.fileTypes.document}
                  handleFileChange={onChange}
                  name={upload && upload.name}
                />
              </Col>
              <Col sm={{ span: 3 }}>
                <Button
                  variant={panelStyle}
                  block
                  className="pull-right mt15"
                  disabled={!upload}
                  onClick={uploadDocument}
                >
                  {isUploading ? <Loading /> : uploadPrompt}
                </Button>
              </Col>
            </Row>

            {activeDocumentModalUri && (
              <DocumentVersionsModal
                uri={activeDocumentModalUri}
                handleClose={() => setActiveDocumentModalUri(null)}
                setActiveDocumentModalUri={setActiveDocumentModalUri}
              />
            )}
          </Card.Body>
        </div>
      </Card>
    );
  }
}

Documents.defaultProps = {
  panelTitle: 'Additional Documents',
  panelStyle: 'primary',
  uploadButtonIconOnly: false,
};

Documents.propTypes = {
  dispatch: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  uploadModel: PropTypes.shape({
    uploadLocation: PropTypes.string,
    uploading: PropTypes.bool,
  }).isRequired,
  relatedTable: PropTypes.oneOf(Object.values(DOCUMENT_RELATED_TABLE_NAMES)).isRequired,
  relatedUUID: PropTypes.string.isRequired,
  panelStyle: PropTypes.oneOf(['primary', 'default']),
  panelTitle: PropTypes.string,
  usersByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
  })).isRequired,
  uploadButtonIconOnly: PropTypes.bool,
  isHawkingUser: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  usersByUri: getUsersByUri(state),
  uploadModel: getUploadModel(state),
  documents: getDocumentsForResourceUuid(state, ownProps.relatedUUID),
  isHawkingUser: isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT),
});

Documents.defaultProps = {
  skipInitialize: false,
};

Documents.propTypes = {
  isInverse: PropTypes.bool.isRequired,
  skipInitialize: PropTypes.bool,
};

export default connect(mapStateToProps)(Documents);
