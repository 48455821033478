import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, Col, Row } from 'react-bootstrap';

const KEY_ENTER = 13;

const Search = ({
  initialSearch,
  onSearch,
}) => {
  const [searchValue, setSearchValue] = useState(initialSearch);

  useEffect(() => {
    if (initialSearch !== searchValue) {
      setSearchValue(initialSearch);
    }
  }, [initialSearch]);

  const onSearchChange = newSearchValue => {
    setSearchValue(newSearchValue);
  };

  const onKeyPress = target => {
    if (target.charCode === KEY_ENTER) {
      onSearch(searchValue);
    }
  };

  return (
    <Row>
      <Col xs={8} sm={9} lg={8}>
        <FormControl
          type="text"
          name="search"
          placeholder="Search"
          onChange={event => onSearchChange(event.target.value)}
          onKeyPress={onKeyPress}
          value={searchValue}
        />
      </Col>
      <Col xs={4} sm={3} lg={4}>
        <Button
          className="record-list-search-btn"
          variant="primary"
          onClick={() => onSearch(searchValue)}
        >
          Submit
        </Button>
      </Col>
    </Row>
  );
};

Search.defaultProps = {
  initialSearch: '',
};

Search.propTypes = {
  initialSearch: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};

export default Search;
