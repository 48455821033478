import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as BS from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import 'rapidfab/styles/main.scss';
import _reduce from 'lodash/reduce';
import Loading from 'rapidfab/components/Loading';
import getEndpointFromURI from 'rapidfab/utils/getEndpointFromURI';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import {
  ACCESS_INFO_ACTION_TYPES,
  API_RESOURCES,
  PROCESS_STEP_SHIPPING_DIRECTION,
  RUN_OPERATIONS,
  WORKFLOW_TYPES,
} from 'rapidfab/constants';
import Fa from 'react-fontawesome';
import DisabledByAccessInfoCheck from 'rapidfab/components/DisabledByAccessInfoCheck';

const isSpecificWorkstationAllowed = processType => {
  const { endpointName } = getEndpointFromURI(processType.uri);

  switch (endpointName) {
    case API_RESOURCES.PRINTER_TYPE:
      return true;
    case API_RESOURCES.POST_PROCESSOR_TYPE:
      return !processType.is_assembly;
    case API_RESOURCES.SHIPPING:
      return false;
    default:
      throw new Error(`Unknown endpoint ${endpointName} was passed to "isSpecificWorkstationAllowed".`);
  }
};

// Removes outsource shipping process steps from steps list
// related to provided post processor outsource process step index
const removeRelatedOutsourceShippingSteps = (processTypes, outsourceStepIndex) => {
  const updatedProcessTypes = [...processTypes];
  const nextStepIndex = outsourceStepIndex + 1;
  const nextStep = updatedProcessTypes[nextStepIndex];
  if (nextStep && nextStep.shippingDirection
    === PROCESS_STEP_SHIPPING_DIRECTION.SERVICE_PROVIDER_TO_BUREAU) {
    updatedProcessTypes.splice(nextStepIndex, 1);
  }

  const previousStepIndex = outsourceStepIndex - 1;
  const previousStep = updatedProcessTypes[previousStepIndex];
  if (previousStep && previousStep.shippingDirection
    === PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER) {
    updatedProcessTypes.splice(previousStepIndex, 1);
  }

  return updatedProcessTypes;
};

class WorkflowPickListModal extends Component {
  constructor(props) {
    super(props);
    const { printerTypes, postProcessorTypes, shippingTypes } = this.props;
    this.state = {
      allProcessTypes: [
        ...printerTypes,
        ...postProcessorTypes,
        ...shippingTypes,
      ],
      selectedProcessTypes: [],
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.handleSelected = this.handleSelected.bind(this);
    this.removeSelectedAt = this.removeSelectedAt.bind(this);
    this.refreshProcessSteps = this.refreshProcessSteps.bind(this);
    this.selectWorkstationCheckboxChanged = this.selectWorkstationCheckboxChanged.bind(this);
    this.selectWorkstation = this.selectWorkstation.bind(this);
    this.getAvailableProcessStepGroups = this.getAvailableProcessStepGroups.bind(this);
  }

  componentDidMount() {
    const {
      printerTypes, postProcessorTypes, shippingTypes,
      currentWorkflowSteps,
    } = this.props;

    const processTypes = [...printerTypes, ...postProcessorTypes, ...shippingTypes];

    if (currentWorkflowSteps.length) {
      this.refreshProcessSteps();
    }

    if (processTypes.length > 0) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        allProcessTypes: processTypes,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      printerTypes, postProcessorTypes, shippingTypes,
      currentWorkflowSteps,
    } = this.props;
    const { allProcessTypes } = this.state;

    if (
      (prevProps.printerTypes.length !== printerTypes.length) ||
      (prevProps.postProcessorTypes.length !== postProcessorTypes.length) ||
      (prevProps.shippingTypes.length !== shippingTypes.length)
    ) {
      const processTypes = [...printerTypes, ...postProcessorTypes, ...shippingTypes];
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        allProcessTypes: processTypes,
      });
    }

    if (
      prevProps.currentWorkflowSteps.length !== currentWorkflowSteps.length ||
      prevState.allProcessTypes.length !== allProcessTypes.length
    ) {
      this.refreshProcessSteps();
    }
  }

  handleSelected(selectedProcessTypeId) {
    const {
      allProcessTypes,
      selectedProcessTypes,
    } = this.state;

    const { shippingTypes, printerTypes } = this.props;

    const selectedProcessType = allProcessTypes.find(
      step => step.id === selectedProcessTypeId,
    );

    const isShippingProcessType = shippingTypes.find(
      type => type.id === selectedProcessTypeId,
    );

    const isPrintingProcessType = printerTypes.find(
      type => type.id === selectedProcessTypeId,
    );

    // Due to a validation for adding the Printing Step only at the beginning of the list.
    if (isPrintingProcessType) {
      this.setState({
        selectedProcessTypes: [
          selectedProcessType,
          ...selectedProcessTypes,
        ],
      });
    } else {
      this.setState({
        selectedProcessTypes: [
          ...selectedProcessTypes,
          {
            ...selectedProcessType,
            // Shipping direction is `bureau_to_customer`
            // for all selected manually shipping steps for now
            shippingDirection:
              isShippingProcessType ? PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_CUSTOMER : null,
          },
        ],
      });
    }
  }

  handleSelectedOutsourceShipping(selectedProcessTypeId, index, shippingDirection) {
    const {
      allProcessTypes,
      selectedProcessTypes,
    } = this.state;

    const selectedProcessType = allProcessTypes.find(
      step => step.id === selectedProcessTypeId,
    );

    const changedSelectedProcessSteps = [...selectedProcessTypes];

    // selectedProcessType is not found when `None` is selected from dropdown
    if (!selectedProcessType) {
      if (selectedProcessTypes[index]
        && selectedProcessTypes[index].shippingDirection === shippingDirection) {
        // Remove outsource shipping step when there was one previously and "None" selected now
        changedSelectedProcessSteps.splice(index, 1);
      }
    } else {
      const stepWithShippingDirection = {
        ...selectedProcessType,
        shippingDirection,
      };
      if (selectedProcessTypes[index]
        && selectedProcessTypes[index].shippingDirection === shippingDirection) {
        // Replace Outsource shipping step when there was one previously
        changedSelectedProcessSteps[index] = stepWithShippingDirection;
      } else {
        const indexToInsert =
          shippingDirection === PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER
            // For `to` shipping step - it needs to be inserted
            // into current Post Processor Outsource index (expected Shipping Index + 1)
            ? index + 1
            // For 'from' shipping step -
            // insert right after current Post Processor Outsource index (expected Shipping index)
            : index;
        // Add new Outsource shipping step when there was none previously
        changedSelectedProcessSteps.splice(indexToInsert, 0, stepWithShippingDirection);
      }
    }

    this.setState({
      selectedProcessTypes: changedSelectedProcessSteps,
    });
  }

  onSubmit(event) {
    event.preventDefault();
    const { selectedProcessTypes } = this.state;
    const { bureauUri } = this.props;
    const convertedSteps = selectedProcessTypes.map(step => (
      {
        notes: 'optional',
        upload: 'optional',
        success: 'optional',
        tracking_id: 'hidden',
        workstation_type_uri: step.uri,
        // Set workstation URI only if checkbox is checked
        workstation: step.specificWorkstation ? step.workstationUri : null,
        shipping_direction: step.shippingDirection,
        bureau: bureauUri,
      }),
    );
    this.props.onSubmit(convertedSteps);
  }

  getAvailableProcessStepGroups() {
    const { workflowType, printerTypes, postProcessorTypes, shippingTypes } = this.props;
    const { selectedProcessTypes } = this.state;

    // TODO: Legacy sorting code. Refactoring is probably required.
    const sortByName = (a, b) => a.name.localeCompare(b.name);
    printerTypes.sort(sortByName);
    postProcessorTypes.sort(sortByName);
    shippingTypes.sort(sortByName);

    const availablePostProcessorTypes = workflowType === WORKFLOW_TYPES.ASSEMBLY
      // For Assembly - show only assembly post processors types
      ? postProcessorTypes.filter(({ is_assembly }) => is_assembly)
      // Show non-assembly post processors types otherwise
      : postProcessorTypes.filter(({ is_assembly }) => !is_assembly);

    const isPrinterTypeSelected =
      selectedProcessTypes.some(processType =>
        processType.uri.includes('printer-type'),
      );

    const isPostProcessorTypeSelected =
      selectedProcessTypes.some(processType =>
        getEndpointFromURI(processType.uri).endpointName === API_RESOURCES.POST_PROCESSOR_TYPE,
      );

    const printerTypesGroup = {
      label: 'Printer Types',
      operation: RUN_OPERATIONS.PRINTING,
      processTypes: printerTypes,
    };
    const postProcessorTypesGroup = {
      label: 'Post-Processor Types',
      operation: RUN_OPERATIONS.POST_PROCESSING,
      processTypes: availablePostProcessorTypes,
    };
    const shippingGroup = {
      label: 'Shipping Types',
      operation: RUN_OPERATIONS.SHIPPING,
      processTypes: shippingTypes,
    };

    switch (workflowType) {
      case WORKFLOW_TYPES.ADDITIVE_MANUFACTURING:
        if (!isPrinterTypeSelected) {
          return [printerTypesGroup];
        }
        return [postProcessorTypesGroup, shippingGroup];
      case WORKFLOW_TYPES.SPECIMEN:
        return [postProcessorTypesGroup, shippingGroup];
      case WORKFLOW_TYPES.ASSEMBLY:
        if (!isPostProcessorTypeSelected) {
          return [postProcessorTypesGroup];
        }
        return [shippingGroup];
      default:
        // Switch requires a default. Anyway, it should not be available in real life.
        return [];
    }
  }

  refreshProcessSteps() {
    /*
     * Will update state (selectedProcessTypes) with all up-to-date process steps
     * based on props (currentWorkflowSteps)
     */
    const { currentWorkflowSteps, processTypesByUri } = this.props;

    const selectedProcessTypes = _reduce(currentWorkflowSteps, (result, workflowStep) => {
      // printer/post-processor types from props contain only Available for Adding ones
      // (with printers/post-processors attached)
      // Using processTypesByUri here since Process Type might be added some time ago
      // and then become non-available (e.g. printer is removed)
      const currentProcessType = processTypesByUri[workflowStep.workstation_type_uri];
      if (currentProcessType) {
        result.push({
          ...currentProcessType,
          workstationUri: workflowStep.workstation,
          specificWorkstation: Boolean(workflowStep.workstation),
          shippingDirection: workflowStep.shipping_direction,
        });
      }

      return result;
    }, []);

    this.setState({
      selectedProcessTypes,
    });
  }

  removeSelectedAt(index) {
    this.setState(prevState => ({
      selectedProcessTypes: prevState.selectedProcessTypes.filter(
        (processType, currentIndex) => {
          if (index === currentIndex) {
            // Exclude selected At
            return false;
          }

          if (
            currentIndex === (index - 1)
            && processType.shippingDirection
              === PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER
          ) {
            // Additionally remove previous step, if that is `to` shipping
            return false;
          }

          if (
            currentIndex === (index + 1)
            && processType.shippingDirection
              === PROCESS_STEP_SHIPPING_DIRECTION.SERVICE_PROVIDER_TO_BUREAU
          ) {
            // Additionally remove next step, if that is `from` shipping
            return false;
          }

          // Include all other steps
          return true;
        },
      ),
    }));
  }

  selectWorkstationCheckboxChanged(index) {
    let changedSelectedProcessTypes = [...this.state.selectedProcessTypes];
    const changedSelectedProcessType = changedSelectedProcessTypes[index];
    changedSelectedProcessType.specificWorkstation =
      !changedSelectedProcessType.specificWorkstation;

    // If Specific Workstation checkbox unchecked clear selected workstation
    // and related outsource shipping steps (if any)
    if (!changedSelectedProcessType.specificWorkstation) {
      const { workstationsByUri } = this.props;
      // If previously selected workstation is an outsource one clear related shipping steps
      const selectedWorkstation = workstationsByUri[changedSelectedProcessType.workstationUri];
      const isOutsourceWorkstation = selectedWorkstation && selectedWorkstation.is_service;

      if (isOutsourceWorkstation) {
        changedSelectedProcessTypes =
          removeRelatedOutsourceShippingSteps(changedSelectedProcessTypes, index);
      }

      changedSelectedProcessType.workstationUri = null;
    }

    this.setState({
      selectedProcessTypes: changedSelectedProcessTypes,
    });
  }

  selectWorkstation(name, uri) {
    // `name` is actually a `string` index from the SingleSelect
    const index = Number(name);
    let changedSelectedProcessTypes = [...this.state.selectedProcessTypes];
    const changedSelectedProcessType = changedSelectedProcessTypes[index];

    // If workstation URI is changed from Outsource
    // to some other one - clear related shipping steps
    if (changedSelectedProcessType.workstationUri) {
      const { workstationsByUri } = this.props;
      // If previously selected workstation is an outsource one clear related shipping steps
      const selectedWorkstation = workstationsByUri[changedSelectedProcessType.workstationUri];
      const isOutsourceWorkstation = selectedWorkstation && selectedWorkstation.is_service;

      if (isOutsourceWorkstation) {
        changedSelectedProcessTypes =
          removeRelatedOutsourceShippingSteps(changedSelectedProcessTypes, index);
      }
    }

    changedSelectedProcessType.workstationUri = uri || null;
    this.setState({
      selectedProcessTypes: changedSelectedProcessTypes,
    });
  }

  render() {
    const {
      show,
      onClose,
      shippingTypes,
      sending,
      fetching,
      workstationsGroupedByType,
      currentProcessStepPosition,
      setChangeWorkflowNotes,
      notes,
      showChangeWorkflowNotes,
      workstationsByUri,
    } = this.props;

    const { selectedProcessTypes } = this.state;

    const isWorkflowSubmitDisabled = sending || fetching || (showChangeWorkflowNotes && !notes);

    const processTypeGroups = this.getAvailableProcessStepGroups();

    const ProcessType = ({ processType, isDisabled, index }) => {
      const listGroupClasses = ['picklist-group-item'];
      if (isDisabled) {
        listGroupClasses.push('picklist-group-item__disabled');
      }

      return (
        <BS.ListGroupItem
          className={listGroupClasses}
          id={processType.id}
          // eslint-disable-next-line react/no-array-index-key
          key={`${processType.id}+${index}`}
        >
          <BS.Row className="picklist-group-item-row">
            <BS.Col xs={1}>
              {
                !isDisabled && (
                  <span
                    tabIndex={index}
                    role="button"
                    onClick={() => this.removeSelectedAt(index)}
                  >-
                  </span>
                )
              }
            </BS.Col>
            <BS.Col xs={9}>
              {(!processType.specificWorkstation ||
                (processType.specificWorkstation && !isSpecificWorkstationAllowed(processType))) && (
                <span>
                  {processType.name}
                </span>
              )}
              {processType.specificWorkstation && isSpecificWorkstationAllowed(processType) && (
                <SelectSingle
                  name={String(index)}
                  data={workstationsGroupedByType[processType.uri] || []}
                  value={processType.workstationUri}
                  handleOnChange={this.selectWorkstation}
                />
              )}
            </BS.Col>
            {isSpecificWorkstationAllowed(processType) && (
              <BS.Col xs={2}>
                <input
                  id={`selectWorkstationCheckbox${index}`}
                  className="pull-right"
                  checked={processType.specificWorkstation || false}
                  type="checkbox"
                  disabled={isDisabled}
                  onChange={() => this.selectWorkstationCheckboxChanged(index)}
                />
              </BS.Col>
            )}
            {processType.shippingDirection === PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_CUSTOMER
            && (
              <BS.Col xs={2}>
                <Fa name="arrow-right pull-right" />
              </BS.Col>
            )}
          </BS.Row>
        </BS.ListGroupItem>
      );
    };

    const OutsourceShippingStep = ({ processStep, index, shippingDirection }) => {
      const processStepId = processStep && processStep.id;
      return (
        <BS.ListGroupItem
          className="picklist-group-item"
          id={processStepId}
          key={`${processStepId}+${index}`}
        >
          <BS.Row className="picklist-group-item-row">
            <BS.Col xs={1}>
              {shippingDirection === PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER
                && (<Fa name="level-down" />)}
              {shippingDirection === PROCESS_STEP_SHIPPING_DIRECTION.SERVICE_PROVIDER_TO_BUREAU
                && (<Fa name="level-up" />)}
            </BS.Col>
            <BS.Col xs={9}>
              <SelectSingle
                name={String(index)}
                data={shippingTypes}
                valueKey="id"
                value={processStepId}
                handleOnChange={
                  (name, value) =>
                    this.handleSelectedOutsourceShipping(value, index, shippingDirection)
                }
              />
            </BS.Col>
          </BS.Row>
        </BS.ListGroupItem>
      );
    };

    const ProcessTypes = () => (
      <BS.ListGroup>
        {
          selectedProcessTypes.map((processType, index) => {
            const isOutsourceShipping = processType.shippingDirection
              && [
                PROCESS_STEP_SHIPPING_DIRECTION.SERVICE_PROVIDER_TO_BUREAU,
                PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER,
              ].includes(processType.shippingDirection);

            if (isOutsourceShipping) {
              // Outsource shipping steps are rendered with the Outsource process step.
              return null;
            }

            // Process step can't be edited
            //   when currentProcessStepPosition lower than index
            // Index starts from 0, but current step position from 1
            const processStepPosition = (index + 1);
            const isDisabled = currentProcessStepPosition &&
                  currentProcessStepPosition >= processStepPosition;

            const selectedWorkstation = workstationsByUri[processType.workstationUri];
            const isOutsourceWorkstation = selectedWorkstation && selectedWorkstation.is_service;

            let toShippingStep = null;
            let fromShippingStep = null;
            if (isOutsourceWorkstation) {
              const previousStep = selectedProcessTypes[index - 1];
              const nextStep = selectedProcessTypes[index + 1];
              if (previousStep && previousStep.shippingDirection
                === PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER) {
                toShippingStep = previousStep;
              }

              if (nextStep && nextStep.shippingDirection
                  === PROCESS_STEP_SHIPPING_DIRECTION.SERVICE_PROVIDER_TO_BUREAU) {
                fromShippingStep = nextStep;
              }
            }

            return (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={`${processType.uri}-${index}`}>
                {isOutsourceWorkstation && (
                  <OutsourceShippingStep
                    processStep={toShippingStep}
                    index={index - 1}
                    shippingDirection={PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER}
                  />
                )}
                <ProcessType
                  processType={processType}
                  index={index}
                  isDisabled={isDisabled}
                />
                {isOutsourceWorkstation && (
                  <OutsourceShippingStep
                    processStep={fromShippingStep}
                    index={index + 1}
                    shippingDirection={PROCESS_STEP_SHIPPING_DIRECTION.SERVICE_PROVIDER_TO_BUREAU}
                  />
                )}
              </React.Fragment>
            );
          },
          )
        }
      </BS.ListGroup>
    );

    return (
      <BS.Modal show={show} onHide={onClose} bsSize="large" className="picklistModal" backdrop="static">
        <form onSubmit={this.onSubmit}>
          <BS.Modal.Header closeButton className="text-left">
            <BS.Modal.Title>Add Production Workflow Steps</BS.Modal.Title>
          </BS.Modal.Header>
          <BS.Modal.Body>
            {
              showChangeWorkflowNotes && (
                <BS.Row>
                  <BS.Col xs={12} sm={12} lg={12}>
                    <div className="well picklist-well" style={{ boxShadow: 'none' }}>
                      <BS.FormGroup>
                        <p className="pickList-p">Reason for Changing Production Workflow*</p>
                        <BS.FormControl
                          componentClass="textarea"
                          style={{ height: 60 }}
                          placeholder="Notes"
                          type="text"
                          id="remanufactureNotes"
                          name="remanufactureNotes"
                          value={notes}
                          onChange={event => setChangeWorkflowNotes(event)}
                        />
                      </BS.FormGroup>
                    </div>
                  </BS.Col>
                </BS.Row>
              )
            }
            <BS.Row>
              <BS.Col xs={6} sm={6} lg={6}>
                <p className="pickList-p">Available Steps</p>
                {
                  fetching ?
                    <Loading />
                    : (
                      <div className="well picklist-well" style={{ boxShadow: 'none' }}>
                        {processTypeGroups
                          .map(({
                            label,
                            operation,
                            processTypes,
                          }) => (
                            <BS.ListGroup key={label}>
                              <p className="pickList-p">{label}</p>
                              {processTypes.map((processType, index) => (
                                <DisabledByAccessInfoCheck
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={`${processType.id}+${index}`}
                                  resourceUri={
                                    // Prevent running checks for shipping, since it is not supported for now
                                    operation !== RUN_OPERATIONS.SHIPPING ? processType.uri : null
                                  }
                                  ignoreDisabledPrefixRendering
                                  actionType={ACCESS_INFO_ACTION_TYPES.USE}
                                >
                                  {({
                                    disabled,
                                    disabledPrefix,
                                  }) => (
                                    <BS.ListGroupItem
                                      className="picklist-group-item"
                                      id={processType.id}
                                      disabled={disabled}
                                      onClick={() => !disabled && this.handleSelected(processType.id)}
                                    >
                                      <BS.Row className="picklist-group-item-row">
                                        <BS.Col xs={1}>
                                          {
                                            disabled
                                              ? disabledPrefix
                                              // Duplicating disabledPrefix classes to make UI consistent
                                              : (<span className="spacer-left spacer-right">+</span>)
                                          }
                                        </BS.Col>
                                        <BS.Col xs={11}>
                                          {processType.name}
                                        </BS.Col>
                                      </BS.Row>
                                    </BS.ListGroupItem>
                                  )}
                                </DisabledByAccessInfoCheck>
                              ))}
                            </BS.ListGroup>
                          ))}
                      </div>
                    )
                }
              </BS.Col>
              <BS.Col xs={6} sm={6} lg={6}>
                <p className="pickList-p">Selected Steps</p>
                <BS.Row>
                  <BS.Col xs={6} xsOffset={6}>
                    <p>Specific Workstation</p>
                  </BS.Col>
                </BS.Row>
                {
                  fetching ?
                    <Loading />
                    : (
                      <div className="well picklist-well">
                        <ProcessTypes />
                      </div>
                    )
                }
              </BS.Col>
            </BS.Row>
          </BS.Modal.Body>
          <BS.Modal.Footer>
            <BS.Button onClick={onClose}>
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
            </BS.Button>
            <BS.Button
              type="submit"
              bsStyle="success"
              disabled={isWorkflowSubmitDisabled}
            >
              {
                sending ?
                  <Loading />
                  :
                  <FormattedMessage id="button.save" defaultMessage="Save" />
              }
            </BS.Button>
          </BS.Modal.Footer>
        </form>
      </BS.Modal>
    );
  }
}

WorkflowPickListModal.defaultProps = {
  currentProcessStepPosition: null,
  fetching: false,
  sending: false,
  setChangeWorkflowNotes: () => {},
  notes: '',
  showChangeWorkflowNotes: false,
};

WorkflowPickListModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentProcessStepPosition: PropTypes.number,
  // currentWorkflowSteps requires list of process steps
  currentWorkflowSteps: PropTypes.arrayOf(PropTypes.shape({
    // URIs eg [/printer-type/, /shipping/, /post-processor-type/]
    uri: PropTypes.string.isRequired,
    workstation: PropTypes.string,
  })).isRequired,
  fetching: PropTypes.bool,
  printerTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  postProcessorTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sending: PropTypes.bool,
  shippingTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  workstationsGroupedByType: PropTypes.shape({}).isRequired,
  workstationsByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    is_service: PropTypes.bool,
    shipping_direction: PropTypes.string,
  })).isRequired,
  setChangeWorkflowNotes: PropTypes.func,
  notes: PropTypes.string,
  showChangeWorkflowNotes: PropTypes.bool,
  workflowType: PropTypes.oneOf(Object.values(WORKFLOW_TYPES)).isRequired,
  processTypesByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  bureauUri: PropTypes.string.isRequired,
};

export default WorkflowPickListModal;
