import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';

export const getBuildFiles = createSelector(
  [baseStateSelectors.getStateBuildFiles, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getActiveBuildFiles = createSelector(
  [getBuildFiles],
  buildFiles => _filter(buildFiles, { archived: null }),
);

export const getBuildFilesByUri = createSelector([getBuildFiles], resources =>
  _keyBy(resources, 'uri'),
);
