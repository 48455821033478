import getRouteURI from 'rapidfab/utils/getRouteURI';
import { COMMENT_RELATED_TABLE_NAMES, ROUTES } from 'rapidfab/constants';

export default function getCommentRedirectUri(
  comment,
  isCurrentUserRestricted,
  isHawkingUser,
  isManager,
  isRecordOwner) {
  const {
    related_table_name: relatedTableName,
    related_uuid: relatedUUID,
  } = comment;

  let redirectUri = null;

  switch (relatedTableName) {
    case COMMENT_RELATED_TABLE_NAMES.ORDER:
      if (isCurrentUserRestricted) {
        redirectUri = getRouteURI(
          ROUTES.REVIEW_RESTRICTED_ORDER,
          { uuid: relatedUUID },
          { comment: comment.uuid },
        );
      } else {
        redirectUri = getRouteURI(
          ROUTES.ORDER_EDIT,
          { uuid: relatedUUID },
          { comment: comment.uuid },
        );
      }
      break;
    case COMMENT_RELATED_TABLE_NAMES.PREP_TASK_RECORD:
      redirectUri =
        getRouteURI(ROUTES.PREP_TASK_RECORDS, {}, { task: relatedUUID });
      break;
    case COMMENT_RELATED_TABLE_NAMES.MODEL_LIBRARY:
      if (isHawkingUser) {
        if (!isRecordOwner && isManager) {
          redirectUri = getRouteURI(ROUTES.HAWKING_ADMINISTRATOR_LIBRARY, {}, { uuid: relatedUUID });
        } else {
          redirectUri = getRouteURI(ROUTES.HAWKING_COMPANY_LIBRARY, {}, { uuid: relatedUUID });
        }
      } else {
        redirectUri = getRouteURI(ROUTES.MODEL_LIBRARY, {}, { uuid: relatedUUID });
      }
      break;
    default:
      break;
  }

  return redirectUri;
}
