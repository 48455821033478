import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';

import getShortUUID from 'rapidfab/utils/getShortUUID';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';

import PostProcessorFormContainer from 'rapidfab/containers/records/PostProcessorFormContainer';
import { getRouteUUID, getRouteUUIDResource, isServiceProvider } from 'rapidfab/selectors';
import { useDispatch, useSelector } from 'react-redux';
import Fa from 'react-fontawesome';
import Actions from 'rapidfab/actions';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { useParams } from 'react-router-dom';

const PostProcessorService = () => {
  const uuid = useSelector(getRouteUUID);
  const processorService = useSelector(getRouteUUIDResource);
  const isServiceProviderRole = useSelector(isServiceProvider);
  if (processorService && !processorService.is_service) {
    window.location.hash = getRouteURI(ROUTES.POST_PROCESSOR_EDIT, { uuid });
  }

  const { name } = processorService || {};
  const { uuid: currentUUID } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].get(currentUUID));
    }
  }, [currentUUID]);

  const labelSettings = {
    labelType: isServiceProviderRole ? 'info' : 'primary',
    labelText: isServiceProviderRole ? 'Incoming Request' : 'Outgoing Service',
    labelFaName: isServiceProviderRole ? 'arrow-circle-right' : 'arrow-circle-left',
  };
  return (
    <Container fluid>
      <BreadcrumbNav
        breadcrumbs={['inventory', 'postProcessorServices', uuid ? `${name} (${getShortUUID(uuid)})` : 'New']}
      />

      <div className="page-header">
        <h1 className="mb15">{name || 'New Processor Service'}</h1>
        <div className={`label label-${labelSettings.labelType}`}>
          <Fa name={labelSettings.labelFaName} /> {labelSettings.labelText}
        </div>
      </div>

      <PostProcessorFormContainer postProcessor={processorService} isService />
    </Container>
  );
};

export default PostProcessorService;
