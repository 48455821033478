import React from 'react';
import { useSelector } from 'react-redux';

import { getRouteUUIDResource, isFeatureEnabled } from 'rapidfab/selectors';
import { FEATURES } from 'rapidfab/constants';

import Printer from 'rapidfab/components/records/Printer';

const PrinterContainer = props => {
  const printer = useSelector(getRouteUUIDResource);
  const isGeneralMFGLanguageEnabled = useSelector(state =>
    (!printer ? isFeatureEnabled(state, FEATURES.GENERAL_MFG_LANGUAGE) : null));
  const name = printer?.name;
  const selected = {
    name,
    route: printer,
    isGeneralMFGLanguageEnabled,
  };

  return <Printer {...props} {...selected} />;
};

export default PrinterContainer;
