import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import _map from 'lodash/map';
import _flatMap from 'lodash/flatMap';
import _filter from 'lodash/filter';
import _uniq from 'lodash/uniq';

import * as Selectors from 'rapidfab/selectors';
import {
  API_RESOURCES,
  BUILD_PACKER_TYPES,
  PRINT_STATUSES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  RUN_STATUSES,
  USER_ROLES, LIST_BY_URIS_CHUNK_SIZE, MATERIAL_MODES,
} from 'rapidfab/constants';
import Actions from 'rapidfab/actions';

import RunRecord from 'rapidfab/components/records/run/RunRecord';
import hhmmss, { parseHhmmss } from 'rapidfab/utils/hhmmss';
import Alert from 'rapidfab/utils/alert';
import { runType } from 'rapidfab/types';
import {
  getRouteUUID,
  getRunEstimateForRun,
  getRunPrints,
  getUUIDResource,
} from 'rapidfab/selectors';
import _chunk from 'lodash/chunk';
import { FormattedMessage } from 'react-intl';

import buildURIFromUUID from 'rapidfab/utils/buildURIFromUUID';
import extractUuid from 'rapidfab/utils/extractUuid';

const RunRecordContainer = props => {
  const [showEstimationTimeEditModal, setShowEstimationTimeEditModal] = useState(false);
  const [estimatesPrintTime, setEstimatesPrintTime] = useState('');
  const [isEstimationsSubmitting, setIsEstimationsSubmitting] = useState(false);
  const [showActualsEditModal, setShowActualsEditModal] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);

  const uuid = useSelector(state => getRouteUUID(state));
  const run = useSelector(state => Selectors.getUUIDResource(state, uuid));
  const runMaterial = useSelector(state => Selectors.getRunMaterialsForRun(state, run));
  const runMaterialFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.RUN_MATERIAL].get.fetching);
  const prints = useSelector(state => getRunPrints(state, run));
  const currentUserRole = useSelector(Selectors.getCurrentUserRole);
  const isRunEmpty = !run || initialLoading;
  const build = useSelector(state => Selectors.getBuildForRun(state, run));
  const estimates = useSelector(Selectors.getRunEstimates);
  const runEstimates = useSelector(state => getRunEstimateForRun(state, run));
  const printerType = useSelector(state => getUUIDResource(state, extractUuid(run?.printer_type)));

  const runInfo = {
    id: run?.id,
    operation: run?.operation,
    uri: run?.uri,
    name: run?.name,
    status: run?.status,
    printCount: prints?.length && prints.filter(p => p.status !== PRINT_STATUSES.CANCELLED).length,
  };

  const selected = {
    uuid,
    build,
    isRunEmpty,
    estimates,
    runMaterialData: {
      runMaterial,
      runMaterialFetching,
    },
    run,
    // TODO: Use `run` object directly instead
    ...(run
      ? runInfo
      : null),
  };

  const dispatch = useDispatch();

  /* The API will send the pieces uris as chunks (several requests)
   and disable the pagination filter to have all the resources. */
  const loadPrintsByPiecesUris = piecesUris => {
    const promises = [];
    _chunk(piecesUris, LIST_BY_URIS_CHUNK_SIZE).forEach(pieceUri => {
      promises.push(dispatch(Actions.Api.nautilus[API_RESOURCES.PRINT]
        .list({ piece: pieceUri }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }, {}, {}, true)));
    });

    return Promise.all(promises);
  };

  const loadPrints = uri => {
    const printRequest = dispatch(
      Actions.Api.nautilus[API_RESOURCES.PRINT].list(
        {
          run: uri,
          work_needed: false,
        },
        { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
        {},
        {},
        true,
      ),
    );

    printRequest.then(printResponse => {
      const responsePrints = printResponse.json.resources;
      const workflowUris = _uniq(_map(responsePrints, 'workflow'));
      const pieceUris = _uniq(_map(responsePrints, 'piece'));
      const remanufactureAvailablePrints = _filter(_uniq(_map(responsePrints, 'remanufactured_to')), print => print !== undefined);

      dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list({ workflows: workflowUris }));

      // Needs to be refactored when Backend gets a change
      if (remanufactureAvailablePrints) {
        remanufactureAvailablePrints?.forEach(UUID => {
          const uri = buildURIFromUUID(API_RESOURCES.PRINT, UUID);
          dispatch(Actions.Api.nautilus[API_RESOURCES.PRINT].list(
            { uri },
            { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
            {},
            {},
            true,
          )).then(response => {
            const pieceURI = response?.json?.resources[0]?.piece;
            dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].list(
              { uri: pieceURI },
              { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
              {},
              {},
              true,
            ));
          });
        });

        // needs backend update to work correctly because remanufactured_to arent uris they are uuids

      //   dispatch(Actions.Api.nautilus[API_RESOURCES.PRINT].list(
      //     { uri: remanufactureAvailablePrints },
      //     { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      //     {},
      //     {},
      //     true,
      //   )).then(response => {
      //     const pieceURI = response?.json?.resources[0]?.piece;
      //     dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].list(
      //       { uri: pieceURI },
      //       { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      //       {},
      //       {},
      //       true,
      //     ));
      //   });
      }

      if (pieceUris.length) {
        // There might be cases when no prints are left in the run. E.g. those were removed via QR App
        // So request pieces only if there are any uris found
        dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].list(
          { uri: pieceUris },
          { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
          {},
          {},
          true,
        ));
      }

      const lineItemUris = _uniq(_map(responsePrints, 'line_item'));
      if (lineItemUris.length) {
        // There might be cases when no prints are left in the run. E.g. those were removed via QR App
        // So request line items only if there are any uris found
        dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].list(
          { uri: lineItemUris },
          { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
          {},
          {},
          true,
        ));
      }

      loadPrintsByPiecesUris(pieceUris)
        .then(printResponses => {
          const allPrints = _flatMap(printResponses, mappedPrints => mappedPrints.json.resources);
          const firstStepPrints = _filter(allPrints, { process_step_position: 1 });
          const runUris = _uniq(_map(firstStepPrints, 'run'))
            .filter(Boolean);
          if (runUris.length > 0) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].list({ uri: runUris }, {}, {}, {}, true));
            dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list());
            dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].list());
          }
        });
    });
  };

  const onInitialize = () => dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].get(uuid, true))
    .then(response => {
      if (response.json.post_processor_type) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list());
      }

      dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_ACTUALS].list(
        { run: response.json.uri },
        // Assuming there is 1-1 relation for run<->runActuals
        { limit: 1 },
      ));

      loadPrints(response.json.uri);

      dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].list(
        { run: response.json.uri },
      ));

      if (response.json.orders.length) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].list(
          { uri: response.json.orders },
          { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
          {},
          {},
          true,
        ));
      }
      dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD].list({ run: response.json.uri }));
    });

  useEffect(() => {
    if (
      printerType &&
      printerType.material_mode === MATERIAL_MODES.MULTI_MATERIAL &&
      run?.uri
    ) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_MATERIAL].get(extractUuid(run.uri), true));
    }
  }, [printerType, run?.uri]);

  useEffect(() => {
    if (runEstimates?.estimates) {
      const estimatesPrintSeconds = runEstimates.estimates.time.run_duration;
      const currentEstimatesPrintTime = hhmmss(estimatesPrintSeconds, true);
      setEstimatesPrintTime(currentEstimatesPrintTime);
    } else {
      setEstimatesPrintTime('');
    }
  }, [runEstimates]);

  useEffect(() => {
    setInitialLoading(true);
    onInitialize().finally(() => setInitialLoading(false));
  }, [uuid]);

  const onEstimationTimeChange = event => {
    setEstimatesPrintTime(event.target.value);
  };

  const onEstimationTimeSubmit = event => {
    event.preventDefault();
    const estimatedPrintSeconds = dayjs.duration(parseHhmmss(estimatesPrintTime)).asSeconds();

    const payload = {
      estimates: {
        time: {
          run_duration: estimatedPrintSeconds,
        },
      },
    };

    setIsEstimationsSubmitting(true);

    dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].put(runEstimates.uuid, payload))
      .then(() => {
        Alert.success(<FormattedMessage
          id="toaster.scheduleRuns.estimationsUpdated"
          defaultMessage="Estimations successfully updated"
        />);
        dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].get(runEstimates.uuid))
          .then(() => {
            setIsEstimationsSubmitting(false);
            setShowEstimationTimeEditModal(false);
          });
      })
      .catch(() => {
        setIsEstimationsSubmitting(false);
      });
  };

  const toggleEstimationModal = () => setShowEstimationTimeEditModal(previous => !previous);
  const toggleActualsModal = () => setShowActualsEditModal(previous => !previous);

  const estimations = {
    ...(runEstimates?.estimates || runEstimates),
    // If the Run was manually scheduled - it has higher priority over Schedule Runs entity
    ...(run && run.start ? { start: run.start } : {}),
    ...(run && run.finish ? { end: run.finish } : {}), //--
    baseMaterial: run?.materials_base,
    supportMaterial: run?.materials_support,
  };

  const isManager = currentUserRole === USER_ROLES.MANAGER;
  const canEditActuals = run?.status === RUN_STATUSES.COMPLETE && isManager;
  const isUserManagedPrinterType = run?.batch_type === BUILD_PACKER_TYPES.USER_MANAGED;

  return (
    <RunRecord
      {...props}
      {...selected}
      estimatesPrintTime={estimatesPrintTime}
      showEstimationTimeEditModal={showEstimationTimeEditModal}
      loadPrints={loadPrints}
      toggleEstimationModal={toggleEstimationModal}
      estimations={estimations}
      onEstimationTimeChange={onEstimationTimeChange}
      onEstimationTimeSubmit={onEstimationTimeSubmit}
      isEstimationsSubmitting={isEstimationsSubmitting}
      canEditActuals={canEditActuals}
      toggleActualsModal={toggleActualsModal}
      showActualsEditModal={showActualsEditModal}
      isUserManagedPrinterType={isUserManagedPrinterType}
    />
  );
};

RunRecordContainer.defaultProps = {
  estimates: [],
  uri: null,
  uuid: null,
  run: null,
};

RunRecordContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  estimates: PropTypes.arrayOf(PropTypes.shape({
    end: PropTypes.string,
    start: PropTypes.string,
    materials: PropTypes.shape({
      base: PropTypes.number,
      support: PropTypes.number,
    }),
    time: PropTypes.shape({
      run_duration: PropTypes.number,
      pre_run_duration: PropTypes.number,
      post_run_duration: PropTypes.number,
    }),
  })),
  uri: PropTypes.string,
  uuid: PropTypes.string,
  run: runType,
};

export default RunRecordContainer;
