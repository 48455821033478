import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Navbar, Row, Container } from 'react-bootstrap';
import _get from 'lodash/get';

import { isCurrentUserRestricted, isFeatureEnabled, isServiceProvider } from 'rapidfab/selectors';
import { FEATURES } from 'rapidfab/constants';
import NavLinksHawking from 'rapidfab/components/navbar/NavLinksHawking';
import NavLinksRegular from 'rapidfab/components/navbar/NavLinksRegular';
import NavbarBrandingContainer from 'rapidfab/containers/navbar/NavbarBrandingContainer';
import NavLinksRestricted from 'rapidfab/components/navbar/NavLinksRestricted';
import NavLinksServiceProvider from 'rapidfab/components/navbar/NavLinksServiceProvider';
import NavLinksDanfossDDW from 'rapidfab/components/navbar/NavLinksDanfossDDW';
import NavLinksStanleyX from 'rapidfab/components/navbar/NavLinksStanleyX';

const RapidfabNavbar = ({
  currentUser,
  locale,
  onChangeLocale,
  onLogout,
  onImpersonateStop,
  session,
  maEnabled,
  mesEnabled,
  serviceProvidersEnabled,
  specimenLibraryEnabled,
  materialManagementEnabled,
  isPrepWorkflowEnabled,
  isServiceProviderRole,
  isHawkingUser,
  isAuthentisePDMUser,
  isRestrictedUser,
  isWIPMatrixEnabled,
  isDanfossUser,
  isStanleyXUser,
  isDigitalDesignWarehouseFeatureEnabled,
}) => {
  /* eslint-disable spaced-comment */
  const isIE = /*@cc_on!@*/false || !!document.documentMode;
  /* eslint-enable spaced-comment */
  if (isIE && document.body) {
    /** Base margin for body is `60px` + summary height of added alert notice `30px` */
    document.body.style.marginTop = '90px';
  }

  const isImpersonating = _get(session, 'currentUser.impersonating');

  let navbarLinks;
  if (session && session.roles.length > 0) {
    if (isServiceProviderRole) {
      navbarLinks = (
        <NavLinksServiceProvider
          currentUser={currentUser}
          locale={locale}
          onChangeLocale={onChangeLocale}
          onLogout={onLogout}
          session={session}
          isImpersonating={isImpersonating}
          onImpersonateStop={onImpersonateStop}
        />
      );
    } else if (isRestrictedUser) {
      navbarLinks = (
        <NavLinksRestricted
          currentUser={currentUser}
          locale={locale}
          onChangeLocale={onChangeLocale}
          onImpersonateStop={onImpersonateStop}
          onLogout={onLogout}
          session={session}
        />
      );
    } else if (isHawkingUser || isAuthentisePDMUser) {
      navbarLinks = (
        <NavLinksHawking
          currentUser={currentUser}
          locale={locale}
          onChangeLocale={onChangeLocale}
          onImpersonateStop={onImpersonateStop}
          onLogout={onLogout}
          session={session}
        />
      );
    } else if (isStanleyXUser) {
      navbarLinks = (
        <NavLinksStanleyX
          currentUser={currentUser}
          locale={locale}
          onChangeLocale={onChangeLocale}
          onImpersonateStop={onImpersonateStop}
          onLogout={onLogout}
          session={session}
        />
      );
    } else if (isDigitalDesignWarehouseFeatureEnabled && isRestrictedUser) {
      // Restricted Danfoss users only
      navbarLinks = (
        <NavLinksDanfossDDW
          currentUser={currentUser}
          locale={locale}
          onChangeLocale={onChangeLocale}
          onLogout={onLogout}
          onImpersonateStop={onImpersonateStop}
          session={session}
          maEnabled={maEnabled}
          mesEnabled={mesEnabled}
          specimenLibraryEnabled={specimenLibraryEnabled}
          serviceProvidersEnabled={serviceProvidersEnabled}
          materialManagementEnabled={materialManagementEnabled}
          isPrepWorkflowEnabled={isPrepWorkflowEnabled}
        />
      );
    } else {
    // Non-service provider non-restricted user (regular bureau user)
      navbarLinks = (
        <NavLinksRegular
          currentUser={currentUser}
          locale={locale}
          onChangeLocale={onChangeLocale}
          onLogout={onLogout}
          onImpersonateStop={onImpersonateStop}
          session={session}
          maEnabled={maEnabled}
          mesEnabled={mesEnabled}
          specimenLibraryEnabled={specimenLibraryEnabled}
          serviceProvidersEnabled={serviceProvidersEnabled}
          materialManagementEnabled={materialManagementEnabled}
          isPrepWorkflowEnabled={isPrepWorkflowEnabled}
          isWIPMatrixEnabled={isWIPMatrixEnabled}
          isDanfossUser={isDanfossUser}
          isDigitalDesignWarehouseFeatureEnabled={isDigitalDesignWarehouseFeatureEnabled}
        />
      );
    }
  }

  return (
    // <Navbar fixedTop inverse fluid collapseOnSelect className={isImpersonating && 'navbar-impersonate'}>
    // fixedTop replaced with fixed /inverse replaced with variant / fluid by default /
    <Navbar
      fixed="top"
      variant="dark"
      collapseOnSelect
      expand="lg"
      className={isImpersonating ? 'navbar-impersonate navbar-inverse' : 'navbar-inverse'}
    >
      <Container fluid>
        {isIE && (
          <Row
            id="IEAlertNotice"
            style={{
              textAlign: 'center',
              backgroundColor: 'red',
              color: 'white',
              fontSize: '16px',
              padding: '4px 0 2px',
              minHeight: 'auto',
              border: 'none',
            }}
          >
            We do not support Internet Explorer. Please use one of the{' '}
            <a href="https://authentise.zendesk.com/hc/en-us/articles/360027018971-RapidFab-Supported-Browsers">
              supported browsers
            </a>
            .
          </Row>
        )}
        <NavbarBrandingContainer />
        <Navbar.Toggle />
        {session && navbarLinks}
      </Container>
    </Navbar>
  );
};

RapidfabNavbar.defaultProps = {
  currentUser: null,
  session: null,
};

RapidfabNavbar.propTypes = {
  currentUser: PropTypes.shape({}),
  locale: PropTypes.string.isRequired,
  onChangeLocale: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onImpersonateStop: PropTypes.func.isRequired,
  session: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.shape({
      role: PropTypes.string,
    })).isRequired,
  }),
  maEnabled: PropTypes.bool.isRequired,
  mesEnabled: PropTypes.bool.isRequired,
  specimenLibraryEnabled: PropTypes.bool.isRequired,
  serviceProvidersEnabled: PropTypes.bool.isRequired,
  materialManagementEnabled: PropTypes.bool.isRequired,
  isPrepWorkflowEnabled: PropTypes.bool.isRequired,
  isServiceProviderRole: PropTypes.bool.isRequired,
  isHawkingUser: PropTypes.bool.isRequired,
  isAuthentisePDMUser: PropTypes.bool.isRequired,
  isRestrictedUser: PropTypes.bool.isRequired,
  isWIPMatrixEnabled: PropTypes.bool.isRequired,
  isDanfossUser: PropTypes.bool.isRequired,
  isDigitalDesignWarehouseFeatureEnabled: PropTypes.bool.isRequired,
  isStanleyXUser: PropTypes.bool.isRequired,
};

// Nav main menu
const mapStateToProps = state => ({
  maEnabled: isFeatureEnabled(state, FEATURES.MACHINE_ANALYTICS),
  mesEnabled: isFeatureEnabled(state, FEATURES.MANUFACTURING_EXECUTION_SYSTEM),
  specimenLibraryEnabled: isFeatureEnabled(state, FEATURES.SPECIMEN_LIBRARY),
  serviceProvidersEnabled: isFeatureEnabled(state, FEATURES.SERVICE_PROVIDERS),
  materialManagementEnabled: isFeatureEnabled(state, FEATURES.MATERIAL_MANAGEMENT),
  isPrepWorkflowEnabled: isFeatureEnabled(state, FEATURES.PREP_WORKFLOW),
  isWIPMatrixEnabled: isFeatureEnabled(state, FEATURES.WIP_MATRIX),
  isHawkingUser: isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT),
  isAuthentisePDMUser: isFeatureEnabled(state, FEATURES.AUTHENTISE_PDM),
  isServiceProviderRole: isServiceProvider(state),
  isRestrictedUser: isCurrentUserRestricted(state),
  isStanleyXUser: isFeatureEnabled(state, FEATURES.STANLEY_X_DEPLOYMENT),
  isDanfossUser: isFeatureEnabled(state, FEATURES.ORDER_BUSINESS_SEGMENT),
  isDigitalDesignWarehouseFeatureEnabled: isFeatureEnabled(state, FEATURES.DIGITAL_DESIGN_WAREHOUSE),
});

export default connect(mapStateToProps)(RapidfabNavbar);
