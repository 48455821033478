import React, { useState } from 'react';

import { Downtime } from 'rapidfab/components/downtime';

const DowntimeContainer = props => {
  const [selection, setSelection] = useState('none');

  const handleSelectionChange = value => setSelection(value);

  return (
    <Downtime
      {...props}
      selection={selection}
      handleSelectionChange={handleSelectionChange}
    />
  );
};

export default DowntimeContainer;
