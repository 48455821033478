import { ROUTES } from 'rapidfab/constants/routes';

export const RESTRICTED_USER_ORDER_STEP_ORDER_DETAILS = 1;
export const RESTRICTED_USER_ORDER_STEP_CAD_FILE = 2;
export const RESTRICTED_USER_ORDER_STEP_REVIEW_ORDER = 3;

export const RESTRICTED_USER_ORDER_STEPS = [
  {
    step: RESTRICTED_USER_ORDER_STEP_ORDER_DETAILS,
    name: 'Enter Details',
    routeName: ROUTES.ORDER_RESTRICTED_EDIT,
  },
  {
    step: RESTRICTED_USER_ORDER_STEP_CAD_FILE,
    name: 'Upload CAD files',
    routeName: ROUTES.EDIT_RESTRICTED_LINE_ITEM_ORDER,
  },
  {
    step: RESTRICTED_USER_ORDER_STEP_REVIEW_ORDER,
    name: 'Review Order',
    routeName: ROUTES.REVIEW_RESTRICTED_ORDER,
  },
];
