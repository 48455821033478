import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';
import Loading from 'rapidfab/components/Loading';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import NotFound from 'rapidfab/components/404';
import extractUuid from 'rapidfab/utils/extractUuid';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { useNavigate } from 'react-router-dom';

const CommentActionContainer = () => {
  const uuid = useSelector(Selectors.getRouteUUID);
  const commentAction = useSelector(state => Selectors.getUUIDResource(state, uuid));
  const loading = useSelector(state => state.ui.nautilus[API_RESOURCES.COMMENT_ACTION].get.fetching);

  const [isRedirecting, setIsRedirecting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onInitialize = resourceUUID =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.COMMENT_ACTION].get(resourceUUID));

  useEffect(() => {
    onInitialize(uuid);
  }, []);

  useEffect(() => {
    if (!commentAction) {
      return;
    }

    const {
      comment: commentUri,
    } = commentAction;
    setIsRedirecting(true);
    navigate(getRouteURI(ROUTES.COMMENT, { uuid: extractUuid(commentUri) }, {}, true));
  }, [commentAction]);

  if (!loading && !isRedirecting && !commentAction) {
    return <NotFound />;
  }

  // This page is only used for redirect at the moment, so show loading always
  return (
    <Loading />
  );
};

export default CommentActionContainer;
