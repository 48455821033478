import Actions from 'rapidfab/actions';
import {
  API_RESOURCES,
  DOCUMENT_RELATED_TABLE_NAMES,
  LIST_BY_URIS_CHUNK_SIZE,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
} from 'rapidfab/constants';
import _chunk from 'lodash/chunk';
import _map from 'lodash/map';
import extractUuid from '../utils/extractUuid';

// eslint-disable-next-line import/prefer-default-export
export const loadLineItemQuotes = (dispatch, lineItemURIs) => {
  dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].clear('list'));
  dispatch(Actions.Api.nautilus[API_RESOURCES.BUREAU_INTAKE_SETTINGS].list());
  return (lineItemURIs.length !== 0)
    ? dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].list(
      { line_item: lineItemURIs },
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      {}, {}, true,
    ))
    : dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].list({}, {}, {}, {}, true));
};

export const loadLineItemDocuments = (dispatch, lineItemURIs) => {
  _chunk(lineItemURIs, LIST_BY_URIS_CHUNK_SIZE).forEach(itemsChunk => {
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.DOCUMENT].list({
        related_table_name: DOCUMENT_RELATED_TABLE_NAMES.LINE_ITEM,
        related_uuid: itemsChunk.map(lineItemUri => extractUuid(lineItemUri)),
      }, {}, {}, {}, true),
    ).then(response => {
      const { resources } = response.json;
      const userUris = _map(resources, 'user');
      if (userUris.length) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({ uri: userUris }));
      }
    });
  });
};

export const loadLineItemServiceProviderJobs = (dispatch, lineItemURIs) => {
  if (!lineItemURIs.length) {
    return;
  }
  _chunk(lineItemURIs, LIST_BY_URIS_CHUNK_SIZE).forEach(itemsChunk => {
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER_JOB].list({ line_item: itemsChunk }, {}, {}, {}, true),
    );
  });
};

export const loadLineItemWorkflows = (dispatch, lineItems) => {
  if (!lineItems.length) {
    return;
  }
  _chunk(lineItems.map(item => item.workflow).filter(Boolean), LIST_BY_URIS_CHUNK_SIZE).forEach(itemsChunk => {
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list(
        { uri: itemsChunk, include_custom_workflows: true }, {}, {}, {}, true,
      ),
    ).then(response => {
      const workflowUris = response.json.resources.map(workflow => workflow.uri);
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list(
          { workflows: workflowUris }, {}, {}, {}, true,
        ),
      );
    });
  });
};
