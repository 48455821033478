import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FormattedDuration,
  FormattedMessage,
  FormattedVolume,
} from 'rapidfab/i18n';
import {
  Button,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  Row,
} from 'react-bootstrap';
import extractUuid from 'rapidfab/utils/extractUuid';
import hhmmss from 'rapidfab/utils/hhmmss';
import Loading from 'rapidfab/components/Loading';
import { PRINT_STATUS_MAP } from 'rapidfab/mappings';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import {
  API_RESOURCES,
  MATERIAL_UNITS,
  PRINT_STATUSES,
} from 'rapidfab/constants';
import * as Selectors from 'rapidfab/selectors';
import {
  getModelForPiece,
  getPieceActualsForPiece,
  getRunActualsForRun,
  getUUIDResource,
} from 'rapidfab/selectors';
import dayjs from 'dayjs';
import Actions from 'rapidfab/actions';
import { modelResourceType, pieceActualsResourceType } from 'rapidfab/types';
import FontAwesome from 'react-fontawesome';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import SmoothCollapse from 'react-smooth-collapse';
import { FormattedDate, FormattedTime } from 'react-intl';

const ProcessStepHeader = (processStepUris, prints) => {
  const complete = _filter(
    processStepUris,
    processStepUri => {
      const print = _find(prints, { process_step: processStepUri });
      return print && print.status === PRINT_STATUSES.COMPLETE;
    },
  ).length.toString();
  const total = processStepUris.length.toString();
  return (
    <FormattedMessage
      id="record.processStepCompleteCount"
      defaultMessage="Process Steps - {complete} / {total} complete"
      values={{ complete, total }}
    />
  );
};

const RunTime = connect((state, { uuid }) => {
  const run = getUUIDResource(state, uuid);
  const runActuals = getRunActualsForRun(state, run);
  const startTime =
    runActuals && runActuals.start_in_progress_time && dayjs(runActuals.start_in_progress_time);
  const endTime =
    runActuals && runActuals.end_in_progress_time && dayjs(runActuals.end_in_progress_time);
  /** In seconds */
  let time = (startTime && endTime) ? endTime.diff(startTime, 'seconds') : null;
  if (time) {
    time = hhmmss(time);
  }
  return { time };
})(({ time }) => (
  <span>
    {time || <FormattedMessage id="notAvailable" defaultMessage="N/A" />}
  </span>
));

const ProcessStep = ({
  print,
  processStep,
  processStepActuals,
  modelVolumeMm,
}) => {
  const fetchingProcessSteps = useSelector(state => state.ui.nautilus[API_RESOURCES.PROCESS_STEP].list.fetching);

  const [expanded, setExpanded] = useState(false);
  const { name } = processStep;

  const dispatch = useDispatch();

  const usersByUri = useSelector(Selectors.getUsersByUri);
  const run = useSelector(state => (print?.run ? getUUIDResource(state, extractUuid(print.run)) : null));
  const runActuals = useSelector(state => (run ? getRunActualsForRun(state, run) : null));
  const currentUser = usersByUri[runActuals?.user];

  const handleGetCurrentUser = async () => {
    if (runActuals?.user) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].get(extractUuid(runActuals.user)));
    }
  };

  const startTime =
    runActuals && runActuals.start_in_progress_time && dayjs(runActuals.start_in_progress_time);
  const endTime =
    runActuals && runActuals.end_in_progress_time && dayjs(runActuals.end_in_progress_time);

  const handleExpand = async () => {
    if ((!currentUser && runActuals) && (startTime || endTime)) {
      await handleGetCurrentUser();
    }
    setExpanded(previous => !previous);
  };

  const workstationName = run?.workstation_name;

  const isPrintingStep = processStep && processStep.step_position === 1;

  const runningCost = processStepActuals && processStepActuals.running_cost;
  const materialCost = processStepActuals && processStepActuals.material_cost;
  const duration = processStepActuals && processStepActuals.duration;

  // We show expanded version (and trigger-button) only if at least one value is available
  const showExpandedVersion = runningCost || materialCost || duration || (isPrintingStep && modelVolumeMm);
  return (
    <ListGroupItem>
      <Row>
        <Col xs={3}>
          {showExpandedVersion && (
            <>
              <Button size="xs" onClick={handleExpand}>
                <FontAwesome
                  name={expanded ? 'chevron-down' : 'chevron-right'}
                />
              </Button>
              &nbsp;
            </>
          )}
          {print.run ? (
            // eslint-disable-next-line unicorn/no-nested-ternary
            fetchingProcessSteps ? <Loading /> : (name ? <a href={`#/records/run/${extractUuid(print.run)}`}>{name}</a> : (
              <FormattedMessage
                id="processStepMissing"
                defaultMessage="[Error - Step Not Found]"
              />
            ))
          ) : (
            name
          )}
        </Col>
        <Col xs={3}>
          <FormattedMessage {...PRINT_STATUS_MAP[print.status]} />
        </Col>
        <Col xs={3}>
          {print.run ? (
            <RunTime uuid={extractUuid(print.run)} />
          ) : (
            <FormattedMessage id="notAvailable" defaultMessage="N/A" />
          )}
        </Col>
        <Col xs={3}>
          {
            (processStepActuals && processStepActuals.total_cost)
              ? <FormattedLocalizedCost value={processStepActuals.total_cost} />
              : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
          }
        </Col>
      </Row>
      <SmoothCollapse expanded={expanded} className="ml30">
        {duration && (
          <Row>
            <Col xs={12}>
              <b>
                <FormattedMessage id="runningTime" defaultMessage="Running Time" />:
              </b>
              &nbsp;
              <FormattedDuration value={duration} />
            </Col>
          </Row>
        )}
        {runningCost && (
          <Row>
            <Col xs={12}>
              <b>
                <FormattedMessage id="runningCost" defaultMessage="Running Cost" />:
              </b>
              &nbsp;
              <FormattedLocalizedCost value={runningCost} />
            </Col>
          </Row>
        )}
        {(isPrintingStep && modelVolumeMm) && (
          <Row>
            <Col xs={12}>
              <b>
                <FormattedMessage id="modelVolume" defaultMessage="Model Volume" />:
              </b>
              &nbsp;
              <FormattedVolume value={modelVolumeMm} valueUnits={MATERIAL_UNITS.MM3} />
            </Col>
          </Row>
        )}
        {materialCost && (
          <Row>
            <Col xs={12}>
              <b>
                <FormattedMessage id="materialCost" defaultMessage="Material Cost" />:
              </b>
              &nbsp;
              <FormattedLocalizedCost value={materialCost} />
            </Col>
          </Row>
        )}
        {startTime && (
          <Row>
            <Col xs={12}>
              <b>
                Started:
              </b>
              &nbsp;
              <FormattedDate value={startTime} /> at <FormattedTime value={startTime} /> {currentUser && `by ${currentUser.name}`}
            </Col>
          </Row>
        )}
        {endTime && (
          <Row>
            <Col xs={12}>
              <b>
                Completed:
              </b>
              &nbsp;
              <FormattedDate value={endTime} /> at <FormattedTime value={endTime} /> {currentUser && `by ${currentUser.name}`}
            </Col>
          </Row>
        )}
        {workstationName && (
          <Row>
            <Col xs={12}>
              <b>
                Workstation:
              </b>
              &nbsp;
              {workstationName}
            </Col>
          </Row>
        )}
      </SmoothCollapse>
    </ListGroupItem>
  );
};

ProcessStep.defaultProps = {
  processStep: { name: '' },
  processStepActuals: null,
  modelVolumeMm: null,
};

ProcessStep.propTypes = {
  print: PropTypes.shape({
    status: PropTypes.string.isRequired,
    run: PropTypes.string,
  }).isRequired,
  processStep: PropTypes.shape({
    name: PropTypes.string,
    step_position: PropTypes.number,
  }),
  modelVolumeMm: PropTypes.number,
  processStepActuals: PropTypes.shape({
    total_cost: PropTypes.number,
    running_cost: PropTypes.number,
    material_cost: PropTypes.number,
    duration: PropTypes.number,
  }),
};

const ProcessSteps = ({
  prints,
  model,
  processStepsByUri,
  workflow,
  withHeader,
  isLoading,
  pieceActuals,
  onInitialize,
}) => {
  useEffect(() => {
    onInitialize();
  }, []);

  if (isLoading) {
    return (
      <Card bg={withHeader ? 'primary' : 'default'}>
        <Loading />
      </Card>
    );
  }

  if (!workflow) {
    // Don't know what to do (bad db state),
    // so render as empty component
    return null;
  }

  return (
    <Card bg="dark">
      <Card.Header className="p-a-0">
        {withHeader && ProcessStepHeader(workflow.process_steps, prints)}
      </Card.Header>
      <div className="card-body-wrapper">
        <ListGroup fill>
          <ListGroupItem key="header">
            <Row>
              <Col xs={3}>
                <b>
                  <FormattedMessage id="field.name" defaultMessage="Name" />
                </b>
              </Col>
              <Col xs={3}>
                <b>
                  <FormattedMessage id="field.status" defaultMessage="Status" />
                </b>
              </Col>
              <Col xs={3}>
                <b>
                  <FormattedMessage id="time" defaultMessage="Time" />
                </b>
              </Col>
              <Col xs={3}>
                <b>
                  <FormattedMessage id="totalCost" defaultMessage="Total Cost" />
                </b>
              </Col>
            </Row>
          </ListGroupItem>

          {workflow.process_steps.map(processStepUri => {
            const print = _find(prints, { process_step: processStepUri });
            const processStep = processStepsByUri[processStepUri];
            if (!print) {
            // There are shouldn't be any errors
            // as we should always have 1 print per process step
            // but let's make to sure that prints are loaded correctly
              return null;
            }

            const processStepActuals =
            pieceActuals && _find(pieceActuals.per_step_details, { process_step: processStepUri });

            return (
              <ProcessStep
                key={processStepUri}
                print={print}
                processStep={processStep}
                processStepActuals={processStepActuals}
                modelVolumeMm={model && model.volume_mm}
              />
            );
          })}
        </ListGroup>
      </div>
    </Card>
  );
};

ProcessSteps.defaultProps = {
  withHeader: false,
  pieceActuals: null,
  model: null,
};

ProcessSteps.propTypes = {
  piece: PropTypes.shape({}).isRequired,
  model: modelResourceType,
  pieceActuals: pieceActualsResourceType,
  withHeader: PropTypes.bool,

  prints: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string.isRequired,
    process_step: PropTypes.string.isRequired,
  })).isRequired,
  processStepsByUri: PropTypes.shape({}).isRequired,
  workflow: PropTypes.shape({
    process_steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onInitialize: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { piece }) => {
  const prints = Selectors.getPrintsForPiece(state, piece);
  const processStepsByUri = Selectors.getProcessStepsByUri(state);

  const model = getModelForPiece(state, piece);

  // Component MUST NOT be rendered with empty piece.workflow value
  const workflow = Selectors.getUUIDResource(state, extractUuid(piece.workflow));

  const isLoading = state.ui.nautilus[API_RESOURCES.WORKFLOW].get.fetching
    || state.ui.nautilus[API_RESOURCES.PIECE_ACTUALS].list.fetching;

  const pieceActuals = getPieceActualsForPiece(state, piece);

  return {
    prints,
    model,
    processStepsByUri,
    workflow,
    isLoading,
    pieceActuals,
  };
};

const mapDispatchToProps = (dispatch, { piece }) => ({
  onInitialize: () =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE_ACTUALS].list({
      piece: piece.uri,
    })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessSteps);
