import PropTypes from 'prop-types';
import {
  MANUFACTURING_PROCESSES,
  MATERIAL_FAMILY,
  MATERIAL_UNITS,
  MATERIAL_UNITS_MEASUREMENT_CORE,
} from 'rapidfab/constants';

export const valueType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.bool,
  PropTypes.array,
]);

export const reduxFormFieldType = PropTypes.shape({
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: valueType,
});

export const customFieldReferenceType = PropTypes.shape({
  field_id: PropTypes.string.isRequired,
  field_name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
});

export const orderFormType = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  custom_field_values: PropTypes.arrayOf(PropTypes.object),
  customer_name: reduxFormFieldType,
  customer_email: reduxFormFieldType,
  shipping: PropTypes.shape({
    name: reduxFormFieldType,
    address: reduxFormFieldType,
    tracking: reduxFormFieldType,
    uri: reduxFormFieldType,
  }),
  initial_comment: reduxFormFieldType,
  name: reduxFormFieldType,
  priority: reduxFormFieldType,
  order_owner: reduxFormFieldType,
  customer_po: reduxFormFieldType,
  quote_number: reduxFormFieldType,
  ip_sensitivity: reduxFormFieldType,
  location: reduxFormFieldType,
  order_type: reduxFormFieldType,
  due_date: reduxFormFieldType,
  region: reduxFormFieldType,
});

export const materialTypeFormType = PropTypes.shape({
  units: reduxFormFieldType,
  id: reduxFormFieldType,
  name: reduxFormFieldType,
  uuid: reduxFormFieldType,
  description: reduxFormFieldType,
  manufacturer: reduxFormFieldType,
  color: reduxFormFieldType,
  type: reduxFormFieldType,
  material_family: reduxFormFieldType,
  printer_types_selected: reduxFormFieldType,
  density: reduxFormFieldType,
  cost: reduxFormFieldType,
  manufacturing_process: reduxFormFieldType,
  third_party_fulfillment: reduxFormFieldType,
  external_datasheet_url: reduxFormFieldType,
});

export const materialLotFormType = PropTypes.shape({
  name: reduxFormFieldType,
  material: reduxFormFieldType,
  location: reduxFormFieldType,
  delivery_id: reduxFormFieldType,
  delivery_date: reduxFormFieldType,
  expiration_date: reduxFormFieldType,
  number_of_containers: reduxFormFieldType,
  size_of_container: reduxFormFieldType,
});

export const materialResourceType = PropTypes.shape({
  uri: PropTypes.string,
  uuid: PropTypes.string,
  name: PropTypes.string,
  base_template: PropTypes.string,
  bureau: PropTypes.string,
  color: PropTypes.string,
  color_opacity: PropTypes.number,
  cost: PropTypes.number,
  density: PropTypes.number,
  description: PropTypes.string,
  external_datasheet_url: PropTypes.string,
  external_id: PropTypes.string,
  identifiers: PropTypes.arrayOf(PropTypes.shape({
    bureau: PropTypes.string,
    id: PropTypes.string,
    finish_id: PropTypes.string,
  })),
  is_prosper_integration_available: PropTypes.bool,
  is_template: PropTypes.bool,
  stock_locations: PropTypes.arrayOf(PropTypes.string),
  manufacturer: PropTypes.string,
  manufacturing_process: PropTypes.oneOf(Object.values(MANUFACTURING_PROCESSES)),
  material_family: PropTypes.oneOf(Object.values(MATERIAL_FAMILY)),
  post_processing_seconds: PropTypes.number,
  swatch: PropTypes.string,
  third_party_fulfillment: PropTypes.bool,
  thumbnail: PropTypes.shape({
    content: PropTypes.string,
    upload_location: PropTypes.string,
  }),
  type: PropTypes.string,
  units: PropTypes.oneOf(Object.values(MATERIAL_UNITS)),
  units_measurement_core: PropTypes.oneOf(Object.values(MATERIAL_UNITS_MEASUREMENT_CORE)),
});

export const finalFormInputTypes = PropTypes.shape({
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
});
