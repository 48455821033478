import React from 'react';
import { Card, Col, Container, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import ModelLibrariesContainer from 'rapidfab/containers/manage/ModelLibrariesContainer';
import Fa from 'react-fontawesome';
import { ROUTES, FEATURES } from 'rapidfab/constants';
import getRouteURI, { routeURIContains } from 'rapidfab/utils/getRouteURI';
import _camelCase from 'lodash/camelCase';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import BuildsLibrariesContainer from 'rapidfab/containers/manage/BuildsLibrariesContainer';
import { useSelector } from 'react-redux';
import {
  isFeatureEnabled,
} from 'rapidfab/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/free-solid-svg-icons';

const getLocationBreadcrumb = location => {
  let breadcrumb = location.split('/').at(-1);
  breadcrumb = breadcrumb.includes('library') && breadcrumb.replace('library', 'libraries');
  breadcrumb = _camelCase(breadcrumb);
  return breadcrumb;
};

const Libraries = () => {
  const isHawkingDeploymentFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT));
  // const isCertifiedBuildsFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.CERTIFIED_BUILDS));
  const isCertifiedBuildsFeatureEnabled = true;

  return (
    <Container fluid>
      <BreadcrumbNav breadcrumbs={['manage', getLocationBreadcrumb(window.location.hash)]} />
      <Row>
        {isCertifiedBuildsFeatureEnabled && !isHawkingDeploymentFeatureEnabled && (
          <Col className="mb15" md={2}>
            <Card bg="dark">
              <Card.Body className="p-a-0">
                <ListGroup>
                  <ListGroupItem
                    onClick={() => { window.location = getRouteURI(ROUTES.MODEL_LIBRARY); }}
                    className={window.location.hash.includes(ROUTES.MODEL_LIBRARY) && 'clearfix active'}
                  >
                    <p>Model Library <Fa name="cube" /></p>
                  </ListGroupItem>
                  <ListGroupItem
                    onClick={() => { window.location = getRouteURI(ROUTES.BUILDS_LIBRARY); }}
                    className={window.location.hash.includes(ROUTES.BUILDS_LIBRARY) && 'clearfix active'}
                  >
                    <p>Builds Library <FontAwesomeIcon icon={faBox} /></p>
                  </ListGroupItem>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        )}
        <Col>
          {routeURIContains(ROUTES.MODEL_LIBRARY) && (
            <ModelLibrariesContainer />
          )}
          {isCertifiedBuildsFeatureEnabled && routeURIContains(ROUTES.BUILDS_LIBRARY) && (
            <BuildsLibrariesContainer />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Libraries;
