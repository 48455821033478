import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import _keyBy from 'lodash/keyBy';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';
import MaterialBatchGenealogy from 'rapidfab/components/records/MaterialBatchGenealogy';
import {
  getMaterialsByUri,
  getMaterialContainersForBatch,
  getUUIDResource,
  getMaterialGenealogy, getRouteUUID,
} from 'rapidfab/selectors';
import Loading from 'rapidfab/components/Loading';
import extractUuid from 'rapidfab/utils/extractUuid';
import { connect } from 'react-redux';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import { API_RESOURCES } from 'rapidfab/constants';

const TREE_DIRECTION = {
  CHILDREN: 'children_tree',
  PARENTS: 'parents_tree',
};

class MaterialBatchGenealogyContainer extends Component {
  constructor(props) {
    super(props);

    this.initGenealogyGraph = this.initGenealogyGraph.bind(this);

    this.state = {
      family: null,
      vertexesByUri: {},
      direction: TREE_DIRECTION.CHILDREN,
    };
  }

  componentDidMount() {
    const { uuid } = this.props;
    this.props.onInitialize(uuid);
  }

  componentDidUpdate(prevProps) {
    const { uuid, genealogy } = this.props;

    if (uuid !== prevProps.uuid) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        vertexesByUri: {},
        family: null,
      });
      this.props.onInitialize(uuid);
    }

    if (genealogy && !prevProps.genealogy) {
      this.initGenealogyGraph();
    }
  }

  initGenealogyGraph() {
    const { genealogy, uuid } = this.props;

    if (extractUuid(genealogy.target_batch) !== uuid) {
      // For first ms when props are passed to component,
      //  previous genealogy data can be stored.
      // We compare uuid from query params and uuid from endpoint result
      return;
    }

    const { direction } = this.state;

    const vertexesByUri = {
      ...(_keyBy(genealogy.batches, 'uri')),
      ...(_keyBy(genealogy.actions, 'uri')),
    };

    const family = _map(
      // Backend marks second parent as is_batch_merged_by_action.
      // Ignoring those node relations for family tree to prevent double-parent cases
      (_filter(genealogy[direction], { is_batch_merged_by_action: false })),
      ({ vertex, parent }) => ({
        ...vertexesByUri[vertex],
        isAction: vertex.includes('/material-batch-action/'),
        isBatch: vertex.includes('/material-batch/'),
        name: getShortUUID(vertex),
        parent: parent && getShortUUID(parent),
      }),
    );

    // Add all ignored actions data to appropriate parent vertex
    _forEach(
      _filter(genealogy[direction], { is_batch_merged_by_action: true }),
      ignoredAction => {
        const parentAction = _find(family, { uri: ignoredAction.parent });
        if (parentAction) {
          parentAction.relatedAction = { ...vertexesByUri[ignoredAction.vertex] };
        }

        const childAction = _find(family, { uri: ignoredAction.vertex });
        if (childAction) {
          childAction.relatedAction = { ...vertexesByUri[ignoredAction.parent] };
        }
      },
    );
    this.setState({
      vertexesByUri,
      family: _uniqBy(family, 'uri'),
    });
  }

  render() {
    const { isLoading, batch, lot } = this.props;
    const { family, vertexesByUri } = this.state;

    if (isLoading || !batch || !lot || !family) {
      return (<Loading />);
    }

    return <MaterialBatchGenealogy {...this.props} family={family} vertexesByUri={vertexesByUri} />;
  }
}

MaterialBatchGenealogyContainer.propTypes = {
  uuid: PropTypes.string.isRequired,
  genealogy: PropTypes.shape({
    target_batch: PropTypes.string.isRequired,
    batches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  batch: PropTypes.shape({}),
  lot: PropTypes.shape({}),
  onInitialize: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

MaterialBatchGenealogyContainer.defaultProps = {
  genealogy: {},
  batch: null,
  lot: null,
};

function mapDispatchToProps(dispatch) {
  return {
    onInitialize: uuid => {
      if (!uuid) {
        return;
      }

      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].clear('get'));

      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_GENEALOGY].get(uuid));
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].get(uuid, true)).then(response => {
        const { material_lots: lotUri } = response.json;
        // TODO: Change when UI for multiple lots is ready extractUuid(lotUri)
        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].get(extractUuid(lotUri[0])));
      });
    },
  };
}

function mapStateToProps(state) {
  const uuid = getRouteUUID(state);
  const batch = getUUIDResource(state, uuid);
  // TODO: Change when UI for multiple lots is ready extractUuid(batch.material_lot)
  const lot = batch && getUUIDResource(state, extractUuid(batch.material_lots[0]));

  const isLoading = state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH].get.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_LOT].get.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_GENEALOGY].get.fetching;

  const materialsByUri = batch && getMaterialsByUri(state);

  return {
    isLoading,
    uuid,
    batch,
    lot,
    material: batch && materialsByUri[batch.material],
    genealogy: getMaterialGenealogy(state),
    containers: getMaterialContainersForBatch(state, batch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialBatchGenealogyContainer);
