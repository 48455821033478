import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';
import Loading from 'rapidfab/components/Loading';
import * as Sentry from '@sentry/react';
import NotFound from 'rapidfab/components/404';
import Comment from 'rapidfab/components/comments/Comment';
import { Col, Row } from 'react-bootstrap';
import getCommentRedirectUri from 'rapidfab/utils/getCommentRedirectUri';
import { API_RESOURCES, FEATURES, USER_ROLES } from 'rapidfab/constants';
import { getCurrentUserRole, getUUIDResource } from 'rapidfab/selectors';

const CommentContainer = () => {
  const uuid = useSelector(Selectors.getRouteUUID);
  const comment = useSelector(state => Selectors.getUUIDResource(state, uuid));
  const loading = useSelector(state => state.ui.nautilus[API_RESOURCES.COMMENT].get.fetching
    || state.ui.nautilus[API_RESOURCES.ORDER].get.fetching
    || state.ui.nautilus[API_RESOURCES.PREP_TASK_RECORD].get.fetching);
  const isCurrentUserRestricted = useSelector(Selectors.isCurrentUserRestricted);
  const isHawkingUser = useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT));
  const user = useSelector(Selectors.getSession);
  const modelLibrary = useSelector(state => (comment ? getUUIDResource(state, comment.related_uuid) : null));
  const currentUserRole = useSelector(getCurrentUserRole);
  const isRecordOwner = modelLibrary && modelLibrary.owner === user.uri;
  const isManager = currentUserRole === USER_ROLES.MANAGER;

  const [isRedirecting, setIsRedirecting] = useState(false);

  const dispatch = useDispatch();
  const onInitialize = resourceUUID =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.COMMENT].get(resourceUUID));

  useEffect(() => {
    onInitialize(uuid);
  }, []);

  useEffect(() => {
    if (comment) {
      const {
        related_table_name: relatedTableName,
        related_uuid: relatedUUID,
      } = comment;
      const redirectUri =
        getCommentRedirectUri(comment, isCurrentUserRestricted, isHawkingUser, isManager, isRecordOwner);

      if (redirectUri) {
        setIsRedirecting(true);
        window.location.hash = redirectUri;
      } else {
        Sentry.captureMessage(`No Route found for comment ${comment.uuid}. Related table name - ${relatedTableName}. Related UUID - ${relatedUUID}`);
      }
    }
  }, [comment]);

  if (loading || isRedirecting) {
    return (
      <Loading />
    );
  } if (!comment) {
    return <NotFound />;
  }

  return (
    <Row>
      <Col md={{ span: 10, offset: 1 }}>
        <Comment comment={comment} />
      </Col>
    </Row>
  );
};

export default CommentContainer;
