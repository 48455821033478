import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Actions from 'rapidfab/actions';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalTitle,
  FormGroup,
  FormControl,
  ModalFooter,
  Button,
  FormLabel,
} from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import { API_RESOURCES } from 'rapidfab/constants';
import {
  finalFormInputTypes,
  materialTypeResourceType,
} from 'rapidfab/types';
import extractUuid from 'rapidfab/utils/extractUuid';
import clone from 'lodash/clone';

import { Form, Field } from 'react-final-form';

const MaterialLotCreatePOModal = ({
  onClose,
  onSave,
  material,
  lotUri,
  initialFormValues,
}) => {
  const onSubmit = formValues => onSave(formValues, lotUri)
    .then(() => onClose());

  return (
    <Modal show onHide={onClose} backdrop="static">
      <ModalHeader closeButton>
        <ModalTitle>
          <FormattedMessage
            id="materialLot.createNewPo"
            defaultMessage="Create New PO"
          />
        </ModalTitle>
      </ModalHeader>
      <Form
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        render={({ handleSubmit }) => (
          <form id="PO" onSubmit={handleSubmit}>
            <ModalBody>
              <div>
                <b><FormattedMessage id="field.material" defaultMessage="Material" />:</b>{material.name}
              </div>
              <div className="mb15">
                <b><FormattedMessage id="field.units" defaultMessage="Units" />:</b>{material.units}
              </div>
              <FormGroup controlId="external_id">
                <FormLabel>
                  <FormattedMessage id="field.poNumber" defaultMessage="PO Number" />: *
                </FormLabel>
                <Field
                  name="external_id"
                  render={props => (
                    <FormControl
                      {...props.input}
                      required
                    />
                  )}
                />
              </FormGroup>
              <FormGroup controlId="planned_full_quantity">
                <FormLabel>
                  <FormattedMessage id="field.plannedTotalAmount" defaultMessage="Planned Total Amount" />: *
                </FormLabel>
                <Field
                  name="planned_full_quantity"
                  type="number"
                  render={props => (
                    <FormControl
                      {...props.input}
                      required
                    />
                  )}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                form="PO"
                variant="success"
                type="submit"
              >
                <Fa name="floppy-o" />{' '}
                <FormattedMessage id="button.save" defaultMessage="Save" />
              </Button>
            </ModalFooter>
          </form>
        )}
      />
    </Modal>
  );
};

MaterialLotCreatePOModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  lotUri: PropTypes.string.isRequired,
  material: materialTypeResourceType.isRequired,
  initialFormValues: PropTypes.shape({
    external_id: PropTypes.string.isRequired,
    planned_full_quantity: PropTypes.number.isRequired,
    delivered_quantity: PropTypes.number.isRequired,
    material: PropTypes.string.isRequired,
    units: PropTypes.string.isRequired,
  }).isRequired,
  input: finalFormInputTypes.isRequired,
};

const mapStateToProps = (state, props) => {
  const { material = {} } = props;
  const { uri } = material;
  const initialFormValues = {
    material: uri,
  };
  return {
    initialFormValues,
    material,
  };
};

const mapDispatchToProps = dispatch => ({
  onSave: async (formValues, lotUri) => {
    const submittedValues = clone(formValues);
    submittedValues.planned_full_quantity = Number(formValues.planned_full_quantity);

    const materialPoPostResp = await dispatch(
      Actions.Api.nautilus[API_RESOURCES.MATERIAL_PURCHASE_ORDER].post(submittedValues),
    );
    const { uri: materialPoUri } = materialPoPostResp.payload;
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].put(
      extractUuid(lotUri),
      { material_purchase_order: materialPoUri },
    ));
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_PURCHASE_ORDER].get(extractUuid(materialPoUri)));
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].get(extractUuid(lotUri)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialLotCreatePOModal);
