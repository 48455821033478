import React from 'react';
import PropTypes from 'prop-types';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { ROUTES, RELATED_TABLE_NAMES, USER_ROLES } from 'rapidfab/constants';

const RelatedResourceRoute = ({
  relatedTableName,
  relatedUUID,
  title,
  currentUserRole,
}) => {
  let route = null;

  const availableRoutes = Object.values(ROUTES);
  const fallbackRouteTemplate = `/records/${relatedTableName}/:uuid`;
  const fallbackRoute = availableRoutes.includes(fallbackRouteTemplate)
    // As A fall-back - use table name as resource only if route exists
    ? `#/records/${relatedTableName}/${relatedUUID}`
    : null;

  const getOrderRoute = () => (
    currentUserRole === USER_ROLES.RESTRICTED
      ? getRouteURI(ROUTES.ORDER_RESTRICTED_EDIT, { uuid: relatedUUID })
      : getRouteURI(ROUTES.ORDER_EDIT, { uuid: relatedUUID })
  );

  switch (relatedTableName) {
    case RELATED_TABLE_NAMES.ORDER:
      route = getOrderRoute();
      break;
    case RELATED_TABLE_NAMES.PREP_TASK_RECORD:
      route = getRouteURI(ROUTES.PREP_TASK_RECORDS, {}, { task: relatedUUID });
      break;
    case RELATED_TABLE_NAMES.COMMENT_ACTION:
      route = getRouteURI(ROUTES.COMMENT_ACTION, { uuid: relatedUUID });
      break;
    default:
      route = fallbackRoute;
      break;
  }
  return <a href={route}>{title}</a>;
};

RelatedResourceRoute.propTypes = {
  relatedTableName: PropTypes.string.isRequired,
  relatedUUID: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  currentUserRole: PropTypes.string.isRequired,
};

export default RelatedResourceRoute;
