import { MATERIAL_BATCH_ACTION_TYPES } from 'rapidfab/constants';
import _get from 'lodash/get';

// Not using default export, since we may add extra helper functions here later
// eslint-disable-next-line import/prefer-default-export
export function getMaterialBatchActionQuantity(materialBatchAction) {
  if (!materialBatchAction) {
    return 0;
  }
  // TODO: A smarter way might be required after Demo 2
  switch (materialBatchAction.action_type) {
    case MATERIAL_BATCH_ACTION_TYPES.SIEVE:
      return _get(materialBatchAction, 'metadata.remaining_quantity', 0);
    case MATERIAL_BATCH_ACTION_TYPES.TEST:
      return _get(materialBatchAction, 'metadata.amount_consumed', 0);
    default:
      return materialBatchAction.quantity || 0;
  }
}
