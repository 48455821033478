import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  API_RESOURCES,
  DOCUMENT_RELATED_TABLE_NAMES,
  MATERIAL_BATCH_ACTION_TYPES,
  MATERIAL_BATCH_CUSTOM_STATUSES,
} from 'rapidfab/constants';
import { getMaterialBatchTestActionsForBatch } from 'rapidfab/selectors/materialManagementSelectors';
import {
  Card,
  Modal,
  Row,
  Col,
  Button,
  FormControl,
  FormLabel,
  FormGroup,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FormControlDate } from 'rapidfab/components/formTools';
import Loading from 'rapidfab/components/Loading';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import FormRow from 'rapidfab/components/FormRow';
import Actions from 'rapidfab/actions';
import extractUuid from 'rapidfab/utils/extractUuid';
import Documents from 'rapidfab/components/records/Documents';
import FileInput from 'rapidfab/components/records/order/edit/FileInput';
import Fa from 'react-fontawesome';
import { FormattedDate } from 'rapidfab/i18n';
import nl2br from 'rapidfab/utils/nl2br';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import FormGroupField from 'rapidfab/components/forms/FormGroupField';
import {
  addTimeZoneToDateString,
  removeTimeZoneFromDateString,
} from 'rapidfab/utils/formHelpers';

const DataRow = ({ id, defaultMessage, children }) => (
  <div>
    <b>
      <FormattedMessage id={id} defaultMessage={defaultMessage} />:
    </b>
    &nbsp;
    {children}
  </div>
);

DataRow.propTypes = {
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const testResultsFields = {
  notes: 'notes',
  test_name: 'metadata.test_name',
  test_start_date: 'metadata.test_start_date',
  test_completion_date: 'metadata.test_completion_date',
};

const ManageTestActions = ({
  isVisible,
  isTestResultWaiting,
  batch,
  testActions,
  isSaving,
  saveTestResults,
}) => {
  const [isModalShown, setShowModal] = useState(false);
  const [additionalDocument, setAdditionalDocument] = useState(null);
  const [selectedActionIndex, setSelectedActionIndex] = useState(0);

  if (!isVisible) {
    return null;
  }

  const onCloseModal = () => {
    setShowModal(false);
  };

  const showModal = () => {
    setShowModal(true);
  };

  const onSelectedActionIndexChange = event => {
    const {
      target: { value },
    } = event;
    setSelectedActionIndex(value);
  };

  const handleDocumentChange = event => {
    setAdditionalDocument(event.target.files[0]);
  };

  const handleDocumentDelete = () => {
    setAdditionalDocument(null);
  };

  const onSubmit = payload => {
    saveTestResults(batch.uri, payload, additionalDocument);
    onCloseModal();
  };

  const selectedTestAction = testActions[selectedActionIndex];

  return (
    <>
      <Card bg="dark" className="mb15">
        <Card.Header className="pd-exp inverse">Test Results</Card.Header>
        <div className="card-body-wrapper">
          <Card.Body>
            {testActions.length > 1 && (
              <Row>
                <FormRow
                  id="field.test.viewPrevious"
                  defaultMessage="View Previous Test"
                >
                  <FormControl
                    id="selectedActionIndex"
                    as="select"
                    className="pull-right"
                    name="selectedAction"
                    value={selectedActionIndex}
                    onChange={onSelectedActionIndexChange}
                  >
                    {Object.keys(testActions).map(index => (
                      <option value={index}>
                        {getShortUUID(testActions[index].uri)}
                      </option>
                    ))}
                  </FormControl>
                </FormRow>
                <Col xs={12}>
                  <hr />
                </Col>
              </Row>
            )}

            {isTestResultWaiting && (
              <Button variant="primary" onClick={showModal} className="pull-right" disabled={isSaving}>
                {isSaving && <Loading inline />} Record New Test
              </Button>
            )}

            {selectedTestAction && (
              <>
                <DataRow id="field.test.id" defaultMessage="Test ID">
                  {getShortUUID(selectedTestAction.uri)}
                </DataRow>
                <DataRow id="field.test.name" defaultMessage="Test Name">
                  {selectedTestAction.metadata.test_name}
                </DataRow>
                <DataRow id="field.test.startDate" defaultMessage="Test Start Date">
                  {selectedTestAction.metadata.test_start_date ?
                    (<FormattedDate value={selectedTestAction.metadata.test_start_date} />) :
                    'N/A'}
                </DataRow>
                <DataRow id="field.test.completionDate" defaultMessage="Test Completion Date">
                  {selectedTestAction.metadata.test_completion_date ?
                    (<FormattedDate value={selectedTestAction.metadata.test_completion_date} />) :
                    'N/A'}
                </DataRow>
                <DataRow id="field.notes" defaultMessage="Notes">
                  {selectedTestAction.notes ? nl2br(selectedTestAction.notes) : 'N/A'}
                </DataRow>
                <hr />
                <Documents
                  relatedTable={DOCUMENT_RELATED_TABLE_NAMES.MATERIAL_BATCH_ACTION}
                  relatedUUID={extractUuid(selectedTestAction.uri)}
                />
              </>
            )}
          </Card.Body>
        </div>

      </Card>
      <Modal show={isModalShown} onHide={onCloseModal} backdrop="static">
        <Form
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Record Test Results
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormGroupField
                  name={testResultsFields.test_name}
                  label={(<FormattedMessage id="field.test.name" defaultMessage="Test Name" />)}
                />
                <FormGroup>
                  <FormLabel htmlFor="testResultsStartDate">
                    <FormattedMessage id="field.test.startDate" defaultMessage="Test Start Date" />:
                  </FormLabel>
                  <Field
                    name={testResultsFields.test_start_date}
                    parse={addTimeZoneToDateString}
                    format={removeTimeZoneFromDateString}
                  >
                    {({ input }) => (
                      <FormControlDate id="testResultsStartDate" {...input} />
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="testResultsCompleteDate">
                    <FormattedMessage id="field.test.completionDate" defaultMessage="Test Completion Date" />: *
                  </FormLabel>
                  <Field
                    name={testResultsFields.test_completion_date}
                    parse={addTimeZoneToDateString}
                    format={removeTimeZoneFromDateString}
                  >
                    {({ input }) => (
                      <FormControlDate id="testResultsCompleteDate" required {...input} />
                    )}
                  </Field>
                </FormGroup>
                <FormGroupField
                  name={testResultsFields.notes}
                  label={(<FormattedMessage id="field.notes" defaultMessage="Notes" />)}
                  as="textarea"
                />
                <FormGroup>
                  <FormLabel>
                    <FormattedMessage id="field.test.document" defaultMessage="Test Document" />:
                  </FormLabel>
                  <Row>
                    <Col xs={6}>
                      <FileInput
                        fileType={FileInput.fileTypes.document}
                        handleFileChange={handleDocumentChange}
                      />
                    </Col>
                    <Col xs={6}>
                      {additionalDocument && (
                        <span>
                          {`${additionalDocument.name} `}
                          <Button
                            size="xs"
                            className="m-r"
                            onClick={handleDocumentDelete}
                          >
                            <Fa name="times" />
                          </Button>
                        </span>
                      )}
                    </Col>
                  </Row>
                </FormGroup>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={onCloseModal}>
                  <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="success" type="submit" disabled={isSaving}>
                  {isSaving && <Loading inline />}
                  <FormattedMessage id="button.save" defaultMessage="Save" />
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Form>
      </Modal>
    </>
  );
};

ManageTestActions.defaultProps = {
  testActions: [],
  isVisible: false,
  isTestResultWaiting: false,
  isSaving: false,
};

ManageTestActions.propTypes = {
  batch: PropTypes.shape({
    custom_status: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }).isRequired,
  testActions: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      uri: PropTypes.string.isRequired,
      notes: PropTypes.string.isRequired,
      metadata: PropTypes.shape({
        test_name: PropTypes.string,
        test_start_date: PropTypes.string,
        test_completion_date: PropTypes.string,
      }).isRequired,
    }),
  ),
  isVisible: PropTypes.bool,
  isTestResultWaiting: PropTypes.bool,
  isSaving: PropTypes.bool,
  saveTestResults: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    saveTestResults(batchUri, fieldsValues, additionalDocument) {
      const payload = {
        action_type: MATERIAL_BATCH_ACTION_TYPES.TEST,
        source_batch: batchUri,
        ...fieldsValues,
      };

      return dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH_ACTION].post(payload)).then(result => {
        if (!additionalDocument) {
          return;
        }
        const uuid = extractUuid(result.headers.location);
        dispatch(
          Actions.Api.nautilus[API_RESOURCES.DOCUMENT].post({
            name: additionalDocument.name,
            related_uuid: uuid,
            related_table_name: DOCUMENT_RELATED_TABLE_NAMES.MATERIAL_BATCH_ACTION,
          }),
        ).then(documentUploadResult => {
          const uploadDocument = documentUploadResult.headers.location;
          const uploadDocumentLocation = documentUploadResult.headers.uploadLocation;
          dispatch(Actions.Api.nautilus[API_RESOURCES.DOCUMENT].get(extractUuid(uploadDocument)));
          dispatch(Actions.UploadModel.upload(uploadDocumentLocation, additionalDocument));
        });
      });
    },
  };
}

const mapStateToProps = (state, ownProps) => {
  const { batch } = ownProps;

  const testActions = getMaterialBatchTestActionsForBatch(state, batch);

  const isTestResultWaiting = batch.custom_status === MATERIAL_BATCH_CUSTOM_STATUSES.IN_TESTING;

  const isSaving = state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH_ACTION].post.fetching
    || state.ui.nautilus[API_RESOURCES.DOCUMENT].post.fetching;

  return {
    testActions,
    isVisible: testActions.length > 0 || isTestResultWaiting,
    isTestResultWaiting,
    isSaving,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageTestActions);
