import isUuid from './isUuid';
import getShortUUID from './getShortUUID';

export default function getEndpointFromUri(uri) {
  if (!uri) {
    throw new Error(`URI ${uri} is not valid`);
  }

  const uriParts = uri.split('/');
  let uuid = null;

  while (uriParts.length !== 0 && uuid === null) {
    const possiblyUUID = uriParts.pop();
    if (isUuid(possiblyUUID)) {
      uuid = possiblyUUID;
    }
  }

  if (!uuid) {
    throw new Error(`UUID in ${uri} not found`);
  }

  const endpointName = uriParts.pop();
  const shortUUID = getShortUUID(uuid);

  return { uuid, shortUUID, endpointName };
}
