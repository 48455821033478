import Actions from 'rapidfab/actions';
import {
  API_RESOURCES,
  FEATURES,
  LIST_BY_URIS_CHUNK_SIZE,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
} from 'rapidfab/constants';
import { loadPiecesWithPrints } from 'rapidfab/dispatchers/piece';
import extractUuid from 'rapidfab/utils/extractUuid';
import _map from 'lodash/map';
import _chunk from 'lodash/chunk';
import _uniq from 'lodash/uniq';
import _compact from 'lodash/compact';
import {
  loadLineItemDocuments,
  loadLineItemQuotes,
  loadLineItemServiceProviderJobs, loadLineItemWorkflows,
} from 'rapidfab/dispatchers/lineItem';
import isFeatureEnabled from 'rapidfab/utils/isFeatureEnabled';

export const loadOrderRelatedUsers = (dispatch, order) => {
  if (!order) {
    return Promise.reject();
  }

  const {
    order_owner: orderOwner,
    user,
  } = order;

  const userPromises = [];

  // We load these users via `get` since it has no permissions check.
  // `list` may not return all needed users
  if (orderOwner) {
    userPromises.push(dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].get(orderOwner, true)));
  }
  if (user) {
    userPromises.push(dispatch(Actions.Api.nautilus.users.get(user, true)));
  }

  return Promise.all(userPromises);
};

/**
 * @param dispatch {func}
 * @param orderUri {string}
 * @param isRestrictedUser {bool}
 * @param features {object} All features from Store
 */
export const loadLineItemsWithRelatedDataForOrder = (dispatch, orderUri, isRestrictedUser, features) => {
  const orderUriQueryParams = { order: orderUri };

  // Be aware that `utils/isFeatureEnabled` function is used here
  const isPrepWorkflowFeatureEnabled = isFeatureEnabled(features, FEATURES.PREP_WORKFLOW);
  const isSpecimenModelLibraryFeatureEnabled = isFeatureEnabled(features, FEATURES.SPECIMEN_LIBRARY);
  const isAnatomicalModelToProductFeatureEnabled = isFeatureEnabled(features, FEATURES.ANATOMICAL_MODEL_TO_PRODUCT);
  const isServiceProvidersFeatureEnabled = isFeatureEnabled(features, FEATURES.SERVICE_PROVIDERS);

  const relatedDataToLoadDefaults = {
    // Pieces and Prints are visible for unrestricted only
    piecesWithPrints: !isRestrictedUser,
    // Runs are visible for unrestricted only
    runs: !isRestrictedUser,
    // Prep workflows and related data is needed when `prep-workflow` feature is enabled
    // and for unrestricted users only
    prepWorkflowsWithTasks: !isRestrictedUser && isPrepWorkflowFeatureEnabled,
    prepWorkflowAndTaskRecords: !isRestrictedUser && isPrepWorkflowFeatureEnabled,
    // Netfabb block is visible for unrestricted only
    lineItemNetfabb: !isRestrictedUser,
    // Hidden under a feature and visible for unrestricted only
    modelLibrary: !isRestrictedUser && isSpecimenModelLibraryFeatureEnabled,
    // Workflow managing options are visible for unrestricted only
    workChecklistLinking: !isRestrictedUser,
    // Quotes block is visible for unrestricted only
    lineItemQuotes: !isRestrictedUser,
    // Anatomical models and parts are needed when `anatomical-model-to-product` feature is enabled only
    anatomicalModelsAndParts: isAnatomicalModelToProductFeatureEnabled,
    serviceProviders: !isRestrictedUser && isServiceProvidersFeatureEnabled,
  };

  const {
    piecesWithPrints,
    runs,
    prepWorkflowsWithTasks,
    prepWorkflowAndTaskRecords,
    lineItemNetfabb,
    modelLibrary,
    workChecklistLinking,
    lineItemQuotes,
    anatomicalModelsAndParts,
    serviceProviders,
  } = relatedDataToLoadDefaults;
  const promises = [
    dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].list(orderUriQueryParams, {}, {}, {}, true))
      .then(lineItemsResponse => {
        const lineItems = lineItemsResponse?.json?.resources || [];

        // Prevent loading same model twice via `uniq`
        // (e.g. in case of ModelLibrary model, or `duplicated` model)
        const modelUris = _compact(_uniq(lineItems.map(({ additive }) => additive?.model)));

        modelUris.forEach(modelUri =>
          dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].get(extractUuid(modelUri), true)),
        );

        const lineItemURIs = _map(lineItems, 'uri');
        const models = lineItems.map(({ additive }) => additive?.model).filter(model => model);

        if (modelLibrary) {
          _chunk(models, LIST_BY_URIS_CHUNK_SIZE).forEach(modelsChunk => {
            dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].list({ 'additive.model': modelsChunk }));
          });
        }

        if (lineItemURIs.length > 0) {
          if (workChecklistLinking) {
            // Line Item form uses `checklist-linking` to determine if workflow field is editable
            // (see lineItemFormOptions config file for more details)
            dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_CHECKLIST_LINKING].list({
              related_uri: lineItemURIs,
            }));
          }
          if (lineItemQuotes) {
            loadLineItemQuotes(dispatch, lineItemURIs);
          }
          loadLineItemDocuments(dispatch, lineItemURIs);
          if (serviceProviders) {
            loadLineItemServiceProviderJobs(dispatch, lineItems.filter(li => !li.no_model_upload).map(li => li.uri));
          }
          loadLineItemWorkflows(dispatch, lineItems);
        }
      }),
  ];
  if (piecesWithPrints) {
    promises.push(loadPiecesWithPrints(dispatch, orderUriQueryParams));
  }
  if (runs) {
    promises.push(
      dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].list(
        { by_order: orderUri },
        { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      )),
    );
  }
  if (prepWorkflowsWithTasks) {
    promises.push(
      dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_WORKFLOW]
        .list({ include_custom_workflows: true }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT })),
      dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_TASK]
        .list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT })),
    );
  }
  if (prepWorkflowAndTaskRecords) {
    promises.push(
      dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_WORKFLOW_RECORD].list(
        orderUriQueryParams,
        { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      ))
        .then(prepWorkflowRecordsResponse => {
          const prepWorkflowRecordUris = _map(prepWorkflowRecordsResponse.json.resources, 'uri');
          _chunk(
            prepWorkflowRecordUris,
            LIST_BY_URIS_CHUNK_SIZE,
          ).forEach(prepWorkflowRecordUrisChunk => {
            dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_TASK_RECORD].list(
              { prep_workflow_record: prepWorkflowRecordUrisChunk },
              { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
            ));
          });
        }),
    );
  }
  if (lineItemNetfabb) {
    promises.push(
      dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_NETFABB].list(orderUriQueryParams)),
    );
  }
  if (anatomicalModelsAndParts) {
    promises.push(
      dispatch(Actions.Api.nautilus[API_RESOURCES.ASSEMBLY_META]
        .list(orderUriQueryParams, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT })),
      dispatch(Actions.Api.nautilus[API_RESOURCES.ASSEMBLY_PART_META]
        .list(orderUriQueryParams, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT })),
    );
  }
  return Promise.all(promises);
};
