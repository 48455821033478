import React, { useState, useEffect } from 'react';
import _find from 'lodash/find';
import { Card, FormCheck, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import extractUuid from 'rapidfab/utils/extractUuid';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { API_RESOURCES } from 'rapidfab/constants/resources';
import Actions from 'rapidfab/actions';
import Alert from 'rapidfab/utils/alert';
import Loading from 'rapidfab/components/Loading';

const CustomMaterialTestInstructionComponent = ({
  material,
  title,
  parentTest,
  materialTestOperationLinkings,
  materialTestInstructionsByUri,
  materialTestUnits,
}) => {
  const isSaving = useSelector(state => state.ui.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION].post.fetching);
  const [customInstructions, setCustomInstructions] = useState({ 1: '' });
  const [deletedInstructionsURIs, setDeletedInstructionsURIs] = useState([]);
  const dispatch = useDispatch();

  const initializeCustomFormValues = () => {
    const instructions = {};

    const operationLinkingForMaterialTestOperation = _find(materialTestOperationLinkings,
      { related_uri: material?.uri, material_test_operation: parentTest?.material_test_operation });

    const existingCustomInstructions = _map(operationLinkingForMaterialTestOperation?.material_test_instructions,
      customInstructionURI => materialTestInstructionsByUri[customInstructionURI]);

    if (operationLinkingForMaterialTestOperation) {
      existingCustomInstructions.forEach(currentInstruction => {
        instructions[currentInstruction?.position] =
          { name: currentInstruction?.name,
            uri: currentInstruction?.uri || '' };
      });

      setCustomInstructions(instructions);
    }
  };

  useEffect(() => {
    if (!_isEmpty(materialTestInstructionsByUri)) {
      initializeCustomFormValues();
    }
  }, [materialTestInstructionsByUri]);

  const deleteCustomInstructions = async () => {
    const promises = [];

    if (deletedInstructionsURIs.length) {
      deletedInstructionsURIs.forEach(uri => {
        promises.push(dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION]
          .delete(extractUuid(uri))));
      });
    }
    await Promise.all(promises);
    setDeletedInstructionsURIs([]);
  };

  const customInstructionsSave = async () => {
    if (_isEmpty(parentTest)) {
      return;
    }

    const { material_test_operation, material_work_checklist } = parentTest;

    try {
      const promises = [];
      Object.entries(customInstructions).forEach(async item => {
        const [customInstructionId, customInstruction] = item;
        const { name, unit, uri } = customInstruction;
        if (name) {
          const payload = {
            material_test_operation,
            material_work_checklist,
            description: '',
            name,
            position: +customInstructionId,
            unit,
          };
          if (materialTestInstructionsByUri[uri]) {
            promises.push(dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION]
              .put(extractUuid(uri), payload)));
          } else {
            promises.push(dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION].post(payload)));
          }
        }
        await Promise.all(promises);
      });
      await deleteCustomInstructions();
      Alert.success('Custom instructions has been saved');
    } catch (error) {
      Alert.error(error);
    }
  };

  return (
    <Form
      initialValues={customInstructions}
      onSubmit={customInstructionsSave}
      render={({ handleSubmit }) => (
        <Card bg="dark">
          <Card.Header className="pd-exp inverse">
            <div className="d-flex align-items-center justify-content-between w-100">
              <span>
                {title}
              </span>
              {!_isEmpty(parentTest) && (
                isSaving ? <Loading /> : (
                  <FontAwesomeIcon
                    type="submit"
                    role="button"
                    icon={faSave}
                    onClick={!_isEmpty(customInstructions[1]) ?
                      handleSubmit :
                      () => Alert.error('Please add instructions for the selected test')}
                    size="md"
                  />
                )
              )}
            </div>
          </Card.Header>
          <Card.Body>
            {
              !_isEmpty(parentTest) ?
                Object.entries(customInstructions).map(([customInstructionId]) => (
                  <Field
                    name={`instruction_field${customInstructions.length}`}
                    render={({ input }) => (
                      <div className="d-flex align-items-center mb-2">
                        <FormControl
                          name="instructionName"
                          type="text"
                          placeholder={customInstructions[customInstructionId]?.name || 'Instruction Field'}
                          value={customInstructions[customInstructionId]?.name || ''}
                          onChange={event => {
                            input.onChange(event);
                            setCustomInstructions(previous =>
                              ({ ...previous,
                                [customInstructionId]: {
                                  ...customInstructions[customInstructionId],
                                  name: event.target.value,
                                } }
                              ));
                          }}
                          disabled={_isEmpty(parentTest)}
                        />
                        <FormControl
                          name="unit"
                          type="select"
                          as="select"
                          placeholder="Units"
                          className="spacer-left"
                          value={customInstructions[customInstructionId].unit}
                          onChange={event => {
                            input.onChange(event);
                            setCustomInstructions(previous =>
                              ({ ...previous,
                                [customInstructionId]: {
                                  ...customInstructions[customInstructionId],
                                  unit: event.target.value,
                                } }
                              ));
                          }}
                          disabled={_isEmpty(parentTest)}
                        >
                          <option key={null} value={null}>
                            Unit
                          </option>
                          {!_isEmpty(materialTestUnits) && _map(materialTestUnits, unit => (
                            <option key={unit.description} value={unit.uri}>
                              {unit.description} ({unit.symbol})
                            </option>
                          ))}
                        </FormControl>
                        <div className="d-flex align-items-center">

                          {!_isEmpty(parentTest) && ([
                            <>
                              <FontAwesomeIcon
                                size="lg"
                                className="spacer-left"
                                icon={faPlusCircle}
                                onClick={() => setCustomInstructions(previous => ({ ...previous, [Object.entries(customInstructions).length + 1]: '' }))}
                              /><FontAwesomeIcon
                                size="lg"
                                className="spacer-left"
                                icon={faMinusCircle}
                                onClick={() => {
                                  if (Object.keys(customInstructions).length > 1) {
                                    setDeletedInstructionsURIs(previous =>
                                      [...previous, customInstructions[customInstructionId].uri]);
                                    setCustomInstructions(() => {
                                      const updatedList = _omit(customInstructions, customInstructionId);
                                      const newList = {};
                                      // resets the positions back in order when deleting an instruction
                                      Object.values(updatedList).forEach((value, index) => { newList[index] = value; });
                                      return newList;
                                    },
                                    );
                                  } else {
                                    setCustomInstructions({ 1: '' });
                                  }
                                }}
                              />
                            </>,
                          ])}
                        </div>
                      </div>
                    )}
                  />
                )) :
                (
                  <p>
                    Please save this material before creating custom instructions.
                  </p>
                )
            }
          </Card.Body>
        </Card>
      )}
    />
  );
};

CustomMaterialTestInstructionComponent.propTypes = {
  title: PropTypes.string.isRequired,
  parentTest: PropTypes.shape({
    material_test_operation: PropTypes.shape({}).isRequired,
    material_work_checklist: PropTypes.string.isRequired,
  }).isRequired,
  materialTestUnits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestInstructionsByUri: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestOperationLinkings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  material: PropTypes.shape({ uri: PropTypes.string.isRequired }).isRequired,
};

const MaterialTypeTestPanel = ({
  materialTestOperationLinkings,
  materialTestOperations,
  isMaterialTestSelected,
  setIsMaterialTestSelected,
  selectedMaterialTestOperations,
  setSelectedMaterialTestOperations,
  materialTestInstructionsByUri,
  materialTestUnits,
  materialTestUnitsByUri,
  material,
}) => {
  const selectedMaterialTestOperationLinkingUris = _map(selectedMaterialTestOperations, 'uri');

  return (
    <Card bg="dark" className="m-b">
      <Card.Header className="pd-exp accent">
        <div className="d-flex align-items-center justify-content-between">
          <FormattedMessage id="materialTests" defaultMessage="Material Tests" />
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={isMaterialTestSelected ? faMinusCircle : faPlusCircle}
              className="pointer"
              size="lg"
              onClick={() =>
                setIsMaterialTestSelected(previous => !previous)}
            />
          </div>
        </div>
      </Card.Header>
      {
        isMaterialTestSelected && (
          <div className="card-body-wrapper-accent">
            <Card.Body>

              {/* starting work here */}
              <Row>
                <p>Select one or more material tests that can be applied to the following material type.</p>
              </Row>
              <ListGroup>
                {
                  _map(materialTestOperationLinkings.filter(linking => linking.related_uri.includes('bureau')), operationLinking => {
                    const materialTestOperation =
                    _find(materialTestOperations, { uri: operationLinking.material_test_operation });

                    const materialLevelTestOperationLinking = materialTestOperationLinkings.find(
                      ({ material_test_operation, related_uri }) => (
                        material_test_operation === operationLinking.material_test_operation &&
                        related_uri === material?.uri
                      ),
                    );

                    return (
                      <ListGroupItem>
                        <FormCheck
                          checked={selectedMaterialTestOperationLinkingUris.includes(operationLinking.uri)}
                          onChange={event => {
                            if (event.target.checked) {
                              setSelectedMaterialTestOperations(previous => [...previous, operationLinking]);
                            } else {
                              const filteredMaterialTestOperations = [...selectedMaterialTestOperations]
                                .filter(item => (
                                  item.uri !== operationLinking.uri
                                ));
                              setSelectedMaterialTestOperations(filteredMaterialTestOperations);
                            }
                          }}
                          className="pull-right"
                        />
                        <p>{materialTestOperation?.name}</p>
                        {materialTestOperation?.name.trim() === 'Chemical Composition' &&
                          _map(selectedMaterialTestOperations, 'uri').includes(operationLinking.uri) &&
                          (
                            <CustomMaterialTestInstructionComponent
                              materialTestUnits={materialTestUnits}
                              materialTestUnitsByUri={materialTestUnitsByUri}
                              title="Chemicals Used"
                              parentTest={materialLevelTestOperationLinking}
                              materialTestOperationLinkings={materialTestOperationLinkings}
                              material={material}
                              materialTestInstructionsByUri={materialTestInstructionsByUri}
                            />
                          )}
                      </ListGroupItem>
                    );
                  })
                }
              </ListGroup>
            </Card.Body>
          </div>
        )
      }
    </Card>
  );
};

export default MaterialTypeTestPanel;

MaterialTypeTestPanel.propTypes = {
  materialTestOperationLinkings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestOperations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMaterialTestSelected: PropTypes.bool.isRequired,
  setIsMaterialTestSelected: PropTypes.func.isRequired,
  selectedMaterialTestOperations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setSelectedMaterialTestOperations: PropTypes.func.isRequired,
  material: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
  materialTestInstructionsByUri: PropTypes.shape({}).isRequired,
  materialTestUnits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  materialTestUnitsByUri: PropTypes.shape({}).isRequired,
};
