import { createSelector } from 'reselect';
import { getPrintersByUri } from 'rapidfab/selectors/printer';
import { getPostProcessorsByUri } from 'rapidfab/selectors/postProcessor';

// eslint-disable-next-line import/prefer-default-export
export const getWorkstationsByUri = createSelector(
  [getPrintersByUri, getPostProcessorsByUri],
  (printersByUri, postProcessorsByUri) => ({
    ...printersByUri,
    ...postProcessorsByUri,
  }),
);
