import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

import { FormattedMessage } from 'rapidfab/i18n';
import UserFormContainer from 'rapidfab/containers/admin/UserFormContainer';
import Fa from 'react-fontawesome';
import UserAdministrationPanel from 'rapidfab/containers/admin/UserAdministrationPanel';

const User = ({
  handleDeleteUser,
  handleSelectionChange,
  handleViewChange,
  isRestrictedUser,
  isSessionManager,
  user,
  view,
}) => (
  <div>
    <div className="d-flex justify-content-between align-items-center my-2">
      <h2>{user.name || user.username}</h2>
      <div>
        <Button
          variant="primary"
          style={{ marginLeft: '3px', borderRadius: '3px' }}
          className="spacer-right"
          disabled={!isSessionManager}
          onClick={() => handleViewChange('edit')}
        >
          <Fa name="pencil" />
        </Button>
        <Button
          variant="danger"
          style={{ marginLeft: '3px', borderRadius: '3px' }}
          className="spacer-right"
          disabled={isRestrictedUser}
          onClick={() => handleViewChange('delete')}
        >
          <Fa name="trash" />
        </Button>
      </div>
    </div>

    {view === 'main' && (
      <div>
        <span>{Array.isArray(user.emails) && user.emails[0]}</span>
        <UserAdministrationPanel user={user} />
      </div>
    )}
    {view === 'delete' && (
      <div>
        <p>Really delete?</p>
        <div className="btn-group-vertical">
          <Button variant="danger" onClick={handleDeleteUser}>
            Delete {user.name || user.username}
          </Button>
        </div>
      </div>
    )}
    {view === 'edit' && (
      <div>
        <UserFormContainer
          handleSelectionChange={handleSelectionChange}
          user={user}
        />
        <div className="btn-group-vertical mt-1 w-full">
          <Button onClick={() => handleViewChange('main')} variant="secondary">
            <FormattedMessage id="back" defaultMessage="Back" />
          </Button>
        </div>
      </div>
    )}
  </div>
);

User.defaultProps = { user: { name: null } };

User.propTypes = {
  view: PropTypes.string.isRequired,
  handleDeleteUser: PropTypes.func.isRequired,
  handleSelectionChange: PropTypes.func.isRequired,
  handleViewChange: PropTypes.func.isRequired,
  isRestrictedUser: PropTypes.bool.isRequired,
  isSessionManager: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
    emails: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default User;
