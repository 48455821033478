import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import { getUUIDResource } from 'rapidfab/selectors';

const DocumentDownload = (({ downloadedDocument }) => {
  if (!downloadedDocument) {
    return null;
  }

  return (
    // eslint-disable-next-line react/no-unknown-property
    <a href={downloadedDocument.content} download={downloadedDocument.name}>
      {' '}
      <FontAwesome name="download" />
    </a>
  );
});

DocumentDownload.propTypes = {
  // Eslint do not see `uri` usage in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  uri: PropTypes.string.isRequired,
  downloadedDocument: PropTypes.shape({
    content: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

DocumentDownload.defaultProps = {
  downloadedDocument: null,
};

const mapStateToProps = (state, ownProps) => {
  const documentUUID = ownProps.uri && extractUuid(ownProps.uri);
  return {
    downloadedDocument: getUUIDResource(state, documentUUID),
  };
};

export default connect(mapStateToProps)(DocumentDownload);
