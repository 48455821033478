const truncateText = (text, truncationAmount, ellipses = true) => {
  let result = '';
  result = text.slice(0, truncationAmount);

  if (ellipses && text.length > truncationAmount) {
    result += '...';
  }

  return result;
};

export default truncateText;
