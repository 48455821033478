import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Fa from 'react-fontawesome';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _find from 'lodash/find';

import Actions from 'rapidfab/actions';
import { getLabels } from 'rapidfab/selectors';
import { Picky } from 'react-picky';
import extractUuid from 'rapidfab/utils/extractUuid';
import { reduxFormFieldType } from 'rapidfab/types';
import { API_RESOURCES } from 'rapidfab/constants';

class Labels extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
    };

    this.onFilterUpdate = this.onFilterUpdate.bind(this);
    this.getRenderList = this.getRenderList.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.createLabel = this.createLabel.bind(this);
  }

  onFilterUpdate(searchValue) {
    this.setState({
      searchValue,
    });
  }

  async onFormChange(activeLabels) {
    const { field } = this.props;

    // TODO Replace to something more readable on next refactor
    // eslint-disable-next-line unicorn/no-array-reduce
    const flattenLabelsList = await activeLabels.reduce(async (previousPromise, label) => {
      const result = await previousPromise;

      let labelURI;

      if ({}.hasOwnProperty.call(label, 'uri')) {
        // Usually Picky returns previous activeLabels as object
        labelURI = label.uri;
      } else {
        // Looks like this object is not exist and user manually typed this name
        // we need to create this label
        labelURI = await this.createLabel(label);
      }

      result.push(labelURI);

      return result;
    }, Promise.resolve([]));

    field.onChange(flattenLabelsList);
  }

  getRenderList({ items, selectValue, getIsSelected }) {
    const { searchValue } = this.state;
    const { labels } = this.props;

    if (items.length === 0 && searchValue) {
      const labelRecentlyAdded = _map(labels, 'name').includes(searchValue);
      // Due to stupid work of react-picky (when select is opened and list of props were changed)
      // library doesn't update list of fields. We need to add success message or something else
      // to show that this field is updated

      return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li key={searchValue} onClick={() => selectValue(searchValue)}>
          <span>
            { labelRecentlyAdded ?
              <input type="checkbox" checked />
              :
              <Fa name="plus" />}
            { labelRecentlyAdded ? ' Added ' : ' Add '}
            {searchValue}
          </span>
        </li>
      );
    }

    return items.map(item => (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <li key={item.uri} tabIndex={-1} onClick={() => selectValue(item)}>
        <input type="checkbox" checked={getIsSelected(item)} />
        {getIsSelected(item) ? <strong>{item.name}</strong> : item.name}
      </li>
    ));
  }

  getSelectedLabels(selectedLabelURIs) {
    const { labels } = this.props;
    return _filter(
      labels,
      label => selectedLabelURIs.includes(label.uri),
    );
  }

  async createLabel(name) {
    const { dispatch, labels } = this.props;

    const existLabel = _find(labels, { name });
    if (existLabel) {
      return existLabel.uri;
    }

    const labelPostResponse = await dispatch(
      Actions.Api.nautilus[API_RESOURCES.LABEL].post({
        name,
        color: '#ffffff',
      }),
    );

    const labelURI = labelPostResponse.headers.location;
    const labelUUID = extractUuid(labelURI);
    await dispatch(
      Actions.Api.nautilus[API_RESOURCES.LABEL].get(labelUUID, true),
    );

    return labelURI;
  }

  render() {
    const { labels, field } = this.props;

    const selectedLabelURIs = field.value;
    const selectedLabels = this.getSelectedLabels(selectedLabelURIs);

    return (
      <Picky
        id="picky"
        options={labels}
        value={selectedLabels}
        labelKey="name"
        valueKey="uri"
        multiple
        includeFilter
        onChange={values => this.onFormChange(values)}
        getFilterValue={this.onFilterUpdate}
        renderList={this.getRenderList}
      />
    );
  }
}

Labels.defaultProps = {
  field: {},
};

Labels.propTypes = {
  dispatch: PropTypes.func.isRequired,
  field: reduxFormFieldType,
  labels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => ({
  labels: getLabels(state),
});

export default connect(mapStateToProps)(Labels);
