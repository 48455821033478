import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import _isEqual from 'lodash/isEqual';
import Actions from 'rapidfab/actions';
import ShipmentsComponent from 'rapidfab/components/manage/Shipments';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import * as Selectors from 'rapidfab/selectors';
import PropTypes from 'prop-types';
import withRecordsListHandling from '../hocs/withRecordsListHandling';

const ShipmentsContainer = props => {
  const { data } = props;
  const isShipmentForOrderFeatureEnabled =
    useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.SHIPMENT_FOR_ORDER));
  const ordersByUri = useSelector(Selectors.getOrdersByUri);
  const runsByUri = useSelector(Selectors.getRunsByUri);

  const dispatch = useDispatch();

  const onFetchResources = shipments => {
    const orderUris = _compact(_map(shipments, 'order'));
    const runUris = _compact(_map(shipments, 'run'));

    if (orderUris.length) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].list(
        { uri: orderUris },
        { limit: orderUris.length },
      ));
    }
    if (runUris.length) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].list(
        { uri: runUris },
        { limit: runUris.length },
      ));
    }
  };

  const [previousDataUUIDs, setPreviousDataUUIDs] = useState();

  // on mount & when data changes, fetch resources
  useEffect(() => {
    const currentDataUUIDs = _map(data, 'uuid');
    if (
      !_isEqual(
        previousDataUUIDs,
        currentDataUUIDs,
      )
    ) {
      onFetchResources(data);
      setPreviousDataUUIDs(currentDataUUIDs);
    }
  }, [data]);

  return (
    <ShipmentsComponent
      {...props}
      runsByUri={runsByUri}
      ordersByUri={ordersByUri}
      isShipmentForOrderFeatureEnabled={isShipmentForOrderFeatureEnabled}
    />
  );
};

ShipmentsContainer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withRecordsListHandling(
  ShipmentsContainer,
  Selectors.getShipments,
  ['shipment'],
);
