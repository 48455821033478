import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import _isEmpty from 'lodash/isEmpty';
import Alert from 'rapidfab/utils/alert';
import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import { AdminImpersonation } from 'rapidfab/components/admin';
import { useIsPageReloading } from 'rapidfab/hooks';
import { transformUsernameSearchString } from 'rapidfab/utils/impersonation';
import * as Selectors from '../../selectors';

const AdminImpersonationContainer = () => {
  const users = useSelector(Selectors.getUsers);
  const session = useSelector(Selectors.getSession);
  const isLoading = useSelector(state =>
    Selectors.getResourceFetching(state, 'nautilus.users'));
  const isImpersonating = useSelector(
    state => state.ui.nautilus[API_RESOURCES.SESSIONS].put.fetching);
  const hasImpersonationPermissions = useSelector(Selectors.hasImpersonatePermissions);

  const dispatch = useDispatch();
  const isPageCurrentlyReloading = useIsPageReloading();

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].clear());
    // Prefetch first 100 users
    // 5000 (PAGINATION_IGNORE_DEFAULT_LIMIT) is too much
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({}, { limit: 100 }));
  };

  const onSearch = searchString => {
    if (_isEmpty(searchString) || searchString === '') {
      onInitialize();
    }
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].clear());
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({
      username: transformUsernameSearchString(searchString),
    }));
  };

  const findUserByUsername = searchString => {
    const username = transformUsernameSearchString(searchString);
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].clear());
    return dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({
      username,
    }));
  };

  const clearImpersonation = () =>
    // Set the impersonation to null
    dispatch(Actions.Api.nautilus[API_RESOURCES.SESSIONS].put(
      0,
      { impersonating: null },
    ));

  const onImpersonate = async userUri => {
    try {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.SESSIONS].put(
        0, // Looks like it's ignored on backend, but it should be PUT
        { impersonating: userUri },
      ));
      window.location.reload();
    } catch {
      // Add some error handling;
    }
  };

  const onImpersonateEnterPress = async email => {
    const userResponse = await findUserByUsername(email);
    const user = userResponse.json?.resources[0];

    if (_isEmpty(user)) {
      Alert.error(
        // todo: i18n formatting
        `User "${email}" couldn't be found.`,
      );
    }

    if (user) {
      onImpersonate(user.uri);
    }
  };

  return (
    <AdminImpersonation
      session={session}
      isLoading={isLoading}
      isPageCurrentlyReloading={isPageCurrentlyReloading}
      isImpersonating={isImpersonating}
      users={users}
      onInitialize={onInitialize}
      onSearch={onSearch}
      clearImpersonation={clearImpersonation}
      onImpersonate={onImpersonate}
      hasImpersonationPermissions={hasImpersonationPermissions}
      onImpersonateEnterPress={onImpersonateEnterPress}
    />
  );
};

export default AdminImpersonationContainer;
