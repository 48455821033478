const useEnvironmentHosts =
  process.env.EVENTSTREAM_HOST ||
  process.env.SCYLLA_HOST ||
  process.env.NAUTILUS_HOST ||
  process.env.QR_HOST ||
  process.env.SENTRY_DSN;

let config = {
  SENTRY_DSN: process.env.SENTRY_DSN || null,
  HOST: {
    EVENT: process.env.EVENTSTREAM_HOST || 'https://events.dev-auth2.com/',
    SCYLLA: process.env.SCYLLA_HOST || 'https://app.dev-auth2.com',
    NAUTILUS: process.env.NAUTILUS_HOST || 'https://data.dev-auth2.com',
    QR: process.env.QR_HOST || 'https://qr.dev-auth2.com',
    THREADS_API: process.env.THREADS_API_HOST || 'https://api.threads.stage-auth.com'
  },
  TOS_URL: 'https://authentise.com/tech/etos.html',
  AUTH_REDIRECT_SUBDOMAIN: 'rapidfab',
  THREADS_URL: process.env.THREADS_URL || 'https://authentise.threads.stage-auth.com',
};

// useEnvironmentHosts=true for local development only for now
// (since `env` are NOT set for dev/stage/prod)
if (!useEnvironmentHosts) {
  // Use window.configs (config.js from root dir) by default
  // if host variables isn't found in environment
  config = window.Config || config;
}

module.exports = config;
