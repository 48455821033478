import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel, FormGroup } from 'react-bootstrap';
import FormControlField from 'rapidfab/components/forms/FormControlField';
import _upperFirst from 'lodash/upperFirst';

const FormGroupField = ({ name, label, required, controlId, ...otherProps }) => {
  const labelText = label || _upperFirst(name);
  const id = controlId || `ux-${name}`;

  return (
    <FormGroup className="form-group" controlId={id}>
      <FormLabel>{labelText}:{required && ' *'}</FormLabel>
      <FormControlField name={name} required={required} {...otherProps} />
    </FormGroup>
  );
};

FormGroupField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  required: PropTypes.bool,
  controlId: PropTypes.string,
};

FormGroupField.defaultProps = {
  required: false,
  label: null,
  controlId: null,
};

export default FormGroupField;
