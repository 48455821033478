import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { getRunFill } from 'rapidfab/utils/getRunName';
import _map from 'lodash/map';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';

import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import { RUN_OPERATION_MAP, RUN_STATUS_MAP } from 'rapidfab/mappings';

const columns = [
  {
    type: 'uuid',
    uid: 'field.id',
    accessor: 'uuid',
    defaultMessage: 'ID',
    resource: 'run',
  },
  {
    type: 'translatable',
    uid: 'field.status',
    accessor: 'status',
    defaultMessage: 'Status',
    mapping: RUN_STATUS_MAP,
    isSortable: true,
  },
  {
    type: 'time',
    uid: 'created',
    accessor: 'created',
    defaultMessage: 'Created',
    isSortable: true,
  },
  {
    type: 'record',
    uid: 'field.run_name',
    accessor: 'name',
    defaultMessage: 'Run Name',
    resource: 'run',
    uri: 'uri',
    isSortable: true,
  },
  {
    type: 'translatable',
    uid: 'field.operation',
    accessor: 'operation',
    defaultMessage: 'Operation',
    mapping: RUN_OPERATION_MAP,
    isSortable: true,
  },
  {
    type: 'text',
    uid: 'workstation.type',
    accessor: 'workstation_type_name',
    defaultMessage: 'Workstation Type',
    isSortable: true,
  },
  {
    type: 'text',
    uid: 'workstation.name',
    accessor: 'workstation_name',
    defaultMessage: 'Workstation Name',
    isSortable: true,
  },
];

const Runs = componentProps => {
  const { data, fetching, onSort } = componentProps;
  const runsWithLockedState = _map(data, oldRun => ({
    ...oldRun,
    prints_fill: getRunFill(oldRun),
  }));

  const NavbarLinks = (
    <Button
      variant="primary"
      size="sm"
      href={getRouteURI(ROUTES.RUN_CREATE)}
      className="pull-right"
    >
      <Fa name="plus" />{' '}
      <UseNonMfgLanguageFeature
        mfgLanguageComponent={
          <FormattedMessage id="record.run.add" defaultMessage="Add Run" />
        }
        nonMfgLanguageComponent={(
          <FormattedMessage
            id="mfg.addRun.Schedule"
            defaultMessage="Schedule"
          />
        )}
      />
    </Button>
  );

  return (
    <TableWithSearching
      {...componentProps}
      data={runsWithLockedState}
      columns={columns}
      isFetching={fetching}
      withBreadcrumbs
      breadcrumbs={['plan', 'runs']}
      navbar={NavbarLinks}
      isManualSoringEnabled
      manualSortingFunc={onSort}
      initialSortedDesc
      initialSortedColumn="updated"
    />
  );
};

Runs.defaultProps = {
  sort: '',
  search: '',
};

Runs.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.string,
  search: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  recordListItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: PropTypes.shape({}).isRequired,
  recordListStore: PropTypes.shape({}).isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
};

export default Runs;
