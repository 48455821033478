import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Error from 'rapidfab/components/error';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import Loading from 'rapidfab/components/Loading';
import ServiceProviderDropdownButton from 'rapidfab/components/manage/ServiceProviderDropdownButton';
import ServiceProviderForm from 'rapidfab/components/manage/ServiceProviderForm';

import { Form } from 'react-final-form';

const ServiceProvider = ({
  submitting,
  onDelete,
  apiErrors,
  printers,
  onFormSubmit,
  initialFormValues,
}) => (
  <Form
    onSubmit={onFormSubmit}
    initialValues={initialFormValues}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Container fluid>
          <BreadcrumbNav
            breadcrumbs={['manage', 'serviceProviders', initialFormValues?.id ? `${initialFormValues?.name} (${getShortUUID(initialFormValues?.uuid)}` : 'New']}
          />
          <div className="clearfix">
            <ServiceProviderDropdownButton
              fields={initialFormValues}
              onDelete={onDelete}
            />
          </div>

          <hr />

          <Row>
            <Col xs={12}>
              <Error errors={apiErrors} />
            </Col>
          </Row>

          {submitting ? <Loading /> : <ServiceProviderForm initialFormValues={initialFormValues} printers={printers} />}
        </Container>
      </form>
    )}
  />
);

ServiceProvider.propTypes = {
  submitting: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  apiErrors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  printers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initialFormValues: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

export default ServiceProvider;
