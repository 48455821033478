import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';

import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';

const AnatomicalModels = componentProps => {
  const { data, fetching, onSort } = componentProps;
  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'anatomical-model',
    },
    {
      type: 'record',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      resource: 'anatomical-model',
      uri: 'uri',
    },
    {
      type: 'text',
      // id's not provided
      uid: 'assemblies_quantity',
      accessor: 'assemblies_quantity',
      defaultMessage: 'Assemblies Quantity',
    },
    {
      type: 'text',
      uid: 'parts_quantity',
      accessor: 'parts_quantity',
      defaultMessage: 'Parts Quantity',
    },
  ];

  return (
    <TableWithSearching
      {...componentProps}
      data={data}
      columns={columns}
      showLocationsFilter={false}
      navbar={(
        <Button
          variant="primary"
          size="sm"
          href={getRouteURI(ROUTES.ANATOMICAL_MODEL_NEW)}
          className="pull-right"
        >
          <Fa name="plus" />{' '}
          {
            // Not adding translations here, since this code is temporary
            // and will be removed once functionality is no longer needed
          }
          Create Anatomical Model
        </Button>
      )}
      isFetching={fetching}
      isManualSoringEnabled
      manualSortingFunc={onSort}
      initialSortedDesc
      initialSortedColumn="name"
    />
  );
};

AnatomicalModels.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default AnatomicalModels;
