import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Form as BSForm,
  Container,
  Card,
} from 'react-bootstrap';
import Fa from 'react-fontawesome';
import Alert from 'rapidfab/utils/alert';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import {
  FEATURES,
  COMMENT_RELATED_TABLE_NAMES, ROUTES, API_RESOURCES,
} from 'rapidfab/constants';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import NewOrderFormContainer from 'rapidfab/containers/records/order/NewOrderFormContainer';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import { orderFormType } from 'rapidfab/types';
import { useNavigate } from 'react-router-dom';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import _isEmpty from 'lodash/isEmpty';

import { Form } from 'react-final-form';
import createOrReplaceArray from 'rapidfab/utils/createOrReplaceArray';
import getInitialCustomFieldValues from 'rapidfab/utils/getInitialCustomFieldValues';
import { getCustomOrderFieldReferences } from 'rapidfab/selectors';

const SaveOrderButton = ({ submitting }) => (
  <Button
    disabled={submitting}
    type="submit"
    value="submit"
    variant="success"
    size="sm"
  >
    {submitting ? <Loading /> : <SaveButtonTitle resourceName="Order" />}
  </Button>
);

SaveOrderButton.propTypes = { submitting: PropTypes.bool.isRequired };

const NewOrderComponent = ({
  bannerMessage,
  bannerLink,
  isUserRestricted,
  onSubmit,
  submitting,
  initCustomFieldValues,
  onCustomFieldChange,
  customOrderFieldReferences,
}) => {
  const breadcrumbs = ['plan', 'orders', 'New Order'];

  return (
    <Container fluid>
      <BreadcrumbNav breadcrumbs={breadcrumbs} />

      <Form
        onSubmit={onSubmit}
        mutators={{
          initCustomFieldValues,
          onCustomFieldChange,
        }}
        render={({ handleSubmit, form, values }) => (
          <BSForm id="new" horizontal onSubmit={handleSubmit}>
            <ButtonToolbar className="clearfix">
              <div className="flexed-pull-right">
                <SaveOrderButton submitting={submitting} />
              </div>
            </ButtonToolbar>
            <div className="pull-right">
              <a href="https://authentise.zendesk.com/hc/en-us/articles/115003329226-Creating-an-Order">
                <FormattedMessage id="help.link" defaultMessage="Help" />
                <Fa className="spacer-left" name="question-circle" />
              </a>
            </div>
            <hr />

            {isUserRestricted && bannerMessage && (
              <div className="jumbotron">
                <p>{bannerMessage}</p>
                <Button href={bannerLink}>
                  <FormattedMessage
                    id="moreInformation"
                    defaultMessage="More Information"
                  />
                </Button>
              </div>
            )}

            <Card bg="dark">
              <Card.Header className="pd-exp inverse">Order</Card.Header>
              <div className="card-body-wrapper">
                <Card.Body>
                  <NewOrderFormContainer
                    formValues={values}
                    initCustomFieldValues={form.mutators.initCustomFieldValues}
                    onCustomFieldChange={form.mutators.onCustomFieldChange}
                    customOrderFieldReferences={customOrderFieldReferences}
                  />
                </Card.Body>
              </div>
            </Card>

            <ButtonToolbar
              className="clearfix"
              style={{
                marginTop: '0.5rem',
                marginBottom: '3rem',
              }}
            >
              <div className="flexed-pull-right">
                <Button
                  id="new"
                  type="submit"
                  variant="success"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Add Line Items
                </Button>
              </div>
            </ButtonToolbar>
          </BSForm>
        )}
      />
    </Container>
  );
};

NewOrderComponent.defaultProps = {
  bannerMessage: null,
  bannerLink: null,
};

NewOrderComponent.propTypes = {
  bannerMessage: PropTypes.string,
  bannerLink: PropTypes.string,
  isUserRestricted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  initCustomFieldValues: PropTypes.func.isRequired,
  onCustomFieldChange: PropTypes.func.isRequired,
  customOrderFieldReferences: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const NewOrder = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const bureau = useSelector(Selectors.getBureau);
  const customOrderFieldReferences = useSelector(getCustomOrderFieldReferences);
  const isUserRestricted = useSelector(Selectors.isCurrentUserRestricted);
  const submitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MODEL].post.fetching ||
    state.ui.nautilus[API_RESOURCES.ORDER].post.fetching);
  const submittedOrders = useSelector(Selectors.getSubmittedOrders) || [];
  const isOrderBusinessSegmentFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state, FEATURES.ORDER_BUSINESS_SEGMENT,
  ));

  const saveSubmittedOrders = orders =>
    dispatch(Actions.SubmittedOrders.setSubmittedOrders(orders));

  const onCustomFieldChange = useCallback(([field, customField], state, { changeValue }) => {
    const customFieldValues = state.formState.values.custom_field_values;

    const customFieldValuesReplaced = createOrReplaceArray(
      customFieldValues,
      { custom_field: customField.customFieldReferenceUri },
      { value: customField.value },
    );

    changeValue(state, field, () => (customFieldValuesReplaced));
  }, []);

  const initCustomFieldValues = useCallback(([field], state, { changeValue }) => {
    const customFieldValues = state.formState.values.custom_field_values;

    const updatedCustomOrderFieldValues = getInitialCustomFieldValues(
      customOrderFieldReferences, customFieldValues,
    );
    changeValue(state, field, () => (updatedCustomOrderFieldValues));
  }, [customOrderFieldReferences]);

  const resourcesExist = resources => {
    resources.forEach(resource => {
      if (_isEmpty(props[resource])) {
        Alert.error(
          <FormattedMessage
            id="toaster.error.noResourcesFound"
            defaultMessage="No {resource} found, please create {resource} to create this order."
            values={{ resource }}
          />,
        );
      }
    });

    return resources.every(resource => (
      !_isEmpty(resource)
    ));
  };

  const onSubmit = payload => {
    if (!resourcesExist([
      'shippings',
      'locations',
    ])) return;

    const orderPayload = {
      application_type: payload.application_type,
      bureau: bureau.uri,
      due_date: payload.due_date
        ? new Date(payload.due_date).toISOString()
        : null,
      custom_field_values: payload.custom_field_values,
      customer_email: payload.customer_email,
      customer_name: payload.customer_name,
      customer_po: payload.customer_po,
      ip_sensitivity: payload.ip_sensitivity,
      location: payload.location,
      name: payload.name,
      order_owner:
        payload.order_owner === '' ? null : payload.order_owner,
      order_type: payload.order_type,
      priority: payload?.priority && Number.parseInt(payload.priority, 10),
      quote_number: payload.quote_number,
      region: payload.region,
      shipping: {
        address: payload.shipping.address,
        name: payload.shipping.name,
        tracking: payload.shipping.tracking,
        uri: payload.shipping.uri,
      },
      shipping_grouping: payload.shipping.uri
        ? payload.shipping_grouping
        : null,
    };
    const initialComment = payload.initial_comment;

    Object.keys(orderPayload).forEach(key => {
      if (orderPayload[key] == null || orderPayload[key] === 'none') {
        delete orderPayload[key];
      }
    });

    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].post(orderPayload))
      .then(async response => {
        const orderUuid = extractUuid(response.payload.uri);

        if (initialComment) {
          try {
            await dispatch(Actions.Api.nautilus[API_RESOURCES.COMMENT].post({
              related_table_name: COMMENT_RELATED_TABLE_NAMES.ORDER,
              related_uuid: orderUuid,
              text: initialComment,
            }));
          } catch {
            Alert.error(
              <FormattedMessage
                id="toaster.error.comment.initialComment"
                defaultMessage="Something went wrong with submitting the initial comment. Please add comment manually."
              />);
          }
        }

        try {
          const localStorageOrderLimit = 25;
          submittedOrders.unshift(orderPayload);
          saveSubmittedOrders(
            submittedOrders.slice(0, localStorageOrderLimit),
          );
        } catch {
          // empty
        }

        navigate(getRouteURI(
          ROUTES.ORDER_EDIT,
          { uuid: orderUuid },
          {},
          true));
      },
      )
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        Alert.error(error.message);
      });
  };

  const htmlBannerObject = {
    __html: bureau.order_banner.html_message,
  };
  // This html is sanitized by the server
  // eslint-disable-next-line react/no-danger
  const htmlBannerMessageRender = (
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={htmlBannerObject} />
  );

  return (
    <NewOrderComponent
      {...props}
      bannerLink={bureau.order_banner.link}
      bannerMessage={bureau.order_banner.html_message ?
        htmlBannerMessageRender :
        bureau.order_banner.message}
      onSubmit={onSubmit}
      isUserRestricted={isUserRestricted}
      submitting={submitting}
      isOrderBusinessSegmentFeatureEnabled={isOrderBusinessSegmentFeatureEnabled}
      initCustomFieldValues={initCustomFieldValues}
      onCustomFieldChange={onCustomFieldChange}
      customOrderFieldReferences={customOrderFieldReferences}
    />
  );
};

NewOrder.propTypes = {
  bureau: PropTypes.shape({
    order_banner: PropTypes.shape({
      message: PropTypes.string,
      html_message: PropTypes.string,
      link: PropTypes.string,
    }),
    uri: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  isOrderBusinessSegmentFeatureEnabled: PropTypes.bool.isRequired,
  submittedOrders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  saveSubmittedOrders: PropTypes.func.isRequired,
  orderForm: orderFormType.isRequired,
  shippings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default NewOrder;
