import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  Button,
} from 'react-bootstrap';
import { Form } from 'react-final-form';
import FormGroupField from 'rapidfab/components/forms/FormGroupField';
import { FormattedMessage } from 'rapidfab/i18n';
import SaveButton from 'rapidfab/components/SaveButton';
import { XEROX_LOGIN_ROUTE_PREFIX, ROUTES } from 'rapidfab/constants';
import Alert from 'rapidfab/utils/alert';
import { Link } from 'react-router-dom';
import Fa from 'react-fontawesome';

const ForgotPassword = ({ onEmailSubmit }) => {
  const isHawkingRoute = window.location.hash.includes(XEROX_LOGIN_ROUTE_PREFIX);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationState, setConfirmationState] = useState({
    confirmationSent: false,
    displayEmail: null,
  });

  const fields = {
    email: 'email',
  };

  const truncateEmail = email => {
    const prefix = email.split('@')[0];
    const suffix = email.split('@')[1];
    const replacingCharsCount = Math.floor(prefix.length * 0.75);
    return `${prefix[0] + '*'.repeat(replacingCharsCount)}@${suffix}`;
  };

  const onSubmitForm = formValues => {
    const { email } = formValues;
    setIsLoading(true);
    onEmailSubmit(email)
      .then(() => {
        Alert.success('We sent an email to you with instructions to reset your password.');
      })
      .catch(error => {
        setIsLoading(false);
        setConfirmationState({ ...confirmationState, confirmationSent: false });
        Alert.error(error);
      })
      .finally(() => {
        setIsLoading(false);
        setConfirmationState({ confirmationSent: true, displayEmail: truncateEmail(email) });
      });
  };

  return (
    <Row>
      <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }} className="mt30">
        <h3>
          <FormattedMessage
            id="forgotPassword"
            defaultMessage="Forgot Password"
          />
        </h3>
        <hr />
        {confirmationState.confirmationSent ? (
          <Card className="my-2" bg="dark">
            <Card.Header>Check your email</Card.Header>
            {
              confirmationState.displayEmail && (
                <Card.Body>
                  <p>A password reset link has been sent to {confirmationState.displayEmail}.</p>
                  <Button
                    className="my-2"
                    onClick={() => setConfirmationState({ ...confirmationState, confirmationSent: false })}
                    variant="success"
                  >
                    <Fa className="spacer-right" name="long-arrow-left" />
                    Back
                  </Button>
                </Card.Body>
              )
            }
          </Card>
        ) : (
          <Form onSubmit={onSubmitForm}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormGroupField
                  name={fields.email}
                  type="email"
                  required
                  label={(
                    <FormattedMessage
                      id="email"
                      defaultMessage="Email"
                    />
                  )}
                />
                {/* <ButtonGroup block vertical> */}
                <div className="btn-group-vertical">
                  <SaveButton
                    showSaveIcon={false}
                    isSaving={isLoading}
                    label={<FormattedMessage id="button.submit" defaultMessage="Submit" />}
                    variant="success"
                    className={isHawkingRoute && 'dark-gray-btn'}
                  />
                </div>
                {/* </ButtonGroup> */}
              </form>
            )}
          </Form>
        )}
        <div className="mt15 text-center">
          <Link to={isHawkingRoute ? ROUTES.LOGIN_XEROX : ROUTES.LOGIN}>
            <FormattedMessage
              id="returnToLogin"
              defaultMessage="Return to login page"
            />
          </Link>
        </div>
      </Col>
    </Row>
  );
};

ForgotPassword.propTypes = {
  onEmailSubmit: PropTypes.func.isRequired,
};

export default ForgotPassword;
