import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _chunk from 'lodash/chunk';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import {
  FEATURES,
  LIST_BY_URIS_CHUNK_SIZE,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  WORKFLOW_TYPES,
  LOCATION_FILTER_DEFAULT_VALUES,
  ROUTES,
  API_RESOURCES,
} from 'rapidfab/constants';
import RunNew from 'rapidfab/components/records/run/RunNew';
import Actions from 'rapidfab/actions';
import {
  getBureauUri,
  getLineItemsForRunNew,
  getPiecesForRunNew,
  getLocationFilter,
  getLocations,
  getMaterials,
  getPrintersForRunNew,
  getSpecimens,
  getModelsByUri,
  getModelLibrariesByUri,
  getAvailableWorkflowsOfType,
  isFeatureEnabled,
  getOrdersByUri,
  getAssemblyPartMeta,
  getAssemblyMeta,
  getIsDebugModeEnabled,
} from 'rapidfab/selectors';
import extractUuid from 'rapidfab/utils/extractUuid';
import { useNavigate } from 'react-router-dom';
import { loadModelLibrariesWithModels } from 'rapidfab/dispatchers/modelLibrary';
import Alert from 'rapidfab/utils/alert';
import { FormattedMessage } from 'react-intl';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItem';

const RunNewContainer = props => {
  const isDebugModeEnabled = useSelector(getIsDebugModeEnabled);
  const bureau = useSelector(getBureauUri);
  let printers = useSelector(getPrintersForRunNew);
  const modelsByUri = useSelector(getModelsByUri);
  const lineItems = useSelector(getLineItemsForRunNew);
  const navigate = useNavigate();

  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MODELER].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL].list.fetching ||
    state.ui.nautilus[API_RESOURCES.ORDER].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PRINTER].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.WORKFLOW].list.fetching);

  const fetchingPieceList = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PRINT].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PIECE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM].list.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_WITH_AVAILABLE_FOR_RUN_PRINTS].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MODEL].list.fetching);

  const creatingRun = useSelector(state => state.ui.nautilus[API_RESOURCES.CREATE_RUNS].post.fetching);

  const orders = useSelector(getOrdersByUri);
  let pieces = useSelector(getPiecesForRunNew);

  const printListStore = useSelector(state => state.ui.nautilus[API_RESOURCES.PRINT].list);
  const lineItemListStore = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_WITH_AVAILABLE_FOR_RUN_PRINTS].list);
  const locations = useSelector(getLocations);

  const locationFilter = useSelector(getLocationFilter);

  if (locationFilter) {
    printers = printers.filter(
      r => r.location === locationFilter,
    );

    pieces = pieces.filter(
      r => r.location === locationFilter,
    );
  }

  const selectedLocations = locationFilter === null
    ? [{ uri: LOCATION_FILTER_DEFAULT_VALUES.UNASSIGNED, name: 'Unassigned' }]
    : locations.filter(location => location.uri === locationFilter);

  const materials = useSelector(getMaterials);
  const specimens = useSelector(getSpecimens);
  const modelLibrariesByUri = useSelector(getModelLibrariesByUri);
  const workflows = useSelector(state => getAvailableWorkflowsOfType(state, WORKFLOW_TYPES.ADDITIVE_MANUFACTURING));
  const isSpecimenLibraryFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.SPECIMEN_LIBRARY));
  /* Line items that contain pieces only */
  const filteredLineItems = lineItems
    .filter(lineItem => !_isEmpty(lineItem.pieces));
  const assemblyMetas = useSelector(state => getAssemblyMeta(state, filteredLineItems));
  const assemblyPartMetas = useSelector(state => getAssemblyPartMeta(state, filteredLineItems));
  const selected = {
    bureau,
    fetching,
    fetchingPieceList,
    lineItems,
    modelsByUri,
    modelLibrariesByUri,
    printers,
    pieces,
    printListStore,
    lineItemListStore,
    specimens,
    locations,
    selectedLocations,
    locationFilter,
    materials,
    workflows,
    creatingRun,
    orders,
    assemblyMetas,
    assemblyPartMetas,
    isSpecimenLibraryFeatureEnabled,
    isDebugModeEnabled,
  };

  const dispatch = useDispatch();
  const loadModelsInChunks = currentLineItems => {
    const models = currentLineItems.map(lineItem => {
      const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
      return lineItem[workflowTypeKey]?.model;
    }).map(model => (model?.uri || model)).filter(Boolean);

    _chunk(models, LIST_BY_URIS_CHUNK_SIZE).forEach(modelsChunk => {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].list(
        { uri: modelsChunk },
        {},
        {},
        {},
        true, // Force update
      ));
    });
  };

  const onInitialize = currentBureau => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.ASSEMBLY_META].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.ASSEMBLY_PART_META].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: currentBureau }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SPECIMEN].list()).then(specimenResponse => {
      if (isSpecimenLibraryFeatureEnabled) {
        const modelLibraryURIs = _uniq(_map(specimenResponse.json.resources, 'model_library'));
        _chunk(modelLibraryURIs, LIST_BY_URIS_CHUNK_SIZE).forEach(modelLibraryURIChunk => {
          loadModelLibrariesWithModels(dispatch, { uri: modelLibraryURIChunk });
        });
      }
    });
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    loadModelLibrariesWithModels(dispatch);
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list());

    // line-item-with-available-for-run-prints used for finding specimen
    // associated with pieces
    dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WITH_AVAILABLE_FOR_RUN_PRINTS].clear('list'));
  };
  const onSave = payload => {
    if (isSpecimenLibraryFeatureEnabled) {
      // If, somehow, a user sets a specimen in a 'create runs' (right side component / top grid)
      // they should get an error
      const activePieces = pieces.filter(piece_ => payload.pieces.includes(piece_.uri));
      const hasInRunSpecimen = activePieces.some(piece => piece.type === 'specimen');
      if (hasInRunSpecimen) {
        Alert.error(
          <FormattedMessage
            id="toaster.error.modelSpecimen.cannotBeAddedToProductionRun"
            defaultMessage="Model Specimens cannot be added to production runs"
          />);
        return;
      }
    }
    dispatch(Actions.Api.nautilus[API_RESOURCES.CREATE_RUNS].post(payload)).then(createRunResponse => {
      // Redirect to create-runs list page, since there is no single CreateRun page for now
      navigate(ROUTES.CREATE_RUNS, { state: {
        uuid: extractUuid(createRunResponse.headers.location),
        autoRedirectToRun: true,
      } });
    });
  };

  const loadDataItems = (
    selectedBuildPlateModel,
    filters,
    pageParams,
    searchParams,
    queryParams,
    currentLocationFilter) => {
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.PRINT].clear('list'),
    );

    if (selectedBuildPlateModel === 'piece') {
      const searchData = { name: searchParams };
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.PRINT].list(
          locationFilter === '' ? { ...filters } : { ...filters, location: extractUuid(locationFilter) },
          pageParams,
          searchData,
          queryParams,
          true, // Force update
        ),
      ).then(
        response => {
          const prints = response.json.resources;
          if (!_isEmpty(prints)) {
            const pieceUris = _uniq(prints.map(print => print.piece));
            const lineItemUris = _uniq(prints.map(print => print.line_item));
            dispatch(
              Actions.Api.nautilus[API_RESOURCES.PIECE].list(
                {
                  uri: pieceUris,
                  ...currentLocationFilter && { location: currentLocationFilter },
                },
                { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
                {},
                {},
                true,
              ),
            );

            dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WITH_AVAILABLE_FOR_RUN_PRINTS].list(
              {
                // There are different URIs, but backend is working only
                // by uuids
                uri: lineItemUris,
              },
              { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
            ),
            ).then(lineItemsResponse => {
              const currentLineItems = lineItemsResponse.json.resources;
              loadModelsInChunks(currentLineItems);
            });
          }
        },
      );
    } else {
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WITH_AVAILABLE_FOR_RUN_PRINTS].clear('list'),
      );
      const updatedFilters = { ...filters };
      if (locationFilter === '') {
        delete updatedFilters.location;
      } else if (!locationFilter) {
        updatedFilters.location = null;
      }
      const searchData = { order_name: searchParams };

      dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WITH_AVAILABLE_FOR_RUN_PRINTS].list(
        updatedFilters,
        pageParams,
        searchData,
        queryParams,
      )).then(
        response => {
          const currentLineItems = response.json.resources;

          _chunk(currentLineItems, LIST_BY_URIS_CHUNK_SIZE).forEach(lineItemChunk => {
            const lineItemURIs = lineItemChunk.map(
              lineItem => lineItem.uri,
            );

            dispatch(
              Actions.Api.nautilus[API_RESOURCES.PRINT].list(
                {
                  line_item: lineItemURIs,
                  process_step_position: 1,
                },
                { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
                {},
                {},
                true,
              ),
            ).then(printsResponse => {
              const pieceUris = _uniq(_map(printsResponse.json.resources, 'piece'));

              _chunk(pieceUris, LIST_BY_URIS_CHUNK_SIZE).forEach(pieceUrisUriChunk =>
                dispatch(
                  Actions.Api.nautilus[API_RESOURCES.PIECE].list(
                    {
                      uri: pieceUrisUriChunk,
                      ...currentLocationFilter && { location: currentLocationFilter },
                    },
                    { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
                    {},
                    {},
                    true,
                  ),
                ));
            });
            loadModelsInChunks(lineItemChunk);
          });
        },
      );
    }
  };
  const handleOnChange = location => {
    dispatch(Actions.LocationFilter.setLocation(location));
  };
  const dispatched = { onInitialize, onSave, loadDataItems, handleOnChange, loadModelsInChunks };

  useEffect(() => onInitialize(bureau, props.uuid), []);

  return (
    <RunNew
      {...props}
      {...selected}
      {...dispatched}
    />
  );
};

RunNewContainer.defaultProps = {
  uuid: null,
};

RunNewContainer.propTypes = {
  bureau: PropTypes.string.isRequired,
  onInitialize: PropTypes.func.isRequired,
  uuid: PropTypes.string,
};

export default RunNewContainer;
