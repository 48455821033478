import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';
import SmoothCollapse from 'react-smooth-collapse';
import { FormattedMessage } from 'rapidfab/i18n';
import VisibleFor from 'rapidfab/components/VisibleFor';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import 'rapidfab/styles/hawking/componentStyles/sidebar.scss';
import Icon, { ICON_NAMES } from 'rapidfab/icons';

// Either provide FaIconName or the icon svg
const SidebarLink = ({ uri, iconName, label, icon }) => {
  const currentHash = window.location.hash;
  return (
    <li className={currentHash === uri ? 'active' : null}>
      <a href={uri}>
        {icon
          ? <Icon name={icon} className="fa-icon-style spacer-right" />
          : <Fa name={iconName} className="spacer-right" />}
        {label}
      </a>
    </li>
  );
};
SidebarLink.defaultProps = {
  icon: null,
  iconName: '',
};
SidebarLink.propTypes = {
  uri: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.oneOf(Object.values(ICON_NAMES)),
};

const CollapsibleSidebarLink = ({ label, iconName, children }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <li>
        <div
          role="button"
          tabIndex={0}
          onClick={() => setExpanded(!expanded)}
        >
          <Fa name={iconName} className="spacer-right" />
          {label}
          <Fa
            name={expanded ? 'chevron-up' : 'chevron-down'}
            className="pull-right"
          />
        </div>
      </li>
      <SmoothCollapse expanded={expanded}>
        <ul className="list-unstyled collapsible-sidebar">
          {children}
        </ul>
      </SmoothCollapse>
    </>
  );
};
CollapsibleSidebarLink.propTypes = {
  iconName: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
};

const Sidebar = () => (
  <nav id="sidebar">
    <ul className="list-unstyled">
      <SidebarLink
        uri={getRouteURI(ROUTES.HAWKING_DASHBOARD)}
        icon={ICON_NAMES.HOME}
        label={<FormattedMessage id="hawking.home" defaultMessage="Home" />}
      />
      <SidebarLink
        uri={getRouteURI(ROUTES.HAWKING_MY_LIBRARY)}
        iconName="user"
        label={<FormattedMessage id="hawking.myLibrary" defaultMessage="My Library" />}
      />
      <SidebarLink
        uri={getRouteURI(ROUTES.HAWKING_COMPANY_LIBRARY)}
        iconName="folder"
        label={<FormattedMessage id="hawking.companyLibrary" defaultMessage="Company Library" />}
      />
      <VisibleFor manager>
        <SidebarLink
          uri={getRouteURI(ROUTES.HAWKING_ADMINISTRATOR_LIBRARY)}
          iconName="folder-open"
          label={<FormattedMessage id="hawking.administratorLibrary" defaultMessage="Administrator Library" />}
        />
        <CollapsibleSidebarLink
          iconName="clipboard"
          label={<FormattedMessage id="admin" defaultMessage="Administration" />}
        >
          <SidebarLink
            label={<FormattedMessage id="users" defaultMessage="Users" />}
            iconName="users"
            uri={getRouteURI(ROUTES.ADMIN_USERS)}
          />
          <SidebarLink
            label={<FormattedMessage id="field.labels" defaultMessage="Labels" />}
            iconName="tags"
            uri={getRouteURI(ROUTES.ADMIN_LABELS)}
          />
          <SidebarLink
            label={<FormattedMessage id="field.analysisSettings" defaultMessage="Analysis Settings" />}
            iconName="sliders"
            uri={getRouteURI(ROUTES.ADMIN_ANALYSIS_SETTINGS)}
          />
        </CollapsibleSidebarLink>
      </VisibleFor>
    </ul>
  </nav>
);

export default Sidebar;
