import Actions from 'rapidfab/actions';
import { API_RESOURCES, LIST_BY_URIS_CHUNK_SIZE } from 'rapidfab/constants';
import _chunk from 'lodash/chunk';
import extractUuid from 'rapidfab/utils/extractUuid';

// eslint-disable-next-line import/prefer-default-export
export const loadModelsInChunks = (dispatch, modelUris) => (

  _chunk(modelUris, LIST_BY_URIS_CHUNK_SIZE).forEach(modelsChunk => {
    if (modelsChunk.length > 1) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].list({ uri: modelsChunk }, {}, {}, {}, true));
    } else {
      // If only one then drop the filter, it gives a 75% speedup
      dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].get(extractUuid(modelsChunk)));
    }
  })
);
