import { XEROX_LOGIN_ROUTE_PREFIX } from 'rapidfab/constants/strings';

// eslint-disable-next-line import/prefer-default-export
export const ROUTES = {
  ABOUT: '/about',
  ADMIN: '/admin',
  ADMIN_BANNER: '/admin/banner',
  ADMIN_IMPERSONATION: '/admin/impersonation',
  ADMIN_LABELS: '/admin/labels',
  ADMIN_ANALYSIS_SETTINGS: '/admin/analysis-settings',
  ADMIN_RECALCULATION: '/admin/recalculation',
  ADMIN_SETTINGS: '/admin/settings',
  ADMIN_USERS: '/admin/users',
  ADMIN_USER_GROUPS: '/admin/user-groups',
  ADMIN_CUSTOM_FIELDS: '/admin/custom-fields',
  ANALYTICS: '/analytics',
  CONVERSION_EDIT: '/records/conversion/:uuid',
  CONVERSION_NEW: '/records/conversion',
  CONVERSIONS: '/manage/conversions',
  FORGOT_PASSWORD: '/forgot',
  FORGOT_PASSWORD_XEROX: `/${XEROX_LOGIN_ROUTE_PREFIX}/forgot`,
  HOME: '/',
  LOGIN: '/login',
  LOGIN_XEROX: `/${XEROX_LOGIN_ROUTE_PREFIX}/login`,
  LOGIN_STANLEY_X: '/stanley-select/login',
  CAPTURES: '/work/captures',
  CAPTURE: '/records/capture/:uuid',
  COMMENT: '/comment/:uuid',
  COMMENT_ACTION: '/comment-assignment/:uuid',
  LOCATION_EDIT: '/records/location/:uuid',
  LOCATION_NEW: '/records/location',
  LOCATIONS: '/manage/locations',
  MANUFACTURER_EDIT: '/records/manufacturer/:uuid',
  MANUFACTURER_NEW: '/records/manufacturer',
  MANUFACTURERS: '/manage/manufacturers',
  MATERIAL_BATCH: '/inventory/batch/:uuid',
  MATERIAL_BATCHES: '/inventory/batches',
  MATERIAL_BATCH_GENEALOGY: '/inventory/batch/:uuid/genealogy',
  MATERIAL_EDIT: '/records/material/:uuid',
  MATERIAL_NEW: '/records/material',
  MATERIALS: '/manage/materials',
  MODEL_LIBRARY: '/manage/model-library',
  BUILDS_LIBRARY: '/manage/builds-library',
  MATERIAL_LOT: '/inventory/lot/:uuid',
  MATERIAL_LOT_NEW: '/inventory/lot',
  MATERIAL_LOTS: '/inventory/lots',
  ORDER_EDIT: '/records/order/:uuid',
  ORDER_NEW: '/records/order',
  ORDERS: '/plan/orders',
  PIECES: '/plan/pieces',
  PIECE_EDIT: '/records/piece/:uuid',
  PRINT_BATCH_CONTAINERS_QR_CODES: '/inventory/batch/:uuid/qr',
  PRINT_BATCH_CONTAINER_QR_CODE: '/inventory/batch/:uuid/qr/:container',
  PRINT_EDIT: '/records/print/:uuid',
  PRINTER_EDIT: '/records/printer/:uuid',
  PRINTER_NEW: '/records/printer',
  PRINTERS: '/inventory/printers',
  PRINTER_TYPE_EDIT: '/records/printer-type/:uuid',
  PRINTER_TYPE_NEW: '/records/printer-type',
  PRINTER_TYPES: '/manage/printer-types',
  PRINT_MATERIAL_LOT_CONTAINERS_QR_CODES: '/inventory/lot/:uuid/qr',
  PRINT_MATERIAL_LOT_CONTAINER_QR_CODE: '/inventory/lot/:uuid/qr/:container',
  PRINT_PRINTER_QR: '/records/printer/:uuid/qr',
  PRINTS: '/plan/prints',
  PROFILE: '/profile',
  POST_PROCESSOR_EDIT: '/records/post-processor/:uuid',
  POST_PROCESSOR_NEW: '/records/post-processor',
  POST_PROCESSORS: '/inventory/post-processors',
  POST_PROCESSOR_SERVICES: '/inventory/post-processor-services',
  POST_PROCESSOR_TYPE_EDIT: '/records/post-processor-type/:uuid',
  POST_PROCESSOR_TYPE_NEW: '/records/post-processor-type',
  POST_PROCESSOR_TYPES: '/manage/post-processor-types',
  POST_PROCESSOR_SERVICE_EDIT: '/records/post-processor-service/:uuid',
  POST_PROCESSOR_SERVICE_NEW: '/records/post-processor-service',
  PREP_TASKS: '/manage/prep-tasks',
  PREP_TASK_RECORDS: '/work/tasks',
  QUEUES: '/work/queues',
  RUN_CREATE: '/records/run',
  RUN_EDIT: '/records/run/:uuid',
  RUNS: '/plan/runs',
  CREATE_RUNS: '/plan/create-runs',
  RESET_PASSWORD: '/reset/:token',
  RESET_PASSWORD_XEROX: `/${XEROX_LOGIN_ROUTE_PREFIX}/reset/:token`,
  RUN_SCHEDULE: '/records/run/:uuid/schedule',
  SENTRY_TEST: '/sentry-test',
  SERVICE_PROVIDER_EDIT: '/records/service-provider/:uuid',
  SERVICE_PROVIDER_JOBS: '/plan/service-provider-jobs',
  SERVICE_PROVIDER_NEW: '/records/service-provider',
  SERVICE_PROVIDERS: '/manage/service-providers',
  SHIPMENT_EDIT: '/records/shipment/:uuid',
  SHIPMENTS: '/manage/shipments',
  SHIPPING_EDIT: '/records/shipping/:uuid',
  SHIPPING_NEW: '/records/shipping',
  SHIPPINGS: '/manage/shipping-partners',
  STOCK_EDIT: '/records/stock/:uuid',
  STOCK_NEW: '/records/stock',
  STOCKS: '/inventory/stocks',
  WIP_MATRIX: '/work/wip-matrix',
  WORKFLOW_CREATE: '/records/workflow',
  WORKFLOW_EDIT: '/records/workflow/:uuid',
  PRODUCTION_WORKFLOW_LIST: '/manage/workflows',
  ASSEMBLY_WORKFLOW_LIST: '/manage/assembly-workflows',
  PREP_WORKFLOW_LIST: '/manage/prep-workflows',
  // Routes below available ONLY for "restricted" roles
  EDIT_RESTRICTED_LINE_ITEM_ORDER: '/records/restricted/order/:uuid/line-items',
  ORDER_RESTRICTED_EDIT: '/records/restricted/order/:uuid',
  ORDER_RESTRICTED_NEW: '/records/restricted/order',
  REVIEW_RESTRICTED_ORDER: '/records/restricted/order/:uuid/review',
  // Routes below available ONLY for Service Provider group
  SERVICE_PROVIDER_JOB_LIST: '/service-provider/job',
  SERVICE_PROVIDER_JOB: '/service-provider/job/:uuid',
  // Routes only available for hawking users
  HAWKING_DASHBOARD: '/home',
  HAWKING_COMPANY_LIBRARY: '/company-library',
  HAWKING_MY_LIBRARY: '/my-library',
  HAWKING_ADMINISTRATOR_LIBRARY: '/administrator-library',

  // Temporary endpoints. Will be removed once the functionality is no longer needed.
  // Hidden under `anatomical-model` feature
  ANATOMICAL_MODELS: '/anatomical-models',
  ANATOMICAL_MODEL_NEW: '/records/anatomical-model',
  ANATOMICAL_MODEL_EDIT: '/records/anatomical-model/:uuid',

  // Deprecated endpoints
  TEMPLATE_NEW: '/records/template',
  TEMPLATE_EDIT: '/records/template/:uuid',

  // `guidelines-engine` feature flag endpoints
  GUIDELINES_ENGINE: '/admin/guidelines-engine',
  GUIDELINES_ENGINE_NEW: '/admin/records/guideline',
  GUIDELINES_ENGINE_EDIT: '/admin/records/guideline/:uuid',

  // `digital-design-warehouse ` feature flag endpoints regular
  DIGITAL_DESIGN_WAREHOUSE: '/digital-design-warehouse',
  DIGITAL_DESIGN_WAREHOUSE_RESTRICTED: '/digital-design-warehouse/restricted',
  DDW_COMPANY_LIBRARY: '/digital-design-warehouse/company-library',
  DDW_CUSTOM_LIBRARY: '/digital-design-warehouse/:customLibrary',
  DDW_CUSTOM_LIBRARY_RESTRICTED: '/digital-design-warehouse/restricted/:customLibrary',
  DDW_MY_LIBRARY: '/digital-design-warehouse/my-library',
  DDW_ALL_DESIGNS: '/digital-design-warehouse/all-designs',
  // `digital-design-warehouse ` feature flag endpoints restricted
  DDW_RESTRICTED_HOME: '/digital-design-warehouse/restricted/home',
  DDW_RESTRICTED_COMPANY_LIBRARY: '/digital-design-warehouse/restricted/company-library',
  DDW_RESTRICTED_MY_LIBRARY: '/digital-design-warehouse/restricted/my-library',
  DDW_RESTRICTED_ADMINISTRATOR_LIBRARY: '/digital-design-warehouse/restricted/administrator-library',

  // Material Tests and Results endpoints
  MATERIAL_TESTS: '/admin/material-tests',
  MATERIAL_TESTS_NEW: '/admin/material-tests',
  MATERIAL_TESTS_EDIT: '/admin/material-tests/:uuid',
};
