import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { Form, FormControl, Row, Col, Button } from 'react-bootstrap';
import FormRow from 'rapidfab/components/FormRow';
import Loading from 'rapidfab/components/Loading';
import { ROUTES } from 'rapidfab/constants';
import { Link } from 'react-router-dom';

const LoginForm = ({ onEmailSubmit, loading, withPassword, ssoURI, onSubmit }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const passwordInputRef = useRef(null);

  const isLoginDisabled = (loading || !email || (withPassword && !password));

  useEffect(() => {
    // Set focus to password input when user sees
    // password input for the first time
    if (withPassword) {
      passwordInputRef.current.focus();
    }
  }, [withPassword]);

  const toSsoLocation = () => {
    if (!ssoURI) {
      return;
    }

    window.location = ssoURI;
  };

  const onLogin = event => {
    event.preventDefault();

    if (!email) {
      return;
    }

    if (!withPassword) {
      // Check for SSO staff
      onEmailSubmit(email);
      return;
    }

    if (!password) {
      return;
    }

    onSubmit(email, password);
  };

  const LoginButtonText = () => {
    if (loading) {
      return <Loading />;
    }
    if (withPassword) {
      return 'Login';
    }
    return 'Next';
  };

  return (
    <Row>
      <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }}>
        <hr />
        {/* horizontal prop */}
        <Form onSubmit={onLogin}>
          <FormRow id="email" defaultMessage="Email">
            <FormControl
              name="email"
              type="text"
              autoComplete="username"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
            />
          </FormRow>

          {
            withPassword && (
              <>
                <FormRow
                  id="field.password"
                  defaultMessage="Password"
                >
                  <FormControl
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                    ref={passwordInputRef}
                  />
                </FormRow>
                <div className="text-right">
                  <Link
                    to={ROUTES.FORGOT_PASSWORD}
                  >
                    Forgot Password?
                  </Link>
                </div>
              </>
            )
          }

          <Row style={{ justifyContent: 'center' }}>
            {/* SSO Disabled: [/4/, 4 (LOGIN), /4/]
                SSO Enabled:  [/2/, 4 (LOGIN), 4 (SSO), /2/] */}
            <Col xs={{ span: ssoURI ? 6 : 12 }} md={{ span: 4 }}>
              <Button
                block
                size="lg"
                type="submit"
                className="primary-submit-btn"
                disabled={isLoginDisabled}
              >
                <LoginButtonText />
              </Button>
            </Col>
            {ssoURI && (
              <Col xs={6} md={4}>
                <Button
                  block
                  size="lg"
                  onClick={() => toSsoLocation()}
                  variant="info"
                >
                  Login via SSO
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

LoginForm.propTypes = {
  onEmailSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  withPassword: PropTypes.bool.isRequired,
  ssoURI: PropTypes.string,
};

LoginForm.defaultProps = {
  ssoURI: null,
};

export default LoginForm;
