export const PRINT_TYPES = {
  PRODUCT: 'product',
  SPECIMEN: 'specimen',
  WASTE: 'waste',
  TOOLING: 'tooling',
};

export const MODEL_TYPES = {
  STL: 'stl',
};

export const DESIGN_FILE_TYPES = {
  SINGLE_MESH: 'single-mesh',
  ANATOMICAL_MODEL: 'anatomical-model',
};

export const PRODUCT_TYPES = {
  // Product type is a copy of Design File type for now
  ...DESIGN_FILE_TYPES,
};

export const WORKFLOW_TYPES = {
  SPECIMEN: 'specimen',
  ADDITIVE_MANUFACTURING: 'additive_manufacturing',
  ASSEMBLY: 'assembly', // Used ONLY when `anatomical-model-to-product` is enabled
  POWDER_MANUFACTURING: 'powder_manufacturing',
};

export const LINE_ITEM_COMPOSITION_TYPES = {
  SINGLE_MESH_PRODUCT: 'single-mesh-product', // Regular 1 model product line item
  CO_PRINT: 'co-print', // co-printed multi-mesh line item (main co-print assembly line item)
  CO_PRINT_PART: 'co-print-part', // co-printed multi-mesh line item part
  ASSEMBLY: 'assembly', // main glue/magnet assembly line item
  ASSEMBLY_PART: 'assembly-part', // glue/magnet assembly part line item
};

export const ASSEMBLY_META_ASSEMBLING_TYPES = {
  NONE: 'None', // Means `Co-Print`
  GLUED: 'Glued',
  MAGNET: 'Magnet',
};
