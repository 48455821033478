import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import Loading from 'rapidfab/components/Loading';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';

const Stocks = props => {
  const { stocks,
    materials,
    locations,
    fetching,
    isMaterialManagementFeatureEnabled,
    onSort } = props;

  const NavbarLinks = (
    <div className="pull-right d-flex">
      {!isMaterialManagementFeatureEnabled && (
        <Button
          variant="primary"
          size="sm"
          href={getRouteURI(ROUTES.STOCK_NEW)}
          className="pull-right"
        >
          <Fa name="plus" />{' '}
          <FormattedMessage
            id="record.materialStock.add"
            defaultMessage="Add Material Stock"
          />
        </Button>
      )}
    </div>
  );

  const breadcrumbs = ['inventory', 'materialStocks'];

  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'stock',
    },
    {
      type: 'resource',
      uid: 'field.material',
      accessor: 'material',
      defaultMessage: 'Material',
      resource: 'material',
      resources: materials,
      isSortable: true,
    },
    {
      type: 'resource',
      uid: 'field.location',
      accessor: 'location',
      defaultMessage: 'Location',
      resource: 'location',
      resources: locations,
      isSortable: true,
    },
    {
      type: 'caps',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'field.quantity',
      accessor: 'quantity',
      defaultMessage: 'Quantity',
      isSortable: true,
    },
  ];

  return (
    <>
      {fetching ? (
        <Loading />
      ) : (
        <TableWithSearching
          {...props}
          isFetching={fetching}
          withBreadcrumbs
          showLocationsFilter
          locations={Object.values(locations)}
          breadcrumbs={breadcrumbs}
          navbar={NavbarLinks}
          data={stocks}
          columns={columns}
          isFilteringEnabled={false}
          withDefaultPagination={false}
          isManualSoringEnabled
          manualSortingFunc={onSort}
          isUpdatedColumnShown={false}
          initialSortedDesc
        />
      )}
    </>
  );
};

Stocks.propTypes = {
  fetching: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stocks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMaterialManagementFeatureEnabled: PropTypes.bool.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default Stocks;
