import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import Feature from 'rapidfab/components/Feature';
import ModalThreeScene from 'rapidfab/components/ModalThreeScene';
import NetfabbImportExport from 'rapidfab/components/NetfabbImportExport';
import ForgeAuth from 'rapidfab/components/ForgeAuth';
import LineItemEditFormContainer from 'rapidfab/containers/records/order/LineItemEditFormContainer';
import VisibleFor from 'rapidfab/components/VisibleFor';
import * as Selectors from 'rapidfab/selectors';
import { getStateForgeToken, getStateModelRotation } from 'rapidfab/selectors/baseStateSelectors';
import Config from 'rapidfab/config';
import Documents from 'rapidfab/components/records/Documents';
import {
  API_RESOURCES,
  DOCUMENT_RELATED_TABLE_NAMES,
  FEATURES,
  LINE_ITEM_COMPOSITION_TYPES,
  MODEL_UNITS,
  TEXT_COLOR_CONTRAST,
  USER_HIDE_INFO_TYPES,
} from 'rapidfab/constants';
import _keyBy from 'lodash/keyBy';

import { getPiecesForLineItem } from 'rapidfab/selectors';
import ServiceProviderJobListContainer from 'rapidfab/containers/records/order/ServiceProviderJobListContainer';
import LineItemPiecesContainer from 'rapidfab/containers/records/order/LineItemPiecesContainer';
import LineItemQuoteContainer from 'rapidfab/containers/records/order/LineItemQuoteContainer';
import LineItemPrepWorkflowPanelContainer from 'rapidfab/containers/records/order/LineItemPrepWorkflowPanelContainer';
import EstimatesAndActuals from 'rapidfab/components/records/order/edit/EstimatesAndActuals';
import AssemblyLayout from 'rapidfab/components/records/order/edit/LineItem/AssemblyLayout';
import CoPrintLayout from 'rapidfab/components/records/order/edit/LineItem/CoPrintLayout';
import AssemblyPartLayout from 'rapidfab/components/records/order/edit/LineItem/AssemblyPartLayout';
import CoPrintPartsContainer from 'rapidfab/containers/records/order/CoPrintPartsContainer';
import LineItemFileDetails from 'rapidfab/components/records/order/edit/LineItemFileDetails';
import SmoothCollapse from 'react-smooth-collapse';
import Fa from 'react-fontawesome';
import { capitalize } from 'lodash/string';
import Alert from 'react-s-alert';
import { LINE_ITEM_STATUS_COLOR_CODE_MAPPING,
  WORKFLOW_TYPES_ESTIMATES_KEY_MAP } from 'rapidfab/mappings';
import Actions from 'rapidfab/actions';
import CancelOrDeleteModal from 'rapidfab/components/CancelOrDeleteModal';
import useOnScreen from 'rapidfab/hooks/useComponentOnScreen';
import DebugModeDataPanel from 'rapidfab/components/DebugMode/DebugModeDataPanel';
import { FormattedMessage } from 'rapidfab/i18n';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItem';

const getSnapshotFromLineItem = (lineItem, model) => {
  const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);

  if (!lineItem || !model) {
    return 'LOADING';
  }

  if (lineItem[workflowTypeKey].no_model_upload) {
    return 'NO_MODEL_UPLOAD';
  }

  const snapshotContent = model.snapshot_content;

  // the default must return 'loading' due to imperfect information from the
  // event stream. E.g: The UI can receive a model that is 'processed' but
  // without a snapshot

  if (snapshotContent) {
    return snapshotContent;
  }

  if (lineItem.status === 'error') {
    return 'ERROR';
  }

  return 'LOADING';
};

const getModelContentFromLineItem = (lineItem, model) => {
  if (!lineItem || !model) {
    return 'LOADING';
  }

  if (lineItem[getLineItemWorkflowTypeObjectKey(lineItem)].no_model_upload) {
    return 'NO_MODEL_UPLOAD';
  }

  const modelContent = model?.content;
  // the default must return 'loading' due to imperfect information from the
  // event stream. E.g: The UI can receive a model that is 'processed' but
  // without a snapshot

  if (modelContent) {
    return modelContent;
  }

  if (lineItem.status === 'error') {
    return 'ERROR';
  }

  return 'LOADING';
};

const LineItem = props => {
  const {
    readOnly,
    expandMode,
    setExpandedItems,
    setExpandMode,
    expandedItems,
    productIndex,
  } = props;
  const dispatch = useDispatch();
  const [isWorkflowChanged, setIsWorkflowChanged] = useState(false);
  const [modelUpload, setModelUpload] = useState(null);
  const [fileDetailsEditMode, setFileDetailsEditMode] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalType, setModalType] = useState('');
  const currency = useSelector(Selectors.getBureauDefaultCurrency);
  const lineItem = useSelector(state => Selectors.getUUIDResource(state, extractUuid(props.uri)));
  const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
  const workflowTypeEstimatesKey = WORKFLOW_TYPES_ESTIMATES_KEY_MAP[lineItem?.workflow_type];
  const pieces = useSelector(state => getPiecesForLineItem(state, lineItem));
  const piecesWithModifiedWorkflowByWorkflowURI = _keyBy(pieces?.filter(piece => piece?.workflow !== lineItem?.workflow), 'workflow');

  const order = useSelector(state => Selectors.getUUIDResource(state, extractUuid(lineItem.order)));
  const models = useSelector(Selectors.getModels);
  const uploadData = useSelector(Selectors.getUploadModel);
  const isRestrictedUser = useSelector(Selectors.isCurrentUserRestricted);
  const role = useSelector(Selectors.getCurrentUserRoleMax);
  const forgeToken = useSelector(state => getStateForgeToken(state)[0]);
  const lineItems = useSelector(state => Selectors.getLineItemsForOrderSortedByCreatedDesc(state, order));
  const customLineItemFieldReferences = useSelector(Selectors.getCustomLineItemFieldReferences);
  const rotation = useSelector(
    state => getStateModelRotation(state)[extractUuid(props.uri)] ?? state.modelRotation.rotation,
  );
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);

  const model = useMemo(
    () => (lineItem ? models.find(m => m.uri === lineItem[workflowTypeKey]?.model) : null),
    [lineItem, models],
  );
  const snapshot = useMemo(() => getSnapshotFromLineItem(lineItem, model), [lineItem, model]);
  const modelContent = useMemo(() => getModelContentFromLineItem(lineItem, model), [lineItem, model]);
  const unit = useMemo(() => (model?.user_unit) || MODEL_UNITS.MM, [model]);

  const redirect = encodeURIComponent(window.location.href);
  const forgeRedirectUrl = `${Config.HOST.NAUTILUS}/oauth/authorize/autodesk-forge/?redirect=${redirect}`;

  const index = useMemo(
    () => lineItems.findIndex(l => l.uri === lineItem.uri),
    [lineItems, lineItem.uri],
  );

  const assemblyPartMetaByAssemblyMetaUri = useSelector(Selectors.getAssemblyPartMetaByAssemblyMetaUri);
  const assemblyMeta = useSelector(state => Selectors.getAssemblyMetaForLineItem(state, lineItem));
  const hideFinancial = role?.hide_info === USER_HIDE_INFO_TYPES.FINANCIAL;

  useEffect(() => {
    setIsWorkflowChanged(false);
  }, [lineItem?.workflow]);

  useEffect(() => {
    if (!isRestrictedUser && !expandedItems?.length && expandMode === 'expanded') {
      setExpandMode('collapsed');
    }
  }, [isRestrictedUser, expandMode, expandedItems?.length]);

  useEffect(() => {
    if (expandMode === 'expanded') {
      return setExpanded(true);
    }
    if (expandMode === 'collapsed') {
      return setExpanded(false);
    }

    if (
      !isRestrictedUser && expandedItems
      && !expandedItems?.length
      && !expandMode && (index < 1 || isRestrictedUser)) {
      setExpandedItems(expandedItems => [...expandedItems, index]);
    }

    return setExpanded(index < 1 || isRestrictedUser);
  }, [expandMode, index, isRestrictedUser]);

  const lineItemFileDetailsProps = {
    currency,
    lineItem,
    model,
    uploadData,
    snapshot,
    unit,
    modelContent,
    forgeToken,
    forgeRedirectUrl,
    customLineItemFieldReferences,
    assemblyPartMetaByAssemblyMetaUri,
    assemblyMeta,
    hideFinancial,
    rotation,
    uuid: extractUuid(props.uri),
    index,
    workflowTypeKey,
  };

  const lazyLoadRef = useRef();
  const lazyLoadComponentDidAppearOnScreen = useOnScreen(lazyLoadRef);

  const lineItemFileDetailsPropsMemo = useMemo(
    () => lineItemFileDetailsProps,
    [JSON.stringify(lineItemFileDetailsProps)],
  );

  const handleFileChange = useCallback(event => {
    const modelUpload = event.target.files[0];
    if (modelUpload) {
      setModelUpload(modelUpload);
    }
  }, []);

  const handleFileRemove = useCallback(() => {
    setModelUpload(null);
  }, []);

  const onSubmitComplete = useCallback(() => {
    setModelUpload(null);
    setFileDetailsEditMode(false);
  }, []);

  const onWorkflowChange = useCallback(selectedWorkflow => {
    const isWorkflowChanged = lineItem.workflow !== selectedWorkflow;
    setIsWorkflowChanged(isWorkflowChanged);
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list(
        { workflows: selectedWorkflow }, {}, {}, {}, true,
      ));
  }, [lineItem?.workflow]);

  const onDuplicate = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(
        Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].clone(lineItem.uuid, { quantity: lineItem.quantity }),
      );
    } catch (error) {
      Alert.error(error.message);
    }
    setIsLoading(false);
    Alert.success(
      <FormattedMessage
        id="toaster.lineItem.duplicated"
        defaultMessage="Line item {lineItemUuid} was duplicated successfully."
        values={{ lineItemUuid: lineItem.uuid }}
      />,
    );
  }, [dispatch, lineItem?.uuid, lineItem?.quantity]);

  const onDelete = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.PRODUCT].delete(extractUuid(lineItem.product)))
        .then(() => dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].remove(extractUuid(lineItem.uri))));
    } catch (error) {
      Alert.error(error.message);
    }
    setIsLoading(false);
    Alert.success(
      <FormattedMessage
        id="toaster.lineItem.deleted"
        defaultMessage="Line item {lineItemUuid} was deleted successfully."
        values={{ lineItemUuid: lineItem.uuid }}
      />,
    );
  }, [dispatch, lineItem?.product, lineItem?.uri]);

  const onHandleConfirmModal = useCallback(async () => {
    switch (modalType) {
      case 'duplicate': {
        await onDuplicate();
        break;
      }
      case 'delete': {
        await onDelete();
        break;
      }
      default:
    }
  }, [modalType, onDuplicate, onDelete]);

  if (!lineItem) {
    return null;
  }

  // Don't show the line item while the model is uploading
  if (model?.uuid === modelUpload?.modelUuid && modelUpload?.uploading) {
    return null;
  }

  const {
    composition_type: compositionType,
    uri,
  } = lineItem;
  const {
    no_model_upload: noModelUpload,
  } = lineItem[workflowTypeKey] || {};

  const labelBgColor = LINE_ITEM_STATUS_COLOR_CODE_MAPPING[lineItem.status];
  const labelTextColor = TEXT_COLOR_CONTRAST[labelBgColor];

  const isReadOnly = typeof readOnly === 'function' ? readOnly(lineItem) : readOnly;

  const getPrepWorkflowPanel = () => (
    <VisibleFor unrestricted>
      <Feature featureName={FEATURES.PREP_WORKFLOW}>
        <LineItemPrepWorkflowPanelContainer lineItemUri={uri} />
      </Feature>
    </VisibleFor>
  );

  const getModalThreeScene = () => !noModelUpload && model && (
    <ModalThreeScene
      snapshot={snapshot}
      model={modelContent}
      noModelUpload={noModelUpload}
      unit={unit}
      fileUnit={model.file_unit}
      size={model.size}
      volume={model.volume_mm}
    />
  );
  const getEstimatesAndActuals = () => !noModelUpload && (
    <VisibleFor unrestricted>
      <EstimatesAndActuals lineItemUri={uri} />
    </VisibleFor>
  );

  const getLineItemEditForm = () => (
    <LineItemEditFormContainer
      currency={currency}
      lineItem={lineItem}
      formKey={lineItem.uri}
      readOnly={readOnly}
      onWorkflowChange={onWorkflowChange}
      modelUpload={modelUpload}
      onSubmitComplete={onSubmitComplete}
      handleFileChange={handleFileChange}
      handleFileRemove={handleFileRemove}
      componentDidAppearOnScreen={lazyLoadComponentDidAppearOnScreen}
    />
  );

  const getDocumentsPanel = () => (
    <Documents
      relatedTable={DOCUMENT_RELATED_TABLE_NAMES.LINE_ITEM}
      relatedUUID={extractUuid(lineItem.uri)}
    />
  );

  const getPiecesPanel = () => (
    <VisibleFor unrestricted>
      <LineItemPiecesContainer
        lineItem={lineItem}
        piecesModifiedWorkFlowList={piecesWithModifiedWorkflowByWorkflowURI}
      />
    </VisibleFor>
  );

  const getCoPrintParts = () => (
    <CoPrintPartsContainer coPrintUri={lineItem.uri} isReadOnly={isReadOnly} />
  );

  const getAssemblyParts = () => {
    const assemblyMetaUri = assemblyMeta && assemblyMeta.uri;
    const assemblyPartsMeta = assemblyPartMetaByAssemblyMetaUri[assemblyMetaUri] || [];
    return assemblyPartsMeta.map(({ part_line_item: partLineItemUri }) => (
      <LineItem
        key={partLineItemUri}
        formKey={partLineItemUri}
        uri={partLineItemUri}
        readOnly={readOnly}
        expandMode={expandMode}
        setExpandedItems={setExpandedItems}
        expandedItems={expandedItems}
        setExpandMode={setExpandMode}
        productIndex={productIndex}
      />
    ));
  };

  switch (compositionType) {
    case LINE_ITEM_COMPOSITION_TYPES.SINGLE_MESH_PRODUCT:
      return (
        <Card ref={lazyLoadRef} bg="dark" className="m-b">
          {isDebugModeEnabled && (
            <DebugModeDataPanel style={{ marginBottom: 10 }} className="spacer-bottom" data={lineItem} />
          )}
          <Card.Header
            className="pd-exp inverse d-flex align-items-center justify-content-between position-sticky z-index-1000"
            style={{ top: '56px' }}
            role="button"
            onClick={() => {
              setExpanded(previous => !previous);

              setExpandedItems(expanded => {
                if (!expanded.includes(index)) {
                  return [...expanded, index];
                }
                return expanded.filter(item => item !== index);
              });
            }}
          >
            <div className="d-flex align-items-center">
              <Fa
                name={expanded ? 'angle-up' : 'angle-down'}
                size="2x"
                style={{ lineHeight: '20px' }}
              />
              <span className="mx-2">
                Line Item of {lineItem.name} ({props.productIndex}) - ({lineItem.id})
              </span>
              <span
                className="badge badge-sm"
                style={{
                  backgroundColor: labelBgColor,
                  color: labelTextColor,
                }}
              >
                {capitalize(lineItem.status)}
              </span>
            </div>

            <div
              tabIndex={0}
              role="button"
              onClick={event => {
                event.stopPropagation();
              }}
              className="d-flex align-items-center"
            >
              <Button
                className="me-1"
                variant="primary"
                size="xs"
                title="Duplicate"
                disabled={isLoading}
                onClick={() => setModalType('duplicate')}

              >
                <Fa name="clone" />
              </Button>
              <Button
                variant="danger"
                size="xs"
                title="Delete"
                disabled={isLoading}
                onClick={() => setModalType('delete')}
              >
                <Fa name="trash" />
              </Button>
              {modalType && (
                <CancelOrDeleteModal
                  modalType={modalType}
                  handleConfirm={onHandleConfirmModal}
                  handleOpen={setModalType}
                />
              )}
            </div>
          </Card.Header>
          <SmoothCollapse expanded={expanded}>
            <div className="card-body-wrapper">
              <Card.Body className="p-a">
                <Row>
                  <VisibleFor unrestricted>
                    <Feature featureName={FEATURES.PREP_WORKFLOW}>
                      <Col md={12} className="p-b-md">
                        <LineItemPrepWorkflowPanelContainer lineItemUri={uri} />
                      </Col>
                    </Feature>
                  </VisibleFor>
                  <Col xs={12} md={6}>
                    {!noModelUpload && (
                      <Row>
                        <Col
                          xs={{
                            span: 10,
                            offset: 1,
                          }}
                          lg={{
                            span: 6,
                            offset: 1,
                          }}
                        >
                          <ModalThreeScene
                            snapshot={snapshot}
                            model={modelContent}
                            noModelUpload={noModelUpload}
                            unit={unit}
                            fileUnit={model?.file_unit}
                            size={model?.size}
                            rotation={rotation}
                            originalRotation={model?.manufacturing_orientation}
                            volume={model?.volume_mm}
                            uuid={model?.uuid}
                          />
                        </Col>
                        <Col lg={{ span: 4 }}>
                          <LineItemFileDetails
                            {...props}
                            {...lineItemFileDetailsPropsMemo}
                            model={model}
                            unit={unit}
                            modelUpload={modelUpload}
                            handleFileRemove={handleFileRemove}
                            handleFileChange={handleFileChange}
                            editMode={fileDetailsEditMode}
                            setEditMode={setFileDetailsEditMode}
                            readOnly={readOnly}
                            onSubmitComplete={onSubmitComplete}
                          />
                        </Col>
                      </Row>
                    )}
                    <VisibleFor unrestricted>
                      {!noModelUpload && (
                        <Feature featureName={FEATURES.NETFABB}>
                          <Row>
                            <Col
                              xs={12}
                              lg={{
                                span: 10,
                                offset: 1,
                              }}
                            >
                              {forgeToken ? (
                                <NetfabbImportExport lineItemUri={uri} />
                              ) : (
                                <ForgeAuth redirectUrl={forgeRedirectUrl} />
                              )}
                            </Col>
                          </Row>
                        </Feature>
                      )}
                      <Row className="m-b">
                        <Col
                          xs={12}
                          lg={{
                            span: 10,
                            offset: 1,
                          }}
                        >
                          {!noModelUpload && (
                            <EstimatesAndActuals lineItemUri={uri} />
                          )}
                        </Col>
                      </Row>
                    </VisibleFor>
                    <VisibleFor unrestricted>
                      {!hideFinancial && (
                        <Row>
                          <Col
                            xs={{ span: 12 }}
                            lg={{
                              span: 10,
                              offset: 1,
                            }}
                          >
                            <Feature
                              featureNamesEnforceAll
                              featureNames={[
                                FEATURES.LINE_ITEM_WORK_STEP_QUOTE_DETAILS,
                                FEATURES.ORDER_QUOTE,
                              ]}
                            >
                              <LineItemQuoteContainer
                                lineItemUri={uri}
                                isWorkflowChanged={isWorkflowChanged}
                              />
                            </Feature>
                          </Col>
                        </Row>
                      )}
                    </VisibleFor>

                    <VisibleFor unrestricted>
                      {!noModelUpload && (
                        <Feature featureName={FEATURES.SERVICE_PROVIDERS}>
                          <Row>
                            <Col
                              xs={{ span: 12 }}
                              lg={{
                                span: 10,
                                offset: 1,
                              }}
                            >
                              <ServiceProviderJobListContainer
                                lineItem={uri}
                              />
                            </Col>
                          </Row>
                        </Feature>
                      )}
                    </VisibleFor>
                  </Col>

                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={12}>
                        {expanded && (
                          <LineItemEditFormContainer
                            currency={currency}
                            lineItem={lineItem}
                            formKey={lineItem.uri}
                            readOnly={readOnly}
                            onWorkflowChange={onWorkflowChange}
                            modelUpload={modelUpload}
                            onSubmitComplete={onSubmitComplete}
                            handleFileChange={handleFileChange}
                            handleFileRemove={handleFileRemove}
                            componentDidAppearOnScreen={lazyLoadComponentDidAppearOnScreen}
                            workflowTypeKey={workflowTypeKey}
                            workflowTypeEstimatesKey={workflowTypeEstimatesKey}
                          />
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={{
                        span: 9,
                        offset: 3,
                      }}
                      >
                        <Documents
                          relatedTable={DOCUMENT_RELATED_TABLE_NAMES.LINE_ITEM}
                          relatedUUID={extractUuid(lineItem.uri)}
                          skipInitialize
                        />
                      </Col>
                    </Row>

                    <VisibleFor unrestricted>
                      <Row>
                        <Col xs={{
                          span: 9,
                          offset: 3,
                        }}
                        >
                          <LineItemPiecesContainer
                            lineItem={lineItem}
                            piecesModifiedWorkFlowList={piecesWithModifiedWorkflowByWorkflowURI}
                          />
                        </Col>
                      </Row>
                    </VisibleFor>
                  </Col>
                </Row>
              </Card.Body>
            </div>
          </SmoothCollapse>
        </Card>
      );
    case LINE_ITEM_COMPOSITION_TYPES.ASSEMBLY:
      return (
        <AssemblyLayout
          uri={uri}
          prepWorkflowPanel={getPrepWorkflowPanel()}
          estimatesAndActuals={getEstimatesAndActuals()}
          lineItemEditForm={getLineItemEditForm()}
          documentsPanel={getDocumentsPanel()}
          piecesPanel={getPiecesPanel()}
          assemblyPartsPanel={getAssemblyParts()}
        />
      );
    case LINE_ITEM_COMPOSITION_TYPES.CO_PRINT:
      return (
        <CoPrintLayout
          uri={uri}
          prepWorkflowPanel={getPrepWorkflowPanel()}
          estimatesAndActuals={getEstimatesAndActuals()}
          lineItemEditForm={getLineItemEditForm()}
          documentsPanel={getDocumentsPanel()}
          piecesPanel={getPiecesPanel()}
          coPrintPartsPanel={getCoPrintParts()}
        />
      );
    case LINE_ITEM_COMPOSITION_TYPES.ASSEMBLY_PART:
      return (
        <AssemblyPartLayout
          uri={uri}
          modalThreeScene={getModalThreeScene()}
          estimatesAndActuals={getEstimatesAndActuals()}
          lineItemEditForm={getLineItemEditForm()}
          piecesPanel={getPiecesPanel()}
        />
      );
    case LINE_ITEM_COMPOSITION_TYPES.CO_PRINT_PART:
      // Co-Print part should not be provided here,
      // anyway, as an edge-case returning null to make it obvious
      return null;
    default:
      return null;
  }
};

LineItem.defaultProps = {
  readOnly: false,
};

LineItem.propTypes = {
  formKey: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
  readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  expandMode: PropTypes.oneOfType([null, PropTypes.string]).isRequired,
  setExpandedItems: PropTypes.func.isRequired,
  setExpandMode: PropTypes.func.isRequired,
  expandedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  productIndex: PropTypes.number.isRequired,
};

export default React.memo(LineItem);
