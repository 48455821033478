import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { getCustomDDWLibraryUri } from 'rapidfab/utils/getRouteURI';
import React from 'react';
import { useSelector } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';
import DanfossDDWCustomLibrary from 'rapidfab/components/danfossDDW/DanfossDDWCustomLibrary';
import Loading from 'rapidfab/components/Loading';

const DanfossDDWCustomLibraryContainer = () => {
  const libraries = useSelector(state => Selectors.getLibraries(state));
  const librariesFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.LIBRARY].list.fetching);
  const isRestricted = useSelector(Selectors.isCurrentUserRestricted);
  // Regex here to remove everything after the first "?" in the url.
  const currentUri = window.location.hash.replace(/#|(\?.*)/g, '');

  // Skip further logic if static libraries are being rendered
  if ([ROUTES.DDW_COMPANY_LIBRARY, ROUTES.DDW_MY_LIBRARY, ROUTES.DDW_ALL_DESIGNS].includes(currentUri)) {
    return null;
  }

  const customLibraries = libraries.map(({ name, owner }) => ({
    // Name will be already in the "Routes" pattern, like: /digital-design-warehouse/<name>
    name: getCustomDDWLibraryUri(name, isRestricted),
    owner,
  }));

  const customLibrary = customLibraries.find(({ name }) => name === currentUri);

  return (
    librariesFetching ?
      <Loading /> : (
        <DanfossDDWCustomLibrary
          customLibrary={customLibrary}
        />
      )
  );
};
export default DanfossDDWCustomLibraryContainer;
