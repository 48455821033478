import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import usePrevious from 'rapidfab/hooks';
import PropTypes from 'prop-types';

import Actions from 'rapidfab/actions';
import {
  API_RESOURCES,
  BUILD_PACKER_TYPES,
  FEATURES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  ROUTES,
} from 'rapidfab/constants';
import extractUuid from 'rapidfab/utils/extractUuid';
import {
  getPostProcessorsForRun,
  getPrintersForRun,
  getRunPrints,
  getRouteUUID,
  getRouteUUIDResource,
  getSpecificWorkstationUrisForPrints,
  getUploadModel,
  getUUIDResource,
  isFeatureEnabled,
  getRunActualsForRun, getBuildFilesForRun,
  getPiecesByUri,
} from 'rapidfab/selectors';

import RunRecordForm from 'rapidfab/components/records/run/RunRecordForm';
import _filter from 'lodash/filter';
import Loading from 'rapidfab/components/Loading';
import { buildFileResourceType, runResourceType } from 'rapidfab/types';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import _uniq from 'lodash/uniq';
import _map from 'lodash/map';
import _isEqual from 'lodash/isEqual';
import Alert from 'rapidfab/utils/alert';
import { FormattedMessage } from 'react-intl';

const RunRecordFormContainer = props => {
  const uuid = useSelector(getRouteUUID);
  const run = useSelector(getRouteUUIDResource);
  const uploadModel = useSelector(getUploadModel);

  const isMaterialManagementFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.MATERIAL_MANAGEMENT));
  const runActuals = useSelector(state => getRunActualsForRun(state, run));
  const materialBatchUri = isMaterialManagementFeatureEnabled && runActuals && runActuals.material_batch;

  const printerType = useSelector(state => getUUIDResource(state, extractUuid(run?.printer_type)));

  const postProcessorType = useSelector(state => getUUIDResource(state, extractUuid(run?.post_processor_type)));

  const prints = useSelector(state => getRunPrints(state, run));
  const printsWorkstationUris = useSelector(state => getSpecificWorkstationUrisForPrints(state, prints));

  const isUserManagedPrinterType = run?.batch_type === BUILD_PACKER_TYPES.USER_MANAGED;

  let printers = useSelector(state => getPrintersForRun(state, run));
  if (printsWorkstationUris.length) {
    // If there are Printers used as workstation in prints production workflow steps
    // Limit list to those ones only
    printers = _filter(
      printers,
      printer => printsWorkstationUris.includes(printer.uri),
    );
  }

  let postProcessors = useSelector(state => getPostProcessorsForRun(state, run));
  if (printsWorkstationUris.length) {
    // If there are Post processors used as workstation in prints production workflow steps
    // Limit list to those ones only
    postProcessors = _filter(
      postProcessors,
      postProcessor => printsWorkstationUris.includes(postProcessor.uri),
    );
  }

  const buildFiles = useSelector(state => getBuildFilesForRun(state, run));
  const materialBatch = useSelector(state =>
    (materialBatchUri ? getUUIDResource(state, extractUuid(materialBatchUri)) : null));
  const isGeneralMFGLanguageEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.GENERAL_MFG_LANGUAGE));
  const isSaving = useSelector(state => state.ui.nautilus[API_RESOURCES.RUN].put.fetching);
  const isFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.RUN].get.fetching);
  const piecesByUri = useSelector(state => getPiecesByUri(state));

  const isCertifiedBuildsFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.CERTIFIED_BUILDS));

  const complete = {
    runOperation: run.operation,
    materialBatchUri,
    materialBatch,
    created: run.created,
    initialName: run.name,
    initialPrinter: run.printer,
    initialPostProcessor: run.post_processor,
    initialNotes: run.notes,
    initialStatus: run.status,
    initialLocked: run.pieces_locked,
    errorMessage: run.error,
    uploadModel,
    printerType: run.printer_type,
    printerTypeName: printerType && printerType.name,
    postProcessorType: run.post_processor_type,
    postProcessorTypeName: postProcessorType && postProcessorType.name,
    priority: run.priority,
    shipping: run.shipping,
  };

  const selected = {
    isSaving,
    isFetching,
    uuid,
    printers,
    postProcessors,
    run,
    buildFiles,
    ...(run
      ? complete
      : null),
    isGeneralMFGLanguageEnabled,
    piecesByUri,
    isCertifiedBuildsFeatureEnabled,
    isUserManagedPrinterType,
  };

  const {
    initialName,
    initialNotes,
    initialStatus,
    initialLocked,
    initialPrinter,
    initialPostProcessor,
    shipping,
  } = complete;

  const [name, setName] = useState(initialName || null);
  const [notes, setNotes] = useState(initialNotes || null);
  const [status, setStatus] = useState(initialStatus || null);
  const [locked, setLocked] = useState(initialLocked || false);
  const [printer, setPrinter] = useState(initialPrinter || null);
  const [postProcessor, setPostProcessor] = useState(initialPostProcessor || null);
  const [modelUpload, setModelUpload] = useState(null);
  const [isModelUploading, setIsModelUploading] = useState(false);
  const [postProcessorData, setPostProcessorData] = useState(null);
  const [printerData, setPrinterData] = useState(null);
  const [shippingData, setShippingData] = useState(null);
  const [
    addCertifiedBuildToLibraryConfirmationModalState,
    setAddCertifiedBuildToLibraryConfirmationModalState,
  ] = useState({
    show: false,
  });
  const [buildFileDoesExistInBuildLibrary, setBuildFileDoesExistInBuildLibrary] = useState(false);
  const [existingBuildFiles, setExistingBuildFiles] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setName(initialName || null);
    setNotes(initialNotes || null);
    setStatus(initialStatus || null);
    setLocked(initialLocked || false);
    setPrinter(initialPrinter || null);
    setPostProcessor(initialPostProcessor || null);
  }, [initialLocked, initialName, initialNotes, initialPostProcessor, initialPrinter, initialStatus]);

  const loadMaterialBatch = () => {
    if (materialBatchUri) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].get(extractUuid(materialBatchUri)));
    }
  };

  const onInitialize = () => {
    const {
      uri: runURI,
      post_processor_type: currentPostProcessorType,
      printer_type: currentPrinterType,
      shipping: currentShipping,
      post_processor: currentPostProcessor,
      printer: currentPrinter,
    } = run;

    dispatch(
      Actions.Api.nautilus[API_RESOURCES.PRINT].list(
        { run: runURI, work_needed: false },
        { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
        {},
        {},
        true,
      ),
    );

    dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE].list({
      archived: null,
      run: runURI,
    })).then(currentBuildFiles => {
      const layoutUris = _uniq(_map(currentBuildFiles.json.resources, 'layout')).filter(Boolean);
      layoutUris.forEach(layoutUri => {
        // TODO Layout is only GET endpoint, so we can't to LIST with uri filter for now
        //  It's always 1-2 layouts
        dispatch(Actions.Api.nautilus[API_RESOURCES.LAYOUT].get(extractUuid(layoutUri)));
      });
    });

    if (currentShipping) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].get(
        extractUuid(currentShipping),
      )).then(currentShippingData => setShippingData(currentShippingData?.json));
    }

    if (currentPostProcessorType) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].list({
        post_processor_type: currentPostProcessorType,
      }));

      dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].get(
        extractUuid(currentPostProcessor),
      )).then(currentPostProcessorData => setPostProcessorData(currentPostProcessorData?.json));

      dispatch(
        Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].get(
          extractUuid(currentPostProcessorType),
        ),
      );
    }

    if (currentPrinterType) {
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].get(
          extractUuid(currentPrinterType),
        ),
      );

      dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].get(
        extractUuid(currentPrinter),
      )).then(currentPrinterData => setPrinterData(currentPrinterData?.json));

      dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list({
        printer_type: currentPrinterType,
      }));
    }

    loadMaterialBatch();
  };

  const loadRunActuals = () => {
    const { runURI } = props;
    // Check for runUri to exist, just in case (anyway, it should be always set here)
    if (runURI) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_ACTUALS].list({ run: runURI }));
    }
  };

  const reLoadRun = () => {
    if (run) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].get(run.uuid))
        .then(() => {
          setStatus(run.status);
        });
    }
  };

  useEffect(() => {
    onInitialize();
  }, [uuid]);

  const previousStatus = usePrevious(status);
  const previousMaterialBatchURI = usePrevious(materialBatchUri);
  const previousUploadModelUploading = usePrevious(uploadModel.uploading);

  // TODO: check if previous work

  useEffect(() => {
    if (previousStatus !== status && !buildFiles.length) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE].list({
        archived: null,
        run: run.uri,
      }));
    }
  }, [status]);

  useEffect(() => {
    if (materialBatchUri && previousMaterialBatchURI !== materialBatchUri) {
      // In case material batch uri was set initially or via event stream
      // Or changed, even though, `change` is not possible logically at the moment
      loadMaterialBatch();
    }
  }, [materialBatchUri]);

  useEffect(() => {
    if (isModelUploading && uploadModel.uploading !== previousUploadModelUploading) {
      setIsModelUploading(uploadModel.uploading);
      setModelUpload(null);
    }
  }, [uploadModel.uploading]);

  const previousRun = usePrevious(run);
  useEffect(() => {
    if (!_isEqual(run, previousRun)) {
      reLoadRun();
    }
  }, [run]);

  useEffect(() => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE_LIBRARY].list(
      {},
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      {},
      {},
      false,
    ))
      .then(response => {
        const { resources } = response?.json;
        const foundBuildFiles = resources.filter(({ run_template }) => run_template === run.uri);
        setExistingBuildFiles(foundBuildFiles);
        if (foundBuildFiles.length) {
          setBuildFileDoesExistInBuildLibrary(true);
        }
      });
  }, [buildFileDoesExistInBuildLibrary]);

  const handleDelete = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].delete(uuid))
      .then(() => {
        window.location.hash = getRouteURI(ROUTES.RUNS);
      });
  };

  const handleFileChange = event => {
    setModelUpload(event.target.files[0]);
  };

  const handleFileRemove = () => {
    setModelUpload(null);
  };

  const handleInputChange = event => {
    const { type, checked, name: currentName } = event.target;
    const value = type === 'checkbox' ? checked : event.target.value;
    switch (currentName) {
      case 'name':
        setName(value);
        break;
      case 'status':
        setStatus(value);
        break;
      case 'notes':
        setNotes(value);
        break;
      case 'printer':
        setPrinter(value);
        break;
      case 'postProcessor':
        setPostProcessor(value);
        break;
      case 'pieces_locked':
        setLocked(value);
        break;
      default:
        break;
    }
  };

  const handleAddBuildFileToCertifiedBuildsLibrary = certifiedBuildName => {
    if (!buildFiles || !buildFiles.length) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.buildFile.notFound"
          defaultMessage="Build file could not be found."
        />);
      return;
    }

    const { uri } = buildFiles[0];

    const payload = {
      build_file_template: uri,
      name: certifiedBuildName,
    };

    dispatch(
      Actions.Api.nautilus[API_RESOURCES.BUILD_FILE_LIBRARY].post(payload))
      .then(Alert.success(<FormattedMessage
        id="toaster.buildFileLibrary.addedToCertifiedBuildsLibrary"
        defaultMessage="Successfully added to Certified Builds library"
      />))
      .then(() => setBuildFileDoesExistInBuildLibrary(true))
      .catch(error => Alert.error(error));
  };

  const handleSubmit = () => {
    const payload = {
      name,
      notes,
      pieces_locked: locked,
      printer: printer || initialPrinter,
      post_processor: postProcessor || initialPostProcessor,
      shipping,
      ...(status !== initialStatus ? { status } : null),
    };

    if (modelUpload) {
      const type = modelUpload.name.toLowerCase().split('.').pop();
      dispatch(Actions.UploadModel.uploadProgress(0));
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.BUILD_FILE].post({
          run: run.uri,
          name: modelUpload.name,
          format: type,
        }),
      )
        .then(args => {
          const { uploadLocation } = args.headers;
          setIsModelUploading(true);
          return dispatch(
            Actions.UploadModel.upload(uploadLocation, modelUpload),
          );
        });
    }
    dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].put(uuid, payload)).then(() => {
      // Load run actuals on status change, in case new object is created
      loadRunActuals();
    });
  };

  if (isFetching) {
    return <Loading />;
  }

  return (
    <RunRecordForm
      {...props}
      {...selected}
      name={name}
      operation={run.operation}
      notes={notes}
      status={status}
      pieces_locked={locked}
      printer={printer}
      postProcessorData={postProcessorData}
      postProcessor={postProcessor}
      isModelUploading={isModelUploading}
      modelUpload={modelUpload}
      printerData={printerData}
      shippingData={shippingData}
      handleDelete={handleDelete}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      handleFileChange={handleFileChange}
      handleFileRemove={handleFileRemove}
      addCertifiedBuildToLibraryConfirmationModalState={addCertifiedBuildToLibraryConfirmationModalState}
      setAddCertifiedBuildToLibraryConfirmationModalState={setAddCertifiedBuildToLibraryConfirmationModalState}
      prints={prints}
      handleAddBuildFileToCertifiedBuildsLibrary={handleAddBuildFileToCertifiedBuildsLibrary}
      buildFileDoesExistInBuildLibrary={buildFileDoesExistInBuildLibrary}
      existingBuildFiles={existingBuildFiles}
    />
  );
};

RunRecordFormContainer.defaultProps = {
  initialName: null,
  initialNotes: null,
  initialStatus: null,
  initialLocked: null,
  initialPrinter: null,
  initialPostProcessor: null,
  errorMessage: null,
  printerType: null,
  postProcessorType: null,
  uuid: null,
  runOperation: null,
  materialBatchUri: null,
  materialBatch: null,
  shipping: null,
  buildFiles: [],
};

RunRecordFormContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  initialName: PropTypes.string,
  initialNotes: PropTypes.string,
  initialStatus: PropTypes.string,
  initialLocked: PropTypes.bool,
  initialPrinter: PropTypes.string,
  initialPostProcessor: PropTypes.string,
  errorMessage: PropTypes.string,
  printerType: PropTypes.string,
  postProcessorType: PropTypes.string,
  uuid: PropTypes.string,
  runURI: PropTypes.string.isRequired,
  runOperation: PropTypes.string,
  materialBatchUri: PropTypes.string,
  materialBatch: PropTypes.shape({}),
  uploadModel: PropTypes.shape({
    uploading: PropTypes.bool,
  }).isRequired,
  shipping: PropTypes.string,
  run: runResourceType.isRequired,
  buildFiles: PropTypes.arrayOf(buildFileResourceType),
  isFetching: PropTypes.bool.isRequired,
};
export default RunRecordFormContainer;
