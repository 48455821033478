import React from 'react';
import { API_RESOURCES } from 'rapidfab/constants';
import ResetPassword from 'rapidfab/components/ResetPassword';
import { useDispatch } from 'react-redux';
import Actions from 'rapidfab/actions';
import { useParams } from 'react-router-dom';

const ResetPasswordContainer = props => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const onReset = payload => dispatch(Actions.Api.nautilus[API_RESOURCES.PASSWORD_RESETS].put(token, payload));
  return (
    <ResetPassword
      {...props}
      onReset={onReset}
    />
  );
};

export default ResetPasswordContainer;
