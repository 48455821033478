import Constants from 'rapidfab/constants';

const initialState = {
  show: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case Constants.CHANGE_IMPERSONATION_MODAL:
      return { ...state, show: action.payload };
    default:
      return state;
  }
}

export default reducer;
