import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Actions from 'rapidfab/actions';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import * as Selectors from 'rapidfab/selectors';

import StocksComponent from 'rapidfab/components/inventory/stocks';
import { isFeatureEnabled } from 'rapidfab/selectors';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';

import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import { getStocks } from 'rapidfab/selectors/stock';

const StocksContainer = props => {
  const bureau = useSelector(state => Selectors.getBureauUri(state));
  const fetching = useSelector(state => isFetchingInitial(
    state.ui.nautilus[API_RESOURCES.MATERIAL].list,
    state.ui.nautilus[API_RESOURCES.LOCATION].list,
    state.ui.nautilus[API_RESOURCES.STOCK].list,
  ));
  const locations = useSelector(state => Selectors.getLocationsByUri(state));
  const materials = useSelector(state => Selectors.getMaterialsByUri(state));
  const stocks = useSelector(state => Selectors.getStocks(state));
  const isMaterialManagementFeatureEnabled =
    useSelector(state => isFeatureEnabled(state, FEATURES.MATERIAL_MANAGEMENT));
  const dispatch = useDispatch();

  const selected = {
    bureau,
    fetching,
    locations,
    materials,
    stocks,
    isMaterialManagementFeatureEnabled,
  };

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.STOCK].list());
  };

  useEffect(() => {
    onInitialize();
  }, [bureau]);

  return <StocksComponent {...props} {...selected} />;
};

StocksContainer.propTypes = {
  bureau: PropTypes.string.isRequired,
  onInitialize: PropTypes.func.isRequired,
};

export default withRecordsListHandling(
  StocksContainer,
  getStocks,
  ['stock'],
  {
    defaultLimit: 50,
    searchBy: null,
    defaultSort: '-material',
    useLocationFiltering: true,
    multicolumnSearch: true,
  },
);
