import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  FormGroup,
  FormControl,
  FormLabel,
  Badge,
  Button,
} from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import Loading from 'rapidfab/components/Loading';
import NonHawkingFeature from 'rapidfab/components/hawking/NonHawkingFeature';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import ResourceReadOnlyViewRow, { RESOURCE_READ_ONLY_VIEW_FIELD_TYPES } from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import {
  getBaseAndSupportMaterials,
  getWorkflowsOfType,
  getWorkflowsByUri,
  getBaseMaterialsByUri,
  getSupportMaterialsByUri,
  isFeatureEnabled,
} from 'rapidfab/selectors';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import extractUuid from 'rapidfab/utils/extractUuid';
import {
  MODEL_LIBRARY_TYPES,
  WORKFLOW_TYPES,
  MODEL_LAYER_THICKNESS_SETTINGS,
  WORKFLOW_USAGE_STATES,
  ROUTES,
  FEATURES,
} from 'rapidfab/constants';
import {
  modelLibraryResourceType,
  materialTypeResourceType,
  workflowResourceType,
  finalFormInputTypes,
} from 'rapidfab/types';
import _omitBy from 'lodash/omitBy';
import _isEmpty from 'lodash/isEmpty';
import CustomFieldComponent from 'rapidfab/components/forms/CustomFieldComponent';
import _find from 'lodash/find';

import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { MODEL_LIBRARY_CREATE_CONTAINER, PRODUCT_SPECIMEN_DETAIL_PANEL } from 'rapidfab/constants/forms';
import _set from 'lodash/set';
import _get from 'lodash/get';
import SelectSingleLazy from 'rapidfab/components/forms/SelectSingleLazy';
import { extractInitialFormValues, replaceFormValuesIntoSubDict } from 'rapidfab/utils/formHelpers';

const ProductSpecimenDetailPanel = ({
  modelLibrary,
  onUpdate,
  baseMaterialsByUri,
  supportMaterialsByUri,
  workflowsByUri,
  baseMaterials,
  supportMaterials,
  workflows,
  specimens,
  isHawkingUser,
  initialFormValues,
  customFieldValues,
  onCustomFieldChange,
  customModelLibraryFieldReferences,
  onFetchMoreWorkflows,
  isModelDataEditable,
  isRestrictedUser,
}) => {
  const isProductType = modelLibrary.type === MODEL_LIBRARY_TYPES.PRODUCT;
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  if (!isProductType) {
    return (
      <Card bg="light" className="panel-light mb15">
        <Card.Header>
          <FormattedMessage
            id="record.modelLibrary.specimenDetails"
            defaultMessage="Specimen Details"
          />
        </Card.Header>
        <Card.Body className="pd-exp inverse">
          {specimens.length > 0 && (
            <>
              <Badge bg="success badge-sm">
                Used in {specimens.length} specimen{specimens.length > 1 && 's'}
              </Badge>
              <hr />
            </>
          )}
          <FormattedMessage
            id="record.modelLibrary.specimenDetailsInfo"
            defaultMessage="Specimens are inserted as part of workflows to verify production quality. See on how to use Specimens and Work Instructions to create a robust and traceable quality control system."
          />
        </Card.Body>
      </Card>
    );
  }

  const onCancelEdit = () => {
    setIsEditing(false);
  };

  const onSubmit = async formValues => {
    // in case workflow or materials dropdown "None" is selected, value will be ''
    // and needs to be removed before submission
    PRODUCT_SPECIMEN_DETAIL_PANEL.FLOAT_FIELDS.forEach(
      fieldName => {
        const field = _get(formValues, fieldName);
        if (field) {
          _set(formValues, fieldName, Number.parseFloat(field));
        }
      },
    );

    const modifiedFormValues = _omitBy(formValues, _isEmpty);

    // if workflow isnt included in payload, that means it should be set as null
    if (!modifiedFormValues.workflow) {
      modifiedFormValues.workflow = null;
    }
    setIsSaving(true);
    try {
      modifiedFormValues.custom_field_values = customFieldValues;

      if (formValues.layer_thickness && formValues.layer_thickness !== initialFormValues.layer_thickness) {
        modifiedFormValues.layer_thickness = formValues.layer_thickness;
      }

      // Create new additive sub-dict and move related additive values there
      const payloadValues = replaceFormValuesIntoSubDict(
        modifiedFormValues,
        MODEL_LIBRARY_CREATE_CONTAINER.ADDITIVE,
        'additive',
      );

      await onUpdate(payloadValues);
      setIsEditing(false);
      setIsSaving(false);
    } catch {
      setIsSaving(false);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialFormValues}
      render={({
        handleSubmit,
        form,
        values,
      }) => {
        const baseMaterialUri = values?.base_material;
        const supportMaterialUri = values?.support_material;
        const workflowUri = values?.workflow;

        const baseMaterial = baseMaterialsByUri[baseMaterialUri];
        const supportMaterial = supportMaterialsByUri[supportMaterialUri];
        const workflow = workflowsByUri[workflowUri];

        const baseMaterialUrl = baseMaterial &&
          getRouteURI(ROUTES.MATERIAL_EDIT, { uuid: extractUuid(baseMaterialUri) });
        const supportMaterialUrl = supportMaterial &&
          getRouteURI(ROUTES.MATERIAL_EDIT, { uuid: extractUuid(supportMaterialUri) });
        const workflowUrl = workflow &&
          getRouteURI(ROUTES.WORKFLOW_EDIT, { uuid: extractUuid(workflowUri) });

        const customModelLibraryFieldValue = reference => _find(
          customFieldValues,
          ({ custom_field }) =>
            reference && custom_field === reference.uri,
        );

        return (
          <form
            id="specimen_details"
            onSubmit={handleSubmit}
          >
            <Card className="m-b" bg="light">
              <Card.Header className="pd-exp">
                <div className="d-flex justify-content-between align-items-center">
                  {isHawkingUser ? (
                    <FormattedMessage
                      id="record.modelLibrary.manufacturingDetails"
                      defaultMessage="Manufacturing Details"
                    />
                  ) : (
                    <FormattedMessage
                      id="record.modelLibrary.productDetails"
                      defaultMessage="Product Details"
                    />
                  )}
                  {isSaving ? <Loading /> : (isEditing
                    ? (
                      <div>
                        <Button
                          size="xs"
                          className="mr15 btn-default"
                          onClick={() => {
                            form.reset();
                            onCancelEdit();
                          }}
                        >
                          <Fa name="times" />
                        </Button>
                        <Button form="specimen_details" size="xs" className="btn-primary" type="submit">
                          <Fa name="save" />
                        </Button>
                      </div>
                    )
                    : (
                      isModelDataEditable && (
                        <Button size="xs" className="btn-default" onClick={() => setIsEditing(true)}>
                          <Fa name="edit" />
                        </Button>
                      )
                    ))}
                </div>
              </Card.Header>
              <Card.Body style={{ maxHeight: 400, overflowY: 'scroll' }}>
                {isEditing
                  ? (
                    <div>
                      {
                        !isHawkingUser && (
                          <FormGroup controlId="mlLayerThickness">
                            <FormLabel>
                              <FormattedMessage id="field.layer_thickness" defaultMessage="Layer Thickness" />: *
                            </FormLabel>
                            <Field
                              name="layer_thickness"
                              type="number"
                              render={props => (
                                <FormControl
                                  {...props.input}
                                  min={MODEL_LAYER_THICKNESS_SETTINGS.MIN}
                                  max={MODEL_LAYER_THICKNESS_SETTINGS.MAX}
                                  step={MODEL_LAYER_THICKNESS_SETTINGS.STEP}
                                  required
                                />
                              )}
                            />
                          </FormGroup>
                        )
                      }
                      <FormGroup className="spacer-top" controlId="mlBaseMaterial">
                        <FormLabel>
                          <FormattedMessage id="field.baseMaterial" defaultMessage="Base Material" />:
                        </FormLabel>
                        <Field
                          name="base_material"
                          render={props => (
                            <SelectSingle
                              data={baseMaterials}
                              name={props.input.name}
                              value={props.input.value}
                              handleOnChange={props.input.onChange}
                              imitateOnChangeEvent
                            />
                          )}
                        />
                      </FormGroup>
                      <FormGroup className="spacer-top" controlId="mlSupportMaterial">
                        <FormLabel>
                          <FormattedMessage id="field.supportMaterial" defaultMessage="Support Material" />:
                        </FormLabel>
                        <Field
                          name="support_material"
                          render={props => (
                            <SelectSingle
                              data={supportMaterials}
                              name={props.input.name}
                              value={props.input.value}
                              handleOnChange={props.input.onChange}
                              imitateOnChangeEvent
                            />
                          )}
                        />
                      </FormGroup>
                      <NonHawkingFeature>
                        <FormGroup className="spacer-top" controlId="mlWorkflow">
                          <FormLabel>
                            <FormattedMessage id="workflow" defaultMessage="Production Workflow" />:
                          </FormLabel>
                          <Field
                            name="workflow"
                            render={props => (
                              <SelectSingleLazy
                                name={props.input.name}
                                dataTestId="modelLibraryProductWorkflow"
                                data={workflows}
                                value={props.input.value}
                                handleOnChange={props.input.onChange}
                                imitateOnChangeEvent
                                isOptionDisabledCallback={item =>
                                  item.usage_state === WORKFLOW_USAGE_STATES.ARCHIVED}
                                onFetchMore={onFetchMoreWorkflows}
                                disabled={isRestrictedUser}
                              />
                            )}
                          />
                        </FormGroup>
                      </NonHawkingFeature>
                    </div>
                  )
                  : (
                    <ResourceReadOnlyView entity={modelLibrary}>
                      {
                        !isHawkingUser && (
                          <ResourceReadOnlyViewRow
                            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                            customValue={modelLibrary && modelLibrary.additive.layer_thickness}
                            name="layer_thickness"
                          />
                        )
                      }
                      <ResourceReadOnlyViewRow
                        name="base_material"
                        type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                        customValue={baseMaterial && (
                          <span>
                            {baseMaterial.name}
                            <NonHawkingFeature>
                              <div
                                className="dot spacer-left"
                                style={{ backgroundColor: baseMaterial.color }}
                              />
                              <a
                                className="spacer-left"
                                href={baseMaterialUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Fa name="external-link" />
                              </a>
                            </NonHawkingFeature>
                          </span>
                        )}
                      />
                      <ResourceReadOnlyViewRow
                        name="support_material"
                        type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                        customValue={supportMaterial && (
                          <span>
                            {supportMaterial.name}
                            <NonHawkingFeature>
                              <div
                                className="dot spacer-left"
                                style={{ backgroundColor: supportMaterial.color }}
                              />
                              <a
                                className="spacer-left"
                                href={supportMaterialUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Fa name="external-link" />
                              </a>
                            </NonHawkingFeature>
                          </span>
                        )}
                      />
                      <NonHawkingFeature>
                        <ResourceReadOnlyViewRow
                          name="workflow"
                          type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                          customValue={workflow && (
                            <span>
                              {workflow.name}
                              <a
                                className="spacer-left"
                                href={workflowUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Fa name="external-link" />
                              </a>
                            </span>
                          )}
                        />
                      </NonHawkingFeature>
                    </ResourceReadOnlyView>
                  )}
                {
                  customModelLibraryFieldReferences.filter(field => field.position >= 1)
                    .sort((field, comparedField) => field.position - comparedField.position)
                    .map(
                      field => (isEditing ? (
                        <FormGroup className="spacer-top">
                          <CustomFieldComponent
                            reference={field}
                            value={customModelLibraryFieldValue(field)?.value}
                            onChange={onCustomFieldChange}
                            variant={CustomFieldComponent.variants.row}
                          />
                        </FormGroup>
                      ) :
                        (
                          <ResourceReadOnlyViewRow
                            label={field.field_name}
                            type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                            customValue={(
                              <span>
                                {customModelLibraryFieldValue(field)?.value ?? 'N/A'}
                              </span>
                            )}
                          />
                        )
                      ),
                    )
                }
              </Card.Body>
            </Card>
          </form>
        );
      }}
    />
  );
};

ProductSpecimenDetailPanel.defaultProps = {
  customFieldValues: [],
  onFetchMoreWorkflows: () => null,
};

ProductSpecimenDetailPanel.propTypes = {
  modelLibrary: modelLibraryResourceType.isRequired,
  // handleSubmit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  initialFormValues: PropTypes.shape({
    layer_thickness: PropTypes.string.isRequired,
    base_material: PropTypes.string.isRequired,
    support_material: PropTypes.string.isRequired,
    workflow: PropTypes.string.isRequired,
  }).isRequired,
  values: PropTypes.shape({
    layer_thickness: PropTypes.string,
    base_material: PropTypes.string,
    support_material: PropTypes.string,
    workflow: PropTypes.string,
  }).isRequired,
  baseMaterialsByUri: PropTypes.objectOf(materialTypeResourceType).isRequired,
  supportMaterialsByUri: PropTypes.objectOf(materialTypeResourceType).isRequired,
  workflowsByUri: PropTypes.objectOf(workflowResourceType).isRequired,
  baseMaterials: PropTypes.arrayOf(materialTypeResourceType).isRequired,
  supportMaterials: PropTypes.arrayOf(materialTypeResourceType).isRequired,
  workflows: PropTypes.arrayOf(workflowResourceType).isRequired,
  input: finalFormInputTypes.isRequired,
  specimens: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isHawkingUser: PropTypes.bool.isRequired,
  findCustomFieldReference: PropTypes.func.isRequired,
  customFieldValues: PropTypes.arrayOf(PropTypes.shape({})),
  onCustomFieldChange: PropTypes.func.isRequired,
  customModelLibraryFieldReferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onFetchMoreWorkflows: PropTypes.func,
  isModelDataEditable: PropTypes.bool.isRequired,
  isRestrictedUser: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { modelLibrary } = ownProps;
  const { base: baseMaterials, support: supportMaterials } = getBaseAndSupportMaterials(state);
  const workflows = getWorkflowsOfType(state, WORKFLOW_TYPES.ADDITIVE_MANUFACTURING);
  const workflowsByUri = getWorkflowsByUri(state);
  const baseMaterialsByUri = getBaseMaterialsByUri(state);
  const supportMaterialsByUri = getSupportMaterialsByUri(state);
  const isHawkingUser = isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT) ||
    isFeatureEnabled(state, FEATURES.AUTHENTISE_PDM);

  const initialFormValues = {
    ...extractInitialFormValues(modelLibrary, PRODUCT_SPECIMEN_DETAIL_PANEL.FIELDS),
    ...extractInitialFormValues(modelLibrary.additive || {}, PRODUCT_SPECIMEN_DETAIL_PANEL.ADDITIVE),
  };

  return {
    initialFormValues,
    baseMaterials,
    supportMaterials,
    workflows,
    workflowsByUri,
    baseMaterialsByUri,
    supportMaterialsByUri,
    isHawkingUser,
  };
};

export default connect(mapStateToProps)(ProductSpecimenDetailPanel);
