import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';

import { ROUTES, WORKFLOW_USAGE_STATES } from 'rapidfab/constants';
import WorkflowTabs, { WORKFLOW_TABS } from 'rapidfab/components/manage/WorkflowTabs';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';

const Workflows = componentProps => {
  const {
    specimenWorkflowFeatureEnabled,
    data,
    fetching,
    onSort,
    isAssembly,
    isDebugModeEnabled,
  } = componentProps;
  const tabActiveKey = isAssembly ? WORKFLOW_TABS.ASSEMBLY_WORKFLOWS : WORKFLOW_TABS.PRODUCTION_WORKFLOWS;

  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'workflow',
    },
    {
      type: 'text',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
    },
    specimenWorkflowFeatureEnabled && (
      {
        type: 'workflow',
        uid: 'field.type',
        accessor: 'type',
        defaultMessage: 'Type',
        isSortable: true,
      }
    ),
    {
      type: 'bool',
      uid: 'field.archived',
      accessor: 'usage_state',
      defaultMessage: 'Archived',
      equals: WORKFLOW_USAGE_STATES.ARCHIVED,
    },
  ];

  const NavbarLinks = (
    <Button
      variant="primary"
      size="sm"
      href={getRouteURI(
        ROUTES.WORKFLOW_CREATE,
        {},
        // using `isAssembly` check here to prevent adding `isAssembly=false` to the URI for better UX.
        isAssembly ? { isAssembly: true } : null,
      )}
      className="pull-right"
    >
      <Fa name="plus" />{' '}
      <FormattedMessage
        id="record.workflow.add"
        defaultMessage="Add Workflow"
      />
    </Button>
  );

  return (
    <TableWithSearching
      {...componentProps}
      breadcrumbs={['manage', 'workflows']}
      withBreadcrumbs
      navbar={NavbarLinks}
      subheader={<WorkflowTabs activeKey={tabActiveKey} />}
      data={data}
      columns={columns}
      isFetching={fetching}
      isFilteringEnabled={false}
      withDefaultPagination={false}
      isManualSoringEnabled
      manualSortingFunc={onSort}
      initialSortedDesc
      initialSortedColumn="updated"
      isDebugModeEnabled={isDebugModeEnabled}
    />
  );
};

Workflows.defaultProps = {
  sort: '',
  search: '',
};

Workflows.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.string,
  search: PropTypes.string,
  recordListItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: PropTypes.shape({}).isRequired,
  recordListStore: PropTypes.shape({}).isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  specimenWorkflowFeatureEnabled: PropTypes.bool.isRequired,
  isAssembly: PropTypes.bool.isRequired,
};

export default Workflows;
