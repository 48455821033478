import Constants from 'rapidfab/constants';

export const changeImpersonationModal = payload => ({
  type: Constants.CHANGE_IMPERSONATION_MODAL,
  payload,
});

export const setChangeImpersonationModalState = value => dispatch => {
  dispatch(changeImpersonationModal(value));
};

export default changeImpersonationModal;
