import { Col, Card, Row } from 'react-bootstrap';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import { ROUTES } from 'rapidfab/constants';
import { MATERIAL_BATCH_STATUS_MAP, MATERIAL_LOT_STATUS_MAP } from 'rapidfab/mappings';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import Table from 'rapidfab/components/Tables/Table';

const MaterialBatchAndLotUIContainer = ({ containers, currentType }) => {
  const routeData = currentType.toLowerCase() === 'batch' ? ROUTES.MATERIAL_BATCH : ROUTES.MATERIAL_LOT;
  const currentRoute = currentUUID =>
    getRouteURI(routeData, { uuid: containers.find(currentContainer => currentContainer.uuid === currentUUID).uuid, container: '' }, {}, true);
  const currentItem = currentUUID => {
    if (currentType === 'lot') {
      return containers.find(item => item.uuid === currentUUID);
    }
    return null;
  };

  const columns = [
    {
      type: 'custom',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      Cell: ({ value }) => {
        const href = currentRoute(value);
        const item = currentItem(value);
        const currentID = value && getShortUUID(value);
        const title = item ? `${item?.name} (${currentID})` : currentID;

        return (
          <div className="d-flex">
            <Link
              to={href}
              className="pull-right"
            > {title}
            </Link>
          </div>
        );
      },
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: currentType === 'lot' ? MATERIAL_LOT_STATUS_MAP : MATERIAL_BATCH_STATUS_MAP,
      isSortable: true,
    },
    {
      type: 'suffixed',
      uid: 'field.originalQuantity',
      accessor: 'full_quantity',
      defaultMessage: 'Original Quantity',
      suffix: 'units',
      isSortable: true,
    },
    {
      type: 'suffixed',
      uid: 'field.currentQuantity',
      accessor: 'quantity',
      defaultMessage: 'Current Quantity',
      suffix: 'units',
      isSortable: true,
    },
    currentType === 'lot' ?
      {
        type: 'time',
        uid: 'field.actualDeliveryDate',
        accessor: 'actual_delivery_date',
        defaultMessage: 'Delivered',
        isSortable: true,
      } :
      {
        type: 'time',
        uid: 'created',
        accessor: 'created',
        defaultMessage: 'Created',
        isSortable: true,
        isManuallySorted: true,
      },
  ];

  let panelType = currentType[0].toUpperCase() + currentType.slice(1, currentType.length);

  if (currentType === 'batch') {
    panelType += 'es';
  } else {
    panelType += 's';
  }

  if (!containers?.length) {
    return null;
  }

  return (
    <Col>
      <Card bg="dark" className="mt30">
        <Card.Header className="pd-exp inverse">{`Active Material ${panelType}`}</Card.Header>
        <Card.Body>
          <Row>
            <Col xs={{ span: 12 }}>
              <Table
                tableID="materialBatchAndLotUIContainer"
                data={containers}
                columns={columns}
                isFilteringEnabled={false}
                withDefaultPagination={false}
                isManualSoringEnabled={false}
                isUpdatedColumnShown={false}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

MaterialBatchAndLotUIContainer.defaultProps = {
  containers: [],
};

MaterialBatchAndLotUIContainer.propTypes = {
  containers: PropTypes.instanceOf(Array),
  currentType: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.string.isRequired,
};

export default MaterialBatchAndLotUIContainer;
