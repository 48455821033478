import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Modal,
} from 'react-bootstrap';

const ConfirmationModal = ({
  handleConfirm,
  handleCancel,
  message,
  title,
  confirmButtonContent,
  cancelButtonContent,
  isHawkingUser,
}) => (
  <Modal show onHide={handleCancel} backdrop="static">
    {title && (
      <Modal.Header style={{ position: 'relative' }}>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
    )}
    <Modal.Body>
      {message}
    </Modal.Body>
    <Modal.Footer>
      <Button variant={!isHawkingUser && 'danger'} className={isHawkingUser && 'hawking-primary'} onClick={handleConfirm}>
        {confirmButtonContent}
      </Button>
      {handleCancel && (
        <Button
          variant={!isHawkingUser && 'primary'}
          className={isHawkingUser && 'hawking-secondary'}
          onClick={handleCancel}
        >
          {cancelButtonContent}
        </Button>
      )}
    </Modal.Footer>
  </Modal>
);

ConfirmationModal.propTypes = {
  message: PropTypes.node.isRequired,
  title: PropTypes.node,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  confirmButtonContent: PropTypes.node, // Making it a `node` to allow <FormattedMessage ...>
  cancelButtonContent: PropTypes.node, // Making it a `node` to allow <FormattedMessage ...>
  isHawkingUser: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  title: null,
  confirmButtonContent: <FormattedMessage id="button.ok" defaultMessage="OK" />,
  cancelButtonContent: <FormattedMessage id="button.cancel" defaultMessage="Cancel" />,
  isHawkingUser: false,
  handleCancel: false,
};

export default ConfirmationModal;
