import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
} from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';

import MyActions from 'rapidfab/components/home/MyActions';
import ModelLibrariesGrid from 'rapidfab/components/manage/ModelLibrariesGrid';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { labelResourceType, modelLibraryResourceType, modelResourceType } from 'rapidfab/types';

function redirect(modelLibrary) {
  window.location.hash = getRouteURI(ROUTES.HAWKING_COMPANY_LIBRARY, {}, { uuid: modelLibrary.uuid });
}

const DanfossDDWDashboard = ({ modelLibraries, modelsByUri, labelsByUri }) => (
  <Container fluid>
    <Row className="mb-3">
      <Col>
        <Button
          variant="primary"
          href={getRouteURI(ROUTES.ORDER_NEW)}
          className="pull-right"
        >
          <Fa name="plus" />{' '}
          <FormattedMessage id="record.order.add" defaultMessage="Add Order" />
        </Button>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        {/* Panel header="..." */}
        <Card bg="dark">
          <Card.Header className="pd-exp inverse">Recently Added Products</Card.Header>
          <div className="card-body-wrapper">
            <Card.Body>
              <ModelLibrariesGrid
                modelLibraries={modelLibraries}
                labelsByUri={labelsByUri}
                handleSelect={redirect}
                modelsByUri={modelsByUri}
              />
            </Card.Body>
          </div>
        </Card>
      </Col>
      <Col md={6}>
        {/* Panel header="..." */}
        <Card bg="dark">
          <Card.Header className="pd-exp inverse">My Actions</Card.Header>
          <div className="card-body-wrapper">
            <Card.Body>
              <div className="my-actions-list">
                <MyActions />
              </div>
            </Card.Body>
          </div>
        </Card>
      </Col>
    </Row>
  </Container>
);

DanfossDDWDashboard.propTypes = {
  modelLibraries: PropTypes.arrayOf(modelLibraryResourceType).isRequired,
  labelsByUri: PropTypes.objectOf(labelResourceType).isRequired,
  modelsByUri: PropTypes.objectOf(modelResourceType).isRequired,
};

export default DanfossDDWDashboard;
