import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import { createSelector } from 'reselect';
import * as baseStateSelectors from './baseStateSelectors';

export const getCustomOrderFieldReferences = createSelector(
  [baseStateSelectors.getStateCustomFields, getStateResources],
  (uuids, resources) => {
    const orderFields = _map(uuids, uuid => resources[uuid]);
    return _filter(orderFields, { related_table_name: 'order', enabled: true });
  },
);

export const getCustomLineItemFieldReferences = createSelector(
  [baseStateSelectors.getStateCustomFields, getStateResources],
  (uuids, resources) => {
    const orderFields = _map(uuids, uuid => resources[uuid]);
    return _filter(orderFields, { related_table_name: 'line_item', enabled: true });
  },
);

export const getCustomModelLibraryFieldReferences = createSelector(
  [baseStateSelectors.getStateCustomFields, getStateResources],
  (uuids, resources) => {
    const orderFields = _map(uuids, uuid => resources[uuid]);
    return _filter(orderFields, { related_table_name: 'model_library', enabled: true });
  },
);

export const getCustomFields = createSelector(
  [baseStateSelectors.getStateCustomFields, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getCustomFieldsByUri = createSelector([getCustomFields], resources =>
  _keyBy(resources, 'uri'),
);
