import React from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Container, Row } from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';

import Table from 'rapidfab/components/Tables/Table';

const ManufacturersGrid = ({ records }) => {
  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'manufacturer',
    },
    {
      type: 'text',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'field.address',
      accessor: 'address',
      defaultMessage: 'Address',
      isSortable: true,
    },
  ];

  return (
    <Table
      tableID="manufacturers"
      data={records}
      columns={columns}
      isFilteringEnabled
      withDefaultPagination
      isManualSoringEnabled={false}
      initialSortedColumn="name"
      initialSortedDesc={false}
      isUpdatedColumnShown={false}
    />
  );
};

ManufacturersGrid.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const Manufacturers = ({ manufacturers, fetching }) => (
  <Container fluid>
    <BreadcrumbNav breadcrumbs={['manage', 'manufacturers']} />

    <Row>
      <Col xs={12}>
        <Button
          variant="primary"
          size="sm"
          href={getRouteURI(ROUTES.MANUFACTURER_NEW)}
          className="pull-right"
        >
          <Fa name="plus" />{' '}
          <FormattedMessage
            id="record.manufacturer.add"
            defaultMessage="Add Manufacturer"
          />
        </Button>
      </Col>
    </Row>

    <hr />

    <Row>
      <Col xs={12}>
        {fetching ? <Loading /> : <ManufacturersGrid records={manufacturers} />}
      </Col>
    </Row>
  </Container>
);

Manufacturers.propTypes = {
  fetching: PropTypes.bool.isRequired,
  manufacturers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Manufacturers;
