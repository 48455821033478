import { OverlayTrigger, Tooltip as BSTooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';
import React from 'react';

const Tooltip = ({ id, children, placement, trigger }) => (
  <OverlayTrigger
    placement={placement}
    overlay={(
      <BSTooltip id={id}>
        {children}
      </BSTooltip>
    )}
  >
    {trigger}
  </OverlayTrigger>
);

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  trigger: PropTypes.node,
  placement: PropTypes.string,
};

Tooltip.defaultProps = {
  placement: 'top',
  trigger: <Fa name="question-circle" className="spacer-left spacer-right" />,
};

export default Tooltip;
