import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Actions from 'rapidfab/actions';
import {
  getTemplateManufacturers,
  getTemplateMaterials,
  getTemplatePrinterTypes,
  isFeatureEnabled,
} from 'rapidfab/selectors';
import { API_RESOURCES, FEATURES, ROUTES } from 'rapidfab/constants';
import extractUuid from 'rapidfab/utils/extractUuid';
import getEndpointFromURI from 'rapidfab/utils/getEndpointFromURI';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import AddFromDB from 'rapidfab/components/inventory/AddFromDB';

const TYPES = {
  material: 'material',
  printerType: 'printer-type',
};

const TYPE_ENDPOINTS = {
  [TYPES.material]: ROUTES.MATERIAL_EDIT,
  [TYPES.printerType]: ROUTES.PRINTER_TYPE_EDIT,
};

const TYPE_SELECTORS = {
  [TYPES.material]: getTemplateMaterials,
  [TYPES.printerType]: getTemplatePrinterTypes,
};

class AddFromDBContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      sending: false,
      manufacturer: null,
      item: null,
    };

    this.onButtonClick = this.onButtonClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onManufacturerChange = this.onManufacturerChange.bind(this);
    this.onItemChange = this.onItemChange.bind(this);
    this.onAdd = this.onAdd.bind(this);
  }

  componentDidMount() {
    this.props.onInitialize(this.props.type);
  }

  onButtonClick() {
    this.setState({ show: true });
  }

  onClose() {
    this.setState({
      show: false,
      manufacturer: null,
      item: null,
    });
  }

  onManufacturerChange(manufacturer) {
    this.setState({
      manufacturer,
      item: null,
    });
  }

  onItemChange(item) {
    this.setState({ item });
  }

  onAdd() {
    const { onSubmit, type } = this.props;
    this.setState({ sending: true });
    const { item } = this.state;

    const cloneUUID = extractUuid(item);

    onSubmit(type, cloneUUID)
      .then(args => {
        const { location } = args.headers;
        if (location) {
          const { uuid } = getEndpointFromURI(location);
          window.location.hash = getRouteURI(TYPE_ENDPOINTS[type], { uuid });
        }
      })
      .catch(() => {
        this.setState({ sending: false });
      });
  }

  render() {
    const { show, manufacturer, item, sending } = this.state;
    const {
      onInitialize,
      onSubmit,
      type,
      buttonText,
      modalTitle,
      itemLabel,
      manualCreateHref,
      manualCreateText,
      manufacturers,
      items,
      externalMaterialDbFeatureEnabled,
      disabled,
      ...otherProps
    } = this.props;

    const nameSort = (a, b) => a.name.localeCompare(b.name);
    const filteredItems = items.filter(index => index.manufacturer === manufacturer);

    return (
      <AddFromDB
        show={show}
        text={buttonText}
        modalTitle={modalTitle}
        itemLabel={itemLabel}
        manualCreateHref={manualCreateHref}
        manualCreateText={manualCreateText}
        manufacturers={manufacturers.sort(nameSort)}
        items={filteredItems.sort(nameSort)}
        manufacturer={manufacturer}
        item={item}
        externalMaterialDbFeatureEnabled={externalMaterialDbFeatureEnabled}
        onButtonClick={this.onButtonClick}
        onClose={this.onClose}
        onManufacturerChange={this.onManufacturerChange}
        onItemChange={this.onItemChange}
        onAdd={this.onAdd}
        sending={sending}
        disabled={disabled}
        {
          // Passing extra props to the internal components (e.g. className="pull-right")
          ...otherProps
        }
      />
    );
  }
}

AddFromDBContainer.propTypes = {
  type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
  buttonText: PropTypes.node.isRequired,
  modalTitle: PropTypes.node.isRequired,
  itemLabel: PropTypes.node.isRequired,
  manualCreateHref: PropTypes.string.isRequired,
  manualCreateText: PropTypes.node.isRequired,
  onInitialize: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  manufacturers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  externalMaterialDbFeatureEnabled: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

AddFromDBContainer.defaultProps = {
  items: [],
  disabled: false,
};

function mapStateToProps(state, props) {
  const { type } = props;

  const manufacturers = getTemplateManufacturers(state);
  const items = TYPE_SELECTORS[type](state);

  const itemManufacturers = new Set(items.map(({ manufacturer }) => manufacturer));
  const filteredManufacturers = manufacturers.filter(({ uri }) => itemManufacturers.has(uri));

  return {
    manufacturers: filteredManufacturers,
    items,
    externalMaterialDbFeatureEnabled: isFeatureEnabled(state, FEATURES.EXTERNAL_MATERIAL_DB),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onInitialize(type) {
      const apiParams = [
        { is_template: true }, // filters
        {}, // page
        {}, // searchParams
        {}, // queryParams
        true, // forced
      ];

      dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].list(...apiParams));
      dispatch(Actions.Api.nautilus[type].list(...apiParams));
    },
    onSubmit(type, resourceUUID) {
      return dispatch(Actions.Api.nautilus[type].clone(resourceUUID, {}));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFromDBContainer);
