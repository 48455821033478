import React from 'react';
import PropTypes from 'prop-types';

import { Button, FormLabel, Form as BSForm, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import Fa from 'react-fontawesome';
import SaveButton from 'rapidfab/components/SaveButton';
import { Field, Form } from 'react-final-form';
import dayjs from 'dayjs';
import { COLORS } from '../../constants';
import { reduxFormFieldType } from '../../types';

const Layout = ({ Table }) => <Table />;
Layout.propTypes = { Table: PropTypes.func.isRequired };

const EditRunActualsModal = ({
  show,
  onSubmit,
  loading,
  onHide,
  initialValues,
}) => {
  const onCancel = reset => {
    if (reset) {
      reset();
    }
    onHide();
  };

  const isStartValid = (startDate, endDate) => dayjs(startDate).isSameOrBefore(dayjs(endDate));
  const isEndValid = (endDate, startDate) => dayjs(endDate).isSameOrAfter(dayjs(startDate));
  const isTimeValid = (startTime, endTime, startDate, endDate, template) => {
    const startDateTime = dayjs(`${startDate} ${startTime}`);
    const endDateTime = dayjs(`${endDate} ${endTime}`);
    return template === 'start' ? startDateTime.isSameOrBefore(endDateTime) : endDateTime.isSameOrAfter(startDateTime);
  };

  const renderErrorText = (id, text) => (
    <FormLabel className="d-flex align-items-center text-danger mt-1 mb-0 font-size-12">
      <Fa className="spacer-left spacer-right" name="warning" style={{ color: COLORS.ORANGE }} />
      <p className="m-0">
        <FormattedMessage
          id={id}
          defaultMessage={text}
        />
      </p>
    </FormLabel>
  );

  return (
    <Modal
      show={show}
      onHide={onCancel}
      backdrop="static"
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form }) => (
          <BSForm onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <FormattedMessage
                id="EditRunActuals"
                defaultMessage="Edit Run Actuals"
              />
            </Modal.Header>
            <Modal.Body>
              <FormGroup
                controlId="uxStartDate"
                className="form-group"
              >
                <FormLabel>Start In Progress Date</FormLabel>
                <Field
                  name="startDate"
                  validate={startDate => !isStartValid(startDate, form.getState().values?.endDate)}
                  initialValue={initialValues?.startDate}
                  render={props => (
                    <>
                      <FormControl
                        required
                        type="date"
                        {...props.input}
                      />
                      {(props.meta.error && props.meta.touched)
                        && renderErrorText('record.run.ActualsStartDateValidation', 'Start Date must be before End Date.')}
                    </>

                  )}
                />
              </FormGroup>

              <FormGroup
                controlId="uxStartTime"
                className="form-group"
              >
                <FormLabel>Start In Progress Time</FormLabel>
                <Field
                  name="startTime"
                  validate={startTime => !isTimeValid(
                    startTime,
                    form.getState().values?.endTime,
                    form.getState().values?.startDate,
                    form.getState().values?.endDate,
                    'start')}
                  initialValue={initialValues?.startTime}
                  render={props => (
                    <>
                      <FormControl
                        required
                        type="time"
                        {...props.input}
                      />
                      {(props.meta.error && props.meta.touched)
                        && renderErrorText('record.run.ActualsStartTimeValidation', 'Start Time must be before End Time.')}
                    </>
                  )}
                />
              </FormGroup>

              <FormGroup
                controlId="uxStartDate"
                className="form-group"
              >
                <FormLabel>End In Progress Date</FormLabel>
                <Field
                  name="endDate"
                  validate={endDate => !isEndValid(endDate, form.getState().values?.startDate)}
                  initialValue={initialValues?.endDate}
                  render={props => (
                    <>
                      <FormControl
                        required
                        type="date"
                        {...props.input}
                      />
                      {(props.meta.error && props.meta.touched)
                        && renderErrorText('record.run.ActualsEndDateValidation', 'End Date must be after Start Date.')}
                    </>
                  )}
                />
              </FormGroup>

              <FormGroup
                className="form-group"
                controlId="uxStartTime"
              >
                <FormLabel>End In Progress Time</FormLabel>
                <Field
                  name="endTime"
                  initialValue={initialValues?.endTime}
                  validate={endTime => !isTimeValid(
                    form.getState().values?.startTime,
                    endTime,
                    form.getState().values?.startDate,
                    form.getState().values?.endDate,
                    'end')}
                  render={props => (
                    <>
                      <FormControl
                        required
                        type="time"
                        {...props.input}
                      />
                      {(props.meta.error && props.meta.touched)
                        && renderErrorText('record.run.ActualsEndTimeValidation', 'End Time must be before Start Time.')}
                    </>
                  )}
                />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => onCancel(form.reset())}>
                <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
              </Button>
              <SaveButton
                showSaveIcon
                isSaving={loading}
                label={<FormattedMessage id="button.submit" defaultMessage="Submit" />}
                variant="success"
              />
            </Modal.Footer>
          </BSForm>
        )}
      />
    </Modal>
  );
};

EditRunActualsModal.defaultProps = {
  loading: false,
  show: false,
  onHide: () => true,
};

EditRunActualsModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    startDate: reduxFormFieldType,
    startTime: reduxFormFieldType,
    endDate: reduxFormFieldType,
    endTime: reduxFormFieldType,
  }).isRequired,
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.bool,
    touched: PropTypes.bool,
  }).isRequired,
};

export default EditRunActualsModal;
