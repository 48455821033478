import { WORK_INSTRUCTION_REPORT_TYPES } from 'rapidfab/constants';
import _flatMap from 'lodash/flatMap';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import isURI from './isURI';

export function validateWorkInstruction(payload) {
  if (payload.description.trim() === '') {
    throw new Error('Work instruction is empty. Please update or delete the work instruction.');
  }

  if (payload.additional_instruction_url && !isURI(payload.additional_instruction_url)) {
    throw new Error(`Additional Instruction URI (${payload.additional_instruction_url}) is not a valid link.
      Please use a valid URL or leave the link field empty.`);
  }

  if (payload.report_type === WORK_INSTRUCTION_REPORT_TYPES.SINGLE_SELECT_DROPDOWN && _isEmpty(payload.choices)) {
    throw new Error('Please add choices to the dropdown work instruction.');
  }

  return true;
}

export default function validateWorkChecklistPayload(payload) {
  /*
    Validates descriptions from all work instructions
    Raises Error if at least one value is empty
   */
  const workInstructions = _flatMap(payload, 'work_checklist.work_instructions');

  _forEach(workInstructions, workInstruction => {
    validateWorkInstruction(workInstruction);
  });

  return true;
}
