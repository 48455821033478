import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import {
  Button, Col,
  FormControl,
  FormGroup, FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  OverlayTrigger,
  Row,
  Tooltip,
  Form as BSForm,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FormControlSelect, FormControlTextArea } from 'rapidfab/components/formTools';
import Fa from 'react-fontawesome';
import _filter from 'lodash/filter';
import _map from 'lodash/map';

import { Form, FormSpy, Field } from 'react-final-form';
import { finalFormInputTypes } from 'rapidfab/types';

const PrepTaskModal = ({
  onClose,
  isSubmitting,
  prepTask,
  prepTasks,
  customGroups,
  onFormSubmit,
  initialFormValues,
}) => {
  const [formState, setFormState] = useState({ values: initialFormValues });

  const prepTaskUUID = prepTask && prepTask.uuid;
  const prepTasksExceptCurrent = _filter(prepTasks, item => item.uuid !== prepTaskUUID);
  const prepTaskNamesExceptCurrent = _map(prepTasksExceptCurrent, 'name');
  const prepTaskShortNamesExceptCurrent = _map(prepTasksExceptCurrent, 'shortname');
  const isPrepTaskNameNonUnique = prepTaskNamesExceptCurrent.includes(formState.values.name);
  const isPrepTaskShortNameNonUnique =
    prepTaskShortNamesExceptCurrent.includes(formState.values.shortname);
  return (
    <Modal show onHide={onClose} backdrop="static">
      <ModalHeader closeButton>
        <ModalTitle>
          {prepTask ? 'Edit' : 'Create'} Prep Task
        </ModalTitle>
      </ModalHeader>
      <Form
        onSubmit={onFormSubmit}
        initialValues={initialFormValues}
        render={({ handleSubmit }) => (
          <form id="prep-task" onSubmit={handleSubmit}>
            <ModalBody>
              <FormGroup controlId="prepTaskName">
                <FormLabel>
                  <FormattedMessage
                    id="field.name"
                    defaultMessage="Name"
                  />: *
                </FormLabel>
                <Field
                  name="name"
                  render={props => (
                    <FormControl
                      required
                      {...props.input}
                    />
                  )}
                />
                {isPrepTaskNameNonUnique && (
                  <FormLabel className="formLabelWarning">
                    {formState.values.name}  name is already in use
                  </FormLabel>
                )}
              </FormGroup>
              <FormGroup controlId="prepTaskShortName">
                <FormLabel>
                  <FormattedMessage
                    id="shortName"
                    defaultMessage="Short Name"
                  />: *
                  <OverlayTrigger
                    placement="bottom"
                    overlay={(
                      <Tooltip>
                        This is a shorter version of the name used for task searching,
                        and UI elements with limited space
                      </Tooltip>
                    )}
                  >
                    <Fa className="spacer-left spacer-right" name="question-circle" />
                  </OverlayTrigger>
                </FormLabel>
                <Field
                  name="shortname"
                  render={props => (
                    <FormControl
                      maxLength="24"
                      required
                      {...props.input}
                      // Setting max-width to 28ch since input has internal padding.
                      // So max-width 24ch + approximately 2ch on each side for padding
                      style={{ maxWidth: '28ch' }}
                    />
                  )}
                />
                {isPrepTaskShortNameNonUnique && (
                  <FormLabel className="formLabelWarning">
                    {formState.values.shortname} shortname is already in use
                  </FormLabel>
                )}
              </FormGroup>
              <FormGroup>
                <FormLabel>
                  <FormattedMessage
                    id="userGroup"
                    defaultMessage="User Group"
                  />: *
                </FormLabel>
                <Field
                  name="edit_group"
                  render={props => (
                    <FormControlSelect
                      id="userGroup"
                      {...props.input}
                    >
                      <FormattedMessage id="all" defaultMessage="All">{text =>
                        <option value="">{text}</option>}
                      </FormattedMessage>
                      {customGroups.map(customGroup => (
                        <option
                          value={customGroup.uri}
                          key={customGroup.uuid}
                        >
                          {customGroup.name}
                        </option>
                      ))}
                    </FormControlSelect>
                  )}
                />
              </FormGroup>
              <Row>
                <Col xs={6}>
                  <FormGroup controlId="prepTaskEstimatedTime">
                    <FormLabel>
                      <FormattedMessage
                        id="estimatedTime.hours"
                        defaultMessage="Estimated Time (hours)"
                      />: *
                    </FormLabel>
                    <Field
                      name="user_estimated_work_time"
                      type="number"
                      render={props => (
                        <FormControl
                          {...props.input}
                          min={0}
                          required
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup controlId="prepTaskDwellTime">
                    <FormLabel>
                      <FormattedMessage
                        id="dwellTime.days"
                        defaultMessage="Dwell Time (days)"
                      />: *
                    </FormLabel>
                    <Field
                      name="user_estimated_dwell_time"
                      type="number"
                      render={props => (
                        <FormControl
                          {...props.input}
                          min={0}
                          required
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <FormLabel>
                  <FormattedMessage
                    id="field.description"
                    defaultMessage="Description"
                  />:
                </FormLabel>
                <Field
                  name="description"
                  initialValue={initialFormValues?.description}
                  render={props => (
                    <FormControlTextArea
                      {...props.input}
                    />
                  )}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              {prepTask && prepTask.used_in_workflow_count > 1 && (
                <div className="pull-left">
                  <Field
                    type="checkbox"
                    name="copy"
                    initialValue={initialFormValues?.copy}
                    render={props => (
                      <BSForm.Check
                        {...props.input}
                        label={`Change only this task, not all ${prepTask.used_in_workflow_count} copies.`}
                      />
                    )}
                  />
                </div>
              )}
              <Button
                form="prep-task"
                type="submit"
                variant="success"
                disabled={isSubmitting}
              >
                { isSubmitting ? <Loading /> : 'Save' }
              </Button>
              <Button variant="primary" disabled={isSubmitting} onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
            <FormSpy
              subscription={{ values: true }}
              onChange={values => setFormState(values)}
            />
          </form>
        )}
      />
    </Modal>
  );
};

PrepTaskModal.defaultProps = {
  prepTask: null,
  isSubmitting: false,
};

PrepTaskModal.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  prepTask: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    shortname: PropTypes.string,
    edit_group: PropTypes.string,
    user_estimated_work_time: PropTypes.number,
    user_estimated_dwell_time: PropTypes.number,
    description: PropTypes.string,
    used_in_workflow_count: PropTypes.number,
  }),
  prepTasks: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    shortname: PropTypes.string.isRequired,
  })).isRequired,
  isSubmitting: PropTypes.bool,
  initialFormValues: PropTypes.shape({
    name: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
    }).isRequired,
    shortname: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
    }).isRequired,
    edit_group: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
    }).isRequired,
    user_estimated_work_time: PropTypes.shape({
      value: PropTypes.number,
      onChange: PropTypes.func,
    }).isRequired,
    user_estimated_dwell_time: PropTypes.shape({
      value: PropTypes.number,
      onChange: PropTypes.func,
    }).isRequired,
    description: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
    }).isRequired,
    copy: PropTypes.shape({
      value: PropTypes.bool,
      onChange: PropTypes.func,
    }).isRequired,
  }).isRequired,
  input: finalFormInputTypes.isRequired,
  customGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PrepTaskModal;
