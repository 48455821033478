import React from 'react';
import PropTypes from 'prop-types';

import Table from 'rapidfab/components/Tables/Table';

const ServiceProvidersGrid = ({ providers }) => {
  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'service-provider',
    },
    {
      type: 'record',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      resource: 'service-provider',
      uri: 'uri',
      isSortable: true,
    },
    {
      type: 'link',
      uid: 'field.website',
      accessor: 'website',
      defaultMessage: 'Website',
      isSortable: true,
    },
  ];

  return (
    <Table
      tableID="serviceProviders"
      data={providers}
      columns={columns}
      isFilteringEnabled
      withDefaultPagination
      isManualSoringEnabled={false}
      initialSortedColumn="name"
      initialSortedDesc={false}
      isUpdatedColumnShown={false}
    />
  );
};

ServiceProvidersGrid.propTypes = {
  providers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ServiceProvidersGrid;
