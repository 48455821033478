import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _groupBy from 'lodash/groupBy';

export const getDesignFiles = createSelector(
  [baseStateSelectors.getStateDesignFiles, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getDesignFilesByProductUri = createSelector(
  [getDesignFiles],
  designFiles => _groupBy(designFiles, 'product'),
);
