import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { runType } from 'rapidfab/types';
import { RUN_OPERATIONS } from 'rapidfab/constants';
import { Card, Row, Col } from 'react-bootstrap';
import RunPrints from 'rapidfab/components/records/run/RunPrints';
import _find from 'lodash/find';
import Loading from 'rapidfab/components/Loading';
import Fa from 'react-fontawesome';
import SmoothCollapse from 'react-smooth-collapse';

const imageStyle = {
  display: 'block',
  maxWidth: '100%',
  borderRadius: '5px',
  overflow: 'hidden',
};

const BuildPlate = ({ buildFiles, layerThickness, ...props }) => {
  const buildFileWithContent = _find(buildFiles, buildFile => (buildFile.snapshot_content !== null));
  const snapshotContent = buildFileWithContent && buildFileWithContent.snapshot_content;
  const { run, gridData } = props;
  const buildFileStatus = run.status === 'calculating' ? <Loading /> : null;
  const [expanded, setExpanded] = useState(false);

  return (
    <Card bg="dark" className="table-responsive m-b">
      <Card.Header
        className="pd-exp inverse d-flex align-items-center position-sticky pointer z-index-1000"
        onClick={() => setExpanded(previous => !previous)}
      >
        <Fa
          name={expanded ? 'angle-up' : 'angle-down'}
          size="2x"
          role="button"
          style={{ lineHeight: '12px' }}
        />
        <span className="mx-2">
          Build Plate Details
        </span>
      </Card.Header>
      <SmoothCollapse expanded={expanded}>
        <div className="card-body-wrapper">
          <Card.Body>
            <Row>
              { buildFiles.length && (snapshotContent && gridData.length > 0) ? (
                <Col sm={12} md={4}>
                  <img src={snapshotContent} alt="snapshot" style={imageStyle} />
                  {/* only display additional build details for printing runs */}
                  {run.operation === RUN_OPERATIONS.PRINTING && (
                    <div className="mt15">
                      <div><b>Layer Thickness: </b>{
                        layerThickness
                          ? `${layerThickness.value} ${layerThickness.units}`
                          : 'Conflict found. Mis-matched layer thickness for prints in run.'
                      }
                      </div>
                      <div><b>Number of Pieces: </b>{gridData.length}</div>
                    </div>
                  )}
                </Col>
              ) : buildFileStatus}
              <Col sm={12} md={snapshotContent && gridData.length > 0 ? 8 : 12}>
                <RunPrints {...props} />
              </Col>
            </Row>
          </Card.Body>
        </div>
      </SmoothCollapse>
    </Card>
  );
};

BuildPlate.defaultProps = {
  layerThickness: null,
};

// This is proxy component,
// so looks like we have a much more propTypes here ¯\_(ツ)_/¯
BuildPlate.propTypes = {
  buildFiles: PropTypes.arrayOf(PropTypes.shape({
    snapshot_content: PropTypes.string,
    uri: PropTypes.string,
  })).isRequired,
  run: runType.isRequired,
  gridData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  layerThickness: PropTypes.shape({
    units: PropTypes.string,
    value: PropTypes.number,
  }),
  isAllPrintsSelected: PropTypes.bool.isRequired,
};

export default memo(BuildPlate);
