import React from 'react';
import DanfossDDWSidebar from 'rapidfab/components/danfossDDW/DanfossDDWSidebar';
import { Col, Container, Row } from 'react-bootstrap';
import HawkingMyLibraryContainer from 'rapidfab/containers/hawking/HawkingMyLibraryContainer';
import Feature from 'rapidfab/components/Feature';
import { routeURIContains } from 'rapidfab/utils/getRouteURI';
import { ROUTES, FEATURES } from 'rapidfab/constants';
import PropTypes from 'prop-types';
import DanfossDDWCustomLibraryContainer from 'rapidfab/containers/danfossDDW/DanfossDDWCustomLibraryContainer';
import HawkingAdministratorLibraryContainer from '../../containers/hawking/HawkingAdministratorLibraryContainer';
import HawkingCompanyLibraryContainer from '../../containers/hawking/HawkingCompanyLibraryContainer';

const DanfossDDWLayout = ({
  children,
  restricted,
  groups,
  libraries,
  ...otherProps
}) => (
  restricted ? (
    /* 👇🏼 This renders the entire DOM layout */
    <Container fluid>
      <Row>
        <Col xs={2}>
          <DanfossDDWSidebar
            isRestricted={restricted}
            libraries={libraries}
            groups={groups}
            {...otherProps}
          />
        </Col>
        <Col xs={10}>
          {children}
        </Col>
      </Row>
    </Container>
  )
    : (
  /* 👇🏼 This renders the embedded view (inside AM Flows) only */
      <Container fluid>
        <Row>
          <Feature featureName={FEATURES.STANLEY_X_DEPLOYMENT} isInverted>
            <Col xs={2}>
              <DanfossDDWSidebar
                isRestricted={restricted}
                groups={groups}
                libraries={libraries}
                {...otherProps}
              />
            </Col>
          </Feature>
          <Col xs={10}>
            {
              routeURIContains(ROUTES.DDW_COMPANY_LIBRARY) &&
                <HawkingCompanyLibraryContainer />
            }
            {
              routeURIContains(ROUTES.DDW_MY_LIBRARY) &&
                <HawkingMyLibraryContainer />
            }
            {
              routeURIContains(ROUTES.DDW_ALL_DESIGNS) &&
                <HawkingAdministratorLibraryContainer />
            }
            {/* Render custom library if static routes does not match */}
            <DanfossDDWCustomLibraryContainer />
          </Col>
        </Row>
      </Container>
    )
);

export default DanfossDDWLayout;

DanfossDDWLayout.defaultProps = {
  createLibraryModalShown: false,
  groups: [],
  handleCreateLibrary: () => {},
  libraries: [],
};

DanfossDDWLayout.propTypes = {
  children: PropTypes.node.isRequired,
  restricted: PropTypes.bool.isRequired,
  createLibraryModalShown: PropTypes.bool,
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  handleCreateLibrary: PropTypes.func,
  libraries: PropTypes.arrayOf(PropTypes.shape({})),
};
