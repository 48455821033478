import React from 'react';
import PropTypes from 'prop-types';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import Loading from 'rapidfab/components/Loading';
import _map from 'lodash/map';
import _compact from 'lodash/compact';
import _startsWith from 'lodash/startsWith';

const UserItem = ({ entity }) => (
  <div className="user-item">
    <div className="user-item__email">{entity.username}</div>
    <div className="user-item__name">{entity.name}</div>
  </div>
);

const TextAreaAutocomplete = React.forwardRef((props, ref) => {
  const autocompleteTrigger = {
    '@': {
      dataProvider: async token => {
        const fetchedUsers = await props.fetchAllUsers();

        if (fetchedUsers) {
          return _compact(_map(fetchedUsers, datum => (
            _startsWith(datum?.username?.toLowerCase(), token.toLowerCase()) ? datum : null
          )));
        }

        return [];
      },
      component: UserItem,
      output: (item, trigger) => `**${trigger}${item.username}**`,
    },
  };

  return (
    <ReactTextareaAutocomplete
      {...props}
      loadingComponent={() => <span><Loading /></span>}
      trigger={autocompleteTrigger}
      className="textarea form-control"
      containerClassName="textarea-autocomplete-container"
      listClassName="textarea-autocomplete-list"
      itemClassName="textarea-autocomplete-item"
      dropdownClassName="textarea-autocomplete-dropdown"
      ref={ref}
    />
  );
});

TextAreaAutocomplete.propTypes = {
  className: PropTypes.string,
  suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchAllUsers: PropTypes.func.isRequired,
};
TextAreaAutocomplete.defaultProps = {
  className: '',
};
UserItem.propTypes = {
  entity: PropTypes.shape({
    username: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default TextAreaAutocomplete;
