import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Container,
  ButtonToolbar,
  SplitButton,
  Dropdown,
} from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-final-form';
import FormGroupField from 'rapidfab/components/forms/FormGroupField';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import Loading from 'rapidfab/components/Loading';
import _pick from 'lodash/pick';

const Manufacturer = ({ onSubmit, onDelete, manufacturer, isSubmitting }) => {
  const fields = {
    name: 'name',
    contact_name: 'contact.name',
    contact_phone: 'contact.phone',
    support_name: 'support.name',
    support_phone: 'support.phone',
    address: 'address',
    notes: 'notes',
  };

  const formInitialValues = manufacturer && _pick(manufacturer, Object.values(fields));
  return (
    <Form
      initialValues={formInitialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Container fluid>
            <BreadcrumbNav
              breadcrumbs={['manage', 'manufacturers', manufacturer ? `${manufacturer.name} (${getShortUUID(manufacturer.uri)})` : 'New']}
            />

            <div className="clearfix">
              <ButtonToolbar className="pull-right">
                <SplitButton
                  id="uxSaveDropdown"
                  type="submit"
                  variant="success"
                  size="sm"
                  disabled={isSubmitting}
                  title={(
                    <>
                      {isSubmitting && <Loading inline className="spacer-right" />}
                      <SaveButtonTitle />
                    </>
                  )}
                  pullRight
                >
                  <Dropdown.Item
                    eventKey={1}
                    onClick={() => onDelete(manufacturer.uuid)}
                    disabled={!manufacturer}
                  >
                    <Fa name="ban" />{' '}
                    <FormattedMessage id="button.delete" defaultMessage="Delete" />
                  </Dropdown.Item>
                </SplitButton>
              </ButtonToolbar>
            </div>

            <hr />

            <Row>
              <Col xs={12}>
                <FormGroupField name={fields.name} required />
                <FormGroupField name={fields.contact_name} label="Commercial Contact" required />
                <FormGroupField name={fields.contact_phone} label="Commercial Phone" required type="tel" />
                <FormGroupField name={fields.support_name} label="Support Contact" required />
                <FormGroupField name={fields.support_phone} label="Support Phone" required type="tel" />
                <FormGroupField name={fields.address} required as="textarea" />
                <FormGroupField name={fields.notes} required as="textarea" />
              </Col>
            </Row>
          </Container>
        </form>
      )}
    </Form>
  );
};

Manufacturer.propTypes = {
  manufacturer: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

Manufacturer.defaultProps = {
  manufacturer: null,
};

export default Manufacturer;
