import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';
import { Form } from 'react-final-form';
import FormGroupField from 'rapidfab/components/forms/FormGroupField';
import _pick from 'lodash/pick';
import { pieceResourceType } from 'rapidfab/types';
import SaveButton from 'rapidfab/components/SaveButton';

const PieceEditNameModal = ({
  piece,
  onUpdate,
  onClose,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const fields = { name: 'name' };
  const formInitialValues = _pick(piece, Object.values(fields));

  const onSubmit = payload => {
    setIsSaving(true);
    onUpdate({ uuid: piece.uuid, ...payload })
      .then(onClose)
      .finally(() => setIsSaving(false));
  };

  return (
    <Modal show onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="piece.editName" defaultMessage="Edit Piece Name" />
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit} initialValues={formInitialValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <FormGroupField
                name={fields.name}
                label={<FormattedMessage id="field.name" defaultMessage="Name" />}
                required
              />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onClose}>
                <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
              </Button>
              <SaveButton isSaving={isSaving} />
            </Modal.Footer>
          </form>
        )}
      </Form>
    </Modal>
  );
};

PieceEditNameModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  piece: pieceResourceType.isRequired,
};

export default PieceEditNameModal;
