import { FormattedMessage } from 'react-intl';
import { MATERIAL_UNITS_MAP } from 'rapidfab/mappings';
import React from 'react';
import PropTypes from 'prop-types';
import { MATERIAL_UNITS } from 'rapidfab/constants';
import * as Sentry from '@sentry/react';

export const FormattedMaterialUnits = ({ units }) => {
  if (!units) {
    return null;
  }
  if (!MATERIAL_UNITS_MAP[units]) {
    Sentry.captureMessage(`Failed to find mapping for ${units} units.`);
  }
  return MATERIAL_UNITS_MAP[units]
    ? <FormattedMessage {...MATERIAL_UNITS_MAP[units]} />
    // render units value as a fallback
    : units;
};

FormattedMaterialUnits.propTypes = {
  units: PropTypes.oneOf(Object.values(MATERIAL_UNITS)),
};

FormattedMaterialUnits.defaultProps = {
  units: null,
};

export const FormattedMaterialUnitsSuffix = ({ units }) => !!units && <> (<FormattedMaterialUnits units={units} />)</>;

FormattedMaterialUnitsSuffix.propTypes = {
  units: PropTypes.oneOf(Object.values(MATERIAL_UNITS)),
};

FormattedMaterialUnitsSuffix.defaultProps = {
  units: null,
};
