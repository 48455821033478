// TODO: will refactor/improve in ch27028
import React from 'react';
import {
  WORKFLOW_TYPES,
  RUN_TRANSFORMATION_STATUSES,
  ACCESS_INFO_ACTION_TYPES,
  RUN_STATUSES,
} from 'rapidfab/constants';
import { FormattedMessage } from 'rapidfab/i18n';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Fa from 'react-fontawesome';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _some from 'lodash/some';
import DisabledByAccessInfoCheck from 'rapidfab/components/DisabledByAccessInfoCheck';
import PropTypes from 'prop-types';

const ProductionChangeColumn = ({
  rowData,
  selectedPrints,
  setSelectedPrints,
  runTransformations,
  printUrisWithSameChecklistLinking,
  isRemanufactureChange,
  isWorkflowChange,
  isNoProductionChangeSelected,
  isRunIncomplete,
  checkboxDisableValues,
  isAllowedChangingWorkflow,
  isNotAllowedChangingWorkflow,
}) => {
  const {
    uri,
    type,
    remanufactured_to: remanufacturedToUri,
    piece: pieceUri,
  } = rowData;

  const checked = _find(selectedPrints, { uri });
  const isSpecimenWorkflow = type === WORKFLOW_TYPES.SPECIMEN;

  const isRunTransformationsExist = _some(
    runTransformations, ({ prints }) => prints.includes(uri),
  );

  // Remanufacture is incomplete when at least one run transformation is not completed
  const hasIncompleteRemanufactures = _some(
    runTransformations,
    ({ status }) => status !== RUN_TRANSFORMATION_STATUSES.COMPLETE,
  );

  // A print is remanufactured when there is a link to remanufactured print,
  // or if a transformation for this print exits
  const wasRemanufactured = isRunTransformationsExist || remanufacturedToUri;

  // check to see if the print's work instructions & workflow matches the selected
  // prints
  const isPieceValidWorkflowAndWorkInstructions = printUrisWithSameChecklistLinking
    ? printUrisWithSameChecklistLinking.includes(uri)
    : true;

  const onCheckboxChange = event => {
    let newPrintsToChange = selectedPrints;
    if (event.target.checked) {
      newPrintsToChange = [...newPrintsToChange, rowData];
    } else {
      newPrintsToChange = _filter(selectedPrints, print => print.uri !== uri);
    }
    setSelectedPrints(newPrintsToChange);
  };

  // booleans that control various tooltips
  const showMatchingChecklistError = isWorkflowChange
    && !isPieceValidWorkflowAndWorkInstructions
    && !isSpecimenWorkflow;
  const showSpecimenError = isWorkflowChange
    && isSpecimenWorkflow;
  const showAlreadyRemanufacturedError = isRemanufactureChange && wasRemanufactured;
  const showRunNotCompleteRemanufactureError = isRemanufactureChange
    && !wasRemanufactured
    && isRunIncomplete;

  // Check if there is another run as the "Next Step"
  const hasNextStepRun = rowData?.nextPrintWithProcessStep && rowData.nextPrintWithProcessStep?.status;
  // Check if the current viewed run is the last complete run + there is a next step run
  const isTheLatestCompleteRun = hasNextStepRun ?
    isAllowedChangingWorkflow && rowData.nextPrintWithProcessStep.status === RUN_STATUSES.QUEUED :
    !!isAllowedChangingWorkflow;

  const isCheckboxDisabled = isNoProductionChangeSelected
    || !isTheLatestCompleteRun
    || showMatchingChecklistError
    || showSpecimenError
    || showAlreadyRemanufacturedError
    || showRunNotCompleteRemanufactureError
    || (hasIncompleteRemanufactures && isRemanufactureChange);

  checkboxDisableValues(isCheckboxDisabled);

  const renderProductionColumnCheckbox = disabled => (
    <Form.Check
      className="m-t-0 mb0"
      checked={checked}
      disabled={disabled}
      onChange={onCheckboxChange}
      type="checkbox"
    />
  );

  return (
    <div className="d-flex align-items-center justify-content-center">
      {
        // No need to run `access-info` check when checkbox is already disabled for other reasons
        isCheckboxDisabled && renderProductionColumnCheckbox(isCheckboxDisabled)
      }
      {
        // When checkbox is enabled - run `access-info` check
        !isCheckboxDisabled && (
          <DisabledByAccessInfoCheck
            resourceUri={pieceUri}
            requestCustomGroupsList
            actionType={
              isWorkflowChange
                ? ACCESS_INFO_ACTION_TYPES.CHANGE_WORKFLOW
                : ACCESS_INFO_ACTION_TYPES.REMANUFACTURE
            }
          >
            {({ disabled, disabledGroups }) => {
              if (disabled && disabledGroups.length && !isNoProductionChangeSelected) {
                checkboxDisableValues(disabled);
                renderProductionColumnCheckbox(disabled);
                return (
                  <OverlayTrigger
                    placement="left"
                    overlay={(
                      <Tooltip id="locationFilteringMessage">
                        You are blocked from remanufacturing because you must be a member
                        of all of the User Groups required by the piece&apos;s workflow.
                        You are not a member of the following User Groups: {disabledGroups.join(', ')}.
                        Contact your Bureau Manager for changes to your assigned User Groups.
                      </Tooltip>
                    )}
                  >
                    <Fa name="question-circle" className="spacer-left" />
                  </OverlayTrigger>
                );
              }

              checkboxDisableValues(disabled);
              return renderProductionColumnCheckbox(disabled);
            }}
          </DisabledByAccessInfoCheck>
        )
      }
      {showMatchingChecklistError && (
        <OverlayTrigger
          className="ml15"
          placement="left"
          overlay={(
            <Tooltip>
              <FormattedMessage
                id="record.run.productionWorkflowChangeTooltipWarning"
                defaultMessage="Only pieces with matching workflows and work instructions can be changed at the same time"
              />
            </Tooltip>
          )}
        >
          <Fa name="question-circle" />
        </OverlayTrigger>
      )}

      {(isNotAllowedChangingWorkflow ||
      (isAllowedChangingWorkflow && isWorkflowChange && !isTheLatestCompleteRun)) && (
        <OverlayTrigger
          className="ml15"
          placement="left"
          overlay={(
            <Tooltip>
              You can only initiate workflow changes when a run is in progress or from a piece&#39;s last completed run.
            </Tooltip>
          )}
        >
          <Fa className="ml8" name="question-circle" />
        </OverlayTrigger>
      )}

      {showSpecimenError && (
        <OverlayTrigger
          className="ml15"
          placement="left"
          overlay={(
            <Tooltip>
              <FormattedMessage
                id="record.run.noSpecimenWorkflowChange"
                defaultMessage="Unable to change Workflow for specimen"
              />
            </Tooltip>
          )}
        >
          <Fa name="question-circle" />
        </OverlayTrigger>
      )}
      {showAlreadyRemanufacturedError && (
        <OverlayTrigger
          className="ml15"
          placement="left"
          overlay={(
            <Tooltip>
              <FormattedMessage
                id="record.run.remanufactureComplete"
                defaultMessage="Remanufacture Complete"
              />
            </Tooltip>
          )}
        >
          <Fa className="ml8" name="question-circle" />
        </OverlayTrigger>
      )}
      {showRunNotCompleteRemanufactureError && (
        <OverlayTrigger
          className="ml15"
          placement="left"
          overlay={(
            <Tooltip>
              <FormattedMessage
                id="record.run.runMustBeCompleteToRemanufacture"
                defaultMessage="Run must be completed to remanufacture"
              />
            </Tooltip>
          )}
        >
          <Fa className="spacer-left" name="question-circle" />
        </OverlayTrigger>
      )}
    </div>
  );
};

ProductionChangeColumn.propTypes = {
  rowData: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    remanufactured_to: PropTypes.string,
    piece: PropTypes.string.isRequired,
    nextPrintWithProcessStep: PropTypes.shape({
      status: PropTypes.string,
    }),
  }).isRequired,
  selectedPrints: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedPrints: PropTypes.func.isRequired,
  runTransformations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  printUrisWithSameChecklistLinking: PropTypes.arrayOf(PropTypes.string).isRequired,
  isRemanufactureChange: PropTypes.bool.isRequired,
  isWorkflowChange: PropTypes.bool.isRequired,
  isNoProductionChangeSelected: PropTypes.bool.isRequired,
  isRunIncomplete: PropTypes.bool.isRequired,
  checkboxDisableValues: PropTypes.func.isRequired,
  isAllowedChangingWorkflow: PropTypes.bool.isRequired,
  isNotAllowedChangingWorkflow: PropTypes.bool.isRequired,
};

export default ProductionChangeColumn;
