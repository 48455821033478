import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from 'react-bootstrap';
import NavProfile from 'rapidfab/components/navbar/NavProfile';

const NavLinksHawking = ({
  currentUser,
  onChangeLocale,
  onImpersonateStop,
  onLogout,
  locale,
  session,
}) => (
  <Navbar.Collapse>
    <NavProfile
      currentUser={currentUser}
      locale={locale}
      onChangeLocale={onChangeLocale}
      onImpersonateStop={onImpersonateStop}
      onLogout={onLogout}
      session={session}
      isHawkingUser
    />
  </Navbar.Collapse>
);
NavLinksHawking.propTypes = {
  currentUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  locale: PropTypes.string.isRequired,
  onChangeLocale: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onImpersonateStop: PropTypes.func.isRequired,
  session: PropTypes.shape({}).isRequired,
};

export default NavLinksHawking;
