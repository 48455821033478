import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUUIDResource, getNonEmptyMaterialContainersForBatch, getRouteUUID } from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';

import Loading from 'rapidfab/components/Loading';
import QrContainer from 'rapidfab/components/qr/Container';
import { Col, Container, Row } from 'react-bootstrap';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { API_RESOURCES, MATERIAL_CONTAINER_STATUSES, PAGINATION_IGNORE_DEFAULT_LIMIT, ROUTES } from 'rapidfab/constants';
import { useParams } from 'react-router-dom';

const BatchContainersContainer = () => {
  const uuid = useSelector(state => getRouteUUID(state));
  const batch = useSelector(state => getUUIDResource(state, uuid));
  const isLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH].get.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list.fetching);
  const route = useParams();

  const containers = useSelector(state => getNonEmptyMaterialContainersForBatch(state, batch));

  const dispatch = useDispatch();

  const onInitialize = currentUUID => {
    if (!currentUUID) {
      return Promise.resolve();
    }

    return dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].get(currentUUID, true)).then(batchResult => {
      const { EMPTY, ...containerStatusesExceptEmpty } = MATERIAL_CONTAINER_STATUSES;

      const { uri: batchUri } = batchResult.json;
      return Promise.all([
        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list({
          current_batch: batchUri,
          status: Object.values(containerStatusesExceptEmpty),
        }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT })),
      ]);
    });
  };

  useEffect(() => {
    (async () => {
      await onInitialize(uuid);
      window.print();
    })();
  }, []);

  if (isLoading || !batch) {
    return (<Loading />);
  }

  const renderContainers = () => {
    if (route?.container && containers?.length) {
      const currentContainer = containers.find(item => item.uuid === route.container);
      if (!currentContainer) return null;
      const materialName = batch.materials.find(m => m.uri === currentContainer?.material)?.name;
      const lotUri = currentContainer.material_lot || batch.material_lots[0];

      return (
        <QrContainer
          lotUri={lotUri}
          materialName={materialName}
          container={currentContainer}
        />
      );
    }

    return containers.map(container => {
      const materialName = batch.materials.find(m => m.uri === container.material)?.name;
      const lotUri = container.material_lot || batch.material_lots[0];

      return (
        <QrContainer
          lotUri={lotUri}
          materialName={materialName}
          container={container}
        />
      );
    });
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <div className="hide-on-print">
            <BreadcrumbNav
              breadcrumbs={[
                'materialBatches',
                {
                  message: `Batch (${getShortUUID(batch.uuid)})`,
                  href: getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: batch.uuid }),
                },
                `Print Batch ${getShortUUID(batch.uuid)} ${route?.container ? `Container ${getShortUUID(route?.container)}` : 'Containers QR Codes'}`,
              ]}
            />
          </div>
          {renderContainers()}
        </Col>
      </Row>
    </Container>
  );
};

export default BatchContainersContainer;
