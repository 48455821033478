import { USER_ROLES } from 'rapidfab/constants';

/*
 * Router bitmask permissions to use.
 * When you extend it, please make sure that general role is 2^x
 * and "ALL" permission includes new role
 */
export const ROUTER_PERMISSION = {
  GUEST: 1,
  RESTRICTED: 2,
  SELF_REGISTERED_GUEST: 4,
  LOCATION_USER: 8,
  SERVICE_PROVIDER: 16,
  // Idk about "unknown" role, but let's
  // assume that this is generic bureau user, to not break things
  UNKNOWN: 32,
  MANAGER: 64,
  // check later the value for standard user, for now set as global
  GLOBAL_USER: 128,
  STANDARD_USER: 256,
  //
  // Mixed permissions:
  //
  // Sum of all permissions, permissions to anyone for router
  ALL: 256 + 128 + 64 + 32 + 16 + 8 + 4 + 2 + 1,
  LOGGED_IN_ONLY: 256 + 128 + 64 + 32 + 16 + 8 + 4 + 2,
  BUREAU_ONLY: 256 + 128 + 64 + 32 + 8 + 4 + 2,
  BUREAU_UNRESTRICTED: 256 + 128 + 64 + 32,
};

export const ROLE_TO_PERMISSION = {
  [USER_ROLES.UNKNOWN]: ROUTER_PERMISSION.UNKNOWN,
  [USER_ROLES.SELF_REGISTERED_GUEST]: ROUTER_PERMISSION.SELF_REGISTERED_GUEST,
  [USER_ROLES.RESTRICTED]: ROUTER_PERMISSION.RESTRICTED,
  [USER_ROLES.LOCATION_USER]: ROUTER_PERMISSION.LOCATION_USER,
  [USER_ROLES.GLOBAL_USER]: ROUTER_PERMISSION.GLOBAL_USER,
  [USER_ROLES.STANDARD_USER]: ROUTER_PERMISSION.STANDARD_USER,
  [USER_ROLES.MANAGER]: ROUTER_PERMISSION.MANAGER,
  [USER_ROLES.SERVICE_PROVIDER]: ROUTER_PERMISSION.SERVICE_PROVIDER,
};
