import * as Sentry from '@sentry/react';
import Constants, { EVENTSTREAM_DISCONNECT_TIMEOUT } from 'rapidfab/constants';

/* eslint-disable no-console */

class EventStream {
  constructor(url, onEvent, onDisconnected) {
    this.url = url;
    this.createConnection();
    this.onEvent = onEvent;
    this.retry = 0;
    this.retryTimeout = null;
    this.onDisconnected = onDisconnected;
    this.timeoutId = null;
    this.disconnected = false;
  }

  createConnection() {
    this.index = 0;
    this.xhr = new XMLHttpRequest();
    this.xhr.withCredentials = true;
    this.xhr.addEventListener('load', this.onLoad.bind(this));
    this.xhr.addEventListener('progress', this.onProgress.bind(this));
    this.xhr.addEventListener('error', this.onError.bind(this));
    this.xhr.addEventListener('abort', this.onAbort.bind(this));
    this.xhr.open('GET', this.url);
    this.xhr.send();
    window.onblur = () => {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.setDisconnected(true);
      }, EVENTSTREAM_DISCONNECT_TIMEOUT);
    };
    window.onfocus = () => {
      clearTimeout(this.timeoutId);
    };
  }

  onLoad() {
    this.handleDisconnect();
  }

  onProgress(event) {
    if (event.loaded <= 0 || this.disconnected) {
      return;
    }
    this.retry = 0;
    this.retryTimeout = null;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      try {
        const chunkDelimiterIndex = event.target.responseText.indexOf(
          '\n',
          this.index,
        );
        if (chunkDelimiterIndex === -1) {
          return;
        }
        const chunk = event.target.responseText.slice(
          this.index,
          chunkDelimiterIndex,
        );
        const data = JSON.parse(chunk);
        this.onEvent(data);
        this.index = this.index + chunk.length + 1;
      } catch (error) {
        Sentry.captureException(error);
        this.index = event.target.responseText.length;
      }
    }
  }

  onError(event) {
    console.error('EventStream error', event, this.xhr);
    this.handleDisconnect();
  }

  onAbort(event) {
    console.log('EventStream aborted', event, this.xhr);
  }

  handleDisconnect() {
    if (this.retryTimeout) {
      return;
    }
    this.retry += 1;
    const retryTime = Math.min(this.retry * (this.retry / 2) * 100, 3000);
    console.log(`Retrying connection to EventStream in ${retryTime}ms`);
    this.retryTimeout = setTimeout(() => {
      this.retryTimeout = null;
      this.createConnection();
    }, retryTime);
  }

  setDisconnected(value) {
    this.disconnected = value;
    this.onDisconnected(value);
  }
}

function createAction(event) {
  return { ...event, type: Constants.EVENT_STREAM_MESSAGE };
}

export function storeEventStreamDisconnected(payload) {
  return {
    type: Constants.STORE_EVENTSTREAM_DISCONNECTED,
    payload,
  };
}

export function setEventStreamDisconnected(payload) {
  return dispatch => {
    dispatch(storeEventStreamDisconnected(payload));
  };
}

/* eslint-disable import/prefer-default-export */
/* eslint-disable no-new */

export function subscribe(dispatch, host) {
  return new EventStream(
    host,
    event => dispatch(createAction(event)),
    status => dispatch(setEventStreamDisconnected(status)),
  );
}
