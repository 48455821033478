import { BATCHING_STRATEGIES, RUN_UNLOCKED_MAX_PRINTS } from 'rapidfab/constants';

export function isLockedRunType(run) {
  return run.batch_type === BATCHING_STRATEGIES.BY_NUMBER_OF_PARTS;
}

export function getRunFill(run) {
  if (isLockedRunType(run) && run.prints.length < RUN_UNLOCKED_MAX_PRINTS) {
    return run.prints.length * (100 / RUN_UNLOCKED_MAX_PRINTS); // 5 prints per run
  }

  return null;
}

export default function getRunName(run) {
  const { pieces_locked, name } = run;
  if (isLockedRunType(run)) {
    if (!pieces_locked) {
      const percentFill = getRunFill(run);
      if (percentFill) {
        return `${name} (🔓${percentFill}%)`;
      }
    } else {
      return `${name} 🔒`;
    }
  }
  return name;
}
