import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  FormLabel,
  FormControl,
  FormGroup,
  Row,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import {
  BATCHING_BY_NUMBER_OF_PARTS,
  BATCHING_BY_SIZE_TYPE_XY,
  BATCHING_BY_SIZE_TYPE_XYZ,
  BATCHING_TYPES,
} from 'rapidfab/constants';
import UnitsTooltip from 'rapidfab/components/UnitsTooltip';

import { Field, FormSpy } from 'react-final-form';
import { FormControlSelect } from 'rapidfab/components/formTools';
import { finalFormInputTypes } from '../../types';

const BatchType = ({
  fields,
  isBatchingStrategyDisabled,
}) => {
  const [form, setForm] = useState({ values: fields });
  const [wrongUnit, setWrongUnit] = useState([]);
  const message = 'Warning. The value you have specified is very small (less than 50 millimeters). This may prevent runs from properly being created. Please review this value carefully.';

  const onPageUnitsCheck = () => {
    const { build_volume, batching_strategy } = form.values;

    // eslint-disable-next-line no-mixed-operators
    if (form.values.build_volume && batching_strategy === 'by_size_xy' || batching_strategy === 'by_size_xyz') {
      const itemsToWarn = [];
      Object.entries(build_volume).map(([name, item]) => {
        if (item && Number.parseFloat(item.value) < 50) {
          const id = `uxBuildVolume${name.toUpperCase()}`;
          itemsToWarn.push(id);
        }
        return itemsToWarn;
      });

      return itemsToWarn;
    }
    return [];
  };

  useEffect(() => {
    const warnings = onPageUnitsCheck();
    if (warnings.length) {
      setWrongUnit(warnings);
    }
  }, []);

  const onBlur = (event, id) => {
    if (Number(event.target.value) < 50 && event.target.id === id) {
      setWrongUnit([...wrongUnit, event.target.id]);
    } else {
      setWrongUnit(wrongUnit.filter(index => index !== id));
    }
  };

  return (
    <Row>
      <Col xs={12} md={12}>
        <FormGroup>
          <FormLabel>
            <FormattedMessage
              id="batchingStrategy"
              defaultMessage="Batching Strategy"
            />: *
          </FormLabel>
          <Field
            name="batching_strategy"
            render={props => (
              <FormControlSelect
                disabled={isBatchingStrategyDisabled}
                required
                className="mb15"
                {...props.input}
              >
                {BATCHING_TYPES.map(batchingType => (
                  <option key={batchingType.value} value={batchingType.value}>
                    {batchingType.label}
                  </option>
                ))}
              </FormControlSelect>
            )}
          />
        </FormGroup>
        {
          ((form.values.batching_strategy === BATCHING_BY_SIZE_TYPE_XY) ||
            (form.values.batching_strategy === BATCHING_BY_SIZE_TYPE_XYZ)) && (
            <Row>
              <Col xs={12} md={12}>
                <FormGroup controlId="uxBuildVolumeX">
                  <FormLabel>
                    <FormattedMessage
                      id="field.buildVolumeX"
                      defaultMessage="Build Volume X in Millimeters"
                    />: *
                    {
                      wrongUnit.includes('uxBuildVolumeX') && <UnitsTooltip id="batching_strategy.units" message={message} />
                    }
                  </FormLabel>
                  <Field
                    name="build_volume.x"
                    type="number"
                    initialValue={fields?.build_volume?.x}
                    render={props => (
                      <FormControl
                        {...props.input}
                        required
                        onBlur={event => props.input.onBlur(event, 'uxBuildVolumeX')}
                      />
                    )}
                  />
                </FormGroup>

                <FormGroup controlId="uxBuildVolumeY">
                  <FormLabel>
                    <FormattedMessage
                      id="field.buildVolumeY"
                      defaultMessage="Build Volume Y in Millimeters"
                    />: *
                    {
                      wrongUnit.includes('uxBuildVolumeY') && <UnitsTooltip id="batching_strategy.units" message={message} />
                    }
                  </FormLabel>
                  <Field
                    name="build_volume.y"
                    type="number"
                    initialValue={fields?.build_volume?.y}
                    render={props => (
                      <FormControl
                        {...props.input}
                        required
                        onBlur={event => onBlur(event, 'uxBuildVolumeY')}
                      />
                    )}
                  />
                </FormGroup>
                {
                  (form.values.batching_strategy === BATCHING_BY_SIZE_TYPE_XYZ) && (
                    <FormGroup controlId="uxBuildVolumeZ">
                      <FormLabel>
                        <FormattedMessage
                          id="field.buildVolumeZ"
                          defaultMessage="Build Volume Z in Millimeters"
                        />: *
                        {
                          wrongUnit.includes('uxBuildVolumeZ') && <UnitsTooltip id="batching_strategy.units" message={message} />
                        }
                      </FormLabel>
                      <Field
                        type="number"
                        name="build_volume.z"
                        initialValue={fields?.build_volume?.z}
                        render={props => (
                          <FormControl
                            {...props.input}
                            required
                            onBlur={event => onBlur(event, 'uxBuildVolumeZ')}
                          />
                        )}
                      />
                    </FormGroup>
                  )
                }
                <FormGroup controlId="minPackingDistance">
                  <FormLabel>
                    <FormattedMessage
                      id="field.minPackingDistance"
                      defaultMessage="Min Packing Distance in Millimeters"
                    />: *
                  </FormLabel>
                  <Field
                    name="min_packing_distance"
                    type="number"
                    initialValue={fields?.min_packing_distance}
                    render={props => (
                      <FormControl
                        {...props.input}
                        required
                      />
                    )}
                  />
                </FormGroup>

              </Col>
            </Row>
          )
        }

        {
          form.values.batching_strategy === BATCHING_BY_NUMBER_OF_PARTS && (
            <Row>
              <Col xs={12} md={12}>

                <FormGroup controlId="uxPartsNumber">
                  <FormLabel>
                    <FormattedMessage
                      id="field.parts_number"
                      defaultMessage="Number of Parts"
                    />:
                  </FormLabel>
                  <Field
                    name="number_of_parts"
                    type="number"
                    initialValue={fields?.number_of_parts}
                    render={props => (
                      <FormControl
                        {...props.input}
                        required
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
          )
        }
      </Col>
      <FormSpy
        subscription={{ values: true }}
        onChange={values => setForm(values)}
      />
    </Row>
  );
};

BatchType.defaultProps = {
  isBatchingStrategyDisabled: false,
};

BatchType.propTypes = {
  fields: PropTypes.shape({
    batching_strategy: PropTypes.shape({
      value: PropTypes.string,
    }),
    build_volume: PropTypes.shape({
      x: PropTypes.string,
      y: PropTypes.string,
      z: PropTypes.string,
    }),
    min_packing_distance: PropTypes.shape({}),
    number_of_parts: PropTypes.shape({}),
  }).isRequired,
  input: finalFormInputTypes.isRequired,
  isBatchingStrategyDisabled: PropTypes.bool,
};

export default BatchType;
