import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import minMax from 'dayjs/plugin/minMax';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Actions from 'rapidfab/actions';
import Config from 'rapidfab/config';
import { useDispatch, useSelector } from 'react-redux';
import _forEach from 'lodash/forEach';
import {
  FEATURES,
  ROUTES,
  XEROX_LOGIN_ROUTE_PREFIX,
  XEROX_COMMON_DOMAIN, XEROX_BUSINESS_DOMAIN,
  XEROX_HAWKING_BRANDING, HAWKING_CLASS_NAME, AUTHENTISE_PDM_CLASS_NAME, API_RESOURCES, XEROX_ENV, AUTHENTISE_ENV,
  STANLEY_X_ENV, STANLEY_X_CLASS_NAME,
  STANLEY_X_BRANDING,
  DANFOSS_DDW_BRANDING,
  DANFOSS_DDW_ENV,
  DANFOSS_DDW_CLASS_NAME,
  BUREAU_BRANDING_KEYS,
} from 'rapidfab/constants';
import { isBureauBrandingUxStyle } from 'rapidfab/utils/bureauBranding';
import SessionProvider from 'rapidfab/containers/SessionProvider';
import Navbar from 'rapidfab/components/Navbar';
import HawkingLayout from 'rapidfab/components/hawking/HawkingLayout';
import { IntlProvider } from 'react-intl';
import * as Selectors from 'rapidfab/selectors';
import isIgnoredError from 'rapidfab/utils/isIgnoredError';
import CustomAlert from 'rapidfab/utils/alert';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import Alert from 'react-s-alert';
import EventStreamContainer from 'rapidfab/containers/eventStream';
import extractUuid from 'rapidfab/utils/extractUuid';
import Loading from 'rapidfab/components/Loading';
import Cookies from 'universal-cookie';
import initializeStore from 'rapidfab/reducers/initializeStore';
import DanfossDDWViewContainer from 'rapidfab/containers/danfossDDW/DanfossDDWViewContainer';
import ChangeImpersonationModalContainer from 'rapidfab/containers/modals/ChangeImpersonationModalContainer';
import RouterComponent from '../routes/index';
import 'rapidfab/styles/stanley/main.scss';
import StanleyXLayout from '../components/stanley/StanleyXLayout';

dayjs.extend(duration);
dayjs.extend(minMax);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Test function for setting the castor3d cross site cookie for xerox
 * This WILL fail on local, should work on dev-auth2 and live.
 * On prod both castor and rapidfab will be subdomains of corp.xerox.com
 */
function maybeSetHawkingCastor3DCookie(userDetails) {
  try {
    if (
      'context' in userDetails &&
      'castor3d_user_id' in userDetails.context
    ) {
      // Leave verbose for testing purposes, clean later once passed testing
      let mDomain = '.authentise.test'; // local test url
      // For testing on dev-auth2
      if (window.location.href.includes('.dev-auth2.com')) {
        mDomain = '.dev-auth2.com';
      } else if (window.location.href.includes(XEROX_COMMON_DOMAIN)) {
        mDomain = XEROX_COMMON_DOMAIN;
      }

      const cookies = new Cookies();
      cookies.set('userId', userDetails.context.castor3d_user_id, {
        path: '/',
        sameSite: 'None',
        secure: true, // Will fail on local, will run on server
        domain: mDomain,
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error); // Sentry this after testing, check specification
  }
}

const AppFunction = () => {
  const [isLogout, setIsLogout] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isHawkingLogin =
    window.location.hash.includes(XEROX_LOGIN_ROUTE_PREFIX) ||
    window.location.toString().includes(XEROX_BUSINESS_DOMAIN);
  const isStanleyXLogin = window.location.hash.includes('/stanley-select/login');
  const isLoginRoute = window.location.hash.includes(ROUTES.LOGIN) || window.location.hash.includes(ROUTES.LOGIN_XEROX);
  const [isBranding, setIsBranding] = useState(false);
  const [eventStream, setEventStream] = useState(null);
  const bureauBranding = useSelector(Selectors.getBureauBranding);
  const showChangeImpersonationModal = useSelector(state => state.changeImpersonationModal.show);

  const session = useSelector(state => ({
    bureaus: Selectors.getBureausCurrentUserRoles(state),
    currentUser: Selectors.getSession(state),
    permissions: Selectors.getPermissions(state),
    roles: Selectors.getRolesCurrentUser(state),
    role: Selectors.getCurrentUserRole(state),
    // errors: Selectors.getErrors(state),
    errors: [
      ...state.ui.nautilus[API_RESOURCES.SESSIONS].get.errors,
      ...state.ui.nautilus[API_RESOURCES.BUREAU].list.errors,
    ],
    isHawkingUser: Selectors.isFeatureEnabled(
      state,
      FEATURES.HAWKING_DEPLOYMENT,
    ),
    isAuthentisePDMUser: Selectors.isFeatureEnabled(
      state,
      FEATURES.AUTHENTISE_PDM,
    ),
    isStanleyXUser: Selectors.isFeatureEnabled(
      state,
      FEATURES.STANLEY_X_DEPLOYMENT,
    ),
    isDigitalDesignWarehouseFeatureEnabled: Selectors.isFeatureEnabled(
      state,
      FEATURES.DIGITAL_DESIGN_WAREHOUSE,
    ),
    isDanfossUser: Selectors.isFeatureEnabled(
      state,
      FEATURES.ORDER_BUSINESS_SEGMENT,
    ),
    isRestrictedUser: Selectors.isCurrentUserRestricted(state),
    features: Selectors.getFeatures(state),
    fetching: isFetchingInitial(state.ui.nautilus[API_RESOURCES.SESSIONS].get) ||
                state.ui.nautilus[API_RESOURCES.BUREAU].list.fetching ||
                state.ui.nautilus[API_RESOURCES.FEATURE].list.fetching ||
                state.ui.nautilus[API_RESOURCES.ROLE].list.fetching,
  }));

  const i18n = useSelector(state => state.i18n);
  const errors = useSelector(state => Selectors.getErrors(state));
  const checkHawkingUser = async () => {
    if (session?.isHawkingUser || isHawkingLogin) return true;
    if (isLoginRoute) return null;
    const { json } = await initializeStore().dispatch(
      Actions.Api.nautilus[API_RESOURCES.FEATURE].list());
    const resources = json?.resources;
    return resources?.find(resource => resource.name === FEATURES.HAWKING_DEPLOYMENT) || false;
  };

  const clearBranding = () => {
    localStorage.removeItem(XEROX_HAWKING_BRANDING);
    localStorage.removeItem(STANLEY_X_BRANDING);
  };

  const setBranding = userType => {
    const favicon = document.querySelector('#favicon');
    if (userType === 'xerox') {
      clearBranding();
      localStorage.setItem(XEROX_HAWKING_BRANDING, 'true');
      document.title = 'ShipLine Digital Warehouse';
      favicon.href = 'faviconshipline.ico';
    } else if (userType === 'stanley-x') {
      clearBranding();
      document.title = 'Stanley Select';
      document.querySelector('#favicon').href = 'faviconstanleyx.ico';
      document.body.className = STANLEY_X_CLASS_NAME;
    } else if (userType === 'danfoss-ddw') {
      localStorage.setItem(DANFOSS_DDW_BRANDING, 'true');
      document.title = 'Danfoss DDW';
    } else {
      clearBranding();
      document.title = 'Rapidfab';
      favicon.href = 'favicon.ico';
    }
  };

  useEffect(() => {
    const getUserType = () => {
      if (isHawkingLogin) return 'xerox';
      if (isStanleyXLogin) return 'stanley-x';
      return null;
    };

    if (!isBranding && !isLoginRoute) {
      checkHawkingUser()
        .then(isHawkingUser => setBranding(isHawkingUser ? 'xerox' : null))
        .then(() => setIsBranding(true));
    }

    if (isLoginRoute) {
      setBranding(getUserType());
    }
  }, [isBranding, isLoginRoute, isHawkingLogin]);

  const setDocumentBranding = environment => {
    // TODO XEROX!!! Add route for Authentise, check regarding Authentise elem
    // const isHawkingLogin = window.location.hash.includes(XEROX_LOGIN_ROUTE_PREFIX);
    const isHawkingLayout = (session.isHawkingUser && environment === XEROX_ENV)
    || (!session.currentUser && isHawkingLogin);
    const isAuthentisePDMLayout = environment === AUTHENTISE_ENV;
    const isHawkingClassSet = document.body.className.includes(HAWKING_CLASS_NAME);

    if (isAuthentisePDMLayout) {
      document.body.className = AUTHENTISE_PDM_CLASS_NAME;
    }

    if (environment === STANLEY_X_ENV) {
      localStorage.setItem(STANLEY_X_BRANDING, 'true');
      document.querySelector('#favicon').href = 'faviconstanleyx.ico';
      document.body.className = STANLEY_X_CLASS_NAME;
    }

    if (environment === DANFOSS_DDW_ENV) {
      localStorage.setItem(DANFOSS_DDW_BRANDING, 'true');
      document.body.className = DANFOSS_DDW_CLASS_NAME;
    }

    if (!isHawkingClassSet && isHawkingLayout) {
      document.body.className = HAWKING_CLASS_NAME;
    } else if (isHawkingClassSet && !isHawkingLayout && !isAuthentisePDMLayout) {
      document.body.className = document.body.className.replace(HAWKING_CLASS_NAME, '');
    }
  };

  const onLogout = async () => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.SESSIONS].clear());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SESSIONS].delete('')).then(() => {
      setIsLogout(previous => !previous);
      localStorage.removeItem(XEROX_HAWKING_BRANDING);
      navigate(session?.isHawkingUser ? ROUTES.LOGIN_XEROX : ROUTES.LOGIN);
      // Hard reload the page to clear all the stored data and start from scratch
      window.location.reload();
    });
  };

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.SESSIONS].get('')).then(response => {
      if (response?.json) {
        Sentry.setUser(response.json);
        maybeSetHawkingCastor3DCookie(response.json);
        setEventStream(Actions.EventStream.subscribe(dispatch, Config.HOST.EVENT));
        dispatch(Actions.Api.nautilus[API_RESOURCES.BUREAU].list());
        dispatch(Actions.Api.nautilus[API_RESOURCES.BUREAU_BRANDING].list());
        dispatch(Actions.Api.nautilus[API_RESOURCES.BUREAU_SETTINGS].list());
        dispatch(Actions.Api.nautilus[API_RESOURCES.CURRENCY_CONVERSION].list());
        dispatch(Actions.Api.nautilus[API_RESOURCES.FEATURE].list());
        dispatch(Actions.Api.nautilus[API_RESOURCES.ROLE].list());
        dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_FIELD].list());
        dispatch(
          Actions.Api.nautilus[API_RESOURCES.PERMISSIONS].list({
            namespace: 'nautilus',
            right: ['administrate.group', 'impersonation'],
          }),
        );
        dispatch(Actions.Api.nautilus[API_RESOURCES.BUREAU].list());
        dispatch(Actions.Api.nautilus[API_RESOURCES.BUREAU_SETTINGS].list());
        dispatch(Actions.Api.nautilus[API_RESOURCES.CURRENCY_CONVERSION].list());
        dispatch(Actions.Api.nautilus[API_RESOURCES.ROLE].list());
      } else {
        /* There will be "Bureau Error" page, this way we will also receive more information on Sentry */
        Sentry.captureMessage(`User has not received the response from "Session" API call:
        ${JSON.stringify({ sessionData: session, sessionResponse: response })}`);
      }
    });
  };

  const handleTranslationError = errorMessage => {
    // Console.error is the default behavior (duplicating it to leave everything as is)
    // eslint-disable-next-line no-console
    console.error(errorMessage);
    // Additionally capturing Sentry error anytime we have a translations issue
    // e.g. if any translation is missing (and it is not yet covered by tests)
    Sentry.captureMessage(errorMessage);
  };

  const onAcceptTerms = user => {
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.USERS].put(extractUuid(user.uri), { tos: true }),
    ).then(() => {
      /* As we already have the session stored, on Agree button we will reload the page
         to initialize all the settings + to be redirected to home page */
      window.location.reload();
    });
  };

  const onChangeLocale = (currentLocale, newLocale) => {
    if (currentLocale !== newLocale) {
      dispatch(Actions.I18n.change(currentLocale, newLocale));
    }
  };

  const onImpersonateStop = () => {
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.SESSIONS].put(0, { impersonating: null }),
    ).then(() => window.location.reload());
  };

  useEffect(() => {
    onInitialize();
  }, [isLogout]);

  useEffect(() => {
    if (!session.fetching) {
      // No need to change document branding until session and features requests are finished
      // if (isHawkingUser || window.location.hash.includes(XEROX_LOGIN_ROUTE_PREFIX)) {
      if (isBureauBrandingUxStyle(bureauBranding, BUREAU_BRANDING_KEYS.FLOWS_LIGHT)
        || window.location.hash.includes(XEROX_LOGIN_ROUTE_PREFIX)) {
        setDocumentBranding(XEROX_ENV);
      } else if (isBureauBrandingUxStyle(bureauBranding, BUREAU_BRANDING_KEYS.DIGITAL_DESIGN_WAREHOUSE)) {
        setDocumentBranding(DANFOSS_DDW_ENV);
      } else if (isBureauBrandingUxStyle(bureauBranding, BUREAU_BRANDING_KEYS.STANLEY_X)) {
        setDocumentBranding(STANLEY_X_ENV);
      } else {
        setDocumentBranding(AUTHENTISE_ENV);
      }
    }
  }, [session]);

  useEffect(() => {
    if (errors.length) {
      _forEach(errors, error => {
        if (isIgnoredError(error)) {
          return;
        }
        CustomAlert.error(error.title || error.code);
      });
    }
  }, [JSON.stringify(errors)]);

  const routerContent = (
    <RouterComponent
      session={session}
    />
  );

  let pageContent = isLogout ? <Loading /> : routerContent;

  // if ((session.isHawkingUser || session.isAuthentisePDMUser) && !isLogout) {
  if (isBureauBrandingUxStyle(bureauBranding, BUREAU_BRANDING_KEYS.FLOWS_LIGHT) && !isLogout) {
    pageContent = <HawkingLayout>{routerContent}</HawkingLayout>;
  }

  if (isBureauBrandingUxStyle(bureauBranding, BUREAU_BRANDING_KEYS.STANLEY_X)) {
    pageContent = <StanleyXLayout>{routerContent}</StanleyXLayout>;
  }

  if (session.isDigitalDesignWarehouseFeatureEnabled
   && session.isRestrictedUser) {
    pageContent = (
      <DanfossDDWViewContainer>
        {routerContent}
      </DanfossDDWViewContainer>
    );
  }

  return (
    <IntlProvider
      locale={i18n.locale}
      messages={i18n.messages}
      onError={handleTranslationError}
    >
      <SessionProvider {...session} onAcceptTerms={onAcceptTerms} isHawkingUser={session.isHawkingUser}>
        <div>
          <Alert
            stack={{ limit: 3, spacing: 10 }}
            timeout={4000}
            offset={40}
            effect="slide"
          />
          {showChangeImpersonationModal && <ChangeImpersonationModalContainer show={showChangeImpersonationModal} />}
          <EventStreamContainer eventStream={eventStream} />
          <Navbar
            currentUser={session.currentUser}
            locale={i18n.locale}
            onChangeLocale={onChangeLocale}
            onLogout={onLogout}
            onImpersonateStop={onImpersonateStop}
            session={session}
          />
          {
            pageContent
          }
        </div>
      </SessionProvider>
    </IntlProvider>
  );
};

export default AppFunction;
