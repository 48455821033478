import _assign from 'lodash/assign';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _mergeWith from 'lodash/mergeWith';
import _omit from 'lodash/omit';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';

import Constants, { RECORD_SOURCES } from 'rapidfab/constants';
import getEndpointFromURI from 'rapidfab/utils/getEndpointFromURI';

export function extractUuid(uri) {
  return getEndpointFromURI(uri).uuid;
}

export function hydrateRecord(record, source) {
  if (!record || !record.uri) return record;
  const uuid = extractUuid(record.uri);
  return {
    ...record,
    uuid,
    // Record id might be set on the backend. E.g. `run.id`. Otherwise generate id from uuid.
    id: record.id || uuid.slice(-6),
    _meta: {
      // Source explains whether the resource was loaded via API
      // or via `created` event from event-stream
      // It contains latest resource source
      // and can be changed when new action on this resource occurs
      // (e.g. created via event-stream and then loaded via Get on some page)
      source,
      // Access Info data is added via `access-info-for-resource` API request
      // Supported Resources list is limited. Check BE support before using it
      // To fill this object, you have to do usual call to ["access-info-for-resource"].list({target_uri: ...})
      // and in response you will get access info for each "action type" for resources
      // which will then be available in the `_meta.accessInfo`
      // See RESOURCE_ACCESS_INFO_SUCCESS reducer
      accessInfo: null,
      // Some other metadata might be added here in future
    },
  };
}

const mergeWithAndReplaceArrayProperties = (object, source) => _mergeWith(
  {},
  object,
  source,
  (objectValue, sourceValue) => { // eslint-disable-line consistent-return
    if (_isArray(objectValue)) {
      return sourceValue;
    }
  });

const keepAccessInfoFromStore = (recordFromStore, newRecord) => {
  /* eslint-disable no-underscore-dangle */
  if (
    !recordFromStore
    // Provided resource might not have an `uri` field (not a real DB resource)
    // - so no meta is available (see hydrateRecord)
    // eslint-disable-next-line no-underscore-dangle
    || !newRecord._meta
    // No need to proceed if there is no access info in store
    || !recordFromStore._meta.accessInfo
  ) {
    return newRecord;
  }

  return _assign(
    {},
    newRecord,
    {
      _meta: {
        ...newRecord._meta,
        accessInfo: {
          // Keep existing _meta.accessInfo from store
          ...recordFromStore._meta.accessInfo,
        },
      },
    },
  );
  /* eslint-enable no-underscore-dangle */
};

function reducer(state = {}, action) {
  let record = null;
  switch (action.type) {
    case Constants.EVENT_STREAM_MESSAGE:
      if (!action.payload || _isEmpty(action.payload)) {
        return state;
      }
      try {
        record = hydrateRecord(action.payload, RECORD_SOURCES.EVENT_STREAM);
      } catch {
        return state;
      }

      if (state[record.uuid] && record.uri !== state[record.uuid].uri) {
        // We have multiple endpoints for the same uuid, but with different objects
        // If object was fetched by different URIs, it's possibly can be the situation
        // when receives event for the same object, but with another serialized format
        // we skip all updated objects with this status for now
        return state;
      }

      return mergeWithAndReplaceArrayProperties(
        state,
        {
          [record.uuid]: _assign(
            {},
            state[record.uuid],
            keepAccessInfoFromStore(state[record.uuid], record),
          ),
        },
      );
    case Constants.RESOURCE_GET_SUCCESS:
      record = hydrateRecord(action.json, RECORD_SOURCES.API);
      return _assign({}, state, {
        [record.uuid || `${action.api.resource}:${action.uuid}`]: keepAccessInfoFromStore(
          state[record.uuid || `${action.api.resource}:${action.uuid}`], record,
        ),
      });
    case Constants.RESOURCE_PUT_SUCCESS:
      record = hydrateRecord(
        mergeWithAndReplaceArrayProperties(state[action.uuid], action.payload),
        RECORD_SOURCES.API,
      );
      return _assign({}, state, {
        [record.uuid]: record,
      });
    case Constants.RESOURCE_POST_SUCCESS:
      record = hydrateRecord(
        // Use action.json if there is a response from server
        action.json
        // TODO: we need to minimise usage of this case
        //  since it leads to a lot of unexpected issues
        //  when some property is missing on POST but available on GET
        //  e.g. `created` field, etc.
        // Otherwise use payload that was sent to server
        || _assign(action.payload, { uri: action.headers.location }),
        RECORD_SOURCES.API,
      );

      // This object can be already in the store,
      // so we don't need to remove old values
      return _assign({}, state, {
        [record.uuid]: _assign({}, state[record.uuid], record),
      });
    case Constants.RESOURCE_LIST_SUCCESS: {
      const records = _map(action.json.resources, resource => {
        const result = hydrateRecord(resource, RECORD_SOURCES.API);
        const recordFromStore = state[result.uuid];
        return keepAccessInfoFromStore(recordFromStore, result);
      });
      return _assign({}, state, _keyBy(records, 'uuid'));
    }
    case Constants.RESOURCE_ACCESS_INFO_SUCCESS: {
      const records = _map(action.json.resources, resource => {
        const targetRecordUuid = extractUuid(resource.target_uri);
        if (!state[targetRecordUuid]) {
          // if there is no record in the store - nowhere to add access info
          return state;
        }
        const targetRecord = _assign({ _meta: {} }, state[targetRecordUuid]);
        /* eslint-disable no-underscore-dangle */
        targetRecord._meta.accessInfo = _assign(
          {},
          targetRecord._meta.accessInfo,
          resource,
        );
        /* eslint-enable no-underscore-dangle */
        return targetRecord;
      });
      return _assign({}, state, _keyBy(records, 'uuid'));
    }
    case Constants.RESOURCE_DELETE_SUCCESS:
    case Constants.RESOURCE_MANUAL_REMOVE:
      return _assign({}, _omit(state, action.uuid));
    default:
      return state;
  }
}

export default reducer;
