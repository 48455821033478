import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getAssemblyPartMetaForLineItem,
  getAvailableBaseMaterialsForOrder,
  getFeatures,
  getMaterialsByUri,
  getUUIDResource,
  isCurrentUserRestricted,
} from 'rapidfab/selectors';
import extractUuid from 'rapidfab/utils/extractUuid';
import { assemblyPartMetaResourceType, lineItemResourceType, materialTypeResourceType, modelResourceType } from 'rapidfab/types';
import CoPrintPartRow from 'rapidfab/components/records/order/edit/LineItem/CoPrintPartRow';
import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import * as Selectors from 'rapidfab/selectors';

const CoPrintPartRowContainer = ({
  lineItem,
  assemblyPartMeta,
  model,
  onSubmit,
  baseMaterials,
  supportMaterials,
  materialsByUri,
  features,
  isReadOnly,
  isUserRestricted,
}) => {
  if (!lineItem) {
    return null;
  }
  return (
    <CoPrintPartRow
      lineItem={lineItem}
      assemblyPartMeta={assemblyPartMeta}
      model={model}
      onSubmit={onSubmit}
      baseMaterials={baseMaterials}
      supportMaterials={supportMaterials}
      materialsByUri={materialsByUri}
      features={features}
      isReadOnly={isReadOnly}
      isUserRestricted={isUserRestricted}
    />
  );
};

CoPrintPartRowContainer.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  lineItemUri: PropTypes.string.isRequired, // Used in mapStateToProps
  lineItem: lineItemResourceType.isRequired,
  assemblyPartMeta: assemblyPartMetaResourceType,
  model: modelResourceType,
  onSubmit: PropTypes.func.isRequired,
  baseMaterials: PropTypes.arrayOf(materialTypeResourceType).isRequired,
  supportMaterials: PropTypes.arrayOf(materialTypeResourceType).isRequired,
  materialsByUri: PropTypes.objectOf(materialTypeResourceType).isRequired,
  features: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
};

CoPrintPartRowContainer.defaultProps = {
  model: null,
  assemblyPartMeta: null,
};

const mapStateToProps = (state, props) => {
  const { lineItemUri } = props;
  const lineItem = getUUIDResource(state, extractUuid(lineItemUri));
  const assemblyPartMeta = getAssemblyPartMetaForLineItem(state, lineItem);
  const model = lineItem && lineItem.model && getUUIDResource(state, extractUuid(lineItem.model));

  const order = lineItem && getUUIDResource(state, extractUuid(lineItem.order));
  const { support: supportMaterials } = Selectors.getBaseAndSupportMaterials(state);

  const availableBaseMaterials = getAvailableBaseMaterialsForOrder(state, order);

  const materialsByUri = getMaterialsByUri(state);
  const features = getFeatures(state);
  const isUserRestricted = isCurrentUserRestricted(state);

  return {
    lineItem,
    assemblyPartMeta,
    model,
    baseMaterials: availableBaseMaterials,
    supportMaterials,
    materialsByUri,
    features,
    isUserRestricted,
  };
};

const mapDispatchToProps = (dispatch, { lineItemUri }) => ({
  onSubmit: payload =>
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].put(extractUuid(lineItemUri), payload),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoPrintPartRowContainer);
