import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Form,
  Table,
  Button,
  OverlayTrigger,
  Tooltip, Row, Col,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Loading from 'rapidfab/components/Loading';
import Fa from 'react-fontawesome';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import CancelOrDeleteModal from 'rapidfab/components/CancelOrDeleteModal';
import Feature from 'rapidfab/components/Feature';
import { FEATURES, QUOTE_PREVIEW_MODAL_FIELDS } from 'rapidfab/constants';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import dayjs from 'dayjs';

const QuoteProcessStepModal = ({ close,
  pieces,
  show,
  savingLineItemQuote,
  processStep,
  fetching,
  processSteps,
  handleSwitch,
  previewData,
  defaultData,
  setPreviewData,
  setDefaultData,
  processStepChargeData,
  editMode,
  onChangeUnit,
  currentValue,
  onClose,
  onCloseConfirm,
  error,
  toggleEditMode,
  confirmClose,
  editModeOff,
  handleSubmit,
  setConfirmClose,
  setSchedulingEstimatesEnabled,
  schedulingEstimatesEnabled,
  scheduledData,
  usersByUri,
  workEstimateFetching,
  fetchingUsers,
  lineItemQuoteFetching,
  calculateTotalPricePerPiece }) => {
  const [additionalValue, setAdditionalValue] = useState(1);
  const [confirmRemoval, setConfirmRemoval] = useState('');
  const maxHoursToWork = Math.max(...processStepChargeData.map(current => Number(current.count)));
  const totalHoursForPieces = maxHoursToWork * pieces;
  const hasAdditionalItems = processStepChargeData.length > 2;

  const onSubmit = async event => {
    event.preventDefault();
    close();
  };

  const addAdditionalItem = () => {
    setAdditionalValue(previous => previous + 1);
    const itemToAdd = {
      chargeName: `Additional Charge ${additionalValue}`,
      unit: 'Unit',
      count: 2,
      pricePerUnit: 10,
      price: 20,
      id: Math.random() * 2,
      removable: true,
    };

    let result;

    if (previewData.length) {
      result = [...previewData, itemToAdd];
    } else {
      result = [...defaultData, itemToAdd];
    }

    setPreviewData(result);
  };

  const removeAdditionalItem = id => {
    if (editMode) {
      const data = previewData.length ? previewData : defaultData;
      const resultItems = data.filter(item => item.id !== id);
      setPreviewData(resultItems);
    } else {
      const resultItems = defaultData.filter(item => item.id !== id);
      setDefaultData(resultItems);
    }
  };

  const handleConfirmRemoval = id => {
    if (editMode && confirmRemoval !== 'all') {
      return removeAdditionalItem(id);
    }
    if (confirmRemoval) {
      if (confirmRemoval === 'all') {
        if (previewData.length) {
          return setPreviewData(previewData.filter(item => !item.removable));
        }
        return setDefaultData(defaultData.filter(item => !item.removable));
      }
      return removeAdditionalItem(id);
    }
    return setConfirmRemoval(id);
  };

  // eslint-disable-next-line unicorn/no-array-reduce,no-param-reassign,no-return-assign
  const getTotalPricePerPiece = () => calculateTotalPricePerPiece({
    laborField: _find(processStepChargeData, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.LABOR }),
    workstationTimeField: _find(processStepChargeData, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.WORKSTATION_TIME }),
    additionalFields: _filter(processStepChargeData, { removable: true }),
    pieceOverheadField: _find(processStepChargeData, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.PIECE_OVERHEAD_COST }),
    runOverheadField: _find(processStepChargeData, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.RUN_OVERHEAD_COST }),
  });
  // PREVIOUS LOGIC |-> dataToInteractWith.reduce((total, item) => total += item.price, 0);

  const getTotalPriceLineItem = getTotalPricePerPiece(processStepChargeData) * pieces;

  const onUpdateUnits = (id, type, value) => {
    const shallowData = JSON.parse(JSON.stringify(previewData.length ? previewData : defaultData));
    const index = shallowData.findIndex(item => item.id === id);
    const currentItem = shallowData[index];

    currentItem[type] = type !== 'chargeName' ? Number(value) : value;
    currentItem.price = currentItem.count * currentItem.pricePerUnit;
    setPreviewData(shallowData);
  };

  const handleToggleSchedulingEstimates = () => setSchedulingEstimatesEnabled(previous => !previous);

  const removeAllAdditionalCharges = () => setConfirmRemoval('all');

  const convertPrice = value => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);

  const editableCharges = new Set([
    QUOTE_PREVIEW_MODAL_FIELDS.PIECE_OVERHEAD_COST,
    QUOTE_PREVIEW_MODAL_FIELDS.RUN_OVERHEAD_COST,
  ]);

  const renderTableBody = data => data.map(({ chargeName, unit, count, pricePerUnit, price, id, removable }) => (
    <tr key={chargeName}>
      <th scope="row" className="quoteStepModalShort">
        {removable && editMode ? (
          <input
            onBlur={event => onUpdateUnits(id, 'chargeName', event.target.value)}
            onChange={event => onChangeUnit(id, 'chargeName', event.target.value)}
            type="text"
            defaultValue={chargeName}
            value={currentValue[id]?.chargeName}
          />
        ) : chargeName}
      </th>
      <td className="text-nowrap">
        <p>{unit}</p>
      </td>
      <td className="text-nowrap">
        {
          editMode ? (
            <input
              className="quoteProcessStepModalInput"
              onBlur={event => onUpdateUnits(id, 'count', event.target.value)}
              onChange={event => onChangeUnit(id, 'count', event.target.value)}
              type="number"
              step=".01"
              defaultValue={count}
              value={currentValue[id]?.count}
              disabled={chargeName === QUOTE_PREVIEW_MODAL_FIELDS.PIECE_OVERHEAD_COST}
            />
          ) : <p>{count}</p>
        }
      </td>
      <td className="text-nowrap">
        {
          editMode ? (
            <>
              <span>$ </span>
              <input
                disabled={!removable &&
                  (!editableCharges.has(chargeName))}
                onBlur={event => onUpdateUnits(id, 'pricePerUnit', event.target.value)}
                onChange={event => onChangeUnit(id, 'pricePerUnit', event.target.value)}
                className="quoteProcessStepModalInput"
                type="number"
                defaultValue={pricePerUnit}
                value={currentValue[id]?.pricePerUnit}
              />
            </>
          ) : (

            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip>
                  {convertPrice(pricePerUnit)}
                </Tooltip>
              )}
            >
              <div>
                <FormattedLocalizedCost value={pricePerUnit} />
              </div>
            </OverlayTrigger>
          )
        }
      </td>
      <td className="text-nowrap quoteProcessStepModalPrice">
        <OverlayTrigger
          placement="top"
          overlay={(
            <Tooltip>
              {convertPrice(price)}
            </Tooltip>
          )}
        >
          <div>
            <FormattedLocalizedCost
              value={price}
            />
          </div>
        </OverlayTrigger>
      </td>
      {
        removable && editMode && (
          <td width="30px">
            <Fa
              role="button"
              name="trash"
              onClick={() => handleConfirmRemoval(id)}
            />
          </td>
        )
      }
    </tr>
  ));

  const renderSchedulingLastEdited = () => {
    if (workEstimateFetching || fetchingUsers) {
      return <Loading className="ml15" inline />;
    }

    return scheduledData ? (
      <span>{` ${usersByUri[scheduledData?.updated_by]?.name} (${dayjs(scheduledData.updated)
        .format('YYYY-MM-DD h:mm A')})`}
      </span>
    ) :
      ' Not Stored as scheduled time';
  };

  // Hidden until BE is not ready
  const renderQuoteLastEdited = () => {
    if (lineItemQuoteFetching) {
      return <Loading className="ml15" inline />;
    }

    return processStep?.updated_by && processStep?.updated ? (
      <span className="spacer-left">{`${usersByUri[processStep.updated_by]?.name} (${dayjs(processStep.updated)
        .format('YYYY-MM-DD h:mm A')})`}
      </span>
    ) :
      ' This workflow step quote has never been edited';
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" className="quoteProcessStepModalShow">
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <div className="d-flex justify-content-between align-items-center w-full">
            <Modal.Title className="w-full">
              <Row>
                <Col md={4} className="d-flex align-items-center">
                  <p className="mb0">
                    Workflow Steps
                  </p>
                  {
                    editMode && (
                      <OverlayTrigger
                        placement="top"
                        overlay={(
                          <Tooltip>
                            Turn off Edit Mode to switch between Workflow Steps
                          </Tooltip>
                        )}
                      >
                        <Fa className="spacer-left" name="question-circle" />
                      </OverlayTrigger>
                    )
                  }
                </Col>
                <Col md={7} className="p-l-0 ml30">
                  <div className="d-flex align-items-center">
                    <p className="mb0">
                      Quote Workflow Step:
                    </p>
                    <p className="quoteProcessStepTitle mb0">
                      {processStep?.name}
                    </p>
                  </div>
                </Col>
              </Row>
            </Modal.Title>
            <div className="quoteProcessStepModalIconsContainer">
              <Button variant="danger" type="button" className="quoteProcessStepModalButton" onClick={removeAllAdditionalCharges} disabled>
                <Fa name="trash" className="quoteProcessStepModalButtonIcon" />
              </Button>
              <Button variant="default" type="button" className="quoteProcessStepModalButton" onClick={onCloseConfirm}>
                <Fa name="close" className="quoteProcessStepModalButtonIcon" />
              </Button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={3} style={{ width: '30%' }} className="p-l-0 p-r-0">
              <div className={editMode ? 'quoteProcessStepSelectData quoteProcessStepSelectDataDisabled' : 'quoteProcessStepSelectData'}>
                {processSteps?.map(({ name, id }, index) => {
                  let stepSelectClass = processStep?.id === id ? 'quoteProcessStepSelectActive' : '';
                  if (editMode && processStep?.id === id) {
                    stepSelectClass += ' quoteProcessStepSelectDisabled';
                  }

                  return (
                    <div
                      className={stepSelectClass}
                      role="button"
                      tabIndex={0}
                      onClick={() => handleSwitch(id)}

                      key={id}
                    >
                      {
                        name?.length > 30 ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={(
                              <Tooltip>
                                {name}
                              </Tooltip>
                            )}
                          >
                            <p className="quoteProcessStepSelect">
                              {index + 1}. {name}
                            </p>
                          </OverlayTrigger>
                        ) : (
                          <p className="quoteProcessStepSelect">
                            {index + 1}. {name}
                          </p>
                        )
                      }
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col md={7} className="bl-light" style={{ width: '70%' }}>
              { fetching ? <Loading className="mt15 mb15 center" size="2x" /> :
                (
                  <div className="quoteProcessStepModalNoBorder list-group-quote">
                    <Table style={{ marginBottom: 0 }} className="list-group-quote">
                      <thead>
                        <tr>
                          <th scope="col">
                            <FormattedMessage
                              id="line_item.quote_price_factor"
                              defaultMessage="Price Factor"
                            />
                          </th>
                          <th scope="col" width="50px" style={{ textAlign: 'center' }}>
                            <FormattedMessage
                              id="line_item.quote_price_factor.unit"
                              defaultMessage="Unit"
                            />
                          </th>
                          <th scope="col" width="30px" style={{ textAlign: 'center' }}>
                            <FormattedMessage
                              id="line_item.quote_price_factor.count"
                              defaultMessage="#"
                            />
                          </th>
                          <th scope="col" width="85px" style={{ textAlign: 'center' }}>
                            <FormattedMessage
                              id="line_item.quote_price_factor.pricePerUnit"
                              defaultMessage="$/#"
                            />
                          </th>
                          <th scope="col" width="50px" style={{ textAlign: 'center' }}>
                            <FormattedMessage
                              id="line_item.quote_price_factor.price"
                              defaultMessage="Price"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody className="quoteProcessStepModal">
                        {renderTableBody(previewData.length ? previewData : defaultData)}
                      </tbody>
                    </Table>
                    {
                      editMode ? (
                        <div className="d-flex justify-content-end align-items-center" style={{ margin: '10px 0' }}>
                          <Button
                            variant="info"
                            style={{ padding: '2px 20px', marginRight: '3px', backgroundColor: '#2B78E4' }}
                            onClick={() => addAdditionalItem()}
                          >
                            <Fa name="plus" style={{ marginRight: '5px' }} />
                            <FormattedMessage
                              id="button.addAdditionalCharge"
                              defaultMessage="Add Additional Charge"
                            />
                          </Button>
                        </div>

                      ) : null
                    }
                    <span className="quoteProcessStepHr" style={{ borderBottom: `${editMode ? '3px' : '2px'} solid black`, margin: '0' }} />

                    <div className="list-group-quote p-a-0 mb15">
                      <div className="quoteProcessStepModalTotalList">
                        <p className="quoteProcessStepModalTotal">
                          Total Price Per Piece
                        </p>
                        <div
                          className="quoteProcessStepModalTotalUnit"
                          style={editMode && hasAdditionalItems ? { marginRight: '41px' } : null}
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={(
                              <Tooltip>
                                {convertPrice(getTotalPricePerPiece(processStepChargeData))}
                              </Tooltip>
                            )}
                          >
                            <p className="quoteProcessStepModalInputTextPrice"><strong><FormattedLocalizedCost value={getTotalPricePerPiece(processStepChargeData)} /></strong>
                            </p>
                          </OverlayTrigger>
                        </div>
                      </div>

                      <div className="quoteProcessStepModalTotalList">
                        <p className="quoteProcessStepModalTotal">
                          Total Price Per Line Item
                        </p>
                        <div
                          className="quoteProcessStepModalTotalUnit"
                          style={editMode && hasAdditionalItems ? { marginRight: '41px' } : null}
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={(
                              <Tooltip>
                                {convertPrice(getTotalPriceLineItem)}
                              </Tooltip>
                            )}
                          >
                            <p className="quoteProcessStepModalInputTextPrice"><strong><FormattedLocalizedCost value={getTotalPriceLineItem} /></strong></p>
                          </OverlayTrigger>
                        </div>
                      </div>

                      <span className="quoteProcessStepHr" />

                      <div className="d-flex align-items-center">
                        <p
                          className="quoteProcessStepPerPieceDuration"
                          style={{ width: editMode ? (hasAdditionalItems ? '200px' : '240px') : '265px' }}
                        >
                          Per Piece Duration
                        </p>
                        <div className="d-flex align-items-center">
                          <p className="quoteProcessStepModalTotalUnit">
                            Hour
                          </p>
                          <p className="quoteProcessStepModalTotalUnit">
                            {maxHoursToWork}
                          </p>
                        </div>

                      </div>

                      <div className="d-flex align-items-center">
                        <p
                          className="quoteProcessStepPerPieceDuration"
                          style={{ width: editMode ? (hasAdditionalItems ? '200px' : '240px') : '265px' }}
                        >
                          Per Line Item Duration <span style={editMode && hasAdditionalItems ? { whiteSpace: 'nowrap' } : null}>({pieces} pieces)</span>
                        </p>
                        <div className="d-flex align-items-center">
                          <p className="quoteProcessStepModalTotalUnit">
                            Hour
                          </p>

                          <p className="quoteProcessStepModalTotalUnit">
                            {totalHoursForPieces}
                          </p>
                        </div>

                      </div>
                    </div>

                    <Feature featureName={FEATURES.WORKSTEP_ESTIMATE}>
                      {
                        editMode && (
                          <div className="checkbox mb15">
                            <Form.Check
                              inline
                              className="quoteProcessStepScheduling"
                              name="timeEstimatesWhenScheduled"
                              disabled={!editMode}
                              checked={schedulingEstimatesEnabled}
                              onChange={handleToggleSchedulingEstimates}
                              type="checkbox"
                              label={(
                                <strong>
                                  <FormattedMessage
                                    id="process_step.schedulingTimeEstimates"
                                    defaultMessage="Use time estimates when scheduling"
                                  />
                                </strong>
                              )}
                            />
                          </div>
                        )
                      }
                    </Feature>

                    {/* Hidden until BE is not ready */}
                    <p className="quoteProcessStepScheduling">
                      <strong className="quoteProcessStepSchedulingTitle">Quote Last Edited:</strong>
                      {renderQuoteLastEdited()}
                    </p>

                    <Feature featureName={FEATURES.WORKSTEP_ESTIMATE}>
                      <p className="quoteProcessStepScheduling">
                        <strong className="quoteProcessStepSchedulingTitle">Schedule Last Edited:</strong>
                        {renderSchedulingLastEdited()}
                      </p>

                    </Feature>
                    {
                      error && <p style={{ color: 'red' }}>Something went wrong, please try again.</p>
                    }
                  </div>
                ) }
            </Col>
          </Row>

          {confirmRemoval && (
            <CancelOrDeleteModal
              modalType={`${confirmRemoval === 'all' ? 'deleteQuoteStepAll' : 'deleteQuoteStep'}`}
              handleConfirm={() => handleConfirmRemoval(confirmRemoval)}
              handleOpen={() => {
                setConfirmRemoval(false);
              }}
            />
          )}
          {
            confirmClose && (
              <CancelOrDeleteModal
                modalType="confirmClose"
                handleConfirm={() => onClose()}
                handleOpen={() => {
                  setConfirmClose(false);
                }}
              />
            )
          }
        </Modal.Body>
        {
          editMode ? (
            <Modal.Footer>
              <Button onClick={editModeOff}>
                <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="success" onClick={handleSubmit}>
                {savingLineItemQuote && <Loading inline />}
                <FormattedMessage id="button.save" defaultMessage="Save" />
              </Button>
            </Modal.Footer>
          ) : (
            <Modal.Footer className="quoteProcessStepModalFooter">
              <Button variant="success" onClick={toggleEditMode}>
                <FormattedMessage id="button.edit" defaultMessage="Edit" />
              </Button>
              <Button variant="danger" onClick={onCloseConfirm}>
                <FormattedMessage id="button.close" defaultMessage="Close" />
              </Button>
            </Modal.Footer>
          )
        }
      </Form>
    </Modal>
  );
};

QuoteProcessStepModal.defaultProps = {
  processStep: {},
};

QuoteProcessStepModal.propTypes = {
  close: PropTypes.func.isRequired,
  pieces: PropTypes.number.isRequired,
  processStep: PropTypes.instanceOf(Object),
  show: PropTypes.bool.isRequired,
  savingLineItemQuote: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  processSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSwitch: PropTypes.func.isRequired,
  previewData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setPreviewData: PropTypes.func.isRequired,
  setDefaultData: PropTypes.func.isRequired,
  processStepChargeData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  editMode: PropTypes.bool.isRequired,
  onChangeUnit: PropTypes.func.isRequired,
  currentValue: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onCloseConfirm: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  confirmClose: PropTypes.bool.isRequired,
  editModeOff: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setConfirmClose: PropTypes.func.isRequired,
  scheduledData: PropTypes.shape({
    updated_by: PropTypes.string,
    updated: PropTypes.string,
  }).isRequired,
  usersByUri: PropTypes.shape({}).isRequired,
  workEstimateFetching: PropTypes.bool.isRequired,
  setSchedulingEstimatesEnabled: PropTypes.func.isRequired,
  schedulingEstimatesEnabled: PropTypes.bool.isRequired,
  lineItemQuoteFetching: PropTypes.bool.isRequired,
  fetchingUsers: PropTypes.bool.isRequired,
  calculateTotalPricePerPiece: PropTypes.func.isRequired,
};

export default memo(QuoteProcessStepModal);
