export const MATERIAL_CONTAINER_NUMBER_FIELD_STEP = '0.01';
export const CONTAINERIZE_QUANTITY_FIELD_STEP = '0.001';

// TODO This variable is created to track points where do we need to fetch
//  all non-paginated objects.
//  Must be removed after implementing algorithm for disabling pagination
export const PAGINATION_IGNORE_DEFAULT_LIMIT = 5000;
export const ORDERS_VIEW_MODE_CHART_LIMIT = 10;
export const ORDERS_VIEW_MODE_LIST_LIMIT = 50;
export const UPLOAD_LINE_ITEMS_MAX_LIMIT = 25;

export const BUILD_VOLUME_XYZ_MAXIMUM_VALUE = 1000000;

export const CASTOR_COSTING_DEFAULT_YEARS_OF_DEMAND = 1;
export const CASTOR_COSTING_DEFAULT_QUANTITY = 1;
export const EVENTSTREAM_DISCONNECT_TIMEOUT = 1000 * 60 * 15; // 15 minutes
export const MAX_FILE_SIZE = 256 * 1024 * 1024; // 256 MB
