import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { Button, Col, ListGroupItem, Row } from 'react-bootstrap';
import { FormattedDate, FormattedTime } from 'rapidfab/i18n';
import EventHeading from 'rapidfab/components/records/piece/event/EventHeading';
import EventContent from 'rapidfab/components/records/piece/event/EventContent';
import EventUser from 'rapidfab/components/records/piece/event/EventUser';

class Event extends Component {
  constructor(props) {
    super(props);

    this.state = { expanded: false };

    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { toggleExpanded } = this;
    const { event, relatedResource, model } = this.props;
    const { expanded } = this.state;

    return (
      <ListGroupItem className="fill">
        <Row>
          <Col xs={1}>
            <Button size="xs" onClick={toggleExpanded}>
              <FontAwesome
                name={expanded ? 'chevron-down' : 'chevron-right'}
              />
            </Button>
          </Col>
          <Col xs={8}>
            <EventHeading
              event={event}
              relatedResource={relatedResource}
              model={model}
            />
          </Col>
          <Col xs={3} className="text-right">
            <FormattedDate value={event.created} />
          </Col>
        </Row>
        {expanded && (
          <Row>
            <Col xs={1} />
            <Col xs={8}>
              <EventContent event={event} />
            </Col>
            <Col xs={3} className="text-right">
              <FormattedTime value={event.created} />
              <br />
              <EventUser userUri={event.user} />
            </Col>
          </Row>
        )}
      </ListGroupItem>
    );
  }
}

Event.propTypes = {
  event: PropTypes.shape({
    created: PropTypes.string,
    user: PropTypes.string,
  }).isRequired,
  relatedResource: PropTypes.shape({}),
  model: PropTypes.shape({}),
};

Event.defaultProps = {
  relatedResource: null,
  model: null,
};

export default Event;
