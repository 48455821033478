import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Actions from 'rapidfab/actions';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import * as Selectors from 'rapidfab/selectors';
import ConversionsComponent from 'rapidfab/components/manage/conversions';
import { API_RESOURCES } from 'rapidfab/constants';

const ConversionsContainer = props => {
  const users = useSelector(Selectors.getUsers);
  const locations = useSelector(Selectors.getLocations);
  const conversions = useSelector(Selectors.getConversions);
  const fetching = useSelector(state => isFetchingInitial(state.ui.nautilus[API_RESOURCES.CURRENCY_CONVERSION].list));

  const dispatch = useDispatch();
  useEffect(() => {
    (async () => dispatch(Actions.Api.nautilus[API_RESOURCES.CURRENCY_CONVERSION].list()))();
  }, []);

  return (
    <ConversionsComponent
      {...props}
      users={users}
      fetching={fetching}
      locations={locations}
      conversions={conversions}
    />
  );
};

export default ConversionsContainer;
