import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import getShortUUID from 'rapidfab/utils/getShortUUID';

const AssemblyPartLayout = ({
  uri,
  modalThreeScene,
  estimatesAndActuals,
  lineItemEditForm,
  piecesPanel,
}) => (
  <Card bg="dark">
    <Card.Header>
      <FormattedMessage id="record.lineItem" defaultMessage="Line Item" /> ({getShortUUID(uri)})
    </Card.Header>
    <div className="card-body-wrapper">
      <Card.Body>
        <Row>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={12} lg={10} lgOffset={1}>
                {modalThreeScene}
                {estimatesAndActuals}
                {piecesPanel}
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6}>
            <Row>
              <Col xs={12}>
                {lineItemEditForm}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </div>
  </Card>
);

AssemblyPartLayout.propTypes = {
  uri: PropTypes.string.isRequired,
  lineItemEditForm: PropTypes.node.isRequired,
  modalThreeScene: PropTypes.node,
  estimatesAndActuals: PropTypes.node,
  piecesPanel: PropTypes.node,
};

AssemblyPartLayout.defaultProps = {
  modalThreeScene: null,
  estimatesAndActuals: null,
  piecesPanel: null,
};

export default AssemblyPartLayout;
