export default function isIgnoredError(error) {
  if (error.code === 'resource-not-found' && error?.title.includes('token for autodesk-forge')) {
    return true;
  }

  if (error.code === 'unauthorized-no-session') {
    // Skip toaster for this error.
    // This error state is handled by router (user is redirected to login page)
    return true;
  }

  if (error.resource === 'saml') {
    // Expected to have SAML errors on login
    // if integration is not enabled.
    // It's handled by login form component
    return true;
  }

  return false;
}
