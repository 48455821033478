import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, FormLabel, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Field } from 'react-final-form';
import { finalFormInputTypes } from 'rapidfab/types';
import { FormattedMessage } from 'rapidfab/i18n';
import Fa from 'react-fontawesome';

const ServiceProviderForm = ({ initialFormValues }) => {
  const disabledEmail = !!Object.getOwnPropertyDescriptor(initialFormValues, 'email');

  return (
    <Row>
      <Col xs={12}>
        <Form.Group controlId="uxName" className="form-group">
          <FormLabel>Name: *</FormLabel>
          <Field
            name="name"
            type="text"
            initialValue={initialFormValues?.name}
            render={props => (
              <FormControl
                {...props.input}
                required
              />
            )}
          />
        </Form.Group>

        <Form.Group controlId="uxAddress" className="form-group">
          <FormLabel>Address: *</FormLabel>
          <Field
            name="address"
            type="text"
            initialValue={initialFormValues?.address}
            render={props => (
              <FormControl
                {...props.input}
                required
                as="textarea"
              />
            )}
          />
        </Form.Group>

        <Form.Group controlId="uxEmail" className="form-group">
          <div className="d-flex align-items-baseline">
            <FormLabel>Email: *</FormLabel>
            {
              disabledEmail && (
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip>
                      <FormattedMessage
                        id="sp_disabledEmail"
                        defaultMessage="User must contact support@authentise.com to update this Service Provider emails, due to security controls."
                      />
                    </Tooltip>
                  )}
                >
                  <Fa className="spacer-left spacer-right" name="info-circle" />
                </OverlayTrigger>
              )
            }
          </div>
          <Field
            name="email"
            type="email"
            initialValue={initialFormValues?.email}
            render={props => (
              <FormControl
                {...props.input}
                required
                disabled={disabledEmail}
              />
            )}
          />
        </Form.Group>

        <Form.Group controlId="uxWebsite" className="form-group">
          <FormLabel>Website: *</FormLabel>
          <Field
            name="website"
            type="text"
            initialValue={initialFormValues?.website}
            render={props => (
              <FormControl
                {...props.input}
                required
              />
            )}
          />
        </Form.Group>

        <Form.Group controlId="uxPhoneNumber" className="form-group">
          <FormLabel>Phone number:</FormLabel>
          <Field
            name="phone_number"
            type="text"
            initialValue={initialFormValues?.phone_number}
            render={props => (
              <FormControl
                {...props.input}
                name="phoneNumber"
              />
            )}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

ServiceProviderForm.propTypes = {
  initialFormValues: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    phone_number: PropTypes.string,
  }).isRequired,
  input: finalFormInputTypes.isRequired,
};

export default ServiceProviderForm;
