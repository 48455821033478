import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup, Form,
  OverlayTrigger, Tooltip,
} from 'react-bootstrap';

import FontAwesome from 'react-fontawesome';
import Loading from 'rapidfab/components/Loading';

const ListItem = ({ lineItem, handleFileSelect, isSelected, error, isPending }) => (
  <div>
    <FormGroup>
      <div className="d-flex file-list-item">
        <Form.Check
          id={`file-list-item-${lineItem.uri}`}
          type="checkbox"
          className="uploaded-file-checkbox"
          label={lineItem.name}
          checked={isSelected}
          onChange={event => handleFileSelect(event, lineItem)}
        />
        {error && (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={(
              <Tooltip className="tooltip-error">
                <span>
                  {error}
                </span>
              </Tooltip>
            )}
          >
            <FontAwesome name="exclamation" className="spacer-right spacer-left exclamation-mark" />
          </OverlayTrigger>
        )}
        {
          isPending && (
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip>
                  <span>
                    Loading...
                  </span>
                </Tooltip>
              )}
            >
              <Loading size="14px" className="ml15" inline />
            </OverlayTrigger>
          )
        }
      </div>
    </FormGroup>
  </div>
);

export default ListItem;

ListItem.propTypes = {
  lineItem: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }).isRequired,
  handleFileSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  isPending: PropTypes.string.isRequired,
};
