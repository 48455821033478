import React from 'react';
import Fa from 'react-fontawesome';
import PropTypes from 'prop-types';

const styles = {
  centerIcons: {
    textAlign: 'center',
  },
};

const WorkflowSpecimen = ({ specimen, modelLibrary, specimenWorkflow, onDelete, onEdit }) => (
  <tr>
    <td>{modelLibrary?.name}</td>
    <td>{specimen?.quantity}</td>
    <td>{specimenWorkflow && specimenWorkflow.name}</td>
    <td style={styles.centerIcons}>
      <div
        role="button"
        onClick={() => onEdit()}
        tabIndex={0}
      >
        <Fa name="edit" />
      </div>
    </td>
    <td style={styles.centerIcons}>
      <div
        role="button"
        onClick={() => onDelete(specimen)}
        tabIndex={0}
      >
        <Fa name="times" />
      </div>
    </td>
  </tr>
);

WorkflowSpecimen.propTypes = {
  specimen: PropTypes.shape({
    quantity: PropTypes.number,
  }).isRequired,
  modelLibrary: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  specimenWorkflow: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default WorkflowSpecimen;
