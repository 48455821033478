import _startCase from 'lodash/startCase';
import getEntityTypeFromUri from 'rapidfab/utils/getEntityTypeFromURI';

export default function getEntityTypeTitleFromUri(uri) {
  if (!uri) {
    return null;
  }
  const entityType = getEntityTypeFromUri(uri);
  return _startCase(entityType);
}
