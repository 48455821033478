import React from 'react';
import { useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';

import ForgotPassword from 'rapidfab/components/ForgotPassword';

const ForgotPasswordContainer = props => {
  const dispatch = useDispatch();
  const onEmailSubmit = email => dispatch(Actions.Api.nautilus[API_RESOURCES.PASSWORD_RESETS]
    .post({ user: email }));

  return (
    <ForgotPassword
      {...props}
      onEmailSubmit={onEmailSubmit}
    />
  );
};

export default ForgotPasswordContainer;
