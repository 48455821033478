import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import * as Selectors from 'rapidfab/selectors';
import { FEATURES } from 'rapidfab/constants';

import { AdminUsers } from 'rapidfab/components/admin';

const AdminUsersContainer = () => {
  const isSessionManager = useSelector(Selectors.isSessionManager);
  const locations = useSelector(Selectors.getLocations);
  const isSelfRegistrationAllowedFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.SELF_REGISTRATION_ALLOWED));

  const [selection, setSelection] = useState('none');
  const [filter, setFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');

  const handleSelectionChange = value => {
    const tableElement = document.querySelector('#users-table');
    tableElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    setSelection(value);
  };

  const handleFilterChange = value => {
    setFilter(value);
    setSelection('none');
  };

  const handleRoleFilterChange = value => {
    setRoleFilter(value);
    setSelection('none');
  };

  return (
    <AdminUsers
      selection={selection}
      handleSelectionChange={handleSelectionChange}
      filter={filter}
      handleFilterChange={handleFilterChange}
      roleFilter={roleFilter}
      handleRoleFilterChange={handleRoleFilterChange}
      locations={locations}
      isSessionManager={isSessionManager}
      isSelfRegistrationAllowedFeatureEnabled={isSelfRegistrationAllowedFeatureEnabled}
    />
  );
};

export default AdminUsersContainer;
