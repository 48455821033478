/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _differenceBy from 'lodash/differenceBy';
import _filter from 'lodash/filter';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _keyBy from 'lodash/keyBy';
import _indexOf from 'lodash/indexOf';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _max from 'lodash/max';
import _pick from 'lodash/pick';
import _partialRight from 'lodash/partialRight';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _intersectionBy from 'lodash/intersectionBy';
import _unionBy from 'lodash/unionBy';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import _uniqWith from 'lodash/uniqWith';
import _cloneDeep from 'lodash/cloneDeep';
import _some from 'lodash/some';
import _slice from 'lodash/slice';
import { Button, Col, Row, FormControl, Container } from 'react-bootstrap';
import _sortBy from 'lodash/sortBy';
import Alert from 'rapidfab/utils/alert';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import {
  BUILD_PACKER_TYPES,
  LOCATION_FILTER_DEFAULT_VALUES,
  PRIORITIES,
  MODEL_LAYER_THICKNESS_SETTINGS,
  LINE_ITEM_COMPOSITION_TYPES,
  MATERIAL_MODES,
} from 'rapidfab/constants';
import { calculate2dPack, calculate3dPack } from 'rapidfab/utils/printBedFill';
import specimensByWorkflow from 'rapidfab/utils/specimensByWorkflow';
import SelectMultiple from 'rapidfab/components/forms/SelectMultiple';
import InputSubmit from 'rapidfab/components/forms/InputSubmit';
import LineItemList from 'rapidfab/components/records/run/LineItemList';
import checkPieceModelTooLargeForPrinter from 'rapidfab/utils/checkPieceModelTooLargeForPrinter';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import ActivePiecesList from './ActivePiecesList';
import PieceList from './PieceList';
import extractUuid from '../../../utils/extractUuid';
import calculateDueDate from '../../../utils/calculateDueDate';
import currentTime from '../../../utils/currentTime';
import { parseHhmmss } from '../../../utils/hhmmss';
import { getLineItemWorkflowTypeObjectKey } from '../../../utils/lineItem';

class RunNew extends Component {
  static checkBuildFillOverflow(pieces, printer) {
    /*
     * Add toaster when build fill is exceeded
     *
     * This is naive validation as build fill
     * may not be actually exceeded by real packer in nautilus side
     */
    if (!pieces || !printer) {
      return true;
    }

    const models = pieces.map(
      piece => piece.lineItem[getLineItemWorkflowTypeObjectKey(piece.lineItem)].model,
    ).filter(Boolean);
    let totalBuildFill;

    switch (printer.printer_type.build_packer_type) {
      case BUILD_PACKER_TYPES.PACK3D:
        totalBuildFill = calculate3dPack(models, printer.printer_type).low;
        break;
      case BUILD_PACKER_TYPES.PACK2D:
        totalBuildFill = calculate2dPack(models, printer.printer_type);
        break;
      default:
        totalBuildFill = 0;
        break;
    }

    if (totalBuildFill >= 1) {
      Alert.error('Unpacked build may exceed printer size');
      return false;
    }

    return true;
  }

  constructor(props) {
    super(props);

    this.BUILD_PLATE_CHOICES = {
      PIECE: 'piece',
      LINE_ITEM: 'line_item',
    };

    this.state = {
      selectedBuildPlateModel: this.BUILD_PLATE_CHOICES.LINE_ITEM,

      selectedPrinter: null,

      selectedPieces: [], // Focused pieces on print/line-item list
      activePieces: [], // Pieces on build plate
      activePiecesSelected: [], // Focused pieces on build plate

      // Specimens for active pieces
      // (specimen-library feature)
      activeSpecimens: [],

      selectedLocations: props.selectedLocations,
      selectedMaterials: [],
      selectedWorkflows: [],

      layerThicknessSearch: '',
      layerThicknessFieldValue: '',

      // FIXME Search query is observed by child components (PrintList/LineItemList)
      //  which not obvious. Consider to move it to parent component
      searchQuery: '',

      // Estimated naive start and end dates,
      // are calculated only for run/new.
      // When run is created, nautilus estimations are used
      startDate: '',
      dueDate: '',
      primaryRunDuration: 0,
      primaryRunDurationUpdated: false,

      // Run priority. Changed based on added pieces to the build plate.
      priority: PRIORITIES.NORMAL,

      // Name will not be generated automatically when user changed run name at least one time
      userHasSetName: false,
      name: '',

      isAnyModelTooLargeForPrinter: false,
    };

    this.handleSelectPrinter = this.handleSelectPrinter.bind(this);
    this.handleSelectPiece = this.handleSelectPiece.bind(this);
    this.handleSelectPiecesGroup = this.handleSelectPiecesGroup.bind(this);
    this.handleSelectActivePiecesGroup = this.handleSelectActivePiecesGroup.bind(this);
    this.handleActivatePieces = this.handleActivatePieces.bind(this);
    this.handleSelectActivePiece = this.handleSelectActivePiece.bind(this);
    this.handleDeactivatePrints = this.handleDeactivatePrints.bind(this);
    this.onChangeRunName = this.onChangeRunName.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.clearPrinter = this.clearPrinter.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
    this.onMaterialChange = this.onMaterialChange.bind(this);
    this.onWorkflowChange = this.onWorkflowChange.bind(this);
    this.onLayerThicknessChange = this.onLayerThicknessChange.bind(this);
    this.onLayerThicknessBlur = this.onLayerThicknessBlur.bind(this);
    this.filterByLayerThicknessSelection = this.filterByLayerThicknessSelection.bind(this);
    this.onSearchQueryChange = this.onSearchQueryChange.bind(this);
    this.onToggleChange = this.onToggleChange.bind(this);
    this.onLoadDataItems = this.onLoadDataItems.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.handleQuickFill = this.handleQuickFill.bind(this);
    this.onChangePrintTime = this.onChangePrintTime.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedPrinter, activePieces } = this.state;
    const { selectedLocations, isSpecimenLibraryFeatureEnabled } = this.props;

    if (prevState.selectedPrinter !== selectedPrinter) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ activePieces: [], activePiecesSelected: [], selectedPieces: [] });
    }

    if (!_isEqual(prevProps.selectedLocations, selectedLocations)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedLocations });
    }

    if (
      prevState.activePieces.length !== activePieces.length
    ) {
      RunNew.checkBuildFillOverflow(activePieces, selectedPrinter);
      this.checkIsModelTooLargeForPrinter();
      this.updateRunName(activePieces);
      this.updateRunPriority(activePieces);

      if (isSpecimenLibraryFeatureEnabled) {
        this.updateRunSpecimens();
      }

      const selectedMaterials = _uniqBy(
        _map(activePieces, ({ lineItem }) => lineItem[getLineItemWorkflowTypeObjectKey(lineItem)].materials.base), 'uri',
      );
      // filter remaining pieces with with selected pieces' materials
      if (selectedPrinter.printer_type.material_mode !== MATERIAL_MODES.MULTI_MATERIAL) {
        this.onMaterialChange(selectedMaterials);
      }
      // filter remaining pieces with selected pieces' layer thicknesses
      this.filterByLayerThicknessSelection();
    }
  }

  handleSave(overrideBuildPlateChecks) {
    // <<<<<<< HEAD
    //     const { name, selectedPrinter, activePieces } = this.state;
    //     const { assemblyMetas, assemblyPartMetas, fetchAssemblyMetaLineItemData } = this.props;
    //
    //     const pieceLineItemUuids = activePieces.map(({ lineItem }) => lineItem.uuid);
    //
    //     const runSavePayload = {
    //       do_build_volume_check: !overrideBuildPlateChecks,
    //       run_name: name,
    //       printer: selectedPrinter.uri,
    //       pieces: activePieces.map(activePiece => activePiece.uri),
    //     };
    //
    //     /* Assembly-part data for the pieces selected. */
    //     const assemblyPartMetasForActivePieces = assemblyPartMetas.filter(
    //       ({ part_line_item }) => part_line_item.includes(pieceLineItemUuids),
    //     );
    //
    //     /* Assembly data for the pieces selected. */
    //     const assemblyMetasForActivePieces = assemblyMetas.filter(
    //       ({ root_line_item }) => {
    //         const assemblyPartMetaLineItemUris =
    //           assemblyPartMetasForActivePieces.map(partMeta => partMeta.root_line_item);
    //         return assemblyPartMetaLineItemUris.includes(root_line_item);
    //       },
    //     );
    //
    //     /* Perform a check to ensure assembly parts as well as assemblies
    //     are of status `confirmed`. Please see ticket */
    //     if (
    //       !_isEmpty(assemblyPartMetasForActivePieces) &&
    //       !_isEmpty(assemblyMetasForActivePieces)) {
    //       fetchAssemblyMetaLineItemData([
    //         assemblyMetasForActivePieces.map(({ root_line_item }) => root_line_item),
    //         assemblyPartMetasForActivePieces.map(({ part_line_item }) => part_line_item),
    //       ])
    //         .then(lineItemDataResponse => {
    //           const assemblyMetaLineItemData = lineItemDataResponse.json?.resources;
    //
    //           if (assemblyMetaLineItemData.every(lineItem => lineItem.status === LINE_ITEM_STATUS.CONFIRMED) &&
    //             activePieces.length) {
    //             this.props.onSave(runSavePayload);
    //           } else {
    //             Alert.error('Assembly must be of status `confirmed` to create this run.');
    //           }
    //         });
    //     } else {
    //       this.props.onSave(runSavePayload);
    // =======
    const { name, selectedPrinter, activePieces, primaryRunDurationUpdated, primaryRunDuration } = this.state;

    if (activePieces.length) {
      this.props.onSave({
        do_build_volume_check: !overrideBuildPlateChecks,
        run_name: name,
        printer: selectedPrinter.uri,
        pieces: activePieces.map(activePiece => activePiece.uri),
        ...(primaryRunDurationUpdated ? { primary_run_duration: primaryRunDuration } : null),
      });
    }
  }

  handleQuickFill(quickFillCount) {
    const { pieces } = this.props;
    const { activePieces, selectedMaterials, layerThicknessSearch, selectedPieces } = this.state;
    const inactivePieces = _differenceBy(pieces, activePieces, 'uri');

    // we want the build plate items to be all the same material — either use
    // the selectedMaterials or just pick the first material listed in inactive
    // pieces
    let materialUrisToFilterBy = [];
    if (!_isEmpty(selectedMaterials)) {
      materialUrisToFilterBy = _map(selectedMaterials, 'uri');
    } else {
      materialUrisToFilterBy = [inactivePieces[0].material];
    }

    let layerThicknessToFilterBy;
    if (layerThicknessSearch) {
      layerThicknessToFilterBy = Number(layerThicknessSearch);
    } else if (selectedPieces.length) {
      layerThicknessToFilterBy = selectedPieces[0].layer_thickness;
    } else {
      layerThicknessToFilterBy = inactivePieces[0].layer_thickness;
    }

    // match pieces with material and layer thickness
    const filteredInactivePiecesByMaterialAndLayerThickness = _filter(
      inactivePieces,
      ({ material, layer_thickness }) =>
        materialUrisToFilterBy.includes(material) && layer_thickness === layerThicknessToFilterBy,
    );

    const piecesToSelect = quickFillCount === undefined ?
      filteredInactivePiecesByMaterialAndLayerThickness :
      _slice(filteredInactivePiecesByMaterialAndLayerThickness, 0, quickFillCount);

    if (_isEmpty(piecesToSelect)) {
      Alert.warning(
        <FormattedMessage
          id="toaster.warning.noPiecesMatchFilters"
          defaultMessage="No pieces match the selected filters"
        />);
    }

    const newActivePieces = _unionBy(
      activePieces,
      piecesToSelect,
      'uri',
    );
    let minDate = currentTime();
    let maxDate = calculateDueDate(newActivePieces);
    const primaryRunDuration = dayjs.duration(dayjs(maxDate).diff(dayjs(minDate))).asSeconds();

    _forEach(newActivePieces, v => {
      if (!maxDate || v.lineItem.created > maxDate) {
        maxDate = v.lineItem.created;
      }

      if (!minDate) {
        minDate = v.lineItem.created;
      }
    });

    this.setState({
      selectedPieces: [],
      activePieces: _sortBy(newActivePieces, ['priority']),
      startDate: minDate,
      dueDate: maxDate,
      primaryRunDuration,
      primaryRunDurationUpdated: false,
    });
  }

  handleActivatePieces(element) {
    const { selectedPrinter } = this.state;

    if (!selectedPrinter) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.printer.select"
          defaultMessage="Please select a printer"
        />);
      return;
    }

    let selectedPieces = _cloneDeep(this.state.selectedPieces);
    const { pieces, lineItems } = this.props;

    if (selectedPieces.length === 0) {
      if (!element) {
        // Button is clicked, but nothing to move
        return;
      }

      if (element.buildplatepiece !== '') {
        selectedPieces = _filter(pieces, { uri: element.buildplatepiece });
      }

      if (element.buildplatelineitem !== '') {
        const lineItemsByUri = _keyBy(lineItems, 'uri');
        const selectedLineItem = lineItemsByUri[element.buildplatelineitem];
        selectedPieces = selectedLineItem.pieces;
      }
    }

    const activePieces = _unionBy(
      this.state.activePieces,
      selectedPieces,
      'uri',
    );

    let minDate = currentTime();
    let maxDate = calculateDueDate(activePieces);

    _forEach(activePieces, v => {
      if (!maxDate || v.lineItem.created > maxDate) {
        maxDate = v.lineItem.created;
      }

      if (!minDate) {
        minDate = v.lineItem.created;
      }
    });
    const primaryRunDuration = dayjs.duration(dayjs(maxDate).diff(dayjs(minDate))).asSeconds();
    this.setState({
      activePieces,
      selectedPieces: [],
      startDate: minDate,
      dueDate: maxDate,
      primaryRunDuration,
      primaryRunDurationUpdated: false,
    });
  }

  handleDeactivatePrints(element) {
    const { selectedBuildPlateModel } = this.state;
    let selectedActivePieces = [...this.state.activePiecesSelected];
    let activePieces = _cloneDeep(this.state.activePieces);

    if (selectedActivePieces.length === 0) {
      if (element) {
        selectedActivePieces = _filter(activePieces, { uri: element.buildplatepiece });

        if (selectedBuildPlateModel === this.BUILD_PLATE_CHOICES.LINE_ITEM) {
          // It this is line item build plate at the left side, we have to operate with whole line item,
          // so all pieces of this line item should be removed
          const usedLineItems = _map(selectedActivePieces, 'line_item');
          selectedActivePieces = _filter(activePieces, activePiece => _indexOf(usedLineItems, activePiece.line_item) !== -1);
        }
      } else {
        // It looks like "Remove Piece" button without
        // el param and there are no any selected pieces
        // Let's remove all pieces from build plate
        selectedActivePieces = activePieces;
      }
    }

    activePieces = _differenceBy(
      this.state.activePieces,
      selectedActivePieces,
      'uri',
    );

    let minDate = currentTime();
    let maxDate = calculateDueDate(activePieces);
    const primaryRunDuration = dayjs.duration(dayjs(maxDate).diff(dayjs(minDate))).asSeconds();
    _forEach(activePieces, v => {
      if (!maxDate || v.lineItem.created > maxDate) {
        maxDate = v.lineItem.created;
      }

      if (!minDate) {
        minDate = v.lineItem.created;
      }
    });

    this.setState({
      activePieces,
      startDate: minDate,
      dueDate: maxDate,
      primaryRunDuration,
      primaryRunDurationUpdated: false,
      selectedPieces: [],
      activePiecesSelected: [],
    });
  }

  handleSelectActivePiece(piece) {
    const { activePiecesSelected } = this.state;
    let focusedPieces = piece;
    if (!Array.isArray(piece)) {
      focusedPieces = [piece];
    }

    const isPieceAlreadySelected = _differenceBy(focusedPieces, activePiecesSelected, 'uri').length === 0;

    if (isPieceAlreadySelected) {
      // Remove this piece
      this.setState({
        activePiecesSelected: _differenceBy(activePiecesSelected, focusedPieces, 'uri'),
      });
    } else {
      // Add this piece
      this.setState({
        activePiecesSelected: _unionBy(activePiecesSelected, focusedPieces, 'uri'),
      });
    }
  }

  handleSelectPiece(piece) {
    const { selectedPieces, selectedPrinter } = this.state;

    let focusedPieces = piece;
    if (!Array.isArray(piece)) {
      focusedPieces = [piece];
    }

    let filteredPieces = [];
    const isPieceAlreadySelected = _intersectionBy(selectedPieces, focusedPieces, 'uri').length > 0;

    if (!selectedPrinter && !isPieceAlreadySelected) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.printer.select"
          defaultMessage="Please select a printer"
        />);
      return;
    }

    if (isPieceAlreadySelected) {
      // Deselect piece if this piece is already selected and user clicked again to this piece
      filteredPieces = _differenceBy(selectedPieces, focusedPieces, 'uri');
    } else {
      filteredPieces = _unionBy(selectedPieces, focusedPieces, 'uri');
    }

    this.setState({ selectedPieces: filteredPieces });
  }

  handleSelectPrinter(printer) {
    this.setState({ selectedPrinter: printer });
  }

  handleSelectActivePiecesGroup(group) {
    const { activePiecesSelected } = this.state;
    this.setState({
      activePiecesSelected: _unionBy(activePiecesSelected, group, 'uri'),
    });
  }

  handleSelectPiecesGroup(group) {
    const { selectedPieces } = this.state;
    this.setState({
      selectedPieces: _unionBy(selectedPieces, group, 'uri'),
    });
  }

  onToggleChange() {
    let buildPlateModel = this.state.selectedBuildPlateModel;
    buildPlateModel = (buildPlateModel === this.BUILD_PLATE_CHOICES.PIECE) ?
      this.BUILD_PLATE_CHOICES.LINE_ITEM
      :
      this.BUILD_PLATE_CHOICES.PIECE;

    this.setState({
      selectedBuildPlateModel: buildPlateModel,

      selectedPieces: [],
      activePieces: [],
      activePiecesSelected: [],
    });
  }

  onLocationChange(location) {
    const { handleOnChange } = this.props;
    const value = location.uri === LOCATION_FILTER_DEFAULT_VALUES.UNASSIGNED
      ? null
      : location.uri;

    this.setState({ selectedLocations: [location], selectedPrinter: null });
    handleOnChange(value);
  }

  onMaterialChange(materials) {
    this.setState({ selectedMaterials: materials });
  }

  onWorkflowChange(workflows) {
    this.setState({ selectedWorkflows: workflows });
  }

  onSearchQueryChange(query) {
    this.setState({ searchQuery: query });
  }

  onLayerThicknessChange(event) {
    // changes the field value
    this.setState({ layerThicknessFieldValue: event.target.value });
  }

  onLayerThicknessBlur() {
    // changes the search value used in queries
    this.setState({ layerThicknessSearch: this.state.layerThicknessFieldValue });
  }

  onChangeRunName(event) {
    const name = event.target.value;

    this.setState({
      name,
      userHasSetName: name !== '',
    });
  }

  onChangePrintTime(timeDuration) {
    const { startDate } = this.state;
    const primaryRunDuration = dayjs.duration(parseHhmmss(timeDuration)).asSeconds();
    const newDueDate = dayjs(new Date(startDate)).add(primaryRunDuration, 'seconds').toISOString();
    this.setState({
      primaryRunDuration,
      dueDate: newDueDate,
      primaryRunDurationUpdated: true,
    });
  }

  onLoadDataItems(filters, pageParams, searchParams, queryParams) {
    const { selectedBuildPlateModel, selectedPrinter } = this.state;
    const { locationFilter } = this.props;

    let searchData = '';

    if (searchParams.name) {
      searchData = searchParams.name;
    } else if (searchParams.order_name) {
      searchData = searchParams.order_name;
    } else if (this.state.searchQuery.length) {
      searchData = this.state.searchQuery;
    }

    // No need to load data until printer is selected (if it needs to be selected)
    if (selectedPrinter) {
      this.props.loadDataItems(selectedBuildPlateModel, filters, pageParams, searchData, queryParams, locationFilter);
    }
  }

  static getAutogeneratedRunName(pieces) {
    const firstPieceWithOrderName = _find(pieces, piece => _get(piece, 'order_name'));
    const name = firstPieceWithOrderName ? firstPieceWithOrderName.order_name : '';

    if (pieces.length > 1) {
      return `${name} + ${pieces.length - 1}`;
    }

    return name;
  }

  getRunWorkflowURIs() {
    /**
     * Get list of all used production workflows for line items by unique values of (material & workflow)
     * Be aware that it's possible to have the same production workflow uris in the result array
     *
     * Example:
     * Workflow1, Workflow2
     * Material1, Material2
     *
     * LI1 — Material1, Workflow1 (M1W1)
     * LI2 — Material2, Workflow1 (M2W1)
     * LI3 — Material1, Workflow2 (M1W2)
     * LI4 — Material2, Workflow2 (M2W2)
     * LI5 — Material2, Workflow2 (M2W2)
     *
     * uniq(material, workflow) = [M1W1, M2W1, M1W2, M2W2]
     * Method will return URI of [W1, W1, W2, W2]
     *
     * @return {array} Array with production workflow uri
     * */
    const { lineItems } = this.props;
    const { activePieces } = this.state;

    const runLineItemUuids = _map(activePieces, piece => extractUuid(piece.line_item));
    const runLineItems = _filter(lineItems, lineItem => _indexOf(runLineItemUuids, lineItem.uuid) !== -1);

    let usedWorkflows = _map(
      runLineItems,
      _partialRight(_pick, ['workflow', 'material']),
    );
    usedWorkflows = _uniqWith(usedWorkflows, _isEqual);

    return _map(usedWorkflows, 'workflow');
  }

  filterByLayerThicknessSelection() {
    const { activePieces } = this.state;
    const activeLayerThickensses = _uniqBy(_map(activePieces, 'layer_thickness'));
    // if there's only one layer thickness selected in the build plate, filter
    // pieces by that layer thickness
    if (activeLayerThickensses.length === 1) {
      const layerThicknessFieldValue = activeLayerThickensses[0];
      this.setState({ layerThicknessFieldValue, layerThicknessSearch: layerThicknessFieldValue });
    } else {
      this.setState({ layerThicknessSearch: '', layerThicknessFieldValue: '' });
    }
  }

  checkIsModelTooLargeForPrinter() {
    const { selectedPrinter, activePieces } = this.state;
    const printerBuildVolume = selectedPrinter.printer_type.build_volume;
    const printerSizeString = `${printerBuildVolume.x} × ${printerBuildVolume.y} × ${printerBuildVolume.z}`;

    const buildVolume = {
      x: printerBuildVolume.x,
      y: printerBuildVolume.y,
      z: printerBuildVolume.z,
    };

    const isAnyModelTooLargeForPrinter = _some(_map(activePieces, piece => {
      const { lineItem: { composition_type: compositionType } } = piece;
      // No model is available for Co-Print (models are in parts) - so nothing to check
      const isModelTooLargeForPrinter = compositionType !== LINE_ITEM_COMPOSITION_TYPES.CO_PRINT
        && checkPieceModelTooLargeForPrinter({ piece, buildVolume });
      if (isModelTooLargeForPrinter && !this.state.isAnyModelTooLargeForPrinter) {
        Alert.warning(<FormattedMessage
          id="toaster.warning.printer.sizeOfPieceMayExceedPrinterSize"
          defaultMessage="Size of {pieceName} may exceed printer's size: {printerSizeString}"
          values={{ pieceName: piece.name, printerSizeString }}
        />);
      }
      return isModelTooLargeForPrinter;
    }));

    this.setState({ isAnyModelTooLargeForPrinter });
  }

  updateRunSpecimens() {
    /**
     * Update `activeSpecimens` state with list of used specimens
     * for run based on production workflows of run.
     * */

    const { isSpecimenLibraryFeatureEnabled, specimens } = this.props;
    if (!isSpecimenLibraryFeatureEnabled) {
      return;
    }

    const workflowURIs = this.getRunWorkflowURIs();

    const activeSpecimens = specimensByWorkflow(specimens, workflowURIs);
    this.setState({ activeSpecimens });
  }

  updateRunPriority(pieces) {
    /*
     * Update priority of run based on max line item priority
     */
    const { priority: oldPriority } = this.state;

    // use the priority of the print with the greatest priority
    // TODO consider to use _maxBy()
    const priority = _max(_map(pieces, ({ lineItem }) => lineItem.priority));

    if (oldPriority !== priority) {
      this.setState({ priority });
    }
  }

  updateRunName(pieces) {
    const { name, userHasSetName } = this.state;
    if (userHasSetName) {
      // User changed run name manually, there no sense to generate run name
      return;
    }

    const runName = userHasSetName ? name : RunNew.getAutogeneratedRunName(pieces);

    this.setState({ name: runName });
  }

  resetFilters() {
    this.setState({
      selectedWorkflows: [],
      selectedMaterials: [],
      selectedLocations: [],
      searchQuery: '',
      layerThicknessSearch: '',
      layerThicknessFieldValue: '',
    });

    // Trigger location change to All
    this.props.handleOnChange(LOCATION_FILTER_DEFAULT_VALUES.ALL);
  }

  clearPrinter() {
    this.setState({ selectedPrinter: null, activePieces: [], activePiecesSelected: [] });
  }

  render() {
    const {
      printers,
      fetching,
      pieces,
      lineItems,
      printListStore,
      lineItemListStore,
      locations,
      materials,
      fetchingPieceList,
      workflows,
      creatingRun,
      modelLibrariesByUri,
      modelsByUri,
      orders,
      assemblyMetas,
      assemblyPartMetas,
      isDebugModeEnabled,
    } = this.props;

    const {
      selectedPrinter,
      selectedPieces,
      selectedLocations,
      selectedMaterials,
      selectedWorkflows,
      layerThicknessSearch,
      layerThicknessFieldValue,
      searchQuery,
      startDate,
      dueDate,
      primaryRunDuration,
      name,
      selectedBuildPlateModel,
      activeSpecimens,
      activePieces,
      activePiecesSelected,
      priority,
      isAnyModelTooLargeForPrinter,
    } = this.state;

    const inactivePieces = _differenceBy(pieces, activePieces, 'uri');
    // If at least one piece is inactive, line item considered as inactive (allow to add this one piece)
    const inactiveLineItemUUIDs = _uniq(_map(inactivePieces, ({ line_item }) => extractUuid(line_item)));
    const inactiveLineItems = _filter(lineItems, ({ uri }) => inactiveLineItemUUIDs.includes(extractUuid(uri)));
    const locationOptions = [
      { uri: LOCATION_FILTER_DEFAULT_VALUES.ALL, name: 'All' },
      ...locations,
      { uri: LOCATION_FILTER_DEFAULT_VALUES.UNASSIGNED, name: 'Unassigned' },
    ];

    return (
      <Container fluid>
        <BreadcrumbNav breadcrumbs={['plan', 'runs', 'New']} />
        <Row>
          {locations && locations.length > 0 && (
            <Col xs={12} sm={2} className="mb8">
              <SelectMultiple
                title="Location"
                data={locationOptions}
                labelKey="name"
                valueKey="uri"
                multiple={false}
                keepOpen={false}
                selectedData={selectedLocations[0] === null
                  ? [{ uri: LOCATION_FILTER_DEFAULT_VALUES.UNASSIGNED, name: 'Unassigned' }]
                  : selectedLocations[0] || [{ uri: LOCATION_FILTER_DEFAULT_VALUES.ALL, name: 'All' }]}
                handleOnClose={this.onLocationChange}
              />
            </Col>
          )}
          <Col xs={12} sm={2} className="mb8">
            {
              materials && materials.length > 0 && (
                <SelectMultiple
                  title="Materials"
                  data={materials}
                  labelKey="name"
                  valueKey="uri"
                  selectedData={selectedMaterials}
                  handleOnClose={this.onMaterialChange}
                />
              )
            }
          </Col>
          <Col xs={12} sm={2} className="mb8">
            {
              workflows && workflows.length > 0 && (
                <SelectMultiple
                  title="Production Workflow"
                  data={workflows}
                  labelKey="name"
                  valueKey="uri"
                  selectedData={selectedWorkflows}
                  handleOnClose={this.onWorkflowChange}
                />
              )
            }
          </Col>
          <Col xs={12} sm={2} className="mb8">
            <FormControl
              min={MODEL_LAYER_THICKNESS_SETTINGS.MIN}
              max={MODEL_LAYER_THICKNESS_SETTINGS.MAX}
              onBlur={this.onLayerThicknessBlur}
              onChange={this.onLayerThicknessChange}
              required
              step={MODEL_LAYER_THICKNESS_SETTINGS.STEP}
              type="number"
              value={layerThicknessFieldValue}
              placeholder="Layer Thickness"
            />
          </Col>
          <Col xs={12} sm={2} className="mb8">
            <InputSubmit
              placeholder="Search"
              value={searchQuery}
              onSubmit={this.onSearchQueryChange}
            />
          </Col>
          <Col xs={12} sm={2} className="mb8">
            {
              (
                searchQuery ||
                selectedLocations.length > 0 ||
                selectedMaterials.length > 0 ||
                selectedWorkflows.length > 0 ||
                !_isEmpty(layerThicknessFieldValue)
              ) && (
                <Button
                  onClick={this.resetFilters}
                >
                  Reset Filters
                </Button>
              )
            }
          </Col>
        </Row>

        <hr />

        {
          fetching && <Loading />
        }

        {!fetching && (
          <Row>
            <Col xs={12} lg={7} className="run-prints-table">
              <div>
                <div className="vm-panel-wrap">
                  {
                    selectedBuildPlateModel === this.BUILD_PLATE_CHOICES.PIECE && (
                      <PieceList
                        fetching={fetchingPieceList}
                        pieces={inactivePieces}
                        selectedPrinter={selectedPrinter}
                        selected={selectedPieces}
                        onSelect={this.handleSelectPiece}
                        onSelectGroup={this.handleSelectPiecesGroup}
                        onActivate={this.handleActivatePieces}
                        onDeactivate={this.handleDeactivatePrints}
                        onLoadPieces={this.onLoadDataItems}
                        printListStore={printListStore}
                        selectedLocations={selectedLocations}
                        selectedMaterials={selectedMaterials}
                        selectedWorkflows={selectedWorkflows}
                        layerThicknessSearch={layerThicknessSearch}
                        searchQuery={searchQuery}
                        onToggleChange={this.onToggleChange}
                        handleQuickFill={this.handleQuickFill}
                        orders={orders}
                        assemblyMetas={assemblyMetas}
                        assemblyPartMetas={assemblyPartMetas}
                        isDebugModeEnabled={isDebugModeEnabled}
                      />
                    )
                  }
                  {
                    selectedBuildPlateModel === this.BUILD_PLATE_CHOICES.LINE_ITEM && (
                      <LineItemList
                        fetching={fetchingPieceList}
                        lineItems={inactiveLineItems}
                        inactivePieces={inactivePieces}
                        selected={selectedPieces}
                        onSelect={this.handleSelectPiece}
                        onSelectGroup={this.handleSelectPiecesGroup}
                        onActivate={this.handleActivatePieces}
                        onDeactivate={this.handleDeactivatePrints}
                        onLoadLineItems={this.onLoadDataItems}
                        lineItemListStore={lineItemListStore}
                        selectedLocations={selectedLocations}
                        selectedMaterials={selectedMaterials}
                        selectedWorkflows={selectedWorkflows}
                        layerThicknessSearch={layerThicknessSearch}
                        selectedPrinter={selectedPrinter}
                        searchQuery={searchQuery}
                        toggleChecked
                        onToggleChange={this.onToggleChange}
                        handleQuickFill={this.handleQuickFill}
                        orders={orders}
                      />
                    )
                  }
                </div>
              </div>
            </Col>
            <Col xs={12} lg={5}>
              <ActivePiecesList
                isAnyModelTooLargeForPrinter={isAnyModelTooLargeForPrinter}
                printer={selectedPrinter}
                pieces={activePieces}
                selected={activePiecesSelected}
                onSelect={this.handleSelectActivePiece}
                onActivate={this.handleActivatePieces}
                onDeactivate={this.handleDeactivatePrints}
                onPrinterSelect={this.handleSelectPrinter}
                onChangeRunName={this.onChangeRunName}
                onSave={this.handleSave}
                name={name}
                startDate={startDate}
                dueDate={dueDate}
                primaryRunDuration={primaryRunDuration}
                onChangePrintTime={this.onChangePrintTime}
                onSelectGroup={this.handleSelectActivePiecesGroup}
                printers={printers}
                creatingRun={creatingRun}
                specimens={activeSpecimens}
                modelLibrariesByUri={modelLibrariesByUri}
                modelsByUri={modelsByUri}
                priority={priority}
              />
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

RunNew.defaultProps = {
  locationFilter: '',
};

RunNew.propTypes = {
  printers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSave: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pieces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modelsByUri: PropTypes.objectOf(
    PropTypes.shape({}),
  ).isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadDataItems: PropTypes.func.isRequired,
  printListStore: PropTypes.shape({}).isRequired,
  lineItemListStore: PropTypes.shape({}).isRequired,
  workflows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modelLibrariesByUri: PropTypes.objectOf(
    PropTypes.shape({}),
  ).isRequired,
  specimens: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchingPieceList: PropTypes.bool.isRequired,
  creatingRun: PropTypes.bool.isRequired,
  isSpecimenLibraryFeatureEnabled: PropTypes.bool.isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  assemblyMetas: PropTypes.arrayOf(PropTypes.shape).isRequired,
  assemblyPartMetas: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  locationFilter: PropTypes.string,
  isDebugModeEnabled: PropTypes.bool.isRequired,
};

export default RunNew;
