import React from 'react';
import PropTypes from 'prop-types';
import { PIECE_STATUS_MAP } from 'rapidfab/mappings';
import { orderResourceType } from 'rapidfab/types';

import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import { Link } from 'react-router-dom';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { FEATURES, ROUTES } from 'rapidfab/constants';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import Fa from 'react-fontawesome';
import Config from 'rapidfab/config';
import Feature from 'rapidfab/components/Feature';
import extractUuid from 'rapidfab/utils/extractUuid';

const Pieces = ({ ordersByUri, runs, data, fetching, onSort, ...componentProps }) => {
  const columns = [
    {
      type: 'custom',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'piece',
      Cell: ({ value }) => {
        const piece = data.find(({ uuid }) => uuid === value);
        return (
          <div className="d-flex">
            <Link
              to={getRouteURI(ROUTES.PIECE_EDIT, { uuid: value }, {}, true)}
              className="pull-right"
            >
              {value && getShortUUID(value)}
            </Link>
            {
              piece.current_print ? (
                <Feature featureName={FEATURES.QR_PRINTS_TRAVELER}>
                  <a
                    href={`${Config.HOST.QR}/traveler/print/${
                      extractUuid(piece.current_print)
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Fa name="qrcode" size="lg" className="spacer-right spacer-left" />
                  </a>
                </Feature>
              ) : null
            }
          </div>
        );
      },
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: PIECE_STATUS_MAP,
      isSortable: true,
    },
    {
      type: 'record',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      resource: 'piece',
      uri: 'uri',
      isSortable: true,
    },
    {
      type: 'resource',
      uid: 'field.order',
      accessor: 'order',
      defaultMessage: 'Order',
      resource: 'order',
      resources: ordersByUri,
      isSortable: true,
    },
    {
      type: 'custom',
      uid: 'currentStep',
      accessor: 'current_step_position',
      defaultMessage: 'Current Step',
      isSortable: true,
      Cell: ({ value, row }) => {
        const print = row.original.current_print;
        const run = runs.find(run => run.prints.includes(print));

        if (run) {
          return (
            <Link
              to={getRouteURI(ROUTES.RUN_EDIT, { uuid: extractUuid(run.uri) }, {}, true)}
            >
              {value} - {run.workstation_name}
            </Link>
          );
        }
        return value;
      },
    },
  ];

  return (
    <TableWithSearching
      {...componentProps}
      data={data}
      columns={columns}
      withBreadcrumbs
      breadcrumbs={['plan', 'pieces']}
      isFetching={fetching}
      isManualSoringEnabled
      manualSortingFunc={onSort}
      initialSortedDesc
      initialSortedColumn="updated"
    />
  );
};

Pieces.propTypes = {
  ordersByUri: PropTypes.objectOf(orderResourceType).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
  onSort: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      current_print: PropTypes.string.isRequired,
    }),
  }).isRequired,
  runs: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string.isRequired,
    workstation_name: PropTypes.string.isRequired,
    prints: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
};

export default Pieces;
