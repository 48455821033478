import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Badge } from 'react-bootstrap';
import { FormattedCost, FormattedMessage } from 'rapidfab/i18n';
import { zverseConversionResourceType } from 'rapidfab/types';
import Fa from 'react-fontawesome';
import Loading from 'rapidfab/components/Loading';
import { ZVERSE_CONVERSION_STATUSES_MAP } from 'rapidfab/mappings';
import { ZVERSE_CONVERSION_STATUSES } from 'rapidfab/constants';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import { FormattedDate } from 'react-intl';

const Zverse = ({
  zverseConversion,
  onEstimateRequest,
  onConversionApprove,
  isHawkingUser,
}) => {
  const [isRequestingConversionEstimate, setIsRequestingConversionEstimate] = useState(false);
  const [isConversionApproving, setIsConversionApproving] = useState(false);
  const [isConfirmApprovalModalShown, setIsConfirmApprovalModalShown] = useState(false);

  const requestConversionEstimate = () => {
    setIsRequestingConversionEstimate(true);
    onEstimateRequest()
      .finally(() => setIsRequestingConversionEstimate(false));
  };

  const approveConversion = () => {
    setIsConfirmApprovalModalShown(true);
  };

  const closeConfirmApprovalModal = () => {
    setIsConversionApproving(false);
    setIsConfirmApprovalModalShown(false);
  };

  const confirmApproveConversion = () => {
    setIsConversionApproving(true);
    setIsConfirmApprovalModalShown(false);
    onConversionApprove(zverseConversion.uri)
      .finally(() => {
        setIsConversionApproving(false);
      });
  };

  // const handleModelUpload = event => {
  //   const modelFile = event.target.files[0];
  //   setUploadingModelFileName(modelFile.name);
  //   onUploadModel(modelFile).finally(() => {
  //     setUploadingModelFileName(null);
  //   });
  // };

  const showRequestEstimate =
    // If Zverse Conversion failed to create for some reason on the backend
    !zverseConversion
    // Or Conversion creation failed on the 3rd party API side
    || zverseConversion.status === ZVERSE_CONVERSION_STATUSES.ERROR
    // Or Approval of the estimate failed
    || zverseConversion.status === ZVERSE_CONVERSION_STATUSES.APPROVAL_FAILED;

  const showConvertTo3dButton =
    // Approval is allowed for Completed Estimate state only
    zverseConversion && zverseConversion.status === ZVERSE_CONVERSION_STATUSES.ESTIMATE_COMPLETED;

  // Show status when there is one
  const showStatusMapping = !!zverseConversion;

  // Estimated price is needed right before Approval
  // const showEstimatedPrice = showConvertTo3dButton;

  // Is the conversion completed?
  const zverseConversionCompleted = zverseConversion?.status === ZVERSE_CONVERSION_STATUSES.CONVERSION_COMPLETED;

  /* Temporary -- waiting on backend completion! */
  const estimatedConversionTime = zverseConversionData => {
    switch (zverseConversionData?.complexity_value) {
      case 1:
        return '1-2 business days';
      case 2:
        return '1-3 business days';
      case 3:
        return '2-5 business days';
      case 4:
        return '3-7 business days';
      case 5:
        return 'Provided with quote';
      default:
        return 'Unknown';
    }
  };

  return (
    <Row>
      <Col md={{ span: 6 }} xs={{ span: 3 }}>
        {showStatusMapping && (
          <p>
            Conversion Status:
            <Badge style={{ fontSize: 15 }} className="spacer-left hawking-badge-secondary">
              {
                ZVERSE_CONVERSION_STATUSES_MAP[zverseConversion.status]
                  ? <FormattedMessage {...ZVERSE_CONVERSION_STATUSES_MAP[zverseConversion.status]} />
                // When Zverse Conversion is POSTed but not yet GETed - no status is available in the store
                  : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
              }
            </Badge>
          </p>
        )}
        {showConvertTo3dButton && (
          zverseConversionCompleted ?
            (
              <div className="mt15">
                <p>
                  Requested Date:
                  <Badge style={{ fontSize: 15 }} className="spacer-left hawking-badge-secondary">
                    <FormattedDate value={zverseConversion?.created} />
                  </Badge>
                </p>
              </div>
            )
            :
            (
              <div className="mt15">
                <Button
                  disabled={isConversionApproving}
                  className="w-lg"
                  variant="primary"
                  onClick={approveConversion}
                >
                  {isConversionApproving && <Loading className="spacer-right" inline />}
                  <Fa name="cube" /> Convert to 3D
                </Button>
              </div>
            )
        )}
        {/* <Fa name="copy" size="5x" /> */}
      </Col>
      <Col md={6} xs={3}>
        {/* <p>
          3D Part files are required to create a Part Analysis Report.
          Add a 3D file or convert the 2D file using the file conversion service.
        </p> */}
        <div>
          <p>
            Estimated time to convert:
            <Badge style={{ fontSize: 15 }} className="spacer-left hawking-badge-secondary">
              {estimatedConversionTime(zverseConversion)}
            </Badge>
          </p>
        </div>
        <div className="mt15">
          <p>
            Expected cost to convert:
            <Badge style={{ fontSize: 15 }} className="spacer-left  hawking-badge-secondary">
              <FormattedCost currency={zverseConversion?.currency} value={zverseConversion?.cost} />
            </Badge>
          </p>
        </div>
        {
          // Show notes, if there are any (Error messages will be added to notes)
          zverseConversion && zverseConversion.notes
        }
      </Col>
      <Col xs={3}>
        <div className="text-center">

          {showRequestEstimate && (
            <Button
              disabled={isRequestingConversionEstimate}
              className="center-block w-lg"
              variant="primary"
              onClick={requestConversionEstimate}
            >
              {isRequestingConversionEstimate && <Loading className="spacer-right" inline />}
              <Fa name="cube" /><p>Convert to 3D</p>
            </Button>
          )}

        </div>
      </Col>
      {isConfirmApprovalModalShown && (
        <ConfirmationModal
          handleCancel={closeConfirmApprovalModal}
          handleConfirm={confirmApproveConversion}
          confirmButtonContent={<FormattedMessage id="button.submit" defaultMessage="Submit" />}
          isHawkingUser={isHawkingUser}
          message={(
            <>
              By pressing submit, you will begin the process of using a service to convert your 2D file to a 3D file.
              {' '}
              This may involve contact from the 3D conversion service and the cost
              {' '}
              (expected <FormattedCost currency={zverseConversion.currency} value={zverseConversion.cost} />)
              {' '}
              will be billed to your organization. Would you like to submit?
            </>
          )}
        />
      )}
    </Row>
  );
};

Zverse.propTypes = {
  onUploadModel: PropTypes.func.isRequired,
  zverseConversion: zverseConversionResourceType,
  onEstimateRequest: PropTypes.func.isRequired,
  onConversionApprove: PropTypes.func.isRequired,
  isHawkingUser: PropTypes.bool.isRequired,
};

Zverse.defaultProps = {
  zverseConversion: null,
};

export default Zverse;
