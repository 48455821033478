import React, { useEffect, useState } from 'react';
import Actions from 'rapidfab/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getRouteUUID, getRouteUUIDResource } from 'rapidfab/selectors';

import PostProcessor from 'rapidfab/components/records/PostProcessor';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';

const PostProcessorContainer = props => {
  const uuid = useSelector(getRouteUUID);
  const postProcessor = useSelector(getRouteUUIDResource);

  const [tab, setTab] = useState('1');
  const dispatch = useDispatch();

  if (postProcessor && postProcessor.is_service) {
    window.location.hash = getRouteURI(ROUTES.POST_PROCESSOR_SERVICE_EDIT, { uuid });
  }

  useEffect(() => {
    if (uuid) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].get(uuid));
    }
  }, []);

  const handleSelectTab = currentTab => {
    setTab(currentTab);
  };

  return (
    <PostProcessor
      {...props}
      tab={tab}
      postProcessor={postProcessor}
      handleSelectTab={handleSelectTab}
    />
  );
};

export default PostProcessorContainer;
