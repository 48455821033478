import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalTitle,
  ModalFooter,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
  Form as BSForm,
} from 'react-bootstrap';
import { FormControlTextArea } from 'rapidfab/components/formTools';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import { materialLotResourceType, finalFormInputTypes } from 'rapidfab/types';
import {
  MATERIAL_CONTAINER_STATUSES,
  MATERIAL_CONTAINER_NUMBER_FIELD_STEP,
} from 'rapidfab/constants';
import _clone from 'lodash/clone';
import _isEmpty from 'lodash/isEmpty';
import _some from 'lodash/some';
import _sumBy from 'lodash/sumBy';
import _round from 'lodash/round';

import { Form, Field, FormSpy } from 'react-final-form';

const MaterialLotReceiveModal = ({
  onClose,
  onSaveContainers,
  onReceiveLot,
  lot,
  containers,
  initialFormValues,
}) => {
  const [containerFields, setContainerFields] = useState(containers);
  const [deletedContainers, setDeletedContainers] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [form, setForm] = useState(initialFormValues);

  const fullQuantity = _sumBy(containerFields, 'quantity');
  const containersCount = containers.length;
  const containerQuantity = Math.floor(lot.full_quantity / containersCount);

  const onChangeContainerFieldValue = (event, index) => {
    const { value } = event.target;
    const newContainerFields = _clone(containerFields);
    newContainerFields[index].quantity = value === '' ? null : Number(value);
    setContainerFields(newContainerFields);
  };

  const onAddContainerField = () => {
    const newContainerFields = _clone(containerFields);
    newContainerFields.push({ quantity: 0, status: MATERIAL_CONTAINER_STATUSES.NEW });
    setContainerFields(newContainerFields);
  };

  const onRemoveContainerField = index => {
    const currentContainer = containerFields[index];
    if (currentContainer.uri) {
      // Mark container to delete from server (if that's an existing one)
      setDeletedContainers([...deletedContainers, { uri: currentContainer.uri }]);
    }
    const newContainerFields = _clone(containerFields);
    newContainerFields.splice(index, 1);
    setContainerFields(newContainerFields);
  };

  const onSubmit = async ({ notes, isAsExpected }) => {
    setIsSaving(true);
    const create = containerFields
      .filter(container => !container.uuid)
      .map(({ quantity }) => ({ quantity }));
    const update = containerFields
      .filter(container => container.uuid)
      .map(({ uri, quantity }) => ({ uri, quantity }));
    const bulkContainersOperations = { update, create, delete: deletedContainers };

    // only modify containers if order is not received as expected
    if (!isAsExpected) {
      await onSaveContainers({ uri: lot.uri, bulkContainersOperations });
    }
    await onReceiveLot(lot.uri, notes);
    setIsSaving(false);
    onClose();
  };

  // disable save button if there are no containers or if one of the containers'
  // amount is 0
  const isSaveButtonDisabled = _isEmpty(containerFields)
    || _some(containerFields, ({ quantity }) => !quantity)
    || isSaving;

  return (
    <Modal show onHide={onClose} backdrop="static">
      <ModalHeader closeButton>
        <ModalTitle>
          <FormattedMessage
            id="materialLot.receiveLot"
            defaultMessage="Receive Lot"
          />
        </ModalTitle>
      </ModalHeader>
      <Form
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <FormGroup>
                <FormLabel>
                  Notes: *
                </FormLabel>
                <FormControlTextArea
                  name="notes"
                  initialValue={initialFormValues?.notes}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Field
                  type="checkbox"
                  name="isAsExpected"
                  render={props => (
                    <BSForm.Check
                      {...props.input}
                      label="Order received as expected"
                    />
                  )}
                />
              </FormGroup>
              <div className="mb15">
                <b>Amount to be Received:</b>
                {form?.values?.isAsExpected
                  ? ` ${containersCount} × ${containerQuantity} ${lot.units}`
                  : ` ${_round(fullQuantity, 2)} ${lot.units}`}
              </div>
              {!form?.values?.isAsExpected && (
                <>
                  {containerFields.map(({ quantity, uri }, index) => (
                    <FormGroup className="d-flex align-items-center" key={uri}>
                      <FormLabel className="mr15">{`Container ${index + 1}`}</FormLabel>
                      <FormControl
                        style={{ maxWidth: 300 }}
                        type="number"
                        min={0}
                        value={quantity}
                        onChange={event => onChangeContainerFieldValue(event, index)}
                        step={MATERIAL_CONTAINER_NUMBER_FIELD_STEP}
                      />
                      <Button
                        className="ml15"
                        variant="danger"
                        onClick={() => onRemoveContainerField(index)}
                      >
                        <Fa name="times" />
                      </Button>
                    </FormGroup>
                  ))}
                  <Button
                    variant="primary"
                    onClick={onAddContainerField}
                  >
                    Add Container
                  </Button>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                variant="success"
                type="submit"
                disabled={isSaveButtonDisabled}
              >
                {isSaving ? (
                  <Fa name="spinner" spin />
                ) : (
                  <FormattedMessage id="materialLot.receiveLot" defaultMessage="Receive Lot" />
                )}
              </Button>
            </ModalFooter>
            <FormSpy
              subscription={{ values: true }}
              onChange={values => setForm(values)}
            />
          </form>
        )}
      />
    </Modal>
  );
};

MaterialLotReceiveModal.defaultProps = {};

MaterialLotReceiveModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onReceiveLot: PropTypes.func.isRequired,
  onSaveContainers: PropTypes.func.isRequired,
  initialFormValues: PropTypes.shape({
    isAsExpected: PropTypes.bool.isRequired,
    notes: PropTypes.string.isRequired,
  }).isRequired,
  input: finalFormInputTypes.isRequired,
  lot: materialLotResourceType.isRequired,
  containers: PropTypes.arrayOf(PropTypes.shape({
    quantity: PropTypes.number,
  })).isRequired,
};

const mapStateToProps = () => {
  const initialFormValues = { isAsExpected: true };
  return {
    initialFormValues,
  };
};

export default connect(mapStateToProps)(MaterialLotReceiveModal);
