import { navbarEN } from './locales/navbar/index';
import { recordsEN } from './locales/records/index';
import { mappingsEN } from './locales/mappings/index';
import { componentsEN } from './locales/components/index';

import { planEN } from './locales/plan/index';
import adminEN from './locales/admin/en.json';
import workEN from './locales/work/en.json';
import inventoryEN from './locales/inventory/en.json';
import manageEN from './locales/manage/en.json';
import mfg from './locales/mfg.json';
import toasterEN from './locales/toaster/en.json';
import restEN from './locales/rest/en.json';

const translation = Object.assign(
  adminEN,
  navbarEN,
  planEN,
  workEN,
  inventoryEN,
  manageEN,
  recordsEN,
  mappingsEN,
  componentsEN,
  mfg,
  toasterEN,
  restEN,
);

export default translation;
