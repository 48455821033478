import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import PostProcessorForm from 'rapidfab/components/records/PostProcessorForm';
import Alert from 'rapidfab/utils/alert';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import usePrevious from 'rapidfab/hooks';
import { FormattedMessage } from 'react-intl';
import {
  isInHoursMinutesFormat,
  getDurationSecondsFromHoursAndMinutes,
  getHoursAndMinutesFromDurationSecs,
} from 'rapidfab/utils/isHoursMinutesFormat';
import extractUuid from 'rapidfab/utils/extractUuid';

const redirect = isService => {
  if (isService) {
    window.location.hash = getRouteURI(ROUTES.POST_PROCESSOR_SERVICES);
  } else {
    window.location.hash = getRouteURI(ROUTES.POST_PROCESSORS);
  }
};

const PostProcessorFormContainer = props => {
  const uuid = useSelector(Selectors.getRouteUUID);
  const loading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LOCATION].list.count === 0 ||
    state.ui.nautilus[API_RESOURCES.LOCATION].list.fetching ||
    state.ui.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list.count === 0 ||
    state.ui.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list.fetching);
  const locations = useSelector(Selectors.getLocations);
  const postProcessor = useSelector(state => Selectors.getUUIDResource(state, uuid));
  const postProcessorTypes = useSelector(Selectors.getPostProcessorTypes);
  const submitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.POST_PROCESSOR].post.fetching
    || state.ui.nautilus[API_RESOURCES.POST_PROCESSOR].put.fetching
    || state.ui.nautilus[API_RESOURCES.POST_PROCESSOR].delete.fetching);
  const isServiceProviderRole = useSelector(Selectors.isServiceProvider);

  const selected = {
    uuid,
    loading,
    locations,
    postProcessor,
    postProcessorTypes,
    submitting,
    isServiceProviderRole,
  };

  const [name, setName] = useState(() => (postProcessor ? postProcessor.name : ''));
  const [location, setLocation] = useState(() => (postProcessor
    ? postProcessor.location
    : (locations.length ? locations[0].uri : null)));
  const [postProcessorType, setPostProcessorType] = useState(() => (postProcessor
    ? postProcessor.post_processor_type
    : (postProcessorTypes.length ? postProcessorTypes[0].uri : null)));
  const [duration, setDuration] = useState(() => (postProcessor
    ? (postProcessor.duration ? postProcessor.duration.toString() : '')
    : ''));
  const [isDurationInvalid, setIsDurationInvalid] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    Promise.all([
      dispatch(Actions.Api.nautilus.location.list()),
      dispatch(Actions.Api.nautilus['post-processor-type'].list()),
    ]);
  }, []);

  const previousPostProcessor = usePrevious(postProcessor);
  const previousPostProcessorTypes = usePrevious(postProcessorTypes);
  const previousLocations = usePrevious(locations);

  useEffect(() => {
    if (!previousPostProcessor && postProcessor) {
      setDuration(props.isService ?
        postProcessor.duration :
        getHoursAndMinutesFromDurationSecs(postProcessor.duration));
      setName(postProcessor.name);
      setPostProcessorType(postProcessor.post_processor_type);
      setLocation(postProcessor.location);
    } else {
      if (previousLocations && previousLocations.length === 0 && locations.length > 0) {
        setLocation(locations[0].uri);
      }
      if (previousPostProcessorTypes && previousPostProcessorTypes.length === 0 && postProcessorTypes.length > 0) {
        setPostProcessorType(postProcessorTypes[0].uri);
      }
    }
  }, [postProcessor]);

  const handleDelete = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].delete(uuid))
      .then(() => redirect(props.isService));
  };

  const handleArchive = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].delete(uuid))
      .then(() => dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].get(uuid)))
      .then(() => Alert.success(
        <FormattedMessage
          id="toaster.postProcessor.archived"
          defaultMessage="Post processor {uuid} successfully archived."
          values={{ uuid }}
        />,
      ))
      .finally(() => redirect(props.isService));
  };
  const handleUnarchive = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].put(uuid, { archived: null }));
  };

  // eslint-disable-next-line consistent-return
  const handleInputChange = event => {
    const { isService } = props;
    const { value, name: currentName } = event.target;

    if (isService && currentName === 'duration') {
      // Duration for Service is shown/edited in days but saved in seconds
      // multiplying days by the number of seconds in a day - 86400 (if any duration provided)
      return setDuration(value ? value * 86400 : value);
    }

    if (currentName === 'duration') {
      setIsDurationInvalid(false);
      if (!isInHoursMinutesFormat(value)) {
        return setIsDurationInvalid(true);
      }
      setDuration(value);
    }

    // Check Post Processor Types to handle
    // certain Post Processor Types such as Non-conformance
    if (currentName === 'postProcessorType') {
      const selectedPostProcessorType = postProcessorTypes.find(
        type =>
          type.uri === value,
      )?.special_type;

      if (props.displayChipNote) {
        props.displayChipNote(selectedPostProcessorType === 'non_conformance_review');
      }
    }

    switch (currentName) {
      case 'name':
        setName(value);
        break;
      case 'location':
        setLocation(value);
        break;
      case 'postProcessorType':
        setPostProcessorType(value);
        break;
      default:
        break;
    }
  };

  const redirectToPostProcessor = uri => {
    window.location.hash = getRouteURI(ROUTES.POST_PROCESSOR_EDIT, { uuid: extractUuid(uri) });
  };

  const handleSubmit = event => {
    event.preventDefault();
    const { isService } = props;

    if (isDurationInvalid && !isService) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.duration.formatMustBeHHMM"
          defaultMessage="Duration formatting is incorrect, it must follow the format of HH:MM"
        />);
      return;
    }

    const payload = {
      duration: isService ? duration : getDurationSecondsFromHoursAndMinutes(duration),
      name,
      post_processor_type: postProcessorType,
    };
    if (!isService) {
      // Location is available for Post Processors only (non Service)
      payload.location = location;
    }

    const entityName = isService ? 'Post-Processor Service' : 'Post-Processor';

    if (uuid) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].put(uuid, payload)).then(() => {
        Alert.success(<FormattedMessage
          id="toaster.postProcessor.updated"
          defaultMessage="{entityName} Successfully updated."
          values={{ entityName }}
        />);
        // Last Updated (user/date) info is set on the backend, and there is no
        // event-stream event for post-processors. So GET request is required
        // right after PUT
        dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].get(uuid));
      });
    } else {
      // is_service is needed on creation only
      payload.is_service = isService;
      dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].post(payload))
        .then(postResponse => {
          Alert.success(<FormattedMessage
            id="toaster.postProcessor.created"
            defaultMessage="{entityName} Successfully created."
            values={{ entityName }}
          />);
          redirectToPostProcessor(postResponse.headers.location);
        });
    }
  };

  return (
    <PostProcessorForm
      {...props}
      {...selected}
      name={name}
      location={location}
      postProcessorType={postProcessorType}
      duration={duration}
      handleDelete={handleDelete}
      handleArchive={handleArchive}
      handleUnarchive={handleUnarchive}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      selectedPostProcessorType={postProcessorTypes.find(
        type =>
          type.uri === postProcessorType,
      )}
      isDurationInvalid={isDurationInvalid}
    />
  );
};

PostProcessorFormContainer.propTypes = {
  isService: PropTypes.bool.isRequired,
  displayChipNote: PropTypes.func.isRequired,
};

export default PostProcessorFormContainer;
