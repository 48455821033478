export const CUSTOM_FIELD_TYPES = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  FLOAT: 'float',
  ARRAY: 'array',
  DATE: 'date',
  INTEGER: 'integer',
  CHECKBOX: 'checkbox',
  URL: 'url',
};

export const CUSTOM_FIELD_RIGHTS = {
  READ: 'read',
  WRITE: 'write',
};

export const CUSTOM_FIELD_FORMATS = {
  CURRENCY: 'currency',
};

export const CUSTOM_FIELD_RELATED_TABLE_NAME = {
  LINE_ITEM: 'line_item',
  ORDER: 'order',
  RUN: 'run',
  MANUFACTURER: 'manufacturer',
  SERVICE_PROVIDER: 'service_provider',
  MATERIAL: 'material',
  MODEL_LIBRARY: 'model_library',
};

export const THREE_M_AQUA_PRICING_CUSTOM_FIELDS = {
  ESTIMATED_PRICE_PER_PIECE: 'estimated_price_per_piece',
  ESTIMATED_TOTAL_PRICE: 'estimated_total_price',
  RECOMMENDED_BATCH_SIZE: 'recommended_batch_size',
};

export const BOEING_ORDER_CUSTOM_FIELDS = {
  FINANCE_REVIEW: 'finance_review',
};

export const BEEHIVE_LINE_ITEM_CUSTOM_FIELDS = {
  REVISION: 'revision',
};

export const XEROX_MODEL_LIBRARY_CUSTOM_FIELDS = {
  SURFACE_ROUGHNESS: 'surface_roughness',
  FINISHING_POST_PROCESSING_DESCRIPTION: 'finishing_post_processing_description',
  PART_NUMBER: 'part_number',
};
