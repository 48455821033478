import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';

export const getPrinterTypes = createSelector(
  [baseStateSelectors.getStatePrinterTypes, getStateResources],
  (uuids, resources) => {
    const printerTypes = _map(uuids, uuid => resources[uuid]);
    return _filter(printerTypes, { is_template: false });
  },
);

export const getPrinterTypesByUri = createSelector(
  [getPrinterTypes],
  printerTypes => _keyBy(printerTypes, 'uri'),
);
