import React from 'react';
import PropTypes from 'prop-types';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import {
  Button,
  Modal,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { ROUTES } from 'rapidfab/constants';
import extractUuid from 'rapidfab/utils/extractUuid';

const SplitConfirmationModal = ({ handleClose, selectedPiecesList, callback }) => (
  <Modal style={{ zIndex: 999999 }} show onHide={handleClose} backdrop="static">
    <Modal.Header closeButton>
      <strong>
        <FormattedMessage id="nc.splitReview" defaultMessage="Split future runs?" />
      </strong>
    </Modal.Header>

    <Modal.Body>
      <strong>
        <FormattedMessage id="nc.selectedPieces" defaultMessage="Selected Pieces" />:{' '}
        { selectedPiecesList.map((piece, index) => (
          <>
            <a href={getRouteURI(ROUTES.PIECE_EDIT, { uuid: extractUuid(piece.piece) })} rel="noreferrer" target="_blank">
              {getShortUUID(piece.uuid)}
            </a>
            {index !== selectedPiecesList.length - 1 && ', '}
          </>
        ))}

      </strong>
      <p>
        <FormattedMessage
          id="splitRunModal.firstLine"
          defaultMessage="Should the other Pieces in the Run wait for the Selected Pieces to complete their new workflow?"
        />
      </p>
      <p>
        <FormattedMessage
          id="splitRunModal.secondLine"
          defaultMessage="If not, split them into their own Runs to and skip the wait."
        />
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => {
        callback(false);
        handleClose();
      }}
      >
        <FormattedMessage id="nc.keepSchedule" defaultMessage="Keep the schedule" />
      </Button>

      <Button
        onClick={() => {
          callback(true);
          handleClose();
        }}
        variant="success"
      >
        <FormattedMessage id="nc.splitTheSchedule" defaultMessage="Split the schedule" />
      </Button>

    </Modal.Footer>
  </Modal>
);

SplitConfirmationModal.propTypes = {
  selectedPiecesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleClose: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
};

export default SplitConfirmationModal;
