import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import Fa from 'react-fontawesome';
import { ROUTES } from 'rapidfab/constants';
import { STOCK_STATUS_MAP } from 'rapidfab/mappings';
import getRouteURI from 'rapidfab/utils/getRouteURI';

const MaterialTypeStockPanelHeader = ({
  onOpenModal,
  materialManagementEnabled,
}) => (
  <Row className="d-flex align-items-center">
    <Col xs={6}>Material Stocks</Col>
    <Col xs={6} className="d-flex justify-content-end">
      {materialManagementEnabled ? (
        <OverlayTrigger
          placement="left"
          overlay={(
            <Tooltip>
              <FormattedMessage
                id="record.material.stockPanelMMTooltip"
                defaultMessage="Order or Create Material Lots in your Inventory to add them to your Stocks"
              />
            </Tooltip>
          )}
        >
          <Fa name="question-circle" size="lg" />
        </OverlayTrigger>
      ) : (
        <Fa name="plus-circle" size="lg" onClick={onOpenModal} />
      )}
    </Col>
  </Row>
);
MaterialTypeStockPanelHeader.propTypes = {
  onOpenModal: PropTypes.func.isRequired,
  materialManagementEnabled: PropTypes.bool.isRequired,
};

const MaterialTypeStockPanel = ({
  stocks,
  locationsByUri,
  materialsByUri,
  onOpenModal,
  materialManagementEnabled,
}) => {
  const stockMaterials = stocks.map(stock => {
    if (!materialsByUri[stock.material]) {
      return null;
    }
    return materialsByUri[stock.material];
  });

  const getUnits = useMemo(() => {
    const stockMaterialUnits = stockMaterials.map(material => material.units);
    const units = new Set(stockMaterialUnits);
    const unitsAreConsistent = units.size === 1;

    if (!unitsAreConsistent) return null;

    return [...units][0];
  }, [stockMaterials]);

  return (
    <>
      <Card bg="dark" className="m-b">
        <Card.Header className="pd-exp inverse">
          <MaterialTypeStockPanelHeader
            onOpenModal={onOpenModal}
            materialManagementEnabled={materialManagementEnabled}
          />
        </Card.Header>
        <div className="card-body-wrapper">
          <ListGroup fill>
            <ListGroupItem key="header">
              <Row>
                <Col xs={3}>
                  <b>
                    <FormattedMessage
                      id="field.location"
                      defaultMessage="Location"
                    />
                  </b>
                </Col>
                <Col xs={3}>
                  <b>
                    <FormattedMessage id="field.status" defaultMessage="Status" />
                  </b>
                </Col>
                <Col xs={3}>
                  <b>
                    <FormattedMessage
                      id="field.quantity"
                      defaultMessage="Quantity"
                    />{' '}
                    {getUnits === null || getUnits === 'cm3' ?
                      <FormattedMessage id="field.units.cm3" defaultMessage="cm³" />
                      :
                      <p>({getUnits}s)</p>}
                  </b>
                </Col>
                <Col xs={3} />
              </Row>
            </ListGroupItem>
            {!stocks.length ? (
              <div className="mt15 ml15 mb15">No stocks for this material</div>
            ) : (
              stocks.map(stock => {
                const location = locationsByUri[stock.location];
                return (
                  <ListGroupItem key={stock.uri}>
                    <Row>
                      <Col xs={3}>{location.name}</Col>
                      <Col xs={3}>
                        {stock.status ? (
                          <FormattedMessage {...STOCK_STATUS_MAP[stock.status]} />
                        ) : (
                          <FormattedMessage
                            id="notAvailable"
                            defaultMessage="N/A"
                          />
                        )}
                      </Col>
                      <Col xs={3}>{stock.quantity}</Col>
                      <Col xs={3}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={getRouteURI(ROUTES.STOCK_EDIT, {
                            uuid: stock.uuid,
                          })}
                        >
                          <Fa name="external-link" />
                        </a>
                      </Col>
                    </Row>
                  </ListGroupItem>
                );
              })
            )}
          </ListGroup>
        </div>
      </Card>
    </>
  );
};

MaterialTypeStockPanel.propTypes = {
  stocks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locationsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  materialsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  onOpenModal: PropTypes.func.isRequired,
  materialManagementEnabled: PropTypes.bool.isRequired,
  units: PropTypes.string,
};

MaterialTypeStockPanel.defaultProps = {
  units: null,
};

export default MaterialTypeStockPanel;
