export function pad(number) {
  if (number > -10 && number < 0) {
    return `-0${Math.abs(number)}`;
  }

  if (number >= 0 && number < 10) {
    return `0${number}`;
  }

  return `${number}`;
}

// Convert seconds into HH:mm:ss format
export default function hhmmss(secs, padHours = false) {
  const parsedSeconds = Math.ceil(Number.parseFloat(secs));

  if (!parsedSeconds) {
    return `${padHours ? '00' : '0'}:00:00`;
  }

  const hours = Math.floor(parsedSeconds / 3600);
  const minutes = Math.floor((parsedSeconds % 3600) / 60);
  const seconds = parsedSeconds % 60;

  return `${padHours ? pad(hours) : hours}:${pad(minutes)}:${pad(seconds)}`;
}

// parse hh:mm:ss to object
export const parseHhmmss = timeString => {
  if (!timeString) {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }
  const parts = timeString.split(':');

  return {
    hours: Number(parts[0]) || 0,
    minutes: Number(parts[1]) || 0,
    seconds: Number(parts[2]) || 0,
  };
};
