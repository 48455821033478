import _omit from 'lodash/omit';
import Actions from 'rapidfab/actions';
import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _differenceBy from 'lodash/differenceBy';
import _isEqual from 'lodash/isEqual';
import _without from 'lodash/without';
import { API_RESOURCES } from 'rapidfab/constants';

export function combineExistingStepsWithSelectedProcessTypes(existingSteps, selectedProcessTypes) {
  let steps = _cloneDeep(existingSteps);

  return _map(selectedProcessTypes, selectedProcessType => {
    const existingStep = _find(steps, {
      workstation_type_uri: selectedProcessType.workstation_type_uri,
      // Direction will be empty for all except Shipping steps
      shipping_direction: selectedProcessType.shipping_direction,
    });

    if (existingStep) {
      // Production Workflows can contain multiples of _the same_ process types.
      // For the next iterated process step with the same process type uri,
      // we don't need to use the same existing step, so existingStep is
      // removed from steps if the step exists.
      //
      // Only one step (even if multiples of the same process type uri exists)
      // is removed each iteration.
      steps = _without(steps, existingStep);
    }

    const changedStep = existingStep || selectedProcessType;

    // Can be null if process step is not fixed to machine.
    // Existing process step will be updated with new workstation
    changedStep.workstation = selectedProcessType.workstation;
    return changedStep;
  });
}

export function getSaveStepDispatcher(dispatch, payload) {
  const stepData = _omit(
    payload,
    ['step_positions', 'name', 'workflows', 'additive', 'related_outsource_step', 'is_service', 'flow_time', 'flow_time_queued'],
  );
  if (payload.uuid) {
    return dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].put(payload.uuid, _omit(stepData, ['bureau', 'source_process_step'])));
  }
  return dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].post(stepData));
}

export function getSaveStepsDispatchers(dispatch, existingSteps, stepsToSave) {
  const uris = [];

  _forEach(stepsToSave, step => {
    const individualStep = { ...step };
    if (!individualStep.uri) {
      uris.push(
        getSaveStepDispatcher(dispatch, _omit(individualStep, ['flow_time', 'flow_time_queued'])).then(resp => resp.headers.location),
      );
    } else {
      const oldStep = _find(existingSteps, { uri: individualStep.uri });
      if (oldStep) {
        if (!_isEqual(individualStep, oldStep)) {
          uris.push(getSaveStepDispatcher(dispatch, individualStep).then(() => individualStep.uri));
        } else {
          uris.push(individualStep.uri);
        }
      }
    }
  });
  return uris;
}

export function getStepsUUIDsToDelete(existingSteps, changedSteps) {
  // Remove from existingSteps all steps from changedSteps
  const unusedSteps = _differenceBy(existingSteps, changedSteps, 'uri');
  return _map(unusedSteps, 'uuid');
}

export function saveWorkflow(dispatch, payload) {
  const workFlowPayload = { ...payload };

  let workflowPromise;
  if (workFlowPayload.uuid) {
    workflowPromise = dispatch(
      Actions.Api.nautilus.workflow.put(workFlowPayload.uuid, _omit(workFlowPayload, ['flow_time', 'flow_time_queued'])),
    );
  } else {
    workflowPromise = dispatch(Actions.Api.nautilus.workflow.post(workFlowPayload));
  }
  return workflowPromise;
}
