import { getCaptures } from 'rapidfab/selectors';
import Captures from 'rapidfab/components/work/captures';
import { API_RESOURCES } from 'rapidfab/constants';
import withRecordsListHandling from '../hocs/withRecordsListHandling';

export default withRecordsListHandling(
  Captures,
  getCaptures,
  [API_RESOURCES.CAPTURE],
  {
    useLocationFiltering: false,
    multicolumnSearch: true,
  },
);
