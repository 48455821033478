import React from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Container, Row, FormControl, FormLabel } from 'react-bootstrap';
import Fa from 'react-fontawesome';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { FormattedMessage } from 'rapidfab/i18n';
import UsersContainer from 'rapidfab/containers/admin/UsersContainer';
import isURI from 'rapidfab/utils/isURI';

import AdminSidebar from './AdminSidebar';

const AdminUsers = ({
  selection,
  handleSelectionChange,
  filter,
  handleFilterChange,
  locations,
  roleFilter,
  handleRoleFilterChange,
  isSessionManager,
  isSelfRegistrationAllowedFeatureEnabled,
}) => (
  <Container className="p-b" fluid>
    <BreadcrumbNav breadcrumbs={['admin', 'users']} />

    <div className="page-header">
      <h1>
        <FormattedMessage
          id="userAdministration"
          defaultMessage="User Administration"
        />
      </h1>
    </div>

    <Row>
      <Col xs={12}>
        <div className="pull-right">
          <Button
            variant="primary"
            disabled={!isSessionManager}
            onClick={() => handleSelectionChange('add')}
          >
            <Fa name="plus" className="spacer-right" />
            <FormattedMessage id="addNewUser" defaultMessage="Add New User" />
          </Button>
        </div>
      </Col>
    </Row>

    <Row className="mb15" id="users-table">
      <Col xs={3}>
        <FormLabel>Search</FormLabel>{' '}
        <FormControl
          type="text"
          value={filter}
          onChange={event => handleFilterChange(event.target.value)}
        />
      </Col>
      <Col xs={3}>
        <FormLabel>Role</FormLabel>{' '}
        <FormControl
          as="select"
          type="select"
          value={roleFilter}
          onChange={event => handleRoleFilterChange(event.target.value)}
        >
          <option value="">Any</option>
          <option value="manager">Manager</option>
          <option value="global-user">Global</option>
          <option value="restricted">Restricted</option>
          {isSelfRegistrationAllowedFeatureEnabled && (
            <option value="self-registered-guest">Guest</option>
          )}
          {locations.map(location => (
            <option key={location.uri} value={location.uri}>Location: {location.name}</option>
          ))}
        </FormControl>
      </Col>
    </Row>

    <Row>
      <Col xs={12} sm={6}>
        <UsersContainer
          handleSelectionChange={handleSelectionChange}
          filter={filter}
          roleFilter={roleFilter}
          selectedUsersURIs={isURI(selection) ? [selection] : []}
          multiSelect={false}
        />
      </Col>
      <Col xs={12} sm={6}>
        <AdminSidebar
          handleSelectionChange={handleSelectionChange}
          selection={selection}
        />
      </Col>
    </Row>
  </Container>
);

AdminUsers.propTypes = {
  selection: PropTypes.string.isRequired,
  handleSelectionChange: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  roleFilter: PropTypes.string.isRequired,
  handleRoleFilterChange: PropTypes.func.isRequired,
  isSessionManager: PropTypes.bool.isRequired,
  isSelfRegistrationAllowedFeatureEnabled: PropTypes.bool.isRequired,
};

export default AdminUsers;
