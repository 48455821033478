import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as Selectors from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';
import extractUuid from 'rapidfab/utils/extractUuid';

import Loading from 'rapidfab/components/Loading';
import PieceComponent from 'rapidfab/components/records/piece/Piece';
import {
  API_RESOURCES,
  DOCUMENT_RELATED_TABLE_NAMES,
  FEATURES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  RUN_OPERATIONS,
} from 'rapidfab/constants';
import _find from 'lodash/find';
import _map from 'lodash/map';
import getRouteParameters from 'rapidfab/utils/getRouteParameters';
import _uniq from 'lodash/uniq';
import usePrevious from 'rapidfab/hooks';

import {
  getPiecesByUri,
} from 'rapidfab/selectors';

const PieceContainer = ({ onInitialize,
  materialBatchUri,
  loadMaterialBatch,
  getUsersBasedOnComments,
  comments,
  ...otherProps }) => {
  // Assuming that component is in Initial Fetching state by default
  const [initialFetching, setInitialFetching] = useState(true);

  useEffect(() => {
    setInitialFetching(true);
    onInitialize(otherProps).then(() => {
      setInitialFetching(false);
    });
  }, [JSON.stringify(otherProps.piece)]);

  const previousComments = usePrevious(comments);

  useEffect(() => {
    if (JSON.stringify(previousComments) !== JSON.stringify(comments)) {
      getUsersBasedOnComments(comments);
    }
  }, [previousComments, comments]);

  useEffect(() => {
    // When material batch was just set (an eventstream event was received)
    // - load material batch data
    if (materialBatchUri) {
      loadMaterialBatch(materialBatchUri);
    }
  }, [materialBatchUri]);

  return (
    <div>
      {initialFetching || !otherProps.piece ? <Loading /> : <PieceComponent comments={comments} {...otherProps} />}
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  const loadMaterialBatch = materialBatchUri => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH]
      .get(extractUuid(materialBatchUri)));
  };

  return {
    onInitialize: props => {
      const { bureau, isMaterialManagementFeatureEnabled } = props;

      const promises = [
        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: bureau.uri })),
        dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list()),
        dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list()),
        dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list()),
      ];

      const eventResourceUris = [];

      // Comments of these resources will be loaded
      const commentRelatedResourceUris = [];

      promises.push(dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].get(props.uuid))
        .then(async pieceResponse => {
          const {
            line_item: lineItemUri,
            order: orderUri,
            uri: pieceUri,
            is_line_item_deleted: isLineItemDeleted,
            workflow: workflowUri,
          } = pieceResponse.json;

          if (props?.piece?.remanufactured_to) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].get(props?.piece?.remanufactured_to));
          }

          dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(extractUuid(orderUri)));
          dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list(
            (workflowUri ? { workflows: workflowUri } : {})),
          );

          if (workflowUri) {
            // Workflow is optional field
            // (orders by restricted users may be with empty production workflow)
            dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].get(extractUuid(workflowUri)));
          }

          if (!isLineItemDeleted) {
            // We need to load line item data only if it is not deleted
            await dispatch(
              Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].get(extractUuid(lineItemUri), true),
            ).then(async lineItemResponse => {
              const {
                prep_workflow: prepWorkflowUri,
                model,
              } = lineItemResponse.json;

              if (model) {
                dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].get(extractUuid(model)))
                  .then(modelResponse => {
                    const replacedModels = modelResponse?.json?.replaced_models;
                    if (replacedModels?.length) {
                      dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].list(
                        { uri: replacedModels },
                        { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
                      ));
                    }
                  });
              }

              if (prepWorkflowUri) {
                eventResourceUris.push(prepWorkflowUri);

                dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_TASK]
                  .list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
                dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_WORKFLOW].get(extractUuid(prepWorkflowUri)));
                // TODO There are two places with the same flow,
                //  consider to merge them into one
                await dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_WORKFLOW_RECORD].list(
                  { order: orderUri },
                  { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
                ))
                  .then(async prepWorkflowRecordsResponse => {
                    const bestPrepWorkflowRecord = _find(
                      prepWorkflowRecordsResponse.json.resources,
                      prepWorkflowRecord =>
                        // Prep Workflow record for line item
                        // is when prep workflow record is for whole order
                        // to attached to exact line item
                        (prepWorkflowRecord.line_item === null)
                        || (prepWorkflowRecord.line_item === lineItemUri),
                    );

                    if (!bestPrepWorkflowRecord) {
                      return;
                    }

                    eventResourceUris.push(bestPrepWorkflowRecord.uri);

                    await dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_TASK_RECORD].list(
                      { prep_workflow_record: bestPrepWorkflowRecord.uri },
                      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
                    )).then(prepTaskRecordResponse => {
                      const prepTaskRecordUris = _map(prepTaskRecordResponse.json.resources, 'uri');

                      commentRelatedResourceUris.push(...prepTaskRecordUris);
                      eventResourceUris.push(...prepTaskRecordUris);
                    });
                  });
              }
            });
          }

          if (pieceResponse.json.model) {
            dispatch(
              Actions.Api.nautilus[API_RESOURCES.MODEL].get(
                extractUuid(pieceResponse.json.model),
              ),
            );
          }

          const orderDocumentsRequest = dispatch(
            Actions.Api.nautilus[API_RESOURCES.DOCUMENT].list({
              related_table_name: DOCUMENT_RELATED_TABLE_NAMES.ORDER,
              related_uuid: extractUuid(orderUri),
            }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }),
          );
          dispatch(Actions.Api.nautilus[API_RESOURCES.PRINT].list(
            {
              piece: pieceUri,
              // For piece we need all prints (not just the ones that require work)
              // In order to show Events for all prints, including Remanufactured ones
              work_needed: false,
            },
            { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
            {},
            {},
            true,
          ))
            .then(printsResponse => {
              const runUris = printsResponse.json.resources.map(print => print.run)
                .filter(runUri => !!runUri);

              const relatedUris = [
                lineItemUri, orderUri,
                ...runUris, ...commentRelatedResourceUris,
              ];
              dispatch(Actions.Api.nautilus[API_RESOURCES.COMMENT].list({
                related_uuid: relatedUris.map(uri => extractUuid(uri)),
              }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));

              const printUris = printsResponse.json.resources.map(
                print => print.uri,
              );

              const runDocumentsRequest = runUris.length
                ? dispatch(
                  Actions.Api.nautilus[API_RESOURCES.DOCUMENT].list({
                    related_table_name: DOCUMENT_RELATED_TABLE_NAMES.RUN,
                    related_uuid: runUris.map(runUri => extractUuid(runUri)),
                  }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }),
                )
                : { json: { resources: [] } };

              if (runUris.length) {
                dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].list(
                  { uri: runUris },
                  { limit: runUris.length },
                )).then(runsResponse => {
                  if (isMaterialManagementFeatureEnabled) {
                    const { resources: runs } = runsResponse?.json;
                    const printingRun = _find(runs, { operation: RUN_OPERATIONS.PRINTING });
                    const printingRunUri = printingRun && printingRun.uri;
                    if (printingRunUri) {
                      dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_ACTUALS].list(
                        { uri: printingRunUri },
                        // Assuming there is only 1 run-actuals record for each run
                        { limit: 1 },
                      )).then(runActualsResponse => {
                        const { resources: runActuals } = runActualsResponse.json;
                        const runActualsForPrintingRun = _find(runActuals, { run: printingRunUri });
                        const { material_batch: materialBatchUri } = runActualsForPrintingRun || {};
                        if (materialBatchUri) {
                          loadMaterialBatch(materialBatchUri);
                        }
                      });
                    }
                  }
                });
                dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_ACTUALS].list(
                  { run: runUris },
                  // Assuming there is 1-1 relation for run<->runActuals
                  { limit: runUris.length },
                ));
              }

              Promise.all([orderDocumentsRequest, runDocumentsRequest]).then(
                ([orderDocumentsResponse, runDocumentsResponse]) => {
                  const orderDocumentUris = orderDocumentsResponse?.json?.resources.map(
                    document_ => document_.uri,
                  ) ?? [];
                  const runDocumentUris = runDocumentsResponse?.json?.resources.map(
                    document_ => document_.uri,
                  ) ?? [];

                  eventResourceUris.push(
                    lineItemUri,
                    orderUri,
                    ...orderDocumentUris,
                    pieceUri,
                    ...printUris,
                    ...runUris,
                    ...runDocumentUris,
                  );

                  dispatch(Actions.Api.nautilus[API_RESOURCES.EVENT].list(
                    { reference: eventResourceUris },
                    { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
                  ));
                },
              );
            });
        }));

      return Promise.all(promises);
    },
    loadMaterialBatch,
    onPieceUpdate: payload => dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].put(payload.uuid, payload)),
    getUsersBasedOnComments: comments => {
      const usersByComments = _uniq(_map(comments, comment => comment.user)).filter(Boolean);

      if (usersByComments.length) {
        /* As the <Piece /> component is used <ReadOnlyComments />, we should fetch the only users
            related to these comments instead of the whole list of users */
        dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({
          uri: usersByComments,
        }));
      }
    },
  };
}

function mapStateToProps(state) {
  const uuid = Selectors.getRouteUUID(state);
  const piece = Selectors.getRouteUUIDResource(state);
  const users = Selectors.getUsers(state);
  const bureau = Selectors.getBureau(state);
  const events = Selectors.getEventsForPiece(state, piece);
  const lineItem = Selectors.getLineItemForPiece(state, piece);

  const piecesByUri = getPiecesByUri(state);

  const workflow = piece && piece.workflow &&
    Selectors.getUUIDResource(state, extractUuid(piece.workflow));

  const order = piece && Selectors.getUUIDResource(state, extractUuid(piece.order));
  const model = Selectors.getModelForPiece(state, piece);
  const comments = Selectors.getCommentsForPiece(state, piece);

  const printingRun = Selectors.getPrintingRunForPiece(state, piece);
  const runActualsForPrintingRun = Selectors.getRunActualsForRun(state, printingRun);
  const isMaterialManagementFeatureEnabled = Selectors.isFeatureEnabled(
    state,
    FEATURES.MATERIAL_MANAGEMENT,
  );

  const materialBatchUri = runActualsForPrintingRun && runActualsForPrintingRun.material_batch;
  // TODO: need to load dynamically when materialBatchUri was just set
  const materialBatch = isMaterialManagementFeatureEnabled &&
    materialBatchUri &&
    Selectors.getUUIDResource(state, extractUuid(materialBatchUri));

  const { initialFilterByCADReplace } = getRouteParameters();

  return {
    uuid,
    piece,
    workflow,
    order,
    lineItem,
    comments,
    users,
    bureau,
    events,
    model,
    isMaterialManagementFeatureEnabled,
    materialBatch,
    initialFilterByCADReplace,
    materialBatchUri,
    piecesByUri,
  };
}

PieceContainer.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  lineItem: PropTypes.shape({}),
  model: PropTypes.shape({}),
  onInitialize: PropTypes.func.isRequired,
  order: PropTypes.shape({}),
  piece: PropTypes.shape({
    is_line_item_deleted: PropTypes.bool.isRequired,
  }),
  workflow: PropTypes.shape({}),
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  uuid: PropTypes.string.isRequired,
  isMaterialManagementFeatureEnabled: PropTypes.bool.isRequired,
  materialBatch: PropTypes.shape({}),
  materialBatchUri: PropTypes.string,
  comments: PropTypes.arrayOf(PropTypes.shape({
    related_uuid: PropTypes.string,
    related_table: PropTypes.string,
    text: PropTypes.string,
    author_name: PropTypes.string,
    created: PropTypes.string,
    uri: PropTypes.string,
  })).isRequired,
  loadMaterialBatch: PropTypes.func.isRequired,
  getUsersBasedOnComments: PropTypes.func.isRequired,
};

PieceContainer.defaultProps = {
  materialBatch: null,
  materialBatchUri: null,
  lineItem: null,
  workflow: null,
  order: null,
  model: null,
  piece: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(PieceContainer);
