import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  SplitButton,
  Dropdown,
  Row,
  Col,
  FormGroup,
  FormLabel,
  FormControl,
  InputGroup,
  Badge,
  OverlayTrigger,
  Card,
  Tooltip,
  Form as BSForm,
} from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import _some from 'lodash/some';
import {
  ACCESS_INFO_ACTION_TYPES, BUILD_VOLUME_XYZ_MAXIMUM_VALUE,
  FEATURES,
  MANUFACTURING_PROCESSES,
} from 'rapidfab/constants';
import { BUILD_PACKER_TYPES_MAP } from 'rapidfab/mappings';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import ExampleEstimates from 'rapidfab/components/ExampleEstimates';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import Feature from 'rapidfab/components/Feature';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import WorkChecklistContainer from 'rapidfab/containers/records/WorkChecklistContainer';
import { FormattedMessageMappingOption } from 'rapidfab/i18n';
import { FormControlCost, FormControlSelect, identity } from 'rapidfab/components/formTools';
import CostTooltip from 'rapidfab/components/CostTooltip';
import { finalFormInputTypes, printerTypeResourceType } from 'rapidfab/types';
import DisabledByAccessInfoCheck from 'rapidfab/components/DisabledByAccessInfoCheck';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import Loading from 'rapidfab/components/Loading';
import { Form, Field } from 'react-final-form';
import LaborAvailableSchedule from 'rapidfab/components/records/LaborAvailableSchedule';
import LaborDuration from 'rapidfab/components/LaborDuration';
import { isInHoursMinutesFormat } from 'rapidfab/utils/isHoursMinutesFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { BUILD_PACKER_TYPES } from 'rapidfab/constants/resources';

const PrinterTypeForm = ({
  onDelete,
  onFormSubmit,
  manufacturers,
  customGroups,
  materials,
  materialsByUri,
  infillStrategies,
  supportStrategies,
  prosperPrinterMaterials,
  prospers,
  fromTemplate,
  defaultCurrency,
  use100Infill,
  printerType,
  initialFormValues,
  isGroupQualificationsFeatureEnabled,
  submitting,
  handleChangeLaborTime,
  handleSetLaborNonStopTime,
  laborScheduleTime,
  laborNonStopEnabled,
  workScheduleEnabled,
  handleTriggerWorkSchedule,
  handleSetWorkday,
  workDays,
  durationState: {
    isDurationInvalid,
    setIsDurationInvalid,
  },
  UTC_TimeZone,
  handleUTC_TimezoneSetting,
  handleMaterialModeSetting,
  multiMaterialIsActive,
  isPack3dPremiumFeatureEnabled,
}) => (
  <Form
    onSubmit={onFormSubmit}
    initialValues={initialFormValues}
    mutators={{
      toggleEnableLaborEnhancedWorkflow: (_, state, { changeValue }) => {
        const { labor_enhanced_workflow_enabled: initialValue } = state.formState.values;
        changeValue(
          state,
          'labor_enhanced_workflow_enabled',
          () => !initialValue,
        );
        if (!initialValue === false) {
          setIsDurationInvalid(false);
          changeValue(state, 'duration', () => '00:00');
        }
      },
      onChangeValue: ([field, value], state, { changeValue }) => {
        setIsDurationInvalid(false);
        if (!isInHoursMinutesFormat(value)) {
          setIsDurationInvalid(true);
        }

        changeValue(
          state,
          field,
          () => value,
        );
      },
    }}
    render={({ handleSubmit, form, values }) => {
      let constantOverhead = 0;
      if (values.constant_overhead) {
        constantOverhead += Number(values.constant_overhead);
      }
      if (values.overhead_per_line_item) {
        constantOverhead += Number(values.overhead_per_line_item);
      }
      const manufacturingProcessMismatchMaterialUris = values.materials
        ? values.materials.filter(materialUri => {
          const material = materialsByUri[materialUri];
          return (
            values.manufacturing_process &&
              material &&
              material.manufacturing_process &&
              material.manufacturing_process !== values.manufacturing_process
          );
        })
        : [];

      const showManufacturingProcessMismatchTooltip =
        manufacturingProcessMismatchMaterialUris.length > 0;
      const manufacturingProcessMismatchMaterialNames =
        manufacturingProcessMismatchMaterialUris.map(
          materialUri =>
            materialsByUri[materialUri] && materialsByUri[materialUri].name,
        );

      const isBuildVolumeValueIncorrect = value => value && (Number(value) > (BUILD_VOLUME_XYZ_MAXIMUM_VALUE - 2));

      const renderBuildVolume = props => (
        <>
          <FormControl
            {...props.input}
            required
            disabled={fromTemplate}
          />
          {
            props.meta.invalid && (
              <FormLabel className="text-danger mt-1">
                <FormattedMessage
                  id="buildVolumeMaximumValue"
                  defaultMessage="Maximum value is 999, 998 mm"
                />
              </FormLabel>
            )
          }
        </>
      );

      return (
        <form onSubmit={handleSubmit}>
          <Container fluid className="p-b">
            <BreadcrumbNav
              breadcrumbs={[
                'manage',
                'printerTypes',
                initialFormValues?.id
                  ? `${initialFormValues?.name} (${getShortUUID(initialFormValues?.uuid)})`
                  : 'New',
              ]}
            />

            <div className="clearfix">
              <div className="pull-right">
                <DisabledByAccessInfoCheck
                  resourceUri={printerType && printerType.uri}
                  actionType={ACCESS_INFO_ACTION_TYPES.EDIT}
                  tooltipPlacement="bottom"
                  renderDisabledPrefix
                >
                  {({ disabled }) => (
                    <SplitButton
                      id="uxSaveDropdown"
                      type="submit"
                      variant="success"
                      size="sm"
                      title={submitting ? <Loading /> : <SaveButtonTitle />}
                      disabled={disabled || submitting}
                    >
                      <Dropdown.Item
                        eventKey={1}
                        onClick={() => onDelete(initialFormValues?.uuid)}
                        disabled={!initialFormValues?.id}
                      >
                        <Fa name="ban" />{' '}
                        <FormattedMessage
                          id="button.delete"
                          defaultMessage="Delete"
                        />
                      </Dropdown.Item>
                    </SplitButton>
                  )}
                </DisabledByAccessInfoCheck>
              </div>
            </div>

            <hr />

            <Row>
              <Col sm={6}>
                <FormGroup className="form-group" controlId="uxName">
                  <FormLabel>
                    <FormattedMessage id="field.name" defaultMessage="Name" />: *
                  </FormLabel>
                  <Field
                    name="name"
                    type="text"
                    render={props => (
                      <FormControl
                        {...props.input}
                        required
                        disabled={fromTemplate}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxDescription">
                  <FormLabel>
                    <FormattedMessage
                      id="field.description"
                      defaultMessage="Description"
                    />: *
                  </FormLabel>
                  <Field
                    name="description"
                    parse={identity}
                    render={props => (
                      <FormControl
                        {...props.input}
                        as="textarea"
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxBuildVolumeX">
                  <FormLabel>
                    <FormattedMessage
                      id="field.buildVolumeX"
                      defaultMessage="Build Volume X in Millimeters"
                    />
                    : *
                  </FormLabel>
                  <Field
                    name="build_volume.x"
                    type="number"
                    validate={value => isBuildVolumeValueIncorrect(value)}
                    render={props => renderBuildVolume(props)}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxBuildVolumeY">
                  <FormLabel>
                    <FormattedMessage
                      id="field.buildVolumeY"
                      defaultMessage="Build Volume Y in Millimeters"
                    />
                    : *
                  </FormLabel>
                  <Field
                    name="build_volume.y"
                    type="number"
                    validate={value => isBuildVolumeValueIncorrect(value)}
                    render={props => renderBuildVolume(props)}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxBuildVolumeZ">
                  <FormLabel>
                    <FormattedMessage
                      id="field.buildVolumeZ"
                      defaultMessage="Build Volume Z in Millimeters"
                    />
                    : *
                  </FormLabel>
                  <Field
                    name="build_volume.z"
                    type="number"
                    validate={value => isBuildVolumeValueIncorrect(value)}
                    render={props => renderBuildVolume(props)}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxTimeBeforePrint">
                  <FormLabel>
                    <FormattedMessage
                      id="field.time_before_print"
                      defaultMessage="Pre-Run Buffer in Seconds"
                    />
                    : *
                  </FormLabel>
                  <Field
                    name="time_before_print"
                    type="number"
                    render={props => (
                      <FormControl
                        {...props.input}
                        required
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxTimeAfterPrint">
                  <FormLabel>
                    <FormattedMessage
                      id="field.time_after_print"
                      defaultMessage="Post-Run Buffer in Seconds"
                    />
                    : *
                  </FormLabel>
                  <Field
                    name="time_after_print"
                    type="number"
                    render={props => (
                      <FormControl
                        {...props.input}
                        required
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxManufacturer">
                  <FormLabel>
                    <FormattedMessage
                      id="field.manufacturer"
                      defaultMessage="Manufacturer"
                    />
                    : *
                  </FormLabel>
                  <Field
                    type="select"
                    name="manufacturer"
                    render={props => (
                      <FormControlSelect
                        {...props.input}
                        required
                        disabled={fromTemplate}
                      >
                        <option key="placeholder" value="" selected disabled>
                          Select a Manufacturer
                        </option>
                        {manufacturers.map(manufacturer => (
                          <option key={manufacturer.uri} value={manufacturer.uri}>
                            {manufacturer.name}
                          </option>
                        ))}
                      </FormControlSelect>
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group">
                  <Card bg="dark">
                    <Card.Header className="pd-exp inverse">
                      <Row className="d-flex justify-content-between align-items-center">
                        <Col xs={7}>
                          <FormattedMessage
                            id="material.settings"
                            defaultMessage="Material Settings"
                          />
                        </Col>
                        <Col className="p-0">
                          <div className="d-flex align-items-center pull-right">
                            <FormattedMessage
                              id="enableMultiMaterial"
                              defaultMessage="Enable Multiple Materials"
                            />

                            <OverlayTrigger
                              placement="top"
                              overlay={(
                                <Tooltip>
                                  Enable to allow Runs on this machine to contain
                                  Pieces with varying Base Materials.  <br />
                                  When Disabled (default), all Pieces within a Run must have the same Base Material.
                                </Tooltip>
                              )}
                            >
                              <FontAwesomeIcon className="spacer-left" icon={faInfoCircle} />
                            </OverlayTrigger>

                            <BSForm.Check
                              type="switch"
                              id="custom-switch"
                              checked={multiMaterialIsActive}
                              onChange={handleMaterialModeSetting}
                              className="ml15 mr0"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body className="card-body-wrapper">
                      <FormGroup className="form-group" controlId="uxMaterials">
                        <FormLabel>
                          <FormattedMessage id="materials" defaultMessage="Materials" />:
                          *
                          {showManufacturingProcessMismatchTooltip && (
                            <OverlayTrigger
                              placement="top"
                              overlay={(
                                <Tooltip>
                                  {`${manufacturingProcessMismatchMaterialNames.join(
                                    ', ',
                                  )} have Manufacturing Processes that don't match this printer type`}
                                </Tooltip>
                              )}
                            >
                              <Fa className="spacer-left" name="exclamation-circle" />
                            </OverlayTrigger>
                          )}
                        </FormLabel>
                        <Field
                          name="materials"
                          render={props => (
                            <FormControlSelect
                              {...props.input}
                              style={{ height: '14em' }}
                              multiple
                              required
                            >
                              {materials.map(material => (
                                <option key={material.uri} value={material.uri}>
                                  {material.name}
                                </option>
                              ))}
                            </FormControlSelect>
                          )}
                        />
                      </FormGroup>
                    </Card.Body>
                  </Card>
                </FormGroup>

                <FormGroup className="form-group" controlId="uxPackerType">
                  <FormLabel>
                    <FormattedMessage
                      id="field.buildTypes"
                      defaultMessage="Build Packer Type"
                    />
                    : *
                  </FormLabel>
                  <Field
                    name="build_packer_type"
                    render={props => (
                      <FormControlSelect
                        {...props.input}
                        required
                      >
                        {Object.keys(BUILD_PACKER_TYPES_MAP).map(packerType => {
                          if (packerType === BUILD_PACKER_TYPES.PACK3D_PREMIUM && !isPack3dPremiumFeatureEnabled) {
                            return null;
                          }

                          return (
                            <FormattedMessageMappingOption
                              mapping={BUILD_PACKER_TYPES_MAP}
                              value={packerType}
                            />
                          );
                        })}
                      </FormControlSelect>
                    )}
                  />
                </FormGroup>

                <FormGroup className="form-group" controlId="uxMinPackingDistance">
                  <FormLabel>
                    <FormattedMessage
                      id="field.min_packing_distance"
                      defaultMessage="Minimum Packing Distance (mm)"
                    />
                    : *
                  </FormLabel>
                  <InputGroup>
                    <Field
                      name="min_packing_distance"
                      type="number"
                      render={props => (
                        <FormControl
                          {...props.input}
                          min={0}
                          max={100}
                          step="any"
                          required
                        />
                      )}
                    />
                    <InputGroup.Text>mm</InputGroup.Text>
                  </InputGroup>
                </FormGroup>

                <Feature featureName={FEATURES.EXTERNAL_PRODUCTION_ESTIMATE}>
                  {infillStrategies.length > 0 && (
                    <FormGroup className="form-group" controlId="uxMaterials">
                      <FormLabel>
                        <FormattedMessage
                          id="field.infill_strategies"
                          defaultMessage="Infill Strategies"
                        />
                        : *
                      </FormLabel>
                      <Field
                        name="infill_strategies"
                        render={props => (
                          <FormControlSelect
                            {...props.input}
                            multiple
                            required
                          >
                            {infillStrategies.map(infillStrategy => (
                              <option
                                key={infillStrategy.uri}
                                value={infillStrategy.uri}
                              >
                                {infillStrategy.name.toString()}
                              </option>
                            ))}
                          </FormControlSelect>
                        )}
                      />
                    </FormGroup>
                  )}
                </Feature>

                <Feature featureName={FEATURES.EXTERNAL_PRODUCTION_ESTIMATE}>
                  {supportStrategies.length > 0 && (
                    <FormGroup className="form-group" controlId="uxMaterials">
                      <FormLabel>
                        <FormattedMessage
                          id="field.support_strategies"
                          defaultMessage="Support Strategies"
                        />
                        : *
                      </FormLabel>
                      <Field
                        name="support_strategies"
                        render={props => (
                          <FormControlSelect
                            {...props.input}
                            multiple
                            required
                          >
                            {supportStrategies.map(supportStrategy => (
                              <option
                                key={supportStrategy.uri}
                                value={supportStrategy.uri}
                              >
                                {`${supportStrategy.name}`}
                              </option>
                            ))}
                          </FormControlSelect>
                        )}
                      />
                    </FormGroup>
                  )}
                </Feature>
                <FormGroup className="form-group">
                  <FormLabel>
                    <UseNonMfgLanguageFeature
                      mfgLanguageComponent={(
                        <FormattedMessage
                          id="printerCostPerHour"
                          defaultMessage="Printer Cost Per Hour"
                        />
                      )}
                      nonMfgLanguageComponent={(
                        <FormattedMessage
                          id="mfg.printerCostPerHour.productionDeviceCostPerHour"
                          defaultMessage="Production Device Cost Per Hour"
                        />
                      )}
                    />
                    : *
                    <CostTooltip />
                  </FormLabel>
                  <FormControlCost
                    type="number"
                    name="running_cost_per_hour"
                    initialValue={initialFormValues?.running_cost_per_hour}
                    currency={defaultCurrency}
                  />
                </FormGroup>
                <FormGroup className="form-group">
                  <FormLabel>
                    <FormattedMessage
                      id="field.manufacturing_process"
                      defaultMessage="Manufacturing Process"
                    />
                    :
                  </FormLabel>
                  <Field
                    type="select"
                    name="manufacturing_process"
                    render={props => (
                      <FormControlSelect
                        {...props.input}
                      >
                        <option value="">None</option>
                        {MANUFACTURING_PROCESSES.map(process => (
                          <option value={process} key={process}>
                            {process}
                          </option>
                        ))}
                      </FormControlSelect>
                    )}
                  />
                </FormGroup>
                <Feature featureName={FEATURES.WORK_SCHEDULE}>
                  <LaborDuration
                    typesEnabled={false}
                    toggleLaborWorkWorkField={form.mutators.toggleEnableLaborEnhancedWorkflow}
                    isLaborWorkstationEnabled={values.labor_enhanced_workflow_enabled}
                  >
                    <FormGroup className="form-group" controlId="uxLaborDuration">
                      <Row>
                        <Col>
                          <FormLabel>Default Labor Duration:</FormLabel>
                          <OverlayTrigger
                            overlay={(
                              <Tooltip>
                                Labor to Start is the default for printer types.
                              </Tooltip>
                            )}
                          >
                            <FontAwesomeIcon className="spacer-left" icon={faInfoCircle} />
                          </OverlayTrigger>
                          <InputGroup>
                            <Field
                              name="duration"
                              type="text"
                              validate={value => (!isInHoursMinutesFormat(value))}
                              render={fieldProps =>
                                (
                                  <FormControl
                                    {...fieldProps.input}
                                    required
                                    maxLength={6}
                                    onChange={event =>
                                      form.mutators.onChangeValue('duration', event.target.value)}
                                    disabled={!values.labor_enhanced_workflow_enabled}
                                  />
                                )}
                            />

                            <InputGroup.Text>
                              <FormattedMessage
                                id="field.workstation-duration"
                                defaultMessage="HH:MM"
                              />

                            </InputGroup.Text>

                          </InputGroup>
                        </Col>

                      </Row>
                    </FormGroup>
                    {isDurationInvalid && (
                      <div className="text-start text-danger">Please use HH:MM format above.</div>
                    )}
                  </LaborDuration>
                </Feature>
                <Card bg="dark">
                  <Card.Header className="pd-exp inverse">
                    Custom Bureau Cost
                  </Card.Header>
                  <div className="card-body-wrapper">
                    <Card.Body>
                      <Row>
                        <Col xs={6}>
                          <FormGroup>
                            <FormLabel>
                              <UseNonMfgLanguageFeature
                                mfgLanguageComponent={(
                                  <FormattedMessage
                                    id="printerCostScaleFactor"
                                    defaultMessage="Printer Cost Scale Factor"
                                  />
                                )}
                                nonMfgLanguageComponent={(
                                  <FormattedMessage
                                    id="mfg.printerCostScaleFactor.productionDeviceCostScaleFactor"
                                    defaultMessage="Production Device Cost Scale Factor"
                                  />
                                )}
                              />
                              :
                            </FormLabel>
                            <InputGroup>
                              <Field
                                name="print_cost_scale_factor"
                                type="number"
                                render={props => (
                                  <FormControl
                                    {...props.input}
                                  />
                                )}
                              />
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col xs={6}>
                          <UseNonMfgLanguageFeature
                            mfgLanguageComponent={
                              <Badge>* (Print Time * Printer Running Cost)</Badge>
                            }
                            nonMfgLanguageComponent={(
                              <Badge>
                                * (Production Device Time * Production Device
                                Running Cost)
                              </Badge>
                            )}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <FormGroup>
                            <FormLabel>
                              <FormattedMessage
                                id="materialCostScaleFactor"
                                defaultMessage="Material Cost Scale Factor"
                              />
                              :
                            </FormLabel>
                            <InputGroup>
                              <Field
                                name="material_cost_scale_factor"
                                type="number"
                                render={props => (
                                  <FormControl
                                    {...props.input}
                                  />
                                )}
                              />
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col xs={6}>
                          <Badge>* (Material Used * Cost of Material)</Badge>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <FormGroup className="form-group">
                            <FormLabel>
                              <FormattedMessage
                                id="overheadCostPerPiece"
                                defaultMessage="Overhead Cost Per Piece"
                              />
                              :
                            </FormLabel>
                            <FormControlCost
                              type="number"
                              name="constant_overhead"
                              initialValue={initialFormValues?.constant_overhead}
                              currency={defaultCurrency}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6}>
                          <Badge>= Custom Bureau Cost</Badge>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <FormGroup className="form-group">
                            <FormLabel>
                              <FormattedMessage
                                id="setupCost"
                                defaultMessage="Setup Cost (Overhead Cost Per Line-Item)"
                              />
                              :
                            </FormLabel>
                            <FormControlCost
                              type="number"
                              name="overhead_per_line_item"
                              initialValue={initialFormValues?.overhead_per_line_item}
                              currency={defaultCurrency}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Card.Body>
                  </div>
                </Card>
                {prospers.length > 0 &&
                  _some(prosperPrinterMaterials, {
                    printer_name: values.name,
                  }) && (
                  <Badge bg="success">
                    <FormattedMessage
                      id="prosperIsActive"
                      defaultMessage="Linked to Prosper 3D Estimation"
                    />
                  </Badge>
                )}
              </Col>
              <Col sm={6}>
                {values?.uri && (
                  <WorkChecklistContainer workstep={initialFormValues.uri || values.uri} wrapPanel />
                )}
                <Card bg="dark" className="m-b">
                  <Card.Header className="pd-exp inverse">User Groups</Card.Header>
                  <div className="card-body-wrapper">
                    <Card.Body>
                      <FormGroup controlId="uxCustomGroup">
                        <Field
                          name="edit_group"
                          render={props => (
                            <FormControlSelect
                              {...props.input}
                              disabled={!isGroupQualificationsFeatureEnabled}
                            >
                              <FormattedMessage id="all" defaultMessage="All">{text =>
                                <option value="">{text}</option>}
                              </FormattedMessage>
                              {customGroups.map(customGroup => (
                                <option
                                  value={customGroup.uri}
                                  key={customGroup.uuid}
                                >
                                  {customGroup.name}
                                </option>
                              ))}
                            </FormControlSelect>
                          )}
                        />
                      </FormGroup>
                      <p>
                        <UseNonMfgLanguageFeature
                          mfgLanguageComponent={(
                            <FormattedMessage
                              id="printerTypeEditGroupHelperText"
                              defaultMessage="Assigning a Printer Type to a User Group will result in the associated Printers, Runs and Workflows will only be editable by users within the User Group"
                            />
                          )}
                          nonMfgLanguageComponent={(
                            <FormattedMessage
                              id="mfg.printer.printerTypeEditGroupHelperText"
                              defaultMessage="Assigning a Production Device Type to a User Group will result in the associated Production Devices, Runs and Workflows will only be editable by users within the User Group"
                            />
                          )}
                        />
                      </p>
                    </Card.Body>
                  </div>
                </Card>
                <Feature featureName={FEATURES.WORK_SCHEDULE}>
                  <LaborAvailableSchedule
                    handleChange={handleChangeLaborTime}
                    handleSetNonStopTime={handleSetLaborNonStopTime}
                    scheduleTime={laborScheduleTime}
                    nonStopEnabled={laborNonStopEnabled}
                    workSchedule={workScheduleEnabled}
                    setWorkSchedule={handleTriggerWorkSchedule}
                    handleSetWorkday={handleSetWorkday}
                    workDays={workDays}
                    UTC_TimeZone={UTC_TimeZone}
                    setUTC_Timezone={handleUTC_TimezoneSetting}
                  />
                </Feature>
                <ExampleEstimates
                  runningCostPerHour={values.running_cost_per_hour}
                  printCostScaleFactor={values.print_cost_scale_factor}
                  materialCostScaleFactor={values.material_cost_scale_factor}
                  constantOverhead={constantOverhead}
                  use100Infill={use100Infill}
                />
              </Col>
            </Row>
          </Container>
        </form>
      );
    }}
  />
);

PrinterTypeForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  customGroups: PropTypes.arrayOf(PropTypes.shape({})),
  materials: PropTypes.arrayOf(PropTypes.shape({})),
  materialsByUri: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      manufacturing_process: PropTypes.string,
      uri: PropTypes.string,
    }),
  ),
  input: finalFormInputTypes.isRequired,
  printerType: printerTypeResourceType,
  infillStrategies: PropTypes.arrayOf(PropTypes.shape({})),
  supportStrategies: PropTypes.arrayOf(PropTypes.shape({})),
  prosperPrinterMaterials: PropTypes.arrayOf(PropTypes.shape({})),
  prospers: PropTypes.arrayOf(PropTypes.shape({})),
  manufacturers: PropTypes.arrayOf(PropTypes.shape({})),
  onDelete: PropTypes.func.isRequired,
  fromTemplate: PropTypes.bool,
  defaultCurrency: PropTypes.string.isRequired,
  use100Infill: PropTypes.bool.isRequired,
  initialFormValues: PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
    manufacturer: PropTypes.string,
    build_packer_type: PropTypes.string,
    material: PropTypes.string,
    infill_strategy: PropTypes.string,
    support_strategy: PropTypes.string,
    running_cost_per_hour: PropTypes.number,
    print_cost_scale_factor: PropTypes.number,
    material_cost_scale_factor: PropTypes.number,
    constant_overhead: PropTypes.number,
    overhead_per_line_item: PropTypes.number,
    edit_group: PropTypes.string,
    manufacturing_process: PropTypes.string,
    support_strategies: PropTypes.arrayOf(PropTypes.string),
    infill_strategies: PropTypes.arrayOf(PropTypes.string),
    prosper_printer_materials: PropTypes.arrayOf(PropTypes.string),
    min_packing_distance: PropTypes.number,
    build_packing_distance: PropTypes.number,
    materials: PropTypes.arrayOf(PropTypes.string),
    time_after_print: PropTypes.number,
    time_before_print: PropTypes.number,
    build_volume: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      z: PropTypes.number,
    }),
    description: PropTypes.string,
    id: PropTypes.string,
    uuid: PropTypes.string,
    flow_time: PropTypes.number,
    flow_time_queued: PropTypes.number,
  }).isRequired,
  isGroupQualificationsFeatureEnabled: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleChangeLaborTime: PropTypes.func.isRequired,
  handleSetLaborNonStopTime: PropTypes.func.isRequired,
  laborScheduleTime: PropTypes.shape({}).isRequired,
  laborNonStopEnabled: PropTypes.bool.isRequired,
  workScheduleEnabled: PropTypes.bool.isRequired,
  handleTriggerWorkSchedule: PropTypes.func.isRequired,
  isDurationInvalid: PropTypes.shape({
    flow_time: PropTypes.bool,
    flow_time_queued: PropTypes.bool,
  }).isRequired,
  onChangeDurationValue: PropTypes.shape({}).isRequired,
  handleSetWorkday: PropTypes.func.isRequired,
  workDays: PropTypes.arrayOf(PropTypes.string).isRequired,
  durationState: PropTypes.shape({
    isDurationInvalid: PropTypes.bool,
    setIsDurationInvalid: PropTypes.func,
  }).isRequired,
  handleUTC_TimezoneSetting: PropTypes.func.isRequired,
  UTC_TimeZone: PropTypes.number.isRequired,
  meta: PropTypes.shape({
    invalid: PropTypes.bool,
  }).isRequired,
  handleMaterialModeSetting: PropTypes.func.isRequired,
  multiMaterialIsActive: PropTypes.bool.isRequired,
  isPack3dPremiumFeatureEnabled: PropTypes.bool.isRequired,
};

PrinterTypeForm.defaultProps = {
  materials: [],
  customGroups: [],
  materialsByUri: {},
  manufacturers: [],
  infillStrategies: [],
  supportStrategies: [],
  prosperPrinterMaterials: [],
  prospers: [],
  fromTemplate: false,
  printerType: null,
};

export default PrinterTypeForm;
