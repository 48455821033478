import React from 'react';
import { Button, FormControl, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormRow from 'rapidfab/components/FormRow';
import { FormattedMessage } from 'rapidfab/i18n';
import { Field, Form } from 'react-final-form';
import Loading from 'rapidfab/components/Loading';
import _isEmpty from 'lodash/isEmpty';
import {
  ROUTES,
} from 'rapidfab/constants';
import extractUuid from 'rapidfab/utils/extractUuid';
import getRouteURI from 'rapidfab/utils/getRouteURI';

const HandleCreateOrderModal = ({
  show,
  onClose,
  handleCreateOrder,
  defaultFormValues,
  createOrderFetching,
  isRestrictedUser,
}) => (
  <Modal show={show}>
    <Form
      onSubmit={async formValues => {
        const orderResponseData = await handleCreateOrder(formValues.order_name, formValues.order_quantity);
        if (orderResponseData) {
          window.location = isRestrictedUser ?
            getRouteURI(ROUTES.ORDER_RESTRICTED_EDIT, { uuid: extractUuid(orderResponseData.orderUri) }) :
            getRouteURI(ROUTES.ORDER_EDIT, { uuid: extractUuid(orderResponseData.orderUri) });
          onClose();
        }
      }}
      initialValues={defaultFormValues}
      validate={formValues => {
        const errors = {};
        if (_isEmpty(formValues.order_name)) errors.order_name = 'Required';
        if (formValues.order_quantity === undefined) errors.order_quantity = 'Required';
        else if (formValues.order_quantity > 999) errors.order_quantity = 'Value too large';
        else if (formValues.order_quantity < 1) errors.order_quantity = 'Value too small';
        return errors;
      }}
      render={({ handleSubmit }) => (
        <>
          <Modal.Header onHide={onClose} closeButton>
            <FormattedMessage
              id="modelLibrary.createOrder"
              defaultMessage="Create Order"
            />
          </Modal.Header>
          <Modal.Body>
            <form
              id="createOrderModalForm"
              onSubmit={handleSubmit}
            >
              <FormRow id="orderName" defaultMessage="Order Name">
                <Field
                  name="order_name"
                  render={({ input, meta }) => (
                    <div>
                      <FormControl
                        placeholder="My Order"
                        value={input.value}
                        type="text"
                        onChange={input.onChange}
                        required
                      />
                      {meta.error && meta.touched && <span className="mt-1 text-danger">{meta.error}</span>}
                    </div>
                  )}
                />
              </FormRow>
              <FormRow id="modelLibrary.pieceQuantity" defaultMessage="Quantity">
                <Field
                  name="order_quantity"
                  type="number"
                  defaultValue={1}
                  render={({ input, meta }) => (
                    <div>
                      <FormControl
                        value={input.value}
                        type="number"
                        max={9999}
                        min={1}
                        defaultValue={1}
                        onChange={input.onChange}
                        required
                      />
                      {meta.error && meta.touched && <span className="mt-1 text-danger">{meta.error}</span>}
                    </div>
                  )}
                />
              </FormRow>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={handleSubmit}
              disabled={createOrderFetching}
            >
              {createOrderFetching ? <Loading /> : 'Save Order'}
            </Button>
          </Modal.Footer>
        </>
      )}
    />
  </Modal>
);

export default HandleCreateOrderModal;

HandleCreateOrderModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleCreateOrder: PropTypes.func.isRequired,
  defaultFormValues: PropTypes.shape({}).isRequired,
  createOrderFetching: PropTypes.bool.isRequired,
  isRestrictedUser: PropTypes.bool.isRequired,
};
