import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import _map from 'lodash/map';
import _compact from 'lodash/compact';
import _uniq from 'lodash/uniq';
import { API_RESOURCES, RUN_STATUSES } from 'rapidfab/constants';
import {
  getUUIDResource,
  getLineItemsForOrder,
  getPrintingProcessStepForLineItem,
  getEarliestAvailablePrinterForLineItem, getRuns, getRunEstimates,
} from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';
import { orderResourceType } from 'rapidfab/types';
import Loading from '../../../components/Loading';

const OrderLikelyCompletionDate = ({
  order,
  lineItems,
  loadPrinters,
  lineItemPrinterTypeUris,
  earliestAvailablePrintersForLineItemsByLineItemUri,
  runs,
  piecesScheduled,
  runEstimates,
  orderPieces,
  isFetching,
  // loadActuals,
}) => {
  // Commented until Run Actuals on BE is working:
  // const [stateActuals, setStateActuals] = useState(false);
  const [latestRunScheduledDate, setLatestRunScheduledDate] = useState(null);

  useEffect(() => {
    loadPrinters(lineItemPrinterTypeUris);
  },
  // JSON.stringify prevents useEffect to run on any re-render with `array` passed as a dependency
  // https://github.com/facebook/react/issues/14476#issuecomment-471199055
  [JSON.stringify(lineItemPrinterTypeUris)],
  );
  const calculateLastEstimatedRun = () => {
    const endDates = runEstimates.map(run => dayjs(run.estimates.end));
    const latestEndDate = dayjs.max(endDates);

    if (latestEndDate.isValid()) {
      return latestEndDate;
    }
    return null;
  };

  // Commented until Run Actuals on BE is working:

  // useEffect(() => {
  //   if (runs.length && !stateActuals) {
  //     const runsURI = runs.map(currentRun => currentRun.uri);
  //     loadActuals(runsURI);
  //     // _forEach(run, currentRun => {
  //     //   loadActuals(currentRun.uri);
  //     // });
  //     // loadScheduled();
  //     setStateActuals(true);
  //   }
  // }, [runs, stateActuals]);

  useEffect(() => {
    if (runEstimates?.length && !latestRunScheduledDate) {
      const latestRunDate = calculateLastEstimatedRun();
      setLatestRunScheduledDate(latestRunDate);
    }
  }, [runEstimates, latestRunScheduledDate]);
  if (isFetching) {
    return <Loading />;
  }
  const likelyCompletionMomentForLineItems = _compact(
    lineItems.map(lineItem => {
      if (!lineItem || !lineItem.estimates) {
        return null;
      }

      const completionDays = lineItem.estimates.flow_time_interval;

      const printer = earliestAvailablePrintersForLineItemsByLineItemUri[lineItem.uri];
      const startMoment = (printer && printer.available_at)
        ? dayjs(printer.available_at)
        : dayjs();

      return startMoment.add(completionDays, 'day');
    }),
  );

  const likelyCompletionMomentForOrder =
    likelyCompletionMomentForLineItems.length && dayjs.max(likelyCompletionMomentForLineItems);
  let likelyCompletionDate = '--------------';
  // let likelyCompletionBg = 'success';
  let likelyCompletionBg = 'default';

  if (likelyCompletionMomentForOrder) {
    const allPiecesScheduledCompleted = (piecesScheduled === orderPieces.length);
    const runsComplete = runs.filter(run => run.status === RUN_STATUSES.COMPLETE).length;
    const totalRuns = runs ? runs.length : 0;
    const isComplete = totalRuns === runsComplete && piecesScheduled === orderPieces.length;
    const isOrderCompleted = allPiecesScheduledCompleted && isComplete;
    const isOverDueByLikelyCompletionDate = (order.due_date && likelyCompletionMomentForOrder) && (likelyCompletionMomentForOrder.diff(dayjs(order.due_date), 'days')) > 0;
    const isOverDueByLatestScheduledPiece = (order.due_date && latestRunScheduledDate) && (latestRunScheduledDate.diff(dayjs(order.due_date), 'days')) > 0;
    const isOrderOverDue = isOverDueByLikelyCompletionDate || isOverDueByLatestScheduledPiece;

    if ((isOrderOverDue && isOrderCompleted)) {
      likelyCompletionBg = 'danger';
    } else if ((isOrderOverDue && !isOrderCompleted && allPiecesScheduledCompleted)) {
      likelyCompletionBg = 'warning';
    } else if ((allPiecesScheduledCompleted && (isOrderCompleted && !isOrderOverDue)) ||
      (allPiecesScheduledCompleted && (!isOrderCompleted && !isOrderOverDue))) {
      // add if all pieces scheduled and before deadline
      likelyCompletionBg = 'success';
    } else {
      likelyCompletionBg = 'default';
    }

    if (piecesScheduled) {
      likelyCompletionDate = likelyCompletionMomentForOrder.format('YYYY-MM-DD');
    }
  }

  const orderDueDate = order.due_date || '--------------';

  return (
    <>
      <div className={`order-status-number ${likelyCompletionBg}`} style={{ padding: '0' }}>
        <div style={{ padding: '5px 10px' }}>
          <div>{orderDueDate}</div>
          <div>{likelyCompletionDate}</div>
        </div>
      </div>
      <div>
        <div>Order Due Date</div>
        <div>Likely Completion Date</div>
      </div>
    </>
  );
};

OrderLikelyCompletionDate.propTypes = {
  // Used in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  orderUUID: PropTypes.string.isRequired,
  order: orderResourceType.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  earliestAvailablePrintersForLineItemsByLineItemUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  lineItemPrinterTypeUris: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  loadPrinters: PropTypes.func.isRequired,
  // loadActuals: PropTypes.func.isRequired,
  runs: PropTypes.instanceOf(Array).isRequired,
  piecesScheduled: PropTypes.number.isRequired,
  runEstimates: PropTypes.instanceOf(Array).isRequired,
  orderPieces: PropTypes.instanceOf(Array).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => ({
  loadPrinters: lineItemPrinterTypeUris => {
    // Load Printers by printer type, since not all process steps have `workstation` specified
    // So, line item can be run on any workstation of the printer type
    if (lineItemPrinterTypeUris.length) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list({
        printer_type: lineItemPrinterTypeUris,
      }));
    }
  },
  // Commented until Run Actuals on BE is working:
  // loadActuals: runURI => dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_ACTUALS].list({ run: runURI })),
  // loadScheduled: () => dispatch(Actions.Api.nautilus.schedule_runs.list()),
});

const mapStateToProps = (state, { orderUUID }) => {
  const order = getUUIDResource(state, orderUUID);
  const lineItems = getLineItemsForOrder(state, order);
  const lineItemPrintingProcessSteps = _map(lineItems, lineItem => getPrintingProcessStepForLineItem(state, lineItem));

  const lineItemPrinterTypeUris = _uniq(
    _compact(
      _map(lineItemPrintingProcessSteps, 'workstation_type_uri'),
    ),
  );

  const earliestAvailablePrintersForLineItemsByLineItemUri = {};

  lineItems.forEach(lineItem => {
    earliestAvailablePrintersForLineItemsByLineItemUri[lineItem.uri] =
      getEarliestAvailablePrinterForLineItem(state, lineItem);
  });

  const runs = getRuns(state);
  const runEstimates = getRunEstimates(state);
  return {
    order,
    lineItems,
    lineItemPrinterTypeUris,
    earliestAvailablePrintersForLineItemsByLineItemUri,
    runs,
    runEstimates,
  };
};

const areStatePropsEqual = (next, previous) => JSON.stringify(previous) === JSON.stringify(next);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null, { areStatePropsEqual },
)(OrderLikelyCompletionDate);
