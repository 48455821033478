import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';
import { Tabs, Tab, FormControl, Col, Row, Button } from 'react-bootstrap';
import TextAreaAutocomplete from 'rapidfab/components/forms/TextAreaAutocomplete';
import { COMMENT_CHARACTER_LIMIT, FEATURES } from 'rapidfab/constants';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import MarkdownComponent from 'rapidfab/components/comments/Markdown';
import findEmails from 'rapidfab/utils/findEmails';
import NonHawkingFeature from 'rapidfab/components/hawking/NonHawkingFeature';
import { isFeatureEnabled } from 'rapidfab/selectors';
import { useSelector } from 'react-redux';
import 'rapidfab/styles/hawking/main.scss';

import './styles.scss';
import UserSearcher from './UserSearcher';

const FORM_TABS = {
  PREVIEW: 'preview',
  WRITE: 'write',
};

const CommentForm = ({ usersByUsername, isSubmitting, onSubmit, fetchAllUsers }) => {
  const isHawkingUser = useSelector(state => isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT));
  const texatareaRef = React.createRef();
  const [activeTab, setActiveTab] = useState(FORM_TABS.WRITE);
  const userPairs = [];
  const usernames = Object.keys(usersByUsername);

  usernames.forEach(username => userPairs.push({
    username,
    name: usersByUsername[username].name,
  }));

  const [comment, setComment] = useState('');
  const [mentionedUsers, setMentionedUsers] = useState([]); // array of user objects
  const [assignedUser, setAssignedUser] = useState(null); // user object, null if not assigned

  const overCharacterLimit = comment.length > COMMENT_CHARACTER_LIMIT;
  const saveButtonDisabled = _isEmpty(comment) || isSubmitting || overCharacterLimit;

  // disable the italic, bold, link buttons if on preview view
  const disableFormattingButtons = activeTab === FORM_TABS.PREVIEW;

  useEffect(() => {
    const foundMentionedEmails = findEmails(comment);

    if (!_isEqual(_map(mentionedUsers, 'username'), foundMentionedEmails)) {
      // Filter object by key with mentioned users and then convert to array
      const foundMentionedUsers = Object.values(_pick(usersByUsername, foundMentionedEmails));
      setMentionedUsers(foundMentionedUsers);
    }

    if (assignedUser && !foundMentionedEmails.includes(assignedUser.username)) {
      setAssignedUser(null);
    }
  }, [comment]);

  const onChangeComment = event => setComment(event.target.value);
  const onSelectNewTab = newActiveTab => setActiveTab(newActiveTab);

  const handleSelectUser = username => {
    const node = texatareaRef?.current;

    if (node && node.selectionStart && node.selectionEnd) {
      const start = node.selectionStart;
      const end = node.selectionEnd;
      const newComment = `${comment.slice(0, start)}@${username}${comment.slice(end)}`;

      setComment(newComment);
    } else {
      setComment(`${comment} **@${username}**`);
    }
  };

  // TODO Maybe add focus to textarea when button is clicked?
  const onClickLink = () => !disableFormattingButtons && setComment(`${comment}[Link Name](URL)`);
  const onClickItalic = () => !disableFormattingButtons && setComment(`${comment}_italics_`);
  const onClickBold = () => !disableFormattingButtons && setComment(`${comment}**bold**`);

  const onCancel = () => setComment('');
  const onSave = event => {
    // This component can be rendered is some forms (order form),
    // we must be confident that parent form is not submitted
    event.preventDefault();

    const payload = { text: comment };
    if (assignedUser) {
      payload.comment_action = { assignee_user: assignedUser.uri };
    }

    if (mentionedUsers.length) {
      // Correct payload: [{uri: ...}, ...]
      payload.mentioned_users = _map(mentionedUsers, step => _pick(step, ['uri']));
    }

    onSubmit(payload).then(() => {
      // Another fields will be updated by comment text change. See useEffect for comment change.
      setComment('');
    });
  };

  return (
    <>
      <Row>
        <Col md={6} xs={12}>
          {
            mentionedUsers.length > 1 && (
              <FormControl
                as="select"
                name="assigned_user"
                onChange={({ target }) => setAssignedUser(usersByUsername[target.value])}
                value={assignedUser ? assignedUser.username : ''}
                required
              >
                <option value="" defaultValue disabled>
                  Assignee
                </option>
                {mentionedUsers.map(mentionedUser => (
                  <option value={mentionedUser.username} key={mentionedUser.username}>
                    {`${mentionedUser.name} (${mentionedUser.username})`}
                  </option>
                ))}
              </FormControl>
            )
          }
          {
            mentionedUsers.length === 1 && (
              <div className="text-right">
                {
                  assignedUser ? (
                    <>
                      Assigned to {assignedUser.name}
                      <Button className={`m-l ${isHawkingUser && 'hawking-secondary'}`} size="sm" variant={!isHawkingUser && 'danger'} onClick={() => setAssignedUser(null)}>Unassign</Button>
                    </>
                  )
                    : (
                      <>
                        Assign to {mentionedUsers[0].name}?
                        <Button className="m-l" size="sm" variant="primary" onClick={() => setAssignedUser(mentionedUsers[0])}>Assign</Button>
                      </>
                    )
                }
              </div>
            )
          }

        </Col>
        <Col md={6} xs={12}>
          <div className="tabs-container">
            <NonHawkingFeature>
              <UserSearcher
                suggestions={userPairs}
                handleChange={handleSelectUser}
                fetchAllUsers={fetchAllUsers}
              />
              <Fa name="link" onClick={onClickLink} />
              <Fa name="italic" onClick={onClickItalic} />
              <Fa name="bold" onClick={onClickBold} />
            </NonHawkingFeature>
            <Tabs
              activeKey={activeTab}
              onSelect={onSelectNewTab}
              id="commentsTabs"
            >
              <Tab eventKey={FORM_TABS.WRITE} title="Write" />
              <Tab eventKey={FORM_TABS.PREVIEW} title="Preview" />
            </Tabs>
          </div>
        </Col>
      </Row>
      {
        activeTab === FORM_TABS.WRITE && (
          <>
            <TextAreaAutocomplete
              placeholder="Add a comment..."
              type="text"
              id="comment"
              name="comment"
              value={comment}
              onChange={onChangeComment}
              suggestions={userPairs}
              ref={texatareaRef}
              fetchAllUsers={fetchAllUsers}
            />
            <span className="info-wrapper">
              <div>Markdown formatting supported, use @ to notify other users of your comment</div>
              <div className={`char-count ${overCharacterLimit ? 'char-count-err' : ''}`}>
                {`${comment.length} / ${COMMENT_CHARACTER_LIMIT}`}
              </div>
            </span>
          </>
        )
      }
      {
        activeTab === FORM_TABS.PREVIEW && (
          <div className="preview-container">
            {
              _isEmpty(comment)
                ? <div className="no-preview">Nothing to preview</div>
                : <MarkdownComponent source={comment} />
            }
          </div>
        )
      }
      <div className="buttons">
        <Button
          variant={!isHawkingUser && 'danger'}
          size="sm"
          className={isHawkingUser && 'hawking-secondary'}
          disabled={_isEmpty(comment) || isSubmitting}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={saveButtonDisabled}
          variant={!isHawkingUser && 'success'}
          className={isHawkingUser && 'hawking-primary'}
          size="sm"
          type="submit"
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </>
  );
};

CommentForm.defaultProps = {
  fetchAllUsers: () => {},
};

CommentForm.propTypes = {
  usersByUsername: PropTypes.shape({}).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetchAllUsers: PropTypes.func,
};

export default CommentForm;
