import React from 'react';
import PropTypes from 'prop-types';
import LineItem from './LineItem';

const LineItems = ({
  lineItems,
  readOnly,
  expandMode,
  setExpandedItems,
  expandedItems,
  setExpandMode,
  productIndex,
}) => (
  <div>
    {lineItems.map(lineItem => (
      <LineItem
        key={lineItem}
        formKey={lineItem}
        uri={lineItem}
        readOnly={readOnly}
        expandMode={expandMode}
        setExpandedItems={setExpandedItems}
        expandedItems={expandedItems}
        setExpandMode={setExpandMode}
        productIndex={productIndex}
      />
    ))}
  </div>
);

LineItems.defaultProps = {
  readOnly: false,
};

LineItems.propTypes = {
  lineItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  expandMode: PropTypes.oneOfType([null, PropTypes.string]).isRequired,
  setExpandedItems: PropTypes.func.isRequired,
  setExpandMode: PropTypes.func.isRequired,
  expandedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  productIndex: PropTypes.number.isRequired,
};

export default LineItems;
