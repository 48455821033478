import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FEATURES, API_RESOURCES } from 'rapidfab/constants';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import GuidelinesEngine from 'rapidfab/components/admin/GuidelinesEngine';
import * as Selectors from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import Actions from 'rapidfab/actions';
import getEndpointFromUri from 'rapidfab/utils/getEndpointFromURI';
import { FormattedMessage } from 'react-intl';

const GuidelinesEngineContainer = props => {
  const dispatch = useDispatch();

  const isHawkingUser = useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT));

  const onDelete = data => {
    const { uuid, uri } = data;
    const endpoint = getEndpointFromUri(uri);

    switch (endpoint.endpointName) {
      case 'for-work-instruction':
        dispatch(Actions.Api.nautilus[API_RESOURCES.FOR_WORK_INSTRUCTION].delete(uuid))
          .then(() => Alert.success(
            <FormattedMessage
              id="toaster.guidelinesEngine.deletedSuccessfully"
              defaultMessage="Deleted guideline {uuid}."
              values={{ uuid }}
            />,
          ));
        break;
      case 'for-process-step':
        dispatch(Actions.Api.nautilus[API_RESOURCES.FOR_PROCESS_STEP].delete(uuid))
          .then(() => Alert.success(
            <FormattedMessage
              id="toaster.guidelinesEngine.deletedSuccessfully"
              defaultMessage="Deleted guideline {uuid}."
              values={{ uuid }}
            />,
          ));
        break;
      default:
        Alert.error(
          <FormattedMessage
            id="toaster.error.guidelinesEngine.unableToDelete"
            defaultMessage="Unable to delete resource {uuid}."
            values={{ uuid }}
          />,
        );
        break;
    }
  };

  const selected = {
    isHawkingUser,
    onDelete,
  };

  return (
    <GuidelinesEngine
      {...props}
      {...selected}
    />
  );
};

export default withRecordsListHandling(
  GuidelinesEngineContainer,
  Selectors.getGuidelines,
  ['guideline/for-work-instruction', 'guideline/for-process-step'],
  { defaultSort: '-name' },
);

GuidelinesEngineContainer.propTypes = {
  onInitialize: PropTypes.func.isRequired,
  labels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
