import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormLabel,
  FormControl,
  FormGroup,
  Modal,
} from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';

const OrderReport = ({
  end,
  handleChange,
  handleHide,
  handleShow,
  isUserRestricted,
  downloadData,
  show,
  start,
}) => (
  <div
    style={{ display: isUserRestricted ? 'none' : null, marginRight: '0.5em' }}
  >
    <Button variant="primary" size="sm" onClick={handleShow}>
      <FormattedMessage
        id="record.csv.download"
        defaultMessage="Download CSV Order Report"
      />
    </Button>
    <Modal show={show} onHide={handleHide}>
      <Modal.Header closeButton>
        <FormattedMessage
          id="CSVOrderReport"
          defaultMessage="CSV Order Report"
        />
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <FormLabel>
            <FormattedMessage id="startDate" defaultMessage="Start Date" />
          </FormLabel>
          <FormControl
            name="start"
            onChange={handleChange}
            pattern="^\d{4}-\d{2}-\d{2}$"
            placeholder="yyyy-mm-dd"
            type="date"
            value={start}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>
            <FormattedMessage id="endDate" defaultMessage="End Date" />
          </FormLabel>
          <FormControl
            name="end"
            onChange={handleChange}
            pattern="^\d{4}-\d{2}-\d{2}$"
            placeholder="yyyy-mm-dd"
            type="date"
            value={end}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          onClick={downloadData}
          disabled={!start || !end}
        >
          <FormattedMessage id="button.download" defaultMessage="Download" />
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
);

OrderReport.propTypes = {
  end: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleHide: PropTypes.func.isRequired,
  handleShow: PropTypes.func.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  downloadData: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  start: PropTypes.string.isRequired,
};

export default OrderReport;
