import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

export const getPostProcessorTypes = createSelector(
  [baseStateSelectors.getStatePostProcessorTypes, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getPostProcessorTypesByUri = createSelector(
  [getPostProcessorTypes],
  postProcessorTypes => _keyBy(postProcessorTypes, 'uri'),
);
