import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import ReviewOrder from 'rapidfab/components/records/order/restricted/review/ReviewOrder';
import Loading from 'rapidfab/components/Loading';
import { loadOrderRelatedUsers } from 'rapidfab/dispatchers/order';
import { API_RESOURCES } from 'rapidfab/constants';

const ReviewRestrictedOrder = () => {
  const routeUUID = useSelector(Selectors.getRouteUUID);
  const bureau = useSelector(Selectors.getBureauUri);
  const order = useSelector(state => Selectors.getUUIDResource(state, routeUUID));

  const dispatch = useDispatch();
  useEffect(() => {
    // Fetch order and related resources
    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(routeUUID)).then(response => {
      const currentOrder = response?.json;
      if (currentOrder) {
        loadOrderRelatedUsers(dispatch, currentOrder);
      }
    });

    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list({ include_custom_workflows: true }));
  }, []);

  const loading = !order || !routeUUID;

  return (
    <div>
      {loading ? <Loading /> : <ReviewOrder />}
    </div>
  );
};

ReviewRestrictedOrder.propTypes = {
  route: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  order: PropTypes.shape({}).isRequired,
};

export default ReviewRestrictedOrder;
