import React from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Container, OverlayTrigger, Tooltip, Row } from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';

import Table from 'rapidfab/components/Tables/Table';

const Conversions = ({ conversions, fetching }) => {
  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'conversion',
    },
    {
      type: 'text',
      uid: 'field.name',
      accessor: 'currency',
      defaultMessage: 'Name',
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'field.multiplier',
      accessor: 'value',
      defaultMessage: 'Currency Multiplier',
      isSortable: true,
    },

    {
      type: 'custom',
      uid: 'field.defaultCurrency',
      accessor: 'is_bureau_default',
      defaultMessage: 'Default Currency',
      Cell: ({ value }) => (
        <div className="d-flex align-items-center">
          <Fa name={value ? 'check-square-o' : 'square-o'} />
          {value && (
            <div>
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip>
                    <FormattedMessage
                      id="defaultCurrencyTooltip"
                      defaultMessage="The bureau currency setting is how orders are stored and displayed by default. Selecting other currencies will change the display only. To apply a new conversion rate to a past order, contact Authentise."
                    />
                  </Tooltip>
                )}
              >
                <Fa name="question-circle" className="spacer-left" />
              </OverlayTrigger>
            </div>
          )}
        </div>
      ),
    },
  ];
  return (
    <>
      <Container fluid>
        <BreadcrumbNav breadcrumbs={['manage', 'currencies']} />
        <Row>
          <Col xs={12}>
            <Button
              variant="primary"
              size="sm"
              href={getRouteURI(ROUTES.CONVERSION_NEW)}
              className="pull-right"
            >
              <Fa name="plus" />{' '}
              <FormattedMessage
                id="record.currency.add"
                defaultMessage="Add Currency Conversion"
              />
            </Button>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col xs={12}>
            {fetching ? (
              <Loading />
            ) : (
              <Table
                tableID="conversions"
                data={conversions}
                columns={columns}
                isFilteringEnabled
                withDefaultPagination
                initialSortedDesc={false}
                initialSortedColumn="currency"
                isManualSoringEnabled={false}
                isUpdatedColumnShown={false}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

Conversions.propTypes = {
  conversions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.string.isRequired,
};

export default Conversions;
