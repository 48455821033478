import { createSelector } from 'reselect';
import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import _filter from 'lodash/filter';

// eslint-disable-next-line import/prefer-default-export
export const getMaterialLots = createSelector(
  [baseStateSelectors.getStateMaterialLots, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getMaterialLotsForStock = createSelector(
  [getPredicate, getMaterialLots],
  (stock, lots) => {
    if (!stock) {
      return [];
    }
    return _filter(lots, lot => stock.material_lots?.includes(lot.uri));
  },
);
