import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import getQRAppUri from 'rapidfab/utils/getQRAppUri';
import QRSticker from 'rapidfab/components/qr/_wrapper';

const Printer = ({ printer, printerType, location, materials }) => (
  <QRSticker>
    <div>Printer Type: {printerType.name || 'N/A'}</div>
    <div>Printer Name: {printer.name}</div>

    <div className="qr">
      <a href={getQRAppUri(printer.uri)} target="_blank" rel="noopener noreferrer">
        <QRCode value={getQRAppUri(printer.uri)} renderAs="svg" />
      </a>
    </div>

    <div>Location: {location.name || 'N/A'}</div>
    <div>Material Types: {materials.length
      ? materials.map(material => material.name).join(', ')
      : 'N/A'}
    </div>
  </QRSticker>
);

Printer.propTypes = {
  printer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  printerType: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  materials: PropTypes.arrayOf({
    name: PropTypes.string,
  }).isRequired,
};

export default Printer;
