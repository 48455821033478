import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Actuals from 'rapidfab/components/records/order/edit/Actuals';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, USER_HIDE_INFO_TYPES } from 'rapidfab/constants';
import {
  getCurrentUserRoleMax,
  getLineItemActualsForLineItem,
  getProcessStepActualsForLineItemByProcessStepUri,
  getProcessStepsForLineItem,
  getUUIDResource,
} from 'rapidfab/selectors';
import Loading from 'rapidfab/components/Loading';
import extractUuid from 'rapidfab/utils/extractUuid';

const ActualsContainer = props => {
  const { lineItemUri } = props;
  const lineItem = useSelector(state => getUUIDResource(state, extractUuid(lineItemUri)));
  const lineItemActuals = useSelector(state => getLineItemActualsForLineItem(state, lineItem));
  const processSteps = useSelector(state => getProcessStepsForLineItem(state, lineItem));
  const actualsByProcessStepUri = useSelector(state =>
    getProcessStepActualsForLineItemByProcessStepUri(state, lineItem));
  const role = useSelector(getCurrentUserRoleMax);
  const hideFinancial = role?.hide_info === USER_HIDE_INFO_TYPES.FINANCIAL;
  const loading = useSelector(state => state.ui.nautilus[API_RESOURCES.LINE_ITEM_ACTUALS].list.fetching);

  const dispatch = useDispatch();
  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_ACTUALS].list(
      { line_item: lineItemUri },
      // Assuming there is 1-1 relation for line-item-actuals <-> line-item
      { limit: 1 },
    ));
  };

  useEffect(() => {
    onInitialize();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (!actualsByProcessStepUri) {
    return <span>No Actuals yet.</span>;
  }

  return (
    <Actuals
      processSteps={processSteps}
      actualsByProcessStepUri={actualsByProcessStepUri}
      lineItemActuals={lineItemActuals}
      hideFinancial={hideFinancial}
    />
  );
};

ActualsContainer.propTypes = {
  // Used in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  lineItemUri: PropTypes.string.isRequired,

};

export default ActualsContainer;
