import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { injectIntl } from 'react-intl';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import {
  Col,
  Container, Row,
} from 'react-bootstrap';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import extractUuid from 'rapidfab/utils/extractUuid';
import { MATERIAL_BATCH_ACTION_TYPES_MAP } from 'rapidfab/mappings';
import { MATERIAL_BATCH_ACTION_TYPES, ROUTES, MATERIAL_BATCH_STATUSES } from 'rapidfab/constants';
import { getMaterialBatchActionQuantity } from 'rapidfab/utils/materialBatchAction';
import { materialBatchResourceType } from 'rapidfab/types/resources';

const MaterialBatchGenealogy = ({
  batch,
  lot,
  family,
  intl,
}) => {
  const treeSettings = {
    node: {
      height: 50,
      width: 150,
      colors: {
        mainBackground: '#252830',
        actionBackground: '#1CA8DD',
        batchBackground: '#f8f9fa',
        vertexPath: '#17a2b8',
        batchText: '#212529',
        actionText: '#ffffff',
        consumedBatch: '#434857',
      },
    },
    spacing: {
      y: 200,
      yOffset: 5,
      vertexText: {
        yOffset: 10,
      },
      vertextTextBackground: {
        yOffset: 30,
      },
    },
    margin: {
      top: 40,
      right: 90,
      bottom: 50,
      left: 90,
    },
  };

  const svgRef = useRef();
  const genealogyWrapper = useRef();

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [fullWidth, setFullWidth] = useState(0);

  const getVertexQuantity = item => {
    // When current item is a batch, quantity needs to be searched for in parent action
    // (e.g. Initial Batch Creation action, Split action)
    const currentData = item.data;
    const parentData = item.parent && item.parent.data;
    const currentAction = currentData.isBatch ? parentData : currentData;
    return getMaterialBatchActionQuantity(currentAction);
  };

  useEffect(() => {
    const updatedBreadcrumbs = ['materialBatches'];
    if (!batch.is_initial_batch) {
      updatedBreadcrumbs.push(
        {
          message: `${lot.name} (${getShortUUID(batch.initial_batch)})`,
          href: batch.initial_batch ?
            getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(batch.initial_batch) }) :
            getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: batch.uuid }),
        },
        {
          message: getShortUUID(batch.uri),
          href: getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(batch.uri) }),
        },
      );
    } else {
      updatedBreadcrumbs.push({
        message: `${lot.name} (${getShortUUID(batch.uri)})`,
        href: getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(batch.uri) }),
      });
    }
    updatedBreadcrumbs.push('Genealogy');
    setBreadcrumbs(updatedBreadcrumbs);
  }, [batch]);

  useEffect(() => {
    // declares a tree layout and set Node Size and separation between nodes
    const treemap = d3.tree()
      .separation((a, b) => (a.parent === b.parent ? 1.1 : 1.2))
      .nodeSize([treeSettings.node.width, treeSettings.node.height]);
    //  assigns the data to a hierarchy using parent-child relationships in the initial family array
    const treeHierarchyConstructor = d3.stratify().id(d => d.name).parentId(d => d.parent);
    const hierarchicalTreeData = treeHierarchyConstructor(family);

    // maps the node data to the tree layout
    const nodes = treemap(hierarchicalTreeData);

    // Get the svg object of the container of the page
    const svg = d3.select(svgRef.current);

    // Clear previously created tree (if any), for cases when svg content is re-rendered
    svg.attr('html', '');

    // appends a 'group' element to 'svg' to group all nodes and position them
    const nodesGroup = svg.append('g');

    let maxDepth = 0;
    let maxNegativeX = 0;
    let maxPositiveX = 0;

    // Normalize for fixed-depth by Y coordinate
    nodes.each(d => {
      // eslint-disable-next-line no-param-reassign
      d.y = d.depth * treeSettings.spacing.y;
      maxDepth = d.depth > maxDepth ? d.depth : maxDepth;

      maxNegativeX = Math.max(maxNegativeX, (-1 * d.x));

      maxPositiveX = Math.max(maxPositiveX, d.x);
    });

    const treeWidth =
      maxNegativeX +
      maxPositiveX +
      treeSettings.node.width +
      treeSettings.margin.left +
      treeSettings.margin.right;

    setFullWidth(treeWidth);

    const translateNodesGroup = {
      // Move all negative parts right (from the origin) adding half of box width and left margin
      x: maxNegativeX + (treeSettings.node.width / 2) + treeSettings.margin.left,
      y: treeSettings.margin.top,
    };

    nodesGroup.attr('width', treeWidth)
      .attr('transform', `translate(${translateNodesGroup.x},${translateNodesGroup.y})`);

    svg
      // Set Width of SVG to fit max nodes width (distance between min negative and max positive X)
      .style('width', treeWidth)
      // Set Height of SVG to fit maxDepth nodes
      .style('height', (maxDepth * treeSettings.spacing.y) + treeSettings.margin.top + treeSettings.margin.bottom);

    // Add the links between the nodes. Create group for link and percentage
    const link = nodesGroup.selectAll('.link')
      .data(nodes.descendants().slice(1))
      .enter()
      .append('g');

    // Add actual path linking
    link.append('path')
      .attr('class', 'link')
      .attr('stroke', treeSettings.node.colors.vertexPath)
      .attr('stroke-width', '3px')
      .attr('fill', 'transparent')
      .attr('d', d => {
        // Calculate points for Bezier Curve based on node box size and distance between them
        const xStart = d.parent.x;
        const yStart = d.parent.y + (treeSettings.node.height / 2);
        const xFinish = d.x;
        const yFinish = d.y - (treeSettings.node.height / 2);
        const yHalfWay = (yFinish + yStart) / 2;
        return `M${xFinish},${yFinish}C${xFinish},${yHalfWay} ${xStart},${yHalfWay} ${xStart},${yStart}`;
      });

    // Create background box around path text to prevent path lines from obscuring text
    link.append('rect')
      .attr('y', d => {
        const yStart = d.parent.y - treeSettings.spacing.vertextTextBackground.yOffset;
        const yFinish = d.y;
        const yHalfWay = (yFinish + yStart) / 2;
        return yHalfWay;
      })
      .attr('x', d => {
        const xStart = d.parent.x - (treeSettings.node.width / 2);
        const xFinish = d.x;
        const xHalfWay = (xFinish + xStart) / 2;
        return xHalfWay;
      })
      .style('fill', d => {
        const currentQuantity = getVertexQuantity(d);
        if (!currentQuantity) {
          // When there is no Quantity provided (e.g. for Test action) - hide the box too
          return 'transparent';
        }
        return treeSettings.node.colors.mainBackground;
      })
      // TODO: Width/height should be based on the text dimensions
      .attr('width', treeSettings.node.width / 2)
      .attr('height', treeSettings.node.height / 2);

    // Add `units` text on each path linking at the center of path
    link.append('text')
      .attr('y', d => {
        const yStart =
          d.parent.y + treeSettings.spacing.vertexText.yOffset + (treeSettings.node.height / 2);
        const yFinish = d.y - ((treeSettings.node.height / 2) + treeSettings.spacing.yOffset);
        const yHalfWay = (yFinish + yStart) / 2;
        return yHalfWay;
      })
      .attr('x', d => {
        const xStart = d.parent.x;
        const xFinish = d.x;
        const xHalfWay = (xFinish + xStart) / 2;
        return xHalfWay;
      })
      .style('fill', treeSettings.node.colors.actionText)
      .style('text-anchor', 'middle')
      .text(d => {
        const currentQuantity = getVertexQuantity(d);
        return currentQuantity ? `${currentQuantity.toFixed()} ${batch.units}` : '';
      });

    // Add each node as a group
    const node = nodesGroup.selectAll('.node')
      .data(nodes.descendants())
      .enter().append('g')
      .attr('class', d => `node${d.children ? ' node--internal' : ' node--leaf'}`)
      .attr('transform', d => `translate(${d.x},${d.y})`);

    // Create box for each Node with border
    node.append('rect')
      // Fill Color is based on Vertex type - action OR batch
      .style('fill', d => (d.data.isAction
        ? treeSettings.node.colors.actionBackground
        : ((d.data.status === MATERIAL_BATCH_STATUSES.DONE
          && d.data.quantity > 0)
          ? treeSettings.node.colors.consumedBatch
          : treeSettings.node.colors.batchBackground)),
      )
      .attr('y', d => {
        const { action_type: actionType } = d.data.relatedAction || {};

        switch (actionType) {
          case MATERIAL_BATCH_ACTION_TYPES.BLEND_BATCHES:
          case MATERIAL_BATCH_ACTION_TYPES.MACHINE_TOP_OFF:
            return -1 * (treeSettings.node.height / 2);
          default:
            return -1 * ((treeSettings.node.height / 2) + treeSettings.spacing.yOffset);
        }
      })
      .attr('x', -1 * (treeSettings.node.width / 2))
      .attr('width', treeSettings.node.width)
      .attr('height', treeSettings.node.height);

    // Add additional data into the box. Valid for related `Blend`/`Top Off` actions at the moment
    node.append('text')
      .style('fill', d => (d.data.isAction
        ? treeSettings.node.colors.actionText
        : ((d.data.status === MATERIAL_BATCH_STATUSES.DONE
          && d.data.quantity > 0)
          ? treeSettings.node.colors.actionText
          : treeSettings.node.colors.batchText)),
      )
      .style('text-anchor', 'middle')
      .style('font-size', '12px')
      .attr('y', 20)
      .text(d => {
        const { action_type: actionType } = d.data.relatedAction || {};
        switch (actionType) {
          case MATERIAL_BATCH_ACTION_TYPES.BLEND_BATCHES:
            return `Blended with ${getShortUUID(d.data.relatedAction.source_batch)}`;
          case MATERIAL_BATCH_ACTION_TYPES.MACHINE_TOP_OFF:
            return `TopUp Batch ${getShortUUID(d.data.relatedAction.metadata.batch_to_load)}`;
          default:
            return '';
        }
      });

    // Make each Node Description a link (<a href>)
    const nodeLink = node.append('a').attr(
      'href',
      d => {
        const { isAction, uuid, source_batch: sourceBatch } = d.data;
        const routeParams = { uuid };
        let queryParams;
        if (isAction) {
          queryParams = { traceabilityReport: 'true' };
          routeParams.uuid = extractUuid(sourceBatch);
        }
        return getRouteURI(ROUTES.MATERIAL_BATCH, routeParams, queryParams);
      },
    );

    // Add the description text to each node - Batch UUID / Action Type Name
    nodeLink.append('text')
      .style('fill', d => (d.data.isAction
        ? treeSettings.node.colors.actionText
        : ((d.data.status === MATERIAL_BATCH_STATUSES.DONE
          && d.data.quantity > 0)
          ? treeSettings.node.colors.actionText
          : treeSettings.node.colors.batchText)),
      )
      .style('text-anchor', 'middle')
      .text(d => {
        const { isAction, name, action_type: actionType } = d.data;
        if (isAction) {
          // Show Action type Mapped value for Actions
          return intl.formatMessage(MATERIAL_BATCH_ACTION_TYPES_MAP[actionType]);
        }
        // Or Batch short UUID for Batches
        return `Batch ${name}`;
      });

    // Horizontal Scroll in a way to show initial batch on the center of the screen
    const treeWidthWindowWidthOverhang = treeWidth - window.innerWidth;
    const rootXOffsetToCenter = treeWidthWindowWidthOverhang / 2;
    if (rootXOffsetToCenter > 0) window.scroll(rootXOffsetToCenter, 0);
  }, [family]);

  return (
    <Container style={{ width: fullWidth, minWidth: '100%' }}>
      <BreadcrumbNav
        breadcrumbs={breadcrumbs}
      />

      <Row>
        <Col xs={12}>
          <div ref={genealogyWrapper}>
            <svg style={{ display: 'flex', margin: 'auto' }} ref={svgRef} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

MaterialBatchGenealogy.propTypes = {
  batch: materialBatchResourceType.isRequired,
  lot: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  family: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(MaterialBatchGenealogy);
