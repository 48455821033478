import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import AddProduct, { EXTENSIONS } from 'rapidfab/components/AddProduct';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, DESIGN_FILE_TYPES, FEATURES, MAX_FILE_SIZE, PRODUCT_TYPES } from 'rapidfab/constants';
import * as Selectors from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import readableFileSize from 'rapidfab/utils/readableFileSize';
import { FormattedMessage } from 'react-intl';

const AddProductContainer = props => {
  const uploadModel = useSelector(Selectors.getUploadModel);
  const restrictedUploadModelLibraryOnlyFeature = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.RESTRICTED_USER_UPLOAD_FROM_MODEL_LIBRARY_ONLY));
  const isUserRestricted = useSelector(Selectors.isCurrentUserRestricted);
  const isCADToSTLConversionFeatureEnabled = useSelector(
    state => Selectors.isFeatureEnabled(state, FEATURES.NATIVE_CAD_TO_STL_CONVERSION),
  );
  const isAnatomicalModelToProductFeatureEnabled = useSelector(
    state => Selectors.isFeatureEnabled(state, FEATURES.ANATOMICAL_MODEL_TO_PRODUCT),
  );

  const selected = {
    uploadModel,
    restrictedUploadModelLibraryOnlyFeature,
    isUserRestricted,
    isCADToSTLConversionFeatureEnabled,
    isAnatomicalModelToProductFeatureEnabled,
  };
  const dispatch = useDispatch();

  const submitProduct = extraPayload =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRODUCT].post({
      order: props.orderUri,
      type: PRODUCT_TYPES.SINGLE_MESH,
      ...extraPayload,
    }));
  const onNoModelUploadSubmit = () =>
    submitProduct({ no_model_upload: true });

  const onFromModelLibrarySubmit = modelLibraryUri =>
    submitProduct({ model_library: modelLibraryUri });

  const onDesignFileSubmit = async (file, extension) => {
    if (file?.size > MAX_FILE_SIZE) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.designFile.maxFileSizeExceeded"
          defaultMessage="Allowed max file {maxFileSize} size exceeded (received file size is {fileSize})."
          values={{ maxFileSize: readableFileSize(MAX_FILE_SIZE), fileSize: readableFileSize(file.size) }}
        />,
      );
      Alert.error(
        <FormattedMessage
          id="toaster.error.designFile.maxFileSizeExceeded"
          defaultMessage="Allowed max file {maxFileSize} size exceeded (received file size is {fileSize})."
          values={{ maxFileSize: readableFileSize(MAX_FILE_SIZE), fileSize: readableFileSize(file.size) }}
        />,
      );
      return null;
    }
    let productType = PRODUCT_TYPES.SINGLE_MESH;
    let designFileType = DESIGN_FILE_TYPES.SINGLE_MESH;
    if (extension === EXTENSIONS.ZIP) {
      // ZIP is for Anatomical Model type only for now
      productType = PRODUCT_TYPES.ANATOMICAL_MODEL;
      designFileType = DESIGN_FILE_TYPES.ANATOMICAL_MODEL;
    }
    const productResponse = await submitProduct({ type: productType });

    const { location: productUri } = productResponse.headers;

    const designFileResponse = await dispatch(
      Actions.Api.nautilus[API_RESOURCES.DESIGN_FILE].post({
        name: file.name,
        product: productUri,
        type: designFileType,
        format: extension,
      }),
    );
    const { uploadLocation, location: designFileUri } = designFileResponse.headers;
    if (designFileUri) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].list({ design_file: designFileUri }, {}, {}, {}, true));
    }
    dispatch(Actions.UploadModel.uploadProgress(0));
    dispatch(Actions.UploadModel.upload(uploadLocation, file));
    return designFileResponse;
  };

  const dispatched = { onNoModelUploadSubmit, onFromModelLibrarySubmit, onDesignFileSubmit };

  return (
    <AddProduct
      {...props}
      {...selected}
      {...dispatched}
    />
  );
};

AddProductContainer.propTypes = {
  orderUri: PropTypes.string.isRequired,
  lineItemsLength: PropTypes.number.isRequired,
};

export default AddProductContainer;
