import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, Button } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import RunMaterialToggle from 'rapidfab/components/records/run/RunMaterialToggle';

const SelectPiecesPanelHeader = ({
  showSearch,
  toggleChecked,
  onToggleChange,
  fetching,
  handleAddPieces,
  isDisabledAddPiecesButton,
  isQuickFillButtonDisabled,
  selectLabel,
  handleSearchSubmit,
  handleSearchValue,
  searchValue,
  selectedItemCount,
  handleQuickFill,
}) => (
  <div className="vm-panel-header fixed-select-pieces-panel">
    <span>{selectLabel}</span>

    <ButtonToolbar className="pull-right">
      <RunMaterialToggle
        checked={toggleChecked}
        onChange={onToggleChange}
        disabled={fetching}
      />
    </ButtonToolbar>

    { showSearch && (
      <>
        <ButtonToolbar className="pull-right">
          <Button
            size="sm"
            variant="success"
            onClick={handleSearchSubmit}
          >
            <FormattedMessage
              id="record.run.search"
              defaultMessage="Search"
            />
          </Button>
        </ButtonToolbar>
        <form
          onSubmit={handleSearchSubmit}
          className="form-group form-group-sm react-bs-table-search-form pull-right m-b-0 mr15"
          style={{ maxWidth: 212 }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchValue}
          />
        </form>
      </>
    )}

    <span className="pull-right mr30">
      <Button
        className="mr15"
        size="sm"
        variant="primary"
        onClick={() => handleQuickFill()}
        disabled={isQuickFillButtonDisabled}
      >
        <FormattedMessage
          id="button.quickFill"
          defaultMessage="Quick Fill"
        />
      </Button>
      <Button
        size="sm"
        variant="success"
        onClick={handleAddPieces}
        disabled={isDisabledAddPiecesButton}
      >
        <FormattedMessage
          id="record.run.addPieces"
          defaultMessage="{count, plural, =0 {Add Pieces} one {Add # Piece} other {Add # Pieces}}"
          values={{ count: selectedItemCount }}
        />
      </Button>
    </span>
  </div>
);

SelectPiecesPanelHeader.defaultProps = {
  showSearch: false,
  handleSearchSubmit: () => {},
  handleSearchValue: () => {},
  searchValue: '',
};

SelectPiecesPanelHeader.propTypes = {
  showSearch: PropTypes.bool,
  toggleChecked: PropTypes.bool.isRequired,
  onToggleChange: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  handleAddPieces: PropTypes.func.isRequired,
  isDisabledAddPiecesButton: PropTypes.bool.isRequired,
  selectLabel: PropTypes.string.isRequired,
  handleSearchSubmit: PropTypes.func,
  handleSearchValue: PropTypes.func,
  searchValue: PropTypes.string,
  selectedItemCount: PropTypes.number.isRequired,
  handleQuickFill: PropTypes.func.isRequired,
  isQuickFillButtonDisabled: PropTypes.bool.isRequired,
};

export default SelectPiecesPanelHeader;
