import React, { useState, useContext } from 'react';
import { Row, Col, FormControl, Card, Modal, Button, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ColorCode } from 'rapidfab/components/GuidelinesEngine/GuidelineSummaryBlock';
import {
  COLORS,
  INITIAL_WORK_INSTRUCTION_STATE,
  WORK_INSTRUCTION_REPORT_TYPES,
  GUIDELINES_ENGINE_ACTION_TYPES,
  GUIDELINES_EXPRESSION_FIELD_TYPES,
} from 'rapidfab/constants';
import PropTypes from 'prop-types';
import GuidelineAddWorkInstruction from 'rapidfab/components/GuidelinesEngine/GuidelineAddWorkInstruction';
import _clone from 'lodash/clone';
import {
  WORK_INSTRUCTION_REPORT_TYPE_MAP,
} from 'rapidfab/mappings';
import { FormattedMessage } from 'rapidfab/i18n';
import Fa from 'react-fontawesome';
import GuidelineContext from 'rapidfab/context/GuidelineContext';

const WorkInstructionListRowItem = ({
  action,
  onEditWorkInstruction,
  onDeleteWorkInstruction,
  ...restProps
}) => (
  <Card className="mt15" bg="dark">
    <Card.Header className="pd-exp inverse">
      <i>{restProps.processStepsByProcessTypeUri[action.target_process_step]?.name ?? 'Please select an Action Location'}</i>
    </Card.Header>
    <div className="card-body-wrapper">
      <Card.Body>
        <Row>
          <Col>
            <p>{action.data?.description}</p>
          </Col>
          <Col>
            {
              action.data?.report_type &&
            action.data?.report_type !== WORK_INSTRUCTION_REPORT_TYPES.NO_ENTRY &&
              (
                <Badge bg="default" className="badge-sm pull-right">
                  <FormattedMessage
                    {...WORK_INSTRUCTION_REPORT_TYPE_MAP[action.data?.report_type]}
                  />{' '}
                  <FormattedMessage
                    id="reportType"
                    defaultMessage="Report Type"
                  />
                </Badge>
              )
            }
          </Col>
          <Col>
            {
              action.data?.required &&
              (
                <Badge bg="danger" className="badge-sm">
                  <FormattedMessage
                    id="field.required"
                    defaultMessage="Required"
                  />
                </Badge>
              )
            }
          </Col>
          <Col>

            <div className="pull-right">
              <Fa role="button" onClick={onEditWorkInstruction} name="edit" size="lg" />
              {/* 👇🏼 Not in v1.0 */}
              {/* <Fa
                role="button"
                onClick={onDeleteWorkInstruction}
                className="spacer-left"
                name="times"
                size="lg"
              /> */}
            </div>

          </Col>
        </Row>
      </Card.Body>
    </div>
  </Card>
);

const WorkflowStepListRowItem = ({ action, ...restProps }) => (
  <Card className="mt15" bg="dark">
    <Card.Header className="pd-exp inverse">
      <i>{restProps.guidelineExpressions.action.data?.name ?? 'Please select an Action Location'}</i>
    </Card.Header>
    <Card.Body />
  </Card>
);

const AddWorkInstructionModal = props => {
  const {
    id,
    show,
    closeModal,
    addWorkInstruction,
    workInstruction,
  } = props;
  const [newWorkInstruction, setNewWorkInstruction] =
    useState(workInstruction ? _clone(workInstruction) : INITIAL_WORK_INSTRUCTION_STATE);

  const onChangeWorkInstruction = (modifiedKey, state) => {
    const mutatedWorkInstruction = _clone(newWorkInstruction);

    // Clear threshold fields when threshold type is changed
    if (modifiedKey === 'threshold_type') {
      mutatedWorkInstruction.threshold = null;
    }

    if (modifiedKey === 'report_type') {
      if (state.value === WORK_INSTRUCTION_REPORT_TYPES.NO_ENTRY) {
        // Unset this field as required as nothing is expected here
        mutatedWorkInstruction.required = false;
      }

      if (
        ![WORK_INSTRUCTION_REPORT_TYPES.NUMBER, WORK_INSTRUCTION_REPORT_TYPES.RANGE]
          .includes(state.value)
      ) {
        // Unset unit field when work instruction is not Number of Range
        mutatedWorkInstruction.report_units = null;
      }

      if (
        ![WORK_INSTRUCTION_REPORT_TYPES.TEXT, WORK_INSTRUCTION_REPORT_TYPES.NUMBER]
          .includes(mutatedWorkInstruction.value)
      ) {
        // Unset thresholds fields when work instruction is not Text or Number
        mutatedWorkInstruction.threshold = null;
        mutatedWorkInstruction.threshold_type = null;
        mutatedWorkInstruction.threshold_action = null;
        mutatedWorkInstruction.threshold_notification = null;
      }
    }

    mutatedWorkInstruction[modifiedKey] = state.value;
    setNewWorkInstruction(mutatedWorkInstruction);
  };

  return (
    <Modal show={show} onHide={closeModal} backdrop="static">
      <Modal.Header>Add New Work Instruction</Modal.Header>
      <Modal.Body className="w-100">
        <GuidelineAddWorkInstruction
          isEditable
          instruction={newWorkInstruction}
          onChange={onChangeWorkInstruction}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={closeModal}>
          Close
        </Button>
        <Button variant="success" onClick={() => addWorkInstruction(id, newWorkInstruction)}>
          Save Work Instruction
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const GuidelineThenBlock = () => {
  const guidelineContext = useContext(GuidelineContext);

  const {
    uuid,
    guidelineExpressions,
    guidelineExpressionValues,
    selectedActionType: { selectedActionType, setSelectedActionType },
    addWorkInstructionModalState: { addWorkInstructionModalState, setAddWorkInstructionModalState },
    processStepsByUri,
    processStepsByProcessTypeUri,
    processSteps,
  } = guidelineContext;

  const uniqueProcessStepsByName = processSteps
    .filter((processStep, index, self) => (
      index === self.findIndex(x => (
        x.name === processStep.name
      ))
    ));

  const saveWorkInstruction = (_, newWorkInstruction) => {
    setAddWorkInstructionModalState({ ...addWorkInstructionModalState, show: false });
    guidelineContext.dispatchGuidelineExpressionsAction({
      type: 'GUIDELINE_EXPRESSIONS_ACTION_DATA_CHANGE',
      payload: newWorkInstruction,
    });
  };

  const handleActionDropdownChange = event => {
    guidelineContext.dispatchGuidelineExpressionsAction({
      type: 'GUIDELINE_EXPRESSIONS_ACTION_TYPE_CHANGE',
      payload: event.target.value,
    });

    setSelectedActionType(null);
    switch (event.target.value) {
      case '':
        break;
      case GUIDELINES_ENGINE_ACTION_TYPES.ADD_WORK_INSTRUCTION:
        setSelectedActionType(GUIDELINES_ENGINE_ACTION_TYPES.ADD_WORK_INSTRUCTION);
        setAddWorkInstructionModalState({ show: true, id: 0 });
        break;
      case GUIDELINES_ENGINE_ACTION_TYPES.ADD_WORKFLOW_STEP:
        setSelectedActionType(GUIDELINES_ENGINE_ACTION_TYPES.ADD_WORKFLOW_STEP);
        break;
      default:
        throw new Error('Unknown dropdown selection.');
    }
  };

  const handleProcessStepChange = event => {
    guidelineContext.dispatchGuidelineExpressionsAction({
      type: 'GUIDELINE_EXPRESSIONS_ACTION_LOCATION_CHANGE',
      payload: event.target.value,
    });

    guidelineContext.dispatchGuidelineExpressionsAction({
      type: 'GUIDELINE_EXPRESSIONS_ACTION_DATA_CHANGE',
      payload: processStepsByProcessTypeUri[event.target.value],
    });
  };

  const isActionDropdownDisabled = Object.values(guidelineExpressionValues).every(value => value === null) ||
    (guidelineExpressions.expressions.length > 1 && guidelineExpressions.join === null);

  const selectedWorkflowSteps = guidelineExpressions.expressions.filter(expression => (
    expression.field_type?.value === GUIDELINES_EXPRESSION_FIELD_TYPES.PROCESS_STEP && expression.value?.uri !== null
  ));

  const { target_process_step: targetProcessStep } = guidelineExpressions.action ?? '';

  const alreadyExistingSavedActionLocation = processStepsByProcessTypeUri[targetProcessStep]?.workstation_type_uri;

  return (
    <>
      <AddWorkInstructionModal
        id={addWorkInstructionModalState.id}
        show={addWorkInstructionModalState.show}
        closeModal={() => setAddWorkInstructionModalState({ show: false, id: null })}
        addWorkInstruction={(id, newWorkInstruction) => saveWorkInstruction(id, newWorkInstruction)}
      />
      <Row className="spacer-top">
        <Col className="d-flex align-items-center">
          <ColorCode color={COLORS.ORANGE} className="spacer-right" />
          <FormControl
            value={guidelineExpressions?.action?.type}
            required
            as="select"
            onChange={handleActionDropdownChange}
            disabled={isActionDropdownDisabled}
          >
            <option key={null} value="">
              Action
            </option>
            <option key="add_work_instruction" value="add_work_instruction">Add Work Instruction</option>
            <option key="add_workflow_step" value="add_workflow_step">Add Workflow Step</option>
          </FormControl>
        </Col>
        <Col className="d-flex align-items-center">
          <ColorCode color={COLORS.RED} className="spacer-right" />
          {selectedActionType === null && (
            <FormControl
              disabled
              as="select"
              onChange={null}
            >
              <option key={null} value="">
                Please select an action
              </option>
            </FormControl>
          )}
          {selectedActionType === GUIDELINES_ENGINE_ACTION_TYPES.ADD_WORK_INSTRUCTION && (
            <OverlayTrigger
              show={selectedWorkflowSteps.length === 0}
              placement="top"
              overlay={(
                <Tooltip>
                  <p>Please select at least one workflow step in the if conditions section.</p>
                </Tooltip>
              )}
            >
              <FormControl
                value={guidelineExpressions?.action?.target_process_step}
                as="select"
                onChange={event =>
                  guidelineContext.dispatchGuidelineExpressionsAction({
                    type: 'GUIDELINE_EXPRESSIONS_ACTION_LOCATION_CHANGE',
                    payload: event.target.value,
                  })}
              >
                <option key="" value="">
                  Workflow Step
                </option>
                {uuid ? targetProcessStep && guidelineExpressions.expressions.map(() => (
                  <option
                    key={processStepsByProcessTypeUri[targetProcessStep]?.uuid}
                    value={processStepsByProcessTypeUri[targetProcessStep]?.workstation_type_uri}
                  >
                    {processStepsByProcessTypeUri[targetProcessStep]?.name}
                  </option>
                )) : guidelineExpressions.expressions.map(expression => (
                  processStepsByUri[expression.value?.uri] && (
                    <option
                      key={processStepsByUri[expression.value.uri]?.uuid}
                      value={processStepsByUri[expression.value.uri].workstation_type_uri}
                    >
                      {processStepsByUri[expression.value.uri].name}
                    </option>
                  )
                ))}
              </FormControl>
            </OverlayTrigger>
          )}
          {selectedActionType === GUIDELINES_ENGINE_ACTION_TYPES.ADD_WORKFLOW_STEP && (
            <FormControl
              value={alreadyExistingSavedActionLocation}
              disabled={selectedActionType === null}
              required
              as="select"
              onChange={handleProcessStepChange}
            >
              <option key={null} value="">
                Action Location
              </option>
              {uniqueProcessStepsByName.map(processStep => (
                <option key={processStep.uri} value={processStep.workstation_type_uri}>
                  {processStep.name}
                </option>
              ))}
            </FormControl>
          )}
        </Col>
        {guidelineExpressions.action?.type === 'add_work_instruction' && (
          <WorkInstructionListRowItem
            action={guidelineExpressions.action}
            onEditWorkInstruction={() =>
              setAddWorkInstructionModalState({ show: true, id: guidelineExpressions.action.id })}
            onDeleteWorkInstruction={() => guidelineContext.dispatchGuidelineExpressionsAction({
              type: 'GUIDELINE_EXPRESSIONS_ACTION_DELETE',
              payload: guidelineExpressions.id,
              // uuid,
            })}
            {...guidelineContext}
          />
        )}
        {guidelineExpressions.action?.type === 'add_workflow_step' &&
          <WorkflowStepListRowItem action={guidelineExpressions.action} {...guidelineContext} />}
      </Row>
    </>
  );
};

export default GuidelineThenBlock;

AddWorkInstructionModal.propTypes = {
  id: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  addWorkInstruction: PropTypes.func.isRequired,
  workInstruction: PropTypes.shape({}).isRequired,
};

WorkInstructionListRowItem.propTypes = {
  workflow_step: PropTypes.shape({}).isRequired,
  onEditWorkInstruction: PropTypes.func.isRequired,
  onDeleteWorkInstruction: PropTypes.func.isRequired,
  action: PropTypes.shape({
    work_instruction: PropTypes.string,
    target_process_step: PropTypes.string,
    data: PropTypes.shape({
      description: PropTypes.string,
      report_type: PropTypes.string,
      required: PropTypes.bool,
    }),
  }).isRequired,
};

WorkflowStepListRowItem.propTypes = {
  action: PropTypes.shape({
    target_process_step: PropTypes.string,
    workflow_step: PropTypes.shape({}),
  }).isRequired,
};

GuidelineThenBlock.propTypes = {
  ruleActions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      work_instruction: PropTypes.shape({}),
    }),
  ).isRequired,
  setRuleActions: PropTypes.func.isRequired,
  resourceItemsMapping: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  resourceItemsAll: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
