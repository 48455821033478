import React, { useEffect } from 'react';
import _concat from 'lodash/concat';
import _filter from 'lodash/filter';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import Queues from 'rapidfab/components/work/Queues';
import * as Selectors from 'rapidfab/selectors';
import {
  API_RESOURCES,
  LOCATION_FILTER_DEFAULT_VALUES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  FEATURES,
} from 'rapidfab/constants';

const QueuesContainer = props => {
  const events = useSelector(Selectors.getQueueEvents);
  const postProcessor = useSelector(state => state.ui.nautilus[API_RESOURCES.POST_PROCESSOR]);
  const blockMachine = useSelector(state => state.ui.nautilus[API_RESOURCES.DOWNTIME]);
  const { printer, location, run } = useSelector(state => state.ui.nautilus);
  const runs = useSelector(Selectors.getRuns);
  const runsByUri = useSelector(Selectors.getRunsByUri);
  const machines = useSelector(Selectors.getMachinesForQueues);
  const locationFilter = useSelector(Selectors.getLocationFilter);
  const buildsByRunUri = useSelector(Selectors.getBuildsByRunUri);
  const settings = useSelector(Selectors.getPreferencesUserSettings);
  const downtimesByUri = useSelector(Selectors.getDowntimesByUri);
  const isGeneralMFGLanguageEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.GENERAL_MFG_LANGUAGE));
  const isWorkScheduleFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.WORK_SCHEDULE));
  const workSchedulesByPostProcessorType = useSelector(Selectors.getWorkSchedulesByPostProcessorType);
  const workSchedulesByPrinterType = useSelector(Selectors.getWorkSchedulesByPrinterType);
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);

  let filteredMachines = null;
  if (
    typeof locationFilter !== 'undefined'
    && locationFilter !== LOCATION_FILTER_DEFAULT_VALUES.ALL
  ) {
    filteredMachines = _filter(machines, ['location', locationFilter]);
  }

  const selected = {
    events,
    machines: filteredMachines || machines,
    runs,
    runsByUri,
    buildsByRunUri,
    settings,
    apiErrors: _concat(
      blockMachine.errors,
      run.list.errors,
      postProcessor.list.errors,
      printer.list.errors,
      location.list.errors,
    ),
    downtimesByUri,
    workSchedulesByPostProcessorType,
    workSchedulesByPrinterType,
    isGeneralMFGLanguageEnabled,
    isDebugModeEnabled,
  };

  const dispatch = useDispatch();

  const handleScheduleRuns = runURI => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].post(
      { run: runURI },
    ));
  };
  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].list(null, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    // downtime endpoint has no pagination limit by default,
    // so no PAGINATION_IGNORE_DEFAULT_LIMIT is needed
    dispatch(Actions.Api.nautilus[API_RESOURCES.DOWNTIME].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    // schedule_runs endpoint has no pagination limit by default,
    // so no PAGINATION_IGNORE_DEFAULT_LIMIT is needed
    dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].list(
      null, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT * 2 }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_ACTUALS].list(null, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    /* Ignoring the limit; `/work-schedule` endpoint doesn't support filtering
    based on URI ARRAYS, once this is implemented, this can be optimised. */
    if (isWorkScheduleFeatureEnabled) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_SCHEDULE].list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    }
  };
  const onUserSettingsUpdate = updatedUserSettings =>
    dispatch(Actions.Preferences.setUserSettings(updatedUserSettings));

  useEffect(() => onInitialize(), []);

  const dispatched = { handleScheduleRuns, onUserSettingsUpdate };

  return (
    <Queues
      {...props}
      {...selected}
      {...dispatched}
    />
  );
};

export default QueuesContainer;
