import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Actions from 'rapidfab/actions';

import { User } from 'rapidfab/components/admin';
import { API_RESOURCES } from 'rapidfab/constants';
import * as Selectors from 'rapidfab/selectors';

const UserContainer = ({ uuid: id, handleSelectionChange }) => {
  const users = useSelector(Selectors.getUsers);
  const user = users.find(({ uuid }) => uuid === id);
  const isSessionManager = useSelector(Selectors.isSessionManager);
  const isRestrictedUser = useSelector(Selectors.isCurrentUserRestricted);

  const [view, setView] = useState('main');

  useEffect(() => {
    setView('main');
  }, [id]);

  const dispatch = useDispatch();

  const handleDeleteUser = async () => {
    try {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].delete(id));
      await handleSelectionChange('none');
    } catch {
      //  Add error handling;
    }
  };

  const handleViewChange = newView => setView(newView);

  return (
    <User
      user={user}
      view={view}
      isSessionManager={isSessionManager}
      isRestrictedUser={isRestrictedUser}
      handleDeleteUser={handleDeleteUser}
      handleViewChange={handleViewChange}
      handleSelectionChange={handleSelectionChange}
    />
  );
};

UserContainer.propTypes = {
  handleSelectionChange: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default UserContainer;
