import React from 'react';
import { getPrepTasks } from 'rapidfab/selectors';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import PrepTasks from 'rapidfab/components/manage/PrepTasks';

const PrepTasksContainer = componentProps => (
  <PrepTasks
    {...componentProps}
  />
);

export default withRecordsListHandling(
  PrepTasksContainer,
  getPrepTasks,
  ['prep-task'],
  {
    defaultSort: 'name',
    useLocationFiltering: false,
  },
);
