/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Zverse from 'rapidfab/components/hawking/Zverse';
import extractUuid from 'rapidfab/utils/extractUuid';
import Actions from 'rapidfab/actions';
import { Col, Row } from 'react-bootstrap';
import { API_RESOURCES, FEATURES, ZVERSE_CONVERSION_STATUSES } from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';
import * as Selectors from 'rapidfab/selectors';

const ZverseContainer = ({ modelUri }) => {
  const model = useSelector(state => Selectors.getUUIDResource(state, extractUuid(modelUri)));
  const { zverse_conversion: zverseConversionUri } = model;
  const zverseConversion = useSelector(state => Selectors.getZverseConversionForModel(state, model));
  const isHawkingUser = useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT));
  const isStanleyXUser = useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.STANLEY_X_DEPLOYMENT));
  const { fetching } = useSelector(state => state.ui.nautilus[API_RESOURCES.ZVERSE_CONVERSION].get);

  const dispatch = useDispatch();

  const onInitialize = zverseConversionUri =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.ZVERSE_CONVERSION].get(extractUuid(zverseConversionUri)));
  const onEstimateRequest = () =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.ZVERSE_CONVERSION]
      .post({ model: modelUri }));
  const onConversionApprove = zverseConversionUri =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.ZVERSE_CONVERSION]
      .put(extractUuid(zverseConversionUri), { status: ZVERSE_CONVERSION_STATUSES.APPROVAL_REQUESTED }));
  const onUploadModel = async modelFile => {
    // 'upload-location' link lives for 5 minutes only
    // fetching model right before upload to make sure 'upload-location' link is valid
    const response = await dispatch(Actions.DownloadModel.fetchModel(modelUri));
    dispatch(Actions.UploadModel.upload(response.json['upload-location'], modelFile));
  };

  useEffect(() => {
    if (zverseConversionUri) {
      onInitialize(zverseConversionUri);
    }
  }, [zverseConversionUri]);

  return (
    <Row>
      <Col xs={12}>
        {
          fetching
            ? <Loading />
            : (
              <Zverse
                zverseConversion={zverseConversion}
                isHawkingUser={isHawkingUser}
                isStanleyXUser={isStanleyXUser}
                onUploadModel={onUploadModel}
                onEstimateRequest={onEstimateRequest}
                onConversionApprove={onConversionApprove}
              />
            )
        }
      </Col>
    </Row>
  );
};

ZverseContainer.propTypes = {
  modelUri: PropTypes.string.isRequired,
};

export default ZverseContainer;
