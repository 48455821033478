import React from 'react';
import Tooltip from 'rapidfab/components/Tooltip';
import { assemblyPartMetaResourceType } from 'rapidfab/types';

const AssemblyPartMetaContextTooltip = ({ assemblyPartMeta }) => {
  const { context } = assemblyPartMeta;
  const { color, material } = context || {};
  if (!material) {
    // No context data to show
    return null;
  }

  return (
    <Tooltip id={`contextTooltip-${assemblyPartMeta.uuid}`}>
      <div>Material provided in A-ZIP file:</div>
      {color && (
        <span
          style={{ backgroundColor: color }}
          className="small-status-dot spacer-right"
        />
      )}
      {material}
    </Tooltip>
  );
};

AssemblyPartMetaContextTooltip.propTypes = {
  assemblyPartMeta: assemblyPartMetaResourceType,
};

AssemblyPartMetaContextTooltip.defaultProps = {
  assemblyPartMeta: {},
};

export default AssemblyPartMetaContextTooltip;
