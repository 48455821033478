import { createSelector } from 'reselect';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import { getPrintersByUri } from 'rapidfab/selectors/printer';
import _forEach from 'lodash/forEach';

export const getRuns = createSelector(
  [baseStateSelectors.getStateRuns, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getRunsByUri = createSelector([getRuns], runs =>
  _keyBy(runs, 'uri'),
);

export const getRunsByPrinterUri = createSelector(
  [getRunsByUri, getPrintersByUri],
  (runsByUri, printersByUri) => {
    const runsByPrinterUri = {};
    _forEach(runsByUri, run => {
      const printerUri = run.printer;
      if (printersByUri[printerUri]) {
        if (!runsByPrinterUri[printerUri]) {
          runsByPrinterUri[printerUri] = [];
        }
        runsByPrinterUri[printerUri].push(run);
      }
    });
    return runsByPrinterUri;
  },
);
