import convertLengthToOtherUnit from 'rapidfab/utils/convertLengthToOtherUnit';
import { MODEL_UNITS } from 'rapidfab/constants';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItem';

export default function checkPieceModelTooLargeForPrinter({ piece, buildVolume }) {
  const workflowTypeKey = getLineItemWorkflowTypeObjectKey(piece.lineItem);
  const { model } = piece.lineItem[workflowTypeKey];
  // Convert model sizes to mm first since build_volume is always in mm
  const modelSize = {
    x: convertLengthToOtherUnit(model.size.x, model.file_unit, MODEL_UNITS.MM),
    y: convertLengthToOtherUnit(model.size.y, model.file_unit, MODEL_UNITS.MM),
    z: convertLengthToOtherUnit(model.size.z, model.file_unit, MODEL_UNITS.MM),
  };

  const xTooLarge = (
    modelSize.x > buildVolume.x ||
    modelSize.x > buildVolume.y ||
    modelSize.x > buildVolume.z
  );
  const yTooLarge = (
    modelSize.y > buildVolume.y ||
    modelSize.y > buildVolume.z ||
    modelSize.y > buildVolume.x
  );
  const zTooLarge = (
    modelSize.z > buildVolume.z ||
    modelSize.z > buildVolume.x ||
    modelSize.z > buildVolume.y
  );

  // model is too large if all 3 configurations are too large
  const isPieceModelTooLargeForPrinter = xTooLarge && yTooLarge && zTooLarge;

  return isPieceModelTooLargeForPrinter;
}
