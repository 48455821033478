import dayjs from 'dayjs';
import _capitalize from 'lodash/capitalize';
import _flatten from 'lodash/flatten';
import _values from 'lodash/values';
import PropTypes from 'prop-types';
import GuidelineContext from 'rapidfab/context/GuidelineContext';
import React, { useContext, useEffect, useState } from 'react';
import { Form, FormControl } from 'react-bootstrap';

const GuidelineValue = ({
  uuid,
  index,
  getNamedExpressionBySelectedFieldName,
}) => {
  const guidelineContext = useContext(GuidelineContext);

  const {
    guidelineExpressions,
    listValues,
  } = guidelineContext;

  const [guidelineValueDropdownListItems,
    setGuidelineValueDropdownListItems] = useState([]);

  const { comparator } = guidelineExpressions.expressions[index];

  useEffect(() => {
    (async () => {
      const result = await listValues(uuid);
      setGuidelineValueDropdownListItems(result);
    })();
  }, [comparator]);

  const namedExpression = getNamedExpressionBySelectedFieldName(uuid);
  const fieldType = namedExpression?.input_type;
  const isValueDisabled =
    comparator === null ||
    comparator.length === 0;

  const flatUniqueResourcesByDisplayFieldKey = _flatten(guidelineValueDropdownListItems?.map(value => (
    _values(value.resources)
      .filter((resource, index, self) => (
        index === self.findIndex(x => (
          x[value.display_field_key] === resource[value.display_field_key]
        ))
      ))
  )));

  // Filter resources based on URI.
  const initialDropdownValueForUri = flatUniqueResourcesByDisplayFieldKey.find(resource => (
    resource.uri === guidelineExpressions.expressions[index].value?.uri
  ));

  // Filter resources based on `display_field_key` (secondary resort).
  const initialDropdownValueForLabel = flatUniqueResourcesByDisplayFieldKey.find(resource => {
    const displayFieldKeyForResource = guidelineValueDropdownListItems[0]?.display_field_key;
    return resource[displayFieldKeyForResource] === guidelineExpressions.expressions[index].value?.label;
  });

  if (fieldType === 'boolean') {
    return <Form.Check>Check</Form.Check>;
  /* If there is content in the `named_expression_options` then it must be
  a value field of type dropdown */
  } if (namedExpression?.named_expression_options.length > 0) {
    return (
      <FormControl
        id={`value-dropdown-${uuid}`}
        required
        value={initialDropdownValueForUri?.uri || initialDropdownValueForLabel?.uri}
        as="select"
        disabled={isValueDisabled || !guidelineValueDropdownListItems}
        onChange={event =>
          guidelineContext
            .dispatchGuidelineExpressionsAction({
              type: 'GUIDELINE_EXPRESSIONS_VALUE_CHANGE',
              payload: {
                label: event.target.selectedOptions[0].getAttribute('data-label'),
                uri: event.target.value,
              },
              uuid,
            })}
      >
        <option key="test" value="Test">
          Value
        </option>
        {guidelineValueDropdownListItems?.map(value => {
          const uniqueResourcesByDisplayFieldKey = _values(value.resources)
            .filter((resource, index, self) => (
              index === self.findIndex(x => (
                x[value.display_field_key] === resource[value.display_field_key]
              ))
            ));

          return (
            <optgroup label={_capitalize(value.resource)}>
              {
                uniqueResourcesByDisplayFieldKey.map(resource => (
                  <option
                    data-label={resource[value.display_field_key]}
                    key={resource.uri}
                    id={resource[value.display_field_key]}
                    value={resource.uri}
                  >
                    {resource[value.display_field_key]}
                  </option>
                ))
              }
            </optgroup>
          );
        })}
      </FormControl>
    );
  } if (fieldType === 'date') {
    const dateData = guidelineExpressions.expressions[index]?.value?.uri;
    // Prevent black walls if for some reason the date is not pulled or invalid.
    const dateValue = typeof dateData === 'string' ? dayjs(dateData).format('YYYY-MM-DD') : '';
    return (
      <input
        value={dateValue}
        pattern="^\d{4}-\d{2}-\d{2}$"
        placeholder="yyyy-mm-dd"
        type="date"
        style={{ color: 'black' }}
        onChange={event => {
          // YYYY-MM-DD HH:mm:ss.SSS format is required for the BE for now.
          const date = dayjs(event.target.value).format('YYYY-MM-DD HH:mm:ss.SSS');
          guidelineContext
            .dispatchGuidelineExpressionsAction({
              type: 'GUIDELINE_EXPRESSIONS_VALUE_CHANGE',
              payload: {
                label: date,
                uri: date,
              },
              uuid,
            });
        }}
      />
    );
  } if (fieldType === 'number') {
    return (
      <FormControl
        required
        value={Number.parseFloat(guidelineExpressions.expressions[index].value?.uri)}
        type="number"
        as="input"
        placeholder="Enter value"
        disabled={isValueDisabled}
        onChange={event => guidelineContext
          .dispatchGuidelineExpressionsAction({
            type: 'GUIDELINE_EXPRESSIONS_VALUE_CHANGE',
            payload: {
              label: event.target.value,
              uri: event.target.value,
            },
            uuid,
          })}
      />
    );
  } if (fieldType === 'string') {
    return (
      <FormControl
        required
        value={guidelineExpressions.expressions[index].value?.uri}
        as="input"
        placeholder="Enter text"
        disabled={isValueDisabled}
        onChange={event => guidelineContext
          .dispatchGuidelineExpressionsAction({
            type: 'GUIDELINE_EXPRESSIONS_VALUE_CHANGE',
            payload: {
              label: event.target.value,
              uri: event.target.value,
            },
            uuid,
          })}
      />
    );
  }
  return (
    <FormControl
      as="input"
      disabled
      placeholder="Select a guideline expression"
    />
  );
};

export default GuidelineValue;

GuidelineValue.propTypes = {
  uuid: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  getNamedExpressionBySelectedFieldName: PropTypes.func.isRequired,
};
