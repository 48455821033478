import React from 'react';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';

const DeleteButtonTitle = ({ resourceName }) => (
  <span>
    <Fa name="times" />
    {' '}
    <FormattedMessage id="button.delete" defaultMessage="Delete" />
    {' '}
    {resourceName}
  </span>
);

DeleteButtonTitle.defaultProps = { resourceName: null };
DeleteButtonTitle.propTypes = { resourceName: PropTypes.string };

export default DeleteButtonTitle;
