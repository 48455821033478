import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';
import {
  FormControlTextCareful,
  FormControlTextAreaWithCustomHandlers,
  FormControlSelect,
} from 'rapidfab/components/formTools';
import {
  WORK_INSTRUCTION_REPORT_TYPES,
  WORK_INSTRUCTION_THRESHOLD_TYPES,
  WORK_INSTRUCTION_THRESHOLD_ACTIONS,
  WORK_INSTRUCTION_THRESHOLD_TYPES_BY_WORK_INSTRUCTION_TYPE, WORK_INSTRUCTION_THRESHOLD_NOTIFICATION,
} from 'rapidfab/constants';
import { Col, FormControl, Badge, Row, Form as BSForm, InputGroup } from 'react-bootstrap';
import TagsInput from 'rapidfab/components/forms/TagsInput';
import { FormattedMessage, FormattedMessageMappingOption } from 'rapidfab/i18n';
import {
  WORK_INSTRUCTION_THRESHOLD_TYPES_MAP,
  WORK_INSTRUCTION_UNITS_MAP,
  WORK_INSTRUCTION_REPORT_TYPE_MAP,
} from 'rapidfab/mappings';
import _isEmpty from 'lodash/isEmpty';
import { finalFormInputTypes, workInstructionResourceType } from 'rapidfab/types';
import GuidelineSuggestionContext from 'rapidfab/context/GuidelineSuggestionContext';

import { Form, Field } from 'react-final-form';
import ExclamationCircle from 'rapidfab/icons/exclamation-circle';
import XMarkCircle from 'rapidfab/icons/xmark-circle';
import ArrowsRepeat from 'rapidfab/icons/arrows-repeat';
import Checklist from 'rapidfab/icons/checklist';

import { isURLRegex } from 'rapidfab/utils/isURI';
import AdditionalURLErrorMessage from 'rapidfab/components/AdditionalURLErrorMessage';

const styles = {
  positionHeader: {
    width: '5%',
  },
  stepHeader: {
    width: '90%',
  },
  optionsHeader: {
    width: '5%',
  },
  centerIcons: {
    textAlign: 'center',
  },
  splitDivs: {
    display: 'inline-block',
    width: '50%',
  },
  inputBasedPadding: {
    paddingLeft: '13px',
    paddingTop: '8px',
    paddingBottom: '6px',
    whiteSpace: 'unset',
  },
  labelTypeStyle: {
    display: 'inline',
    marginTop: '2px',
    marginLeft: '5px',
  },
  stepButtonSpacingTop: {
    marginTop: '9px',
  },
  disabledInteractionStep: {
    pointerEvents: 'none',
    opacity: '0.5',
  },
};

export const StepActionButton = ({ onClick, icon: Icon, disabled, text, value, action }) => (
  <button
    name={value}
    type="button"
    disabled={disabled}
    onClick={onClick}
    className={`
        work-instructions-step-action-btn
        ${value === action ? 'work-instructions-step-action-btn-active' : ''}
        `}
  >
    <div className="d-flex align-items-center justify-content-start">
      <div className="mr15">
        {
          Icon && (
            <Icon className="work-instructions-step-action-icon" />
          )
        }
      </div>
      <p className="work-instructions-step-action-text">{text}</p>
    </div>
  </button>
);

StepActionButton.defaultProps = {
  icon: null,
  disabled: false,
};

StepActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.func,
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};

const ThresholdsBlock = ({ instruction, onChange, disabled }) => {
  const thresholdType = instruction.threshold_type;
  const thresholdInitialAction = instruction.threshold_action;

  // Not showing value field by default
  let valueFieldType = null;

  switch (instruction.report_type) {
    case WORK_INSTRUCTION_REPORT_TYPES.NUMBER:
      if ([
        WORK_INSTRUCTION_THRESHOLD_TYPES.TOLERANCE,
        WORK_INSTRUCTION_THRESHOLD_TYPES.LESS_THAN,
        WORK_INSTRUCTION_THRESHOLD_TYPES.LESS_THAN_OR_EQUALS_TO,
        WORK_INSTRUCTION_THRESHOLD_TYPES.GREATER_THAN,
        WORK_INSTRUCTION_THRESHOLD_TYPES.GREATER_THAN_OR_EQUALS_TO,
        WORK_INSTRUCTION_THRESHOLD_TYPES.EQUALS_TO,
        WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_EQUALS_TO,
      ].includes(thresholdType)) {
        valueFieldType = 'number';
      }
      break;
    case WORK_INSTRUCTION_REPORT_TYPES.TEXT:
      if ([
        WORK_INSTRUCTION_THRESHOLD_TYPES.EQUALS_TO,
        WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_EQUALS_TO,
        WORK_INSTRUCTION_THRESHOLD_TYPES.CONTAINS,
        WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_CONTAINS,
      ].includes(thresholdType)) {
        valueFieldType = 'text';
      }
      break;
    default:
      break;
  }

  const showDeviationField =
    thresholdType === WORK_INSTRUCTION_THRESHOLD_TYPES.TOLERANCE;
  const showLowerBoundField = [
    WORK_INSTRUCTION_THRESHOLD_TYPES.BETWEEN,
    WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_BETWEEN,
  ].includes(thresholdType);
  const showUpperBoundField = [
    WORK_INSTRUCTION_THRESHOLD_TYPES.BETWEEN,
    WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_BETWEEN,
  ].includes(thresholdType);
  const showAdditionalOptions = thresholdType && thresholdType !== WORK_INSTRUCTION_THRESHOLD_TYPES.NONE;

  const onThresholdFieldChange = (event, thresholdFieldName, isNumber) => {
    const currentThreshold = instruction.threshold || {};
    let newValue = event.target.value && isNumber ? Number(event.target.value) : event.target.value;

    if (isNumber && newValue < 0) {
      newValue = 0;
    }

    const updatedThreshold = {
      ...currentThreshold,
      [thresholdFieldName]: newValue,
    };

    onChange('threshold', {
      position: instruction.position,
      value: updatedThreshold,
    });
  };

  const thresholdValue = instruction.threshold && instruction.threshold.value;
  const thresholdDeviation = instruction.threshold && instruction.threshold.deviation;
  const thresholdLowerBound = instruction.threshold && instruction.threshold.lower_bound;
  const thresholdUpperBound = instruction.threshold && instruction.threshold.upper_bound;
  const [thresholdAction, setThresholdAction] = useState(thresholdInitialAction || null);

  const availableThresholdTypes =
    WORK_INSTRUCTION_THRESHOLD_TYPES_BY_WORK_INSTRUCTION_TYPE[instruction.report_type];

  const handleThresholdActions = (field, value) => {
    if (thresholdAction === value) {
      setThresholdAction(null);
      return onChange(field, {
        position: instruction.position,
        value: null,
      });
    }

    onChange(
      field,
      {
        position: instruction.position,
        value,
      },
    );
    return setThresholdAction(value);
  };

  return (
    // Refresh all child components when value dynamically changed from parent component
    // (is used to refresh numbers in inputs on value reset when type is changed).
    // Can be added for each inputs (if it becomes too heavy to refresh whole component)
    <React.Fragment key={thresholdType}>
      <Row className="mt15">
        <Col xs={12}>Thresholds:</Col>
      </Row>
      <Row>
        <Col xs={5}>
          <Field
            name="threshold_type"
            render={props => (
              <FormControlSelect
                value={props.input.value}
                disabled={disabled}
                onChange={event => {
                  props.input.onChange(event.target.value);
                  onChange('threshold_type', {
                    position: instruction.position,
                    value: event.target.value,
                  });
                }}
              >
                <FormattedMessage id="field.none" defaultMessage="None">
                  {text => <option value={null}>{text}</option>}
                </FormattedMessage>
                {availableThresholdTypes.map(thresholdTypeKey => (
                  <FormattedMessageMappingOption
                    key={thresholdTypeKey}
                    value={thresholdTypeKey}
                    mapping={WORK_INSTRUCTION_THRESHOLD_TYPES_MAP}
                  />
                ))}
              </FormControlSelect>
            )}
          />
        </Col>
        {valueFieldType && (
          <Col xs={showDeviationField ? 3 : 7}>
            <FormControl
              type={valueFieldType}
              value={thresholdValue}
              disabled={disabled}
              min={0}
              onChange={event =>
                onThresholdFieldChange(
                  event,
                  'value',
                  valueFieldType === 'number',
                )}
            />
          </Col>
        )}
        {showDeviationField && (
          <Col xs={4}>
            <InputGroup>
              <InputGroup.Text>±</InputGroup.Text>
              <FormControl
                type="number"
                min={0}
                value={thresholdDeviation}
                disabled={disabled}
                onChange={event =>
                  onThresholdFieldChange(event, 'deviation', true)}
              />
            </InputGroup>
          </Col>
        )}
        {showLowerBoundField && (
          <Col xs={3}>
            <FormControl
              type="number"
              min={0}
              value={thresholdLowerBound}
              disabled={disabled}
              onChange={event =>
                onThresholdFieldChange(event, 'lower_bound', true)}
            />
          </Col>
        )}
        {showUpperBoundField && (
          <Col xs={3}>
            <FormControl
              type="number"
              min={0}
              value={thresholdUpperBound}
              disabled={disabled}
              onChange={event =>
                onThresholdFieldChange(event, 'upper_bound', true)}
            />
          </Col>
        )}
      </Row>
      {showAdditionalOptions ? (
        <Row>
          <Col xs={12}>
            <form>
              <p className="work-instructions-step-action-title">If threshold is outside of limit, allow user to</p>
              <div className="d-flex">
                <div className="mr15">
                  <Field
                    type="button"
                    name="threshold_action"
                    render={() => (
                      <StepActionButton
                        text="Send to NC"
                        value={WORK_INSTRUCTION_THRESHOLD_ACTIONS.NCR_WARNING}
                        icon={Checklist}
                        action={thresholdAction}
                        onClick={() => handleThresholdActions('threshold_action', WORK_INSTRUCTION_THRESHOLD_ACTIONS.NCR_WARNING)}
                      />
                    )}
                  />
                  <Field
                    type="button"
                    name="threshold_action"
                    render={() => (
                      <StepActionButton
                        text="Show warning"
                        value={WORK_INSTRUCTION_THRESHOLD_ACTIONS.WARNING}
                        icon={ExclamationCircle}
                        action={thresholdAction}
                        onClick={() => handleThresholdActions('threshold_action', WORK_INSTRUCTION_THRESHOLD_ACTIONS.WARNING)}
                      />
                    )}
                  />
                </div>
                <div>
                  <Field
                    type="button"
                    name="threshold_action"
                    render={() => (
                      <StepActionButton
                        disabled
                        text="Remanufacture"
                        value={WORK_INSTRUCTION_THRESHOLD_ACTIONS.REMFG_WARNING}
                        icon={ArrowsRepeat}
                        action={thresholdAction}
                        onClick={() => handleThresholdActions('threshold_action', WORK_INSTRUCTION_THRESHOLD_ACTIONS.REMFG_WARNING)}
                      />
                    )}
                  />
                  <Field
                    type="button"
                    name="threshold_action"
                    render={() => (
                      <StepActionButton
                        text="Show error"
                        icon={XMarkCircle}
                        action={thresholdAction}
                        value={WORK_INSTRUCTION_THRESHOLD_ACTIONS.ERROR}
                        onClick={() => handleThresholdActions('threshold_action', WORK_INSTRUCTION_THRESHOLD_ACTIONS.ERROR)}
                      />
                    )}
                  />
                </div>
              </div>
              <hr className="work-instructions-step-action-hr" />
              <div>
                <Field
                  type="checkbox"
                  name="threshold_notification"
                  render={props => (
                    <BSForm.Check
                      label="Also notify owner by email if value falls outside of the range."
                      checked={props.input.checked}
                      onChange={event => {
                        props.input.onChange(event.target.checked);
                        onChange(
                          'threshold_notification',
                          {
                            position: instruction.position,
                            // set "piece_owner" as default while BE is in progress for user_group
                            value: event.target.checked ? WORK_INSTRUCTION_THRESHOLD_NOTIFICATION.PIECE_OWNER : null,
                          },
                        );
                      }}
                      type="checkbox"
                      className="checkbox-default"
                    />
                  )}
                />
              </div>
            </form>
          </Col>
        </Row>
      ) : null}
    </React.Fragment>
  );
};

ThresholdsBlock.propTypes = {
  instruction: workInstructionResourceType.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  input: finalFormInputTypes.isRequired,
};

class WorkInstruction extends Component {
  constructor(props) {
    super(props);

    this.inputElement = null;

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isEditable } = this.props;

    if (isEditable && isEditable !== prevProps.isEditable) {
      // this.focusInput();
    }
  }

  onSubmit(event) {
    const { instruction, onToggle } = this.props;

    onToggle(instruction.position);

    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    const {
      instruction,
      isEditable,
      onDelete,
      onChange,
      onToggle,
      onReorder,
      quantity,
      readOnly,
      isStepComplete,
    } = this.props;

    const isFirstPosition = instruction.position === 1;
    const isLastPosition = instruction.position === quantity;

    const showUnitsField = [
      WORK_INSTRUCTION_REPORT_TYPES.NUMBER,
      WORK_INSTRUCTION_REPORT_TYPES.RANGE,
    ].includes(instruction.report_type);

    const showThresholdsFields = [
      WORK_INSTRUCTION_REPORT_TYPES.NUMBER,
      WORK_INSTRUCTION_REPORT_TYPES.TEXT,
    ].includes(instruction.report_type);

    const showChoicesField = instruction.report_type === WORK_INSTRUCTION_REPORT_TYPES.SINGLE_SELECT_DROPDOWN;

    const Arrows = ({ position }) => (
      <div>
        <div
          onClick={() => {
            onReorder('work_instructions', {
              position,
              direction: 'down',
            });
          }}
          role="button"
          className={isStepComplete ? 'disabled-interaction' : ''}
          style={({ ...styles.centerIcons, ...styles.splitDivs })}
          tabIndex={0}
        >
          {quantity !== 1 && !isLastPosition && <Fa name="angle-down" size="2x" /> }
        </div>
        <div
          onClick={() => {
            onReorder('work_instructions', {
              position,
              direction: 'up',
            });
          }}
          role="button"
          className={isStepComplete ? 'disabled-interaction' : ''}
          style={({ ...styles.centerIcons, ...styles.splitDivs })}
          tabIndex={0}
        >
          {quantity !== 1 && !isFirstPosition && <Fa name="angle-up" size="2x" /> }
        </div>
      </div>
    );

    return (
      <tr key={instruction.position}>
        <td>
          {!readOnly && (<Arrows position={instruction.position} />)}
        </td>

        {!isEditable && (
          <td>
            <div style={styles.inputBasedPadding}>
              {`${instruction.description} `}
              {
                instruction.additional_instruction_url && (
                  <a
                    href={instruction.additional_instruction_url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Fa name="external-link" />
                  </a>
                )
              }

              <span className="pull-right">
                {
                  instruction.report_type &&
                instruction.report_type !== WORK_INSTRUCTION_REPORT_TYPES.NO_ENTRY &&
                  (
                    <Badge bg="default" className="badge-sm" style={styles.labelTypeStyle}>
                      <FormattedMessage
                        {...WORK_INSTRUCTION_REPORT_TYPE_MAP[instruction.report_type]}
                      />{' '}
                      <FormattedMessage
                        id="reportType"
                        defaultMessage="Report Type"
                      />
                    </Badge>
                  )
                }

                {
                  !_isEmpty(instruction.threshold) && (
                    <Badge
                      bg="info"
                      className="badge-sm"
                      style={styles.labelTypeStyle}
                    >
                      <FormattedMessage id="threshold" defaultMessage="Threshold" />
                    </Badge>
                  )
                }

                {
                  instruction.required && (
                    <Badge
                      bg="danger"
                      className="badge-sm badge-danger"
                      style={styles.labelTypeStyle}
                    >
                      <FormattedMessage id="field.required" defaultMessage="Required" />
                    </Badge>
                  )
                }
              </span>
            </div>
          </td>
        )}

        {isEditable && (
          <td>
            <Form
              onSubmit={this.onSubmit}
              initialValues={instruction}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="description"
                    render={props => (
                      <FormControlTextAreaWithCustomHandlers
                        required
                        name="description"
                        disabled={readOnly}
                        value={props.input.value}
                        onChange={event => {
                          props.input.onChange(event.target.value);
                          onChange('description', {
                            position: instruction.position,
                            value: event.target.value,
                          });
                        }}
                        inputRef={ref => {
                          this.inputElement = ref;
                        }}
                        placeholder="Step Description"
                      />
                    )}
                  />
                  <br />
                  <Row>
                    <Col xs={4}>
                      <Field
                        type="select"
                        name="report_type"
                        render={props => (
                          <FormControlSelect
                            value={props.input.value}
                            disabled={readOnly}
                            onChange={event => {
                              props.input.onChange(event.target.value);
                              onChange('report_type', {
                                position: instruction.position,
                                value: event.target.value,
                              });
                              // Required to reset the value of the threshold field
                              onChange('threshold_type', {
                                position: instruction.position,
                                value: null,
                              });
                            }}
                          >
                            {Object.values(WORK_INSTRUCTION_REPORT_TYPES).map(
                              reportType => (
                                <FormattedMessageMappingOption
                                  mapping={WORK_INSTRUCTION_REPORT_TYPE_MAP}
                                  value={reportType}
                                />
                              ))}
                          </FormControlSelect>
                        )}
                      />
                    </Col>
                    {showUnitsField && (
                      <Col xs={4}>
                        <Field
                          name="report_units"
                          render={props => (
                            <FormControlSelect
                              value={props.input.value}
                              disabled={readOnly}
                              onChange={event => {
                                props.input.onChange(event.target.value);
                                onChange('report_units', {
                                  position: instruction.position,
                                  value: event.target.value,
                                });
                              }}
                            >
                              <FormattedMessage id="field.units" defaultMessage="Units">{text =>
                                <option value={null}>{text}</option>}
                              </FormattedMessage>
                              {Object.keys(WORK_INSTRUCTION_UNITS_MAP).map(workInstructionUnit => (
                                <FormattedMessageMappingOption
                                  key={workInstructionUnit}
                                  value={workInstructionUnit}
                                  mapping={WORK_INSTRUCTION_UNITS_MAP}
                                />
                              ))}
                            </FormControlSelect>
                          )}
                        />
                      </Col>
                    )}
                    <Col xs={4}>
                      {
                        instruction.report_type !== WORK_INSTRUCTION_REPORT_TYPES.NO_ENTRY && (
                          <Field
                            name="required"
                            type="checkbox"
                            render={props => (
                              <BSForm.Check
                                disabled={readOnly}
                                checked={props.input.checked}
                                onChange={event => {
                                  props.input.onChange(event.target.value);
                                  onChange('required', {
                                    position: instruction.position,
                                    value: event.target.checked,
                                  });
                                }}
                                type="checkbox"
                                className="checkbox-default"
                                label="Required"
                              />
                            )}
                          />
                        )
                      }
                    </Col>
                    {showChoicesField && (
                      <Col xs={12}>
                        Choices:
                        <TagsInput
                          tags={instruction.choices}
                          onChange={choices => onChange('choices', { position: instruction.position, value: choices })}
                        />
                      </Col>
                    )}
                  </Row>
                  {showThresholdsFields && (
                    <ThresholdsBlock
                      instruction={instruction}
                      onChange={onChange}
                      disabled={readOnly}
                    />
                  )}
                  <br />
                  <Field
                    name="additional_instruction_url"
                    validate={value => !isURLRegex(value)}
                    render={fieldProps => (
                      <>
                        <FormControlTextCareful
                          {...fieldProps.input}
                          disabled={readOnly}
                          value={fieldProps.input.value}
                          isInvalid={(fieldProps.meta.touched && fieldProps.meta.invalid && fieldProps.input.value !== '')}
                          placeholder="Link for Instructions"
                          onChange={event => {
                            fieldProps.input.onChange(event.target.value);
                            onChange('additional_instruction_url', {
                              value: event.target.value,
                              position: instruction.position,
                            });
                          }}
                        />
                        <AdditionalURLErrorMessage {...fieldProps} />
                      </>
                    )}
                  />
                </form>
              )}
            />
          </td>
        )}

        <td style={styles.centerIcons}>
          <div
            role="button"
            onClick={() => onToggle('work_instructions', instruction.position)}
            tabIndex={0}
            className={isStepComplete && !readOnly ? 'disabled-interaction' : ''}
            style={styles.stepButtonSpacingTop}
          >
            {readOnly && (<Fa name={isEditable ? 'eye-slash' : 'eye'} />)}
            {!readOnly && (<Fa name={isEditable ? 'floppy-o' : 'edit'} />)}
          </div>
        </td>
        <td style={styles.centerIcons}>
          {!readOnly && (
            <div
              role="button"
              onClick={() => onDelete('work_instructions', instruction.position)}
              tabIndex={0}
              className={isStepComplete ? 'disabled-interaction' : ''}
              style={styles.stepButtonSpacingTop}
            >
              <Fa name="times" />
            </div>
          )}
        </td>
      </tr>
    );
  }
}

WorkInstruction.contextType = GuidelineSuggestionContext;

WorkInstruction.propTypes = {
  instruction: workInstructionResourceType.isRequired,
  onDelete: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  input: finalFormInputTypes.isRequired,
  quantity: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  isStepComplete: PropTypes.bool.isRequired,
};

WorkInstruction.defaultProps = {
  readOnly: false,
};

export default WorkInstruction;
