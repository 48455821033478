import React from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import {
  Navbar,
  Nav,
  NavDropdown,
} from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import NavProfile from 'rapidfab/components/navbar/NavProfile';
import { Plan, Inventory } from 'rapidfab/components/navbar/Titles';

const NavLinksServiceProvider = ({
  currentUser,
  locale,
  onChangeLocale,
  onLogout,
  session,
  isImpersonating,
  onImpersonateStop,
}) => (
  <Navbar.Collapse>
    <Nav>
      <NavDropdown eventKey={1} title={<Plan />} id="uxNavPlan">
        <NavDropdown.Item eventKey={1.3} href={getRouteURI(ROUTES.SERVICE_PROVIDER_JOB_LIST)}>
          <Fa name="clipboard" />{' '}
          <FormattedMessage id="plan.serviceProviderJobs" defaultMessage="Service Provider Jobs" />
        </NavDropdown.Item>
      </NavDropdown>
      <NavDropdown
        eventKey={2}
        title={<Inventory />}
        id="uxNavInventory"
      >
        <NavDropdown.Item eventKey={2.1} href={getRouteURI(ROUTES.POST_PROCESSOR_SERVICES)}>
          <Fa name="clipboard" />{' '}
          <FormattedMessage
            id="inventory.postProcessorServices"
            defaultMessage="Post Processor Services"
          />
        </NavDropdown.Item>
      </NavDropdown>
      {isImpersonating && (
        <NavDropdown.Item eventKey={1.2} onClick={() => onImpersonateStop()}>
          <div>
            <Fa name="user-secret" />{' '}
            <FormattedMessage id="endImpersonation" defaultMessage="End Impersonation" />
          </div>
        </NavDropdown.Item>
      )}
    </Nav>
    <NavProfile
      currentUser={currentUser}
      locale={locale}
      onChangeLocale={onChangeLocale}
      onLogout={onLogout}
      session={session}
      isServiceProviderRole
      onImpersonateStop={onImpersonateStop}
    />
  </Navbar.Collapse>
);

NavLinksServiceProvider.propTypes = {
  currentUser: PropTypes.shape({}).isRequired,
  locale: PropTypes.string.isRequired,
  onChangeLocale: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  session: PropTypes.shape({}).isRequired,
  isImpersonating: PropTypes.bool.isRequired,
  onImpersonateStop: PropTypes.func.isRequired,
};

export default NavLinksServiceProvider;
