import React, { useState } from 'react';
import { Badge, Button, Card, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import truncateText from 'rapidfab/utils/truncateText';
import Fa from 'react-fontawesome';
import PropTypes from 'prop-types';

const DebugModeDataPanel = props => {
  const { data, style, className } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [copiedValueState, setCopiedValueState] = useState({});

  const copy = value => {
    navigator.clipboard.writeText(value);
  };

  return (
    <Card style={style} className={`${className} w-100`}>
      <Card.Header>
        <p>
          <Badge className="badge badge-sm spacer-right" bg="warning">Debug Mode</Badge>
          {`Data for '${data?.name}'` || 'Data'}
          <Button variant="success" onClick={() => setIsOpen(!isOpen)} size="xs" className="pull-right">
            {isOpen ? 'Close' : 'Open'}
            <Fa className="spacer-left" name={isOpen ? 'chevron-up' : 'chevron-down'} />
          </Button>
          <Button
            onClick={() => {
              copy(data?.uuid);
              setCopiedValueState({
                uuid: true,
              });
            }}
            size="xs"
            className="pull-right spacer-right"
          >
            Copy UUID
            <Fa className="spacer-left" name={copiedValueState.uuid ? 'check' : 'clone'} />
          </Button>
          <Button
            onClick={() => {
              copy(data?.uri);
              setCopiedValueState({
                uri: true,
              });
            }}
            size="xs"
            className="pull-right spacer-right"
          >
            Copy URI
            <Fa className="spacer-left" name={copiedValueState.uri ? 'check' : 'clone'} />
          </Button>
          <Button
            onClick={() => {
              copy(JSON.stringify(data));
              setCopiedValueState({
                json: true,
              });
            }}
            size="xs"
            className="pull-right spacer-right"
          >
            Copy JSON
            <Fa className="spacer-left" name={copiedValueState.json ? 'check' : 'clone'} />
          </Button>
        </p>
      </Card.Header>
      {isOpen && (
        <Card.Body>
          <div>
            <Table size="sm">
              <p>
                UUID:
                <Badge
                  bg=""
                  role="button"
                  onClick={() => {
                    copy(data?.uuid);
                    setCopiedValueState({
                      uuid: true,
                    });
                  }}
                  className="text-white spacer-left badge badge-sm"
                >
                  {data?.uuid ?? 'Not found'}
                </Badge>
              </p>
              <p>
                URI:
                <Badge
                  bg=""
                  className="text-white spacer-left badge badge-sm"
                  role="button"
                  onClick={() => {
                    copy(data?.uri);
                    setCopiedValueState({
                      uri: true,
                    });
                  }}
                >
                  {data?.uri ?? 'Not found'}
                </Badge>
              </p>
              <p>
                JSON object:
                <Badge
                  role="button"
                  bg=""
                  className="text-white spacer-left badge badge-sm"
                  onClick={() => {
                    copy(JSON.stringify(data));
                    setCopiedValueState({
                      json: true,
                    });
                  }}
                >
                  {truncateText(JSON.stringify(data), 50) ?? 'Not found'}
                </Badge>
              </p>
            </Table>
          </div>
          {
            Object.entries(data).map(([key, value]) => (
              <OverlayTrigger
                overlay={(
                  <Tooltip>
                    {
                      copiedValueState[key] ?
                        <p>Copied</p>
                        :
                        <p>Click to copy</p>
                    }
                  </Tooltip>
                )}
              >
                <p>
                  {key}:
                  <Badge
                    role="button"
                    onClick={() => {
                      copy(value);
                      setCopiedValueState({ [key]: true });
                    }}
                    bg=""
                    className="text-white spacer-left badge badge-sm"
                  >
                    {truncateText(JSON.stringify(value), 80)}
                  </Badge>
                </p>
              </OverlayTrigger>
            ))
          }
        </Card.Body>
      )}
    </Card>
  );
};

export default DebugModeDataPanel;

DebugModeDataPanel.propTypes = {
  data: PropTypes.shape({
    uri: PropTypes.string,
    uuid: PropTypes.string,
    json: PropTypes.shape({}),
    name: PropTypes.string,
  }).isRequired,
  style: PropTypes.shape({}).isRequired,
  className: PropTypes.string.isRequired,
};
