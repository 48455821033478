import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Locations from 'rapidfab/components/locations';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { ROUTES } from 'rapidfab/constants';
import SubLocations from 'rapidfab/components/SubLocations';
import MaterialLotsTable from './MaterialLotsTable';

const MaterialLots = ({
  onOrderMaterialLots,
  inventoryMaterialLots,
  listStore,
  filters,
  offset,
  limit,
  search,
  sort,
  onSort,
  onPageChange,
  onLimitChange,
  onSearch,
  fetching,
  ...componentProps
}) => (
  <Container fluid>
    <BreadcrumbNav
      breadcrumbs={['inventory', 'materialLots']}
    />
    <Row>
      <Col xs={3}>
        <Locations />
      </Col>
      <Col xs={5}>
        <SubLocations />
      </Col>

      <Col xs={4}>
        <Button
          variant="primary"
          size="sm"
          href={getRouteURI(ROUTES.MATERIAL_LOT_NEW)}
          className="pull-right"
        >
          <Fa name="plus" />{' '}
          <FormattedMessage id="materialLot.add" defaultMessage="Add Material Lot" />
        </Button>
        <Button
          variant="info"
          size="sm"
          href={getRouteURI(
            ROUTES.MATERIAL_LOT_NEW, null, { isOrderMaterialLot: true },
          )}
          className="pull-right mr15"
        >
          <Fa name="clipboard" />{' '}
          Order Material Lot
        </Button>
      </Col>
    </Row>
    <hr />
    <MaterialLotsTable
      inventoryMaterialLots={inventoryMaterialLots}
      onOrderMaterialLots={onOrderMaterialLots}
      listStore={listStore}
      filters={filters}
      offset={offset}
      limit={limit}
      search={search}
      sort={sort}
      onSort={onSort}
      onPageChange={onPageChange}
      onLimitChange={onLimitChange}
      onSearch={onSearch}
      fetching={fetching}
      locationsState={{
        subLocations: componentProps.subLocations,
        locations: componentProps.locations,
      }}
    />
  </Container>
);

MaterialLots.defaultProps = {
  sort: '',
  search: '',
};

MaterialLots.propTypes = {
  onOrderMaterialLots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  inventoryMaterialLots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  listStore: PropTypes.shape({}).isRequired,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.string,
  search: PropTypes.string,
  filters: PropTypes.shape({
    location: PropTypes.string,
  }).isRequired,
  recordListStore: PropTypes.shape({}).isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  fetching: PropTypes.bool.isRequired,
};

export default MaterialLots;
