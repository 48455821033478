import { navbarFR } from './locales/navbar/index';
import { recordsFR } from './locales/records/index';
import { mappingsFR } from './locales/mappings/index';
import { componentsFR } from './locales/components/index';

import { planFR } from './locales/plan/index';
import adminFR from './locales/admin/fr.json';
import workFR from './locales/work/fr.json';
import inventoryFR from './locales/inventory/fr.json';
import manageFR from './locales/manage/fr.json';
import mfg from './locales/mfg.json';
import toasterFR from './locales/toaster/fr.json';
import restFR from './locales/rest/fr.json';

const translation = Object.assign(
  adminFR,
  navbarFR,
  planFR,
  workFR,
  inventoryFR,
  manageFR,
  recordsFR,
  mappingsFR,
  componentsFR,
  mfg,
  toasterFR,
  restFR,
);

export default translation;
