import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import Fa from 'react-fontawesome';
import LabelModalContainer from 'rapidfab/containers/admin/LabelModalContainer';
import 'rapidfab/styles/hawking/main.scss';

import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';

const MODALS = {
  DELETE: 'delete',
  EDIT: 'edit',
};

const Labels = componentProps => {
  const { data, onDelete, isHawkingUser, fetching, onSort } = componentProps;

  const [visibileModal, setVisibleModal] = useState(null);
  const [editUri, setEditUri] = useState(null);
  const [deleteUuid, setDeleteUuid] = useState(null);

  const onCloseModals = () => {
    setVisibleModal(null);
    setEditUri(null);
    setDeleteUuid(null);
  };
  const openEditModal = () => setVisibleModal(MODALS.EDIT);

  const handleDelete = () => {
    onDelete(deleteUuid);
    onCloseModals();
  };

  useEffect(() => {
    if (editUri) {
      setVisibleModal(MODALS.EDIT);
    }
  }, [editUri]);

  useEffect(() => {
    if (deleteUuid) {
      setVisibleModal(MODALS.DELETE);
    }
  }, [deleteUuid]);

  const NavbarLinks = (
    <Button
      className="pull-right"
      size="sm"
      variant="primary"
      onClick={openEditModal}
    >
      <Fa name="plus" />{' '}
      <FormattedMessage id="label.add" defaultMessage="Add Label" />
    </Button>
  );

  const columns = [
    {
      type: 'text',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      short: true,
    },
    {
      type: 'custom',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
      Cell: ({ row: { original } }) => (
        <>
          {original.name}
          <div className="pull-right">
            <Button
              className="spacer-right"
              variant="primary"
              size="xs"
              tabIndex={0}
              onClick={() => setEditUri(original.uri)}
            >
              <Fa name="pencil" />
            </Button>
            <Button
              variant={!isHawkingUser && 'danger'}
              size="xs"
              tabIndex={0}
              onClick={() => setDeleteUuid(original.uuid)}
              className={isHawkingUser && 'hawking-secondary'}
            >
              <Fa name="trash" />
            </Button>
          </div>
        </>
      ),
    },
    {
      type: 'text',
      uid: 'field.description',
      accessor: 'description',
      defaultMessage: 'Description',
      isSortable: true,
    },
    {
      type: 'color',
      uid: 'field.color',
      accessor: 'color',
      defaultMessage: 'Color',
      isSortable: true,
    },
  ];

  return (
    <>
      <TableWithSearching
        {...componentProps}
        withBreadcrumbs
        breadcrumbs={['admin', 'labels']}
        showLocationsFilter={false}
        navbar={NavbarLinks}
        data={data}
        columns={columns}
        isFetching={fetching}
        isFilteringEnabled={false}
        withDefaultPagination={false}
        isManualSoringEnabled
        isUpdatedColumnShown={false}
        manualSortingFunc={onSort}
        initialSortedDesc
        initialSortedColumn="name"
      />
      {visibileModal === MODALS.EDIT && (
        <LabelModalContainer
          onClose={onCloseModals}
          uri={editUri}
        />
      )}
      {visibileModal === MODALS.DELETE && (
        <ConfirmationModal
          handleCancel={onCloseModals}
          handleConfirm={handleDelete}
          message="Are you sure you want to delete this label?"
          isHawkingUser={isHawkingUser}
        />
      )}
    </>
  );
};

Labels.propTypes = {
  onDelete: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
      uri: PropTypes.string,
    }),
  })).isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Labels;
