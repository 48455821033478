import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Fa from 'react-fontawesome';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import { getUUIDResource } from 'rapidfab/selectors';
import getEndpointFromUri from 'rapidfab/utils/getEndpointFromURI';

const ResourceLink = ({ location, name, linkIconOnly }) => (
  <a href={location}>{linkIconOnly ? <Fa name="external-link" /> : name}</a>
);

ResourceLink.defaultProps = {
  linkIconOnly: false,
};

ResourceLink.propTypes = {
  location: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  linkIconOnly: PropTypes.bool,
  // Used by mapStateToProps() only  ¯\_(ツ)_/¯
  // eslint-disable-next-line react/no-unused-prop-types
  uri: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { uri } = ownProps;
  const uuid = extractUuid(uri);
  const resource = getUUIDResource(state, uuid);
  const { endpointName } = getEndpointFromUri(uri);
  const location = `/#/records/${endpointName}/${uuid}`;
  const name = resource ? resource.name : uri;
  return { location, name };
};

export default connect(mapStateToProps)(ResourceLink);
