import { faAdd, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, FormControl, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'rapidfab/components/Loading';
import PropTypes from 'prop-types';

const ManageTestPanels = ({
  filteredSamples,
  testPanels,
  showMaterialAddTestPanelModal,
  setTestPanelCurrentlyEdited,
  manageTestPanelsState,
  materialTestInstructionReports,
  materialTestUnitsByUri,
  materialTestInstructions,
  materialTestOperations,
  materialTestOperationLinkings,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  /* Added to disable "Edit Test Panel" button while fetching
  "Material Test Instruction Report" to prevent API calls errors
  */
  const isMaterialTestReportFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION_REPORT].list.fetching);

  const { selectedTestPanelUri, setSelectedTestPanel } = manageTestPanelsState;

  const instructionReportsForSelectedTestPanel = materialTestInstructionReports.filter(
    instructionReport => instructionReport.material_test_panel === selectedTestPanelUri,
  );

  const materialTestOperationsByUri = _keyBy(materialTestOperations, 'uri');

  /* Duplicated from MaterialAddTestPanelModal */
  const getExistingReportsForTestPanel = () => {
    setLoading(true);
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION_REPORT].list({
      material_test_panel: selectedTestPanelUri,
    }))
      .finally(() => setLoading(false));
  };

  useEffect(() => getExistingReportsForTestPanel(), [selectedTestPanelUri]);

  return (
    <Card bg="dark" className="mb15">
      <Card.Header className="pd-exp inverse">
        Test Results
      </Card.Header>
      <div className="card-body-wrapper">
        <Card.Body>
          <div className="d-flex">
            <FormControl
              id="selectedTestPanelIndex"
              as="select"
              name="testPanel"
              value={selectedTestPanelUri}
              disabled={!testPanels.length}
              onChange={event => setSelectedTestPanel(event.target.value)}
            >
              <option id={null} value="">
                Select Test Panel
              </option>
              {testPanels.map(panel => (
                <option id={null} value={panel.uri}>
                  {panel?.name}
                </option>
              ))}
            </FormControl>

            <Button
              className="spacer-left"
              style={{ minWidth: '20%' }}
              variant="primary"
              onClick={() => {
                showMaterialAddTestPanelModal(true);
                setTestPanelCurrentlyEdited(selectedTestPanelUri);
              }}
              disabled={_isEmpty(selectedTestPanelUri) || isMaterialTestReportFetching}
            >
              <p className="my-0">
                Edit Test Panel
                <FontAwesomeIcon
                  className="spacer-left"
                  icon={faEdit}
                />
              </p>
            </Button>

            <Button
              className={`spacer-left ${!filteredSamples.length ? 'allPointerEvents' : ''}`}
              style={{ minWidth: '20%' }}
              variant="success"
              disabled={!filteredSamples.length}
              onClick={() => {
                showMaterialAddTestPanelModal(true);
                setTestPanelCurrentlyEdited(null);
              }}
            >
              <p className="my-0">
                {
                  /*
                    If there are no available samples in the list ->
                    we should disable this button and show the relevant tooltip.
                  */
                  !filteredSamples.length ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={(
                        <Tooltip>
                          <p>There are no test samples available. Create one via QR if needed.</p>
                        </Tooltip>
                      )}
                    >
                      <p className="my-0">
                        Add Test Panel
                        <FontAwesomeIcon
                          className="spacer-left"
                          icon={faAdd}
                        />
                      </p>
                    </OverlayTrigger>
                  ) : (
                    <p className="my-0">
                      Add Test Panel
                      <FontAwesomeIcon
                        className="spacer-left"
                        icon={faAdd}
                      />
                    </p>
                  )
                }
              </p>
            </Button>
          </div>
          <Row className="m-t">
            {loading ? <Loading /> : (
              <Accordion flush>
                {
                  _isEmpty(selectedTestPanelUri) ? null :
                    materialTestOperationLinkings.map((operationLinking, index) => {
                      const currentOperationLinkingMaterialTestInstructions =
                      materialTestInstructions.filter(instruction =>
                        operationLinking.material_test_instructions.includes(
                          instruction.uri,
                        ),
                      );

                      /* Array with report nested inside the instructions. */
                      const currentOperationLinkingMaterialTestInstructionData =
                          currentOperationLinkingMaterialTestInstructions.map(instruction => ({
                            ...instruction,
                            material_test_instruction_report:
                              instructionReportsForSelectedTestPanel
                                .find(report => report.material_test_instruction === instruction.uri),
                          }));

                      return (
                        materialTestOperationsByUri[operationLinking.material_test_operation] && (
                          <Accordion.Item eventKey={index} variant="dark">
                            <Accordion.Header>
                              <p>
                                {materialTestOperationsByUri[operationLinking.material_test_operation].name}
                              </p>
                            </Accordion.Header>
                            <Accordion.Body>
                              {currentOperationLinkingMaterialTestInstructionData.map(instruction => (
                                <div>
                                  <b>
                                    {instruction.name}:
                                  </b>
                                  <p>
                                    {instruction?.material_test_instruction_report?.value ?? 'Null'}
                                    {' '}
                                    {materialTestUnitsByUri[instruction?.unit]?.symbol ?? '%'}
                                  </p>
                                  {instruction?.material_test_instruction_report?.notes && (
                                    <>
                                      <b>
                                        Notes:
                                      </b>
                                      <p>{instruction.material_test_instruction_report.notes}
                                      </p>
                                    </>
                                  )}
                                  <hr />
                                </div>
                              ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      );
                    })
                }
              </Accordion>
            )}
          </Row>
        </Card.Body>
      </div>
    </Card>
  );
};

export default ManageTestPanels;

ManageTestPanels.propTypes = {
  testPanels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showMaterialAddTestPanelModal: PropTypes.bool.isRequired,
  setTestPanelCurrentlyEdited: PropTypes.string.isRequired,
  manageTestPanelsState: PropTypes.shape({
    selectedTestPanelUri: PropTypes.string,
    setSelectedTestPanel: PropTypes.func,
  }).isRequired,
  materialTestInstructionReports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestUnitsByUri: PropTypes.shape({}).isRequired,
  materialTestInstructions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestOperations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestOperationLinkings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filteredSamples: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
