import React from 'react';
import PropTypes from 'prop-types';
import {
  getCustomEventContent,
  getEventReferenceResourceType,
} from 'rapidfab/utils/tracebilityReportEvents';
import DocumentDownloadLink from 'rapidfab/components/records/piece/event/DocumentDownloadLink';
import EventValue from 'rapidfab/components/records/piece/event/EventValue';

const EventContent = ({ event }) => {
  // In case there is a custom content - render it instead of default variant
  const customContent = getCustomEventContent(event);
  if (customContent) {
    return customContent;
  }

  const resourceType = getEventReferenceResourceType(event.reference);
  const previousEventExists = (event.previous_value && event.previous_value !== 'None') || false;
  return (
    <>
      {
        previousEventExists && (
          <span>
            <span>from </span>
            <b><EventValue value={event.previous_value} /></b>
          </span>
        )
      }
      <span> to </span>
      <b><EventValue value={event.current_value} /></b>
      {(resourceType === 'document') && (
        <DocumentDownloadLink uri={event.reference} />
      )}
    </>
  );
};

EventContent.propTypes = {
  event: PropTypes.shape({
    previous_value: PropTypes.number,
    current_value: PropTypes.number,
    reference: PropTypes.string,
  }).isRequired,
};

export default EventContent;
