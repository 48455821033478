import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import ServiceProvidersComponent from 'rapidfab/components/manage/ServiceProviders';
import { API_RESOURCES } from 'rapidfab/constants';

const ServiceProvidersContainer = props => {
  const { fetching } = useSelector(state => state.ui.nautilus[API_RESOURCES.SERVICE_PROVIDER].list);
  const providers = useSelector(Selectors.getServiceProviders);

  const dispatch = useDispatch();
  const onInitialize = () => dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER].list());

  useEffect(() => {
    (async () => onInitialize())();
  }, []);

  return (
    <ServiceProvidersComponent
      {...props}
      fetching={fetching}
      providers={providers}
    />
  );
};

export default ServiceProvidersContainer;
