import dayjs from 'dayjs';

export const ORDER_FORM_TYPES = { EDIT: 'edit', CREATE: 'create' };

export default ({
  isNormalUser,
  isDanfossUser = false,
  isSintaviaUser = false,
  isMerckUser = false,
  isBoeingUser = false,
  is3MAquaUser = false,
  is3MUser = false,
  isBeehiveUser = false,
  isXerox3DPUser = false,
}, orderFormType) => {
  const isEditForm = orderFormType === ORDER_FORM_TYPES.EDIT;
  const isCreateForm = orderFormType === ORDER_FORM_TYPES.CREATE;
  const showLocationOnEditForm =
      (isNormalUser ||
      is3MUser);
  const showLocationOnCreateForm =
      (is3MUser && !isNormalUser) ||
      (isBeehiveUser && isNormalUser);

  return ({
    orderOwner: {
      show: isNormalUser || isMerckUser ||
      (is3MAquaUser && isNormalUser) || (isBoeingUser && isNormalUser),
      required: isDanfossUser,
    },
    shippingName: {
      show: (isNormalUser && !is3MAquaUser) || (isDanfossUser && !isNormalUser) || (!isNormalUser && isXerox3DPUser),
      required: (isSintaviaUser && isNormalUser) || (isDanfossUser && !isNormalUser),
      id: 'field.shippingName',
      defaultMessage: 'Shipping Name',
    },
    shippingAddress: {
      show: isNormalUser || isDanfossUser || isMerckUser || isBoeingUser ||
        (isSintaviaUser && isNormalUser) || is3MUser || is3MAquaUser || (!isNormalUser && isXerox3DPUser),
      required: isDanfossUser || isMerckUser || isBoeingUser ||
        (is3MAquaUser && !isNormalUser),
      id: isMerckUser && !isNormalUser ? 'field.shippingAddressMailstop' : 'field.shippingAddress',
      defaultMessage: isMerckUser && !isNormalUser ? 'Shipping Address & Mailstop' : 'Shipping Address',
    },
    trackingNumber: {
      show: isNormalUser || isSintaviaUser || isBoeingUser,
      required: false,
    },
    shippingType: {
      show: isNormalUser || isSintaviaUser || (isBoeingUser && isNormalUser),
      required: isBoeingUser,
    },
    creator: {
      show: isNormalUser,
    },
    created: {
      show: isNormalUser,
    },
    customerPo: {
      show: true,
      required: isDanfossUser,
    },
    quoteNumber: {
      show: true,
      required: isDanfossUser,
    },
    customerEmail: {
      show: isNormalUser || isMerckUser || (isBoeingUser && isNormalUser)
      || is3MAquaUser || isXerox3DPUser,
      required: isMerckUser || (is3MAquaUser && !isNormalUser) || isXerox3DPUser,
    },
    customerName: {
      show: isNormalUser || isMerckUser || (isBoeingUser && isNormalUser)
      || is3MAquaUser || isXerox3DPUser,
      required: isMerckUser || (isSintaviaUser && isNormalUser)
      || (is3MAquaUser && !isNormalUser) || isXerox3DPUser,
      id: 'field.customer_name',
      defaultMessage: 'Customer Name',
    },
    ipSensitivity: {
      show: true,
      required: isDanfossUser,
    },
    priority: {
      show: isNormalUser,
    },
    dueDate: {
      required: isSintaviaUser,
      min: isNormalUser ? null : dayjs().add(3, 'days').format('YYYY-MM-DD'),
    },
    customer_go: {
      show: isBoeingUser && isNormalUser,
      required: false,
    },
    planning_status: {
      show: isBoeingUser && isNormalUser,
      required: false,
    },
    build_file_status: {
      show: isBoeingUser && isNormalUser,
      required: false,
    },
    location: {
      show: (isEditForm && showLocationOnEditForm) || (isCreateForm && showLocationOnCreateForm),
    },
  });
};
