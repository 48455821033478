import React, { useState } from 'react';
import PropTypes from 'prop-types';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import Fa from 'react-fontawesome';
import _sumBy from 'lodash/sumBy';
import _filter from 'lodash/filter';
import { connect } from 'react-redux';
import Actions from 'rapidfab/actions';
import { MATERIAL_BATCH_ACTION_TYPES, ROUTES, CONTAINERIZE_QUANTITY_FIELD_STEP, API_RESOURCES } from 'rapidfab/constants';
import { Button, Col, FormLabel, Form, FormControl, Modal, Row } from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';
import { FormattedMessage } from 'react-intl';
import Alert from 'rapidfab/utils/alert';
import { materialBatchResourceType } from 'rapidfab/types';

const ContainerizeBlock = ({
  batch,
  isSaving,
  containerizeBatch,
}) => {
  const emptyContainer = { quantity: null };

  const [isModalShown, setShowModal] = useState(false);
  const [containers, setContainers] = useState([{ ...emptyContainer }]);

  if (!batch) {
    return null;
  }

  // No need to show `Containerise` options when there is no quantity left
  if (!batch.quantity) {
    return null;
  }

  // Hide `Containerize` options when batch is loaded into a machine
  if (batch.at_machine) {
    return null;
  }

  if (batch.material_in_containers) {
    return (
      <a
        href={getRouteURI(ROUTES.PRINT_BATCH_CONTAINERS_QR_CODES, { uuid: batch.uuid })}
        className="pull-right"
      >
        <Fa name="qrcode" size="lg" className="spacer-right" />
        Print Containers QR Codes
      </a>
    );
  }

  const specifiedQuantity = _sumBy(containers, 'quantity');

  const leftQuantity = batch.quantity - specifiedQuantity;

  const isSubmitAllowed = leftQuantity === 0;

  const closeModal = () => {
    setShowModal(false);
  };

  const showModal = () => {
    setShowModal(true);
  };

  const addNewContainer = () => {
    setContainers([
      ...containers,
      { ...emptyContainer },
    ]);
  };

  const removeContainer = index => {
    const changedContainers = [...containers];
    changedContainers.splice(index, 1);
    setContainers(changedContainers);
  };

  const onQuantityChange = (index, event) => {
    const { target: { value } } = event;
    const changedContainers = [...containers];
    changedContainers[index].quantity = value !== '' ? Number(value) : null;
    setContainers(changedContainers);
  };

  const onSubmit = event => {
    event.preventDefault();
    if (!isSubmitAllowed) {
      if (leftQuantity > 0) {
        Alert.error(
          <FormattedMessage
            id="toaster.error.containerizeBlock.remainingQuantity"
            defaultMessage="You still have {leftQuantity} {batchUnits} remaining to be containerised."
            values={{ leftQuantity, batchUnits: batch.units }}
          />,
        );
      } else {
        Alert.error(
          <FormattedMessage
            id="toaster.error.containerizeBlock.allRemainingQuantity"
            defaultMessage="All remaining quantity needs to be containerised."
          />);
      }
      return;
    }
    containerizeBatch(
      batch.uri,
      containers,
    );
    closeModal();
  };

  return (
    <>
      <p>
        <b>Note:</b>
        Your material is not currently in containers. Would you like to place your material back into containers?
      </p>
      <Button variant="primary" onClick={showModal} className="pull-right" disabled={isSaving}>
        {isSaving && <Loading inline />} Containerize Material
      </Button>
      <Modal show={isModalShown} onHide={closeModal} backdrop="static">
        <Form onSubmit={onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              Please specify the amount of material in each container
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <b>Note:</b> Specifying how a batch is stored in containers
              {' '}will create a new QR code for that container but will NOT
              {' '}create a new Batch ID (it will keep the original Batch ID).
              {' '}To create a new batch, please use the Split action in the QR app.
            </p>
            <p>
              <b>Remaining amount of Material to be containerized:</b>
              &nbsp;
              <span className={leftQuantity === 0 ? 'text-success' : 'text-danger'}>{leftQuantity} {batch.units}</span>
            </p>
            {containers.map((container, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Row key={index} className="form-group">
                <Col xs={4}>
                  <FormLabel htmlFor={`containerQuantity${index}`}>
                    Container {index + 1}:
                  </FormLabel>
                </Col>
                <Col xs={4}>
                  <FormControl
                    id={`containerQuantity${index}`}
                    type="number"
                    step={CONTAINERIZE_QUANTITY_FIELD_STEP}
                    min="0"
                    placeholder="0"
                    max={batch.quantity}
                    onChange={event => onQuantityChange(index, event)}
                    value={String(container.quantity)}
                  />
                </Col>
                <Col xs={4}>
                  {containers.length > 1 && (
                    <Button variant="danger" onClick={() => removeContainer(index)} className="pull-right">
                      <Fa name="times" />
                    </Button>
                  )}
                </Col>
              </Row>
            ))}
            <Button variant="success" onClick={addNewContainer}>
              + Add Container
            </Button>
            <div className="clearfix" />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeModal}>
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="success" type="submit" disabled={isSaving}>
              {isSaving && <Loading inline />}
              <FormattedMessage id="button.save" defaultMessage="Save" />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

ContainerizeBlock.defaultProps = {
  batch: null,
};

ContainerizeBlock.propTypes = {
  batch: materialBatchResourceType,
  isSaving: PropTypes.bool.isRequired,
  containerizeBatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    containerizeBatch(batchUri, containers) {
      const payload = {
        action_type: MATERIAL_BATCH_ACTION_TYPES.CONTAINERIZE_BATCH,
        source_batch: batchUri,
        metadata: {
          containers: _filter(containers, container => container.quantity > 0),
        },
      };

      return dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH_ACTION].post(payload));
    },
  };
}

const mapStateToProps = state => {
  const isSaving = state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH_ACTION].post.fetching;

  return {
    isSaving,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContainerizeBlock);
