import { createSelector } from 'reselect';
import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import { getShipments } from 'rapidfab/selectors/shipment';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import dayjs from 'dayjs';
import _findLast from 'lodash/findLast';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';

export const getShipmentsForOrder = createSelector(
  [getShipments, getPredicate],
  (shipments, order) => {
    if (!order) {
      return [];
    }
    return _filter(shipments, { order: order.uri });
  },
);

export const getShipmentPackingSlips = createSelector(
  [baseStateSelectors.getStateShipmentPackingSlips, getStateResources],
  (uuids, resources) => _sortBy(
    uuids.map(uuid => resources[uuid]), packingSlip => dayjs(packingSlip.created).local(),
  ),
);

export const getShipmentPackingSlipForShipment = createSelector(
  [getPredicate, getShipmentPackingSlips],
  (shipmentUri, orderPackingSlips) => {
    if (shipmentUri) {
      return _findLast(orderPackingSlips, { shipment: shipmentUri });
    }
    return null;
  },
);
