import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Container, Row } from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';

import Table from 'rapidfab/components/Tables/Table';
import _keyBy from 'lodash/keyBy';
import { locationResourceType, subLocationResourceType, userResourceType } from 'rapidfab/types';
import TableCellLabels from 'rapidfab/components/TableCellLabels';

const LocationsGrid = ({ locations, usersByUri, subLocations }) => {
  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'location',
    },
    {
      type: 'text',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'field.address',
      accessor: 'address',
      defaultMessage: 'Address',
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'field.country',
      accessor: 'countries_served',
      defaultMessage: 'Country',
      isSortable: true,
    },
    {
      type: 'contact',
      uid: 'field.contact',
      accessor: 'contact',
      defaultMessage: 'Contact',
      users: usersByUri,
      isSortable: true,
    },
    {
      type: 'custom',
      uid: 'field.subLocations',
      accessor: 'subLocations',
      defaultMessage: 'Sub-Locations',
      isSortable: false,
      Cell: ({ row: { original } }) => {
        const subLocationsFiltered = subLocations.filter(subLocation => subLocation.location === original.uri);
        if (!subLocationsFiltered.length) {
          return (
            <FormattedMessage id="notAvailable" defaultMessage="N/A" />
          );
        }
        const labelUris = subLocationsFiltered.map(label => label.uri);
        const labelsByUri = _keyBy(subLocationsFiltered, 'uri');
        return (
          <TableCellLabels
            labelUris={labelUris}
            labelsByUri={labelsByUri}
          />
        );
      },
    },
  ];
  // add sub-locations to search
  const data = useMemo(() => locations.map(
    location => (
      {
        ...location,
        subLocations: subLocations.filter(
          subLocation => subLocation.location === location.uri,
        ).map(node => node.name).join(', '),
      }
    ),
  ), [locations, subLocations]);
  return (
    <Table
      tableID="locations"
      data={data}
      columns={columns}
      isFilteringEnabled
      withDefaultPagination
      isManualSoringEnabled={false}
      initialSortedColumn="name"
      initialSortedDesc={false}
      isUpdatedColumnShown={false}
    />
  );
};

LocationsGrid.defaultProps = {
  row: null,
};

LocationsGrid.propTypes = {
  locations: PropTypes.arrayOf(locationResourceType).isRequired,
  subLocations: PropTypes.arrayOf(subLocationResourceType).isRequired,
  usersByUri: PropTypes.objectOf(userResourceType).isRequired,
  row: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    original: PropTypes.arrayOf(locationResourceType),
  }),
};

const Locations = ({ locations, usersByUri, fetching, subLocations }) => (
  <Container fluid>
    <Row>
      <Col xs={12}>
        <BreadcrumbNav breadcrumbs={['manage', 'locations']} />
        <div className="clearfix">
          <Button
            variant="primary"
            size="sm"
            href={getRouteURI(ROUTES.LOCATION_NEW)}
            className="pull-right"
          >
            <Fa name="plus" />{' '}
            <FormattedMessage
              id="record.location.add"
              defaultMessage="Add Location"
            />
          </Button>
        </div>

        <hr />
        <Row className="mb8">
          <Col className="col-lg-10 col-12 offset-lg-1">
            <UseNonMfgLanguageFeature
              mfgLanguageComponent={(
                <div className="jumbotron">
                  <p>These are Manufacturing Locations (Locations) that can directly access Authentise MES.
                    You can limit Orders, Print Runs, or Users to specific locations.
                    &apos;Global&apos; users can see and manage all locations.
                  </p>
                  <p>Sub-Locations are used to manage Materials and organize Location&apos;s Resources.</p>
                </div>
              )}
              nonMfgLanguageComponent={(
                <div className="jumbotron">
                  <p>These are Manufacturing Locations (Locations) that can directly access Authentise MES.
                    You can limit Orders, Production Device Runs, or Users to specific locations.
                    &apos;Global&apos; users can see and manage all locations.
                  </p>
                  <p>Sub-Locations are used to manage Materials and organize Location&apos;s Resources.</p>
                </div>
              )}
            />
          </Col>
        </Row>
        {fetching ? (
          <Loading />
        ) : (
          <LocationsGrid locations={locations} usersByUri={usersByUri} subLocations={subLocations} />
        )}
      </Col>
    </Row>
  </Container>
);

Locations.propTypes = {
  fetching: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(locationResourceType).isRequired,
  subLocations: PropTypes.arrayOf(subLocationResourceType).isRequired,
  usersByUri: PropTypes.objectOf(userResourceType).isRequired,
};

export default Locations;
