import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import extractUuid from 'rapidfab/utils/extractUuid';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import WorkflowChecklistsModal from 'rapidfab/components/modals/WorkflowChecklistsModal';

const WorkflowChecklistsModalContainer = props => {
  const {
    workflowUri,
    lineItemUri,
    onInitialize,
    workflow,
  } = props;

  useEffect(() => {
    if (workflowUri && lineItemUri) {
      onInitialize(workflow?.uri ? workflowUri : null, lineItemUri);
    }
  }, [workflowUri, lineItemUri]);

  return (
    <WorkflowChecklistsModal
      {...props}
    />
  );
};

WorkflowChecklistsModalContainer.defaultProps = {
  workflowUri: null,
  workflow: null,
};

WorkflowChecklistsModalContainer.propTypes = {
  workflowUri: PropTypes.string,
  lineItemUri: PropTypes.string.isRequired,
  onInitialize: PropTypes.func.isRequired,
  workflow: PropTypes.shape({
    uri: PropTypes.string.isRequired,
  }),
  guidelineSuggestionsForLineItem: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = (state, props) => {
  const fetching = state.ui.nautilus[API_RESOURCES.WORK_CHECKLISTS_FOR_RESOURCE].list.fetching
    || state.ui.nautilus[API_RESOURCES.WORKFLOW].get.fetching
    || state.ui.nautilus[API_RESOURCES.WORKFLOW].put.fetching
    || state.ui.nautilus[API_RESOURCES.PROCESS_STEP].list.fetching
    || state.ui.nautilus[API_RESOURCES.POST_PROCESSOR].list.fetching
    || state.ui.nautilus[API_RESOURCES.PRINTER].list.fetching;

  let workflow = null;
  if (props.workflowUri) {
    workflow = Selectors.getUUIDResource(state, extractUuid(props.workflowUri));
  }
  const processSteps = Selectors.getProcessStepsForWorkflow(state, workflow);

  const workstationsByUri = Selectors.getWorkstationsByUri(state);

  const workChecklistLinkingsByLineItem = Selectors.getWorkChecklistLinkingsForResource(
    state,
    props.lineItemUri,
  );

  const workChecklistLinkingsByWorkflow = Selectors.getWorkChecklistLinkingsForResource(
    state,
    props.workflowUri,
  );

  const workChecklistLinkings = [...workChecklistLinkingsByLineItem, ...workChecklistLinkingsByWorkflow];

  const isGuidelineEngineFeatureEnabled = Selectors.isFeatureEnabled(
    state,
    FEATURES.GUIDELINES_ENGINE,
  );

  const isDebugModeEnabled = Selectors.getIsDebugModeEnabled(state);

  return {
    fetching,
    workChecklistLinkings,
    processSteps,
    workflow,
    workstationsByUri,
    isGuidelineEngineFeatureEnabled,
    isDebugModeEnabled,
  };
};

const mapDispatchToProps = dispatch => ({
  onInitialize: (workflowUri, lineItemUri) => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_CHECKLISTS_FOR_RESOURCE].list({
      related_uri: [lineItemUri, workflowUri],
    }));

    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list());

    if (workflowUri) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].get(extractUuid(workflowUri)));
      dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list({ workflows: workflowUri }));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowChecklistsModalContainer);
