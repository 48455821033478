import { createSelector } from 'reselect';
import { getStateServiceProviders } from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

export const getServiceProviders = createSelector(
  [getStateServiceProviders, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getServiceProvidersByUri = createSelector(
  [getServiceProviders],
  serviceProviders => _keyBy(serviceProviders, 'uri'),
);
