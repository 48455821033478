import { hot } from 'react-hot-loader/root';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import 'rapidfab/styles/main.scss';
import initializeStore from 'rapidfab/reducers/initializeStore';
import Config from 'rapidfab/config';
import AppFunction from './containers/appFunction';

if (Config.SENTRY_DSN) {
  Sentry.init({
    dsn: Config.SENTRY_DSN,
    release: `rapidfab@${process.env.GITDESCRIBE}`,
    integrations: [new Integrations.BrowserTracing()],
    ignoreErrors: [
      // Prevent sending 4XX API errors to sentry (since those are user-side errors)
      /^Error calling API on RESOURCE_(POST|PUT|LIST|GET|DELETE|CLONE|REPLACE)_FAILURE response status 4\d{2}$/,
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1,
  });
}
const App = () => (
  <Provider store={initializeStore()}>
    <HashRouter>
      <AppFunction />
    </HashRouter>
  </Provider>
);

export default hot(App);
