import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { Button, Card, Col, Form as BSForm, Row } from 'react-bootstrap';
import {
  API_RESOURCES,
  COMMENT_RELATED_TABLE_NAMES,
  DOCUMENT_RELATED_TABLE_NAMES,
  FEATURES,
  ROUTES,
} from 'rapidfab/constants';
import Feature from 'rapidfab/components/Feature';
import extractUuid from 'rapidfab/utils/extractUuid';
import CommentsContainer from 'rapidfab/containers/CommentsContainer';
import EditOrderFormContainer from 'rapidfab/containers/records/order/EditOrderFormContainer';
import Documents from 'rapidfab/components/records/Documents';
import { orderResourceType } from 'rapidfab/types';
import OrderEstimatesContainer from 'rapidfab/containers/records/order/OrderEstimatesContainer';
import OrderLikelyCompletionDate from 'rapidfab/containers/records/order/OrderLikelyCompletionDate';
import OrderShipmentsContainer from 'rapidfab/containers/records/order/OrderShipmentsContainer';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import ColorChits from 'rapidfab/components/records/order/ColorChits';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import Loading from 'rapidfab/components/Loading';
import Fa from 'react-fontawesome';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import OrderProgressContainer from 'rapidfab/containers/records/order/OrderProgressContainer';
import OrderQuote from './OrderQuote';
import OrderRuns from './OrderRuns';
import SaveDropdownButton from './SaveDropdownButton';
import DownloadPackingSlipButton from './DownloadPackingSlipButton';
import BAMProcessingStatus from './BAMProcessingStatus';

const PanelHeader = () => (
  <FormattedMessage
    id="record.order.summary"
    defaultMessage="Order Summary"
  />
);

const scrollToBottom = () => {
  window.scrollTo(
    0,
    document.body.scrollHeight || document.documentElement.scrollHeight,
  );
};

const OrderSummary = ({
  initialFormValues,
  uuid,
  order,
  isRestrictedUser,
  boeingOrderFieldsFeatureEnabled,
  orderPieces,
  piecesScheduledCount,
  piecesCompletedCount,
  isFetching,
  customOrderFieldReferences,
  orderQuoteAvailable,
  totalPieces,
  currentRunUris,
  isSaveDisabled,
  onDuplicate,
  onDelete,
  onCancel,
  onSave,
  onCustomFieldChange,
  initCustomFieldValues,
  currentRunUrisDoesExceedWarnThreshold,
  tabOpeningWarning: {
    showTabOpeningWarning,
    setShowTabOpeningWarning,
  },
}) => {
  const openAllCurrentRuns = () => {
    if (showTabOpeningWarning) setShowTabOpeningWarning(false);
    currentRunUris.forEach(link => {
      window.open(getRouteURI(ROUTES.RUN_EDIT, { uuid: extractUuid(link) }, {}, false),
        '_blank', 'noopener noreferrer');
    });
  };

  return (
    <Card bg="dark" className="m-b">
      <Card.Header className="pd-exp inverse">
        <PanelHeader />
      </Card.Header>
      <div className="card-body-wrapper">
        {showTabOpeningWarning && (
          <ConfirmationModal
            message="This action will open more than 3 browser tabs and may slow browser performance. Do you wish to continue?"
            handleConfirm={openAllCurrentRuns}
            handleCancel={() => setShowTabOpeningWarning(false)}
            /* 👇🏼 These will be added in a further sprint */
            // showMessageRepeatCheckbox
            // onCheckMessageAgainCheckbox={()=>{}}
          />
        )}
        <Card.Body>
          <Form
            initialValues={initialFormValues}
            mutators={{
              initCustomFieldValues,
              onCustomFieldChange,
            }}
            onSubmit={onSave}
            render={({
              handleSubmit,
              form,
              values,
            }) => (
              <BSForm horizontal onSubmit={handleSubmit}>
                <div className="order-status-items">
                  {totalPieces > 0 && (
                    isFetching
                      ? <Loading />
                      : (
                        <ColorChits
                          color="blue"
                          completed={piecesScheduledCount}
                          total={totalPieces}
                          label={(
                            <UseNonMfgLanguageFeature
                              mfgLanguageComponent={<>Pieces Scheduled</>}
                              nonMfgLanguageComponent={<>Pieces Scheduled</>}
                            />
                          )}
                        />
                      )
                  )}
                  {totalPieces > 0 && (
                    isFetching
                      ? <Loading />
                      : (
                        <ColorChits
                          color="orange"
                          completed={piecesCompletedCount}
                          total={totalPieces}
                          label={(
                            <UseNonMfgLanguageFeature
                              mfgLanguageComponent={<>Pieces Printing Complete</>}
                              nonMfgLanguageComponent={<>Pieces Printing Complete</>}
                            />
                          )}
                        />
                      )
                  )}
                  <div className="order-status-item d-flex align-items-center">
                    {totalPieces > 0 && (
                      <OrderLikelyCompletionDate
                        isFetching={isFetching}
                        orderUUID={extractUuid(order.uri)}
                        orderPieces={orderPieces}
                        piecesScheduled={piecesScheduledCount}
                      />
                    )}
                  </div>

                  <div className="order-status-item">
                    <OrderProgressContainer orderUri={order.uri} />
                  </div>

                  {currentRunUris.length > 0 && (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid
                    <Button
                      size="sm"
                      style={{ marginRight: '1rem' }}
                      className="spacer-right"
                      onClick={event => {
                        event.preventDefault();

                        if (currentRunUrisDoesExceedWarnThreshold) {
                          setShowTabOpeningWarning(true);
                          return;
                        }

                        openAllCurrentRuns();
                      }}
                      rel="noreferrer"
                    >
                      <FormattedMessage
                        id="goToCurrentRuns"
                        defaultMessage="Go to Current Runs"
                      />
                      <Fa
                        className="spacer-left"
                        name="external-link"
                      />
                    </Button>
                  )}

                  <div style={{ flexGrow: 1 }} />
                  <Feature featureName={FEATURES.ORDER_PACKING_SLIP}>
                    <DownloadPackingSlipButton
                      documentEndpointName={API_RESOURCES.ORDER_PACKING_SLIP}
                      resourceUri={order.uri}
                      isLoading={isFetching}
                    />
                  </Feature>

                  <Button
                    className="order-summary-btn"
                    variant="success"
                    size="sm"
                    style={{ marginRight: '1rem' }}
                    onClick={scrollToBottom}
                  >
                    <FormattedMessage
                      id="record.cadfile.add"
                      defaultMessage="Add CAD File"
                    />
                  </Button>
                  <SaveDropdownButton
                    className="order-summary-btn"
                    onCancel={() => onCancel(uuid)}
                    onDelete={() => onDelete(uuid)}
                    onDuplicate={() => onDuplicate(uuid)}
                    resourceName="Order"
                    disabled={isSaveDisabled}
                  />
                </div>
                <hr />
                <Row className="m-b p-a">
                  <Col xs={12} md={7}>
                    <EditOrderFormContainer
                      formValues={values}
                      initialValues={initialFormValues}
                      initialValuesEqual
                      customOrderFieldReferences={customOrderFieldReferences}
                      initCustomFieldValues={form.mutators.initCustomFieldValues}
                      onCustomFieldChange={form.mutators.onCustomFieldChange}
                    />
                  </Col>

                  <Col xs={12} md={5}>
                    {(boeingOrderFieldsFeatureEnabled && !isRestrictedUser) && (
                      <BAMProcessingStatus
                        initialValues={initialFormValues}
                        customOrderFieldReferences={customOrderFieldReferences}
                      />
                    )}
                    <div className="m-b">
                      <OrderEstimatesContainer />
                    </div>
                    <Feature featureName={FEATURES.SHIPMENT_FOR_ORDER}>
                      <OrderShipmentsContainer orderUri={order.uri} />
                    </Feature>
                    <Feature featureName={FEATURES.ORDER_DOCUMENTS}>
                      <Documents
                        relatedTable={DOCUMENT_RELATED_TABLE_NAMES.ORDER}
                        relatedUUID={extractUuid(order.uri)}
                      />
                    </Feature>
                    {orderQuoteAvailable && (
                      <div className="m-b">
                        <OrderQuote />
                      </div>
                    )}
                    <OrderRuns />
                  </Col>
                </Row>
                <Col xs={12}>
                  <CommentsContainer
                    resourceTableName={COMMENT_RELATED_TABLE_NAMES.ORDER}
                    resourceUUID={order.uuid}
                  />
                </Col>
              </BSForm>
            )}
          />
        </Card.Body>
      </div>
    </Card>
  );
};

OrderSummary.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  uuid: PropTypes.string.isRequired,
  order: orderResourceType.isRequired,
  orderPut: PropTypes.shape({
    errors: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  boeingOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  isRestrictedUser: PropTypes.bool.isRequired,
  initialFormValues: PropTypes.shape({}).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  orderPieces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  piecesScheduledCount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  piecesCompletedCount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired,
  customOrderFieldReferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initCustomFieldValues: PropTypes.func.isRequired,
  onCustomFieldChange: PropTypes.func.isRequired,
  orderQuoteAvailable: PropTypes.bool.isRequired,
  totalPieces: PropTypes.number.isRequired,
  currentRunUris: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentRunUrisDoesExceedWarnThreshold: PropTypes.bool.isRequired,
  tabOpeningWarning: PropTypes.shape({
    showTabOpeningWarning: PropTypes.bool,
    setShowTabOpeningWarning: PropTypes.func,
  }).isRequired,
};

const areStatePropsEqual = (next, previous) => JSON.stringify(previous) === JSON.stringify(next);

export default memo(OrderSummary, areStatePropsEqual);
