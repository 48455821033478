import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import WorkflowPickListModalContainer from 'rapidfab/containers/records/workflow/WorkflowPickListModalContainer';
import extractUuid from 'rapidfab/utils/extractUuid';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import _omit from 'lodash/omit';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import _map from 'lodash/map';
import {
  combineExistingStepsWithSelectedProcessTypes,
  getSaveStepsDispatchers,
  saveWorkflow,
} from 'rapidfab/utils/handleWorkflowSave';
import Alert from 'rapidfab/utils/alert';
import _difference from 'lodash/difference';
import _chunk from 'lodash/chunk';
import { API_RESOURCES, ENTITY_TYPES, LINE_ITEM_DESIGN_STAGE_STATUSES } from 'rapidfab/constants';
import { FormattedMessage } from 'react-intl';
import getEntityTypeFromUri from '../../utils/getEntityTypeFromURI';

const workflowReadOnlyFields = [
  'line_item',
  'source_workflow',
  'updated',
  'updated_by',
  'pieces',
  'materials',
];

const WorkflowModalContainer = props => {
  const fetching = useSelector(state => state.ui.nautilus[API_RESOURCES.SPECIMEN].list.fetching ||
    state.ui.nautilus[API_RESOURCES.WORK_CHECKLIST_LINKING].list.fetching ||
    state.ui.nautilus[API_RESOURCES.WORKFLOW].get.fetching ||
    state.ui.nautilus[API_RESOURCES.PROCESS_STEP].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PROCESS_STEP].get.fetching ||
    state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PRINTER].list.fetching ||
    state.ui.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.POST_PROCESSOR].list.fetching ||
    state.ui.nautilus[API_RESOURCES.SHIPPING].list.fetching);

  const order = useSelector(Selectors.getRouteUUIDResource);
  const workflow = useSelector(state => Selectors.getUUIDResource(state, extractUuid(props.workflowURI)));
  const workflowProcessSteps = useSelector(state => Selectors.getProcessStepsForWorkflow(state, workflow));

  const printerTypes = useSelector(Selectors.getAvailablePrinterTypes);
  const postProcessorTypes = useSelector(Selectors.getAvailablePostProcessorTypes);
  const shippingTypes = useSelector(Selectors.getShippings);
  const relatedEntityType = props.lineItemURI ? getEntityTypeFromUri(props.lineItemURI) : null;
  const lineItem = useSelector(state => Selectors.getUUIDResource(state, extractUuid(props.lineItemURI)));
  const lineItemQuote = useSelector(state => Selectors.getLineItemQuoteByLineItemUri(state, props.lineItemURI));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const onInitialize = workflowURI => {
    if (!workflowURI) {
      return Alert.error(
        <FormattedMessage
          id="toaster.error.workflow.loading"
          defaultMessage="Error in loading of the Workflow. Please try again."
        />);
    }
    const workflowUUID = extractUuid(workflowURI);
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].get(workflowUUID))
      .then(() => {
        dispatch(Actions.Api.nautilus[API_RESOURCES.SPECIMEN].clear('list'));
        dispatch(Actions.Api.nautilus[API_RESOURCES.SPECIMEN].list(
          { parent_workflow: workflowURI },
          null, null, true,
        ));
        return dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_CHECKLIST_LINKING].list({ related_uri: workflowURI }));
      });

    dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list({ workflows: workflowURI }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list());
    return true;
  };
  const loadProcessSteps = (processStepUris, steps) => {
    //  _chunk(processStepUris, LIST_BY_URIS_CHUNK_SIZE)
    // Limit removed in ticket sc-38531. Code  did not look useful anymore.
    // (LIST_BY_URIS_CHUNK_SIZE is 15 by default)
    // If the workflow is replaced, and you added more than 15 process steps
    // - due to this limit it will be shown 15 only,
    // But actually there are more than 15 (depends on how many you added),
    // and you will see the result only after page refresh.
    // Restore if you have API speed problems
    _chunk(processStepUris, steps.length)
      .forEach(processStepUrisChunk => {
        dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list({ uri: processStepUrisChunk }));
      });
  };
  const onWorkflowStepsDuplicate = async steps => {
    const stepUrisPromises = getSaveStepsDispatchers(
      dispatch,
      [],
      _map(
        steps.filter(step => !step.uri),
        step => ({
          ..._omit(step, ['uuid', 'uri', 'workflows', 'step_positions']),
          // If it's an already existing process step, we need to copy it with
          // source_process_step which points to parent process step
          source_process_step: step.uri,
        }),
      ),
    );
    const stepUris = steps.map(step => step.uri || stepUrisPromises.shift());
    return Promise.all(stepUris);
  };
  const onWorkflowUpdate = (
    workflowPayload,
    existingWorkflowSteps,
    workflowStepsToSave,
  ) => {
    const payload = { ...workflowPayload };

    const stepUrisPromises = getSaveStepsDispatchers(
      dispatch,
      existingWorkflowSteps,
      workflowStepsToSave,
    );

    return Promise.all(stepUrisPromises)
      .then(savedStepsUris => {
        payload.process_steps = savedStepsUris;

        saveWorkflow(dispatch, payload)
          .then(() => dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list({
            workflows: payload.uri,
          }, {}, {}, {}, true)));
      });
  };
  const refreshProcessSteps = lineItemUri =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_CHECKLISTS_FOR_RESOURCE].list({
      related_uri: lineItemUri,
    }));
  const lineItemQuoteRefresh = uuid => dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].get(uuid));
  const onWorkflowReplace = (sourceWorkflowUUID, payload, steps) => {
    const workflowPayload = { ...payload };

    const stepCopiesUris = getSaveStepsDispatchers(
      dispatch,
      [],
      _map(
        steps.filter(step => !step.uri),
        step => ({
          ..._omit(step, ['uuid', 'uri', 'workflows', 'step_positions']),
          // If it's an already existing process step, we need to copy it with
          // source_process_step which points to parent process step
          source_process_step: step.uri,
        }),
      ),
    );
    const stepUris = steps.map(step => step.uri || stepCopiesUris.shift());
    return Promise.all(stepUris)
      .then(async processStepsUris => {
        workflowPayload.process_steps = processStepsUris;
        await dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].replace(sourceWorkflowUUID, workflowPayload))
          .then(
            async response => {
              await dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].get(extractUuid(response.headers.location)));
            },
          );
      });
  };

  useEffect(() => {
    const { workflowURI } = props;
    if (workflowURI) {
      onInitialize(workflowURI);
    }
  }, [props.workflowURI]);

  useEffect(() => {
    if (workflow?.process_steps) {
      // Load all process steps if production workflow in prevProps is not defined.
      // It happens when event from event-stream with new production workflow is received,
      // but production workflow is not loaded yet. So, workflow prop is null at this moment.
      // At the next iteration (when production workflow is loaded),
      //  we will have null production workflow in prevProps
      const oldProcessSteps = workflowProcessSteps.map(step => step.uri);
      // List of steps were changed (e.g. on Save). Need to load new steps
      loadProcessSteps(_difference(workflow.process_steps, oldProcessSteps), workflow.process_steps);
    }
  }, [JSON.stringify(workflow?.process_steps)]);

  const onSave = async (selectedProcessTypes, splitWorkflow) => {
    const {
      lineItemURI,
      onWorkflowStepsCreateCallback,
      onClose,
    } = props;
    setIsSubmitting(true);
    const isLineItemWorkflow = workflow.line_item !== null;

    const changedProcessSteps = combineExistingStepsWithSelectedProcessTypes(
      workflowProcessSteps,
      selectedProcessTypes,
    );

    try {
      if (onWorkflowStepsCreateCallback) {
        // If onWorkflowStepsCreateCallback is provided,
        // only process steps should be recreated and returned as callback
        const processSteps = await onWorkflowStepsDuplicate(changedProcessSteps);
        await onWorkflowStepsCreateCallback(processSteps, splitWorkflow);
      } else if (!isLineItemWorkflow) {
        // If non line-item workflow is edited,
        // we always need to replace workflow
        // and make it "workflow for line-item"
        const payload = {
          line_item: lineItemURI,
          name: `Line item ${getShortUUID(lineItemURI)}`,
          description: `Custom production workflow for line item ${extractUuid(lineItemURI)}`,
        };
        await onWorkflowReplace(workflow.uuid, payload, changedProcessSteps).then(() => props.reloadPrints(order.uri));
        await refreshProcessSteps(lineItemURI);
      } else {
        // If it's line-item-based production workflow,
        // we're good just to edit this production workflow
        // with new process steps
        const payload = {
          // Removes fields from workflowReadOnlyFields
          // due to validation on backend
          ..._omit(workflow, workflowReadOnlyFields),
        };
        await onWorkflowUpdate(payload, workflowProcessSteps, changedProcessSteps);
      }
    } catch {
      setIsSubmitting(false);
    } finally {
      Alert.success(<FormattedMessage
        id="toaster.productionWorkflow.updated"
        defaultMessage="Production Workflow successfully updated"
      />);
      setIsSubmitting(false);
      if (order.quote_required) {
        await lineItemQuoteRefresh(lineItemQuote.uri);
      }

      onClose();
    }
  };

  const {
    onClose,
    currentProcessStepPosition,
    setChangeWorkflowNotes,
    notes,
    showChangeWorkflowNotes,
    isHidden,
  } = props;

  if (isHidden) {
    return null;
  }
  const isEditingDisabled = relatedEntityType === ENTITY_TYPES.LINE_ITEM
    && !LINE_ITEM_DESIGN_STAGE_STATUSES.includes(lineItem?.status);

  return (
    <WorkflowPickListModalContainer
      show
      fetching={fetching}
      sending={isSubmitting}
      onClose={() => onClose()}
      onSubmit={onSave}
      currentWorkflowSteps={workflowProcessSteps}
      printerTypes={printerTypes}
      postProcessorTypes={postProcessorTypes}
      shippingTypes={shippingTypes}
      currentProcessStepPosition={currentProcessStepPosition}
      setChangeWorkflowNotes={setChangeWorkflowNotes}
      notes={notes}
      showChangeWorkflowNotes={showChangeWorkflowNotes}
      isEditingDisabled={isEditingDisabled}
      isAllPrintsSelected={props.isAllPrintsSelected}
      selectedPiecesList={props.selectedPiecesList}
      workflowType={workflow.type}
    />
  );
};

WorkflowModalContainer.defaultProps = {
  lineItemURI: null,
  onWorkflowStepsCreateCallback: null,
  currentProcessStepPosition: null,
  setChangeWorkflowNotes: () => {},
  notes: '',
  showChangeWorkflowNotes: false,
  isHidden: false,
  isAllPrintsSelected: true,
  selectedPiecesList: [],
};

WorkflowModalContainer.propTypes = {
  currentProcessStepPosition: PropTypes.number,
  lineItemURI: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onWorkflowStepsCreateCallback: PropTypes.func,
  workflowURI: PropTypes.string.isRequired,
  setChangeWorkflowNotes: PropTypes.func,
  reloadPrints: PropTypes.func.isRequired,
  notes: PropTypes.string,
  showChangeWorkflowNotes: PropTypes.bool,
  isHidden: PropTypes.bool,
  isAllPrintsSelected: PropTypes.bool,
  selectedPiecesList: PropTypes.arrayOf(PropTypes.shape({})),
};
export default memo(WorkflowModalContainer);
