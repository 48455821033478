import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import {
  getCurrentUserRoleMax,
  getRouteUUIDResource,
  getTotalModelsVolumeMmForOrder,
  isFeatureEnabled,
} from 'rapidfab/selectors';
import OrderEstimates from 'rapidfab/components/records/order/edit/OrderEstimates';
import { FEATURES, USER_HIDE_INFO_TYPES } from 'rapidfab/constants';

const OrderEstimatesContainer = props => {
  const order = useSelector(getRouteUUIDResource);
  const { estimates } = order;

  const totalPrintingCost = _get(estimates, 'cost.printing', null);
  const totalMaterialCost = _get(estimates, 'cost.total_material', null);
  const totalModelsVolumeMm = useSelector(state => getTotalModelsVolumeMmForOrder(state, order));
  const bureauCustom = _get(estimates, 'cost.total', null);
  const postProcessing = _get(estimates, 'cost.post_processing', null);
  const printingTime = _get(estimates, 'printing_time', null);
  const shippingAmount = _get(estimates, 'cost.shipping', null);
  const support = _get(estimates, 'materials.support', null);
  const use100Infill = useSelector(state => isFeatureEnabled(state, FEATURES.USE_100_INFILL));
  const role = useSelector(getCurrentUserRoleMax);
  const hideFinancial = role?.hide_info === USER_HIDE_INFO_TYPES.FINANCIAL;

  return (
    <OrderEstimates
      {...props}
      totalPrintingCost={totalPrintingCost}
      totalMaterialCost={totalMaterialCost}
      totalModelsVolumeMm={totalModelsVolumeMm}
      bureauCustom={bureauCustom}
      postProcessing={postProcessing}
      printingTime={printingTime}
      shippingAmount={shippingAmount}
      support={support}
      use100Infill={use100Infill}
      hideFinancial={hideFinancial}
    />
  );
};

OrderEstimatesContainer.defaultProps = {
  totalPrintingCost: null,
  totalMaterialCost: null,
  totalModelsVolumeMm: null,
  bureauCustom: null,
  postProcessing: null,
  printingTime: null,
  shippingAmount: null,
  support: null,
};

OrderEstimatesContainer.propTypes = {
  totalPrintingCost: PropTypes.number,
  totalMaterialCost: PropTypes.number,
  totalModelsVolumeMm: PropTypes.number,
  bureauCustom: PropTypes.number,
  postProcessing: PropTypes.number,
  printingTime: PropTypes.number,
  shippingAmount: PropTypes.number,
  support: PropTypes.number,
  use100Infill: PropTypes.bool.isRequired,
  hideFinancial: PropTypes.bool.isRequired,
};

export default memo(OrderEstimatesContainer);
