import React from 'react';
import PropTypes from 'prop-types';
import ServiceProvidersGrid from 'rapidfab/components/manage/ServiceProvidersGrid';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';

const ServiceProviders = ({ providers, fetching }) => (
  <Container fluid>
    <BreadcrumbNav breadcrumbs={['manage', 'serviceProviders']} />

    <Row>
      <Col xs={12}>
        <Button
          variant="primary"
          size="sm"
          href={getRouteURI(ROUTES.SERVICE_PROVIDER_NEW)}
          className="pull-right"
        >
          <Fa name="plus" />{' '}
          <FormattedMessage
            id="record.serviceProvider.add"
            defaultMessage="Add Service Provider"
          />
        </Button>
      </Col>
    </Row>

    <hr />

    <Row>
      <Col xs={12}>
        {fetching ? (
          <Loading />
        ) : (
          <ServiceProvidersGrid providers={providers} />
        )}
      </Col>
    </Row>
  </Container>
);

ServiceProviders.propTypes = {
  fetching: PropTypes.bool.isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ServiceProviders;
