import { createSelector } from 'reselect';
import { getStateAssemblyPartMeta } from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _groupBy from 'lodash/groupBy';

export const getAssemblyPartMeta = createSelector(
  [getStateAssemblyPartMeta, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getAssemblyPartMetaByUri = createSelector(
  [getAssemblyPartMeta],
  parts => _keyBy(parts, 'uri'),
);

export const getAssemblyPartMetaByAssemblyMetaUri = createSelector(
  [getAssemblyPartMeta],
  parts => _groupBy(parts, 'assembly_meta'),
);

export const getAssemblyPartMetaByProductUri = createSelector(
  [getAssemblyPartMeta],
  parts => _groupBy(parts, 'product'),
);
