import React from 'react';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';

class LegacyEndpointRedirect extends React.Component {
  componentDidMount() {
    let locationHash = window.location.hash;

    // Some years ago ROUTES.TEMPLATE_NEW(/records/template/) route was used for email notifications,
    // so replacing /template/ with /workflow/ so that a 404 page is not shown to the users.
    if (locationHash.startsWith(`${getRouteURI(ROUTES.TEMPLATE_NEW)}/`)) {
      locationHash = locationHash.replace(ROUTES.TEMPLATE_NEW, ROUTES.WORKFLOW_CREATE);
    }

    window.location.hash = locationHash;
  }

  render() {
    return null;
  }
}

export default LegacyEndpointRedirect;
