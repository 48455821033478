import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import Users from 'rapidfab/components/admin/Users';
import Loading from 'rapidfab/components/Loading';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';

// FuzzyBrad™️ algorithm
// (Poor man's fuzzy search)
const getFilterRegex = filter => {
  const isSpecialChar = /[ $()*+.?[\\\]^{|}]+$/;
  const characters = [...filter].map(char => (isSpecialChar.test(char) ? `\\${char}` : char));
  const expanded = characters.join('.*');
  return new RegExp(`^.*${expanded}.*$`);
};

const UsersContainer = props => {
  const { readOnly } = props;
  const users = useSelector(Selectors.getUsers);
  const roles = useSelector(Selectors.getRoles);
  const isLoading = useSelector(state => state.ui.nautilus[API_RESOURCES.USERS].list.fetching);
  const rolesGroupedByUsername = useSelector(Selectors.getRolesGroupedByUsername);

  const dispatch = useDispatch();

  const fetchUsers = () => !readOnly &&
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list(
      {}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
    ));
  const fetchLocations = () => !readOnly &&
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());

  useEffect(() => {
    fetchUsers();
    fetchLocations();
  }, []);

  const { filter, roleFilter } = props;

  const lowerCaseFilter = filter.toLowerCase();
  const filterRegex = getFilterRegex(lowerCaseFilter);
  const filteredUsers = filter || roleFilter ? users.filter(({ name, username }) => {
    const matchesEmail = username && username.toLowerCase().startsWith(lowerCaseFilter);
    const matchesName = name && filterRegex.test(name.toLowerCase());
    const matchesRole = roleFilter ?
      roles.some(role => {
        if (username !== role.username) {
          return false;
        }

        const matchesLocation = role.role === 'location-user' && role.location === roleFilter;
        return roleFilter === role.role || matchesLocation;
      }) : true;

    return matchesRole && (matchesEmail || matchesName);
  }) : users;

  return isLoading
    ? <Loading />
    : (
      <Users
        {...props}
        users={filteredUsers}
        rolesGroupedByUsername={rolesGroupedByUsername}
      />
    );
};

UsersContainer.propTypes = {
  filter: PropTypes.string.isRequired,
  roleFilter: PropTypes.string,
  selectedUsersURIs: PropTypes.arrayOf(PropTypes.string).isRequired,
  multiSelect: PropTypes.bool.isRequired,
  handleSelectionChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

UsersContainer.defaultProps = {
  roleFilter: null,
  readOnly: false,
};

export default UsersContainer;
