import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Actions from 'rapidfab/actions';
import {
  getDocumentsByUri,
  getUsersByUri,
} from 'rapidfab/selectors';
import { API_RESOURCES } from 'rapidfab/constants';
import extractUuid from 'rapidfab/utils/extractUuid';
import { FormattedMessage } from 'react-intl';
import FileInput from 'rapidfab/components/records/order/edit/FileInput';
import Loading from 'rapidfab/components/Loading';
import {
  Row,
  Col,
  Button,
  Modal,
} from 'react-bootstrap';
import { documentResourceType } from 'rapidfab/types';
import DocumentItem from './DocumentItem';

const DocumentVersionsModal = ({
  uri,
  handleClose,
  documentsByUri,
  usersByUri,
  onReplaceDocument,
  setActiveDocumentModalUri,
}) => {
  const document = documentsByUri[uri];
  const [newDocument, setNewDocument] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const onChangeDocument = event => {
    const documentToSelect = event.target.files[0];
    setNewDocument(documentToSelect);
  };

  const onUpload = () => {
    setIsUploading(true);
    onReplaceDocument(uri, newDocument)
      .then(newUri => {
        // clear the file input
        setNewDocument(null);
        setIsUploading(false);
        // set the newly created document as active so the modal will display
        // the newly uploaded document and all past ones
        setActiveDocumentModalUri(newUri);
      });
  };

  return (
    <Modal show onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage
            id="documentVersions"
            defaultMessage="Document Versions"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DocumentItem className="mb15" isLatestVersion document={document} usersByUri={usersByUri} />
        {document.previous_documents.map(documentUri => {
          const previousDocument = documentsByUri[documentUri];
          return (
            <DocumentItem className="mb15" key={documentUri} document={previousDocument} usersByUri={usersByUri} />
          );
        })}
        <Row>
          <Col sm={9}>
            <FileInput
              fileType={FileInput.fileTypes.document}
              handleFileChange={onChangeDocument}
              name={newDocument && newDocument.name}
              chooseFileLabel={(
                <FormattedMessage
                  id="chooseDocumentReplacement"
                  defaultMessage="Choose Document Replacement"
                />
              )}
            />
          </Col>
          <Col sm={3}>
            <Button variant="primary" block disabled={!newDocument} onClick={onUpload}>
              {
                isUploading ? <Loading /> : 'Upload'
              }
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

DocumentVersionsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  uri: PropTypes.string.isRequired,
  documentsByUri: PropTypes.objectOf(documentResourceType).isRequired,
  usersByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
  })).isRequired,
  onReplaceDocument: PropTypes.func.isRequired,
  setActiveDocumentModalUri: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const documentsByUri = getDocumentsByUri(state);
  const usersByUri = getUsersByUri(state);
  return {
    documentsByUri,
    usersByUri,
  };
};

const mapDispatchToProps = dispatch => ({
  onReplaceDocument: async (replacingUri, document) => {
    const { name } = document;
    const replacingUuid = extractUuid(replacingUri);

    const replaceDocumentResponse = await dispatch(
      Actions.Api.nautilus[API_RESOURCES.DOCUMENT].replace(replacingUuid, { name }),
    );
    const uri = replaceDocumentResponse.headers.location;
    const { uploadLocation } = replaceDocumentResponse.headers;

    await dispatch(Actions.UploadModel.upload(uploadLocation, document));

    // previous document must be fetched so the record can be refreshed — event-stream
    // does not operate over bureau-based fields
    // TODO remove `replacingUri` after ch30026
    await dispatch(Actions.Api.nautilus[API_RESOURCES.DOCUMENT].list({ uri: [uri, replacingUri] }));
    // returns the newly created document's URI to set new document as active
    return Promise.resolve(uri);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentVersionsModal);
