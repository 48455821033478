import React from 'react';
import PropTypes from 'prop-types';

import {
  Col,
  FormLabel,
  FormControl,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Dropdown,
  Card,
  Row,
  SplitButton, Badge, InputGroup,
} from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';

import Loading from 'rapidfab/components/Loading';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import PostProcessorProvidersContainer from 'rapidfab/containers/records/PostProcessorProvidersContainer';
import VisibleFor from 'rapidfab/components/VisibleFor';
import DisabledByAccessInfoCheck from 'rapidfab/components/DisabledByAccessInfoCheck';
import { ACCESS_INFO_ACTION_TYPES, ROUTES } from 'rapidfab/constants';
import LastUpdated from 'rapidfab/components/LastUpdated';
import { postProcessorTypeResourceType } from 'rapidfab/types';
import { Link } from 'react-router-dom';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import extractUuid from 'rapidfab/utils/extractUuid';

const styles = {
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  spacingTopNewForm: { marginTop: '-2rem' },
  spacingTopExistingForm: { marginTop: '1rem' },
};

const PostProcessorForm = ({
  duration,
  handleDelete,
  handleArchive,
  handleUnarchive,
  handleInputChange,
  handleSubmit,
  loading,
  location,
  locations,
  name,
  postProcessorType,
  postProcessorTypes,
  selectedPostProcessorType,
  submitting,
  uuid,
  isService,
  postProcessor,
  isServiceProviderRole,
  isDurationInvalid,
}) => (
  <div style={postProcessor ? styles.spacingTopExistingForm : styles.spacingTopNewForm}>
    {loading ? (
      <Loading />
    ) : (
      <form onSubmit={handleSubmit}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div>
            {postProcessor?.archived && (
              <Badge bg="default">
                <FormattedMessage id="field.archived" defaultMessage="Archived" />
              </Badge>
            )}
          </div>
          <div className="pull-right">
            <VisibleFor bureauUserRole>
              <DisabledByAccessInfoCheck
                resourceUri={postProcessor && postProcessor.uri}
                actionType={ACCESS_INFO_ACTION_TYPES.EDIT}
                renderDisabledPrefix
              >
                {({ disabled }) => (
                  <SplitButton
                    id="uxSaveDropdown"
                    type="submit"
                    variant="success"
                    size="sm"
                    title={submitting ? <Loading /> : <SaveButtonTitle />}
                    disabled={disabled}
                  >
                    {postProcessor?.archived ? (
                      <>
                        <Dropdown.Item
                          eventKey={2}
                          onClick={handleUnarchive}
                          disabled={!uuid}
                        >
                          <Fa name="archive" />{' '}
                          <FormattedMessage id="button.unarchive" defaultMessage="Unarchive" />
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey={1}
                          onClick={handleDelete}
                          disabled={!uuid}
                        >
                          <Fa name="ban" />{' '}
                          <FormattedMessage id="button.delete" defaultMessage="Delete" />
                        </Dropdown.Item>
                      </>
                    ) : (
                      <Dropdown.Item
                        eventKey={1}
                        onClick={handleArchive}
                        disabled={!uuid}
                      >
                        <Fa name="archive" />{' '}
                        <FormattedMessage id="button.archive" defaultMessage="Archive" />
                      </Dropdown.Item>
                    )}
                  </SplitButton>
                )}
              </DisabledByAccessInfoCheck>
            </VisibleFor>
          </div>
        </div>

        <br />

        <Row>
          <Col xs={12} sm={6}>
            {(!isService || uuid) && (
              <FormGroup controlId="uxName" className="form-group">
                <FormLabel>Name: *</FormLabel>
                <FormControl
                  name="name"
                  type="text"
                  required
                  disabled={isService && uuid}
                  onChange={handleInputChange}
                  value={name}
                />
              </FormGroup>
            )}
            {!isService && (
              <FormGroup controlId="uxLocation" className="form-group">
                <FormLabel>
                  <FormattedMessage
                    id="field.location"
                    defaultMessage="Location"
                  />
                  : *
                </FormLabel>
                <FormControl
                  as="select"
                  name="location"
                  onChange={handleInputChange}
                  required
                  value={location}
                >
                  {
                    // We show `Choose...` option only if no value is selected
                    // Anyway, this is an edge-case
                    // and no exact STRs found at the moment to see this issue
                    // since by default first location is auto-selected
                  }
                  {!location && (
                    <FormattedMessage
                      id="field.choose"
                      defaultMessage="Choose…"
                    >
                      {text => <option value="">{text}</option>}
                    </FormattedMessage>
                  )}
                  {locations.map(loc => (
                    <option key={loc.uri} value={loc.uri}>
                      {loc.name}
                    </option>
                  ))}
                </FormControl>
              </FormGroup>
            )}
            {
              // For MVP hiding post-processor-type field for SP role
              // since SP role does not have access to Post Processor Type API
              !isServiceProviderRole && (
                <FormGroup controlId="uxPostProcessorType" className="form-group">
                  <FormLabel>
                    <FormattedMessage
                      id="field.postProcessorType"
                      defaultMessage="Post Processor Type"
                    />
                    : *
                  </FormLabel>
                  <InputGroup>
                    <FormControl
                      as="select"
                      name="postProcessorType"
                      onChange={handleInputChange}
                      required
                      disabled={uuid}
                      value={postProcessorType}
                    >
                      {
                      // We show `Choose...` option only if no value is selected
                      // Anyway, this is an edge-case
                      // and no exact STRs found at the moment to see this issue
                      // since by default first post processor type is auto-selected
                      }
                      {!postProcessorType && (
                        <FormattedMessage
                          id="field.choose"
                          defaultMessage="Choose…"
                        >
                          {text => <option value="">{text}</option>}
                        </FormattedMessage>
                      )}
                      {postProcessorTypes
                        .filter(ppt => (!uuid ? !ppt.archived : ppt))
                        .map(ppt => (
                          <option key={ppt.uri} value={ppt.uri}>
                            {ppt.name}
                          </option>
                        ))}
                    </FormControl>
                    <InputGroup.Text>
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={getRouteURI(ROUTES.POST_PROCESSOR_TYPE_EDIT,
                          { uuid: extractUuid(postProcessorType) },
                          {}, true)}
                      >
                        <Fa name="external-link" />
                      </Link>
                    </InputGroup.Text>
                  </InputGroup>
                </FormGroup>
              )
            }
            <FormGroup controlId="uxDuration" className="form-group">
              <FormLabel>
                Workstation Default Duration: *
              </FormLabel>
              <InputGroup>
                {
                  isService ? (
                    <FormControl
                      defaultValue={duration ? duration / (24 * 60 * 60) : ''}
                      name="duration"
                      onChange={handleInputChange}
                      required
                      isInvalid={isDurationInvalid}
                    />
                  ) : (
                    <FormControl
                      defaultValue={duration}
                      name="duration"
                      onChange={handleInputChange}
                      required
                      isInvalid={isDurationInvalid}
                    />
                  )
                }
                <InputGroup.Text>
                  {
                    isService ? (
                      <span>days</span>
                    ) : (
                      <FormattedMessage
                        id="field.workstation-duration"
                        defaultMessage="HH:MM"
                      />
                    )
                  }

                </InputGroup.Text>
              </InputGroup>
            </FormGroup>
            {isDurationInvalid && (<span className="text-start text-danger">Please use HH:MM format above</span>)}
            <LastUpdated resource={postProcessor} />
          </Col>
          <Col xs={12} sm={6}>
            {selectedPostProcessorType && (
              <Card bg="dark">
                <Card.Header
                  className="pd-exp inverse"
                >{`Post Processor Type: ${selectedPostProcessorType.name}`}
                </Card.Header>
                <div className="card-body-wrapper">
                  <Card.Body>
                    <ListGroup fill>
                      <ListGroupItem style={styles.flexRow}>
                        <strong>
                          <FormattedMessage
                            id="field.description"
                            defaultMessages="Description"
                          />:
                        </strong>
                        <span>{selectedPostProcessorType.description}</span>
                      </ListGroupItem>
                      <ListGroupItem style={styles.flexRow}>
                        <strong>
                          <FormattedMessage
                            id="field.duration"
                            defaultMessage="Duration"
                          />:
                        </strong>
                        <span>
                          {selectedPostProcessorType.duration ? (
                            selectedPostProcessorType.duration
                          ) : (
                            <FormattedMessage
                              id="notAvailable"
                              defaultMessage="N/A"
                            />
                          )}
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </Card.Body>
                </div>
              </Card>
            )}
            {(isService && postProcessor) && (
              <PostProcessorProvidersContainer processorService={postProcessor} />
            )}
          </Col>
        </Row>
      </form>
    )}
  </div>
);

PostProcessorForm.defaultProps = {
  location: '',
  postProcessorType: '',
  selectedPostProcessorType: null,
  uuid: null,
  postProcessor: null,
};

PostProcessorForm.propTypes = {
  duration: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleArchive: PropTypes.func.isRequired,
  handleUnarchive: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string.isRequired,
  postProcessorType: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  postProcessorTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedPostProcessorType: PropTypes.shape({
    description: PropTypes.string,
    duration: PropTypes.number,
    name: PropTypes.string,
  }),
  submitting: PropTypes.bool.isRequired,
  uuid: PropTypes.string,
  isService: PropTypes.bool.isRequired,
  postProcessor: postProcessorTypeResourceType,
  isServiceProviderRole: PropTypes.bool.isRequired,
  isDurationInvalid: PropTypes.bool.isRequired,
};

export default PostProcessorForm;
