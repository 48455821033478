import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, FormGroup } from 'react-bootstrap';
import { FEATURES } from 'rapidfab/constants';
import Feature from 'rapidfab/components/Feature';

const ModelLibraryInput = ({ onClick }) => (
  <Feature featureName={FEATURES.SPECIMEN_LIBRARY}>
    <FormGroup style={{ marginLeft: 0, marginRight: '15px', position: 'relative' }}>
      <Button className="m-b-sm" variant="default" onClick={onClick}>
        <FormattedMessage
          id="chooseModelFromLibrary"
          defaultMessage="Choose model from library"
        />
      </Button>
    </FormGroup>
  </Feature>
);

ModelLibraryInput.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ModelLibraryInput;
