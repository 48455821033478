import React, { useEffect, useState } from 'react';
import WIPMatrix from 'rapidfab/components/work/WIPMatrix';
import { useDispatch, useSelector } from 'react-redux';
import {
  API_RESOURCES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  ROUTES,
  WIP_MATRIX_TIMING_STATUSES,
  FEATURES,
} from 'rapidfab/constants';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _uniq from 'lodash/uniq';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import extractUuid from 'rapidfab/utils/extractUuid';
import usePrevious from 'rapidfab/hooks';
import dayjs from 'dayjs';
import NotFound from 'rapidfab/components/404';

const WIPMatrixContainer = () => {
  const dispatch = useDispatch();

  const [orderSearch, setOrderSearch] = useState('');
  const [orderSortKey, setOrderSortKey] = useState('-priority');
  const [isAscending, setIsAscending] = useState(false);
  const [orderFilterKey, setOrderFilterKey] = useState('');

  const orders = useSelector(Selectors.getOrders);
  const prints = useSelector(Selectors.getPrints);
  const locationFilter = useSelector(Selectors.getLocationFilter);
  const printsByUri = useSelector(Selectors.getPrintsByUri);
  const printsByOrder = _keyBy(prints, 'order');
  const printerTypes = useSelector(Selectors.getPrinterTypes);
  const runsForOrders = useSelector(
    state => Selectors.getRunsForOrder(state, orders),
  );
  const piecesForOrders = useSelector(
    state => Selectors.getPiecesForOrder(state, orders),
  );

  const isWIPMatrixEnabled = useSelector(
    state => Selectors.isFeatureEnabled(state, FEATURES.WIP_MATRIX));

  const [ordersFetchMoreState, setOrdersFetchMoreState] = useState({ offset: 0, count: 1 });

  const limit = 30;

  const postProcessorTypes = useSelector(Selectors.getPostProcessorTypes);
  const filteredOrdersByLocation = locationFilter ? _filter(orders, { location: locationFilter }) : orders;

  const searchedOrders = _sortBy(filteredOrdersByLocation, orderSortKey);

  const handleSearchOrders = searchText => {
    setOrderSearch(searchText);
    setOrdersFetchMoreState({ offset: 0, count: 1 });
  };

  const onSortByChange = sortBy => {
    const sort = !isAscending ? sortBy : `-${sortBy}`;
    setOrdersFetchMoreState({ offset: 0, count: 1 });
    setOrderSortKey(sort);
  };

  const handleFilterOrders = event => {
    setOrdersFetchMoreState({ offset: 0, count: 1 });
    setOrderFilterKey(event.target.value);
  };

  const openCurrentRunsForOrder = order => {
    const orderPieces = _filter(piecesForOrders, ['order', order.uri]);

    const currentRunUris = _uniq(
      _map(orderPieces, piece => {
        const currentPrint = printsByUri[piece.current_print];
        const currentRunUri = currentPrint && currentPrint.run;
        return currentRunUri;
      }),
    );

    currentRunUris.filter(Boolean).forEach(link => {
      window.open(getRouteURI(ROUTES.RUN_EDIT, { uuid: extractUuid(link) }, {}, false),
        '_blank', 'noopener noreferrer');
    });
  };

  const getTimingKey = dueDate => {
    if (dayjs(dueDate).isAfter(dayjs()) &&
      dayjs(dueDate).diff(dayjs().startOf('day'), 'days') >= 7) {
      return WIP_MATRIX_TIMING_STATUSES.ON_TIME;
    } if (dayjs(dueDate).isAfter(dayjs()) &&
      dayjs(dueDate).diff(dayjs().startOf('day'), 'days') < 7) {
      return WIP_MATRIX_TIMING_STATUSES.BEHIND;
    } if (dayjs(dueDate).isBefore(dayjs())) {
      return WIP_MATRIX_TIMING_STATUSES.LATE;
    }
    return WIP_MATRIX_TIMING_STATUSES.UNKNOWN;
  };

  const selected = {
    runsForOrders,
    postProcessorTypes,
    printerTypes,
    printsByOrder,
  };

  const previousLocation = usePrevious(locationFilter);
  const onInitialize = () => {
    const search = {};
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].clear('list'));
    const orderFilters = {};
    if (locationFilter) {
      orderFilters.location = locationFilter;
    }
    if (orderSearch) {
      search.multicolumn_search = orderSearch;
    }
    if (orderFilterKey) {
      orderFilters.run_status = orderFilterKey.toLowerCase();
    }
    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER]
      .list(orderFilters,
        { offset: locationFilter ? 0 : ordersFetchMoreState.offset, limit },
        {},
        { sort: orderSortKey, ...search },
        true))
      .then(orderResponse => {
        const orders = orderResponse?.json?.resources;
        const orderUris = _map(orders, 'uri');

        setOrdersFetchMoreState(previous => (
          { offset: locationFilter !== previousLocation
            ? 0
            : previous.offset + limit,
          count: orderResponse?.json.meta?.count || 0 }
        ));

        if (orderUris.length) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.PRINT].list({
            order: orderUris,
          }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
          dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].list({
            order: orderUris,
          }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
          dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].list({
            by_order: orderUris,
          }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
        }
      });
  };

  const fetchMoreData = async () => {
    const filters = {};
    const search = {};
    if (locationFilter) {
      filters.location = locationFilter;
    }
    if (orderSearch) {
      search.multicolumn_search = orderSearch;
    }
    if (orderFilterKey) {
      filters.run_status = orderFilterKey.toLowerCase();
    }
    const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER]
      .list(filters, { limit, offset: ordersFetchMoreState.offset }, {}, { sort: orderSortKey, ...search }, true))
      .then(orderResponse => {
        const orders = orderResponse?.json?.resources;

        const orderUris = _map(orders, 'uri');
        if (orderUris?.length) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.PRINT].list({
            order: orderUris,
          }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
          dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].list({
            order: orderUris,
          }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
          dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].list({
            by_order: orderUris,
          }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
        }
        return orderResponse;
      });

    setOrdersFetchMoreState(previous => (
      { offset: previous.offset + limit, count: response?.json.meta?.count || 0 }
    ));
  };

  useEffect(() => {
    onInitialize();
  }, [locationFilter, orderSortKey, orderFilterKey, previousLocation, orderSearch]);

  return (
    isWIPMatrixEnabled ? (
      <WIPMatrix
        {...selected}
        orders={
          !_isEmpty(orderSearch) ||
          !_isEmpty(orderSortKey) ||
          !_isEmpty(orderFilterKey)
            ?
            searchedOrders :
            orders
        }
        orderSearch={orderSearch}
        handleSearchOrders={handleSearchOrders}
        onSortByChange={onSortByChange}
        handleFilterOrders={handleFilterOrders}
        fetchMoreData={ordersFetchMoreState.offset < ordersFetchMoreState.count ?
          fetchMoreData :
          null}
        orderSortKey={orderSortKey}
        openCurrentRunsForOrder={openCurrentRunsForOrder}
        getTimingKey={getTimingKey}
        ascendingState={{ isAscending, setIsAscending }}
      />
    ) : <NotFound />
  );
};

export default WIPMatrixContainer;
