import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import extractUuid from 'rapidfab/utils/extractUuid';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import PrepTaskRecordModal from 'rapidfab/components/work/PrepTaskRecordModal';
import { API_RESOURCES } from 'rapidfab/constants';
import { useDispatch, useSelector } from 'react-redux';

import { PREP_TASK_RECORD_MODAL_CONTAINER } from 'rapidfab/constants/forms';

const PrepTaskRecordModalContainer = props => {
  const isLoading = useSelector(state => state.ui.nautilus[API_RESOURCES.PREP_TASK_RECORD].get.fetching
    || state.ui.nautilus[API_RESOURCES.PREP_TASK].get.fetching
    || state.ui.nautilus[API_RESOURCES.PREP_WORKFLOW_RECORD].get.fetching
    || state.ui.nautilus[API_RESOURCES.CUSTOM_GROUP].get.fetching
    || state.ui.nautilus[API_RESOURCES.ORDER].get.fetching
    || state.ui.nautilus[API_RESOURCES.USERS].get.fetching);

  const isSubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PREP_TASK_RECORD].put.fetching);

  const prepTaskRecord = useSelector(state =>
    (props.prepTaskRecordUUID ? Selectors.getUUIDResource(state, props.prepTaskRecordUUID) : null));

  const prepTask = useSelector(state =>
    (prepTaskRecord ? Selectors.getUUIDResource(state, extractUuid(prepTaskRecord.prep_task)) : null));

  const group = useSelector(state =>
    (prepTask && prepTask.edit_group ? Selectors.getUUIDResource(state, extractUuid(prepTask.edit_group)) : null));

  const isCurrentUserManager = useSelector(Selectors.isSessionManager);
  const currentUser = useSelector(Selectors.getSession);
  const isUpdateAllowed = isCurrentUserManager
    || (group && group.members.includes(currentUser.uri));

  const prepWorkflowRecord = useSelector(state =>
    (prepTaskRecord ? Selectors.getUUIDResource(state, extractUuid(prepTaskRecord.prep_workflow_record)) : null));

  const order = useSelector(state =>
    (prepWorkflowRecord ? Selectors.getUUIDResource(state, extractUuid(prepWorkflowRecord.order)) : null));

  const usersByUri = useSelector(Selectors.getUsersByUri);
  const orderOwner = order && usersByUri[order.order_owner];

  const initialValues = prepTaskRecord || {};
  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => PREP_TASK_RECORD_MODAL_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  const selected = {
    isLoading,
    isSubmitting,
    prepTaskRecord,
    prepTask,
    order,
    orderOwner,
    isUpdateAllowed,
    initialFormValues,
  };

  const dispatch = useDispatch();
  const onInitialize = prepTaskRecordUUID => {
    if (prepTaskRecordUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_TASK_RECORD].get(prepTaskRecordUUID))
        .then(prepTaskRecordResponse => {
          const {
            prep_task: prepTaskUri,
            prep_workflow_record: prepWorkflowRecordUri,
          } = prepTaskRecordResponse.json;
          dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_TASK].get(extractUuid(prepTaskUri)))
            .then(prepTaskResponse => {
              const { edit_group: editGroupUri } = prepTaskResponse.json;
              if (editGroupUri) {
                dispatch(Actions.Api.nautilus[API_RESOURCES.CUSTOM_GROUP].get(extractUuid(editGroupUri)));
              }
            });
          dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_WORKFLOW_RECORD].get(extractUuid(prepWorkflowRecordUri)))
            .then(prepWorkflowRecordResponse => {
              const { order: orderUri } = prepWorkflowRecordResponse.json;
              dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(extractUuid(orderUri)))
                .then(orderResponse => {
                  const { order_owner: userUri } = orderResponse.json;
                  if (userUri) {
                    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].get(extractUuid(userUri)));
                  }
                });
            });
        });
    }
  };

  const onSubmit = async payload => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_TASK_RECORD].put(payload.uuid, payload));
    await dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_TASK_RECORD].get(payload.uuid));
    props.onClose();
  };

  useEffect(() => onInitialize(props.prepTaskRecordUUID), []);

  const handleClose = () => props.onClose(null);

  return (
    <PrepTaskRecordModal
      {...props}
      {...selected}
      onSubmit={onSubmit}
      onClose={handleClose}
    />
  );
};

PrepTaskRecordModalContainer.defaultProps = {
  prepTaskRecordUUID: null,
};

PrepTaskRecordModalContainer.propTypes = {
  prepTaskRecordUUID: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default PrepTaskRecordModalContainer;
