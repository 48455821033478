import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputGroup, Form } from 'react-bootstrap';
import onlyDecimal from 'rapidfab/reducers/onlyDecimal';
import { CurrencySymbols } from 'rapidfab/constants';
import toISOStringWithoutTime from 'rapidfab/utils/toISOStringWithoutTime';
import { Field } from 'react-final-form';
import { finalFormInputTypes } from 'rapidfab/types';

export function FormControlTextCareful(props) {
  const {
    value,
    onChange,
    onBlur,
    required,
    disabled,
    type,
    pattern,
    placeholder,
    inputRef,
    invalid,
  } = props;
  const safeValue = value == null ? '' : value;
  return (
    <FormControl
      type={type}
      value={safeValue}
      onBlur={onBlur}
      onChange={onChange}
      required={required}
      disabled={disabled}
      pattern={pattern}
      placeholder={placeholder}
      inputRef={inputRef}
      invalid={invalid}
    />
  );
}

FormControlTextCareful.propTypes = {
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  inputRef: PropTypes.func,
  invalid: PropTypes.bool,
};

FormControlTextCareful.defaultProps = {
  value: null,
  type: 'text',
  required: false,
  disabled: false,
  pattern: null,
  placeholder: null,
  onBlur: () => null,
  inputRef: () => true,
  invalid: false,
};
export function FormControlCost({
  name, type, required, disabled, currency, initialValue,
}) {
  return (
    <InputGroup>
      <InputGroup.Text>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: CurrencySymbols[currency] }} />
      </InputGroup.Text>
      <Field
        name={name}
        initialValue={initialValue}
        render={props => (
          <FormControl
            name={name}
            type={type}
            value={props.input.value}
            onChange={event => {
              // TODO: Replace with field-level `Normalizer` once Redux-Form is updated to v6
              if (event.target.value) {
                // eslint-disable-next-line no-param-reassign
                event.target.value = onlyDecimal(event.target.value);
              }
              return props.input.onChange(event);
            }}
            required={required}
            disabled={disabled}
          />
        )}
      />
    </InputGroup>
  );
}

FormControlCost.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  input: finalFormInputTypes.isRequired,
  initialValue: PropTypes.string.isRequired,
};

FormControlCost.defaultProps = {
  type: 'number',
  required: false,
  disabled: false,
};

export function CustomFormControlCost({
  name, value, type, onChange, required, disabled, currency,
}) {
  return (
    <InputGroup>
      <InputGroup.Text>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: CurrencySymbols[currency] }} />
      </InputGroup.Text>
      <FormControl
        name={name}
        type={type}
        value={value}
        onChange={event => {
          // TODO: Replace with field-level `Normalizer` once Redux-Form is updated to v6
          if (event.target.value) {
            // eslint-disable-next-line no-param-reassign
            event.target.value = onlyDecimal(event.target.value);
          }
          return onChange(event);
        }}
        required={required}
        disabled={disabled}
      />
    </InputGroup>
  );
}

CustomFormControlCost.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

CustomFormControlCost.defaultProps = {
  type: 'number',
  required: false,
  disabled: false,
};

export const identity = value => (value);

export function FormControlTextArea({
  id,
  name,
  value,
  onChange,
  disabled,
  required,
  placeholder,
  inputRef,
  initialValue,
}) {
  return (
    <Field
      name={name}
      parse={identity}
      initialValue={initialValue}
      render={props => (
        <FormControl
          as="textarea"
          disabled={disabled}
          required={required}
          id={id}
          value={value || props.input.value || ''}
          onChange={onChange || props.input.onChange}
          className="resize-vertical"
          name={name}
          placeholder={placeholder}
          ref={inputRef}
        />
      )}
    />
  );
}

FormControlTextArea.defaultProps = {
  value: undefined,
  onChange: undefined,
  input: undefined,
};

FormControlTextArea.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  inputRef: PropTypes.func,
  initialValue: PropTypes.string,
  input: finalFormInputTypes,
};

FormControlTextArea.defaultProps = {
  id: null,
  disabled: false,
  required: false,
  name: null,
  placeholder: '',
  initialValue: null,
  inputRef: () => true,
};

export function FormControlTextAreaWithCustomHandlers({
  id,
  name,
  value,
  onChange,
  disabled,
  required,
  placeholder,
  inputRef,
}) {
  return (
    <FormControl
      as="textarea"
      disabled={disabled}
      required={required}
      id={id}
      value={value || ''}
      onChange={onChange}
      className="resize-vertical"
      name={name}
      placeholder={placeholder}
      ref={inputRef}
    />
  );
}

FormControlTextAreaWithCustomHandlers.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  inputRef: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

FormControlTextAreaWithCustomHandlers.defaultProps = {
  placeholder: '',
  name: null,
  disabled: false,
  required: false,
};

export function FormControlCheckboxWithCustomHandlers({
  id,
  name,
  value,
  onChange,
  disabled,
  required,
  inputRef,
}) {
  return (
    <Form.Check
      disabled={disabled}
      required={required}
      type="checkbox"
      id={id}
      checked={value === 'true'}
      onChange={onChange}
      name={name}
      ref={inputRef}
    />
  );
}

FormControlCheckboxWithCustomHandlers.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  inputRef: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

FormControlCheckboxWithCustomHandlers.defaultProps = {
  id: null,
  name: null,
  disabled: false,
  required: false,
};

export function FormControlSelect({
  onChange,
  children,
  id,
  value,
  name,
  disabled,
  className,
  required,
  multiple,
}) {
  return (
    <FormControl
      className={className}
      as="select"
      id={id}
      value={value}
      multiple={multiple}
      onChange={onChange}
      name={name}
      disabled={disabled}
      required={required}
    >
      {children}
    </FormControl>
  );
}

FormControlSelect.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  multiple: PropTypes.bool,
};

FormControlSelect.defaultProps = {
  required: false,
  disabled: false,
  name: null,
  value: null,
  className: '',
  multiple: false,
};

export function FormControlDate({
  onChange,
  id,
  value,
  name,
  disabled,
  required,
  ...otherProps
}) {
  const dateIn4Years = new Date();
  dateIn4Years.setFullYear(dateIn4Years.getFullYear() + 4);
  return (
    <FormControl
      type="date"
      id={id}
      value={value}
      max={toISOStringWithoutTime(dateIn4Years)}
      onChange={onChange}
      name={name}
      disabled={disabled}
      required={required}
      {...otherProps}
    />
  );
}

FormControlDate.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

FormControlDate.defaultProps = {
  disabled: false,
  required: false,
  name: null,
  value: null,
};
