import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'rapidfab/i18n';
import Fa from 'react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const Plan = () => (
  <span>
    <Fa name="calendar" /> <FormattedMessage id="plan" defaultMessage="Plan" />
  </span>
);

export const Work = () => (
  <span>
    <Fa name="wrench" /> <FormattedMessage id="work" defaultMessage="Work" />
  </span>
);

export const Inventory = () => (
  <span>
    <Fa name="list" />{' '}
    <FormattedMessage id="inventory" defaultMessage="Inventory" />
  </span>
);

export const Manage = () => (
  <span>
    <Fa name="cog" />{' '}
    <FormattedMessage id="manage" defaultMessage="Manage" />
  </span>
);

export const MyProfile = ({ currentUser }) => (
  <>
    <OverlayTrigger
      placement="bottom"
      overlay={(
        <Tooltip id="currentUsername">
          {currentUser ? (currentUser.name || currentUser.username) : '...'}
        </Tooltip>
      )}
    >
      <Fa name="user-circle" />
    </OverlayTrigger>
  </>
);
MyProfile.defaultProps = {
  currentUser: null,
};
MyProfile.propTypes = {
  currentUser: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
  }),
};
