/**
 * Current file contains all previously created constants.
 * All constants require moving to separate files based on functionality those are related to
 * (e.g. entity name, feature name, etc. ADR/clarifications might be required)
 *
 * Each constant might require separate test whenever possible.
 *
 * A separate task to cover these changes might be required
 */

import config from 'rapidfab/config';
import { FEATURES } from './features';

export const Currencies = ['USD', 'GBP', 'JPY', 'EUR', 'DKK', 'INR', 'SEK'];
export const CurrencySymbols = {
  USD: '$',
  GBP: '&pound;',
  JPY: '&yen;',
  EUR: '&euro;',
  DKK: 'kr.',
  INR: '₹',
  SEK: 'kr',
};

export const UNITS = {
  metric: 'metric',
  imperial: 'imperial',
};

export const PROTOCOL_REGEX = new RegExp(/(http(s?)):\/\//, 'i');

export const SCYLLA_MODELERS_URI = `${config.HOST.SCYLLA}/#/analytics/modelers`;

export const SERVICE_PROVIDER_JOB_STATUS = {
  BID_REQUESTED: 'bid_requested',
  BID_UPDATED: 'bid_updated',
  BID_NEGOTIATED: 'bid_negotiated',
  SHIPPING_TO: 'shipping_to',
  IN_PROGRESS: 'in_progress',
  SHIPPING_FROM: 'shipping_from',
  COMPLETED: 'completed',
  DECLINED: 'declined',
  CANCELLED: 'cancelled',
};

export const SERVICE_PROVIDER_JOB_END_STATUSES = [
  // On these statuses bureau can't move job to any of another statuses
  // Service Provider don't have access to order and line item on these statuses
  SERVICE_PROVIDER_JOB_STATUS.COMPLETED,
  SERVICE_PROVIDER_JOB_STATUS.CANCELLED,
  SERVICE_PROVIDER_JOB_STATUS.DECLINED,
];

export const LINE_ITEM_SERVICE_PROVIDER_ROLE = {
  BUREAU_USER: 'bureau_user',
  SERVICE_PROVIDER_USER: 'service_provider_user',
};

export const BUILD_STATUS = {
  COMPLETE: 'complete',
  FAILED: 'failed',
  MACHINE_ERROR: 'machine_error',
  NEW: 'new',
  PART_EXCEEDS_BOUNDARIES: 'part_exceeds_boundaries',
  PROCESSING: 'processing',
  SUSPENDED: 'suspended',
  USER_ABORT: 'user_abort',
};

export const BUILD_TIME_FIELD = {
  START_TIME: 'start_time',
  END_TIME: 'end_time',
  CREATED: 'created',
  UPDATED: 'updated',
};

export const BOEING_FINANCE_REVIEW_STATUS = {
  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
  COMPLETE: 'complete',
};

export const COMMENT_CHARACTER_LIMIT = 1024;

export const RUN_UNLOCKED_MAX_PRINTS = 5;

export const RUN_STATUSES = {
  CALCULATING: 'calculating',
  CALCULATED: 'calculated',
  QUEUED: 'queued',
  IN_PROGRESS: 'in-progress',
  PAUSED: 'paused',
  COMPLETE: 'complete',
  ERROR: 'error',
  CANCELLED: 'cancelled',
};

export const RUN_OPERATIONS = {
  PRINTING: 'printing',
  POST_PROCESSING: 'post-processing',
  SHIPPING: 'shipping',
};

// In backend implementation it's like the same statuses, as in run,
// but also with `created` and `incomplete` statuses
export const PRINT_STATUSES = { ...RUN_STATUSES,
  CREATED: 'created',
  INCOMPLETE: 'incomplete' };

export const PRINT_ERROR_STATUSES = [
  PRINT_STATUSES.ERROR,
  PRINT_STATUSES.CANCELLED,
  PRINT_STATUSES.INCOMPLETE,
];

export const PIECE_STATUSES = {
  NEW: 'new',
  CONFIRMED: 'confirmed',
  QUEUED_FOR_PRINTING: 'queued-for-printing',
  PRINTING: 'printing',
  QUEUED_FOR_POST_PROCESSING: 'queued-for-post-processing',
  POST_PROCESSING: 'post-processing',
  QUEUED_FOR_SHIPPING: 'queued-for-shipping',
  SHIPPING: 'shipping',
  COMPLETE: 'complete',
  INCOMPLETE: 'incomplete',
  CANCELLED: 'cancelled',
  ERROR: 'error',
};

// List of statuses with mid-run state
// (when previous run is completed, but next is not started yet).
// "queued for printing" is excluded because it's not possible to remanufacture print
// of printing run (place when this const is used) and there is no previous run
export const PIECE_BETWEEN_RUN_STATUSES = [
  PIECE_STATUSES.QUEUED_FOR_POST_PROCESSING,
  PIECE_STATUSES.QUEUED_FOR_SHIPPING,
];

export const PROCESS_STEPS_BY_ENDPOINT = {
  'printer-type': 'Printer Types',
  'post-processor-type': 'Post Processor Types',
  shipping: 'Shipping Types',
};

export const ESTIMATOR = {
  PROSPER: 'prosper',
  AUTHENTISE: 'authentise',
  USER: 'user',
};

export const MODEL_UNITS = {
  AUTOMATIC: 'auto',
  MM: 'mm',
  CM: 'cm',
  M: 'm',
  INCHES: 'in',
  FEET: 'feet',
};

export const MATERIAL_FAMILY = {
  POLYMER: 'Polymer',
  COMPOSITE: 'Composite',
  METAL: 'Metal',
  CERAMIC: 'Ceramic',
  WAX: 'Wax',
  SAND: 'Sand',
};

export const MODEL_LIBRARY_SORT = [
  { label: 'Sort By', value: '' },
  { label: 'Name', value: 'name' },
  { label: 'Created', value: 'created' },
  { label: 'Updated', value: 'updated' },
];

export const WIP_MATRIX_SORT = [
  { label: 'Name', value: 'name' },
  { label: 'Created', value: 'created' },
  { label: 'Due Date', value: 'due_date' },
  { label: 'Priority', value: 'priority' },
];

export const WIP_MATRIX_FILTERS = [
  { label: 'Filters', value: '' },
  { label: 'Error', value: 'error' },
  { label: 'In Progress', value: 'in-progress' },
  { label: 'To Do', value: 'queued' },
  { label: 'Done', value: 'complete' },
];

export const MODEL_ACTIONS = {
  MESH_HEAL_VIA_AUTHENTISE: 'mesh_heal_via_Authentise',
  PROCESSSING_MESH_HEAL_VIA_AUTHENTISE: 'processsing_mesh_heal_via_Authentise',
};

export const ORDER_QUOTE_STATUS = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETE: 'complete',
  ERROR: 'error',
};

export const TRACEABILITY_REPORT_STATUS = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETE: 'complete',
  ERROR: 'error',
};

export const WORKFLOW_USAGE_STATES = {
  ARCHIVED: 'archived',
  ACTIVE: 'active',
  CAN_BE_SAFELY_ARCHIVED: 'can_be_safely_archived',
  INACTIVE: 'inactive',
};

export const MATERIAL_BATCH_STATUSES = {
  NEW: 'new',
  IN_USE: 'in_use',
  DONE: 'done',
  CONSUMED: 'consumed',
};

export const MATERIAL_BATCH_CUSTOM_STATUSES = {
  IN_TESTING: 'in_testing',
  TESTING_COMPLETE: 'testing_complete',
};

export const MATERIAL_CONTAINER_STATUSES = {
  NEW: 'new',
  IN_USE: 'in_use',
  EMPTY: 'empty',
};

export const WORK_INSTRUCTION_UNITS = {
  MM: 'mm',
  CM: 'cm',
  INCH: 'inch',
  GRAM: 'gram',
  KG: 'kg',
  LB: 'lb',
};

export const WORK_INSTRUCTION_THRESHOLD_ACTIONS = {
  WARNING: 'warning',
  ERROR: 'error',
  NCR_WARNING: 'warning_prompt_ncr',
  REMFG_WARNING: 'warning_prompt_remfg',
};

export const WORK_INSTRUCTION_THRESHOLD_NOTIFICATION = {
  PIECE_OWNER: 'piece_owner',
  USER_GROUP: 'user_group',
};

export const ANALYTICS_REPORT_TEMPLATE = {
  OPERATING_TIME_PER_DAY: 'operating_time_days',
  OPERATING_TIME_PER_WEEK: 'operating_time_weeks',
  YIELD: 'analytics_of_yield',
  STATE: 'analytics_of_state',
};

export const ANALYTICS_REPORT_BUTTON_LABEL = {
  [ANALYTICS_REPORT_TEMPLATE.OPERATING_TIME_PER_DAY]: 'Download Daily CSV',
  [ANALYTICS_REPORT_TEMPLATE.OPERATING_TIME_PER_WEEK]: 'Download Weekly CSV',
  [ANALYTICS_REPORT_TEMPLATE.YIELD]: 'Download Yield CSV',
  [ANALYTICS_REPORT_TEMPLATE.STATE]: 'Download State CSV',
};

export const TRACEABILITY_FILTERS = [
  { label: 'CAD Model Changes', value: 'cad_model_changes' },
  { label: 'CAD Model Replaced', value: 'cad_model_replaced' },
  { label: 'Print Events', value: 'print_events' },
  { label: 'Post Process Events', value: 'post_process_events' },
  { label: 'Work Checklist Changes', value: 'work_checklist_changes' },
  { label: 'Work Instruction Report Events', value: 'work_instruction_report' },
  // Available only when prep workflow feature is on
  { label: 'In Preparation Events', value: 'preparation_events', feature: FEATURES.PREP_WORKFLOW },
  { label: 'Manual Notes', value: 'manual_notes' },
  { label: 'Other', value: 'other' },
];

export const CAD_MODEL_REPLACED_TRACEABILITY_FILTER = [
  { label: 'CAD Model Replaced', value: 'cad_model_replaced' },
];

export const EVENT_REFERENCE_TYPES = {
  LINE_ITEM: 'line-item',
  ORDER: 'order',
  PRINT: 'print',
  RUN: 'run',
  DOCUMENT: 'document',
  PREP_WORKFLOW: 'prep-workflow',
  PREP_WORKFLOW_RECORD: 'prep-workflow-record',
  PREP_TASK_RECORD: 'prep-task-record',
};

export const BATCHING_BY_SIZE_TYPE_XY = 'by_size_xy';
export const BATCHING_BY_SIZE_TYPE_XYZ = 'by_size_xyz';
export const BATCHING_BY_NUMBER_OF_PARTS = 'by_number_of_parts';
export const BATCHING_BY_PREVIOUS_RUN = 'match_previous';
export const BATCHING_SINGLE = 'single';
export const BATCHING_TYPES = [
  { label: 'Single', value: BATCHING_SINGLE },
  { label: 'Batch By Size (XY)', value: BATCHING_BY_SIZE_TYPE_XY },
  { label: 'Batch By Size (XYZ)', value: BATCHING_BY_SIZE_TYPE_XYZ },
  { label: 'Batch By Number of Parts', value: BATCHING_BY_NUMBER_OF_PARTS },
  { label: 'Include All Parts of Previous Run', value: BATCHING_BY_PREVIOUS_RUN },
];

export const REMANUFACTURE_REASONS = {
  part_redesign: 'Part Redesign',
  delamination: 'Delamination',
  change_parameters: 'Change Parameters',
  order_print_failure: 'Other Print Failure',
};

export const RUN_TRANSFORM_TYPES = {
  REMANUFACTURE: 'remanufacture',
  REDO: 'redo',
  CHANGE_WORKFLOW: 'change_workflow',
};

export const MODEL_LIBRARY_TYPES = {
  PRODUCT: 'product',
  SPECIMEN: 'specimen',
};

export const ORDER_QUOTE_DISCOUNT_TYPES = {
  AMOUNT: 'amount',
  PERCENTAGE: 'percentage',
};

export const USER_ROLES = {
  RESTRICTED: 'restricted',
  SERVICE_PROVIDER: 'service-provider',
  UNKNOWN: 'unknown',
  MANAGER: 'manager',
  STANDARD_USER: 'standard-user',
  GLOBAL_USER: 'global-user',
  LOCATION_USER: 'location-user',
  SELF_REGISTERED_GUEST: 'self-registered-guest',
};

export const USER_ROLES_WEIGHT = {
  [USER_ROLES.SERVICE_PROVIDER]: 8,
  [USER_ROLES.MANAGER]: 7,
  [USER_ROLES.STANDARD_USER]: 6,
  [USER_ROLES.GLOBAL_USER]: 5,
  [USER_ROLES.LOCATION_USER]: 4,
  [USER_ROLES.RESTRICTED]: 3,
  [USER_ROLES.SELF_REGISTERED_GUEST]: 2,
  [USER_ROLES.UNKNOWN]: 1,
};

export const RUN_TRANSFORMATION_STATUSES = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETE: 'complete',
  ERROR: 'error',
};

export const SHIPMENT_STATUSES = {
  NEW: 'new',
  QUEUED: 'queued',
  IN_TRANSIT: 'in_transit',
  RECEIVED: 'received',
  ERROR: 'error',
  CANCELLED: 'cancelled',
};

export const SHIPMENT_DATE_TYPES = {
  DELIVERY: 'delivery',
  SHIPMENT: 'shipment',
};

// 15 is recommended size of chunks when list by URIs, to fit into the URL length limits
// 15 uris in a URL will lead to approximately 1200 symbols depending on data domain length.
export const LIST_BY_URIS_CHUNK_SIZE = 15;

export const PROCESS_STEP_SHIPPING_DIRECTION = {
  BUREAU_TO_CUSTOMER: 'bureau_to_customer',
  BUREAU_TO_SERVICE_PROVIDER: 'bureau_to_service_provider',
  SERVICE_PROVIDER_TO_BUREAU: 'service_provider_to_bureau',
};

export const PROCESS_PROVIDER_STATUSES = {
  PENDING: 'pending',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const SERVICE_PROVIDER_JOB_TARGET = {
  LINE_ITEM: 'line_item',
  PROCESS_STEP: 'process_step',
};

export const MATERIAL_UNITS = {
  GRAM: 'gram',
  KG: 'kg',
  LB: 'lb',
  MM3: 'mm3',
  CM3: 'cm3',
  INCH3: 'inch3',
};

export const MATERIAL_UNITS_MEASUREMENT_CORE = {
  VOLUME: 'volume',
  WEIGHT: 'weight',
};

export const MATERIAL_UNITS_BY_UNITS_MEASUREMENT_CORE = {
  [MATERIAL_UNITS_MEASUREMENT_CORE.VOLUME]: [
    MATERIAL_UNITS.MM3,
    MATERIAL_UNITS.CM3,
    MATERIAL_UNITS.INCH3,
  ],
  [MATERIAL_UNITS_MEASUREMENT_CORE.WEIGHT]: [
    MATERIAL_UNITS.GRAM,
    MATERIAL_UNITS.KG,
    MATERIAL_UNITS.LB,
  ],
};

export const PREP_TASK_RECORD_STATUSES = {
  NEW: 'new',
  QUEUED: 'queued',
  IN_PROGRESS: 'in-progress',
  ON_HOLD: 'on-hold',
  COMPLETE: 'complete',
  SKIPPED: 'skipped',
  CANCELLED: 'cancelled',
};

export const PREP_TASK_RECORD_ACTIVE_STATUSES = [
  PREP_TASK_RECORD_STATUSES.QUEUED,
  PREP_TASK_RECORD_STATUSES.IN_PROGRESS,
  PREP_TASK_RECORD_STATUSES.ON_HOLD,
];

export const PREP_TASK_RECORD_END_STATUSES = [
  PREP_TASK_RECORD_STATUSES.COMPLETE,
  PREP_TASK_RECORD_STATUSES.SKIPPED,
  PREP_TASK_RECORD_STATUSES.CANCELLED,
];

export const PREP_WORKFLOW_RECORD_STATUSES = {
  QUEUED: 'queued',
  IN_PROGRESS: 'in-progress',
  ON_HOLD: 'on-hold',
  COMPLETE: 'complete',
  SKIPPED: 'skipped',
  CANCELLED: 'cancelled',
};

export const PREP_WORKFLOW_RECORD_SUCCESS_END_STATUSES = [
  PREP_WORKFLOW_RECORD_STATUSES.COMPLETE,
  PREP_WORKFLOW_RECORD_STATUSES.SKIPPED,
];

export const PREP_WORKFLOW_RECORD_END_STATUSES = [
  ...PREP_WORKFLOW_RECORD_SUCCESS_END_STATUSES,
  PREP_WORKFLOW_RECORD_STATUSES.CANCELLED,
];

export const USER_SETTINGS_KEYS = {
  LINE_ITEM_AUTO_STATUS_ON_PREP_WORKFLOW_COMPLETE: 'line-item-auto-status-on-prep-workflow-complete',
  QUEUES_TOUR_COMPLETE: 'queues-tour-complete',
};

export const USER_SETTINGS_VALUES = {
  LINE_ITEM_AUTO_STATUS_ON_PREP_WORKFLOW_COMPLETE: {
    SET_CONFIRMED: 'set-confirmed',
    LEAVE_PENDING: 'leave-pending',
  },
};

export const CREATE_RUNS_STATUSES = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETE: 'complete',
  ERROR: 'error',
};

export const COMMENT_ACTION_STATUSES = {
  NEW: 'new',
  IN_PROGRESS: 'in-progress',
  COMPLETE: 'complete',
};

export const NAVBAR_HEIGHT_PX = 50;

export const CAPTURE_STATUSES = {
  COMPLETE: 'complete',
  FAILED: 'failed',
  PROCESSING: 'processing',
  NEW: 'new',
};

export const ORDER_PACKING_SLIP_STATUSES = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETE: 'complete',
  ERROR: 'error',
};
export const VIEW_MODE_OPTIONS = {
  LIST: 'list',
  CARDS: 'card',
  CHART: 'chart',
};
