import React, { useState } from 'react';
import PropTypes from 'prop-types';

import _map from 'lodash/map';
import Dropdown from 'react-bootstrap/Dropdown';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { API_RESOURCES } from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';

const Toggler = React.forwardRef(({ onClick, fetchAllUsers }, ref) => (
  <span
    className="user-searcher__toggler"
    ref={ref}
    role="button"
    tabIndex={0}
    onClick={event => {
      event.preventDefault();
      fetchAllUsers();
      onClick(event);
    }}
  >
    @
  </span>
));

const UserList = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const fetchingUsers = useSelector(state => state.ui.nautilus[API_RESOURCES.USERS].list.fetching);
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          className="select-user__input"
          placeholder="Mention someone"
          onChange={event => setValue(event.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {
            fetchingUsers ? <Loading inline className="spinner-centered-white" /> :
              React.Children.toArray(children).filter(
                child =>
                  !value || child.key.toLowerCase().includes(value),
              )
          }
        </ul>
      </div>
    );
  },
);

const UserSearcher = ({ suggestions, handleChange, fetchAllUsers }) => (
  <Dropdown className="select-user__dropdown mr15">
    <Dropdown.Toggle
      as={Toggler}
      id="dropdown-custom-components"
      fetchAllUsers={fetchAllUsers}
    />

    <Dropdown.Menu as={UserList}>
      {_map(suggestions, entity => (
        <Dropdown.Item
          key={entity.username}
          onClick={() => handleChange(entity.username)}
        >
          <div className="user-item">
            <div className="user-item__email">{entity.username}</div>
            <div className="user-item__name">{entity.name}</div>
          </div>
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

export default UserSearcher;

Toggler.propTypes = {
  onClick: PropTypes.func.isRequired,
  fetchAllUsers: PropTypes.func.isRequired,
};

UserList.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({}).isRequired,
  className: PropTypes.string.isRequired,
  'aria-labelledby': PropTypes.string.isRequired,
};

UserSearcher.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  handleChange: PropTypes.func.isRequired,
  fetchAllUsers: PropTypes.func.isRequired,
};
