import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Actions from 'rapidfab/actions';
import MaterialLotComponent from 'rapidfab/components/records/MaterialLot';
import {
  getMaterialsByUri,
  getBureauUri,
  getUUIDResource,
  getInitialMaterialBatchesForLot,
  getMaterialContainersForLot,
  getMaterialBatches,
  getRouteUUID,
  getSubLocationsForLocation,
} from 'rapidfab/selectors';
import Loading from 'rapidfab/components/Loading';
import Alert from 'rapidfab/utils/alert';
import {
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  API_RESOURCES,
  MATERIAL_LOT_ACTION_TYPES,
} from 'rapidfab/constants';
import extractUuid from 'rapidfab/utils/extractUuid';

import { MATERIAL_LOT_CONTAINER } from 'rapidfab/constants/forms';
import { FormattedMessage } from 'react-intl';

const MaterialLotContainer = props => {
  const uuid = useSelector(getRouteUUID);
  const lot = useSelector(state => getUUIDResource(state, uuid));
  const bureau = useSelector(getBureauUri);
  const batches = useSelector(getMaterialBatches);
  const isLoading = useSelector(state => state.ui.nautilus[API_RESOURCES.MATERIAL_LOT].get.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH].list.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL].list.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list.fetching);

  const materialsByUri = useSelector(state => (lot ? getMaterialsByUri(state) : null));

  const material = lot && materialsByUri[lot.material];
  const containers = useSelector(state => getMaterialContainersForLot(state, lot));
  const initialBatches = useSelector(state => getInitialMaterialBatchesForLot(state, lot));
  const subLocations = useSelector(state => getSubLocationsForLocation(state, lot?.location));
  const isLotActionSaving = useSelector(state => state.ui.nautilus[API_RESOURCES.MATERIAL_LOT_ACTION].post.fetching);
  const isLotSaving = useSelector(state => state.ui.nautilus[API_RESOURCES.MATERIAL_LOT].put.fetching);

  const initialValues = lot || {};

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => MATERIAL_LOT_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  const selected = {
    isLoading,
    uuid,
    lot,
    initialFormValues,
    bureau,
    material,
    containers,
    initialBatches,
    materialBatches: batches,
    isLotActionSaving,
    isLotSaving,
    subLocations,
  };

  const dispatch = useDispatch();

  const onInitialize = (currentUUID, currentBureau) => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].get(currentUUID)).then(response => {
        const { uri, material_purchase_order, location } = response.json;

        if (material_purchase_order) {
          dispatch(
            Actions.Api.nautilus[API_RESOURCES.MATERIAL_PURCHASE_ORDER].get(extractUuid(material_purchase_order)),
          );
        }

        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT_ACTION].list(
          { material_lot: uri },
          { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
        ));

        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list(
          { material_lot: uri },
          { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
        ));
        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].list(
          { material_lots: uri, is_initial_batch: true },
        ));
        if (location) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list({ location }));
        }
      });
    }
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: currentBureau }));
  };
  const onSaveNotes = (currentUUID, notes) => {
    const payload = { notes };
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].put(currentUUID, payload))
      .then(() => {
        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].get(currentUUID));
        Alert.success(
          <FormattedMessage
            id="toaster.notes.updated"
            defaultMessage="Notes successfully updated"
          />);
      });
  };
  const onSaveStatus = (currentUUID, status) => {
    const payload = { status };
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].put(currentUUID, payload))
      .then(() => {
        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].get(currentUUID));
        Alert.success(
          <FormattedMessage
            id="toaster.materialLot.statusUpdated"
            defaultMessage="Status successfully updated"
          />);
      });
  };
  const onSaveLot = ({ ...payload }) => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].put(uuid, payload))
      .then(() => {
        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].get(uuid));
        Alert.success(<FormattedMessage
          id="toaster.materialLot.updated"
          defaultMessage="Lot successfully updated"
        />);
      });
  };
  const onSaveContainers = async ({ uri, bulkContainersOperations, reasonCode }) => {
    const reason_code = reasonCode || null;
    if (bulkContainersOperations.create.length) {
      const createPayload = {
        action_type: MATERIAL_LOT_ACTION_TYPES.ADD_CONTAINERS,
        material_lot: uri,
        metadata: {
          containers: bulkContainersOperations.create,
          reason_code,
        },
      };
      await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT_ACTION].post(createPayload));
    }
    if (bulkContainersOperations.update.length) {
      const updatePayload = {
        action_type: MATERIAL_LOT_ACTION_TYPES.EDIT_CONTAINERS_QUANTITY,
        material_lot: uri,
        metadata: {
          containers: bulkContainersOperations.update,
          reason_code,
        },
      };
      await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT_ACTION].post(updatePayload));
    }
    if (bulkContainersOperations.delete.length) {
      const deletePayload = {
        action_type: MATERIAL_LOT_ACTION_TYPES.DELETE_CONTAINERS,
        material_lot: uri,
        metadata: {
          containers: bulkContainersOperations.delete,
          reason_code,
        },
      };
      await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT_ACTION].post(deletePayload));
    }

    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT_ACTION].list(
      { material_lot: uri },
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
    ));

    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].clear('list'));
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list(
      { material_lot: uri },
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
    ));

    Alert.success(<FormattedMessage
      id="toaster.materialContainer.updated"
      defaultMessage="Containers successfully updated"
    />);
  };
  const onReceiveLot = async (uri, notes) => {
    const payload = {
      action_type: MATERIAL_LOT_ACTION_TYPES.RECEIVE_LOT,
      material_lot: uri,
      notes,
    };
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT_ACTION].post(payload));
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list(
      { material_lot: uri },
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
    ));
    Alert.success(<FormattedMessage
      id="toaster.materialLot.updated"
      defaultMessage="Lot successfully updated"
    />);
  };

  useEffect(() => onInitialize(uuid, bureau), [uuid]);

  if (isLoading || !lot) {
    return (<Loading />);
  }

  return (
    <MaterialLotComponent
      {...props}
      {...selected}
      onSaveLot={onSaveLot}
      onSaveNotes={onSaveNotes}
      onSaveStatus={onSaveStatus}
      onSaveContainers={onSaveContainers}
      onReceiveLot={onReceiveLot}
    />
  );
};

export default MaterialLotContainer;
