import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { ORDER_STATUS_MAP } from 'rapidfab/mappings';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import { getOrders } from 'rapidfab/selectors';
import { LOCATION_FILTER_DEFAULT_VALUES, ROUTES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';

import Table from 'rapidfab/components/Tables/Table';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import DebugModeFeature from 'rapidfab/components/DebugModeFeature';
import { Link } from 'react-router-dom';

const Orders = ({ data, ...componentProps }) => {
  const { locationFilter, filters, onFilterUpdate, fetching, onSort } = componentProps;

  const columns = [
    {
      type: 'custom',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'order',
      Cell: ({ row: { original: data } }) => (
        <DebugModeFeature curl={data.uri}>
          <Link
            to={getRouteURI(ROUTES.ORDER_EDIT, { uuid: data.uuid }, null, true)}
          >
            {getShortUUID(data.uri)}
          </Link>
        </DebugModeFeature>
      ),
    },
    {
      type: 'text',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: false,
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: ORDER_STATUS_MAP,
      coloured: true,
      isSortable: false,
    },
    {
      type: 'time',
      uid: 'created',
      accessor: 'created',
      defaultMessage: 'Created',
      isSortable: true,
      isManuallySorted: true,
    },
  ];

  useEffect(() => {
    if (filters && filters.location !== locationFilter) {
      // Location Filter is hidden in Record List. Using global page location filter instead
      // Providing location filter value manually into Record List
      const updatedFilters = {
        ...filters,
        location: locationFilter,
      };

      if (locationFilter === LOCATION_FILTER_DEFAULT_VALUES.ALL) {
        delete updatedFilters.location;
      }
      onFilterUpdate(updatedFilters);
    }
  }, [locationFilter]);

  return (
    <>
      <Table
        data={data?.length > 10 ? data.slice(0, 10) : data}
        columns={columns}
        isFetching={fetching}
        isFilteringEnabled={false}
        withDefaultPagination={false}
        initialSortedColumn="created"
        initialSortedDesc
        manualSortingFunc={onSort}
        isManualSoringEnabled
        isUpdatedColumnShown={false}
      />
    </>
  );
};

Orders.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

// This request is also used to fulfill the chart data
// on the home page. Thus the limit ignoration is added
export default withRecordsListHandling(
  Orders,
  getOrders,
  ['order'],
  {
    searchBy: null,
    defaultLimit: PAGINATION_IGNORE_DEFAULT_LIMIT,
    defaultSort: '-created',
    useLocationFiltering: true,
    multicolumnSearch: false,
  },
);
