import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { pieceResourceType } from 'rapidfab/types';

import { FormattedMessage } from 'rapidfab/i18n';
import { Button, FormControl, Form } from 'react-bootstrap';
import Tooltip from 'rc-tooltip';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import Loading from 'rapidfab/components/Loading';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _values from 'lodash/values';

import {
  PIECE_BETWEEN_RUN_STATUSES,
  PRINT_TYPES,
  ROUTES,
  RUN_OPERATIONS,
  RUN_STATUSES,
  WORKFLOW_TYPES,
} from 'rapidfab/constants';

import RemanufactureModal from 'rapidfab/components/records/run/RemanufactureModal';
import WorkflowModalContainer from 'rapidfab/containers/records/WorkflowModalContainer';
import WorkChecklistModalContainer from 'rapidfab/containers/records/WorkChecklistModalContainer';
import FontAwesome from 'react-fontawesome';
import Table from 'rapidfab/components/Tables/Table';
import Alert from 'react-s-alert';
import { Link } from 'react-router-dom';
import ChangeInformationText from 'rapidfab/components/records/run/ChangeInformationText';
import Icon, { ICON_NAMES } from 'rapidfab/icons';
import extractUuid from 'rapidfab/utils/extractUuid';
import ProductionChangeColumn from './ProductionChangeColumn';
import getRouteURI from '../../../utils/getRouteURI';
import Config from '../../../config';
import NCReviewModalContainer from './NCReviewModalContainer';
import ScrapModalContainer from './ScrapModalContainer';

// eslint-disable-next-line no-shadow
const Layout = ({ Table }) => <Table />;
Layout.propTypes = { Table: PropTypes.func.isRequired };

const PRODUCTION_CHANGE_TYPES_VALUES = {
  NONCONFORMANCE: 'nonconformance',
  WORKFLOW: 'workflow',
  REMANUFACTURE: 'remanufacture',
  SCRAP: 'scrap',
  NONE: '',
};

const PRODUCTION_CHANGE_OPTIONS = [
  { label: 'None', value: PRODUCTION_CHANGE_TYPES_VALUES.NONE },
  { label: 'Flag for Non-conformance Review', value: PRODUCTION_CHANGE_TYPES_VALUES.NONCONFORMANCE },
  { label: 'Workflow Change', value: PRODUCTION_CHANGE_TYPES_VALUES.WORKFLOW },
  { label: 'Remanufacture', value: PRODUCTION_CHANGE_TYPES_VALUES.REMANUFACTURE },
  { label: 'Scrap', value: PRODUCTION_CHANGE_TYPES_VALUES.SCRAP },
];

const RunPrints = ({
  run,
  runTransformations,
  gridData,
  orders,
  selectedPrints,
  piecesByUri,
  isQRPrintTravelerFeatureEnabled,
  nextPrintsWithProcessStep,
  printingRuns,
  showPrintingRunColumn,
  isRemanufactureModalVisible,
  invertRemanufactureModalStatus,
  handleInputChange,
  remanufactureNotes,
  remanufactureReason,
  onRemanufactureModalSubmit,
  onNCReviewSubmit,
  isRemanufactureLoading,
  runRemanufacture,
  transformType,
  setTransformTypeForChangeWorkflow,
  setSelectedPrints,
  setChangeWorkflowNotes,
  initializeFieldValues,
  isBuildPlateLoading,
  workflowsByUuid,
  groupedPrintUrisByWorkChecklistLinkingUris,
  postProcessorsByUri,
  runsByUri,
  isAllPrintsSelected,
  onScrapPieceSubmit,
}) => {
  const [isProcessStepsModalShown, setIsProcessStepsModalShown] = useState(false);
  const [isWorkInstructionsModalShown, setIsWorkInstructionsModalShown] = useState(false);
  const [remanufacturedWorkflowUri, setRemanufacturedWorkflowUri] = useState(null);
  const [pieceWorkflowUri, setPieceWorkflowUri] = useState('');
  const [changeWorflowProcessStepPosition, setChangeWorflowProcessStepPosition] = useState(null);
  const [productionChange, setProductionChange] = useState(PRODUCTION_CHANGE_TYPES_VALUES.NONE);
  const [selectAllChecked, setselectAllChecked] = useState(false);
  const [isCheckboxDisabled, setisCheckboxDisabled] = useState(false);
  const [isNcReviewModalShown, setisNcReviewModalShown] = useState(false);
  const [isScrapModalShown, setIsScrapModalShown] = useState(false);
  const [selectedPiecesList, setSelectedPiecesList] = useState('');
  const [isChangeInformationExpanded, setIsChangeInformationExpanded] = useState(false);
  const [selectedProcessStep, setSelectedProcessStep] = useState('');

  const data = useMemo(() => {
    const gridTableData = gridData.map(print => ({
      ...print,
      selected: selectedPrints.some(({ uuid }) => print.uuid === uuid),
      is_qr_print_traveler_feature_enabled: isQRPrintTravelerFeatureEnabled,
      nextPrintWithProcessStep: nextPrintsWithProcessStep[print.uri],
      printingRun: printingRuns[print.uri],
      pieceName: piecesByUri[print.piece]?.name,
    }));
    const scrapTransformations = runTransformations.filter(({ type }) => type === PRODUCTION_CHANGE_TYPES_VALUES.SCRAP);
    if (scrapTransformations.length) {
      const scrapTransformationPrints = new Set(
        scrapTransformations.flatMap(transformation => transformation.prints),
      );
      return gridTableData.map(item => {
        if (scrapTransformationPrints.has(item.uri)) {
          return { ...item, nextPrintWithProcessStep: null };
        }
        return item;
      });
    }
    return gridTableData;
  }, [
    runTransformations,
    gridData,
    selectedPrints,
    isQRPrintTravelerFeatureEnabled,
    nextPrintsWithProcessStep,
    printingRuns,
    piecesByUri,
  ]);

  const checkboxDisableValues = isDisabled => {
    setisCheckboxDisabled(isDisabled);
  };

  // when production change type changes, reset selected prints, cut Change Information Text
  useEffect(() => {
    setselectAllChecked(false);
    setSelectedPrints([]);
    setIsChangeInformationExpanded(false);
  }, [productionChange]);

  // use the first print of the selected prints array to find the array of print uris
  // that match that uri's work checklist
  const arrayOfGroupedPrintUris = _values(
    groupedPrintUrisByWorkChecklistLinkingUris,
  );
  const printUrisWithSameChecklistLinking =
    selectedPrints[0] &&
    _find(arrayOfGroupedPrintUris, printUris =>
      printUris.includes(selectedPrints[0].uri),
    );

  useEffect(() => {
    if (printUrisWithSameChecklistLinking?.length) {
      const piecesToSelect = gridData.filter(
        ({ uri }) => printUrisWithSameChecklistLinking.includes(uri),
      );

      if (selectAllChecked) {
        if (printUrisWithSameChecklistLinking?.length !== selectedPrints.length) {
          setselectAllChecked(false);
        }

        setSelectedPrints(piecesToSelect);
      }
    }
  }, [printUrisWithSameChecklistLinking?.length]);

  const handleSelectAllCheckChange = ({ target: { checked } }) => {
    setselectAllChecked(checked);
    // if select all is checked select all the prints
    setSelectedPrints(checked ? gridData : []);
  };

  // since the prints have already been determined
  // to have same workflows and work instructions,
  // we can use any print's(data[0] in this case) workflow.
  const randomPrintWorkflow = data[0]?.workflow;
  const isAssemblyType = (workflowsByUuid[randomPrintWorkflow]?.type === WORKFLOW_TYPES.ASSEMBLY);

  const isNoProductionChangeSelected = !productionChange;
  const isNoPrintsSelected = !selectedPrints.length;

  // called when 'Work Instructions' Modal is closed
  const onWorkInstructionsModalClose = () => {
    setIsWorkInstructionsModalShown(false);
    initializeFieldValues();
    setSelectedProcessStep('');
  };

  // called when 'Workflow Change' model is closed
  const onProcessStepsModalClose = () => {
    setIsProcessStepsModalShown(false);
    initializeFieldValues();
  };

  const onProcessStepsModalSubmit = (newProcessSteps, splitWorkflow = false) => {
    onRemanufactureModalSubmit({ processStepURIs: newProcessSteps }, splitWorkflow)
      .then(response => {
        const { custom_workflow: workflowUri } = response.json;
        setRemanufacturedWorkflowUri(workflowUri);
        setIsProcessStepsModalShown(false);
        setIsWorkInstructionsModalShown(true);
      })
      .catch(error => Alert.error(error.message));
  };
  const onMarkingNonConformanceSubmit = (splitSchedules = false) => {
    onNCReviewSubmit(splitSchedules)
      .then(response => {
        if (response?.json) {
          setisNcReviewModalShown(false);
          Alert.success(
            <FormattedMessage
              id="toaster.ncreview.changedSuccessfully"
              defaultMessage="Changed successfully."
            />,
          );
        }
      })
      .catch(error => {
        Alert.error(error.message);
      });
  };

  const onNonConformanceModalOpen = () => {
    const piecesUris = _map(selectedPrints, 'piece');
    setisNcReviewModalShown(true);
    setSelectedPiecesList(piecesUris);
  };

  const onScrapModalOpen = () => {
    const piecesUris = _map(selectedPrints, 'piece');
    setIsScrapModalShown(true);
    setSelectedPiecesList(piecesUris);
  };

  const onChangeProductionWorkflow = () => {
    // since the prints have already been determined to have same workflows and
    // work instructions, we can use the any print or piece from the list of
    // selected prints
    const firstPrint = selectedPrints[0];

    const pieceUris = _map(selectedPrints, 'piece');
    const pieces = _map(pieceUris, uri => piecesByUri[uri]);
    const firstPiece = pieces[0];

    const workflowUuid = firstPrint.workflow;
    const workflowUri = workflowsByUuid[extractUuid(workflowUuid)]?.uri;

    const currentStepPosition = firstPiece.current_step_position;
    const { status } = firstPiece;

    setIsProcessStepsModalShown(true);
    setPieceWorkflowUri(workflowUri);
    setTransformTypeForChangeWorkflow();

    // When we have certain transitional statuses, the "current step" is a position one lower than
    // it would otherwise appear.
    const changeWorkflowProcessStepPosition =
      PIECE_BETWEEN_RUN_STATUSES.includes(status)
        ? currentStepPosition - 1
        : currentStepPosition;

    setChangeWorflowProcessStepPosition(changeWorkflowProcessStepPosition);
  };

  const onClickProductionChange = () => {
    if (productionChange === PRODUCTION_CHANGE_TYPES_VALUES.REMANUFACTURE) {
      return runRemanufacture();
    }
    if (productionChange === PRODUCTION_CHANGE_TYPES_VALUES.NONCONFORMANCE) {
      return onNonConformanceModalOpen();
    }
    if (productionChange === PRODUCTION_CHANGE_TYPES_VALUES.SCRAP) {
      return onScrapModalOpen();
    }
    return onChangeProductionWorkflow();
  };

  // need some text to display for scraps production change field
  const chooseInformationText = () => {
    switch (productionChange) {
      // TODO In case of new texts are added expand the functionality
      case PRODUCTION_CHANGE_TYPES_VALUES.NONE:
        return 'record.run.productionChangeInfo.none';
      case PRODUCTION_CHANGE_TYPES_VALUES.WORKFLOW:
        return `record.run.productionChangeInfo.workflow.${isChangeInformationExpanded ? 'expanded' : 'cut'}`;
      case PRODUCTION_CHANGE_TYPES_VALUES.REMANUFACTURE:
        return `record.run.productionChangeInfo.remanufacture.${isChangeInformationExpanded ? 'expanded' : 'cut'}`;
      case PRODUCTION_CHANGE_TYPES_VALUES.NONCONFORMANCE:
        return `record.run.productionChangeInfo.nonconformance.${isChangeInformationExpanded ? 'expanded' : 'cut'}`;
      case PRODUCTION_CHANGE_TYPES_VALUES.SCRAP:
        return `record.run.productionChangeInfo.scrap.${isChangeInformationExpanded ? 'expanded' : 'cut'}`;
      default:
        return 'record.run.productionChangeInfo.none';
    }
  };

  const shouldShowReadMore = !isChangeInformationExpanded &&
    (productionChange === PRODUCTION_CHANGE_TYPES_VALUES.NONCONFORMANCE ||
      productionChange === PRODUCTION_CHANGE_TYPES_VALUES.WORKFLOW ||
      productionChange === PRODUCTION_CHANGE_TYPES_VALUES.SCRAP ||
      productionChange === PRODUCTION_CHANGE_TYPES_VALUES.REMANUFACTURE
    );

  const shouldShowMoreInfo = isChangeInformationExpanded;

  /* You should not be able to change the workflow if the current run status is NOT:
     - 'In Progress'
     - 'Complete' */
  const isNotAllowedChangingWorkflow = productionChange === PRODUCTION_CHANGE_TYPES_VALUES.WORKFLOW
    && Object.values(RUN_STATUSES)
      .filter(status => ![RUN_STATUSES.IN_PROGRESS, RUN_STATUSES.COMPLETE].includes(status))
      .includes(run?.status);

  // when work instruction modal changed to closed, reset selected prints and select all checkbox
  useEffect(() => {
    if (!isWorkInstructionsModalShown) {
      setselectAllChecked(false);
      setSelectedPrints([]);
    }
  }, [isWorkInstructionsModalShown]);
  useEffect(() => {
    if (!isScrapModalShown) {
      setselectAllChecked(false);
      setSelectedPrints([]);
    }
  }, [isScrapModalShown]);

  const columns = [
    {
      type: 'custom',
      uid: 'piece',
      accessor: 'id',
      defaultMessage: 'Piece',
      Cell: ({
        row: {
          original,
          index,
        },
      }) => (
        <div className="d-inline">
          <span className="spacer-right">
            <Link
              to={getRouteURI(ROUTES.PIECE_EDIT, { uuid: extractUuid(original.piece) }, {}, true)}
            >
              <span className="truncate-data">
                {original.pieceName} ({index + 1})
              </span>
            </Link>
          </span>
          {original.is_qr_print_traveler_feature_enabled && (
            <span className="spacer-left">
              <a
                href={`${Config.HOST.QR}/traveler/print/${original.uuid}`}
                target="_blank"
                rel="noopener noreferrer"
                type="download"
              >
                <FontAwesome name="qrcode" size="lg" />
              </a>
            </span>
          )}
        </div>
      ),
    },
    {
      type: 'resource',
      uid: 'field.order',
      accessor: 'order',
      defaultMessage: 'Order',
      resource: 'order',
      resources: orders,
    },
    {
      type: 'custom',
      uid: 'field.partName',
      accessor: 'partName',
      defaultMessage: 'Part',
      Cell: ({ row: { original } }) => (
        <>
          {original.partName}
          {original.type === PRINT_TYPES.SPECIMEN && ' (specimen)'}
        </>
      ),
    },
    !showPrintingRunColumn && (
      {
        type: 'custom',
        uid: 'field.printingRun',
        accessor: 'printingRun',
        defaultMessage: 'Printing Run (Lot)',
        Cell: ({ row: { original: data } }) => {
          const { printingRun } = data;
          if (printingRun) {
            return printingRun.name;
          }
          return <FormattedMessage id="notAvailable" defaultMessage="N/A" />;
        },
      }
    ),
    {
      type: 'time',
      uid: 'field.dueDate',
      accessor: 'dueDate',
      defaultMessage: 'Due Date',
    },
    {
      type: 'custom',
      uid: isAssemblyType ? 'assemblyWorkflow' : 'workflow',
      accessor: 'workflow',
      defaultMessage: isAssemblyType ? 'Assembly Workflow' : 'Production Workflow',
      Cell: ({ row: { original } }) => {
        const { workflow: workflowUuid } = original;
        const workflow = workflowsByUuid[extractUuid(workflowUuid)];
        if (!workflow) return '';
        return (
          <div>
            {workflow.name}
          </div>
        );
      },
    },
    {
      type: 'custom',
      uid: 'field.nextStep',
      accessor: 'nextStep',
      defaultMessage: 'Next Step',
      Cell: ({ row: { original } }) => {
        const print = original.nextPrintWithProcessStep;
        const remanufacturedPrintURI = piecesByUri[original?.piece]?.remanufactured_to;
        const remanufacturedUUID = extractUuid(remanufacturedPrintURI);
        const remanufacturedPrint = piecesByUri[remanufacturedPrintURI];

        if (print) {
          const name = (print.process_step && print.process_step.name);
          return (name ? <Link to={`/records/run/${extractUuid(print.run)}`} onClick={() => window.scrollTo(0, 0)}>{name}</Link> : (
            <FormattedMessage
              id="processStepMissing"
              defaultMessage="[Error - Step Not Found]"
            />
          ));
        }
        return (
          <>
            <FormattedMessage id="notAvailable" defaultMessage="N/A" />
            {remanufacturedUUID && (

              <Tooltip
                placement="right"
                destroyTooltipOnHide
                overlayInnerStyle={{ padding: '10px', wordBreak: 'break-word' }}
                mouseLeaveDelay={0.4}
                overlay={(
                  <>
                    <p>This piece has been successfully remanufactured. <br />
                      Replacement piece can be found here
                      <Link
                        className="spacer-left"
                        to={getRouteURI(ROUTES.PIECE_EDIT,
                          { uuid: remanufacturedUUID }, {}, true)}
                      >
                        {remanufacturedPrint && (`${remanufacturedPrint?.name} - (${getShortUUID(remanufacturedPrint?.uuid)})`)}
                      </Link>
                    </p>
                  </>
                )}
              >
                <FontAwesome className="spacer-left spacer-right text-warning" name="exclamation-triangle" />
              </Tooltip>

            )}
          </>

        );
      },
    },
    {
      type: 'custom',
      uid: 'record.run.productionChange',
      accessor: 'productionChange',
      defaultMessage: 'Production Change',
      Cell: ({ row: { original } }) => (
        <ProductionChangeColumn
          rowData={original}
          isNoProductionChangeSelected={isNoProductionChangeSelected}
          isRemanufactureChange={
            productionChange ===
            PRODUCTION_CHANGE_TYPES_VALUES.REMANUFACTURE
          }
          isWorkflowChange={
            productionChange ===
            PRODUCTION_CHANGE_TYPES_VALUES.WORKFLOW ||
            productionChange === PRODUCTION_CHANGE_TYPES_VALUES.NONCONFORMANCE
          }
          runTransformations={runTransformations}
          selectedPrints={selectedPrints}
          setSelectedPrints={setSelectedPrints}
          printUrisWithSameChecklistLinking={
            printUrisWithSameChecklistLinking
          }
          isRunIncomplete={run.status !== RUN_STATUSES.COMPLETE}
          isAllowedChangingWorkflow={
            run.status === RUN_STATUSES.COMPLETE ||
            run.status === RUN_STATUSES.IN_PROGRESS
          }
          isNotAllowedChangingWorkflow={isNotAllowedChangingWorkflow}
          checkboxDisableValues={checkboxDisableValues}
        />
      ),
    },
  ];

  return (
    <>
      {isRemanufactureModalVisible && (
        <RemanufactureModal
          selectedPrints={selectedPrints}
          isRemanufactureModalVisible={isRemanufactureModalVisible}
          invertRemanufactureModalStatus={invertRemanufactureModalStatus}
          handleInputChange={handleInputChange}
          transformType={transformType}
          notes={remanufactureNotes}
          reason={remanufactureReason}
          submit={onRemanufactureModalSubmit}
          loading={isRemanufactureLoading}
          isPrinting={run.operation === RUN_OPERATIONS.PRINTING}
        />
      )}
      {isBuildPlateLoading ? (
        <Loading />
      ) : (
        <>
          <Table
            data={data}
            columns={columns}
            isFilteringEnabled={false}
            isUpdatedColumnShown={false}
            withDefaultPagination={false}
          />
          <div className="d-flex justify-content-end align-items-center">
            <Form.Check
              className="spacer-left"
              checked={selectAllChecked}
              disabled={isCheckboxDisabled}
              onChange={handleSelectAllCheckChange}
              label="Select All Production Change"
            />
          </div>

          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex justify-content-end mr30">
              <ChangeInformationText>
                <div className="d-flex align-items-center">
                  <Icon name={ICON_NAMES.INFO} size="large" />
                  <span>
                    <FormattedMessage
                      id="record.run.productionChangeType"
                      defaultMessage="Production Change Information"
                    />
                  </span>
                </div>
              </ChangeInformationText>
              <ChangeInformationText>
                <span>
                  <FormattedMessage
                    id={chooseInformationText()}
                    defaultMessage="Select a type of Production Change to alter the manufacturing process for a Piece"
                  />
                </span>
                &nbsp;
                {shouldShowReadMore && (
                  <span
                    tabIndex={0}
                    role="button"
                    className="change-info-expander"
                    onClick={() => setIsChangeInformationExpanded(true)}
                  >
                    read more...
                  </span>
                )}
                &nbsp;
                {shouldShowMoreInfo && (
                  <a
                    target="_blank"
                    href="https://authentise.zendesk.com/hc/en-us/articles/360055743712"
                    rel="noreferrer"
                    className="change-info-more-link"
                  >
                    More info
                  </a>
                )}
              </ChangeInformationText>
            </div>
            <FormControl
              className="wauto ml5"
              as="select"
              onChange={event => setProductionChange(event.target.value)}
              value={productionChange}
            >
              {PRODUCTION_CHANGE_OPTIONS.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </FormControl>
            <Button
              onClick={onClickProductionChange}
              bsSize="small"
              bsStyle="success"
              className="spacer-left"
              disabled={isNoProductionChangeSelected || isNoPrintsSelected}
            >
              Submit
            </Button>
          </div>
        </>
      )}
      {
        isProcessStepsModalShown && (
          <WorkflowModalContainer
            selectedPiecesList={selectedPrints}
            workflowURI={pieceWorkflowUri}
            onClose={onProcessStepsModalClose}
            onWorkflowStepsCreateCallback={onProcessStepsModalSubmit}
            currentProcessStepPosition={changeWorflowProcessStepPosition}
            setChangeWorkflowNotes={setChangeWorkflowNotes}
            notes={remanufactureNotes}
            isAllPrintsSelected={isAllPrintsSelected}
            showChangeWorkflowNotes
          />
        )
      }
      {
        isWorkInstructionsModalShown && (
          <WorkChecklistModalContainer
            relatedURI={remanufacturedWorkflowUri}
            workflowURI={remanufacturedWorkflowUri}
            onClose={onWorkInstructionsModalClose}
            selectedProcessStep={selectedProcessStep}
            selectProcessStep={setSelectedProcessStep}
          />
        )
      }
      {
        isNcReviewModalShown && (
          <NCReviewModalContainer
            selectedPiecesList={_map(selectedPiecesList, uri => piecesByUri[uri])}
            selectedPrints={selectedPrints}
            postProcessorsByUri={postProcessorsByUri}
            run={run}
            runsByUri={runsByUri}
            onClose={() => {
              setisNcReviewModalShown(false);
            }}
            ncreviewSubmitCallback={onMarkingNonConformanceSubmit}
            isAllPrintsSelected={isAllPrintsSelected}
          />
        )
      }
      {
        isScrapModalShown && (
          <ScrapModalContainer
            selectedPiecesList={_map(selectedPiecesList, uri => piecesByUri[uri])}
            selectedPrints={selectedPrints}
            postProcessorsByUri={postProcessorsByUri}
            run={run}
            runsByUri={runsByUri}
            onClose={() => setIsScrapModalShown(false)}
            onSubmit={onScrapPieceSubmit}
          />
        )
      }
    </>
  );
};

RunPrints.defaultProps = {
  isRemanufactureLoading: true,
  isRemanufactureModalVisible: false,
  remanufactureNotes: '',
  remanufactureReason: '',
  transformType: '',
  isQRPrintTravelerFeatureEnabled: false,
  setTransformTypeForChangeWorkflow: () => {},
  setSelectedPrints: () => {},
  setChangeWorkflowNotes: () => {},
  initializeFieldValues: () => {},
};

RunPrints.propTypes = {
  gridData: PropTypes.arrayOf(
    PropTypes.shape({
      customerName: PropTypes.string,
      dueDate: PropTypes.string,
      id: PropTypes.string,
      order: PropTypes.string,
      uuid: PropTypes.string,
      uri: PropTypes.string,
      nextStep: PropTypes.string,
    }),
  ).isRequired,
  piecesByUri: PropTypes.objectOf(pieceResourceType).isRequired,
  orders: PropTypes.shape({}).isRequired,
  onRemanufactureModalSubmit: PropTypes.func.isRequired,
  onScrapPieceSubmit: PropTypes.func.isRequired,
  invertRemanufactureModalStatus: PropTypes.func.isRequired,
  showPrintingRunColumn: PropTypes.bool.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  selectedPrints: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string.isRequired,
    workflow: PropTypes.string.isRequired,
  })).isRequired,
  setSelectedPrints: PropTypes.func,
  isRemanufactureModalVisible: PropTypes.bool,
  remanufactureNotes: PropTypes.string,
  runRemanufacture: PropTypes.func.isRequired,
  remanufactureReason: PropTypes.string,
  isRemanufactureLoading: PropTypes.bool,
  isQRPrintTravelerFeatureEnabled: PropTypes.bool,
  run: PropTypes.shape({
    status: PropTypes.string,
    operation: PropTypes.string,
  }).isRequired,
  runTransformations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  nextPrintsWithProcessStep: PropTypes.shape({}).isRequired,
  printingRuns: PropTypes.shape({}).isRequired,
  transformType: PropTypes.string,
  setTransformTypeForChangeWorkflow: PropTypes.func,
  setChangeWorkflowNotes: PropTypes.func,
  initializeFieldValues: PropTypes.func,
  isBuildPlateLoading: PropTypes.bool.isRequired,
  workflowsByUuid: PropTypes.objectOf(PropTypes.shape({
    uri: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  groupedPrintUrisByWorkChecklistLinkingUris: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  postProcessorsByUri: PropTypes.shape({}).isRequired,
  runsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  onNCReviewSubmit: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      piece: pieceResourceType,
      printingRun: PropTypes.shape({
        name: PropTypes.string,
      }),
      type: PropTypes.string.isRequired,
      workflow: PropTypes.shape({}),
      pieceName: PropTypes.string.isRequired,
      pieceUri: PropTypes.string.isRequired,
      is_qr_print_traveler_feature_enabled: PropTypes.bool.isRequired,
      partName: PropTypes.string.isRequired,
      nextPrintWithProcessStep: PropTypes.shape({
        process_step: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
        run: PropTypes.shape({}),
      }).isRequired,
    }),
    index: PropTypes.number,
  }).isRequired,
  isAllPrintsSelected: PropTypes.bool.isRequired,
};

export default RunPrints;
