import React from 'react';
import PropTypes from 'prop-types';
import { labelResourceType, modelLibraryResourceType, modelResourceType } from 'rapidfab/types';
import 'rapidfab/styles/componentStyles/modelLibraryCard.scss';
import TableCellLabels from 'rapidfab/components/TableCellLabels';

const ModelLibrariesGrid = ({
  modelLibraries,
  modelsByUri,
  labelsByUri,
  handleSelect,
}) => (
  <div className="card-list mb30">
    {modelLibraries.map(modelLibrary => {
      const model = modelsByUri[modelLibrary.additive.model];
      return (
        <div
          className="card"
          key={modelLibrary.uri}
          role="button"
          tabIndex={0}
          onClick={() => handleSelect(modelLibrary)}
        >
          <img className="card-img-top" src={model && model.snapshot_content} alt="" />
          <div className="card-body">
            <h5 className="card-title">{modelLibrary.name}</h5>
            {
              modelLibrary && modelLibrary.labels && modelLibrary.labels.length > 0 && (
                <div className="spacer-top">
                  <TableCellLabels
                    labelUris={modelLibrary.labels}
                    labelsByUri={labelsByUri}
                  />
                </div>
              )
            }
          </div>
        </div>
      );
    })}
  </div>
);

ModelLibrariesGrid.defaultProps = {
  handleSelect: null,
};

ModelLibrariesGrid.propTypes = {
  modelLibraries: PropTypes.arrayOf(modelLibraryResourceType).isRequired,
  labelsByUri: PropTypes.objectOf(labelResourceType).isRequired,
  handleSelect: PropTypes.func,
  modelsByUri: PropTypes.objectOf(modelResourceType).isRequired,
};

export default ModelLibrariesGrid;
