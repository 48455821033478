import React from 'react';
import PropTypes from 'prop-types';

import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import AddFromDB from 'rapidfab/containers/inventory/AddFromDBContainer';
import DisabledFor from 'rapidfab/components/DisabledFor';
import DisabledIconWithTooltip from 'rapidfab/components/DisabledIconWithTooltip';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';

import Table from 'rapidfab/components/Tables/Table';

const columns = [
  {
    type: 'uuid',
    uid: 'field.id',
    accessor: 'uuid',
    defaultMessage: 'ID',
    resource: 'printer-type',
  },
  {
    type: 'text',
    uid: 'field.name',
    accessor: 'name',
    defaultMessage: 'Name',
    isSortable: true,
  },
];

const PrinterTypes = ({ printerTypes, fetching, isGroupQualificationsFeatureEnabled }) => (
  <Container fluid>
    <BreadcrumbNav breadcrumbs={['manage', 'printerTypes']} />

    <Row>
      <Col xs={12}>
        <div className="pull-right">
          <DisabledFor
            nonManager
            /* Disable for Non Managers only when `group-qualifications` feature is enabled. No limitations otherwise */
            alwaysEnabled={!isGroupQualificationsFeatureEnabled}
            disabledPrefix={<DisabledIconWithTooltip placement="bottom" />}
          >
            {({ disabled }) => (
              <AddFromDB
                disabled={disabled}
                type="printer-type"
                buttonText={(
                  <UseNonMfgLanguageFeature
                    mfgLanguageComponent={<FormattedMessage id="record.printerType.add" defaultMessage="Add Printer Type" />}
                    nonMfgLanguageComponent={<FormattedMessage id="mfg.addPrinterType.addProductionDevice" defaultMessage="Add Production Device" />}
                  />
                )}
                modalTitle={(
                  <UseNonMfgLanguageFeature
                    mfgLanguageComponent={<FormattedMessage id="printerdb.search" defaultMessage="Printer Search" />}
                    nonMfgLanguageComponent={<FormattedMessage id="mfg.printerSearch.productionDeviceSearch" defaultMessage="Production Device Search" />}
                  />
                )}
                itemLabel={(
                  <UseNonMfgLanguageFeature
                    mfgLanguageComponent={<FormattedMessage id="field.printerType" defaultMessage="Printer Type" />}
                    nonMfgLanguageComponent={<FormattedMessage id="mfg.printerType.productionDeviceType" defaultMessage="Production Device Type" />}
                  />
                )}
                manualCreateHref={getRouteURI(ROUTES.PRINTER_TYPE_NEW)}
                manualCreateText={(
                  <UseNonMfgLanguageFeature
                    mfgLanguageComponent={<FormattedMessage id="printerdb.notlisted" defaultMessage="My printer isn’t listed" />}
                    nonMfgLanguageComponent={<FormattedMessage id="mfg.printerNotListed.productionDeviceNotListed" defaultMessage="My Production Device isn’t listed" />}
                  />
                )}
              />
            )}
          </DisabledFor>
        </div>
      </Col>
    </Row>

    <hr />

    <Row>
      <Col xs={12}>
        {fetching ? (
          <Loading />
        ) : (
          <Table
            tableID="printerTypes"
            data={printerTypes}
            columns={columns}
            isFilteringEnabled
            withDefaultPagination
            isManualSoringEnabled={false}
            initialSortedColumn="name"
            initialSortedDesc={false}
            isUpdatedColumnShown={false}
          />
        )}
      </Col>
    </Row>
  </Container>
);

PrinterTypes.propTypes = {
  printerTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
  isGroupQualificationsFeatureEnabled: PropTypes.bool.isRequired,
};

export default PrinterTypes;
