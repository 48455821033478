import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Form,
  Col,
  FormLabel,
  FormControl,
  FormGroup,
  InputGroup,
  OverlayTrigger, Row,
  Tooltip,
  Button,
} from 'react-bootstrap';

import Actions from 'rapidfab/actions';
import {
  getBureauSettings,
  getCurrentUserRoleMax,
  getLocations,
  getLocationsByUri,
  getSelectedUserLocations,
  getSelectedUserRoleMax,
  getSelectedUserRoles,
  isSessionManager,
} from 'rapidfab/selectors';
import { API_RESOURCES, USER_HIDE_INFO_TYPES, USER_ROLES } from 'rapidfab/constants';
import { connect, useSelector } from 'react-redux';
import _forEach from 'lodash/forEach';
import SelectMultiple from 'rapidfab/components/forms/SelectMultiple';
import Fa from 'react-fontawesome';
import Alert from 'rapidfab/utils/alert';
import Loading from 'rapidfab/components/Loading';
import _isEqual from 'lodash/isEqual';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import { differenceBy } from 'lodash/array';
import extractUuid from 'rapidfab/utils/extractUuid';
import QrLogonCodeModal, { QrLogonCodeDisclaimerConfirmationModal } from 'rapidfab/components/qr/QrLogonCodeModal';
import { FormattedMessage } from 'react-intl';

const roles = [
  { name: 'Manager', value: 'manager' },
  { name: 'Standard User', value: 'standard-user' },
  { name: 'Restricted User', value: 'restricted' },
  { name: 'Global User', value: 'global-user' },
];

const CURRENT_USER_MANAGER = 'CURRENT_USER_MANAGER';
const SELECTED_USER_MANAGER = 'SELECTED_USER_MANAGER';

const styles = {
  spacingVertical: { display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '30px' },
  spacingBottom: { marginBottom: '0.5rem' },
  spacingTop: { marginTop: '1.5rem' },
};

const UserAdministrationPanel = ({
  isCurrentSessionManager,
  userRoles,
  locations,
  isRoleSaving,
  user,
  createRole,
  deleteRole,
  currentUserRole,
  dispatchHideFinancials,
  hideFinancialState,
  selectedUserRoleMax,
  getSelectedUserLocation,
  locationsBy,
  getContactlessLogonQRCode,
}) => {
  const [isHideFinancials, setIsHideFinancials] = useState(hideFinancialState);
  const hasGlobalRole = userRoles.find(role => role.role === USER_ROLES.GLOBAL_USER);
  const [confirmGlobal, setConfirmGlobal] = useState(false);
  const [removingGlobal, setRemovingGlobal] = useState(false);

  const [showQrLogonCodeModal, setShowQrLogonCodeModal] = useState(false);
  const [showQrLogonCodeWarningDisclaimerModal, setShowQrLogonCodeWarningDisclaimerModal] = useState(false);
  const [qrCodeBase64Data, setQrCodeBase64Data] = useState(null);
  const [isLoadingQRCodeData, setIsLoadingQRCodeData] = useState(false);

  const transformedLocations = getSelectedUserLocation.map(currentLocation => {
    if (locationsBy[currentLocation.location]) {
      return locationsBy[currentLocation.location];
    }
    return [];
  });
  const selectedUserName = selectedUserRoleMax?.username;
  const selectedUserRole = selectedUserRoleMax?.role;
  const currentUserName = currentUserRole?.username;
  const [dataLocations, setDataLocations] = useState(transformedLocations);
  const [selectedUser, setSelectedUser] = useState(selectedUserName);
  const isCurrentUserManager = currentUserRole?.role === USER_ROLES.MANAGER;
  const isCurrentAndSelectedUserManager = (currentUserRole?.role && selectedUserRole === USER_ROLES.MANAGER) &&
    (currentUserName === selectedUserName);
  const rolesToShow = [...roles];
  const updatedUserRoles = new Set(userRoles.map(role => role.role));
  const conditionsRendering =
    (isCurrentAndSelectedUserManager && CURRENT_USER_MANAGER) ||
    (selectedUserRole === USER_ROLES.MANAGER && SELECTED_USER_MANAGER);
  const disableConditions =
    isCurrentAndSelectedUserManager
    || !isCurrentSessionManager
    || isRoleSaving;
  const bureauSettings = useSelector(getBureauSettings);
  const isContactlessLoginEnabled = bureauSettings?.contactless_logon_enabled;

  useEffect(() => {
    if (selectedUserName) {
      setSelectedUser(selectedUserName);
    }
  }, [selectedUserName]);

  useEffect(() => {
    if (selectedUserName !== selectedUser) {
      // to clear current data locations state on switch every user
      if (transformedLocations.length) {
        setDataLocations(transformedLocations);
      } else {
        setDataLocations([]);
      }
    }
  }, [selectedUserName]);

  // TRANSFORM CURRENT LOCATION ROLES TO LOCATIONS OBJECTS
  const transformLocationsByAddress = selectedLocations => {
    const result = [];
    const valueToFilter = selectedLocations || locations;
    _forEach(getSelectedUserLocation, item => {
      valueToFilter.forEach(dataItem => {
        if (dataItem.uri === item.location) {
          result.push(dataItem);
        }
      });
    });

    return result;
  };

  // TRANSFORM FROM LOCATION OBJECT TO LOCATION ROLE
  const getTransformedLocationIntoRole = property =>
    getSelectedUserLocation.find(locationRole => locationRole.location === property.uri);

  // DELETE USER ROLE FROM CHECKBOX:
  const removeCurrentSelectedRole = async (rolesList, currentRole) => {
    const currentSelected = rolesList.find(role => role.role === currentRole);

    if (currentSelected) {
      // we have some role selected, and we need to uncheck it
      return new Promise(resolve => {
        deleteRole(currentSelected.uuid);
        resolve();
      });
    }
    return null;
  };

  // TOGGLE SET LOCATION TO THE USER (ADD OR REMOVE)
  const onLocationsChange = async selectedLocations => {
    if (_isEqual(selectedLocations, dataLocations) && !confirmGlobal) return;
    setDataLocations(selectedLocations);

    if (selectedLocations.length === locations.length && !hasGlobalRole && !confirmGlobal) {
      // we've decided to add all locations, so we should transform role to "Global User"
      setConfirmGlobal(true);
    } else {
      const currentSelectedLocationsByAddresses = transformLocationsByAddress(selectedLocations);
      const currentSetLocationsByAddresses = transformLocationsByAddress();
      const uniqueItemsValue = confirmGlobal ? locations : selectedLocations;
      // find the objects which we do not have in the current locations list
      const uniqueItemsToAdd = differenceBy(uniqueItemsValue, currentSelectedLocationsByAddresses, 'uri');
      // find the objects which we have in the current location list but do not have in the list of selected locations
      const itemsToRemove = currentSetLocationsByAddresses
        .filter(locationSet => !selectedLocations.includes(locationSet));

      if (uniqueItemsToAdd.length) {
        try {
          if (uniqueItemsToAdd.length > 1) {
            // we have multiple location roles to add
            _forEach(uniqueItemsToAdd, async uniqueItem => {
              await createRole({
                username: user.username,
                role: USER_ROLES.LOCATION_USER,
                location: uniqueItem.uri,
              });
            });
          } else {
            await createRole({
              username: user.username,
              role: USER_ROLES.LOCATION_USER,
              location: uniqueItemsToAdd[0].uri,
            });
          }
        } finally {
          if (confirmGlobal) {
            setDataLocations(locations);
          }
        }
      }

      if (itemsToRemove.length) {
        if (itemsToRemove.length > 1) {
          // we have multiple location roles to remove
          _forEach(itemsToRemove, async removeItem => {
            await deleteRole(getTransformedLocationIntoRole(removeItem)?.uuid);
          });
        } else {
          await deleteRole(getTransformedLocationIntoRole(itemsToRemove[0])?.uuid);
        }
      }
    }
    setSelectedUser(selectedUserName);
  };

  // CONFIRM SETTING UP THE GLOBAL USER ROLE FROM THE CHECKBOX + ADD ALL LOCATIONS
  const handleConfirmGlobalUserRole = async () => {
    const rolePayload = {
      username: user.username,
      role: USER_ROLES.GLOBAL_USER,
    };

    if (!hasGlobalRole) {
      await createRole(rolePayload);
    }
    await onLocationsChange(dataLocations);
    setConfirmGlobal(false);
  };

  // DELETE GLOBAL USER ROLE FROM THE CHECKBOX + REMOVE ALL LOCATIONS
  const handleRemoveGlobalUserRole = async () => {
    setRemovingGlobal(false);
    await deleteRole(hasGlobalRole?.uuid);
    _forEach(dataLocations, async locationSelected => {
      await deleteRole(getTransformedLocationIntoRole(locationSelected)?.uuid);
    });
    setDataLocations([]);
  };

  // TOGGLE BACK TO THE PREVIOUS LOCATIONS SET AND DO NOT SET THE GLOBAL ROLE
  const handleDenySwitchingGlobalUserRole = () => {
    setConfirmGlobal(false);
    setDataLocations(transformLocationsByAddress());
  };

  // TOGGLE USER ROLES FROM CHECKBOX:
  const handleToggleRole = async (event, roleName) => {
    const { name, checked } = event.target;
    if (checked) {
      if (name === USER_ROLES.GLOBAL_USER) {
        // we should warn user if we are checking or unchecking this role and manipulate with locations
        return setConfirmGlobal(true);
      }

      const payload = {
        username: user.username,
        role: name,
      };
      try {
        await Promise.resolve(createRole(payload));
      } catch {
        Alert.warning(
          <FormattedMessage
            id="toaster.warning.role.doesNotWork"
            defaultMessage="Sorry, for now setting up the role {roleName} does not work."
            values={{ roleName }}
          />,
        );
        throw new Error(`Sorry, for now setting up the role ${roleName} does not work.`);
      }
    } else {
      if (name === USER_ROLES.GLOBAL_USER && getSelectedUserLocation.length) {
        // we should warn user if he would like to remove all locations in the list as well as remove global user
        return setRemovingGlobal(true);
      }

      await removeCurrentSelectedRole(userRoles, name);
    }
    return null;
  };

  // TOGGLE FINANCIALS STATE FOR 1 ROLE:
  const toggleHideFinancials = () => {
    if (selectedUserRole === USER_ROLES.MANAGER) return;
    setIsHideFinancials(currentState => !currentState);

    if (userRoles.length > 1) {
      // we have multiple user roles and need to set multiple requests
      _forEach(userRoles, currentRole => {
        dispatchHideFinancials(currentRole?.uuid, {
          hide_info: isHideFinancials ? null : USER_HIDE_INFO_TYPES.FINANCIAL,
        });
      });
    } else {
      // we have only one role selected and need to set it
      dispatchHideFinancials(selectedUserRoleMax?.uuid, {
        hide_info: isHideFinancials ? null : USER_HIDE_INFO_TYPES.FINANCIAL,
      });
    }
  };

  // RENDER TOOLTIP TEXT CONDITIONS
  const renderTooltipConditions = (renderFor, type, style = null, position = 'left') => {
    const handlers = {
      [CURRENT_USER_MANAGER]: `${renderFor} cannot be changed if your own role is Manager.`,
      [SELECTED_USER_MANAGER]: `${renderFor} cannot be changed if selected user role is Manager.`,
    };

    if (!handlers[type]) return null;

    return (
      <div style={style}>
        <OverlayTrigger
          placement={position}
          overlay={(
            <Tooltip id={renderFor.toLowerCase().trim()}>
              {handlers[type]}
            </Tooltip>
          )}
        >
          <Fa name="question-circle" className={renderFor === 'Hide Financials' ? 'spacer-left' : null} />
        </OverlayTrigger>
      </div>
    );
  };

  const handleGetContactlessLogonQRCode = async () => {
    setIsLoadingQRCodeData(true);
    const qrCodeBase64Data = await getContactlessLogonQRCode(extractUuid(user.uri))
      .catch(error => {
        setIsLoadingQRCodeData(false);
        return Alert.error(error);
      });
    if (qrCodeBase64Data) {
      setQrCodeBase64Data(qrCodeBase64Data);
      setShowQrLogonCodeModal(true);
      return setIsLoadingQRCodeData(false);
    }
    return setIsLoadingQRCodeData(false);
  };

  const onUserConfirmGetContactlessLogonQRCode = shouldUserProceed => {
    setShowQrLogonCodeWarningDisclaimerModal(false);
    if (shouldUserProceed) {
      handleGetContactlessLogonQRCode();
    }
  };

  return (
    <>
      <FormGroup style={{ padding: '2rem 0' }}>
        <QrLogonCodeDisclaimerConfirmationModal
          show={showQrLogonCodeWarningDisclaimerModal}
          onConfirm={onUserConfirmGetContactlessLogonQRCode}
        />
        <QrLogonCodeModal
          show={showQrLogonCodeModal}
          onHide={() => setShowQrLogonCodeModal(false)}
          qrCode={qrCodeBase64Data}
          user={user}
        />
        <FormLabel>Roles</FormLabel>
        <div style={{ ...styles.spacingVertical }}>
          <div>
            {
              rolesToShow.map(({ name, value }) => (
                <InputGroup key={value} style={styles.spacingBottom}>
                  <InputGroup.Text>
                    <input
                      checked={updatedUserRoles.has(value)}
                      disabled={(isCurrentUserManager && selectedUserName) === currentUserName || isRoleSaving}
                      name={value}
                      onChange={event => handleToggleRole(event, name)}
                      type="checkbox"
                    />
                  </InputGroup.Text>
                  <FormControl type="text" value={name} disabled />
                </InputGroup>
              ))
            }
          </div>
          <div>
            <Row>
              <Col xs={11} sm={11}>
                <SelectMultiple
                  title="Location"
                  data={locations}
                  labelKey="name"
                  valueKey="uri"
                  disabled={disableConditions || selectedUserRole === USER_ROLES.MANAGER}
                  multiple
                  selectAllOption
                  selectedData={dataLocations}
                  handleOnClose={selected => onLocationsChange(selected)}
                />
              </Col>
              <Col xs={1} sm={1} className="d-flex justify-content-center align-items-center">
                {renderTooltipConditions('Location', conditionsRendering, {})}
              </Col>
            </Row>
            <div>
              <div style={styles.spacingTop}>
                <div className="d-flex align-items-center">
                  {
                    isRoleSaving ? (<Loading />) : (
                      <>
                        <Form.Check
                          type="checkbox"
                          style={{ margin: 0 }}
                          name="hideFinancials"
                          disabled={disableConditions || !userRoles.length || selectedUserRole === USER_ROLES.MANAGER}
                          checked={hideFinancialState}
                          onChange={toggleHideFinancials}
                          label="Hide Financials"
                        />
                        {renderTooltipConditions('Hide Financials', conditionsRendering, null, 'bottom')}
                      </>
                    )
                  }
                </div>
                <Form.Check
                  type="checkbox"
                  style={{ margin: 0 }}
                  name="agreedToTOS"
                  disabled
                  checked={user.tos}
                  label="Agreed to TOS"
                />
              </div>
              {
                confirmGlobal && (
                  <ConfirmationModal
                    handleCancel={handleDenySwitchingGlobalUserRole}
                    handleConfirm={handleConfirmGlobalUserRole}
                    message="Are you sure you would like to set the Global User role and select all locations?"
                  />
                )
              }
              {
                removingGlobal && (
                  <ConfirmationModal
                    handleCancel={() => setRemovingGlobal(false)}
                    handleConfirm={handleRemoveGlobalUserRole}
                    message="Are you sure you would like to remove all locations in the list as well as remove Global User role?"
                  />
                )
              }
            </div>
          </div>
        </div>
      </FormGroup>
      {
        isContactlessLoginEnabled && (
          <FormGroup>
            <FormLabel>Contactless Logon</FormLabel>
            <div>
              <Button
                disabled={isLoadingQRCodeData}
                onClick={() => setShowQrLogonCodeWarningDisclaimerModal(true)}
              >
                Get QR Logon Code
                <Fa className="spacer-left" name="qrcode" />
              </Button>
            </div>
          </FormGroup>
        )
      }
    </>
  );
};

UserAdministrationPanel.defaultProps = {
  currentUser: {},
};

UserAdministrationPanel.propTypes = {
  isCurrentSessionManager: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isRoleSaving: PropTypes.bool.isRequired,
  createRole: PropTypes.func.isRequired,
  deleteRole: PropTypes.func.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    tos: PropTypes.bool.isRequired,
    uri: PropTypes.string.isRequired,
  }).isRequired,
  currentUser: PropTypes.shape({}),
  currentUserRole: PropTypes.shape({
    username: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
  dispatchHideFinancials: PropTypes.func.isRequired,
  hideFinancialState: PropTypes.bool.isRequired,
  selectedUserRoleMax: PropTypes.shape({
    username: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  getSelectedUserLocation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locationsBy: PropTypes.shape({}).isRequired,
  getContactlessLogonQRCode: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const currentUserRole = getCurrentUserRoleMax(state);
  const selectedUserRoleMax = getSelectedUserRoleMax(state, ownProps.user);
  const hideFinancialState = selectedUserRoleMax?.hide_info === USER_HIDE_INFO_TYPES.FINANCIAL;
  const getSelectedUserLocation = getSelectedUserLocations(state, ownProps.user);
  const locationsBy = getLocationsByUri(state);

  return {
    locations: getLocations(state),
    isCurrentSessionManager: isSessionManager(state),
    userRoles: getSelectedUserRoles(state, ownProps.user),
    isRoleSaving: (
      state.ui.nautilus[API_RESOURCES.ROLE].post.fetching ||
      state.ui.nautilus[API_RESOURCES.ROLE].delete.fetching ||
      state.ui.nautilus[API_RESOURCES.ROLE].put.fetching
    ),
    currentUserRole,
    hideFinancialState,
    selectedUserRoleMax,
    getSelectedUserLocation,
    locationsBy,
  };
};

const mapDispatchToProps = dispatch => ({
  createRole: payload => dispatch(Actions.Api.nautilus[API_RESOURCES.ROLE].post(payload)),
  deleteRole: uuid => dispatch(Actions.Api.nautilus[API_RESOURCES.ROLE].delete(uuid)),
  dispatchHideFinancials: (uuid, payload) => dispatch(Actions.Api.nautilus[API_RESOURCES.ROLE].put(uuid, payload)),
  getContactlessLogonQRCode: async uuid => {
    const user = await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS]
      .get(uuid, {}, { get_contactless_logon: true }));
    const { qr_logon_code: qrLogonCode } = user.json;
    return qrLogonCode;
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAdministrationPanel);
