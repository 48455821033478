import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonToolbar,
  Card,
  Col,
  Container,
  Dropdown,
  Form as BSForm,
  FormControl,
  FormGroup,
  FormLabel,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Row,
  SplitButton,
  Tooltip,
} from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import {
  materialStockResourceType,
  finalFormInputTypes,
  subLocationResourceType,
  stockSummaryResourceType,
  locationResourceType,
  materialResourceType,
} from 'rapidfab/types';
import MaterialBatchAndLotUIContainer
  from 'rapidfab/components/records/material_management/MaterialBatchAndLotUIContainer';
import Loading from 'rapidfab/components/Loading';
import { Form, Field } from 'react-final-form';
import MaterialStockSubLocationTable from 'rapidfab/components/MaterialStockSubLocationTable';
import StockFields from 'rapidfab/components/records/StockFields';
import Feature from 'rapidfab/components/Feature';
import { FEATURES } from 'rapidfab/constants';
import { FormattedMaterialUnitsSuffix } from 'rapidfab/components/FormattedMaterialUnits';

const AutomaticOrderingThresholdPanelHeader = ({
  isAutomaticOrdering,
  setIsAutomaticOrdering,
  setMaxMinValuesToNull,
  isMaterialSelected,
  setMaterialWarning,
}) => (
  <Row className="d-flex align-items-center">
    <Col xs={10}>Automatic Ordering Thresholds</Col>
    <Col xs={2} className="d-flex justify-content-end align-items-center">
      <OverlayTrigger
        placement="left"
        overlay={(
          <Tooltip id="automaticOrderingTooltip">
            <FormattedMessage
              id="record.materialStock.automaticOrderingTooltip"
              defaultMessage="When enabled, if material stock levels (minus on-order stock) go below minimum levels, re-ordering is triggered"
            />
          </Tooltip>
        )}
      >
        <Fa name="question-circle" />
      </OverlayTrigger>
      <BSForm.Check
        className="m-t-0 mb0 ml15"
        checked={isAutomaticOrdering}
        onChange={state => {
          if (!isMaterialSelected) {
            return setMaterialWarning(true);
          }
          setIsAutomaticOrdering(!isAutomaticOrdering);
          return setMaxMinValuesToNull(state);
        }}
        type="checkbox"
      />
    </Col>
  </Row>
);

AutomaticOrderingThresholdPanelHeader.propTypes = {
  isAutomaticOrdering: PropTypes.bool.isRequired,
  setIsAutomaticOrdering: PropTypes.func.isRequired,
  setMaxMinValuesToNull: PropTypes.func.isRequired,
  isMaterialSelected: PropTypes.bool.isRequired,
  setMaterialWarning: PropTypes.func.isRequired,
};

const Stock = ({
  stock,
  onSubmit,
  onDelete,
  locations,
  materials,
  isMaterialManagementFeatureEnabled,
  batches,
  lots,
  loading,
  initialFormValues,
  submitting,
  subLocations,
  stockSummary,
  stockSummaryLoading,
}) => {
  const isInitialAutomaticOrdering = Boolean(stock.stock_max && stock.stock_min);

  const [isAutomaticOrdering, setIsAutomaticOrdering] = useState(isInitialAutomaticOrdering);
  const filteredBatches = batches.filter(currentBatch =>
    currentBatch?.material_in_containers && currentBatch?.containers.length);
  const filteredLots = lots.filter(currentLot => currentLot?.containers_available_for_initial_batches?.length);

  const setMaxMinValuesToNull = (args, state) => {
    state?.fields.stock_max.change(null);
    state?.fields.stock_min.change(null);
    state?.fields.on_order_quantity.change(null);
  };

  const initialMaterial = materials.find(
    material => material.uri === initialFormValues?.material);

  const [selectedMaterial, setSelectedMaterial] = useState(
    initialMaterial,
  );

  const selectedLocation = locations.find(
    location => location.uri === initialFormValues?.location,
  );
  const [materialWarning, setMaterialWarning] = useState(false);

  let breadcrumb = 'New';

  if (initialFormValues?.uuid) {
    const materialString = `${selectedMaterial.name} (${getShortUUID(selectedMaterial.uuid)})`;
    const locationString = `${selectedLocation.name} (${getShortUUID(selectedLocation.uuid)})`;
    breadcrumb = `${materialString} in ${locationString}`;
  }

  const selectedMaterialUnits = selectedMaterial && selectedMaterial.units;
  const selectedMaterialUnitsLabel = <FormattedMaterialUnitsSuffix units={selectedMaterialUnits} />;

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{ setMaxMinValuesToNull }}
      initialValues={initialFormValues}
    >
      {({
        handleSubmit,
        form,
      }) => (
        <form id="stock" onSubmit={handleSubmit}>
          <Container fluid className="m-b">
            <BreadcrumbNav
              breadcrumbs={['inventory', 'materialStocks', breadcrumb]}
            />
            <div className="clearfix">
              <ButtonToolbar className="pull-right">
                {isMaterialManagementFeatureEnabled ? (
                  <Button
                    form="stock"
                    id="uxSaveButton"
                    type="submit"
                    variant="success"
                    size="sm"
                    pullRight
                  >
                    <SaveButtonTitle />
                  </Button>
                ) : (
                  <SplitButton
                    form="stock"
                    id="uxSaveDropdown"
                    type="submit"
                    variant="success"
                    size="sm"
                    disabled={submitting}
                    title={submitting ? <Loading /> : <SaveButtonTitle />}
                    pullRight
                  >
                    <Dropdown.Item
                      eventKey={1}
                      onClick={() => onDelete(stock.uuid)}
                      disabled={!stock.uri}
                    >
                      <Fa name="ban" />{' '}
                      <FormattedMessage
                        id="button.delete"
                        defaultMessage="Delete"
                      />
                    </Dropdown.Item>
                  </SplitButton>
                )}
              </ButtonToolbar>
            </div>

            <hr />

            <Row>
              <Col xs={12} md={6}>
                <StockFields
                  initialFormValues={initialFormValues}
                  locations={locations}
                  materials={materials}
                  isReadOnly={isMaterialManagementFeatureEnabled}
                  setSelectedMaterial={setSelectedMaterial}
                  materialWarning={materialWarning}
                  setMaterialWarning={setMaterialWarning}
                />
                {selectedMaterial && (
                  <Card className="mt30">
                    <Card.Header className="pd-exp accent">{`Material Type: ${selectedMaterial.name}`}</Card.Header>
                    <ListGroup fill>
                      <ListGroupItem className="d-flex justify-content-between">
                        <strong className="d-flex justify-content-between">Description</strong>
                        <span>{selectedMaterial.description}</span>
                      </ListGroupItem>
                      <ListGroupItem className="d-flex justify-content-between">
                        <strong>Units</strong>
                        <span>{selectedMaterial.units}</span>
                      </ListGroupItem>
                    </ListGroup>
                  </Card>
                )}
                <Card bg="dark" className="mt30">
                  <Card.Header className="pd-exp inverse">
                    <AutomaticOrderingThresholdPanelHeader
                      isAutomaticOrdering={isAutomaticOrdering}
                      setIsAutomaticOrdering={setIsAutomaticOrdering}
                      setMaxMinValuesToNull={form.mutators.setMaxMinValuesToNull}
                      isMaterialSelected={selectedMaterial}
                      setMaterialWarning={setMaterialWarning}
                    />
                  </Card.Header>
                  <div className="card-body-wrapper">
                    <Card.Body>
                      {isAutomaticOrdering && (
                        <div>
                          <FormGroup controlId="uxOnOrderQuantity">
                            <FormLabel className="d-flex align-items-center">
                              <p>
                                <FormattedMessage
                                  id="record.materialStock.onOrderQuantity"
                                  defaultMessage="On Order Quantity"
                                />{selectedMaterialUnitsLabel}
                                : {!isMaterialManagementFeatureEnabled && '*'}
                              </p>
                              <OverlayTrigger
                                placement="right"
                                overlay={(
                                  <Tooltip id="orderedAmountTooltip">
                                    <FormattedMessage
                                      id="record.materialStock.orderedAmountTooltip"
                                      defaultMessage="The current amount of a Material that has been Ordered and is expected to replenish this Stock."
                                    />
                                  </Tooltip>
                                )}
                              >
                                <Fa name="info-circle" className="ml5" />
                              </OverlayTrigger>
                            </FormLabel>
                            <Field
                              name="on_order_quantity"
                              type="number"
                              render={props => (
                                <FormControl
                                  {...props.input}
                                  required={!isMaterialManagementFeatureEnabled}
                                  min={0}
                                  disabled={isMaterialManagementFeatureEnabled}
                                />
                              )}
                            />
                          </FormGroup>
                          <FormGroup controlId="uxMinMaterial">
                            <FormLabel>
                              <p>Minimum Material ({selectedMaterial.units === 'cm3' ?
                                'cm³' : selectedMaterial.units}): {!isMaterialManagementFeatureEnabled && '*'}
                              </p>
                            </FormLabel>
                            <Field
                              name="stock_min"
                              type="number"
                              render={props => (
                                <FormControl
                                  {...props.input}
                                  required
                                  min={0}
                                />
                              )}
                            />
                          </FormGroup>
                          <FormGroup controlId="uxMaxMaterial">
                            <FormLabel>
                              <p>Maximum Material ({selectedMaterial.units === 'cm3' ?
                                'cm³' : selectedMaterial.units}): {!isMaterialManagementFeatureEnabled && '*'}
                              </p>
                            </FormLabel>
                            <Field
                              name="stock_max"
                              type="number"
                              render={props => (
                                <FormControl
                                  {...props.input}
                                  required
                                  min={0}
                                />
                              )}
                            />
                          </FormGroup>
                        </div>
                      )}
                    </Card.Body>
                  </div>
                </Card>
              </Col>

              <Col xs={12} md={6}>
                <Feature featureName={FEATURES.MATERIAL_MANAGEMENT}>
                  <MaterialStockSubLocationTable
                    stockSummaryLoading={stockSummaryLoading}
                    subLocations={subLocations}
                    stockSummary={stockSummary}
                    units={selectedMaterial?.units}
                  />
                </Feature>
                <Row>
                  {filteredLots?.length ? (
                    loading ? <Loading /> : (
                      <MaterialBatchAndLotUIContainer
                        containers={filteredLots}
                        currentType="lot"
                      />
                    )
                  ) : null}
                </Row>
                <Row>
                  {filteredBatches?.length ? (
                    loading ? <Loading /> : (
                      <MaterialBatchAndLotUIContainer
                        containers={filteredBatches}
                        currentType="batch"
                      />
                    )
                  ) : null}
                </Row>

              </Col>
            </Row>
          </Container>
        </form>
      )}
    </Form>
  );
};

Stock.defaultProps = {
  stock: {},
  batches: [],
  lots: [],
  loading: false,
  stockSummaryLoading: false,
};

Stock.propTypes = {
  initialFormValues: PropTypes.shape({
    material: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    stock_max: PropTypes.number.isRequired,
    stock_min: PropTypes.number.isRequired,
    on_order_quantity: PropTypes.number.isRequired,
    track_quantity: PropTypes.bool.isRequired,
  }).isRequired,
  stock: materialStockResourceType,
  locations: PropTypes.arrayOf(locationResourceType).isRequired,
  materials: PropTypes.arrayOf(materialResourceType).isRequired,
  input: finalFormInputTypes.isRequired,
  subLocations: PropTypes.arrayOf(subLocationResourceType).isRequired,
  stockSummary: PropTypes.arrayOf(stockSummaryResourceType).isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isMaterialManagementFeatureEnabled: PropTypes.bool.isRequired,
  batches: PropTypes.instanceOf(Array),
  lots: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  stockSummaryLoading: PropTypes.bool,
};

export default Stock;
