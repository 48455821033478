import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import * as Selectors from 'rapidfab/selectors';

import LocationsComponent from 'rapidfab/components/manage/locations';
import { API_RESOURCES } from 'rapidfab/constants';

const LocationsContainer = props => {
  const usersByUri = useSelector(Selectors.getUsersByUri);
  const locations = useSelector(Selectors.getLocations);
  const subLocations = useSelector(Selectors.getSubLocations);
  const bureauGroup = useSelector(Selectors.getBureauGroup);
  const fetching = useSelector(state =>
    isFetchingInitial(
      state.ui.nautilus[API_RESOURCES.LOCATION].list,
      state.ui.nautilus[API_RESOURCES.USERS].list,
      state.ui.nautilus[API_RESOURCES.SUB_LOCATION].list,
    ));

  const dispatch = useDispatch();
  const onInitialize = bureausGroup => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({ group: bureausGroup }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list());
  };

  useEffect(() => onInitialize(bureauGroup), []);

  return (
    <LocationsComponent
      {...props}
      usersByUri={usersByUri}
      fetching={fetching}
      locations={locations}
      subLocations={subLocations}
    />
  );
};

export default LocationsContainer;
