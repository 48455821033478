/* eslint-disable linebreak-style */
export const isInHoursMinutesFormat = value => {
  if (typeof value !== 'string') {
    return false;
  }
  const regex = new RegExp('^(\\d?\\d\\d):[0-5]\\d$');
  const match = value.match(regex);
  return (match !== null);
};

export const getHoursAndMinutesFromDurationSecs = duration => {
  if (duration === undefined) {
    return '00:00';
  }
  if (duration) {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.round((duration % 3600) / 60);
    const hoursAsString = hours < 10 ? `0${hours}` : `${hours}`;
    const minutesAsString = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return `${hoursAsString}:${minutesAsString}`;
  }
  return '00:00';
};

export const getDurationSecondsFromHoursAndMinutes = hoursAndMinutes => {
  const [hours, minutes] = hoursAndMinutes.split(':');
  const duration = (+hours * 3600) + (+minutes * 60);
  return duration;
};
