import React, { useCallback, useEffect, useState } from 'react';
import Actions from 'rapidfab/actions';
import LocationComponent from 'rapidfab/components/records/location';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES, FEATURES, LOCATION_NOTIFICATION_SETTING_BOOLS, ROUTES } from 'rapidfab/constants';
import userSort from 'rapidfab/utils/userSort';
import countries from 'i18n-iso-countries';
import countriesEn from 'i18n-iso-countries/langs/en.json';
import _clone from 'lodash/clone';
import _pick from 'lodash/pick';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import extractUuid from 'rapidfab/utils/extractUuid';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'rapidfab/hooks';
import { useNavigate } from 'react-router-dom';
import Alert from 'rapidfab/utils/alert';

import { LOCATION_CONTAINER } from 'rapidfab/constants/forms';
import { FormattedMessage } from 'react-intl';
import { isFeatureEnabled } from 'rapidfab/selectors';

const locationSettingsPutFields = [
  ...Object.values(LOCATION_NOTIFICATION_SETTING_BOOLS),
  'uri',
];

countries.registerLocale(countriesEn);

function getCountryList() {
  const allCountries = Object.keys(countries.getAlpha3Codes()).map(code => ({
    code,
    name: countries.getName(code, 'en'),
  }));

  allCountries.sort((a, b) => a.name.localeCompare(b.name));

  return allCountries;
}

const LocationContainer = props => {
  const [updatedLocationSettings, setUpdatedLocationSettings] = useState({});
  const uuid = useSelector(Selectors.getRouteUUID);
  const location = useSelector(state => Selectors.getInitialValuesBureau(state, props));
  const subLocations = useSelector(Selectors.getSubLocations);
  const subLocationsByUri = useSelector(Selectors.getSubLocationsByUri);
  const users = useSelector(state => Selectors.getUsers(state).sort(userSort));
  const locationSettings = useSelector(state => Selectors.getLocationSettingsForLocation(state, location));
  const bureau = useSelector(Selectors.getBureau);
  const isSubmitting = useSelector(state => state.ui.nautilus[API_RESOURCES.LOCATION].post.fetching
    || state.ui.nautilus[API_RESOURCES.LOCATION].put.fetching
    || state.ui.nautilus[API_RESOURCES.LOCATION].delete.fetching
    || state.ui.nautilus[API_RESOURCES.LOCATION_SETTINGS].put.fetching);
  const subLocationsFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.SUB_LOCATION].list.fetching);
  const materialResourcesFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL_LOT].list.fetching);
  const subLocationsSubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.SUB_LOCATION].post.fetching ||
    state.ui.nautilus[API_RESOURCES.SUB_LOCATION].put.fetching);
  const subLocationDeleting = useSelector(state => state.ui.nautilus[API_RESOURCES.SUB_LOCATION].delete.fetching);
  const isMaterialManagementFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.MATERIAL_MANAGEMENT));
  const navigate = useNavigate();

  const initialFormValues = {};
  Object
    .keys(location)
    .filter(key => LOCATION_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = location[key];
    });

  const selected = {
    uuid,
    locationUri: location?.uri,
    initialFormValues,
    users,
    bureauGroup: bureau?.group,
    bureauUri: bureau?.uri,
    countries: getCountryList(),
    locationSettings,
    isSubmitting,
    subLocations,
    subLocationsFetching,
    subLocationsByUri,
    materialResourcesFetching,
    subLocationsSubmitting,
    subLocationDeleting,
    isMaterialManagementFeatureEnabled,
  };

  const dispatch = useDispatch();

  const redirectToNewLocation = uri => {
    window.location = getRouteURI(ROUTES.LOCATION_EDIT, { uuid: extractUuid(uri) });
  };

  const onInitialize = (currentUuid, bureauGroup) => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].clear('list'));
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.USERS].list({
        group: bureauGroup,
      }),
    );
    if (currentUuid) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].get(currentUuid))
        .then(locationResponse => {
          const { uri } = locationResponse.json;
          dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION_SETTINGS].list({ location: uri }));
          dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list({ location: uri }));
        });
    }
  };
  const onSave = async (locationPayload, locationSettingsPayload) => {
    const modifiedLocationPayload = locationPayload;

    // The API requires an array, but we only have a single selection for now.
    // This isn't great, but it's better than other options until we can
    // integrate a proper multi-select input for this.
    if (!_isArray(modifiedLocationPayload.countries_served)) {
      modifiedLocationPayload.countries_served = [modifiedLocationPayload.countries_served];
    }

    Object.keys(modifiedLocationPayload).forEach(key => {
      if (modifiedLocationPayload[key] == null) {
        delete modifiedLocationPayload[key];
      }
    });

    let locationSettingsUuid = locationSettingsPayload.uuid;

    if (modifiedLocationPayload.uuid) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION]
        .put(modifiedLocationPayload.uuid, modifiedLocationPayload))
        .then(() => {
          Alert.success(
            <FormattedMessage
              id="toaster.location.updated"
              defaultMessage="Location {uuid} successfully updated."
              values={{ uuid: modifiedLocationPayload.uuid }}
            />,
          );
        });
    } else {
      const locationResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION]
        .post(modifiedLocationPayload));
      locationSettingsUuid = extractUuid(locationResponse.headers.locationSettings);
      Alert.success(
        <FormattedMessage
          id="toaster.location.created"
          defaultMessage="Location successfully created."
        />,
      );
      redirectToNewLocation(locationResponse.headers.location);
    }

    const updatedLocationSettingsPayload = _pick(locationSettingsPayload, locationSettingsPutFields);
    // Send location settings PUT request only if there is anything to send.
    if (!_isEmpty(updatedLocationSettingsPayload)) {
      // We never create `location-settings` manually. It is always done on the backend.
      // So, we only need `update` here
      await dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION_SETTINGS].put(
        locationSettingsUuid, updatedLocationSettingsPayload,
      ));
    }
    // navigate(getRouteURI(ROUTES.LOCATION_EDIT, { uuid: extractUuid(locationResponse.headers.location) }, {}, true));
  };

  const onSaveSubLocationName = (uuid, name) => {
    if (uuid && name) {
      return dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].put(uuid, { name }));
    }

    return null;
  };

  const onArchiveSubLocation = (uuid, hasResources) => {
    if (uuid) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].delete(uuid))
        .then(() => {
          if (hasResources) {
            Alert.success(
              <FormattedMessage
                id="toaster.subLocation.archived"
                defaultMessage="Sub-Location successfully archived."
              />,
            );
          } else {
            Alert.success(
              <FormattedMessage
                id="toaster.subLocation.deleted"
                defaultMessage="Sub-Location successfully deleted."
              />,
            );
          }
        });
    }
  };

  const onUnmount = () => {
    // get rid of pesky lingering errors
    dispatch(Actions.UI.clearUIState(['nautilus.location']));
  };

  const onDelete = currentUUID => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].delete(currentUUID))
        .then(() => Alert.success(
          <FormattedMessage
            id="toaster.location.deleted"
            defaultMessage="Location {uuid} successfully deleted."
            values={{ uuid: currentUUID }}
          />,
        ))
        .then(() => navigate(getRouteURI(ROUTES.LOCATIONS, {}, {}, true)));
    }
  };

  const refreshLocationSettings = () => {
    const locationSettingsValues = _pick(
      locationSettings, Object.values(LOCATION_NOTIFICATION_SETTING_BOOLS),
    );
    setUpdatedLocationSettings(locationSettingsValues);
  };

  useEffect(() => {
    onInitialize(uuid, selected.bureauGroup);
    refreshLocationSettings();

    return () => {
      onUnmount();
    };
  }, []);

  const previousLocationSettings = usePrevious(locationSettings);

  useEffect(() => {
    if (_isEmpty(previousLocationSettings) && locationSettings?.uri) {
      refreshLocationSettings();
    }
  }, [locationSettings]);

  const onSubmit = locationPayload => {
    const { bureauUri } = selected;
    const locationSettingsPayload = {
      ...locationSettings,
      ...updatedLocationSettings,
      bureau: bureauUri,
    };

    onSave(locationPayload, locationSettingsPayload);
  };

  const onChangeLocationSettings = (key, value) => {
    const newLocationSettingsValues = _clone(updatedLocationSettings);
    newLocationSettingsValues[key] = value;
    setUpdatedLocationSettings(newLocationSettingsValues);
  };

  /*
    This method will send the API call to the related resource and filter it
    either by the sub_location uri or location uri (if "All" tab is active).
    As the result, it will return the "count" of the resources stored in the DB.
    It is needed to show the number in the Resources Table if Sub-Locations component. */
  const handleFetchRelatedResource = (fetchAll, resource, uri) => {
    if (uri) {
      return dispatch(Actions.Api.nautilus[resource].list(
        fetchAll ?
          { location: uri } :
          { sub_location: uri }, {}, {}, {}, true))
        .then(resourceResponse => resourceResponse?.json?.meta?.count || 0);
    }
    return null;
  };

  const handleAddSubLocation = values => {
    const payload = {
      ...values,
      location: location?.uri,
    };

    return dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].post(payload))
      .then(() => Alert.success(
        <FormattedMessage
          id="toaster.subLocation.created"
          defaultMessage="Sub-Location successfully created."
        />,
      ))
      .catch(error => Alert.error(error));
  };

  const handleSetLocationFilters = useCallback((locationUri, subLocationUri) => {
    if (locationUri && !subLocationUri) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].clear('list'));
      dispatch(Actions.LocationFilter.setSubLocation(null));
      return dispatch(Actions.LocationFilter.setLocation(locationUri));
    }

    if (locationUri && subLocationUri) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].clear('list'));
      dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].clear('list'));

      dispatch(Actions.LocationFilter.setSubLocation(subLocationUri));
      return dispatch(Actions.LocationFilter.setLocation(locationUri));
    }

    return null;
  }, [dispatch]);

  const dispatched = {
    onSubmit,
    onDelete,
    onSaveSubLocationName,
    onArchiveSubLocation,
    handleFetchRelatedResource,
    handleAddSubLocation,
    handleSetLocationFilters,
  };

  return (
    <LocationComponent
      updatedLocationSettings={updatedLocationSettings}
      onChangeLocationSettings={onChangeLocationSettings}
      {...props}
      {...selected}
      {...dispatched}
    />
  );
};

export default LocationContainer;
