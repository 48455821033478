import React from 'react';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';
import {
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import extractUuid from 'rapidfab/utils/extractUuid';
import { documentResourceType } from 'rapidfab/types';
import dayjs from 'dayjs';
import { COLORS } from 'rapidfab/constants';

const DocumentItem = ({
  className,
  document,
  onDelete,
  openVersionModal,
  usersByUri,
  isLatestVersion,
  isHawkingUser,
}) => {
  const { uri, name, content, user: userUri, previous_documents, created, virus_scan: virusScanStatus } = document;
  const uuid = extractUuid(uri);
  const user = usersByUri[userUri];
  const versionNumber = previous_documents && previous_documents.length + 1;
  const isQuarantined = virusScanStatus === 'quarantined';
  return (
    <div className={`d-flex align-items-center justify-content-between ${className}`}>
      <div>
        {
          isQuarantined && (
            <div className="pull-left spacer-right">
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip>
                    Virus Scan has quarantined this file. Contact a manager to clear.
                  </Tooltip>
                )}
              >
                <Fa name="bug" style={{ color: COLORS.RED }} />
              </OverlayTrigger>
            </div>
          )
        }
        <a style={{ color: isQuarantined && COLORS.RED }} href={content}>{name || uuid}</a>
        {' '}
        <span>
          (last update: by {user ? (user.name || user.username) : '-'} at {dayjs(created).format('DD MMM YYYY, HH:mm')})
        </span>
        {' '}
        <span
          className={openVersionModal && 'link'}
          role="button"
          tabIndex={0}
          onClick={openVersionModal}
        >
          Version {versionNumber} {isLatestVersion === true && '(Current)'}
        </span>
        {!content && (
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip>
                The file upload was interrupted before it it was finished.
                Refreshing a page will interrupt a file upload. Please delete the file and try again.
              </Tooltip>
            )}
          >
            <Fa name="exclamation-circle" className="spacer-left" />
          </OverlayTrigger>
        )}
      </div>
      <div>
        {onDelete && (
          <Button
            size="xs"
            className={isHawkingUser ? 'pull-right hawking-primary' : 'pull-right'}
            variant={!isHawkingUser && 'danger'}
            onClick={() => onDelete(uuid)}
          >
            <Fa name="times" />
          </Button>
        )}
      </div>
    </div>
  );
};

DocumentItem.defaultProps = {
  onDelete: null,
  openVersionModal: null,
  isLatestVersion: null,
  className: '',
};

DocumentItem.propTypes = {
  document: documentResourceType.isRequired,
  onDelete: PropTypes.func,
  openVersionModal: PropTypes.func,
  usersByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
  })).isRequired,
  isLatestVersion: PropTypes.bool,
  isHawkingUser: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default DocumentItem;
