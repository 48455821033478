import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';
import SmoothCollapse from 'react-smooth-collapse';
import { FormattedMessage } from 'rapidfab/i18n';
import VisibleFor from 'rapidfab/components/VisibleFor';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI, { getCustomDDWLibraryUri } from 'rapidfab/utils/getRouteURI';
import 'rapidfab/styles/danfossDDW/main.scss';
import Icon, { ICON_NAMES } from 'rapidfab/icons';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import CreateLibraryModal from 'rapidfab/components/modals/CreateLibraryModal';
import Loading from 'rapidfab/components/Loading';
import truncateText from 'rapidfab/utils/truncateText';

// Either provide FaIconName or the icon svg
const SidebarLink = ({ uri, iconName, label, icon, className }) => {
  const currentHash = window.location.hash;
  const clazz = [
    currentHash === uri ? 'active' : null,
    className,
  ];

  return (
    <li className={clazz.join(' ')}>
      <a href={uri}>
        {icon
          ? <Icon name={icon} className="fa-icon-style spacer-right" />
          : <Fa name={iconName} className="spacer-right" />}
        {label}
      </a>
    </li>
  );
};
SidebarLink.defaultProps = {
  icon: null,
  iconName: '',
  className: '',
};
SidebarLink.propTypes = {
  uri: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.oneOf(Object.values(ICON_NAMES)),
  className: PropTypes.string,
};

const CollapsibleSidebarLink = ({ label, iconName, children }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <li>
        <div
          role="button"
          tabIndex={0}
          onClick={() => setExpanded(!expanded)}
        >
          <Fa name={iconName} className="spacer-right" />
          {label}
          <Fa
            name={expanded ? 'chevron-up' : 'chevron-down'}
            className="pull-right"
          />
        </div>
      </li>
      <SmoothCollapse expanded={expanded}>
        <ul className="list-unstyled collapsible-sidebar">
          {children}
        </ul>
      </SmoothCollapse>
    </>
  );
};
CollapsibleSidebarLink.propTypes = {
  iconName: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
};

const DanfossDDWSidebar = ({
  isRestricted,
  createLibraryModalShown,
  groups,
  handleCreateLibrary,
  libraries,
  addLibraryFetching,
  onClose,
  onShow,
  librariesFetching,
  groupsFetching,
}) => {
  const renderCustomLibraries = () => {
    if (!libraries.length && !librariesFetching) {
      return null;
    }

    if (librariesFetching) {
      return <Loading inline className="mt30 ml30" />;
    }

    return (
      <>
        <p className="ddwModelLibraryLabel">Custom</p>
        {libraries.map(library => (
          <SidebarLink
            key={library.uri}
            uri={getRouteURI(getCustomDDWLibraryUri(library.name, isRestricted))}
            iconName="folder"
            label={(
              library.name.length > 28 ? (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip">{library.name}</Tooltip>
                  }
                >
                  <span>{truncateText(library.name, 28)}</span>
                </OverlayTrigger>
              ) : truncateText(library.name, 28)
            )}
          />
        ))}
        {addLibraryFetching && <Loading inline className="ml30" />}
      </>
    );
  };

  return (
    <nav id="sidebar">
      <ul className="list-unstyled">
        {isRestricted && (
          <SidebarLink
            uri={getRouteURI(ROUTES.DDW_RESTRICTED_HOME)}
            icon={ICON_NAMES.HOME}
            label={<FormattedMessage id="hawking.home" defaultMessage="Home" />}
          />
        )}
        <p className="ddwModelLibraryLabel">General</p>
        <SidebarLink
          uri={getRouteURI(isRestricted ? ROUTES.DDW_RESTRICTED_MY_LIBRARY : ROUTES.DDW_MY_LIBRARY)}
          iconName="user"
          label={<FormattedMessage id="hawking.myLibrary" defaultMessage="My Library" />}
        />
        <SidebarLink
          uri={getRouteURI(isRestricted ? ROUTES.DDW_RESTRICTED_COMPANY_LIBRARY : ROUTES.DDW_COMPANY_LIBRARY)}
          iconName="folder"
          label={<FormattedMessage id="hawking.companyLibrary" defaultMessage="Company Library" />}
        />
        {renderCustomLibraries()}
        <VisibleFor manager>
          <SidebarLink
            className="mt30"
            uri={getRouteURI(isRestricted ? ROUTES.DDW_RESTRICTED_ADMINISTRATOR_LIBRARY :
              ROUTES.DDW_ALL_DESIGNS)}
            iconName="folder-open"
            label={<FormattedMessage id="ddw.allDesigns" defaultMessage="All Designs" />}
          />
        </VisibleFor>
        <VisibleFor manager>
          <div className="d-flex justify-content-center">
            <Button
              bg="primary"
              variant="primary"
              className="mt30"
              onClick={onShow}
            >
              Create
              <FontAwesomeIcon icon={faPlusCircle} className="spacer-left" />
            </Button>
          </div>
        </VisibleFor>
        <CreateLibraryModal
          show={createLibraryModalShown}
          groups={groups}
          handleCreateLibrary={handleCreateLibrary}
          addLibraryFetching={addLibraryFetching}
          onClose={onClose}
          groupsFetching={groupsFetching}
        />
      </ul>
    </nav>
  );
};

export default DanfossDDWSidebar;

DanfossDDWSidebar.defaultProps = {
  createLibraryModalShown: false,
  groups: [],
  handleCreateLibrary: () => {},
  libraries: [],
  addLibraryFetching: false,
  onClose: () => {},
  onShow: () => {},
  librariesFetching: false,
  groupsFetching: false,
};

DanfossDDWSidebar.propTypes = {
  isRestricted: PropTypes.bool.isRequired,
  createLibraryModalShown: PropTypes.bool,
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  handleCreateLibrary: PropTypes.func,
  libraries: PropTypes.arrayOf(PropTypes.shape({})),
  addLibraryFetching: PropTypes.bool,
  onClose: PropTypes.func,
  onShow: PropTypes.func,
  librariesFetching: PropTypes.bool,
  groupsFetching: PropTypes.bool,
};
