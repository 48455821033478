import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import {
  ButtonToolbar,
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import Fa from 'react-fontawesome';
import Loading from 'rapidfab/components/Loading';

import { Form, Field } from 'react-final-form';
import { finalFormInputTypes, subLocationResourceType } from 'rapidfab/types';
import { FormControlSelect } from 'rapidfab/components/formTools';

const MaterialLotNew = ({
  isSubmitting,
  locations,
  materials,
  onSubmit,
  isOrderMaterialLot,
  initialFormValues,
  subLocations,
  loadSubLocations,
}) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialFormValues}
    render={({ handleSubmit, values, form }) => {
      const selectedMaterial = materials.find(
        material => material.uri === values.material,
      );

      return (
        <form onSubmit={handleSubmit}>
          <Container fluid className="p-b">
            <BreadcrumbNav
              breadcrumbs={[
                'inventory',
                'materialLots',
                isOrderMaterialLot ? 'Order Material Lot' : 'New',
              ]}
            />

            <Row>
              <Col xs={6}>
                {isOrderMaterialLot && (
                  <h3>
                    <b>
                      <FormattedMessage
                        id="materialLot.order"
                        defaultMessage="Order Material Lot"
                      />
                    </b>
                  </h3>
                )}
              </Col>
              <Col xs={6}>
                <ButtonToolbar className="clearfix">
                  <div className="flexed-pull-right">
                    {isOrderMaterialLot ? (
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        value="submit"
                        variant="success"
                        size="sm"
                      >
                        {isSubmitting ? (
                          <Loading />
                        ) : (
                          <>
                            <Fa name="clipboard" />{' '}
                            <FormattedMessage
                              id="materialLot.order"
                              defaultMessage="Order Material Lot"
                            />
                          </>
                        )}
                      </Button>
                    ) : (
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        value="submit"
                        variant="success"
                        size="sm"
                      >
                        {isSubmitting ? (
                          <Loading />
                        ) : (
                          <SaveButtonTitle resourceName="Material Lot" />
                        )}
                      </Button>
                    )}
                  </div>
                </ButtonToolbar>
              </Col>
            </Row>

            <hr />

            <Row>
              <Col xs={12} sm={6}>
                <FormGroup className="form-group" controlId="uxName">
                  <FormLabel>
                    <FormattedMessage id="field.name" defaultMessage="Name" />: *
                  </FormLabel>
                  <Field
                    name="name"
                    type="text"
                    render={props => (
                      <FormControl
                        {...props.input}
                        required
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxMaterial">
                  <FormLabel>
                    <FormattedMessage
                      id="field.material"
                      defaultMessage="Material"
                    />
                    : *
                  </FormLabel>
                  <Field
                    name="material"
                    render={props => (
                      <SelectSingle
                        name={props.input.name}
                        data={materials}
                        value={props.input.value}
                        handleOnChange={props.input.onChange}
                        required
                        imitateOnChangeEvent
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxLocation">
                  <FormLabel>
                    <FormattedMessage
                      id="field.location"
                      defaultMessage="Location"
                    />
                    : *
                  </FormLabel>
                  <Field
                    name="location"
                    render={props => (
                      <FormControlSelect
                        {...props.input}
                        onChange={event => {
                          form.change('sub_location', '');
                          const newLocationUri = event.target.value;
                          loadSubLocations(newLocationUri);
                          props.input.onChange(event);
                        }}
                        required
                      >
                        <option key="placeholder" value="" selected disabled>
                          Select a Location
                        </option>
                        {locations.map(location => (
                          <option key={location.uri} value={location.uri}>
                            {location.name}
                          </option>
                        ))}
                      </FormControlSelect>
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxSubLocation">
                  <FormLabel>
                    <FormattedMessage
                      id="field.subLocation"
                      defaultMessage="Sub-Location"
                    />
                    :
                  </FormLabel>
                  <Field
                    name="sub_location"
                    render={props => (
                      <FormControlSelect
                        {...props.input}
                        disabled={!values.location}
                      >
                        <option key="placeholder" value="" selected disabled>
                          {!values.location ? 'Select a Location' : 'Select a Sub-Location'}
                        </option>
                        {subLocations.map(subLocation => (
                          <option key={subLocation.uri} value={subLocation.uri}>
                            {subLocation.name}
                          </option>
                        ))}
                      </FormControlSelect>
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxDeliveryID">
                  <FormLabel>
                    <FormattedMessage
                      id="field.deliveryId"
                      defaultMessage="Delivery ID"
                    />
                    :
                  </FormLabel>
                  <Field
                    name="delivery_id"
                    type="text"
                    render={props => (
                      <FormControl {...props.input} />
                    )}
                  />
                </FormGroup>
                {isOrderMaterialLot ? (
                  <FormGroup className="form-group" controlId="uxDeliveryDate">
                    <FormLabel>
                      <FormattedMessage
                        id="field.estimatedDeliveryDate"
                        defaultMessage="Estimated Delivery Date"
                      />
                      : *
                    </FormLabel>
                    <Field
                      name="estimated_delivery_date"
                      type="date"
                      render={props => (
                        <FormControl
                          {...props.input}
                          required
                          pattern="^\d{4}-\d{2}-\d{2}$"
                          placeholder="yyyy-mm-dd"
                        />
                      )}
                    />
                  </FormGroup>
                ) : (
                  <FormGroup className="form-group" controlId="uxDeliveryDate">
                    <FormLabel>
                      <FormattedMessage
                        id="field.deliveryDate"
                        defaultMessage="Delivery Date"
                      />
                      : *
                    </FormLabel>
                    <Field
                      name="actual_delivery_date"
                      type="date"
                      render={props => (
                        <FormControl
                          {...props.input}
                          required
                          pattern="^\d{4}-\d{2}-\d{2}$"
                          placeholder="yyyy-mm-dd"
                        />
                      )}
                    />
                  </FormGroup>
                )}
                <FormGroup className="form-group" controlId="uxExpirationDate">
                  <FormLabel>
                    <FormattedMessage
                      id="field.expirationDate"
                      defaultMessage="Expiration Date"
                    />
                    :
                  </FormLabel>
                  <Field
                    name="expiration_date"
                    type="date"
                    render={props => (
                      <FormControl
                        {...props.input}
                        pattern="^\d{4}-\d{2}-\d{2}$"
                        placeholder="yyyy-mm-dd"
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxContainersQuantity">
                  <FormLabel>
                    <FormattedMessage
                      id="field.number_of_containers"
                      defaultMessage="Number of Containers"
                    />
                    : *
                  </FormLabel>
                  <Field
                    name="number_of_containers"
                    type="number"
                    render={props => (
                      <FormControl
                        {...props.input}
                        required
                        min="1"
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup controlId="uxContainersSize">
                  <FormLabel>
                    <FormattedMessage
                      id="field.size_of_container"
                      defaultMessage="Size of Containers"
                    />
                    {selectedMaterial?.units && (
                      <span> ({selectedMaterial.units})</span>
                    )}
                    : *
                  </FormLabel>
                  <Field
                    name="size_of_container"
                    type="number"
                    render={props => (
                      <FormControl
                        {...props.input}
                        required
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </form>
      );
    }}
  />
);

MaterialLotNew.defaultProps = {
  isOrderMaterialLot: false,
};

MaterialLotNew.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOrderMaterialLot: PropTypes.bool,
  input: finalFormInputTypes.isRequired,
  initialFormValues: PropTypes.shape({
    material: PropTypes.string,
    location: PropTypes.string,
    delivery_id: PropTypes.string,
    name: PropTypes.string,
    estimated_delivery_date: PropTypes.string,
    actual_delivery_date: PropTypes.string,
    expiration_date: PropTypes.string,
    number_of_containers: PropTypes.number,
    size_of_container: PropTypes.number,
  }).isRequired,
  subLocations: PropTypes.arrayOf(subLocationResourceType).isRequired,
  loadSubLocations: PropTypes.func.isRequired,
};

export default MaterialLotNew;
