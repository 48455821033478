import { createSelector } from 'reselect';
import { getPostProcessors } from 'rapidfab/selectors/postProcessor';
import { getPostProcessorTypes } from 'rapidfab/selectors/postProcessorType';
import _countBy from 'lodash/countBy';

export const getAvailablePostProcessorTypes = createSelector(
  [getPostProcessorTypes, getPostProcessors],
  (postProcessorTypes, postProcessors) => {
    const typeCount = _countBy(postProcessors, 'post_processor_type');
    // When there are no Post Processors for a Type - typeCount[uri] = `undefined`
    // Anyway, "undefined > 0" is false so this still works
    return postProcessorTypes.filter(({ uri }) => typeCount[uri] > 0);
  },
);

export const getNonAssemblyPostProcessorTypes = createSelector(
  [getPostProcessorTypes],
  postProcessorTypes => postProcessorTypes.filter(({ is_assembly }) => !is_assembly),
);
