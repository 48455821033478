import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import * as Selectors from 'rapidfab/selectors';

import PrinterTypesComponent from 'rapidfab/components/manage/printerTypes';
import { isFeatureEnabled } from 'rapidfab/selectors';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';

const PrinterTypesContainer = props => {
  const fetching = useSelector(state => isFetchingInitial(
    state.ui.nautilus[API_RESOURCES.MANUFACTURER].list,
    state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].list,
  ));
  const manufacturers = useSelector(Selectors.getManufacturers);
  const printerTypes = useSelector(Selectors.getPrinterTypes);
  const isGroupQualificationsFeatureEnabled =
    useSelector(state => isFeatureEnabled(state, FEATURES.GROUP_QUALIFICATIONS));

  const dispatch = useDispatch();

  const onInitialize = () => {
    const apiParams = [
      {}, // filters
      {}, // page
      {}, // searchParams
      {}, // queryParams
      true, // forced
    ];

    dispatch(Actions.Api.nautilus[API_RESOURCES.MANUFACTURER].list(...apiParams));
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list(...apiParams));
  };

  useEffect(() => onInitialize(), []);

  return (
    <PrinterTypesComponent
      {...props}
      fetching={fetching}
      manufacturers={manufacturers}
      printerTypes={printerTypes}
      isGroupQualificationsFeatureEnabled={isGroupQualificationsFeatureEnabled}
    />
  );
};

export default PrinterTypesContainer;
