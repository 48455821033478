import React from 'react';
import hexRgb from 'hex-rgb';
import { SketchPicker } from 'react-color';
import { reduxFormFieldType } from 'rapidfab/types';

const ColorWithOpacityPicker = ({
  colorField,
  opacityField,
  ...otherProps
}) => {
  let rgbaValue = '';
  if (colorField.value) {
    // convert hex color value to { r, g, b }
    const { red: r, green: g, blue: b } = hexRgb(colorField.value);
    // rgbaValue is what's passed into color picker
    rgbaValue = { r, g, b, a: opacityField.value };
  }

  return (
    <SketchPicker
      presetColors={[]}
      color={rgbaValue}
      onChangeComplete={({ rgb: { a: opacity }, hex }) => {
        // color field stored as hex in color field
        colorField.onChange(hex === 'transparent' ? '#000000' : hex);
        // opacity float is stored in its own color_opacity field
        opacityField.onChange(opacity);
      }}
      {...otherProps}
    />
  );
};

ColorWithOpacityPicker.propTypes = {
  colorField: reduxFormFieldType,
  opacityField: reduxFormFieldType,
};

ColorWithOpacityPicker.defaultProps = {
  colorField: {
    value: null,
    onChange: () => {},
  },
  opacityField: {
    value: null,
    onChange: () => {},
  },
};

export default ColorWithOpacityPicker;
