import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUsersByUri } from 'rapidfab/selectors';
import { API_RESOURCES, DATETIME_DEFAULT_FORMAT } from 'rapidfab/constants';
import Actions from 'rapidfab/actions';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';

const LastUpdated = ({
  usersByUri,
  resource,
  initialiseUser,
  capitalized,
}) => {
  const { updated_by, updated, version, edited_by } = resource || {};
  const updatedByUserUri = updated_by || edited_by;
  const updatedByUserName = usersByUri[updatedByUserUri] && usersByUri[updatedByUserUri].name;

  useEffect(() => {
    // to get editor data in case it is missing
    if (!updatedByUserName && updatedByUserUri) {
      initialiseUser(updatedByUserUri);
    }
  }, [updatedByUserUri]);

  if (!resource) return null;
  if (!usersByUri || usersByUri.length) return null;

  if ((!updated_by || !edited_by) && !updated && !version) return null;

  const updatedAt = updated;

  return (
    <div className="m-b">
      {(updatedByUserName || updatedAt) && (
        <span className={capitalized && 'text-capitalize'}>
          {updatedByUserName ? (
            <FormattedMessage
              id="lastEdited"
              defaultMessage="last edited by {updatedByUserName} on {when}."
              values={{
                updatedByUserName: updatedByUserName || 'Unknown',
                when: dayjs(updatedAt).format(DATETIME_DEFAULT_FORMAT) || 'Unknown',
              }}
            />
          ) : (
            <FormattedMessage
              id="lastEditedBySystem"
              defaultMessage="were created by the system on {when}."
              values={{
                when: dayjs(updatedAt).format(DATETIME_DEFAULT_FORMAT) || 'Unknown',
              }}
            />
          )}
          {' '}
        </span>
      )}
    </div>
  );
};

LastUpdated.defaultProps = {
  resource: null,
  capitalized: true,
};

LastUpdated.propTypes = {
  usersByUri: PropTypes.objectOf(PropTypes.shape({ name: PropTypes.string })).isRequired,
  resource: PropTypes.shape({
    updated_by: PropTypes.string,
    edited_by: PropTypes.string,
    updated: PropTypes.string,
    version: PropTypes.number,
  }),
  initialiseUser: PropTypes.func.isRequired,
  capitalized: PropTypes.bool,
};

const mapStateToProps = state => {
  const usersByUri = getUsersByUri(state);
  return {
    usersByUri,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    initialiseUser: userUri => {
      dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({ uri: userUri }));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LastUpdated);
