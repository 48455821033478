import React from 'react';
import PropTypes from 'prop-types';

import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Badge, Button, Col, Container, Row } from 'react-bootstrap';
import DisabledFor from 'rapidfab/components/DisabledFor';
import DisabledIconWithTooltip from 'rapidfab/components/DisabledIconWithTooltip';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { ROUTES } from 'rapidfab/constants';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Table from 'rapidfab/components/Tables/Table';
import _isEmpty from 'lodash/isEmpty';

const PostProcessorTypesGrid = ({ postProcessorTypes, isFetching, isAssemblyColumnShown }) => {
  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'post-processor-type',
    },
    {
      type: 'custom',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
      Cell: ({ row: { original } }) => (
        <div>
          {original.name}
          {original.archived && (
            <Badge bg="default" className="badge-sm ms-2">
              <FormattedMessage id="field.archived" defaultMessage="Archived" />
            </Badge>
          )}
        </div>
      ),
    },
    (isAssemblyColumnShown) && {
      type: 'bool',
      uid: 'assembly',
      accessor: 'is_assembly',
      defaultMessage: 'Assembly',
      isSortable: true,
    },
  ];
  return (
    <Table
      tableID="postProcessorTypes"
      data={postProcessorTypes}
      columns={columns}
      isFilteringEnabled
      withDefaultPagination
      initialSortedColumn="name"
      initialSortedDesc={false}
      isManualSoringEnabled={false}
      isUpdatedColumnShown={false}
      isFetching={isFetching}
    />
  );
};

PostProcessorTypesGrid.propTypes = {
  postProcessorTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      archived: PropTypes.string,
    }),
  }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isAssemblyColumnShown: PropTypes.bool.isRequired,
};

const PostProcessorTypes = ({
  isGroupQualificationsFeatureEnabled,
  extraFilters,
  ...componentProps
}) => (
  <Container fluid>
    <BreadcrumbNav breadcrumbs={['manage', 'postProcessorTypes']} />

    <Row>
      <Col xs={12}>
        <div className="pull-right">
          <DisabledFor
            nonManager
            /*
              Disable for Non Managers only when `group-qualifications` feature is enabled.
              No limitations otherwise
              */
            alwaysEnabled={!isGroupQualificationsFeatureEnabled}
            disabledPrefix={<DisabledIconWithTooltip placement="bottom" />}
          >
            {({ disabled }) => (
              <Button
                variant="primary"
                size="sm"
                href={getRouteURI(ROUTES.POST_PROCESSOR_TYPE_NEW)}
                disabled={disabled}
              >
                <Fa name="plus" />{' '}
                <FormattedMessage id="record.postProcessorType.add" defaultMessage="Add Post Processor Type" />
              </Button>
            )}
          </DisabledFor>
        </div>
      </Col>
    </Row>

    <hr />

    <Row>
      <Col xs={12}>
        {/* <<<<<<< HEAD */}
        {/*         {fetching ? ( */}
        {/*           <Loading /> */}
        {/*         ) : ( */}
        {/*           <PostProcessorTypesGrid */}
        {/*             postProcessorTypes={postProcessorTypes} */}
        {/*             materials={materials} */}
        {/*             isAssemblyColumnShown={isAssemblyColumnShown} */}
        {/*           /> */}
        {/* ======= */}
        {!_isEmpty(extraFilters) && (
          <Row className="mb8">
            <Col className="col-lg-10 col-12 offset-lg-1">
              <div className="filters-label ">Filters:</div>
              {extraFilters}
            </Col>
          </Row>
        )}
        <PostProcessorTypesGrid {...componentProps} />
      </Col>
    </Row>
  </Container>
);

PostProcessorTypes.defaultProps = {
  extraFilters: [],
};

PostProcessorTypes.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  postProcessorTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isGroupQualificationsFeatureEnabled: PropTypes.bool.isRequired,
  extraFilters: PropTypes.arrayOf(PropTypes.shape({})),
  isAssemblyColumnShown: PropTypes.bool.isRequired,
};

export default PostProcessorTypes;
