import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES } from 'rapidfab/constants';
import Alert from 'rapidfab/utils/alert';
import { AnatomicalModelAssemblyModal } from 'rapidfab/components/AnatomicalModel';

import { ANATOMICAL_MODEL_ASSEMBLY_MODAL_CONTAINER } from 'rapidfab/constants/forms';
import { FormattedMessage } from 'react-intl';

const AnatomicalModelAssemblyModalContainer = ({ uuid, anatomicalModelUri, onClose }) => {
  const isLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ANATOMICAL_MODEL_ASSEMBLY].get.fetching);
  const isSubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ANATOMICAL_MODEL_ASSEMBLY].post.fetching ||
    state.ui.nautilus[API_RESOURCES.ANATOMICAL_MODEL_ASSEMBLY].put.fetching);
  const assembly = useSelector(state => uuid && Selectors.getUUIDResource(state, uuid));
  const initialValues = assembly || {};

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => ANATOMICAL_MODEL_ASSEMBLY_MODAL_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  const dispatch = useDispatch();
  const onInitialize = uuid => {
    if (!uuid) {
      return;
    }
    dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_ASSEMBLY].get(uuid));
  };

  useEffect(() => {
    onInitialize(uuid);
  }, [uuid]);

  const onFormSubmit = async payload => {
    const updatedPayload = { ...payload };

    let assemblyUri;
    if (updatedPayload.uuid) {
      assemblyUri = updatedPayload.uri;
      await dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_ASSEMBLY]
        .put(updatedPayload.uuid, updatedPayload));
      Alert.success(
        <FormattedMessage
          id="toaster.assembly.updated"
          defaultMessage="Assembly updated successfully."
        />);
    } else {
      updatedPayload.anatomical_model = anatomicalModelUri;
      const prepTaskResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_ASSEMBLY]
        .post(updatedPayload));

      assemblyUri = prepTaskResponse.headers.location;
      Alert.success(
        <FormattedMessage
          id="toaster.assembly.created"
          defaultMessage="Assembly created successfully."
        />);
    }
    onClose(assemblyUri);
  };

  return (
    <AnatomicalModelAssemblyModal
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      onFormSubmit={onFormSubmit}
      onClose={onClose}
      initialFormValues={initialFormValues}
    />
  );
};

AnatomicalModelAssemblyModalContainer.defaultProps = {
  uuid: null,
};

AnatomicalModelAssemblyModalContainer.propTypes = {
  anatomicalModelUri: PropTypes.string.isRequired,
  uuid: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default AnatomicalModelAssemblyModalContainer;
