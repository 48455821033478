import { createSelector } from 'reselect';
import { getStateResources } from 'rapidfab/selectors/helpers/base';
import { getStateAnatomicalModelSummaryReports } from 'rapidfab/selectors/baseStateSelectors';
import _sortBy from 'lodash/sortBy';
import dayjs from 'dayjs';

// eslint-disable-next-line import/prefer-default-export
export const getAnatomicalModelSummaryReports = createSelector(
  [getStateAnatomicalModelSummaryReports, getStateResources],
  (uuids, resources) => _sortBy(
    uuids.map(uuid => resources[uuid]), summaryReport => dayjs(summaryReport.created).local(),
  ),
);
