import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Row } from 'react-bootstrap';

import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';

import Table from 'rapidfab/components/Tables/Table';

const ShippingsGrid = ({ records }) => {
  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'shipping',
    },
    {
      type: 'text',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
    },
    {
      type: 'caps',
      uid: 'field.region',
      accessor: 'region',
      defaultMessage: 'Region',
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'field.cost',
      accessor: 'cost',
      defaultMessage: 'Cost',
      isSortable: true,
    },
  ];

  return (
    <Table
      tableID="shipping"
      data={records}
      columns={columns}
      isFilteringEnabled
      withDefaultPagination
      isManualSoringEnabled={false}
      initialSortedColumn="name"
      initialSortedDesc={false}
      isUpdatedColumnShown={false}
    />
  );
};

ShippingsGrid.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const Shippings = ({ shippings, fetching }) => (
  <Container fluid>
    <BreadcrumbNav breadcrumbs={['manage', 'shipping']} />

    <Row>
      <Col xs={12}>
        <Button
          variant="primary"
          size="sm"
          href={getRouteURI(ROUTES.SHIPPING_NEW)}
          className="pull-right"
        >
          <Fa name="plus" />{' '}
          <FormattedMessage
            id="record.shipping.add"
            defaultMessage="Add Shipping"
          />
        </Button>
      </Col>
    </Row>

    <hr />

    <Row>
      <Col xs={12}>
        {fetching ? <Loading /> : <ShippingsGrid records={shippings} />}
      </Col>
    </Row>
  </Container>
);

Shippings.propTypes = {
  fetching: PropTypes.bool.isRequired,
  shippings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Shippings;
