import React, { useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import { ORDER_UNLOCKED_STATUSES } from 'rapidfab/constants';
import { Button, Container } from 'react-bootstrap';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import AddProductContainer from 'rapidfab/containers/AddProductContainer';
import OrderProductsContainer from 'rapidfab/containers/OrderProductsContainer';
import { getRouteUUIDResource } from 'rapidfab/selectors';
import { FormattedMessage } from 'rapidfab/i18n';
import * as Selectors from 'rapidfab/selectors';
import OrderSummaryContainer from 'rapidfab/containers/records/order/OrderSummaryContainer';

const EditOrder = () => {
  const order = useSelector(getRouteUUIDResource) || {};
  const breadcrumbOrderLabel = order?.id ? `${order.name} (${order.customer_id || getShortUUID(order.uuid)})` : '';
  const isOrderUnlocked = ORDER_UNLOCKED_STATUSES.includes(order.status);
  const lineItems = useSelector(state => Selectors.getLineItemsForOrder(state, order));
  const [lineItemsLength, setLineItemsLength] = useState(lineItems.length);
  const [expandMode, setExpandMode] = useState(null);
  const [expandedItems, setExpandedItems] = useState([]);

  useEffect(() => {
    if (lineItemsLength > 0 && expandedItems.length === lineItemsLength) {
      setExpandMode('expanded');
    }
  }, [expandedItems.length, lineItemsLength]);

  const handleExpandAllLineItems = () => setExpandMode(previous => {
    if (!previous || previous === 'collapsed') {
      // eslint-disable-next-line unicorn/new-for-builtins
      setExpandedItems([...Array(lineItemsLength).keys()]);

      return 'expanded';
    }

    if (lineItemsLength > 0 && expandedItems.length === lineItemsLength) {
      setExpandedItems([]);
    }

    return 'collapsed';
  });

  useEffect(() => {
    setLineItemsLength(lineItems.length);
  }, [lineItems.length]);
  return (
    <Container className="p-b" fluid>
      <BreadcrumbNav breadcrumbs={['plan', 'orders', breadcrumbOrderLabel]} />
      <OrderSummaryContainer />
      <div className="d-flex justify-content-end mb15 mr15">
        {
          lineItemsLength > 1 && (
            <Button variant="info" onClick={handleExpandAllLineItems}>
              {expandMode === 'collapsed' || !expandMode || expandedItems.length !== lineItemsLength ? (
                <FormattedMessage id="button.expandAll" defaultMessage="Expand All" />
              ) : (
                <FormattedMessage id="button.collapseAll" defaultMessage="Collapse All" />
              )}
            </Button>
          )
        }
      </div>
      <OrderProductsContainer
        orderUri={order.uri}
        expandMode={expandMode}
        expandedItems={expandedItems}
        setExpandedItems={setExpandedItems}
        setExpandMode={setExpandMode}
      />
      {!isOrderUnlocked ? (
        <div className="jumbotron">
          <p>
            Additional line items cannot be added to a confirmed order. Please
            create a new order to add additional line items.
          </p>
        </div>
      ) : (
        <AddProductContainer orderUri={order.uri} lineItemsLength={lineItemsLength} />
      )}
    </Container>
  );
};

export default memo(EditOrder);
