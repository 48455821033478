import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import _compact from 'lodash/compact';
import _isEqual from 'lodash/isEqual';
import { pieceResourceType } from 'rapidfab/types';
import PropTypes from 'prop-types';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES } from 'rapidfab/constants';

import Pieces from 'rapidfab/components/plan/pieces';
import withRecordsListHandling from '../hocs/withRecordsListHandling';

const PiecesContainer = props => {
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);
  const ordersByUri = useSelector(Selectors.getOrdersByUri);
  const runs = useSelector(Selectors.getRuns);
  const isFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PIECE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.ORDER].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PRINT].list.fetching ||
    state.ui.nautilus[API_RESOURCES.RUN].list.fetching,
  );

  const dispatch = useDispatch();
  const onFetchOrders = ordersUris => dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].list(
    { uri: ordersUris },
    { limit: ordersUris.length },
  ));

  const { data } = props;
  const orderUris = _compact(_uniq(_map(data, 'order')));
  const printUris = _compact(_uniq(_map(data, 'current_print')));

  const [previousOrderUris, setPreviousOrderUris] = useState();

  useEffect(() => {
    if (orderUris.length && !_isEqual(previousOrderUris, orderUris)) {
      setPreviousOrderUris(orderUris);
      onFetchOrders(orderUris);
    }
  }, [orderUris, isFetching]);

  const loadRunsByPrintUris = async printUris => {
    const printsResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.PRINT].list(
      { uri: printUris },
      { limit: printUris.length },
    ));
    const runUris = _compact(_uniq(_map(printsResponse?.json?.resources, 'run')));
    if (runUris.length) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].list(
        { uri: runUris },
        { limit: runUris.length },
      ));
    }
  };
  useEffect(() => {
    if (printUris.length) {
      loadRunsByPrintUris(printUris);
    }
  }, [JSON.stringify(printUris)]);

  return <Pieces isDebugModeEnabled={isDebugModeEnabled} ordersByUri={ordersByUri} runs={runs} {...props} />;
};

PiecesContainer.propTypes = {
  data: PropTypes.arrayOf(pieceResourceType).isRequired,
};

export default withRecordsListHandling(
  PiecesContainer,
  Selectors.getPieces,
  ['piece'],
);
