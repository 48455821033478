import React from 'react';
import { Route, Routes } from 'react-router-dom';
import InventoryManufacturers from 'rapidfab/containers/manage/manufacturers';
import InventoryLocations from 'rapidfab/containers/manage/locations';
import InventoryServiceProvidersContainer from 'rapidfab/containers/manage/ServiceProvidersContainer';
import InventoryShipping from 'rapidfab/containers/manage/shipping';
import InventoryMaterials from 'rapidfab/containers/manage/materials';
import InventoryPrinterTypes from 'rapidfab/containers/manage/printerTypes';
import InventoryPostProcessorTypes from 'rapidfab/containers/manage/postProcessorTypes';
import ProductionWorkflowsContainer from 'rapidfab/containers/manage/ProductionWorkflowsContainer';
import PrepWorkflowsContainer from 'rapidfab/containers/manage/PrepWorkflowsContainer';
import AssemblyWorkflowsContainer from 'rapidfab/containers/manage/AssemblyWorkflowsContainer';
import LibrariesContainer from 'rapidfab/containers/manage/LibrariesContainer';
import PropTypes from 'prop-types';
import ManageConversions from '../containers/manage/conversions';
import PrivateRoute from './PrivateRoute';
import { FEATURES } from '../constants';
import { ROUTER_PERMISSION } from '../constantsRouter';
import Shipments from '../containers/manage/Shipments';
import PrepTasksContainer from '../containers/manage/PrepTasksContainer';
import NotFound from '../components/404';

const ManageRoutes = ({ session }) => (
  <Routes>
    <Route
      path="/conversions"
      element={(
        <PrivateRoute
          session={session}
          component={ManageConversions}
          permission={ROUTER_PERMISSION.BUREAU_UNRESTRICTED}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/model-library"
      element={(
        <PrivateRoute
          session={session}
          component={LibrariesContainer}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
              [FEATURES.SPECIMEN_LIBRARY]: true,
            }
          }
        />
      )}
    />
    <Route
      path="/builds-library"
      element={(
        <PrivateRoute
          session={session}
          component={LibrariesContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.CERTIFIED_BUILDS]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/manufacturers"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryManufacturers}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/locations"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryLocations}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/service-providers"
      element={(
        <PrivateRoute
          session={session}
          isRestrictedRoute
          component={InventoryServiceProvidersContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.SERVICE_PROVIDERS]
          }
        />
      )}
    />
    <Route
      path="/shipping-partners"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryShipping}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/shipments"
      element={(
        <PrivateRoute
          session={session}
          component={Shipments}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          decidingFeatures={[
            FEATURES.SHIPMENT_FOR_ORDER,
            FEATURES.SERVICE_PROVIDER_AT_STEPS,
          ]}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/materials"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryMaterials}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/printer-types"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryPrinterTypes}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/post-processor-types"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryPostProcessorTypes}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/workflows"
      element={(
        <PrivateRoute
          session={session}
          component={ProductionWorkflowsContainer}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/prep-workflows"
      element={(
        <PrivateRoute
          session={session}
          component={PrepWorkflowsContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.PREP_WORKFLOW]
          }
        />
      )}
    />
    <Route
      path="/assembly-workflows"
      element={(
        <PrivateRoute
          session={session}
          component={AssemblyWorkflowsContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.ANATOMICAL_MODEL_TO_PRODUCT]
          }
        />
      )}
    />
    <Route
      path="/prep-tasks"
      element={(
        <PrivateRoute
          session={session}
          component={PrepTasksContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.PREP_WORKFLOW]
          }
        />
      )}
    />
    <Route
      path="*"
      element={<NotFound />}
    />
  </Routes>
);

ManageRoutes.propTypes = {
  session: PropTypes.shape({}).isRequired,
};

export default ManageRoutes;
