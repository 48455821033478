import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import Actions from 'rapidfab/actions';
import Workflow from 'rapidfab/components/records/workflow/Workflow';
import {
  API_RESOURCES, FEATURES,
  LIST_BY_URIS_CHUNK_SIZE,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  ROUTES,
  WORKFLOW_TYPES,
} from 'rapidfab/constants';
import * as Selectors from 'rapidfab/selectors';
import _get from 'lodash/get';
import _set from 'lodash/set';
import Alert from 'rapidfab/utils/alert';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import getEndpointFromURI from 'rapidfab/utils/getEndpointFromURI';
import { getSaveStepsDispatchers, saveWorkflow } from 'rapidfab/utils/handleWorkflowSave';
import _chunk from 'lodash/chunk';
import { WORKFLOW_CONTAINER } from 'rapidfab/constants/forms';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { isFeatureEnabled } from 'rapidfab/selectors';
import extractUuid from '../../utils/extractUuid';

function redirect(uri) {
  if (uri) {
    window.location.hash = getRouteURI(ROUTES.WORKFLOW_EDIT, { uuid: getEndpointFromURI(uri).uuid });
  } else {
    window.location.hash = getRouteURI(ROUTES.PRODUCTION_WORKFLOW_LIST);
  }
}

const WorkflowContainer = props => {
  const uuid = useSelector(Selectors.getRouteUUID);
  const [searchParams] = useSearchParams();
  const workflow = useSelector(state => Selectors.getUUIDResource(state, uuid));
  const specimens = useSelector(state => (workflow ? Selectors.getSpecimensForWorkflow(state, workflow) : null));
  const availablePrinterTypes = useSelector(Selectors.getAvailablePrinterTypes);
  const availablePostProcessorTypes = useSelector(Selectors.getAvailablePostProcessorTypes);
  const shippingTypes = useSelector(Selectors.getShippings);
  const specimenWorkflows = useSelector(Selectors.getSpecimenWorkflows);
  const processStepsRelatedResourcesFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PROCESS_STEP].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.SHIPPING].list.fetching);
  const checklistLinkings = useSelector(state =>
    (workflow ? Selectors.getRelatedWorkChecklistLinking(state, workflow.uri) : []));

  const isAssembly = workflow
    ? workflow.type === WORKFLOW_TYPES.ASSEMBLY
    // Query params is a string, so `"false"` will be = true. Checking for exact string 'true' instead
    : searchParams.get('isAssembly') === 'true';

  const initialValues = workflow;
  WORKFLOW_CONTAINER.STRING_FIELDS.forEach(
    fieldName => {
      const field = _get(initialValues, fieldName);
      if (field === null) {
        _set(initialValues, fieldName, '');
      }
    },
  );

  const initialFormValues = {};
  if (initialValues) {
    Object
      .keys(initialValues)
      .filter(key => WORKFLOW_CONTAINER.FIELDS.includes(key))
      .forEach(key => {
        initialFormValues[key] = initialValues[key];
      });
  }

  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PROCESS_STEP].list.fetching ||
    state.ui.nautilus[API_RESOURCES.WORKFLOW].list.fetching ||
    Selectors.getResourceFetching(state, 'nautilus.specimen'));

  const isSubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.WORKFLOW].put.fetching ||
    state.ui.nautilus[API_RESOURCES.WORKFLOW].post.fetching ||
    state.ui.nautilus[API_RESOURCES.WORKFLOW].replace.fetching ||
    state.ui.nautilus[API_RESOURCES.PROCESS_STEP].put.fetching ||
    state.ui.nautilus[API_RESOURCES.PROCESS_STEP].post.fetching);

  const modelLibraries = useSelector(state => Selectors.getModelLibrariesByUri(state, workflow));
  const steps = useSelector(state => Selectors.getProcessStepsForWorkflow(state, workflow));
  const postProcessorsByUri = useSelector(Selectors.getPostProcessorsByUri);
  const printersByUri = useSelector(Selectors.getPrintersByUri);
  const printerTypesByUri = useSelector(Selectors.getPrinterTypesByUri);
  const postProcessorTypesByUri = useSelector(Selectors.getPostProcessorTypesByUri);
  const shippingsByUri = useSelector(Selectors.getShippingsByUri);
  const isPowderEnabled = useSelector(
    state => isFeatureEnabled(state, FEATURES.POWDER_WORKFLOW),
  );
  const processTypesByUri = { ...printerTypesByUri, ...postProcessorTypesByUri, ...shippingsByUri };

  const selected = {
    uuid,
    workflow,
    fetching,
    isSubmitting,
    specimens,
    modelLibraries,
    initialFormValues,
    steps,
    checklistLinkings,
    availablePrinterTypes,
    availablePostProcessorTypes,
    shippingTypes,
    specimenWorkflows,
    isAssembly,
    postProcessorsByUri,
    printersByUri,
    processTypesByUri,
    isPowderEnabled,
    processStepsFetching: processStepsRelatedResourcesFetching,
  };

  const dispatch = useDispatch();

  const onInitialize = currentUUID => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list({ type: WORKFLOW_TYPES.SPECIMEN }, {}, {}, {}, true));
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW]
        .get(currentUUID, {}, {}, {}, true))
        .then(response => {
          const workflowUri = response?.json?.uri;
          dispatch(Actions.Api.nautilus[API_RESOURCES.SPECIMEN].clear('list'));
          dispatch(Actions.Api.nautilus[API_RESOURCES.SPECIMEN].list(
            { parent_workflow: workflowUri },
            null, null, true,
          ));
          dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_CHECKLIST_LINKING].list(
            { related_uri: workflowUri },
            { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
          ));
          dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list({ workflows: workflowUri }));
        });
    }
  };
  const loadProcessSteps = processStepUris => {
    _chunk(processStepUris, LIST_BY_URIS_CHUNK_SIZE).forEach(processStepUrisChunk => {
      dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list({ uri: processStepUrisChunk }));
    });
  };
  const onSave = (payload, deletedSteps, deletedSpecimens) => {
    const updatedPayload = { ...payload };
    // delete readonly fields
    delete updatedPayload.flow_time;
    delete updatedPayload.flow_time_queued;
    saveWorkflow(dispatch, updatedPayload).then(response => {
      // Last Updated (user/date) info is set on the backend, and there is no
      // event-stream event for workflows. So GET request is required right
      // after PUT

      const uri = response?.headers?.location;

      dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].get(extractUuid(uri)));
      const stepsToDelete = deletedSteps.filter(
        stepUUID => steps.find(step_ => extractUuid(step_.uri) === stepUUID)?.workflows.length < 2,
      );
      const deletePromises = _map(stepsToDelete, currentUUID =>
        dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].delete(currentUUID)),
      );
      Promise.all(deletePromises).then(() => {
        const deleteSpecimensPromises = _map(deletedSpecimens, specimen =>
          dispatch(Actions.Api.nautilus[API_RESOURCES.SPECIMEN].delete(specimen.uuid)),
        );
        Promise.all(deleteSpecimensPromises).then(() => {
          if (!extractUuid(payload.uri)) {
            Alert.success(
              <FormattedMessage
                id="toaster.specimen.created"
                defaultMessage="Successfully created"
              />);
          } else {
            Alert.success(<FormattedMessage
              id="toaster.specimen.updated"
              defaultMessage="Successfully updated"
            />);
          }
          if (uri) {
            redirect(uri);
          }
        });
      });
    });
  };
  const onDelete = currentUUID => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].delete(currentUUID))
        .then(() => Alert.success(
          <FormattedMessage
            id="toaster.workflow.deleted"
            defaultMessage="Workflow {uuid} successfully deleted."
            values={{ uuid: currentUUID }}
          />,
        ))
        .finally(() => redirect());
    }
  };
  const onDuplicate = workflowCopy => {
    const { name, steps: currentSteps, type, description, source_workflow, specimens } = workflowCopy;
    const stepCopiesUris = getSaveStepsDispatchers(
      dispatch,
      [],
      _map(currentSteps, step => _omit(step, ['uuid', 'uri'])),
    );
    Promise.all(stepCopiesUris).then(processStepsUris => {
      const payload = {
        description,
        name,
        type,
        source_workflow,
        process_steps: processStepsUris,
        specimens,
      };

      saveWorkflow(dispatch, payload).then(() => {
        Alert.success(<FormattedMessage
          id="toaster.workflow.duplicated"
          defaultMessage="Successfully duplicated"
        />);
        redirect();
      });
    });
  };
  const onReplace = payload => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].replace(payload.uuid, payload))
      .then(response => {
        Alert.success(<FormattedMessage
          id="toaster.workflow.replaced"
          defaultMessage="Successfully replaced"
        />);
        redirect(response.headers.location);
      })
      .catch(() => Alert.error(
        <FormattedMessage
          id="toaster.error.workflow.failedToReplace"
          defaultMessage="Failed to replace, please check the step order"
        />));
  };

  const saveSteps = (existingSteps, stepsToSave) =>
    getSaveStepsDispatchers(dispatch, existingSteps, stepsToSave);
  const onUnmount = () => {
    // get rid of pesky lingering errors
    dispatch(Actions.UI.clearUIState(['nautilus.location']));
  };

  /* Taking into account when we call /model-library .list() when onInitialize method fires
     it will provide us with 20 models (due to pagination limits) and within 20 models it can
     find 3-5 specimens only. So created the manual method to get all the specimens when new
     workflow is about to be created and "Add Specimen" modal window appears. */
  const handleGetAllSpecimens = async () => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].list(
      { type: 'specimen' },
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      {},
      {},
      true));
  };

  useEffect(() => {
    onInitialize(uuid);
  }, [uuid]);

  useEffect(() => {
    if (workflow?.process_steps?.length) {
      loadProcessSteps(workflow?.process_steps);
    }
  }, [JSON.stringify(workflow?.process_steps)]);

  useEffect(() => () => onUnmount(), []);

  const dispatched = { saveSteps, onReplace, onDelete, onDuplicate, onSave, handleGetAllSpecimens };

  return (
    <Workflow {...props} {...selected} {...dispatched} fields={initialFormValues} />
  );
};

WorkflowContainer.propTypes = {
  loadProcessSteps: PropTypes.func.isRequired,
};

export default WorkflowContainer;
