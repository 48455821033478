import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Row,
  Col,
} from 'react-bootstrap';
import { Form } from 'react-final-form';
import FormGroupField from 'rapidfab/components/forms/FormGroupField';
import SaveButton from 'rapidfab/components/SaveButton';
import { FormattedMessage } from 'rapidfab/i18n';
import { ROUTES, XEROX_LOGIN_ROUTE_PREFIX } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import Alert from 'rapidfab/utils/alert';

const ResetPassword = ({ onReset }) => {
  const isHawkingRoute = window.location.hash.includes(XEROX_LOGIN_ROUTE_PREFIX);
  const loginUri = getRouteURI(isHawkingRoute ? ROUTES.LOGIN_XEROX : ROUTES.LOGIN);
  const [isLoading, setIsLoading] = useState(false);

  const fields = {
    password: 'password',
    confirmPassword: 'confirm_password',
  };

  const onSubmitForm = formValues => {
    setIsLoading(true);
    onReset(formValues)
      .then(() => {
        Alert.success(<FormattedMessage id="toaster.passwordReset" defaultMessage="Your password has been reset." />);
        window.location.hash = loginUri;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Row>
      <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }}>
        <h3>
          <FormattedMessage
            id="resetPassword"
            defaultMessage="Reset Password"
          />
        </h3>
        <hr />
        <Form onSubmit={onSubmitForm}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormGroupField
                name={fields.password}
                type="password"
                required
                label={(
                  <FormattedMessage
                    id="field.password"
                    defaultMessage="Password"
                  />
                )}
              />
              <FormGroupField
                name={fields.confirmPassword}
                type="password"
                required
                label={(
                  <FormattedMessage
                    id="field.confirmPassword"
                    defaultMessage="Confirm Password"
                  />
                )}
              />
              {/* <ButtonGroup block vertical> */}
              <div className="btn-group-vertical">
                <SaveButton
                  showSaveIcon={false}
                  isSaving={isLoading}
                  label={(
                    <FormattedMessage
                      id="button.submit"
                      defaultMessage="Submit"
                    />
                  )}
                  className={isHawkingRoute && 'dark-gray-btn'}
                  variant="success"
                />
              </div>
              {/* </ButtonGroup> */}
            </form>
          )}
        </Form>
        <div className="mt15 text-center">
          <a href={loginUri}>
            <FormattedMessage
              id="returnToLogin"
              defaultMessage="Return to login page"
            />
          </a>
        </div>
      </Col>
    </Row>
  );
};

ResetPassword.propTypes = {
  onReset: PropTypes.func.isRequired,
};

export default ResetPassword;
