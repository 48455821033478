import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'react-bootstrap';
import Fa from 'react-fontawesome';

import { FormattedMessage } from 'rapidfab/i18n';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { ROUTES } from 'rapidfab/constants';
import { useNavigate } from 'react-router-dom';

const styles = { spacingBelow: { marginBottom: '2rem' } };

const RunScheduleButton = ({ uuid, disabled }) => {
  const navigate = useNavigate();
  const href = getRouteURI(ROUTES.RUN_SCHEDULE, { uuid }, {}, true);
  return (
    <div className="btn-group-vertical w-full" style={styles.spacingBelow}>
      <Button variant="default" disabled={disabled} onClick={() => navigate(href)}>
        <Fa name="clock-o" />{' '}
        <FormattedMessage
          id="manuallySchedule"
          defaultMessage="Manually Schedule"
        />
      </Button>
    </div>
  );
};
RunScheduleButton.defaultProps = {
  uuid: null,
  disabled: false,
};
RunScheduleButton.propTypes = {
  uuid: PropTypes.string,
  disabled: PropTypes.bool,
};
export default RunScheduleButton;
