import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import {
  ButtonToolbar,
  Container,
  Row,
  Col,
  Card,
  Button, Nav, NavItem, NavLink,
} from 'react-bootstrap';
import { FormControlTextAreaWithCustomHandlers } from 'rapidfab/components/formTools';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import extractUuid from 'rapidfab/utils/extractUuid';
import {
  MATERIAL_BATCH_ACTION_TYPES_MAP,
  MATERIAL_BATCH_CUSTOM_STATUSES_MAP,
  MATERIAL_BATCH_STATUS_MAP,
} from 'rapidfab/mappings';
import { FormattedMessage } from 'rapidfab/i18n';
import Fa from 'react-fontawesome';
import Documents from 'rapidfab/components/records/Documents';
import {
  DOCUMENT_RELATED_TABLE_NAMES,
  MATERIAL_BATCH_STATUSES,
  MATERIAL_CONTAINER_STATUSES,
  ROUTES,
  NAVBAR_HEIGHT_PX,
  FEATURES,
} from 'rapidfab/constants';
import MaterialBatchMetadataColumn from 'rapidfab/components/records/material_management/MaterialBatchMetadataColumn';
import TraceabilityReportExportButton from 'rapidfab/components/records/material_management/TraceabilityReportExportButton';
import ContainerizeBlock from 'rapidfab/components/records/material_management/ContainerizeBlock';
import ContainersEditableGrid from 'rapidfab/components/records/material_management/ContainersEditableGrid';
import MaterialInfo from 'rapidfab/components/records/material_management/MaterialInfo';
import BatchEditableQuantity from 'rapidfab/components/records/material_management/BatchEditableQuantity';
import { getMaterialBatchActionQuantity } from 'rapidfab/utils/materialBatchAction';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import ResourceReadOnlyViewRow, { RESOURCE_READ_ONLY_VIEW_FIELD_TYPES } from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import ManageTestPanels from 'rapidfab/components/records/material_management/ManageTestPanels';
import MaterialAddTestPanelModal from 'rapidfab/components/records/material_management/MaterialAddTestPanelModal';
import {
  materialBatchResourceType,
  printerResourceType,
  printerTypeResourceType,
  materialTypeResourceType, subLocationResourceType, userResourceType,
} from 'rapidfab/types';
import { Link } from 'react-router-dom';
import Table from 'rapidfab/components/Tables/Table';
import Feature from 'rapidfab/components/Feature';
import _isEmpty from 'lodash/isEmpty';
import ManageTestActions from 'rapidfab/components/records/material_management/ManageTestActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableList, faChartLine } from '@fortawesome/free-solid-svg-icons';
import LotDetails from './material_management/LotDetails';

const TRACEABILITY_REPORT_TABS = {
  ACTION_LOG: 'action_log',
  SENSOR_HISTORY: 'sensor_history',
};

const MaterialBatch = ({
  containers,
  isLoading,
  material,
  batch,
  lot,
  printer,
  samples,
  printerType,
  printerTypeMaterials,
  printerLocation,
  batchActions,
  scrollToTraceabilityReport,
  onSaveNotes,
  onEditBatchQuantityAction,
  isBatchActionSaving,
  isGeneralMFGLanguageEnabled,
  notes,
  onChangeNotes,
  materialTestPanels,
  materialTestPanelsByUri,
  materialAddTestPanelModalState: {
    showMaterialAddTestPanelModal,
    setShowMaterialAddTestPanelModal,
    testPanelCurrentlyEdited,
    setTestPanelCurrentlyEdited,
  },
  materialTestInstructions,
  materialTestInstructionsByUri,
  materialTestOperations,
  materialTestOperationLinkings,
  materialTestUnits,
  materialTestUnitsByUri,
  manageTestPanelsState,
  materialTestInstructionReports,
  materialTestInstructionReportsByUri,
  isMaterialTestPanelFeatureEnabled,
  onInitializeMaterialTest,
  subLocation,
  usersByUri,
}) => {
  const traceabilityReportRef = useRef();
  const [tab, setTab] = useState(TRACEABILITY_REPORT_TABS.ACTION_LOG);

  const onNotesSubmit = () => onSaveNotes(batch.uuid, notes);
  const onContainersSubmit =
    (bulkContainersOperations, reasonCode, containersEditReason) =>
      onEditBatchQuantityAction({
        uri: batch.uri,
        updatedContainers: bulkContainersOperations.update,
        notes: containersEditReason,
        reasonCode,
      });
  const onQuantityChangeSubmit =
    ({ quantity, notes: quantityEditReason, reasonCode }) =>
      onEditBatchQuantityAction({
        uri: batch.uri,
        notes: quantityEditReason,
        quantity,
        reasonCode,
      });

  useEffect(() => {
    if (scrollToTraceabilityReport) {
      window.scrollTo(
        0,
        traceabilityReportRef.current.getBoundingClientRect().top - NAVBAR_HEIGHT_PX,
      );
    }
  }, [scrollToTraceabilityReport]);

  const breadcrumbs = ['inventory', 'materialBatches'];
  if (!batch.is_initial_batch) {
    breadcrumbs.push(
      {
        message: `${lot.name} (${getShortUUID(batch.initial_batch)})`,
        href: getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(batch.initial_batch) }),
      },
      getShortUUID(batch.uri));
  } else {
    breadcrumbs.push(`${lot.name} (${getShortUUID(batch.uri)})`);
  }

  const columns = [
    {
      type: 'text',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      short: true,
    },
    {
      type: 'translatable',
      uid: 'field.type',
      accessor: 'action_type',
      defaultMessage: 'Type',
      mapping: MATERIAL_BATCH_ACTION_TYPES_MAP,
    },
    {
      type: 'custom',
      uid: 'details',
      accessor: 'metadata',
      defaultMessage: 'Details',
      Cell: ({ row: { original } }) => (
        <MaterialBatchMetadataColumn rowData={original} />
      ),
    },
    {
      type: 'custom',
      uid: 'field.quantity',
      accessor: 'quantity',
      defaultMessage: 'Quantity',
      Cell: ({ row: { original } }) => (
        getMaterialBatchActionQuantity(original)
      ),
    },
    {
      type: 'time',
      uid: 'field.date',
      accessor: 'created',
      defaultMessage: 'Date',
    },
    {
      type: 'contact',
      uid: 'field.user',
      accessor: 'user',
      defaultMessage: 'User',
      users: usersByUri,
    },
  ];

  /*
    In the Select Samples Dropdown, we should show the only available samples,
    which means they should not be already linked to some Material Test Panels.
  */
  const filteredSamples = samples.filter(sample => {
    const sampleUri = sample.uri;
    const materialTestPanel = materialTestPanels.find(materialTestPanel => materialTestPanel.sample === sampleUri);
    return !materialTestPanel;
  });

  return (
    <Container fluid>
      <Feature
        featureName={FEATURES.MATERIAL_TEST_PANEL}
      >
        {showMaterialAddTestPanelModal && (
          <MaterialAddTestPanelModal
            samples={samples}
            isLoading={isLoading}
            filteredSamples={filteredSamples}
            materialTestPanel={materialTestPanelsByUri[testPanelCurrentlyEdited]}
            setTestPanelCurrentlyEdited={setTestPanelCurrentlyEdited}
            materialTestPanels={materialTestPanels}
            materialTestInstructions={materialTestInstructions}
            materialTestOperationLinkings={materialTestOperationLinkings}
            materialTestOperations={materialTestOperations}
            materialTestUnits={materialTestUnits}
            materialTestUnitsByUri={materialTestUnitsByUri}
            onClose={() => setShowMaterialAddTestPanelModal(false)}
            onInitializeMaterialTest={onInitializeMaterialTest}
          />
        )}
      </Feature>
      <BreadcrumbNav breadcrumbs={breadcrumbs} />

      <ButtonToolbar className="clearfix">
        <div className="pull-left btn-toolbar-item">
          <div>
            <Link
              to={getRouteURI(ROUTES.MATERIAL_BATCH_GENEALOGY, { uuid: extractUuid(batch.uri) }, {}, true)}
            >
              Genealogy View
            </Link>
          </div>
        </div>
      </ButtonToolbar>

      <hr />

      <Row>
        <Col xs={{ span: 12 }} md={{ span: 6 }}>
          <Card bg="dark" className="mb15">
            <Card.Header className="pd-exp accent">Batch Details</Card.Header>
            <div className="card-body-wrapper-accent">
              <Card.Body>
                <Row>
                  <Col xs={{ span: 6 }}>
                    <ResourceReadOnlyView
                      entity={batch}
                    >
                      <ResourceReadOnlyViewRow
                        name="location_name"
                        label="Location"
                        type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                        customValue={batch && `${batch.location_name} ${subLocation ? `(${subLocation.name})` : ''}`}
                      />
                      {!!batch.quantity && (
                        <ResourceReadOnlyViewRow
                          name="material_in_containers"
                          label="Track Individual Containers"
                          type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.BOOLEAN}
                        />
                      )}
                      <ResourceReadOnlyViewRow name="materials" type="custom" customValue={batch.materials.map(material => material.name).join(', ')} label={batch.materials.length > 1 ? 'Materials' : 'Material'} className="wrap-text" />
                      <ResourceReadOnlyViewRow
                        name="quantity"
                        type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                        customValue={batch && `${batch.quantity} (${batch.units})`}
                      />
                      <ResourceReadOnlyViewRow
                        name="status"
                        type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                        customValue={
                          batch
                      && <FormattedMessage {...MATERIAL_BATCH_STATUS_MAP[batch.status]} />
                        }
                      />
                      <ResourceReadOnlyViewRow
                        name="custom_status"
                        type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                        customValue={(
                          batch
                      && batch.custom_status
                      && <FormattedMessage {...MATERIAL_BATCH_CUSTOM_STATUSES_MAP[batch.custom_status]} />
                        )}
                      />
                      <ResourceReadOnlyViewRow name="usage_cycles" />
                      <ResourceReadOnlyViewRow
                        name="updated"
                        type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATETIME}
                      />
                    </ResourceReadOnlyView>
                  </Col>
                  <Col xs={{ span: 6 }}>
                    <ContainerizeBlock batch={batch} />
                  </Col>
                  <Col xs={{ span: 12 }} className="mt15">
                    <b>Notes:</b>
                    <FormControlTextAreaWithCustomHandlers
                      name="notes"
                      value={notes}
                      onChange={onChangeNotes}
                    />
                    <Button
                      onClick={onNotesSubmit}
                      size="sm"
                      variant="success"
                      className="pull-right mt15"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </div>
          </Card>

          <ResourceReadOnlyView
            withPanelWrapper
            title={isGeneralMFGLanguageEnabled ? 'Production Device' : 'Printer'}
            entity={printer}
            placeholder="This batch is not currently loaded in a machine"
          >
            <ResourceReadOnlyViewRow
              label="Printer Name"
              type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
              customValue={
                printer
                && (
                  <>
                    <Link
                      to={getRouteURI(ROUTES.PRINTER_EDIT, { uuid: extractUuid(printer.uri) }, {}, true)}
                    >
                      {' '}{printer.name}
                    </Link>
                    <Link
                      to={getRouteURI(ROUTES.PRINT_PRINTER_QR, { uuid: extractUuid(printer.uri) }, {}, true)}
                    >
                      <Fa name="qrcode" className="spacer-left" />
                    </Link>
                  </>
                )
              }
            />
            <ResourceReadOnlyViewRow
              label="Printer Type"
              type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
              customValue={printerType && printerType.name}
            />
            <ResourceReadOnlyViewRow
              label="Location"
              type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
              customValue={printerLocation && printerLocation.name}
            />
            <ResourceReadOnlyViewRow
              label="Supported Material Types"
              className="wrap-text"
              type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
              customValue={printerTypeMaterials && printerTypeMaterials.map(({ name }) => name).join(', ')}
            />
          </ResourceReadOnlyView>

          {lot && (
            <LotDetails
              lot={lot}
              material={material}
              batch={batch}
            />
          )}

          <MaterialInfo material={material} />
        </Col>
        <Col xs={{ span: 12 }} md={{ span: 6 }}>
          <Documents
            relatedTable={DOCUMENT_RELATED_TABLE_NAMES.MATERIAL_BATCH}
            relatedUUID={extractUuid(batch.uri)}
          />
          <div ref={traceabilityReportRef}>
            <Card bg="dark" className="mb15">
              <Card.Header className="pd-exp inverse">Material Traceability Report</Card.Header>
              <div className="card-body-wrapper">
                <Card.Body>
                  <Row className="mb15">
                    <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                      <Nav
                        variant="tabs"
                        activeKey={tab}
                        onSelect={setTab}
                      >
                        <NavItem>
                          <NavLink eventKey={TRACEABILITY_REPORT_TABS.ACTION_LOG}>
                            <FontAwesomeIcon icon={faTableList} className="spacer-right" />
                            <FormattedMessage
                              id="tab.actionLog"
                              defaultMessage="Action Log"
                            />
                          </NavLink>
                        </NavItem>
                        {/* The functionality is not implemented, so the tab is disabled */}
                        <NavItem>
                          <NavLink disabled eventKey={TRACEABILITY_REPORT_TABS.SENSOR_HISTORY}>
                            <FontAwesomeIcon icon={faChartLine} className="spacer-right" />
                            <FormattedMessage
                              id="tab.sensorHistory"
                              defaultMessage="Sensor History"
                            />
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                    <Col xs={{ span: 4 }} lg={{ span: 4 }}>
                      <TraceabilityReportExportButton batch={batch} />
                    </Col>
                  </Row>
                  <Table
                    data={batchActions}
                    columns={columns}
                    isFilteringEnabled={false}
                    withDefaultPagination={false}
                    isUpdatedColumnShown={false}
                    initialSortedColumn="created"
                    resetDefaultSortingState
                  />
                </Card.Body>
              </div>
            </Card>
          </div>
          {
            isMaterialTestPanelFeatureEnabled &&
            (!_isEmpty(materialTestOperationLinkings)
              // We should also hide the panel if we have no Samples from API at all.
              && !_isEmpty(samples)) ? (
                <ManageTestPanels
                  filteredSamples={filteredSamples}
                  testPanels={materialTestPanels}
                  materialTestInstructions={materialTestInstructions}
                  materialTestInstructionsByUri={materialTestInstructionsByUri}
                  materialTestOperations={materialTestOperations}
                  materialTestUnitsByUri={materialTestUnitsByUri}
                  showMaterialAddTestPanelModal={() => setShowMaterialAddTestPanelModal(true)}
                  setTestPanelCurrentlyEdited={setTestPanelCurrentlyEdited}
                  manageTestPanelsState={manageTestPanelsState}
                  materialTestInstructionReports={materialTestInstructionReports}
                  materialTestInstructionReportsByUri={materialTestInstructionReportsByUri}
                  materialTestOperationLinkings={materialTestOperationLinkings}
                />
              ) : (
                <ManageTestActions batch={batch} />
              )
          }
        </Col>
      </Row>

      {batch.material_in_containers && (
        <ContainersEditableGrid
          panelHeading="Material Batch Containers"
          deleteContainersAllowed={false}
          addContainersAllowed={false}
          showAddedToBatchColumn={false}
          showReasonInput
          containers={containers}
          units={batch.units}
          onSaveContainers={onContainersSubmit}
          isSaving={isBatchActionSaving}
          isContainerEditable={
            container => container.status !== MATERIAL_CONTAINER_STATUSES.EMPTY
          }
          uuid={batch.uuid}
          route={ROUTES.PRINT_BATCH_CONTAINER_QR_CODE}
        />
      )}
      {
        !batch.material_in_containers
        && batch.status !== MATERIAL_BATCH_STATUSES.DONE
        && !batch.at_machine
        && (
          <BatchEditableQuantity
            batch={batch}
            onSaveQuantityChange={onQuantityChangeSubmit}
          />
        )
      }
    </Container>
  );
};

MaterialBatch.propTypes = {
  containers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  batch: materialBatchResourceType.isRequired,
  lot: PropTypes.shape({
    name: PropTypes.string,
    delivery_date: PropTypes.string,
    delivery_id: PropTypes.string,
    expiration_date: PropTypes.string,
    usage_cycles_limit: PropTypes.number,
    initial_batches: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  printer: printerResourceType,
  printerType: printerTypeResourceType,
  printerTypeMaterials: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })),
  printerLocation: PropTypes.shape({
    name: PropTypes.string,
  }),
  samples: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  material: materialTypeResourceType.isRequired,
  batchActions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  scrollToTraceabilityReport: PropTypes.bool,
  onSaveNotes: PropTypes.func.isRequired,
  onEditBatchQuantityAction: PropTypes.func.isRequired,
  isBatchActionSaving: PropTypes.bool.isRequired,
  isGeneralMFGLanguageEnabled: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
  }).isRequired,
  notes: PropTypes.string,
  onChangeNotes: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  materialTestPanels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestPanelsByUri: PropTypes.shape({}).isRequired,
  materialAddTestPanelModalState: PropTypes.shape({
    showMaterialAddTestPanelModal: PropTypes.bool,
    setShowMaterialAddTestPanelModal: PropTypes.func,
    testPanelCurrentlyEdited: PropTypes.bool,
    setTestPanelCurrentlyEdited: PropTypes.func,
  }).isRequired,
  materialTestInstructions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestInstructionsByUri: PropTypes.shape({}).isRequired,
  materialTestOperations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestUnitsByUri: PropTypes.shape({}).isRequired,
  manageTestPanelsState: PropTypes.shape({}).isRequired,
  materialTestInstructionReports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestOperationLinkings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestInstructionReportsByUri: PropTypes.shape({}).isRequired,
  isMaterialTestPanelFeatureEnabled: PropTypes.bool,
  materialTestUnits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onInitializeMaterialTest: PropTypes.func.isRequired,
  subLocation: subLocationResourceType.isRequired,
  usersByUri: PropTypes.objectOf(userResourceType).isRequired,
};

MaterialBatch.defaultProps = {
  scrollToTraceabilityReport: false,
  printer: null,
  printerType: {},
  printerTypeMaterials: [],
  printerLocation: {},
  notes: '',
  isMaterialTestPanelFeatureEnabled: false,
};

export default memo(MaterialBatch);
