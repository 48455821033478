import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';

import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import PostProcessorsComponent from 'rapidfab/components/inventory/postProcessors';
import { API_RESOURCES, FEATURES } from 'rapidfab/constants';
import { Form } from 'react-bootstrap';
import _isNull from 'lodash/isNull';
import PropTypes from 'prop-types';

const PostProcessors = props => {
  const locationsByUri = useSelector(Selectors.getLocationsByUri);
  const postProcessorTypes = useSelector(Selectors.getPostProcessorTypesByUri);
  const isGroupQualificationsFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.GROUP_QUALIFICATIONS));
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);
  const isAssemblyColumnShown = useSelector(
    state => Selectors.isFeatureEnabled(state, FEATURES.ANATOMICAL_MODEL_TO_PRODUCT),
  );

  const dispatch = useDispatch();
  const onInitialize = () => dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list());

  const onCheckboxChange = event => {
    const { checked } = event.target;
    const { onFilterUpdate, filters } = props;
    const { archived, ...changedFilters } = filters;

    if (checked) {
      changedFilters.archived = null;
    }
    onFilterUpdate(changedFilters);
  };

  const { archived } = props.filters;

  useEffect(() => {
    // eslint-disable-next-line no-return-await
    (async () => await onInitialize())();
  }, []);

  return (
    <PostProcessorsComponent
      {...props}
      locationsByUri={locationsByUri}
      postProcessorTypes={postProcessorTypes}
      isGroupQualificationsFeatureEnabled={isGroupQualificationsFeatureEnabled}
      isDebugModeEnabled={isDebugModeEnabled}
      isAssemblyColumnShown={isAssemblyColumnShown}
      extraFilters={[
        <>
          <Form.Check
            name="archived"
            checked={_isNull(archived)}
            onChange={onCheckboxChange}
            type="checkbox"
            label="Hide Archived"
          />
        </>,
      ]}
    />
  );
};

PostProcessors.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withRecordsListHandling(
  PostProcessors,
  Selectors.getNonServicePostProcessors,
  ['post-processor'],
);
