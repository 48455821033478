import _filter from 'lodash/filter';

const productionWorkflowsFilteredByBaseMaterial = (baseMaterial, workflows) => {
  if (!baseMaterial) {
    return [];
  }

  return _filter(
    workflows,
    // So need to check that material is supported for the Printing Process Step only
    workflowItem => workflowItem.materials.includes(baseMaterial),
  );
};

export default productionWorkflowsFilteredByBaseMaterial;
