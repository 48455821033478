import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import SmoothCollapse from 'react-smooth-collapse';
import { FormattedMessage } from 'rapidfab/i18n';
import { WORK_INSTRUCTION_REPORT_TYPE_MAP } from 'rapidfab/mappings';
import Fa from 'react-fontawesome';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import getShortUUID from 'rapidfab/utils/getShortUUID';

const ChecklistsForProcessSteps = ({
  processStep,
  index,
  workChecklistLinkings,
  workstationsByUri,
  isDebugModeEnabled,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const { workstation, name, uri } = processStep;
  const checklist = _find(
    workChecklistLinkings,
    ({ process_step }) => process_step === uri,
  );
  const emptyWorkInstruction = {
    work_instructions: [],
  };
  const { work_checklist: workChecklist = emptyWorkInstruction } = checklist || {};
  const workstationName = workstation
    ? _get(workstationsByUri[workstation], 'name', '')
    : 'Any Workstation';
  return (
    <div className="item-container" key={uri}>
      <div className="item-header">
        <div>
          <b className="wrap-text">
            {index + 1} {name}
            {isDebugModeEnabled && (
              <Badge className="spacer-left text-white" bg="info">
                {getShortUUID(uri)}
              </Badge>
            )}
          </b>
          {workChecklist.description
        && <div>{workChecklist.description}</div>}
        </div>
        <div className="item-header-right-content">
          <div className="item-header-right-content-labels">
            <b>{workstationName}</b>
            {workChecklist.additional_instruction_url
              && (
                <div>
                  <a
                    href={workChecklist.additional_instruction_url}
                  >
                    {workChecklist.additional_instruction_url}
                  </a>
                </div>
              )}
          </div>
          {workChecklist.work_instructions.length ? (
            <Fa
              className="ml15"
              name={collapsed ? 'chevron-down' : 'chevron-up'}
              onClick={() => setCollapsed(!collapsed)}
            />
          ) : null}
        </div>
      </div>
      <div>
        <SmoothCollapse expanded={!collapsed}>
          {_map(workChecklist.work_instructions, ((instructions, instructionIndex) => (
            <div key={instructions.uuid} className="item-work-instructions">
              <div>{index + 1}.{instructionIndex + 1} {instructions.description}</div>
              <div>
                <Badge className="badge-sm" bg="default">
                  <FormattedMessage
                    {...WORK_INSTRUCTION_REPORT_TYPE_MAP[instructions.report_type]}
                  />
                </Badge>
                {
                  !_isEmpty(instructions.threshold) && (
                    <Badge
                      variant="info"
                      className="badge-sm"
                      bg="info"
                      style={{ display: 'inline', marginTop: '2px', marginLeft: '5px' }}
                    >
                      <FormattedMessage id="threshold" defaultMessage="Threshold" />
                    </Badge>
                  )
                }
                {instructions.required &&
                  <Badge className="spacer-left badge-sm badge-danger" variant="danger">Required</Badge>}
              </div>
            </div>
          )))}
        </SmoothCollapse>
      </div>
    </div>
  );
};
ChecklistsForProcessSteps.propTypes = {
  processStep: PropTypes.shape({
    workstation: PropTypes.string,
    name: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  workChecklistLinkings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  workstationsByUri: PropTypes.shape({}).isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
};

export default ChecklistsForProcessSteps;
