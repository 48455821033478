import React from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
} from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import NavProfile from 'rapidfab/components/navbar/NavProfile';

const NavLinksRestricted = ({
  currentUser,
  onChangeLocale,
  onImpersonateStop,
  onLogout,
  locale,
  session,
}) => (
  <Navbar.Collapse>
    <Nav>
      <NavItem>
        <NavLink eventKey={1.1} href={getRouteURI(ROUTES.ORDERS)}>
          <Fa name="files-o" />{' '}
          <FormattedMessage id="plan.orders" defaultMessage="Orders" />
        </NavLink>
      </NavItem>
    </Nav>
    <NavProfile
      currentUser={currentUser}
      locale={locale}
      onChangeLocale={onChangeLocale}
      onImpersonateStop={onImpersonateStop}
      onLogout={onLogout}
      session={session}
    />
  </Navbar.Collapse>
);

NavLinksRestricted.propTypes = {
  currentUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  locale: PropTypes.string.isRequired,
  onChangeLocale: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onImpersonateStop: PropTypes.func.isRequired,
  session: PropTypes.shape({}).isRequired,
};

NavLinksRestricted.propTypes = {
  currentUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  locale: PropTypes.string.isRequired,
  onChangeLocale: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onImpersonateStop: PropTypes.func.isRequired,
  session: PropTypes.shape({}).isRequired,
};

export default NavLinksRestricted;
