import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FormLabel, FormGroup, FormControl } from 'react-bootstrap';
import Alert from 'rapidfab/utils/alert';
import { ALLOWED_DOCUMENT_EXTENSIONS, ALLOWED_MODEL_EXTENSIONS } from 'rapidfab/constants';

const FileInput = ({
  handleFileChange,
  name,
  required,
  fileType,
  buttonStyle,
  buttonClassName,
  onlyFileInput,
  acceptedExtensions,
  chooseFileLabel,
  disabled,
  style,
  controlLabelStyle,
}) => {
  if (required && !onlyFileInput) {
    // Regards required and fileInputKey.
    //  There is two options where FileInput is used:
    //  1. Input as form field. Not needed to reset <input> when new file will be selected.
    //  2. Input just to select a file. FileInput file value is controlled from outside.
    //     So, when file will is selected, callback will be called,
    //     and <input> will be reset because `fileInputKey` is changed.
    //     In `name` prop selected value will be passed, so user will see selected file name,
    //  If file considered to be required, then input shouldn't be reset because user will not be able to send
    //   this form due to browser validation. So that's why you have to pass both props.
    //  For (1) and (2) file is always stored outside of this component (see for handleFileChange() usage),
    //   but for (1) we should always guarantee that always selected file will be in <input>
    throw new Error('You MUST pass onlyFileInput props if you want to use FileInput as required input');
  }

  const [fileInputKey, setFileInputKey] = useState(() => Date.now());
  const getAcceptedExtensions = () => {
    if (acceptedExtensions) {
      // List of accepted extensions is already defined by props
      return acceptedExtensions;
    }

    if (fileType === FileInput.fileTypes.model) {
      return ALLOWED_MODEL_EXTENSIONS;
    }

    if (fileType === FileInput.fileTypes.document) {
      return ALLOWED_DOCUMENT_EXTENSIONS;
    }

    return [];
  };

  const validExtensions = getAcceptedExtensions();
  const acceptExtensions = `.${validExtensions.join(', .')}`;

  const checkFileType = event => {
    try {
      const file = event.target.files[0];
      const fileExtension = file.name.toLowerCase()
        .split('.')
        .pop();

      if (!validExtensions.includes(fileExtension)) {
        Alert.error(
          <FormattedMessage
            id="toaster.error.fileType.extensionMustBeOfType"
            defaultMessage="{fileType} must be one of extension: {acceptExtensions}"
            values={{ fileType, acceptExtensions }}
          />);
        return;
      }
    } catch {
      // if something goes wrong here, just accept it
    }

    handleFileChange(event);

    if (!onlyFileInput) {
      // Clear input in case if this component is not part of form
      // Then, input value will be changed to null,
      // but selected file will be stored outside and passed back with `name` props.
      setFileInputKey(Date.now());
    }
  };

  const fileFormControl = (
    <FormControl
      disabled={disabled}
      key={fileInputKey}
      id={`${fileType}Input`}
      name={fileType}
      type="file"
      accept={acceptExtensions}
      required={required}
      /* eslint-disable-next-line react/jsx-no-bind */
      onChange={checkFileType}
      style={onlyFileInput ?
        {}
        // Add styles to hide input when labels are rendered
        : {
          width: '100%',
          height: '100%',
          position: 'absolute',
          opacity: 0,
        }}
    />
  );

  if (onlyFileInput) {
    return fileFormControl;
  }

  return (
    <FormGroup style={{ ...style }} className="m-l-0 p-relative mt15">
      {fileFormControl}
      <FormLabel style={{ ...controlLabelStyle }} htmlFor="fileInput" className={`btn ${buttonStyle} ${buttonClassName} file-input`}>
        {chooseFileLabel || (
          <FormattedMessage
            id={`choose${fileType}File`}
            defaultMessage={`Choose ${fileType} File`}
          />
        )}
      </FormLabel>
      {name && (
        <div className="d-inline ml15 link">
          {name}
        </div>
      )}
    </FormGroup>
  );
};

FileInput.fileTypes = {
  document: 'Document',
  model: 'Model',
};

FileInput.defaultProps = {
  name: '',
  required: false,
  acceptedExtensions: null,
  fileType: FileInput.fileTypes.model,
  buttonStyle: 'btn-default',
  buttonClassName: null,
  onlyFileInput: false,
  chooseFileLabel: null,
  disabled: false,
  style: null,
  controlLabelStyle: null,
};

FileInput.propTypes = {
  handleFileChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  required: PropTypes.bool,
  fileType: PropTypes.oneOf(Object.values(FileInput.fileTypes)),
  buttonStyle: PropTypes.oneOf(['btn-primary', 'btn-default']),
  buttonClassName: PropTypes.string,
  onlyFileInput: PropTypes.bool,
  acceptedExtensions: PropTypes.arrayOf(PropTypes.string),
  chooseFileLabel: PropTypes.node,
  disabled: PropTypes.bool,
  style: PropTypes.shape({}),
  controlLabelStyle: PropTypes.shape({}),
};

export default FileInput;
