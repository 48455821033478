import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import PrepTaskModalContainer from 'rapidfab/containers/manage/PrepTaskModalContainer';
import getShortUUID from 'rapidfab/utils/getShortUUID';

import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import PropTypes from 'prop-types';

const PrepTasks = ({ data, fetching, onSort, ...componentProps }) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [currentPrepTaskUri, setCurrentPrepTaskUri] = useState(null);

  const closeModal = () => {
    setIsModalShown(false);
    setCurrentPrepTaskUri(null);
  };

  const showModal = prepTaskUri => {
    setIsModalShown(true);
    setCurrentPrepTaskUri(prepTaskUri);
  };

  const columns = [
    {
      type: 'custom',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      Cell: ({ row: { original } }) => (
        <Button
          className="p-a-0"
          variant="link"
          tabIndex={0}
          role="button"
          onClick={() => showModal(original.uri)}
        >
          {getShortUUID(original.uuid)}
        </Button>
      ),
    },
    {
      type: 'text',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
    },
    {
      type: 'text',
      uid: 'shortName',
      accessor: 'shortname',
      defaultMessage: 'Short Name',
    },
    {
      type: 'text',
      uid: 'estimatedTime.hours',
      accessor: 'user_estimated_work_time',
      defaultMessage: 'Estimated Time (hours)',
    },
    {
      type: 'text',
      uid: 'dwellTime.days',
      accessor: 'user_estimated_dwell_time',
      defaultMessage: 'Dwell Time (days)',
    },
  ];

  const NavbarLinks = (
    <Button
      variant="primary"
      size="sm"
      onClick={() => showModal(null)}
      className="pull-right"
    >
      <Fa name="plus" />{' '}
      <FormattedMessage id="button.add" defaultMessage="Add" />
    </Button>
  );

  return (
    <>
      <TableWithSearching
        {...componentProps}
        withBreadcrumbs
        breadcrumbs={['manage', 'Prep Tasks']}
        navbar={NavbarLinks}
        data={data}
        columns={columns}
        isFetching={fetching}
        isManualSoringEnabled
        isUpdatedColumnShown={false}
        manualSortingFunc={onSort}
        initialSortedDesc
        initialSortedColumn="name"
      />
      {isModalShown && (
        <PrepTaskModalContainer
          prepTaskUri={currentPrepTaskUri}
          onClose={closeModal}
        />
      )}
    </>
  );
};

PrepTasks.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
  onSort: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default PrepTasks;
