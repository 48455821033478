import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { Form, FormControl, Row, Col, Button, Image } from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';
import { ROUTES } from 'rapidfab/constants';
import { Link } from 'react-router-dom';
import hawkingLogo from 'rapidfab/images/xerox-logo-red.png';

const HawkingLoginForm = ({ onEmailSubmit, loading, withPassword, ssoURI, onSubmit }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const passwordInputRef = useRef(null);

  const isLoginDisabled = (loading || !email || (withPassword && !password));

  useEffect(() => {
    // Set focus to password input when user sees
    // password input for the first time
    if (withPassword) {
      passwordInputRef.current.focus();
    }
  }, [withPassword]);

  const toSsoLocation = () => {
    if (!ssoURI) {
      return;
    }

    window.location = ssoURI;
  };

  const onLogin = event => {
    event.preventDefault();

    if (!email) {
      return;
    }

    if (!withPassword) {
      // Check for SSO staff
      onEmailSubmit(email);
      return;
    }

    if (!password) {
      return;
    }

    onSubmit(email, password);
  };

  const LoginButtonText = () => {
    if (loading) {
      return <Loading />;
    }
    if (withPassword) {
      return 'Login';
    }
    return 'Next';
  };

  return (
    <div className="vh-100 login-form-container h-100 w-full o-h">
      <Row>
        <Col
          md={3}
          className="login-form d-flex flex-column align-items-center justify-content-center position-absolute h-100 p15"
        >
          <div className="d-flex flex-column justify-content-center w-50 h-100">
            <Image className="mb15" src={hawkingLogo} width={150} />
            <h2 className="my-2"><b>ShipLine Digital Warehouse</b></h2>
            {/* horizontal prop */}
            <Form onSubmit={onLogin}>
              <FormControl
                name="email"
                type="text"
                autoComplete="username"
                value={email}
                placeholder="Email"
                onChange={({ target }) => setEmail(target.value)}
                className="hawking-username"
              />

              {
                withPassword && (
                  <>
                    <FormControl
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      value={password}
                      placeholder="Password"
                      onChange={({ target }) => setPassword(target.value)}
                      ref={passwordInputRef}
                      className="hawking-password"
                    />

                  </>
                )
              }

              <Row style={{ justifyContent: 'center' }}>
                {/* SSO Disabled: [/4/, 4 (LOGIN), /4/]
                SSO Enabled:  [/2/, 4 (LOGIN), 4 (SSO), /2/] */}
                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                  <Button
                    block
                    size="lg"
                    type="submit"
                    className="dark-gray-btn mt15"
                    disabled={isLoginDisabled}
                  >
                    <LoginButtonText />
                  </Button>
                </Col>
                {ssoURI && (
                  <Col xs={6} md={4}>
                    <Button
                      block
                      size="lg"
                      onClick={() => toSsoLocation()}
                      variant="info"
                    >
                      Login via SSO
                    </Button>
                  </Col>
                )}
                {withPassword && (
                  <div className="text-right d-flex justify-content-center p-t">
                    <Link
                      to={ROUTES.FORGOT_PASSWORD_XEROX}
                      className="hawking-forgot-password"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                )}
              </Row>
            </Form>
          </div>
          <div className="mb15">
            <p>&copy; {new Date().getFullYear()} Xerox Corporation</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

HawkingLoginForm.propTypes = {
  onEmailSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  withPassword: PropTypes.bool.isRequired,
  ssoURI: PropTypes.string,
};

HawkingLoginForm.defaultProps = {
  ssoURI: null,
};

export default HawkingLoginForm;
