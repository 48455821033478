import {
  LINE_ITEM_STATUS,
  PREP_TASK_RECORD_STATUSES,
  RUN_STATUSES,
  MATERIAL_LOT_STATUSES,
} from 'rapidfab/constants';

export const LINE_ITEM_STATUS_TRANSFORMATIONS = {
  [LINE_ITEM_STATUS.NEW]: [
    LINE_ITEM_STATUS.NEW,
  ],
  [LINE_ITEM_STATUS.CALCULATING_ESTIMATES]: [
    LINE_ITEM_STATUS.CALCULATING_ESTIMATES,
  ],
  [LINE_ITEM_STATUS.PENDING]: [
    LINE_ITEM_STATUS.PENDING,
    LINE_ITEM_STATUS.CANCELLED,
    LINE_ITEM_STATUS.CONFIRMED,
    LINE_ITEM_STATUS.COMPLETE,
  ],
  [LINE_ITEM_STATUS.IN_PREPARATION]: [
    LINE_ITEM_STATUS.IN_PREPARATION,
    LINE_ITEM_STATUS.CANCELLED,
  ],
  [LINE_ITEM_STATUS.CONFIRMED]: [
    LINE_ITEM_STATUS.CONFIRMED,
    LINE_ITEM_STATUS.CANCELLED,
    LINE_ITEM_STATUS.COMPLETE,
  ],
  [LINE_ITEM_STATUS.PRINTING]: [
    LINE_ITEM_STATUS.PRINTING,
    LINE_ITEM_STATUS.CANCELLED,
  ],
  [LINE_ITEM_STATUS.CANCELLED]: [
    LINE_ITEM_STATUS.CANCELLED,
  ],
  [LINE_ITEM_STATUS.COMPLETE]: [
    LINE_ITEM_STATUS.COMPLETE,
    LINE_ITEM_STATUS.CANCELLED,
  ],
  [LINE_ITEM_STATUS.POST_PROCESSING]: [
    LINE_ITEM_STATUS.POST_PROCESSING,
  ],
  [LINE_ITEM_STATUS.SHIPPING]: [
    LINE_ITEM_STATUS.SHIPPING,
    LINE_ITEM_STATUS.CANCELLED,
    LINE_ITEM_STATUS.COMPLETE,
  ],
  [LINE_ITEM_STATUS.ERROR]: [
    LINE_ITEM_STATUS.ERROR,
  ],
};

export const RUN_STATUS_TRANSFORMATIONS = {
  [RUN_STATUSES.CALCULATING]: [
    RUN_STATUSES.CALCULATING,
    // It's backend operation to update status,
    // we don't need to allow any other transformations here
  ],
  [RUN_STATUSES.CALCULATED]: [
    RUN_STATUSES.CALCULATED,
    RUN_STATUSES.QUEUED,
    RUN_STATUSES.IN_PROGRESS,
    RUN_STATUSES.ERROR,
  ],
  [RUN_STATUSES.QUEUED]: [
    RUN_STATUSES.QUEUED,
    RUN_STATUSES.CALCULATED,
    RUN_STATUSES.IN_PROGRESS,
    RUN_STATUSES.ERROR,
  ],
  [RUN_STATUSES.IN_PROGRESS]: [
    RUN_STATUSES.IN_PROGRESS,
    RUN_STATUSES.COMPLETE,
    RUN_STATUSES.ERROR,
  ],
  [RUN_STATUSES.COMPLETE]: [
    RUN_STATUSES.COMPLETE,
    RUN_STATUSES.ERROR,
  ],
  [RUN_STATUSES.ERROR]: [
    RUN_STATUSES.ERROR,
    RUN_STATUSES.COMPLETE,
    RUN_STATUSES.IN_PROGRESS,
  ],
  [RUN_STATUSES.CANCELLED]: [
    // When Run is cancelled - no way out
    RUN_STATUSES.CANCELLED,
  ],
  // Paused status is hidden under a feature,
  // but in the case where Run is Paused, it means the feature is enabled
  [RUN_STATUSES.PAUSED]: [
    RUN_STATUSES.PAUSED,
    // When Run is paused - it needs to be moved to in progress before further changes
    RUN_STATUSES.IN_PROGRESS,
  ],
};

export const PREP_TASK_RECORD_STATUS_TRANSFORMATIONS = {
  [PREP_TASK_RECORD_STATUSES.NEW]: [
    // New status can be changed to Queued on the backend only for now
    PREP_TASK_RECORD_STATUSES.NEW,
  ],
  [PREP_TASK_RECORD_STATUSES.QUEUED]: [
    PREP_TASK_RECORD_STATUSES.QUEUED,
    PREP_TASK_RECORD_STATUSES.IN_PROGRESS,
    PREP_TASK_RECORD_STATUSES.SKIPPED,
  ],
  [PREP_TASK_RECORD_STATUSES.IN_PROGRESS]: [
    PREP_TASK_RECORD_STATUSES.IN_PROGRESS,
    // On Hold is an optional status
    PREP_TASK_RECORD_STATUSES.ON_HOLD,
    PREP_TASK_RECORD_STATUSES.COMPLETE,
    PREP_TASK_RECORD_STATUSES.SKIPPED,
  ],
  [PREP_TASK_RECORD_STATUSES.ON_HOLD]: [
    PREP_TASK_RECORD_STATUSES.ON_HOLD,
    PREP_TASK_RECORD_STATUSES.IN_PROGRESS,
  ],
  [PREP_TASK_RECORD_STATUSES.COMPLETE]: [
    PREP_TASK_RECORD_STATUSES.COMPLETE,
  ],
  [PREP_TASK_RECORD_STATUSES.SKIPPED]: [
    PREP_TASK_RECORD_STATUSES.SKIPPED,
    PREP_TASK_RECORD_STATUSES.COMPLETE,
  ],
  [PREP_TASK_RECORD_STATUSES.CANCELLED]: [
    PREP_TASK_RECORD_STATUSES.CANCELLED,
  ],
};

export const MATERIAL_LOT_STATUS_TRANSFORMATIONS = {
  [MATERIAL_LOT_STATUSES.ON_ORDER]: [
    MATERIAL_LOT_STATUSES.ON_ORDER,
    MATERIAL_LOT_STATUSES.RECEIVED,
  ],
  [MATERIAL_LOT_STATUSES.RECEIVED]: [
    MATERIAL_LOT_STATUSES.RECEIVED,
    MATERIAL_LOT_STATUSES.IN_TESTING,
    MATERIAL_LOT_STATUSES.RETURN_TO_SUPPLIER,
  ],
  [MATERIAL_LOT_STATUSES.IN_TESTING]: [
    MATERIAL_LOT_STATUSES.IN_TESTING,
    MATERIAL_LOT_STATUSES.AVAILABLE,
    MATERIAL_LOT_STATUSES.RETURN_TO_SUPPLIER,
  ],
  [MATERIAL_LOT_STATUSES.AVAILABLE]: [
    MATERIAL_LOT_STATUSES.AVAILABLE,
    MATERIAL_LOT_STATUSES.IN_USE,
    MATERIAL_LOT_STATUSES.SCRAPPED,
  ],
  [MATERIAL_LOT_STATUSES.IN_USE]: [
    MATERIAL_LOT_STATUSES.IN_USE,
    MATERIAL_LOT_STATUSES.SCRAPPED,
    MATERIAL_LOT_STATUSES.DONE,
  ],
  [MATERIAL_LOT_STATUSES.DONE]: [
    MATERIAL_LOT_STATUSES.DONE,
  ],
  [MATERIAL_LOT_STATUSES.SCRAPPED]: [
    MATERIAL_LOT_STATUSES.SCRAPPED,
  ],
  [MATERIAL_LOT_STATUSES.RETURN_TO_SUPPLIER]: [
    MATERIAL_LOT_STATUSES.RETURN_TO_SUPPLIER,
  ],
  [MATERIAL_LOT_STATUSES.IN_STORAGE]: [
    MATERIAL_LOT_STATUSES.IN_STORAGE,
    MATERIAL_LOT_STATUSES.IN_TESTING,
    MATERIAL_LOT_STATUSES.SCRAPPED,
  ],
};
