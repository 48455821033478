import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Actions from 'rapidfab/actions';
import LabelModal from 'rapidfab/components/admin/LabelModal';
import { API_RESOURCES, DEFAULT_LABEL_COLOR, FEATURES } from 'rapidfab/constants';
import { getLabelsByUri, isFeatureEnabled } from 'rapidfab/selectors';
import extractUuid from 'rapidfab/utils/extractUuid';
import PropTypes from 'prop-types';

import { LABEL_MODAL_CONTAINER } from 'rapidfab/constants/forms';

const LabelModalContainer = props => {
  const { uri } = props;
  const labelsByUri = useSelector(getLabelsByUri);
  const label = labelsByUri[uri];
  const initialValues = { color: DEFAULT_LABEL_COLOR, ...label };
  const isHawkingUser = useSelector(state => isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT));

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => LABEL_MODAL_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  const selected = {
    initialFormValues,
    isHawkingUser,
  };

  const dispatch = useDispatch();
  const onCreateLabel = payload => dispatch(Actions.Api.nautilus[API_RESOURCES.LABEL].post(payload));

  // eslint-disable-next-line no-shadow
  const onUpdateLabel = (uri, payload) => dispatch(Actions.Api.nautilus[API_RESOURCES.LABEL]
    .put(extractUuid(uri), payload));

  return (
    <LabelModal
      {...props}
      {...selected}
      onCreateLabel={onCreateLabel}
      onUpdateLabel={onUpdateLabel}
    />
  );
};

LabelModalContainer.propTypes = {
  uri: PropTypes.string.isRequired,
};

export default LabelModalContainer;
