import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Table, Form, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import Loading from 'rapidfab/components/Loading';
import createOrReplaceArray from 'rapidfab/utils/createOrReplaceArray';
import isArrayEqual from 'rapidfab/utils/isArrayEqual';
import { FormControlTextAreaWithCustomHandlers } from 'rapidfab/components/formTools';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';

class LineItemQuoteEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [],
      notes: '',
      totalsByStep: {},
    };

    this.checkboxes = ['in_quote', 'in_price', 'separate'];

    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setInitialCheckboxValues = this.setInitialCheckboxValues.bind(this);
  }

  componentDidMount() {
    this.setInitialCheckboxValues();
  }

  componentDidUpdate(prevProps) {
    const { processSteps, publicNotes, totalsByProcessStep } = this.props;

    const updatedFields = {};

    if (processSteps && !isArrayEqual(processSteps, prevProps.processSteps)) {
      // eslint-disable-next-line react/no-did-update-set-state
      updatedFields.steps = _cloneDeep(processSteps);
    }

    if (publicNotes !== prevProps.publicNotes) {
      updatedFields.notes = publicNotes;
    }

    if (totalsByProcessStep && !_isEqual(totalsByProcessStep, prevProps.totalsByProcessStep)) {
      updatedFields.totalsByStep = totalsByProcessStep;
    }

    if (Object.keys(updatedFields).length > 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(updatedFields);
    }
  }

  onCheckboxChange(event, processStepUri) {
    const { checked, name } = event.target;

    const { steps } = this.state;

    const processStep = _find(steps, { uri: processStepUri });

    const workStepQuoteDetails = { ...processStep.work_steps_quote_details };
    workStepQuoteDetails[name] = checked;
    if (name === 'in_quote') {
      // In Quote changes need to be synchronized with In Price (not vice-verse)
      workStepQuoteDetails.in_price = checked;
      if (!checked) {
        // Disable Separate anytime step is removed from quote
        workStepQuoteDetails.separate = checked;
      }
    }
    if (
      checked
      && ['in_price', 'separate'].includes(name)
      && !workStepQuoteDetails.in_quote
    ) {
      // When In Price or Separate option enabled - Enable In Quote too
      workStepQuoteDetails.in_quote = true;
    }

    const updatedSteps = createOrReplaceArray(
      steps,
      { uri: processStepUri },
      { work_steps_quote_details: workStepQuoteDetails },
    );

    this.setState({ steps: updatedSteps });
  }

  onInputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  onClose() {
    if (this.props.savingLineItemQuote) {
      // Prevent closing modal during save process
      return;
    }
    this.setInitialCheckboxValues();
    this.props.close();
  }

  async onSubmit(event) {
    event.preventDefault();
    const { saveQuoteDetails } = this.props;
    const quoteDetails = _map(this.state.steps, 'work_steps_quote_details');
    await saveQuoteDetails(quoteDetails, this.state.notes);
    this.props.visibilityAffectPrice(quoteDetails);
    this.props.close();
  }

  setInitialCheckboxValues() {
    const { processSteps, publicNotes } = this.props;

    const initialFields = {
      steps: [],
      notes: publicNotes,
    };

    if (processSteps) {
      initialFields.steps = _cloneDeep(processSteps);
    }

    this.setState(initialFields);
  }

  render() {
    const { show, savingLineItemQuote } = this.props;

    const { steps, notes, totalsByStep } = this.state;

    return (
      <Modal show={show} onHide={this.onClose} backdrop="static">
        <Form onSubmit={this.onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage
                id="line_item.quote_visibility_settings"
                defaultMessage="Line Item Quote Visibility Settings"
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Table>
                <thead className="line_item_quote_visibility_settings">
                  <tr>
                    <th scope="col">
                      <FormattedMessage
                        id="line_item.process_step"
                        defaultMessage="Process Step"
                      />
                    </th>
                    <th scope="col">
                      <FormattedMessage
                        id="line_item.process_step.in_quote"
                        defaultMessage="In Quote"
                      />
                    </th>
                    <th scope="col">
                      <FormattedMessage
                        id="line_item.process_step.in_price"
                        defaultMessage="In Price"
                      />
                    </th>
                    <th scope="col">
                      <FormattedMessage
                        id="line_item.process_step.separate"
                        defaultMessage="Separate"
                      />
                    </th>
                    <th scope="col">
                      <FormattedMessage
                        id="line_item_quote.total"
                        defaultMessage="Total"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody className="line_item_quote_visibility_settings">
                  {steps.map(processStep => (
                    <tr key={processStep.uri}>
                      <td>
                        {processStep.name}
                      </td>
                      {this.checkboxes.map(checkbox => (
                        <td key={checkbox}>
                          <input
                            checked={processStep.work_steps_quote_details[checkbox]}
                            onChange={event => this.onCheckboxChange(event, processStep.uri)}
                            type="checkbox"
                            name={checkbox}
                          />
                        </td>
                      ))}
                      <td key={processStep.uuid}>
                        {
                          !totalsByStep[processStep.uuid]
                            ? (
                              <FormattedLocalizedCost
                                value={this.props.totalPrice(processStep)}
                              />
                            )
                            : '-'
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div>
              <div>
                <b>
                  <FormattedMessage
                    id="field.notes"
                    defaultMessage="Notes"
                  />:
                </b>
              </div>
              <div>
                <FormControlTextAreaWithCustomHandlers
                  name="notes"
                  value={notes}
                  onChange={this.onInputChange}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onClose}>
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="success" type="submit" disabled={savingLineItemQuote}>
              {savingLineItemQuote && <Loading inline />}
              <FormattedMessage id="button.save" defaultMessage="Save" />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

LineItemQuoteEditModal.propTypes = {
  close: PropTypes.func.isRequired,
  processSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  show: PropTypes.bool.isRequired,
  saveQuoteDetails: PropTypes.func.isRequired,
  savingLineItemQuote: PropTypes.bool.isRequired,
  publicNotes: PropTypes.string.isRequired,
  totalsByProcessStep: PropTypes.shape({}).isRequired,
  totalPrice: PropTypes.func.isRequired,
  visibilityAffectPrice: PropTypes.func.isRequired,
};

export default LineItemQuoteEditModal;
