import React, { useEffect, useState, useMemo } from 'react';
import BuildsLibraries from 'rapidfab/components/manage/BuildsLibraries';
import * as Selectors from 'rapidfab/selectors';
import { useSelector, useDispatch } from 'react-redux';
import {
  API_RESOURCES,
  ROUTES,
} from 'rapidfab/constants';
import { loadBuildLibraries } from 'rapidfab/dispatchers/buildsLibrary';
import Actions from 'rapidfab/actions';
import Alert from 'rapidfab/utils/alert';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import extractUuid from 'rapidfab/utils/extractUuid';
import _pick from 'lodash/pick';
import _values from 'lodash/values';
import { getIsDebugModeEnabled } from 'rapidfab/selectors';
import { FormattedMessage } from 'react-intl';

const BuildsLibrariesContainer = () => {
  const dispatch = useDispatch();

  const buildsLibraries = useSelector(Selectors.getBuildsLibraries);
  const piecesByUri = useSelector(Selectors.getPiecesByUri);
  const buildFilesByUri = useSelector(Selectors.getBuildFilesByUri);
  const isDebugModeEnabled = useSelector(getIsDebugModeEnabled);

  const selected = {
    buildsLibraries,
    piecesByUri,
    buildFilesByUri,
    isDebugModeEnabled,
  };

  const [search, setSearch] = useState('');
  const [confirmationModalState,
    setConfirmationModalState] =
    useState({
      show: false,
      buildLibrary: null,
    });
  const [paginationState, setPaginationState] = useState({
    pageLimitValues: [15, 30, 60, 90],
    pageLimit: 30,
    offset: 0,
    activePage: 0,
    totalModelLibraries: 0,
  });

  const totalPaginatedPages = Math.ceil(paginationState.totalModelLibraries / paginationState.pageLimit);

  const fetchData = () => {
    loadBuildLibraries(
      dispatch,
      null,
      paginationState.pageLimit,
      paginationState.offset > 0 ? paginationState.offset : 0,
      buildsLibrariesData => setPaginationState({
        ...paginationState,
        totalModelLibraries: buildsLibrariesData.meta.count,
        activePage: paginationState.activePage >= totalPaginatedPages && paginationState.activePage !== 0 ?
          totalPaginatedPages - 1 :
          paginationState.activePage,
      }),
    );
  };

  useEffect(() => {
    fetchData();
  },
  [paginationState.pageLimit,
    paginationState.offset,
    paginationState.activePage,
  ]);

  /* This feature will be added in a future sprint, omitting
  for v1.0. 👇🏼
  const [gridState, setGridState] = useState({
    editing: false,
    id: null,
    newTitle: null,
    buildLibraryUuid: null,
  });
  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);
  */

  const handleDeleteBuildLibrary = buildLibraryUuid => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE_LIBRARY].delete(buildLibraryUuid))
      .catch(error => Alert.error(error))
      .then(() => Alert.success(
        <FormattedMessage
          id="toaster.buildLibrary.deleted"
          defaultMessage="Deleted {buildLibraryUuid}."
          values={{ buildLibraryUuid }}
        />,
      ));
  };

  const handleSelectBuildLibrary = buildLibrary => {
    setConfirmationModalState({
      ...confirmationModalState,
      show: true,
      buildLibrary,
    });
  };

  const isFetchingBuildLibraries = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.BUILD_FILE_LIBRARY].list.fetching);

  const isSavingScheduleBuildFileLibrary = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.SCHEDULE_BUILD_FILE_LIBRARY].post.fetching);

  /* This feature will be added in a future sprint, omitting
  for v1.0. 👇🏼 */
  /* const handleRenameBuildLibrary = (newBuildLibraryName, buildLibrary) => {
    if (buildLibrary.name !== newBuildLibraryName &&
      newBuildLibraryName !== null) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD_FILE_LIBRARY].put(buildLibrary.uuid, {
        name: newBuildLibraryName,
      }))
        .catch(error => Alert.error(error))
        .finally(Alert.success('Changes saved.'));
    }
  }; */

  const filteredBuildsLibraries = useMemo(() => {
    if (!search || search.length === 0) {
      return buildsLibraries;
    }
    return buildsLibraries.filter(({ name }) => name.toLowerCase()
      .includes(search.toLowerCase()));
  }, [search]);

  const { pieces: buildLibraryPieceUris } = confirmationModalState.buildLibrary ?? [];
  const pieces = _values(_pick(piecesByUri, buildLibraryPieceUris));

  const handleAddCertifiedBuildIntoProduction = buildLibrary => {
    const payload = {
      build_file_library: buildLibrary.uri,
    };

    dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_BUILD_FILE_LIBRARY].post(payload))
      .catch(error => Alert.error(error))
      .then(response => {
        if (!response?.headers?.location) {
          Alert.error(
            <FormattedMessage
              id="toaster.error.order.creation"
              defaultMessage="Error in creation of order. Please try again."
            />,
          );
          return;
        }
        setConfirmationModalState({ show: false, buildLibrary: null });
        window.location.hash = getRouteURI(ROUTES.ORDER_EDIT, { uuid: extractUuid(response.headers.location) });
      })
      .finally(() => Alert.success(
        <FormattedMessage
          id="toaster.buildsLibrary.scheduledCertifiedBuild"
          defaultMessage="Successfully scheduled certified build."
        />));
  };

  return (
    <BuildsLibraries
      {...selected}
      isFetching={isFetchingBuildLibraries}
      buildsLibraries={!search ? buildsLibraries : filteredBuildsLibraries}
      handleDeleteBuildLibrary={handleDeleteBuildLibrary}
      search={search}
      handleBuildLibrariesSearch={search => setSearch(search)}
      handleSelect={handleSelectBuildLibrary}
      confirmationModal={{ confirmationModalState, setConfirmationModalState }}
      handleAddCertifiedBuildIntoProduction={handleAddCertifiedBuildIntoProduction}
      pagination={{ ...paginationState, setPaginationState, totalPaginatedPages }}
      isSavingScheduleBuildFileLibrary={isSavingScheduleBuildFileLibrary}
      pieces={pieces}
    />
  );
};

export default BuildsLibrariesContainer;
