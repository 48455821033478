import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';

import SelectPrinterDropdown from './SelectPrinterDropdown';

const ActivePiecesHeader = ({
  onDeactivate,
  printers,
  onPrinterSelect,
  selectedPrinter,
  selectedItemCount,
}) => (
  <Row className="panel_heading">
    <Col xs={6}>
      <span>Build Plate</span>
      <ButtonToolbar className="pull-right">
        <Button size="sm" variant="info" onClick={onDeactivate}>
          <FormattedMessage
            id="record.run.removePieces"
            defaultMessage="{count, plural, =0 {Remove All Pieces} one {Remove # Piece} other {Remove # Pieces}}"
            values={{ count: selectedItemCount }}
          />
        </Button>
      </ButtonToolbar>
    </Col>
    {printers !== null && (
      <Col xs={6}>
        <SelectPrinterDropdown
          className="buildPlateSelect"
          title="Select a Printer"
          data={printers.sort((a, b) => a.name.trim().localeCompare(b.name.trim()))}
          labelKey="name"
          valueKey="uri"
          multiple={false}
          selectedData={selectedPrinter}
          handleOnClose={onPrinterSelect}
        />
      </Col>
    )}
  </Row>
);

ActivePiecesHeader.defaultProps = {
  printers: null,
  onPrinterSelect: () => true,
  selectedPrinter: null,
};

ActivePiecesHeader.propTypes = {
  onDeactivate: PropTypes.func.isRequired,
  printers: PropTypes.arrayOf(PropTypes.shape({})),
  onPrinterSelect: PropTypes.func,
  selectedPrinter: PropTypes.oneOfType([
    PropTypes.shape,
    PropTypes.shape({}),
  ]),
  selectedItemCount: PropTypes.number.isRequired,
};

export default ActivePiecesHeader;
