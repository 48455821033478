import _assign from 'lodash/assign';
import _find from 'lodash/find';
import Constants from 'rapidfab/constants';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';

export const initialState = {
  uploadLocation: null,
  uploading: false,
  percent: 0,
  orderPayload: null,
  errors: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case Constants.RESOURCE_POST_SUCCESS:
      if (action.api.resource === 'model') {
        return { ...state, modelUuid: extractUuid(action.headers.location) };
      }
      return state;
    case Constants.UPLOAD_MODEL_REQUEST:
      return { ...state,
        uploadLocation: action.uploadUrl,
        uploading: true,
        percent: 0 };
    case Constants.UPLOAD_MODEL_PROGRESS:
      return { ...state,
        uploading: true,
        percent: action.percent };
    case Constants.UPLOAD_MODEL_SUCCESS:
      return { ...state, uploading: false };
    case Constants.UPLOAD_MODEL_STORE_PAYLOAD:
      return { ...state, orderPayload: action.payload };
    case Constants.UPLOAD_MODEL_CLEAR:
      return initialState;
    case Constants.UPLOAD_MODEL_ADD_ERROR: {
      const errors = _assign([], state.errors);

      action.errors.forEach(error => {
        if (!_find(errors, error)) {
          errors.push(error);
        }
      });
      return _assign({}, state, {
        errors,
      });
    }
    default:
      return state;
  }
}

export default reducer;
