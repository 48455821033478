import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Runs from 'rapidfab/components/plan/runs';
import _mapValues from 'lodash/mapValues';
import { FormControl, FormLabel } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import { RUN_OPERATION_MAP, RUN_STATUS_MAP } from 'rapidfab/mappings';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES } from 'rapidfab/constants';
import SelectMultiple from 'rapidfab/components/forms/SelectMultiple';
import withRecordsListHandling from '../hocs/withRecordsListHandling';
import 'rapidfab/containers/plan/runs.scss';

const FilterItem = ({ label, name, value, onChange, children }) => (
  <div className="form-group mr15">
    <FormLabel>
      {label}:
    </FormLabel>
    <FormControl
      className="spacer-left truncate-data"
      as="select"
      id={name}
      name={name}
      onChange={onChange}
      type="select"
      value={value}
    >
      <FormattedMessage id="all" defaultMessage="All">{text =>
        <option value="">{text}</option>}
      </FormattedMessage>
      {children}
    </FormControl>
  </div>
);

FilterItem.defaultProps = { value: '' };

FilterItem.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const WORKSTATION_FILTERS_SETTINGS = {
  workstation_type: {
    printer_type: 'printer-type',
    post_processor_type: 'post-processor-type',
    shipping: 'shipping',
  },
  workstation_name: {
    printer: 'printer',
    post_processor: 'post-processor',
  },
};

const RunsContainer = componentProps => {
  const {
    operation,
    printer_type: printerType,
    post_processor_type: postProcessorType,
    shipping,
    printer,
    post_processor: postProcessor,
  } = componentProps.filters;

  const workstationType = printerType || postProcessorType || shipping;
  const workstation = printer || postProcessor;

  const shippings = useSelector(Selectors.getShippings);
  const printerTypes = useSelector(Selectors.getPrinterTypes);
  const postProcessorTypes = useSelector(Selectors.getPostProcessorTypes);
  const workstationTypes = [
    ...shippings,
    ...printerTypes,
    ...postProcessorTypes,
  ];

  const printers = useSelector(Selectors.getPrinters);
  const postProcessors = useSelector(Selectors.getPostProcessors);

  const workstations = [
    ...printers,
    ...postProcessors,
  ];

  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);

  const dispatch = useDispatch();
  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list());
  };

  useEffect(() => onInitialize(), []);

  const onChange = event => {
    const { value } = event.target;
    let { name } = event.target;
    const { onFilterUpdate, filters } = componentProps;

    const changedFilters = { ...filters };

    const workstationFilterSettings = WORKSTATION_FILTERS_SETTINGS[name];
    if (workstationFilterSettings) {
      Object.keys(workstationFilterSettings).forEach(filterName => {
        delete changedFilters[filterName];
        const resourceName = workstationFilterSettings[filterName];
        if (value.includes(resourceName)) {
          name = filterName;
        }
      });
    }

    delete changedFilters[name];
    if (value) {
      changedFilters[name] = value;
    }

    onFilterUpdate(changedFilters);
  };

  const onStatusChange = selectedStatuses => {
    const { onFilterUpdate, filters } = componentProps;
    const changedFilters = { ...filters };

    if (selectedStatuses.length) {
      changedFilters.status = selectedStatuses.map(statusItem => statusItem.status);
    }
    onFilterUpdate(changedFilters);
  };

  // Prepare options list based on run statuses for SelectMultiple
  const availableStatuses = _mapValues(RUN_STATUS_MAP, (statusOption, status) => ({
    label: statusOption.defaultMessage,
    status,
  }));

  const { status: statuses = [] } = componentProps.filters;

  // Converting array of string to options list for SelectMultiple to work properly
  const selectedStatuses = statuses.map(status => availableStatuses[status]);

  return (
    <Runs
      {...componentProps}
      isDebugModeEnabled={isDebugModeEnabled}
      extraFilters={(
        <div className="form-inline" style={{ lineHeight: '40px' }}>
          <div className="form-group mr15">
            <FormLabel htmlFor="statusFilter">
              Status:
            </FormLabel>
            <div className="spacer-left form-control inline-picky-wrapper">
              <SelectMultiple
                title="Status"
                className="run-status__selector"
                data={Object.values(availableStatuses)}
                labelKey="label"
                valueKey="status"
                selectedData={selectedStatuses}
                handleOnClose={onStatusChange}
              />
            </div>
          </div>

          <FilterItem
            name="operation"
            value={operation}
            onChange={onChange}
            label={(<FormattedMessage id="field.operation" defaultMessage="Operation" />)}
          >
            {Object.keys(RUN_OPERATION_MAP).map(operationKey => (
              <FormattedMessage
                key={operationKey}
                {...RUN_OPERATION_MAP[operationKey]}
              >
                {text =>
                  <option key={operationKey} value={operationKey}>{text}</option>}
              </FormattedMessage>
            ))}
          </FilterItem>
          <FilterItem
            name="workstation_type"
            value={workstationType}
            onChange={onChange}
            label={(<FormattedMessage id="workstation.type" defaultMessage="Workstation Type" />)}
          >
            {workstationTypes.map(workstationTypeItem => (
              <option
                key={workstationTypeItem.uri}
                value={workstationTypeItem.uri}
              >
                {workstationTypeItem.name}
              </option>
            ))}
          </FilterItem>
          <FilterItem
            name="workstation_name"
            value={workstation}
            onChange={onChange}
            label={(<FormattedMessage id="workstation.name" defaultMessage="Workstation Name" />)}
          >
            {workstations.map(workstationItem => (
              <option
                key={workstationItem.uri}
                value={workstationItem.uri}
              >
                {workstationItem.name}
              </option>
            ))}
          </FilterItem>
        </div>
      )}
    />
  );
};

export default withRecordsListHandling(
  RunsContainer,
  Selectors.getRuns,
  ['run'],
);
