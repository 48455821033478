import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  FormGroup,
  FormControl,
  Button,
  Modal, Row, Col, Form as BSForm, FormLabel,
} from 'react-bootstrap';
import { reduxFormFieldType } from 'rapidfab/types';
import Alert from 'rapidfab/utils/alert';
import '../../styles/hawking/main.scss';
import { Field, Form } from 'react-final-form';
import _isNil from 'lodash/isNil';
import SaveButton from '../SaveButton';
import SelectSingle from '../forms/SelectSingle';
import { CUSTOM_FIELD_RELATED_TABLE_NAME, CUSTOM_FIELD_TYPES } from '../../constants';

const CustomFieldAddModal = ({
  initialValues,
  onClose,
  onCreateCustomField,
  isHawkingUser,
  customFieldsByUri,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const isValid = payload => {
    const customField = Object.values(customFieldsByUri)
      .find(item => (payload.field_id === item.field_id) && (payload.related_table_name === item.related_table_name));
    if (customField) {
      const message = `Custom field with field_id "${payload.field_id}" for the table "${payload.related_table_name}" already exists`;
      Alert.error(message);
      return false;
    }
    return true;
  };
  const onSubmit = async values => {
    const payload = { ...values };
    delete payload.meta;
    if (!isValid(payload)) {
      return;
    }
    setIsSaving(true);
    try {
      await onCreateCustomField(payload);
      const message = 'System is optimized for 5 custom-fields. Using more than that may affect the UI layout in unexpected ways';
      Alert.warning(message);
      setIsSaving(false);
      onClose();
    } catch {
      setIsSaving(false);
    }
  };
  return (
    <Modal show onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="custom_field.add" defaultMessage="Add a Custom Field" />
        </Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>

              <FormGroup className="form-group" controlId="customFieldName">
                <Row>
                  <Col xs={5}>
                    <FormLabel>
                      <FormattedMessage
                        id="field.custom_field_name"
                        defaultMessage="Display Name (User Friendly)"
                      />: *
                    </FormLabel>
                  </Col>
                  <Col xs={7}>
                    <Field
                      name="field_name"
                      initialValue={initialValues?.field_name}
                      render={props => (
                        <FormControl required {...props.input} />
                      )}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="form-group" controlId="customFieldId">
                <Row>
                  <Col xs={5}>
                    <FormLabel>
                      <FormattedMessage
                        id="field.custom_field_id"
                        defaultMessage="Field Key (internal ID)"
                      />: *
                    </FormLabel>
                  </Col>
                  <Col xs={7}>
                    <Field
                      name="field_id"
                      initialValue={initialValues?.field_id}
                      render={props => (
                        <FormControl required {...props.input} />
                      )}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="form-group" controlId="customFieldRelatedTable">
                <Row>
                  <Col xs={5}>
                    <FormLabel>
                      <FormattedMessage
                        id="field.custom_field_related_table_name"
                        defaultMessage="Related Table"
                      />: *
                    </FormLabel>
                  </Col>
                  <Col xs={7}>
                    <div className="picky-spacer-wrapper">
                      <Field
                        name="related_table_name"
                        initialValue={initialValues?.related_table_name}
                        render={({ input }) => (
                          <SelectSingle
                            name={input.name}
                            data={
                              Object.values(CUSTOM_FIELD_RELATED_TABLE_NAME)
                                .map(
                                  value => ({
                                    value,
                                    label: value,
                                  }),
                                )
                            }
                            value={input.value}
                            handleOnChange={input.onChange}
                            valueKey="value"
                            labelKey="label"
                            includeFilter={false}
                            required
                            imitateOnChangeEvent
                          />
                        )}
                      />
                    </div>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="form-group" controlId="customFieldType">
                <Row>
                  <Col xs={5}>
                    <FormLabel>
                      <FormattedMessage
                        id="field.custom_field_type"
                        defaultMessage="Data Type"
                      />: *
                    </FormLabel>
                  </Col>
                  <Col xs={7}>
                    <div className="picky-spacer-wrapper">
                      <Field
                        name="type"
                        initialValue={initialValues?.type}
                        render={({ input }) => (
                          <SelectSingle
                            name={input.name}
                            data={
                              Object.values(CUSTOM_FIELD_TYPES)
                                .map(
                                  value => ({
                                    value,
                                    label: value,
                                  }),
                                )
                            }
                            value={input.value}
                            handleOnChange={input.onChange}
                            valueKey="value"
                            labelKey="label"
                            includeFilter={false}
                            required
                            imitateOnChangeEvent
                          />
                        )}
                      />
                    </div>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="form-group" controlId="customFieldDefaultValue">
                <Row>
                  <Col xs={5}>
                    <FormLabel>
                      <FormattedMessage
                        id="field.custom_field_default_value"
                        defaultMessage="Default Value"
                      />: {initialValues?.required && '*'}
                    </FormLabel>
                  </Col>
                  <Col xs={7}>
                    <Field
                      name="default_value"
                      parse={value => (_isNil(value) ? null : value)}
                      initialValue={initialValues?.default_value}
                      render={props => (
                        <FormControl
                          required={initialValues?.required}
                          {...props.input}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="form-group" controlId="customFieldRequired">
                <Row>
                  <Col xs={12}>
                    <FormLabel style={{ display: 'flex' }}>
                      <FormattedMessage
                        id="field.custom_field_required"
                        defaultMessage="Value Required"
                      />: &nbsp;
                      <Field
                        name="required"
                        type="checkbox"
                        initialValue={initialValues?.required}
                        render={props => (
                          <BSForm.Check
                            inline
                            className="m-b-0"
                            {...props.input}
                          />
                        )}
                      />
                    </FormLabel>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup className="form-group" controlId="customFieldDescription">
                <Row>
                  <Col xs={5}>
                    <FormLabel>
                      <FormattedMessage
                        id="field.description"
                        defaultMessage="Description"
                      />:
                    </FormLabel>
                  </Col>
                  <Col xs={7}>
                    <Field
                      name="description"
                      parse={value => (_isNil(value) ? null : value)}
                      initialValue={initialValues?.description}
                      render={props => (
                        <FormControl as="textarea" {...props.input} />
                      )}
                    />
                  </Col>
                </Row>
              </FormGroup>

            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onClose}>
                <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
              </Button>
              <SaveButton
                variant={!isHawkingUser && 'success'}
                isSaving={isSaving}
                label={<FormattedMessage id="button.save" defaultMessage="Save" />}
              />

            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
};

CustomFieldAddModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    field_id: reduxFormFieldType,
    field_name: reduxFormFieldType,
    related_table_name: reduxFormFieldType,
    type: reduxFormFieldType,
    default_value: reduxFormFieldType,
    required: reduxFormFieldType,
    description: reduxFormFieldType,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCreateCustomField: PropTypes.func.isRequired,
  isHawkingUser: PropTypes.bool.isRequired,
  customFieldsByUri: PropTypes.shape({}).isRequired,
  input: PropTypes.shape({}).isRequired,
};

export default CustomFieldAddModal;
