import dayjs from 'dayjs';

export const addTimeZoneToDateString = value => {
  if (!value) {
    return null;
  }

  // Convert dates to ISO string in order to include timezone
  const date = dayjs(value);
  return date.toISOString();
};

export const removeTimeZoneFromDateString = value => {
  if (!value) {
    return '';
  }

  // Convert to user timezone and change to genetic date format (YYYY-MM-DD)
  const fieldDate = dayjs(value);
  return fieldDate.format('YYYY-MM-DD');
};

/* This method will extract the Resource Values to the Initial Form Values,
   the same way as we did previously:

   const initialFormValues = {};

   Object
    .keys(<RESOURCE>)

    .filter(key => <FORM_DATA_VALUES.FIELDS>.includes(key))
    .forEach(key => {
      initialFormValues[key] = <RESOURCE>[key];
    });

   *** -> FORM_DATA_VALUES.FIELDS - locates in rapidfab/constants/forms.js

   */
export const extractInitialFormValues = (source, keys) => {
  const result = {};
  keys.forEach(key => {
    if (source[key] !== undefined) {
      result[key] = source[key];
    }
  });
  return result;
};

/* This method will create the sub-dict names "subObjectKey" and
   move the keys/values described in "keys" from the root object to
   the sub-dict names "subObjectKey". It will skip the keys from "keys"
   and will not move them to "subObjectKey" + delete from the initial object.
*/
export const replaceFormValuesIntoSubDict = (source, keys, subObjectKey, keysToSkip = []) => {
  const result = {
    [subObjectKey]: {},
  };

  Object.entries(source).forEach(([key, value]) => {
    if (keys.includes(key) && !keysToSkip.includes(key)) {
      result[subObjectKey][key] = value;
    } else if (!keys.includes(key)) {
      result[key] = value;
    }
  });

  return result;
};
