import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _difference from 'lodash/difference';
import _forEach from 'lodash/forEach';

import { FEATURES, PRIORITIES, ORDER_SHIPPING_GROUPING_OPTIONS, API_RESOURCES, USER_ROLES } from 'rapidfab/constants';
import {
  getShippingsAlphabetized,
  getUsers,
  isCurrentUserRestricted,
  isFeatureEnabled,
  getSubmittedOrders,
  getLocations, getCurrentUserRole,
} from 'rapidfab/selectors';
import userSort from 'rapidfab/utils/userSort';

import NewOrderForm from 'rapidfab/components/records/order/new/NewOrderForm';

import { NEW_ORDER_FORM_CONTAINER } from 'rapidfab/constants/forms';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';

const businessSegmentProfiledFields =
  _difference(NEW_ORDER_FORM_CONTAINER.FIELDS, NEW_ORDER_FORM_CONTAINER.UNIQUE_FIELDS);
const threeMAutofillFields = ['customer_name', 'customer_email'];

const NewOrderFormContainer = props => {
  const isUserRestricted = useSelector(isCurrentUserRestricted);
  const shippings = useSelector(getShippingsAlphabetized);
  const users = useSelector(getUsers).sort(userSort);
  const locationsAvailable = useSelector(getLocations);
  const [fetchedUsers, setFetchedUsers] = React.useState(false);

  const initialShipping = shippings[0] ? shippings[0].uri : null;

  const boeingOrderFieldsFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.BOEING_ORDER_FIELDS));
  const threeMOrderFieldsFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.THREE_M_MAIN_BUREAU_ORDER_FIELDS));
  const beehiveOrderFieldsFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.BEEHIVE3D_ORDER_FIELDS));
  const xerox3DPFieldsFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.XEROX3DP_FIELDS));
  const isDanfossUser = useSelector(state => isFeatureEnabled(state, FEATURES.ORDER_BUSINESS_SEGMENT));
  const isShipmentForOrderFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.SHIPMENT_FOR_ORDER));
  const ricohOrderFieldsFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.RICOH_USA_ORDER_FIELDS));

  const isDigitalDesignWarehouseFeatureEnabled = useSelector(state => isFeatureEnabled(
    state, FEATURES.DIGITAL_DESIGN_WAREHOUSE,
  ));

  const submittedOrders = useSelector(getSubmittedOrders);
  const currentUserRole = useSelector(getCurrentUserRole);
  const isManager = currentUserRole === USER_ROLES.MANAGER;

  const initialValues = {
    custom_field_values: [],
    shipping: {
      uri: initialShipping,
    },
    priority: PRIORITIES.NORMAL,
    // when the ricohOrderFieldsFeatureEnabled is enabled(For Ricoh),
    // initially the `WholeOrderShipmentCheckbox` should be checked(value=`by-order`)
    shipping_grouping: ricohOrderFieldsFeatureEnabled
      ? ORDER_SHIPPING_GROUPING_OPTIONS.BY_ORDER
      : (isShipmentForOrderFeatureEnabled
        ? ORDER_SHIPPING_GROUPING_OPTIONS.BY_BATCHING_STRATEGY
        : null),
  };

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => NEW_ORDER_FORM_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  if (threeMOrderFieldsFeatureEnabled) {
    // For companies with requested fields to be auto-filled,
    // Data will be auto-filled with information from the most recent previous order
    // Stored in LocalStorage. Only exceptions are fields from uniqueFields

    const autoFillFields = threeMOrderFieldsFeatureEnabled ?
      threeMAutofillFields
      :
      businessSegmentProfiledFields;
    try {
      const latestSubmittedOrder = submittedOrders && submittedOrders[0];
      if (latestSubmittedOrder) {
        _forEach(autoFillFields, value => {
          let fieldName = value;

          // For fields like 'shipping.name'
          // makes sense to copy whole 'shipping' object with all values
          const possiblyDictDotIndex = fieldName.indexOf('.');
          const isDictField = (possiblyDictDotIndex > -1);
          if (isDictField) {
            fieldName = value.slice(0, possiblyDictDotIndex);
          }

          if (latestSubmittedOrder[fieldName]) {
            initialValues[fieldName] = latestSubmittedOrder[fieldName];
          }
        });
      }
    } catch {
      // empty
    }
  }

  const dispatch = useDispatch();

  const fetchAllUsers = useCallback(() => dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list(
    (isManager && { archived: false }),
  )).then(() => setFetchedUsers(true)), []);

  const selected = {
    isUserRestricted,
    initialFormValues,
    shippings,
    users,
    formValues: props.formValues,
    customOrderFieldReferences: props.customOrderFieldReferences,
    locationsAvailable,
    isBoeingOrderFieldsFeatureEnabled: boeingOrderFieldsFeatureEnabled,
    is3MOrderFieldsFeatureEnabled: threeMOrderFieldsFeatureEnabled,
    isBeehiveOrderFieldsFeatureEnabled: beehiveOrderFieldsFeatureEnabled,
    isXerox3DPFieldsFeatureEnabled: xerox3DPFieldsFeatureEnabled,
    isDanfossUser,
    submittedOrders,
    fetchedUsers,
    isDigitalDesignWarehouseFeatureEnabled,
  };

  const dispatched = {
    fetchAllUsers,
  };

  return (
    <NewOrderForm
      {...props}
      {...selected}
      {...dispatched}
    />
  );
};

NewOrderFormContainer.propTypes = {
  formValues: PropTypes.shape({}).isRequired,
  customOrderFieldReferences: PropTypes.shape({}).isRequired,
};

export default NewOrderFormContainer;
