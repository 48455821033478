import React from 'react';
import CustomFieldDetailModal from 'rapidfab/components/admin/CustomFieldDetailModal';
import { FEATURES } from 'rapidfab/constants';
import { getCustomFieldsByUri, isFeatureEnabled } from 'rapidfab/selectors';
import { connect } from 'react-redux';

const CustomFieldDetailModalContainer = props => <CustomFieldDetailModal {...props} />;

const mapStateToProps = (state, props) => {
  const { uri } = props;
  const customFieldsByUri = getCustomFieldsByUri(state);
  const customField = customFieldsByUri[uri];
  const isHawkingUser = isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT);
  return {
    customField,
    isHawkingUser,
  };
};

const mapDispatchToProps = () => ({
  onDeleteCustomField: uri => {
    // TODO write func
    console.log('onDeleteCustomField', uri);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldDetailModalContainer);
