import _sumBy from 'lodash/sumBy';
import { getLineItemEstimatesTypeObjectKey } from './lineItem';

export default function calculateDueDate(pieces) {
  if (!pieces) {
    return null;
  }
  const secondsEstimatedTotal = _sumBy(pieces, piece => Number.parseInt(
    // lineItem.estimates.printing_time is for all pieces of line item
    // For one piece we have to divide it by piece quantity
    piece.lineItem[getLineItemEstimatesTypeObjectKey(piece.lineItem)].printing_time / piece.lineItem.quantity,
    10,
  ));

  if (!secondsEstimatedTotal) {
    return null;
  }

  // Convert seconds to milliseconds
  return new Date(Date.now() + (secondsEstimatedTotal * 1000)).toISOString();
}
