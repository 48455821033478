import React from 'react';
import PropTypes from 'prop-types';

import { Button, FormLabel, FormControl, FormGroup, ButtonGroup } from 'react-bootstrap';

import { FormattedMessage } from 'rapidfab/i18n';

const styles = {
  paddingTop: { paddingTop: '1rem' },
};

const UserForm = ({
  isEditing,
  email,
  name,
  handleInputChange,
  handleSubmit,
}) => (
  <>

    <h5>
      <FormattedMessage
        id="instructionsForAddingUsers"
        defaultMessage="Please enter user's full name and email address here. On submission they will be emailed a link to complete the setup of their account. The link will be valid for 24 hours."
      />
    </h5>

    <form onSubmit={handleSubmit}>
      <FormGroup className="form-group">
        <FormLabel>
          <FormattedMessage id="field.name" defaultMessage="Name" />
        </FormLabel>
        <FormControl
          type="text"
          name="name"
          value={name}
          onChange={handleInputChange}
          required
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>
          <FormattedMessage id="email" defaultMessage="Email" />
        </FormLabel>
        <FormControl
          type="text"
          name="email"
          value={email}
          onChange={handleInputChange}
          required
        />
      </FormGroup>
      <FormGroup style={styles.paddingTop}>
        <ButtonGroup variant="default" className="block" type="submit">
          <Button type="submit" className="w-full">
            {isEditing ? (
              <FormattedMessage id="updateUser" defaultMessage="Update User" />
            ) : (
              <FormattedMessage id="createAccountAndInvite" defaultMessage="Create User" />
            )}
          </Button>
        </ButtonGroup>
      </FormGroup>
    </form>
  </>
);

UserForm.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default UserForm;
