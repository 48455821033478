import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import * as Selectors from 'rapidfab/selectors';
import LoginForm from 'rapidfab/components/LoginForm';
import HawkingLoginForm from 'rapidfab/components/HawkingLoginForm';
import LoginHeader from 'rapidfab/components/LoginHeader';
import { Row, Col } from 'react-bootstrap';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, XEROX_HAWKING_BRANDING, XEROX_LOGIN_ROUTE_PREFIX,
  XEROX_BUSINESS_DOMAIN, STANLEY_X_BRANDING } from 'rapidfab/constants';
import { useLocation } from 'react-router-dom';

const LoginContainer = () => {
  const isLoading = useSelector(state =>
    Selectors.getResourceFetching(state, 'nautilus.saml') ||
    Selectors.getResourceFetching(state, 'nautilus.sessions'));
  // Use raw data as saml resource identifier is not valid API endpoint
  // (email as resource identifier instead of uuid)
  const { json: samlData, errors: samlErrors } = useSelector(state => state.ui.nautilus[API_RESOURCES.SAML].get);

  const selected = {
    isLoading,
    saml: samlData,
    samlErrors,
    queryParams: {},
  };

  const dispatch = useDispatch();

  const onEmailSubmit = email => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.SAML].get(email));
  };
  const onSubmit = (email, password, callback) => {
    const payload = { username: email, password };
    dispatch(Actions.Api.nautilus[API_RESOURCES.SESSIONS].post(payload)).then(() => callback());
  };

  // Errors from saml endpoint means disabled SAML integration for this user
  let isPasswordVisible = selected.samlErrors.length > 0;
  let ssoURI = null;

  const location = useLocation();

  const isHawkingLogin =
    location.pathname.includes(XEROX_LOGIN_ROUTE_PREFIX) ||
    window.location.toString().includes(XEROX_BUSINESS_DOMAIN);
  const isStanleyXLogin = location.pathname.includes('stanley-select/login');

  if (!_isEmpty(selected.saml)) {
    if (!selected.saml.allow_password_login) {
      window.location = selected.saml.sso_uri;
    } else {
      // Allow to login via password + with SSO button
      isPasswordVisible = true;
      ssoURI = selected.saml.sso_uri;
    }
  }

  const onSuccessLogin = () => {
    if (isHawkingLogin) {
      localStorage.setItem(XEROX_HAWKING_BRANDING, 'true');
    } else if (isStanleyXLogin) {
      localStorage.setItem(STANLEY_X_BRANDING, 'true');
    }
    window.location.reload();
  };

  return (
    <div>
      {!isHawkingLogin && (
        <LoginHeader
          isHawkingLogin={isHawkingLogin}
          isStanleyXLogin={isStanleyXLogin}
        />
      )}
      {isHawkingLogin && (
        <HawkingLoginForm
          loading={isLoading}
          onEmailSubmit={onEmailSubmit}
          onSubmit={(login, password) => onSubmit(login, password, onSuccessLogin)}
          ssoURI={ssoURI}
          withPassword={isPasswordVisible}
          isStanleyXLogin={isStanleyXLogin}
        />
      )}
      {!isHawkingLogin && (
        <LoginForm
          loading={isLoading}
          onEmailSubmit={onEmailSubmit}
          onSubmit={(login, password) => onSubmit(login, password, onSuccessLogin)}
          ssoURI={ssoURI}
          withPassword={isPasswordVisible}
          isStanleyXLogin={isStanleyXLogin}
        />
      )}
      {!isHawkingLogin && (
        <Row>
          <Col
            xs={{ span: 10, offset: 1 }}
            md={{ span: 6, offset: 3 }}
            className="text-center"
          >
            <hr />© Authentise 2015
          </Col>
        </Row>
      )}
    </div>
  );
};

export default LoginContainer;
