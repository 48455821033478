import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import { ListGroup, ListGroupItem, Card, Row, Col } from 'react-bootstrap';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import ProcessProviderContainer from 'rapidfab/containers/records/ProcessProviderContainer';

const ProcessProviders = ({
  serviceProviders,
  processProvidersByServiceProviderUri,
  processorUri,
  isServiceProviderRole,
}) => {
  const headerText = isServiceProviderRole ? 'Accept Jobs From' : 'Outsource To';
  return (
    <Card bg="dark" border="secondary" className="panel--scrollable process-providers mt15">
      <Card.Header className="pd-exp inverse">{headerText}</Card.Header>
      <Card.Body>
        <ListGroup fill className="process-providers-list-group">
          <ListGroupItem key="header">
            <Row>
              <Col xs={6}>
                <b>
                  {isServiceProviderRole ? 'Source Bureau' : 'Service Provider'}
                </b>
              </Col>
              <Col xs={3}>
                <b>Status</b>
              </Col>
              <Col xs={3}>
                <b>Action</b>
              </Col>
            </Row>
          </ListGroupItem>

          {serviceProviders ? (
            serviceProviders.map(provider => {
              const uniqueKey = getShortUUID(provider.uuid);
              const formID = `record.service_provider_${uniqueKey}`;
              const processProvider =
                // For regular bureau
                processProvidersByServiceProviderUri[provider.uri] ||
                // For service provider
                processProvidersByServiceProviderUri[
                  provider.source_service_provider
                ];

              return (
                <ProcessProviderContainer
                  key={uniqueKey}
                  form={formID}
                  provider={provider}
                  processorUri={processorUri}
                  processProvider={processProvider}
                />
              );
            })
          ) : (
            <Loading />
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

ProcessProviders.propTypes = {
  processorUri: PropTypes.string.isRequired,
  serviceProviders: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string.isRequired,
  })).isRequired,
  processProvidersByServiceProviderUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  isServiceProviderRole: PropTypes.bool.isRequired,
};

export default ProcessProviders;
