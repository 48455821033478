import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import _filter from 'lodash/filter';
import { getLineItems } from 'rapidfab/selectors/lineItem';
import { getDesignFiles } from 'rapidfab/selectors/designFile';
import { getAssemblyMeta } from 'rapidfab/selectors/assemblyMeta';
import { getAssemblyPartMeta } from 'rapidfab/selectors/assemblyPartMeta';

export const getLineItemsForProduct = createSelector(
  [getPredicate, getLineItems],
  (product, lineItems) => {
    if (!product) {
      return [];
    }
    return _filter(lineItems, { product: product.uri });
  },
);

export const getDesignFilesForProduct = createSelector(
  [getPredicate, getDesignFiles],
  (product, designFiles) => {
    if (!product) {
      return [];
    }
    return _filter(designFiles, { product: product.uri });
  },
);

export const getAssemblyMetaForProduct = createSelector(
  [getPredicate, getAssemblyMeta],
  (product, assemblyMeta) => {
    if (!product) {
      return [];
    }
    return _filter(assemblyMeta, { product: product.uri });
  },
);

export const getAssemblyPartMetaForProduct = createSelector(
  [getPredicate, getAssemblyPartMeta],
  (product, assemblyPartMeta) => {
    if (!product) {
      return [];
    }
    return _filter(assemblyPartMeta, { product: product.uri });
  },
);
