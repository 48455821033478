import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Alert from 'rapidfab/utils/alert';
import {
  ALLOWED_MODEL_EXTENSIONS,
  NATIVE_CAD_CONVERSION_EXTENSIONS,
  ZVERSE_CONVERSION_EXTENSIONS,
} from 'rapidfab/constants';

const ModelInputDropTarget = ({
  onSelectFiles, children, isZverseConversionSupported, isNativeCadConversionSupported,
}) => {
  const getFileExtension = file => {
    try {
      return file.name.toLowerCase().split('.').pop();
    } catch {
      return null;
    }
  };

  const validExtensions = [
    ...ALLOWED_MODEL_EXTENSIONS,
    ...(isZverseConversionSupported ? ZVERSE_CONVERSION_EXTENSIONS : []),
    ...(isNativeCadConversionSupported ? NATIVE_CAD_CONVERSION_EXTENSIONS : []),
  ];

  const onDropValidate = file => {
    const fileExtension = getFileExtension(file);

    if (fileExtension && !validExtensions.includes(fileExtension)) {
      let message = `File must be one of extension: ${validExtensions.join(', ')}`;
      if (!isNativeCadConversionSupported && NATIVE_CAD_CONVERSION_EXTENSIONS.includes(fileExtension)) {
        // When a Native CAD file is selected, but it is not allowed - ask to contact Authentise to enable it
        message = `We don't support ${fileExtension} format. Contact Authentise Support to include more file formats.`;
      }
      // Dropzone expects object to be returned in case of error
      // (the result is used in onDropRejected)
      return {
        message,
      };
    }

    // Prevent users to upload the files with the size more than 256 MB
    if (Number(file.size) > 256000000) {
      return {
        message: 'The file that you selected is too large. ' +
          'Please upload a file that does not exceed 256 MB or contact your system administrator if you require support for larger files.',
      };
    }

    // Dropzone expects null when everything is fine
    return null;
  };

  const onDropAccepted = files =>
    onSelectFiles(files);

  /**
   * See Dropzone docs for more details
   * @param filesErrors [{ errors: [{ message: '' }], file: {...} }]
   */
  const onDropRejected = filesErrors => {
    filesErrors.forEach(({ errors }) => {
      errors.forEach(({ message }) => {
        Alert.error(message);
      });
    });
  };

  return (
    <Dropzone
      onDropAccepted={onDropAccepted}
      validator={onDropValidate}
      onDropRejected={onDropRejected}
      multiple
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps({ role: 'button' })}>
          <input {...getInputProps()} />
          {children}
        </div>
      )}
    </Dropzone>
  );
};

ModelInputDropTarget.propTypes = {
  onSelectFiles: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isZverseConversionSupported: PropTypes.bool,
  isNativeCadConversionSupported: PropTypes.bool,
};

ModelInputDropTarget.defaultProps = {
  isZverseConversionSupported: false,
  isNativeCadConversionSupported: false,
};

export default ModelInputDropTarget;
