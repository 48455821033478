import { MATERIAL_UNITS } from 'rapidfab/constants';

const UNIT_COEFFICIENTS = {
  [MATERIAL_UNITS.MM3]: {
    [MATERIAL_UNITS.MM3]: 1,
    // 1 mm3 = 0.001 cm3
    [MATERIAL_UNITS.CM3]: 0.001,
    // 1 mm3 = 0.0000610237441 in3
    [MATERIAL_UNITS.INCH3]: 0.0000610237441,
  },
  [MATERIAL_UNITS.CM3]: {
    // 1 cm3 = 1000 mm3
    [MATERIAL_UNITS.MM3]: 1000,
    [MATERIAL_UNITS.CM3]: 1,
    // 1 cm3 = 0.0610237441 in3
    [MATERIAL_UNITS.INCH3]: 0.0610237441,
  },
  [MATERIAL_UNITS.INCH3]: {
    // 1 in3 = 16 387.064 mm3
    [MATERIAL_UNITS.MM3]: 16387.064,
    // 1 in3 = 16.387064 cm3
    [MATERIAL_UNITS.CM3]: 16.387064,
    [MATERIAL_UNITS.INCH3]: 1,
  },
};

export default function convertVolumeToOtherUnit(value, valueUnit, requestedUnit) {
  if (!UNIT_COEFFICIENTS[valueUnit]) {
    return null;
  }

  if (!UNIT_COEFFICIENTS[valueUnit][requestedUnit]) {
    return null;
  }

  const coef = UNIT_COEFFICIENTS[valueUnit][requestedUnit];

  return value * coef;
}
