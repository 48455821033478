import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _map from 'lodash/map';
import PropTypes from 'prop-types';

import * as Selectors from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';
import { API_RESOURCES } from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';
import extractUuid from 'rapidfab/utils/extractUuid';
import { AnatomicalModelAssemblies } from 'rapidfab/components/AnatomicalModel';

const AnatomicalModelAssembliesContainer = ({ anatomicalModelUri }) => {
  const anatomicalModel = useSelector(state =>
    anatomicalModelUri &&
    Selectors.getUUIDResource(state, extractUuid(anatomicalModelUri)));
  const assemblies = useSelector(state =>
    Selectors.getAnatomicalModelAssembliesForModel(state, anatomicalModel));
  const partsByAssemblyUri = useSelector(Selectors.getAnatomicalModelPartsByAssemblyUri);
  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ANATOMICAL_MODEL_ASSEMBLY].list.fetching);

  const dispatch = useDispatch();
  const loadAssemblies = async () => {
    if (!anatomicalModelUri) {
      return;
    }
    const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_ASSEMBLY]
      .list({ anatomical_model: anatomicalModelUri }));
    const assemblyUris = _map(response.json.resources, 'uri');
    if (!assemblyUris.length) {
      return;
    }
    dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART]
      .list({ anatomical_model_assembly: assemblyUris }));
  };

  const onInitialize = () => {
    loadAssemblies();
  };

  useEffect(() => {
    onInitialize();
  }, []);

  const onDeleteAssembly = assemblyUri => (assemblyUri
    ? dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_ASSEMBLY]
      .delete(extractUuid(assemblyUri)))
    : Promise.reject());

  const onDeletePart = partUri => (partUri
    ? dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART]
      .delete(extractUuid(partUri)))
    : Promise.reject());

  if (fetching) {
    return <Loading />;
  }

  return (
    <AnatomicalModelAssemblies
      assemblies={assemblies}
      anatomicalModelUri={anatomicalModelUri}
      partsByAssemblyUri={partsByAssemblyUri}
      onDeleteAssembly={onDeleteAssembly}
      onDeletePart={onDeletePart}
    />
  );
};

AnatomicalModelAssembliesContainer.propTypes = {
  anatomicalModelUri: PropTypes.string.isRequired,
};

export default AnatomicalModelAssembliesContainer;
