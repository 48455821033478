import React from 'react';
import Fa from 'react-fontawesome';
import RcTooltip from 'rc-tooltip';
import PropTypes from 'prop-types';
import extractUuid from 'rapidfab/utils/extractUuid';
import { FormattedMessage } from '../../../i18n';
import { RUN_STATUS_MAP } from '../../../mappings';
import Config from '../../../config';
import { runResourceType } from '../../../types';

const GanttRunTooltip = ({ run, isBuildAttached, children }) => (
  <RcTooltip
    placement="bottom"
    destroyTooltipOnHide
    mouseEnterDelay={0}
    mouseLeaveDelay={0.1}
    id={run.id}
    overlayInnerStyle={{ padding: '10px', wordBreak: 'break-word' }}
    overlay={(
      <div className="QueuesStyleOverlay">
        <>
          <div>
            <b>Run Type: </b>
            {run.workstation_type_name}
          </div>
          <div>
            <b>Workstation: </b>
            {run.workstation_name}
          </div>
          <div>
            <b>Status: </b>
            <FormattedMessage
              {...RUN_STATUS_MAP[run.status]}
            />
          </div>
          <div>
            <b>Pieces in Run: </b>{run.prints.length}
          </div>
          <div>
            {/* eslint-disable-next-line camelcase */}
            <b>Locked: </b>{run.pieces_locked ? 'Yes' : 'No'}
          </div>
          <div>
            <b>Linked to Build: </b>{isBuildAttached ? 'Yes' : 'No'}
          </div>
          <div className="QueuesStyleQrContainer">
            <a className="QueuesStyleQrLink" href={`${Config.HOST.QR}/traveler/run/${extractUuid(run.uri)}`}>
              <Fa name="qrcode" size="lg" className="spacer-right" />
            </a>
          </div>
        </>
      </div>
    )}
  >
    {children}
  </RcTooltip>
);

GanttRunTooltip.propTypes = {
  run: runResourceType.isRequired,
  isBuildAttached: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

export default GanttRunTooltip;
