import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import extractUuid from 'rapidfab/utils/extractUuid';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import PrepWorkflowModal from 'rapidfab/components/manage/PrepWorkflowModal';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import _sortBy from 'lodash/sortBy';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import getEndpointFromURI from 'rapidfab/utils/getEndpointFromURI';

import { PREP_WORKFLOW_MODAL_CONTAINER } from 'rapidfab/constants/forms';

const PrepWorkflowModalContainer = ({ prepWorkflowUUID, onClose, ...restProps }) => {
  const isLoading = useSelector(state => state.ui.nautilus[API_RESOURCES.PREP_WORKFLOW].get.fetching
    || state.ui.nautilus[API_RESOURCES.PREP_TASK].list.fetching);
  const isSubmitting = useSelector(state => state.ui.nautilus[API_RESOURCES.PREP_WORKFLOW].post.fetching ||
    state.ui.nautilus[API_RESOURCES.PREP_WORKFLOW].put.fetching);
  const prepWorkflow = useSelector(state => Selectors.getUUIDResource(state, prepWorkflowUUID));
  const customGroups = useSelector(Selectors.getCustomGroups);
  const initialValues = prepWorkflow ? _cloneDeep(prepWorkflow) : { tasks_in_workflow: [] };

  initialValues.tasks_in_workflow = _map(
    _sortBy(initialValues.tasks_in_workflow, 'position'),
    taskInWorkflow => {
      const { position, ...taskInWorkflowWithoutPosition } = taskInWorkflow;
      return taskInWorkflowWithoutPosition;
    },
  );

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => PREP_WORKFLOW_MODAL_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  const prepTasks = useSelector(Selectors.getPrepTasks);

  const selected = {
    isLoading,
    isSubmitting,
    prepWorkflow,
    customGroups,
    initialFormValues,
    prepTasks,
  };

  const dispatch = useDispatch();

  const onInitialize = currentPrepWorkflowUUID => {
    if (currentPrepWorkflowUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_WORKFLOW].get(prepWorkflowUUID));
    }

    dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_TASK].list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
  };
  const onFormSubmit = async payload => {
    const { tasks_in_workflow: tasksInWorkflow, ...restPayload } = { ...payload };
    const updatedPayload = _omit(restPayload, PREP_WORKFLOW_MODAL_CONTAINER.READONLY_FIELDS);

    updatedPayload.tasks_in_workflow = _map(
      tasksInWorkflow,
      taskInWorkflow => _omit(taskInWorkflow, PREP_WORKFLOW_MODAL_CONTAINER.IGNORED_FIELDS),
    );

    let prepWorkflowUri = updatedPayload.uri;
    if (updatedPayload.uuid) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_WORKFLOW].put(updatedPayload.uuid, updatedPayload));
    } else {
      const prepWorkflowResponse =
        await dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_WORKFLOW].post(updatedPayload));
      prepWorkflowUri = prepWorkflowResponse.headers.location;
    }
    await dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_WORKFLOW].get(extractUuid(prepWorkflowUri)));
    onClose(prepWorkflowUri);
  };

  const onReplace = async payload => {
    const { tasks_in_workflow: tasksInWorkflow, uuid, ...restPayload } = { ...payload };
    const updatedPayload = _omit(restPayload, PREP_WORKFLOW_MODAL_CONTAINER.READONLY_FIELDS);

    updatedPayload.tasks_in_workflow = _map(
      tasksInWorkflow,
      taskInWorkflow => _omit(taskInWorkflow, PREP_WORKFLOW_MODAL_CONTAINER.IGNORED_FIELDS),
    );

    const prepWorkflowResponse =
      await dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_WORKFLOW].replace(uuid, updatedPayload));
    const prepWorkflowUri = prepWorkflowResponse.headers.location;
    if (prepWorkflowUri) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_WORKFLOW].get(extractUuid(prepWorkflowUri))).then(() => {
        onClose(prepWorkflowUri);
      });
    }
  };

  useEffect(() => {
    (async () => onInitialize(prepWorkflowUUID))();
  }, []);

  const handleClose = () => onClose(null);

  const handleSubmit = event => {
    const {
      customizeForUri,
    } = restProps;
    const values = initialValues;

    if (customizeForUri) {
      // If tasks were not changed (just prep workflow name is changed),
      // we don't need to replace prep workflow
      const isAnyTaskChanged = !_isEqual(
        initialValues.tasks_in_workflow,
        event.tasks_in_workflow,
      );

      if (!values.source_workflow && isAnyTaskChanged) {
        // If prep workflow is customized first time for "customizeForUri" entity
        // we need to create new prep workflow only for this entity

        const endpoint = getEndpointFromURI(customizeForUri);
        const endpointToFieldName = {
          'line-item': 'line_item',
          order: 'order',
        };
        const prepWorkflowForFieldName = endpointToFieldName[endpoint.endpointName];
        if (!prepWorkflowForFieldName) {
          throw new Error(`Not sure how to handle endpoint ${customizeForUri} for prep workflow ${values.uuid}`);
        }

        values[prepWorkflowForFieldName] = customizeForUri;
        values.name = `${values.name} for ${endpoint.endpointName} ${endpoint.shortUUID}`;

        values.tasks_in_workflow = event.tasks_in_workflow;
        const payload = _omit(values, ['type', 'updated', 'usage_state', 'order']);
        return onReplace(payload);
      }
    }

    return onFormSubmit(event);
  };

  return (
    <PrepWorkflowModal
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      prepWorkflow={prepWorkflow}
      customGroups={customGroups}
      prepTasks={prepTasks}
      onClose={handleClose}
      onFormSubmit={handleSubmit}
      onReplace={onReplace}
      {...restProps}
      {...selected}
    />
  );
};

PrepWorkflowModalContainer.defaultProps = {
  prepWorkflow: null,
  prepWorkflowUUID: null,
  currentActivePrepTaskIndex: null,
  customizeForUri: null,
  withDeleteConfirmation: false,
};

PrepWorkflowModalContainer.propTypes = {
  currentActivePrepTaskIndex: PropTypes.number,
  customizeForUri: PropTypes.string,
  withDeleteConfirmation: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  prepWorkflow: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    uri: PropTypes.string,
  }),
  prepWorkflowUUID: PropTypes.string,
  fields: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onReplace: PropTypes.func.isRequired,
  onInitialize: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  prepTasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PrepWorkflowModalContainer;
