import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as Selectors from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import _omit from 'lodash/omit';
import Actions from 'rapidfab/actions';
import AdminSettings from 'rapidfab/components/admin/AdminSettings';
import extractUuid from 'rapidfab/utils/extractUuid';
import { API_RESOURCES } from 'rapidfab/constants';

import { ADMIN_SETTINGS_CONTAINER } from 'rapidfab/constants/forms';
import usePrevious from 'rapidfab/hooks';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import { FormattedMessage } from 'react-intl';

const AdminSettingsContainer = () => {
  // It's used for bitwise masks (all values must be 2^x)
  const roles = {
    CUSTOMER_ROLE: 1,
    OWNER_ROLE: 2,
    FOLLOWING_EMAIL: 4,
  };

  // eslint-disable-next-line unicorn/no-array-reduce
  const ALL_ROLES = Object.values(roles).reduce((accumulator, value) => accumulator + value, 0);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);
  const [formState, setFormState] = useState({});

  const notifications = [
    {
      id: 'notification_order_created',
      label: 'New order created',
      show: (roles.CUSTOMER_ROLE + roles.FOLLOWING_EMAIL),
    },
    {
      id: 'notification_line_items_confirmed',
      label: 'All order line items have been confirmed',
      show: ALL_ROLES,
    },
    {
      id: 'notification_order_scheduled',
      label: 'All order objects have been scheduled',
      show: ALL_ROLES,
    },
    {
      id: 'notification_order_started_printing',
      label: 'First object in an order has started printing',
      show: ALL_ROLES,
    },
    {
      id: 'notification_print_error',
      label: 'Any print has errored',
      show: ALL_ROLES,
    },
    {
      id: 'notification_order_completed',
      label: 'An order has finished shipping',
      show: ALL_ROLES,
    },
  ];

  const bureauSettings = useSelector(Selectors.getBureauSettings);
  const bureauIntakeSettings = useSelector(Selectors.getBureauIntakeSettings) || {};
  const initialValues = { ...bureauSettings };
  const loading = useSelector(state => state.ui.nautilus[API_RESOURCES.BUREAU_SETTINGS].list.fetching);
  const isSubmitting = useSelector(state => state.ui.nautilus[API_RESOURCES.BUREAU_SETTINGS].put.fetching);
  const prepWorkflows = useSelector(state => Selectors.getPrepWorkflows(state));
  const isSessionManager = useSelector(state => Selectors.isSessionManager(state));

  ADMIN_SETTINGS_CONTAINER.NULL_FIELDS.forEach(
    fieldName => {
      if (initialValues[fieldName] === null) {
        initialValues[fieldName] = '';
      }
    },
  );

  ADMIN_SETTINGS_CONTAINER.INTEGER_FIELDS.forEach(
    fieldName => {
      if (initialValues[fieldName]) {
        initialValues[fieldName] = String(initialValues[fieldName]);
      }
    },
  );

  ADMIN_SETTINGS_CONTAINER.FLOAT_FIELDS.forEach(
    fieldName => {
      if (initialValues[fieldName]) {
        initialValues[fieldName] = String(initialValues[fieldName]);
      }
    },
  );

  ADMIN_SETTINGS_CONTAINER.ARRAY_FIELDS.forEach(
    fieldName => {
      if (initialValues[fieldName]) {
        initialValues[fieldName] = (initialValues[fieldName] || '').join(',');
      }
    },
  );

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => ADMIN_SETTINGS_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  const selected = {
    initialFormValues,
    bureauSettings: useSelector(state => state.ui.nautilus[API_RESOURCES.BUREAU_SETTINGS].put),
    loading,
    isSubmitting,
    bureauIntakeSettings: { ...(bureauIntakeSettings || {}) },
    prepWorkflows,
    isSessionManager,
    assemblyWorkflows: useSelector(Selectors.getNonCustomAssemblyWorkflows),
  };

  const dispatch = useDispatch();

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.BUREAU_SETTINGS].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.BUREAU_INTAKE_SETTINGS].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PREP_WORKFLOW].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list());
  };

  const onFormSubmit = formValues => {
    let payload = { ...formValues };

    if (selected.bureauSettings?.errors?.length !== 0) {
      // force should only be sent as true when error recieved.
      payload.force = true;
    }
    ADMIN_SETTINGS_CONTAINER.NULL_FIELDS.forEach(
      fieldName => {
        if (payload[fieldName] === '') {
          payload[fieldName] = null;
        }
      },
    );

    ADMIN_SETTINGS_CONTAINER.INTEGER_FIELDS.forEach(
      fieldName => {
        const integerValue = Number.parseInt(payload[fieldName], 10);
        if (integerValue) {
          payload[fieldName] = integerValue;
        }
      },
    );

    ADMIN_SETTINGS_CONTAINER.FLOAT_FIELDS.forEach(
      fieldName => {
        const floatValue = Number.parseFloat(payload[fieldName]);
        if (floatValue) {
          payload[fieldName] = floatValue;
        }
      },
    );

    ADMIN_SETTINGS_CONTAINER.ARRAY_FIELDS.forEach(
      fieldName => {
        // Split into array, remove null values and remove spaces before/after email
        payload[fieldName] = payload[fieldName].split(',').filter(Boolean).map(item => item.trim());
      },
    );

    ADMIN_SETTINGS_CONTAINER.BOOLEAN_VALUES.forEach(
      fieldName => {
        payload[fieldName] = ([true, 'true'].includes(payload[fieldName]));
      },
    );
    const bureauSettingsUUID = extractUuid(payload.uri);

    // Remove read-only fields from payload
    payload = _omit(payload, ADMIN_SETTINGS_CONTAINER.READONLY_FIELDS);

    dispatch(Actions.Api.nautilus[API_RESOURCES.BUREAU_SETTINGS].put(bureauSettingsUUID, payload))
      .then(() => dispatch(Actions.Api.nautilus[API_RESOURCES.BUREAU_SETTINGS].get(bureauSettingsUUID)));
  };

  useEffect(() => onInitialize(), []);
  const previousSubmitting = usePrevious(isSubmitting);
  useEffect(() => {
    if (previousSubmitting && (isSubmitting !== previousSubmitting)) {
      const isSuccessResponse = selected.bureauSettings?.errors?.length === 0;
      if (isSuccessResponse) {
        Alert.success(
          <FormattedMessage
            id="toaster.adminSettings.successfullyUpdated"
            defaultMessage="Settings successfully updated."
          />,
        );
      }
    }
  }, [isSubmitting, previousSubmitting, selected.bureauSettings?.errors?.length]);

  const previousBureauSettings = usePrevious(selected.bureauSettings);
  useEffect(() => {
    if (previousBureauSettings?.errors && (previousBureauSettings.errors !== selected.bureauSettings.errors)
      && isSubmitting === false) {
      if (selected.bureauSettings?.errors?.length !== 0) {
        setShowConfirmationModal(true);
      } else {
        setShowConfirmationModal(false);
      }
    }
  }, [isSubmitting, previousBureauSettings?.errors, selected.bureauSettings.errors]);

  const handleWarningCancel = () => {
    setShowConfirmationModal(false);
    dispatch(Actions.Api.nautilus['bureau-settings'].list());
  };

  const onToggle = ([fieldName, fieldMeta], state, { changeValue }) => {
    let bitwiseFieldValue = state.formState.values[fieldName] || state.formState.initialValues[fieldName];

    if (fieldMeta.isChecked) {
      // add roleBitwiseMask value to all values
      // eslint-disable-next-line no-bitwise
      bitwiseFieldValue |= fieldMeta.roleBitwiseMask;
    } else {
      // eslint-disable-next-line no-bitwise
      bitwiseFieldValue ^= fieldMeta.roleBitwiseMask;
    }

    changeValue(state, fieldName, () => bitwiseFieldValue);
  };

  return (
    <>
      <AdminSettings
        {...selected}
        onFormSubmit={onFormSubmit}
        roles={roles}
        onToggle={onToggle}
        notifications={notifications}
        isSessionManager={isSessionManager}
        submitting={isSubmitting}
        loading={loading}
        prepWorkflows={prepWorkflows}
        setFormState={setFormState}
        bureauIntakeSettings={bureauIntakeSettings}
      />
      {showConfirmationModal && (
        <ConfirmationModal
          handleCancel={handleWarningCancel}
          handleConfirm={() => onFormSubmit(formState?.values)}
          message="Warning: Some users have active QR ID codes,
            if you prevent QR-based login these users will not be able to use their QR ID to logon.
            Do want to to prevent QR-based logons anyway?"
        />
      )}
    </>
  );
};

AdminSettingsContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isSessionManager: PropTypes.bool.isRequired,
  bureauSettings: PropTypes.shape({
    errors: PropTypes.shape([]).isRequired,
  }).isRequired,
  bureauIntakeSettings: PropTypes.shape({}).isRequired,
  prepWorkflows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fields: PropTypes.shape({}).isRequired,
  onInitialize: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default AdminSettingsContainer;
