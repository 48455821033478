import React from 'react';
import { XEROX_LOGIN_ROUTE_PREFIX, XEROX_BUSINESS_DOMAIN } from 'rapidfab/constants';
import { Row, Col, Image } from 'react-bootstrap';
import StanleyXLogo from 'rapidfab/images/stanley_select_logo_two_colour.png';
import ThreeDIAXLogo from 'rapidfab/images/3diax-logo.png';
import PropTypes from 'prop-types';

const LoginHeader = ({
  isStanleyXLogin,
}) => {
  const isHawkingLogin =
    window.location.hash.includes(XEROX_LOGIN_ROUTE_PREFIX) ||
    window.location.toString().includes(XEROX_BUSINESS_DOMAIN);
  let headingText = 'Authentise Engineering Additive Manufacturing Distribution Platform';
  let headingLogo = ThreeDIAXLogo;
  let imgStyle = { height: '150px' };

  if (isStanleyXLogin) {
    headingText = 'Stanley Select Login';
    headingLogo = StanleyXLogo;
    imgStyle = { height: '50px' };
  }

  return (
    <Row>
      <Col xs={{ span: 10, offset: 1 }} className="text-center mt30">
        <Image src={headingLogo} alt="" style={imgStyle} />
        <h3 className={isHawkingLogin ? 'hawking-logo-heading-text' : 'logo-heading-text'}>{headingText}</h3>
      </Col>
    </Row>
  );
};

LoginHeader.propTypes = {
  isStanleyXLogin: PropTypes.bool.isRequired,
};

export default LoginHeader;
