import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormControl,
  ListGroupItem,
  ListGroup,
} from 'react-bootstrap';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { FormattedMessage } from 'rapidfab/i18n';
import {
  KEY_USERS_FOR_IMPERSONATION,
} from 'rapidfab/constants';
import _isEmpty from 'lodash/isEmpty';
import _toUpper from 'lodash/toUpper';
import _filter from 'lodash/filter';

import Fa from 'react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUsers } from '@fortawesome/free-solid-svg-icons';
import usePrevious from 'rapidfab/hooks';
import Loading from 'rapidfab/components/Loading';

const AdminImpersonation = ({
  onSearch,
  onImpersonate,
  clearImpersonation,
  onInitialize,
  hasImpersonationPermissions,
  users,
  isLoading,
  isImpersonating,
  session,
  onImpersonateEnterPress,
  isPageCurrentlyReloading,
}) => {
  const [email, setEmail] = useState('');
  const previousEmail = usePrevious(email);

  const filteredUsers = (
    _isEmpty(email) ? users :
      _filter(users, ({ username }) => _toUpper(username)
        .includes(_toUpper(email)),
      )
  );

  useEffect(() => {
    if (hasImpersonationPermissions && email) {
      onSearch(email);
    }
    // when the username is blank->
    // initial user list should be restored.
    if (hasImpersonationPermissions && email === '') {
      onInitialize();
    }
  }, [hasImpersonationPermissions]);

  if (isImpersonating || isPageCurrentlyReloading) {
    return <Loading />;
  }

  return (
    <Container className="p-b" fluid>
      <BreadcrumbNav breadcrumbs={['admin', 'impersonation']} />

      <div className="page-header">
        <h1 className="font-size-36">
          <FormattedMessage id="impersonation" defaultMessage="Impersonation" />
        </h1>
      </div>

      <Row>
        <Col xs={12}>
          {session.impersonating && (
            <>
              <h4>
                <Fa name="exclamation-circle" />
                &nbsp; User {session.name} ({session.username}) is impersonated
                NOW. &nbsp;
                <Button
                  variant="danger"
                  size="xs"
                  onClick={() => {
                    onImpersonate(null);
                  }}
                >
                  Stop!
                </Button>
              </h4>
              <hr />
            </>
          )}

          {hasImpersonationPermissions && (
            <Form
              horizontal
              onSubmit={event => {
                event.preventDefault();
                if (users[0] && email) {
                  onImpersonate(users[0].uri);
                }
              }}
            >
              <div className="d-flex align-items-center">
                <span className="mr15">
                  <FormattedMessage
                    id="email"
                    defaultMessage="Email"
                  />:
                </span>
                <FormControl
                  name="email"
                  type="text"
                  placeholder="Username or full email (press enter to impersonate)"
                  autocomplete="username"
                  value={email}
                  onChange={({ target }) => setEmail(target.value)}
                  onBlur={() => {
                    if (previousEmail !== email) {
                      onSearch(email);
                    }
                  }}
                  onKeyDown={event => {
                    if ((event.key === 'Enter' || event.keyCode === 13) && !_isEmpty(email)) {
                      onImpersonateEnterPress(email);
                    }
                  }}
                  disabled={isImpersonating}
                />
                <Button
                  onClick={() => onSearch(email)}
                  className="spacer-left"
                  disabled={isLoading || isImpersonating || isPageCurrentlyReloading}
                >
                  {(isLoading || isImpersonating || isPageCurrentlyReloading) ? <Loading /> : (
                    <FormattedMessage
                      id="record.run.search"
                      defaultMessage="Search"
                    />
                  )}
                </Button>
              </div>
            </Form>
          )}

          <hr />

          <h1>
            <FontAwesomeIcon icon={faUsers} size="sm" className="spacer-right" />
            Quick Impersonation:
          </h1>
          {KEY_USERS_FOR_IMPERSONATION.map(bureau => (
            <Button
              role="button"
              size="xs"
              variant="primary"
              className="spacer-right"
              bg="info"
              onClick={() => {
                clearImpersonation()
                  .then(() => onImpersonate(bureau.uri));
              }}
              disabled={session.uri === bureau.uri}
            >
              {bureau.name}
            </Button>
          ))}

          <hr />

          {users.length > 0 && (
            <>
              <h1>
                <FontAwesomeIcon size="sm" icon={faSearch} className="spacer-right" />
                Found {users.length} user{users.length > 1 ? 's' : ''}:
              </h1>
              <ListGroup>
                {filteredUsers.map(user => (
                  <ListGroupItem key={user.uri}>
                    <Button
                      variant="primary"
                      size="xs"
                      className="spacer-right"
                      onClick={() => {
                        onImpersonate(user.uri);
                      }}
                    >
                      Impersonate!
                    </Button>
                    {` ${user.name} (${user.username})`}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </>
          )}

          {!users.length && email && !isLoading && <h4>Users are not found</h4>}

          {isLoading && <Loading />}
        </Col>
      </Row>
    </Container>
  );
};

AdminImpersonation.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasImpersonationPermissions: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired,
  onInitialize: PropTypes.func.isRequired,
  onImpersonate: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string,
    }),
  ).isRequired,
  session: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string.isRequired,
    impersonating: PropTypes.string.isRequired,
    uri: PropTypes.string,
  }).isRequired,
  clearImpersonation: PropTypes.func.isRequired,
  isImpersonating: PropTypes.bool.isRequired,
  onImpersonateEnterPress: PropTypes.func.isRequired,
  isPageCurrentlyReloading: PropTypes.bool.isRequired,
};

export default AdminImpersonation;
