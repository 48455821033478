import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Card,
} from 'react-bootstrap';
import MyActions from 'rapidfab/components/home/MyActions';
import ModelLibrariesGrid from 'rapidfab/components/manage/ModelLibrariesGrid';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { labelResourceType, modelLibraryResourceType, modelResourceType } from 'rapidfab/types';

function redirect(modelLibrary) {
  window.location.hash = getRouteURI(ROUTES.HAWKING_COMPANY_LIBRARY, {}, { uuid: modelLibrary.uuid });
}

const HawkingDashboard = ({ modelLibraries, modelsByUri, labelsByUri }) => (
  <Container fluid>
    <Row>
      <Col md={6}>
        {/* Panel header="..." */}
        <Card>
          <Card.Header>Recently Added Products</Card.Header>
          <Card.Body>
            <ModelLibrariesGrid
              modelLibraries={modelLibraries}
              labelsByUri={labelsByUri}
              handleSelect={redirect}
              modelsByUri={modelsByUri}
            />
          </Card.Body>
        </Card>
      </Col>
      <Col md={6}>
        {/* Panel header="..." */}
        <Card>
          <Card.Header>My Actions</Card.Header>
          <Card.Body>
            <div className="my-actions-list">
              <MyActions />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
);

HawkingDashboard.propTypes = {
  modelLibraries: PropTypes.arrayOf(modelLibraryResourceType).isRequired,
  labelsByUri: PropTypes.objectOf(labelResourceType).isRequired,
  modelsByUri: PropTypes.objectOf(modelResourceType).isRequired,
};

export default HawkingDashboard;
