import _forEach from 'lodash/forEach';
import getRouteURI from 'rapidfab/utils/getRouteURI';

export default function prepareStepperURI(stepperLinks, params) {
  const stepperModifiedLinks = stepperLinks;

  if (params) {
    _forEach(stepperModifiedLinks, o => {
      const selectedLink = getRouteURI(o.routeName, params);
      // eslint-disable-next-line no-param-reassign
      o.uri = selectedLink;
    });
  }

  return stepperModifiedLinks;
}
