import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Selectors from 'rapidfab/selectors';
import extractUuid from 'rapidfab/utils/extractUuid';
import WorkChecklistModalContainer from 'rapidfab/containers/records/WorkChecklistModalContainer';
import WorkflowModalContainer from 'rapidfab/containers/records/WorkflowModalContainer';
import WorkPlanViewModal from 'rapidfab/components/records/order/edit/WorkPlanViewModal';
import { API_RESOURCES } from 'rapidfab/constants';
import GuidelineSuggestionContext from 'rapidfab/context/GuidelineSuggestionContext';
import getEndpointFromUri from 'rapidfab/utils/getEndpointFromURI';
import Actions from 'rapidfab/actions';
import Alert from 'rapidfab/utils/alert';

const MODALS = {
  WORK_PLAN_PREVIEW: 'work-plan-preview',
  WORK_INSTRUCTIONS: 'work-instructions',
  CUSTOMIZE_WORKFLOW: 'customize-workflow',
};

const WorkPlanViewModalContainer = props => {
  const {
    lineItemUri,
    workflowUri,
    previewOnly,
    guidelineSuggestionsForLineItem,
    onReloadPrints,
  } = props;

  const [selectedProcessStep, setSelectedProcessStep] = useState('');

  const dispatch = useDispatch();

  const fetching = useSelector(state => state.ui.nautilus[API_RESOURCES.WORK_CHECKLISTS_FOR_RESOURCE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.WORKFLOW].get.fetching ||
    state.ui.nautilus[API_RESOURCES.PROCESS_STEP].list.fetching ||
    state.ui.nautilus[API_RESOURCES.POST_PROCESSOR].list.fetching);

  const workflow = useSelector(state => Selectors.getUUIDResource(
    state, props.workflowUri ? extractUuid(props.workflowUri) : null,
  ));
  const processSteps = useSelector(state => Selectors.getProcessStepsForWorkflow(state, workflow));

  const postProcessorsByUri = useSelector(state => Selectors.getPostProcessorsByUri(state));

  const workChecklistLinkings = useSelector(state => Selectors.getWorkChecklistLinkingsForResource(
    state,
    props.lineItemUri,
  ));

  // Contains already existing checklist linkings for the current resource
  // (not the ones to other resources like Workflow or Workstation Type)
  const relatedChecklistLinkings = useSelector(state =>
    Selectors.getRelatedWorkChecklistLinkingsForResource(state, props.lineItemUri));

  // Flag which defines if linking group exist or not (at least one linking exits)
  // Used generally for title (Create|Update) Work Instructions
  const isExistsChecklistLinkingGroup = !!relatedChecklistLinkings.length;

  const selected = {
    isExistsChecklistLinkingGroup,
    fetching,
    workChecklistLinkings,
    processSteps,
    workflow,
    postProcessorsByUri,
  };

  const [visibleModal, setVisibleModal] = useState(MODALS.WORK_PLAN_PREVIEW);
  const [selectedGuidelineSuggestion, setSelectedGuidelineSuggestion] = useState({
    suggestion: {},
    processStep: {},
  });
  const [guidelineSuggestionsWithTemplateData, setGuidelineSuggestionsWithTemplateData] = useState([]);

  const getTemplateDataForGuidelineSuggestions = () => {
    if (guidelineSuggestionsForLineItem) {
      const updatedSuggestionsWithTemplateData = [];
      guidelineSuggestionsForLineItem.forEach(suggestion => {
        const endpoint = getEndpointFromUri(suggestion.source_guideline);

        if (endpoint.endpointName === 'for-work-instruction') {
          dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_INSTRUCTION]
            .get(extractUuid(suggestion.work_instruction_template), true))
            .catch(error => Alert.error(error))
            .then(response => {
              updatedSuggestionsWithTemplateData.push({
                ...suggestion,
                work_instruction_template: response.json,
              });
            });
        } else if (endpoint.endpointName === 'for-process-step') {
          dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP]
            .get(extractUuid(suggestion.process_step_template), true))
            .catch(error => Alert.error(error))
            .then(response => {
              updatedSuggestionsWithTemplateData.push({
                ...suggestion,
                process_step_template: response.json ?? 'Not found',
              });
            });
        }
      });
      setGuidelineSuggestionsWithTemplateData(updatedSuggestionsWithTemplateData);
    }
  };

  useEffect(() => {
    getTemplateDataForGuidelineSuggestions();
  }, []);

  const onOpenPreview = () => {
    setVisibleModal(MODALS.WORK_PLAN_PREVIEW);
    setSelectedProcessStep('');
  };
  const onOpenCustomizeWorkflow = () => {
    setSelectedGuidelineSuggestion({ suggestion: null, processStep: null });
    setVisibleModal(MODALS.CUSTOMIZE_WORKFLOW);
  };

  const onOpenWorkInstructions = () => {
    setSelectedGuidelineSuggestion(null);
    setVisibleModal(MODALS.WORK_INSTRUCTIONS);
  };

  const onApplyWorkInstructionGuidelineSuggestion = (selectedGuidelineSuggestion, processStep) => {
    setSelectedGuidelineSuggestion({ suggestion: selectedGuidelineSuggestion, processStep });
    setVisibleModal(MODALS.WORK_INSTRUCTIONS);
  };

  const onApplyProcessStepGuidelineSuggestion = (selectedGuidelineSuggestion, processStep) => {
    setSelectedGuidelineSuggestion({ suggestion: selectedGuidelineSuggestion, processStep });
    setVisibleModal(MODALS.CUSTOMIZE_WORKFLOW);
  };

  return (
    <GuidelineSuggestionContext.Provider
      value={{
        selectedGuidelineSuggestion,
        guidelineSuggestionsWithTemplateData,
      }}
    >
      {visibleModal === MODALS.WORK_PLAN_PREVIEW && (
        <WorkPlanViewModal
          onApplyGuidelineSuggestion={{
            onApplyWorkInstructionGuidelineSuggestion,
            onApplyProcessStepGuidelineSuggestion,
          }}
          onOpenCustomizeWorkflow={onOpenCustomizeWorkflow}
          onOpenWorkInstructions={onOpenWorkInstructions}
          previewOnly={previewOnly}
          {...props}
          {...selected}
        />
      )}
      <WorkflowModalContainer
        reloadPrints={onReloadPrints}
        lineItemURI={lineItemUri}
        workflowURI={workflowUri}
        onClose={onOpenPreview}
        isHidden={visibleModal !== MODALS.CUSTOMIZE_WORKFLOW}
      />
      {visibleModal === MODALS.WORK_INSTRUCTIONS && (
        <WorkChecklistModalContainer
          onApplyGuidelineSuggestion={{
            onApplyWorkInstructionGuidelineSuggestion,
            onApplyProcessStepGuidelineSuggestion,
          }}
          relatedURI={lineItemUri}
          workflowURI={workflowUri}
          onClose={onOpenPreview}
          selectedProcessStep={selectedProcessStep}
          selectProcessStep={setSelectedProcessStep}
        />
      )}
    </GuidelineSuggestionContext.Provider>
  );
};

WorkPlanViewModalContainer.defaultProps = {
  workflowUri: null,
  previewOnly: false,
};

WorkPlanViewModalContainer.propTypes = {
  workflowUri: PropTypes.string,
  lineItemUri: PropTypes.string.isRequired,
  previewOnly: PropTypes.bool,
  guidelineSuggestionsForLineItem: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onReloadPrints: PropTypes.func.isRequired,
};

export default WorkPlanViewModalContainer;
