import _map from 'lodash/map';
import _find from 'lodash/find';
import { createSelector } from 'reselect';
import { getRouteUUID } from 'rapidfab/selectors/legacy-selectors';
import { getRolesCurrentUser } from 'rapidfab/selectors/helpers/roles';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getStateResources } from 'rapidfab/selectors/helpers/base';

export const getBureaus = createSelector(
  [baseStateSelectors.getStateBureaus, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getBureausCurrentUserRoles = createSelector(
  [getRolesCurrentUser, getBureaus],
  (roles, bureaus) => {
    const roleBureaus = new Set();

    roles.forEach(role => {
      roleBureaus.add(bureaus.find(bureau => bureau.uri === role.bureau));
    });

    return roleBureaus;
  },
);

export const getBureau = createSelector(
  [getBureausCurrentUserRoles],
  bureaus => {
    if (!(bureaus && bureaus.size)) {
      return null;
    }
    return [...bureaus][0];
  },
);

export const getBureauUri = createSelector([getBureau], bureau => bureau?.uri);

export const getBureauSettings = createSelector(
  [baseStateSelectors.getStateBureauSettings, getStateResources, getBureauUri],
  (uuids, resources, bureauUri) =>
    _find(
      _map(uuids, uuid => resources[uuid]), { bureau: bureauUri },
    ),
);

export const getBureauIntakeSettings = createSelector(
  [baseStateSelectors.getStateBureauIntakeSettings, getStateResources, getBureauUri],
  (uuids, resources, bureauUri) =>
    _find(
      _map(uuids, uuid => resources[uuid]), { bureau: bureauUri },
    ),
);

export const getInitialValuesBureau = createSelector(
  [getRouteUUID, getStateResources, getBureau],
  (uuid, resources, bureau) => {
    const resource = resources[uuid] || {};
    if (!resource.bureau) {
      resource.bureau = bureau?.uri;
    }
    return resource;
  },
);
