import { navbarJA } from './locales/navbar/index';
import { recordsJA } from './locales/records/index';
import { mappingsJA } from './locales/mappings/index';
import { componentsJA } from './locales/components/index';

import { planJA } from './locales/plan/index';
import adminJA from './locales/admin/ja.json';
import workJA from './locales/work/ja.json';
import inventoryJA from './locales/inventory/ja.json';
import manageJA from './locales/manage/ja.json';
import mfg from './locales/mfg.json';
import toasterJA from './locales/toaster/ja.json';
import restJA from './locales/rest/ja.json';

const translation = Object.assign(
  adminJA,
  navbarJA,
  planJA,
  workJA,
  inventoryJA,
  manageJA,
  recordsJA,
  mappingsJA,
  componentsJA,
  mfg,
  toasterJA,
  restJA,
);

export default translation;
