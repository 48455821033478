import React, { useContext } from 'react';
import { Button, ButtonToolbar, Card, Container, Form as BsForm, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import GuidelineIfBlock from 'rapidfab/components/GuidelinesEngine/GuidelineIfBlock';
import GuidelineThenBlock from 'rapidfab/components/GuidelinesEngine/GuidelineThenBlock';
import GuidelineSummaryBlock from 'rapidfab/components/GuidelinesEngine/GuidelineSummaryBlock';
import { Form, Field } from 'react-final-form';
import FormRow from 'rapidfab/components/FormRow';
import { finalFormInputTypes } from 'rapidfab/types';
import GuidelineContext from 'rapidfab/context/GuidelineContext';
import Loading from 'rapidfab/components/Loading';

const Guideline = () => {
  const guidelineContext = useContext(GuidelineContext);

  const {
    uuid,
    onGuidelineSave,
    saving,
    dataForGuideline,
  } = guidelineContext;

  return (
    <Container fluid>
      <BreadcrumbNav breadcrumbs={['guidelinesEngine', uuid ? 'editGuideline' : 'newGuideline']} />
      <Form onSubmit={onGuidelineSave}>
        {({ handleSubmit }) => (
          <BsForm onSubmit={handleSubmit}>
            <h1>{uuid ? `Guideline (${getShortUUID(uuid)})` : 'Create New Guideline'}</h1>
            <ButtonToolbar className="clearfix">
              <div className="flexed-pull-right">
                {!uuid && (
                  <Button
                    disabled={saving}
                    variant="success"
                    onClick={handleSubmit}
                  >
                    {saving ? <Loading /> : 'Save Guideline'}
                  </Button>
                )}
              </div>
            </ButtonToolbar>
            <hr />
            <div className="d-flex justify-content-center w-100">
              <Card bg="dark w-75">
                <Card.Header className="pd-exp inverse">Guideline</Card.Header>
                <div className="card-body-wrapper">
                  <Card.Body>
                    <Card className="card-body-wrapper spacer-top" bg="dark">
                      <Card.Body>
                        <Field
                          name="name"
                          defaultValue={dataForGuideline && dataForGuideline.name}
                          render={props => (

                            <FormRow className="spacer-top form-group row" id="field.name" defaultMessage="Name">
                              <FormControl
                                {...props.input}
                                as="input"
                                placeholder="Name"
                                required
                              />
                            </FormRow>
                          )}
                        />
                        <Field
                          name="description"
                          defaultValue={dataForGuideline && dataForGuideline.description}
                          render={props => (
                            <FormRow className="spacer-top form-group row" id="field.description" defaultMessage="Description">
                              <FormControl
                                {...props.input}
                                as="textarea"
                                placeholder="Description"
                                required
                              />
                            </FormRow>
                          )}
                        />
                      </Card.Body>
                    </Card>
                    <Card className="card-body-wrapper spacer-top" bg="dark">
                      <Card.Header className="pd-exp inverse">IF (condition)</Card.Header>
                      <Card.Body>
                        <div className="spacer-top spacer-bottom">
                          <p>Set an if statement that will determine the condition for triggering the given rule.</p>
                        </div>
                        <GuidelineIfBlock />
                      </Card.Body>
                    </Card>
                    <Card className="card-body-wrapper spacer-top" bg="dark">
                      <Card.Header className="pd-exp inverse">THEN (execution)</Card.Header>
                      <Card.Body>
                        <div className="spacer-top spacer-bottom">
                          <p>Set a then statement that will execute based on the condition configured above.</p>
                        </div>
                        <GuidelineThenBlock />
                      </Card.Body>
                    </Card>
                    <Card className="card-body-wrapper spacer-top" bg="dark">
                      <Card.Header className="pd-exp inverse">Summary</Card.Header>
                      <Card.Body>
                        <GuidelineSummaryBlock />
                      </Card.Body>
                    </Card>
                    {!uuid && (
                      <Button
                        disabled={saving}
                        className="spacer-top pull-right my-2"
                        variant="success"
                        onClick={handleSubmit}
                      >
                        {saving ? <Loading /> : 'Save Guideline'}
                      </Button>
                    )}
                  </Card.Body>
                </div>
              </Card>
            </div>
          </BsForm>
        )}
      </Form>
    </Container>
  );
};

Guideline.propTypes = {
  guideline: PropTypes.shape({}).isRequired,
  initialIfConditionsState: PropTypes.shape({}).isRequired,
  ruleIfConditions: PropTypes.arrayOf(PropTypes.shape({
    selectedResourceType: PropTypes.string,
  })).isRequired,
  summary: PropTypes.string.isRequired,
  handleActionChange: PropTypes.func.isRequired,
  getResourceItemsByResourceType: PropTypes.func.isRequired,
  getRuleFiltersByResourceItem: PropTypes.func.isRequired,
  ruleActions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setRuleActions: PropTypes.func.isRequired,
  input: finalFormInputTypes.isRequired,
};

export default Guideline;
