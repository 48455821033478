import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonToolbar,
  Card, Image, ToggleButton, ToggleButtonGroup,
  Form, OverlayTrigger, Tooltip, // eslint-disable-line no-unused-vars
} from 'react-bootstrap';
import convertLengthToOtherUnit from 'rapidfab/utils/convertLengthToOtherUnit';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import ThreeScene from 'rapidfab/components/ThreeScene';
import Modal from 'react-modal';
import { MODEL_UNITS, API_RESOURCES } from 'rapidfab/constants';
import 'react-image-lightbox/style.css';
import './modalStyle.css';
import ReactSlider from 'react-slider';
import '../styles/componentStyles/react-slider.scss';
import { modelRotation } from 'rapidfab/actions/modelRotation';
import { connect } from 'react-redux';
import Actions from 'rapidfab/actions';
import Alert from 'rapidfab/utils/alert';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    background: '#EDEDED',
  },
  overlay: {
    position: 'fixed',
    zIndex: 100500, // must be greater than .show z-index
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
};

const buttonStyles = {
  display: 'flex',
  justifyContent: 'center',
  margin: '15px',
};

const loadingStyle = {
  zIndex: '5',
  height: '100%',
  width: '100%',
  position: 'fixed',
  backgroundColor: '#EDEDED',
};

const centerStyle = {
  margin: '0',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'grey',
};

const modalContentStyle = {
  display: 'flex',
};

const thumbnailWrapper = {
  position: 'relative',
  fontSize: '0.8em',
  color: '#000',
};

const iconWrapper = {
  position: 'absolute',
  top: '0',
  right: '0',
};

const iconStyle = {
  top: '0',
  right: '0',
  marginTop: '0.4em',
  marginRight: '0.4em',
  marginLeft: '0.4em',
};

const iconTextStyle = {
  fontWeight: 'bold',
  fontSize: '1.4em',
  top: '0',
  right: '0',
};

const mfgOrientationManualValueEntryBoxStyle = {
  padding: '0 5px',
  height: 20,
  width: 50,
};

const dimensionsLabelStyle = {
  backgroundColor: '#cfd2da',
  padding: '3px 5px',
  margin: 5,
  borderRadius: 3,
};

const ORIENTATION_AXES = [
  {
    label: 'X',
    value: 'theta_x',
    colour: 'green',
  },
  {
    label: 'Y',
    value: 'theta_y',
    colour: 'red',
  },
  {
    label: 'Z',
    value: 'theta_z',
    colour: 'blue',
  },
];

const NoModelUpload = () => (
  <Card>
    <div className="text-center">
      <FormattedMessage id="record.itar" defaultMessage="No Design Upload (ITAR)" />
    </div>
  </Card>
);

const Loading = () => (
  <Card>
    <div className="text-center">
      <Fa name="spinner" spin />{' '}
      <span />
      <FormattedMessage
        id="loading.thumbnail"
        defaultMessage="Rendering Thumbnail…"
      />
    </div>
  </Card>
);

const NoSnapshot = () => (
  <Card>
    <div className="text-center">
      <FormattedMessage
        id="record.snapshot.none"
        defaultMessage="No Snapshot"
      />
    </div>
  </Card>
);

const Error = () => (
  <Card>
    <div className="text-center">
      <FormattedMessage id="status.error" defaultMessage="Error" />
    </div>
  </Card>
);

const DimensionsLabel = ({ value, unit, cubed = false }) => (
  <b style={dimensionsLabelStyle}>{value} {unit}{cubed && <sup>3</sup>}</b>
);

DimensionsLabel.defaultProps = {
  cubed: false,
};

DimensionsLabel.propTypes = {
  value: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  cubed: PropTypes.bool,
};

const OrientationSlider = ({
  disabled,
  rotation,
  originalRotation,
  axis,
  onChange,
  onManualValueEnterBlur,
  onResetSingleAxis,
  isSnapToAxis,
  color = 'auto',
}) => (
  <>
    <ReactSlider
      disabled={disabled}
      max={180}
      min={-180}
      className="horizontal-slider"
      thumbClassName="slider-thumb"
      trackClassName="slider-track"
      defaultValue={originalRotation?.[axis] ?? 0}
      value={rotation?.[axis] ?? 0}
      onChange={onChange}
      step={isSnapToAxis ? 90 : 1}
    />
    <p
      className={`${(disabled && !rotation?.[axis]) && 'moveFreeAxes'} single-line`}
      suppressContentEditableWarning
      contentEditable={!disabled}
      onBlur={onManualValueEnterBlur}
      role="presentation"
      onInput={event => {
        // Check the value entered isn't over 3 in string length.
        if (event.currentTarget.textContent.length > 3) event.preventDefault();
      }}
      style={{
        ...mfgOrientationManualValueEntryBoxStyle,
        backgroundColor: '#fff',
        color: '#555',
      }}
    >
      {(disabled && rotation?.[axis] === null) ? <span><i>null</i></span> : rotation?.[axis]}
    </p>
    {
      (rotation?.[axis] || !disabled) ? (
        <div className="spacer-left">
          <span>&#176;</span>
        </div>
      ) : null
    }

    <Fa
      style={{ marginLeft: 5, color }}
      name="undo"
      onClick={onResetSingleAxis}
    />
  </>
);

OrientationSlider.defaultProps = {
  color: 'auto',
};

OrientationSlider.propTypes = {
  disabled: PropTypes.bool.isRequired,
  lockedAxes: PropTypes.shape({}).isRequired,
  rotation: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    z: PropTypes.number,
  }).isRequired,
  originalRotation: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    z: PropTypes.number,
  }).isRequired,
  axis: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onManualValueEnterBlur: PropTypes.func.isRequired,
  onResetSingleAxis: PropTypes.func.isRequired,
  isSnapToAxis: PropTypes.bool.isRequired,
  color: PropTypes.string,
};

class ModalThreeScene extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      mode: 'solid',
      isLoading: true,
      rotation: {
        theta_x: this.props.rotation.theta_x ?? null,
        theta_y: this.props.rotation.theta_y ?? null,
        theta_z: this.props.rotation.theta_z ?? null,
      },
      isManufacturingOrientationEditMode: false,
      lockedAxes: {
        theta_x: false,
        theta_y: false,
        theta_z: false,
      },
      isSnapToAxis: true,
      isSavingModelRotation: false,
      isChangingRotation: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleModeChange = this.handleModeChange.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.renderModalThreeScene = this.renderModalThreeScene.bind(this);
  }

  componentDidUpdate() {
    const originalLockedAxes = {
      theta_x: !!this.props.originalRotation?.theta_x,
      theta_y: !!this.props.originalRotation?.theta_y,
      theta_z: !!this.props.originalRotation?.theta_z,
    };
    /* If the "LockedAxes" value is the same as originalLockedAxes
    OR this.state.rotation is the same as the Original Rotation (in other words
     no changes made to locking axes or to changing the values) -> we will set
     the isChangingRotation value to false and "Save" button will be disabled. */
    if (((
      JSON.stringify(this.state.rotation) === JSON.stringify(this.props.originalRotation))
      && (JSON.stringify(this.state.lockedAxes) === JSON.stringify(originalLockedAxes)))
      && this.state.isChangingRotation) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isChangingRotation: false,
      });
    }
  }

  handleModeChange(event) {
    this.setState({ mode: event.target.value });
  }

  onLoad() {
    this.setState({ isLoading: false });
  }

  setAxisRotation(value, axis, rotation) {
    // const value = inputtedValue.split(' ')[0];
    // Check the valued entered IS numeric, otherwise reset to zero.
    if (!/^-?\d+(\.\d{1,2})?$/.test(value)) return this.setState({ rotation: { ...rotation, [axis]: 0 } });
    if (
      Number.parseFloat(value) > 180 ||
      Number.parseFloat(value) < -180
    ) return this.setState({ rotation: { ...rotation, [axis]: 0 } });
    return this.setState({ rotation: { ...rotation, [axis]: Number.parseFloat(value) } });
  }

  toggleAxisAsLocked(axis, lockedAxes, isManufacturingOrientationEditMode) {
    if (isManufacturingOrientationEditMode) {
      /* We should trigger ChangeRotation so the "Save" button to be enabled
      (if the axis values are not the same as original locked values)  */
      this.setState({ lockedAxes: { ...lockedAxes, [axis]: !lockedAxes[axis] }, isChangingRotation: true });
    }
  }

  openModal(event) {
    const { originalRotation } = this.props;

    this.setState({
      isOpen: true,
      isLoading: true,
      isManufacturingOrientationEditMode: false,
      rotation: originalRotation,
      /* All axes should be locked by default see: [sc-45442] */
      lockedAxes: {
        theta_x: true,
        theta_y: true,
        theta_z: true,
      },
      isSavingModelRotation: false,
      isChangingRotation: false,
    });
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    document.body.style.overflow = 'hidden';
  }

  closeModal() {
    this.setState({ isOpen: false });
    document.body.removeAttribute('style');
  }

  renderModalThreeScene() {
    const {
      isOpen,
      mode,
      isLoading,
      rotation,
      lockedAxes,
      isManufacturingOrientationEditMode,
      isSnapToAxis,
      isSavingModelRotation,
    } = this.state;
    const {
      model,
      snapshot,
      unit,
      fileUnit,
      size,
      volume,
      isZverseFileUploaded,
      setGlobalModelRotation,
      saveModelRotation,
      isHawkingUser,
      uuid,
      originalRotation,
      showMfgOrientationPanel = true,
    } = this.props;

    const conversionMultiplier = convertLengthToOtherUnit(1, fileUnit, unit);
    let totalVolume = volume; // Always in MM or null

    if (totalVolume) {
      // Volume is null when model data is not loaded yet
      if (unit === MODEL_UNITS.INCHES) {
        // transform mm to inches
        // 1 in^3 = 16387.064 mm^3
        const INCHES_TO_MM_COEFFICIENT = 16387.064;
        totalVolume /= INCHES_TO_MM_COEFFICIENT;
      }

      totalVolume = Math.round(totalVolume);
    }

    if (isZverseFileUploaded) {
      return (
        <div style={thumbnailWrapper}>
          {isHawkingUser ? (
            <img src={snapshot} alt="2d-preview" width="100%" height={295} />
          )
            :
            <Image className="img-thumbnail" src={snapshot} />}
        </div>
      );
    }

    const shouldSaveModelRotation = rotation &&
    /* Try to find a value in x, y and z that is not null,
    does one exist? */
      /* Additionally, we should save the changes if some actions on rotation change were made */
      (Object.values(rotation).every(axis => axis !== null))
    &&
    /* AND, is the rotation (local state) different from the
    stored manufacturing orientation? */
    rotation !== originalRotation;

    const roundToNearestMultiple = (number, multiple = 90) => (number / multiple).toFixed() * multiple;

    const handleManualValueEnterBlur = (event, axis, currentRotation) => {
      this.setState({ isChangingRotation: true });
      this.setAxisRotation(event.currentTarget.textContent, axis, currentRotation);
    };

    /* Manually reset all the Axis values */
    const onResetAxisRotation = () => this.setState({
      rotation: {
        theta_x: 0,
        theta_y: 0,
        theta_z: 0,
        status: 'uploaded',
      },
      isChangingRotation: true,
    });

    const handleDiscardChanges = () => {
      this.setState(
        { rotation: originalRotation,
          isChangingRotation: false,
          isManufacturingOrientationEditMode: false,
          lockedAxes: {
            theta_x: !!this.props.originalRotation?.theta_x,
            theta_y: !!this.props.originalRotation?.theta_y,
            theta_z: !!this.props.originalRotation?.theta_z,
          } });
    };

    const handleDeleteOrientation = () => {
      saveModelRotation(
        { theta_x: null, theta_y: null, theta_z: null, status: null },
        uuid,
        () => this.closeModal(),
        value => this.setState({ isSavingModelRotation: value }),
      );
    };

    return (
      <div>
        <button
          type="button"
          className="modalStyle"
          onClick={this.openModal}
          style={{ ...thumbnailWrapper, width: isHawkingUser && '100%' }}
        >

          {snapshot ?
            (isHawkingUser ? <img src={snapshot} alt="2d-preview" width="100%" height={295} /> : <Image className="img-thumbnail" src={snapshot} />) :
            <Fa style={centerStyle} name="spinner" size="lg" spin />}
          <div style={iconWrapper}>
            <div className="d-flex">
              <p style={iconTextStyle}>3D</p>
              <Fa style={iconStyle} name="arrows-alt" size="lg" />
            </div>
          </div>
        </button>

        {isOpen && (
          <Modal
            appElement={document.querySelector('#app')}
            // className="show"
            isOpen={this.state.isOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            backdrop="static"
          >
            {isLoading && (
              <div style={loadingStyle} className="text-center">
                <Fa style={centerStyle} name="spinner" size="lg" spin />
              </div>
            )}
            <div>
              <ButtonToolbar style={buttonStyles}>
                <ToggleButtonGroup
                  name="options"
                  type="radio"
                  size="sm"
                  value={mode}
                >
                  <ToggleButton
                    id="solid"
                    variant={isHawkingUser ? 'outline-secondary' : 'outline-success'}
                    value="solid"
                    onChange={this.handleModeChange}
                  >
                    Solid
                  </ToggleButton>
                  <ToggleButton
                    id="wireframe"
                    variant={isHawkingUser ? 'outline-secondary' : 'outline-success'}
                    value="wireframe"
                    onChange={this.handleModeChange}
                  >
                    Wireframe
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
              <div style={modalContentStyle}>
                <ThreeScene
                  model={model}
                  unit={unit}
                  fileUnit={fileUnit}
                  mode={mode}
                  onLoad={this.onLoad}
                  rotation={rotation}
                  onRotationChange={newRotation => this.setState({ rotation: newRotation })}
                  enableRotate={!isManufacturingOrientationEditMode}
                />
                {!isHawkingUser && (
                  <div>
                    <Card bg="light" className="panel-light mr15" style={{ maxWidth: '330px' }}>
                      <Card.Header>Model Information</Card.Header>
                      <Card.Body>
                        <Card bg="light">
                          <Card.Header>Dimensions</Card.Header>
                          <Card.Body>
                            <div className="d-flex">
                              {
                                ORIENTATION_AXES.map(axis => (
                                  <div key={axis.value} className="spacer-right">
                                    <p>
                                      <b>{axis.label}: </b>
                                      <DimensionsLabel
                                        value={Math.round(size[axis.value.slice(-1)] * conversionMultiplier)}
                                        unit={unit}
                                      />
                                    </p>
                                  </div>
                                ))
                              }
                            </div>
                            <div style={{ marginTop: 10 }}>
                              <b>Total:</b>
                              <DimensionsLabel
                                value={`${totalVolume}`}
                                unit={unit}
                                cubed
                              />
                            </div>
                          </Card.Body>
                        </Card>
                        {
                          !isHawkingUser && showMfgOrientationPanel && (
                            <Card className="mt15" bg="light">
                              <Card.Header>Manufacturing Orientation</Card.Header>
                              <Card.Body>
                                { /* eslint-disable-next-line no-trailing-spaces */ }
                                <p>Rotate the model about its x, y, or z axes in preparation for manufacturing. These
                                  orientation changes will be applied to the model when creating a Print Run Build File.
                                </p>
                                {
                                  ORIENTATION_AXES.map(axis => {
                                    const { value: axisValue, label, colour } = axis;
                                    return (
                                      <div key={axisValue} style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                        <Fa name="circle" color={colour} style={{ marginRight: 5, color: colour }} /> <b>{label}</b>
                                        <OrientationSlider
                                          axis={axisValue}
                                          lockedAxes={lockedAxes}
                                          onChange={value =>
                                            this.setState(
                                              { rotation: { ...rotation, [axisValue]: value /* || null */ },
                                                isChangingRotation: true })}
                                          onLockAxis={() => {
                                            this.toggleAxisAsLocked(
                                              axisValue,
                                              lockedAxes,
                                              isManufacturingOrientationEditMode,
                                            );
                                          }}
                                          onManualValueEnterBlur={event =>
                                            handleManualValueEnterBlur(event, axisValue, rotation)}
                                          onResetSingleAxis={() =>
                                            this.setAxisRotation(originalRotation[axisValue], axisValue, rotation)}
                                          disabled={!isManufacturingOrientationEditMode}
                                          rotation={rotation}
                                          originalRotation={originalRotation}
                                          isSnapToAxis={isSnapToAxis}
                                          color={isManufacturingOrientationEditMode ? '#555555' : '#C0C0C0'}
                                        />
                                      </div>
                                    );
                                  },
                                  )
                                }
                                <div className="d-flex align-items-center justify-content-between">
                                  <Form>
                                    <Form.Check
                                      bg="light"
                                      label="Snap to Axes"
                                      type="checkbox"
                                      inline
                                      className="mt15 mb-15"
                                      checked={isSnapToAxis}
                                      onChange={() => {
                                        const { theta_y, theta_x, theta_z } = rotation;
                                        // Snap to the nearest upper bound multiple of 90.
                                        this.setState({
                                          isSnapToAxis: !isSnapToAxis,
                                          rotation: {
                                            theta_x: lockedAxes.theta_x ? theta_x : roundToNearestMultiple(theta_x),
                                            theta_y: lockedAxes.theta_y ? theta_y : roundToNearestMultiple(theta_y),
                                            theta_z: lockedAxes.theta_z ? theta_z : roundToNearestMultiple(theta_z),
                                          },
                                        });
                                      }}
                                    />
                                  </Form>
                                  <div className="d-flex align-items-center mt15">
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={(
                                        <Tooltip>
                                          Reset all Axes to 0°
                                        </Tooltip>
                                      )}
                                    >
                                      <Button
                                        variant="link"
                                        disabled={!isManufacturingOrientationEditMode || Object.values(rotation).every(axis => !axis || axis === 'uploaded')}
                                      >
                                        <Fa
                                          style={{ marginRight: '5px', fontSize: '16px' }}
                                          name="undo"
                                          onClick={onResetAxisRotation}
                                        />
                                      </Button>

                                    </OverlayTrigger>
                                  </div>

                                </div>

                                <div style={{ marginTop: 20 }}>
                                  {this.state.isManufacturingOrientationEditMode ? (
                                    <Button
                                      disabled={isSavingModelRotation}
                                      variant="success"
                                      className="pull-right"
                                      style={{ marginLeft: 5 }}
                                      onClick={() => {
                                        setGlobalModelRotation(rotation, uuid);

                                        // Send PUT request
                                        if (shouldSaveModelRotation) {
                                          saveModelRotation(
                                            rotation,
                                            uuid,
                                            () => this.closeModal(),
                                            value => this.setState({ isSavingModelRotation: value }),
                                          );
                                        } else this.closeModal();
                                      }}
                                    >
                                      Save
                                    </Button>
                                  ) :
                                    (
                                      <Button
                                        onClick={() => {
                                          this.setState({
                                            rotation: {
                                              theta_x: originalRotation.theta_x ?? 0,
                                              theta_y: originalRotation.theta_y ?? 0,
                                              theta_z: originalRotation.theta_z ?? 0,
                                            },
                                            isManufacturingOrientationEditMode: true,
                                          });
                                        }}
                                        style={{ marginLeft: 5 }}
                                        bsStyle="primary"
                                        className="pull-right"
                                      >
                                        Edit
                                      </Button>
                                    )}
                                  <Button
                                    style={{ marginLeft: 5 }}
                                    disabled={!isManufacturingOrientationEditMode || isSavingModelRotation}
                                    className="pull-right"
                                    onClick={handleDiscardChanges}
                                  >
                                    Discard
                                  </Button>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={(
                                      <Tooltip>
                                        <p>test</p>
                                      </Tooltip>
                                    )}
                                  >
                                    <Button
                                      variant="danger"
                                      style={{ marginLeft: 5 }}
                                      disabled={!isManufacturingOrientationEditMode || isSavingModelRotation}
                                      className="pull-right"
                                      onClick={handleDeleteOrientation}
                                    >
                                      Delete
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                              </Card.Body>
                            </Card>
                          )
                        }
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }

  render() {
    const { snapshot } = this.props;

    switch (snapshot) {
      case 'ERROR':
        return <Error />;

      case 'NO_MODEL_UPLOAD':
        return <NoModelUpload />;

      case 'LOADING':
        return <Loading />;

      case 'NO_SNAPSHOT':
        return <NoSnapshot />;

      default:
        return this.renderModalThreeScene();
    }
  }
}

ModalThreeScene.defaultProps = {
  volume: null,
  isZverseFileUploaded: false,
  originalRotation: null,
  setGlobalModelRotation: null,
  showMfgOrientationPanel: true,
  rotation: {
    theta_x: 0,
    theta_y: 0,
    theta_z: 0,
  },
};

ModalThreeScene.propTypes = {
  model: PropTypes.string.isRequired,
  snapshot: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  fileUnit: PropTypes.string.isRequired,
  size: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    z: PropTypes.number,
  }).isRequired,
  rotation: PropTypes.shape({
    theta_x: PropTypes.number,
    theta_y: PropTypes.number,
    theta_z: PropTypes.number,
  }),
  originalRotation: PropTypes.shape({
    theta_x: PropTypes.number,
    theta_y: PropTypes.number,
    theta_z: PropTypes.number,
  }),
  volume: PropTypes.number,
  isZverseFileUploaded: PropTypes.bool,
  setGlobalModelRotation: PropTypes.func,
  isHawkingUser: PropTypes.bool.isRequired,
  uuid: PropTypes.string.isRequired,
  saveModelRotation: PropTypes.func.isRequired,
  showMfgOrientationPanel: PropTypes.bool,
};

const mapDispatchToProps = dispatch => ({
  saveModelRotation: (
    rotation,
    uuid,
    closeModal,
    setIsSavingModelRotation,
  ) => {
    setIsSavingModelRotation(true);
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.MODEL].put(uuid, {
        manufacturing_orientation: {
          theta_x: rotation.theta_x,
          theta_y: rotation.theta_y,
          theta_z: rotation.theta_z,
        },
      }))
      .then(() => Alert.success(
        <FormattedMessage
          id="toaster.rotationSuccessfullySaved"
          defaultMessage="Rotation successfully saved."
        />,
      ))
      .then(() => setIsSavingModelRotation(false))
      .finally(closeModal);
  },
  setGlobalModelRotation: (rotation, uuid) => {
    dispatch(modelRotation(rotation, uuid));
  },
});

export default connect(null, mapDispatchToProps)(ModalThreeScene);
