import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Actions from 'rapidfab/actions';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import * as Selectors from 'rapidfab/selectors';

import Loading from 'rapidfab/components/Loading';
import NewOrder from 'rapidfab/components/records/order/new/NewOrder';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { ROUTES, PAGINATION_IGNORE_DEFAULT_LIMIT, API_RESOURCES } from 'rapidfab/constants';
import { useNavigate } from 'react-router-dom';

const NewOrderContainer = props => {
  const bureau = useSelector(Selectors.getBureau);
  const fetching = useSelector(state => isFetchingInitial(
    state.ui.nautilus[API_RESOURCES.MATERIAL].list,
    state.ui.nautilus[API_RESOURCES.WORKFLOW].list,
    state.ui.nautilus[API_RESOURCES.SERVICE_PROVIDER].list,
  ));
  const shippings = useSelector(Selectors.getShippingsAlphabetized);
  const locations = useSelector(Selectors.getLocations);

  const isRestrictedUser = useSelector(Selectors.isCurrentUserRestricted);

  const selected = {
    shippings,
    locations,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onInitialize = currentBureau => {
    const { uri } = currentBureau;
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: uri }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER]
      .list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
  };

  useEffect(() => onInitialize(bureau), []);

  // For restricted user it's another endpoint and another form
  if (!fetching && isRestrictedUser) {
    navigate(getRouteURI(ROUTES.ORDER_RESTRICTED_NEW, {}, {}, true));
  }

  return (
    <div>
      {fetching ? (
        <Loading />
      ) : (
        <div>
          <NewOrder
            {...props}
            {...selected}
          />
        </div>
      )}
    </div>
  );
};

export default NewOrderContainer;
