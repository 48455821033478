import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import Fa from 'react-fontawesome';
import WorkflowChecklistsModalContainer from 'rapidfab/containers/modals/WorkflowChecklistsModalContainer';
import { ACCESS_INFO_ACTION_TYPES } from 'rapidfab/constants';
import DisabledByAccessInfoCheck from 'rapidfab/components/DisabledByAccessInfoCheck';

const WorkPlanViewModal = props => {
  const {
    onClose,
    isExistsChecklistLinkingGroup,
    onOpenWorkInstructions,
    onOpenCustomizeWorkflow,
    workflowUri,
    lineItemUri,
    previewOnly,
  } = props;

  return (
    <WorkflowChecklistsModalContainer
      {...props}
      workflowUri={workflowUri}
      lineItemUri={lineItemUri}
      onClose={onClose}
    >
      {!previewOnly && (
        <div className="clearfix">
          <div className="pull-right d-flex align-items-center">
            <DisabledByAccessInfoCheck
              resourceUri={workflowUri}
              actionType={ACCESS_INFO_ACTION_TYPES.EDIT}
              renderDisabledPrefix
            >
              {({ disabled }) => (
                <>
                  <Button
                    variant="info"
                    className="mr15"
                    style={{ padding: '5px' }}
                    onClick={onOpenCustomizeWorkflow}
                    disabled={disabled}
                  >
                    <Fa name="pencil" />{' '}
                    Customize Line Item Production Workflow
                  </Button>
                  <Button
                    style={{ padding: '5px' }}
                    variant={isExistsChecklistLinkingGroup ? 'primary' : 'success'}
                    onClick={onOpenWorkInstructions}
                    disabled={disabled}
                  >
                    {isExistsChecklistLinkingGroup ? (
                      <>
                        <Fa name="edit" />{' '}
                        <FormattedMessage
                          id="button.editWorkInstructions"
                          defaultMessage="Edit Work Instructions"
                        />
                      </>
                    ) : (
                      <FormattedMessage
                        id="button.create"
                        defaultMessage="+ Create Work Instructions"
                      />
                    )}
                  </Button>
                </>
              )}
            </DisabledByAccessInfoCheck>
          </div>
        </div>
      )}
    </WorkflowChecklistsModalContainer>
  );
};

WorkPlanViewModal.defaultProps = {
  previewOnly: false,
};

WorkPlanViewModal.propTypes = {
  lineItemUri: PropTypes.string.isRequired,
  workflowUri: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isExistsChecklistLinkingGroup: PropTypes.bool.isRequired,
  onOpenWorkInstructions: PropTypes.func.isRequired,
  onOpenCustomizeWorkflow: PropTypes.func.isRequired,
  previewOnly: PropTypes.bool,
};

export default WorkPlanViewModal;
