import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import hhmmss from 'rapidfab/utils/hhmmss';
import { TEXT_COLOR_CONTRAST } from 'rapidfab/constants';
import { RUN_OPERATION_MAP, RUN_STATUS_COLOR_MAP } from 'rapidfab/mappings';
import _startCase from 'lodash/startCase';
import _isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';

const RunRecordHeader = ({
  name,
  estimations,
  estimations: { start, end },
  printCount,
  status,
  operation,
}) => {
  const startMoment = dayjs(start);
  const endMoment = dayjs(end);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const estimatedRunTime = !_isEmpty(estimations) && useMemo(
    () => hhmmss(endMoment.diff(startMoment, 'seconds')), [start, end],
  );
  const labelBgColor = RUN_STATUS_COLOR_MAP[status];
  const textColor = TEXT_COLOR_CONTRAST[labelBgColor];
  const estimationText = `Estimated Run Time: ${estimatedRunTime || '-'} / `;

  return (
    <div className="page-header">
      <h3>
        <div className="page-header-flex">
          <div className="page-header-flex-title">
            <div>
              {operation && (
                <UseNonMfgLanguageFeature
                  mfgLanguageComponent={(
                    <FormattedMessage
                      id={RUN_OPERATION_MAP[operation].id}
                      defaultMessage={RUN_OPERATION_MAP[operation].defaultMessage}
                    />
                  )}
                  nonMfgLanguageComponent={(
                    <FormattedMessage
                      id="mfg.printing.schedule"
                      defaultMessage="Schedule"
                    />
                  )}
                />
              )} Run:<b className="spacer-left wrap-text">{name || '-'}</b>
            </div>
            <h5>
              ({`${estimationText}Pieces Count: ${printCount || '-'}`})
            </h5>
          </div>
          <div className="d-flex spacer-top">
            <Badge style={{ color: textColor }} ref={element => element && element.style.setProperty('background-color', labelBgColor, 'important')}>
              {_startCase(status)}
            </Badge>
          </div>
        </div>
      </h3>
    </div>
  );
};

RunRecordHeader.defaultProps = {
  name: null,
  status: null,
  printCount: null,
  operation: null,
  estimations: {},
};

RunRecordHeader.propTypes = {
  operation: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  printCount: PropTypes.number,
  estimations: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
};

export default RunRecordHeader;
