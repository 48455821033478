import { createSelector } from 'reselect';
import _map from 'lodash/map';
import { getProcessSteps } from 'rapidfab/selectors/processStep';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import { getLineItemQuoteByLineItemUri } from 'rapidfab/selectors/legacy-selectors';

export const getLineItemQuoteProcessStepsForLineItem = createSelector(
  [getLineItemQuoteByLineItemUri, getProcessSteps],
  (lineItemQuote, processSteps) => {
    if (!lineItemQuote) {
      return [];
    }
    const lineItemProcessStepsUris = _map(lineItemQuote.work_steps_quote_details, 'process_step');
    const lineItemProcessSteps = processSteps.filter(step =>
      lineItemProcessStepsUris.includes(step.uri),
    );
    return _map(lineItemProcessSteps, lineItemProcessStep => {
      const workStepQuoteDetails = _find(
        lineItemQuote.work_steps_quote_details,
        { process_step: lineItemProcessStep.uri },
      );
      return {
        ...lineItemProcessStep,
        work_steps_quote_details: workStepQuoteDetails,
      };
    });
  },
);

export const getSeparateLineItemQuoteProcessStepsForLineItem = createSelector(
  [getLineItemQuoteProcessStepsForLineItem],
  processStepsForLineItem => _filter(
    processStepsForLineItem,
    processStep => processStep.work_steps_quote_details.separate,
  ),
);

export const getNonSeparateInQuoteLineItemQuoteProcessStepsForLineItem = createSelector(
  [getLineItemQuoteProcessStepsForLineItem],
  processSteps => _filter(
    processSteps,
    processStep =>
      !processStep.work_steps_quote_details.separate
      && processStep.work_steps_quote_details.in_quote,
  ),
);
