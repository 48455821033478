export const LOCATION_FILTER_DEFAULT_VALUES = {
  UNASSIGNED: 'unassigned',
  ALL: '',
};

export const SUB_LOCATION_FILTER_DEFAULT_VALUES = {
  ALL: '',
};

export const MODEL_LAYER_THICKNESS_SETTINGS = {
  MIN: 0.001,
  MAX: 1,
  DEFAULT: 0.2,
  HAWKING_DEFAULT: 0.24,
  THREEDPC_DEFAULT: 0.1,
  STEP: 0.001,
};

export const MATERIAL_REASON_CODE_OPTIONS = {
  INVALID_RECEIPTS: 'Invalid_receipts',
  ITEM_SUBSTITUTION: 'item_substitution',
  UOM_DISCREPANCY: 'uom_discrepancy',
  CYCLE_COUNT: 'cycle_count',
  CANNOT_BE_DETERMINED: 'cannot_be_determined',
  OVERSHIPMENT_ADJUSTMENT: 'overshipment_adjustment',
  DAMAGED_TRANSIT: 'damaged_transit',
  DAMAGED_WAREHOUSE: 'damaged_warehouse',
  PHYSICAL_COUNT: 'physical_count',
  DATA_ENTRY_ERROR: 'data_entry_error',
  SCRAP: 'scrap',
};

export const MANUFACTURING_PROCESSES = [
  'Binder Jet',
  'SLA', // Stereolithography.
  'SLS', // Selective laser sintering.
  'FDM', // Fused Deposition Modeling.
  'Polyjet',
  'Polyjet Anatomy',
  'EBM', // Electron Beam Manufacturing.
  'MJF', // HP version of polyjet.
  'DLP', // Digital Light Projection.
  'CLIP', // Continuous Liquid Interface Production.
  'DMLS', // Direct Metal Laser Sintering.
  'SDL', // Selective Deposits Layer, paper and glue
];

export const LOCATION_NOTIFICATION_SETTING_BOOLS = {
  ORDER_CREATED: 'notification_order_created',
  LINE_ITEMS_CONFIRMED: 'notification_line_items_confirmed',
  ORDER_STARTED_PRINTING: 'notification_order_started_printing',
  ORDER_SCHEDULED: 'notification_order_scheduled',
  PRINT_ERROR: 'notification_print_error',
  ORDER_COMPLETED: 'notification_order_completed',
};

export const SHIPMENT_ADDRESS_TYPES = {
  RECIPIENT_ADDRESS: 'recipient_address',
  BILLING_ADDRESS: 'billing_address',
  ORIGIN_ADDRESS: 'origin_address',
};

export const ORDER_SHIPPING_GROUPING_OPTIONS = {
  BY_BATCHING_STRATEGY: 'by_batching_strategy',
  BY_ORDER: 'by_order',
};

export const WORK_INSTRUCTION_REPORT_TYPES = {
  NO_ENTRY: 'no entry',
  CHECKBOX: 'checkbox',
  DOCUMENT: 'document',
  PICTURE: 'picture',
  NUMBER: 'number',
  RANGE: 'range',
  TEXT: 'text',
  SINGLE_SELECT_DROPDOWN: 'single-selection-dropdown',
};

export const WORK_INSTRUCTION_THRESHOLD_TYPES = {
  BETWEEN: 'between',
  NOT_BETWEEN: 'not-between',
  TOLERANCE: 'tolerance',
  LESS_THAN: 'less-than',
  LESS_THAN_OR_EQUALS_TO: 'less-than-or-equals-to',
  GREATER_THAN: 'greater-than',
  GREATER_THAN_OR_EQUALS_TO: 'greater-than-or-equals-to',
  EQUALS_TO: 'equals-to',
  NOT_EQUALS_TO: 'not-equals-to',
  CONTAINS: 'contains',
  NOT_CONTAINS: 'not-contains',
  NONE: 'None',
};

export const WORK_INSTRUCTION_THRESHOLD_TYPES_BY_WORK_INSTRUCTION_TYPE = {
  [WORK_INSTRUCTION_REPORT_TYPES.NO_ENTRY]: [],
  [WORK_INSTRUCTION_REPORT_TYPES.CHECKBOX]: [],
  [WORK_INSTRUCTION_REPORT_TYPES.DOCUMENT]: [],
  [WORK_INSTRUCTION_REPORT_TYPES.PICTURE]: [],
  [WORK_INSTRUCTION_REPORT_TYPES.NUMBER]: [
    WORK_INSTRUCTION_THRESHOLD_TYPES.BETWEEN,
    WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_BETWEEN,
    WORK_INSTRUCTION_THRESHOLD_TYPES.TOLERANCE,
    WORK_INSTRUCTION_THRESHOLD_TYPES.LESS_THAN,
    WORK_INSTRUCTION_THRESHOLD_TYPES.LESS_THAN_OR_EQUALS_TO,
    WORK_INSTRUCTION_THRESHOLD_TYPES.GREATER_THAN,
    WORK_INSTRUCTION_THRESHOLD_TYPES.GREATER_THAN_OR_EQUALS_TO,
    WORK_INSTRUCTION_THRESHOLD_TYPES.EQUALS_TO,
    WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_EQUALS_TO,
  ],
  [WORK_INSTRUCTION_REPORT_TYPES.RANGE]: [],
  [WORK_INSTRUCTION_REPORT_TYPES.TEXT]: [
    WORK_INSTRUCTION_THRESHOLD_TYPES.CONTAINS,
    WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_CONTAINS,
    WORK_INSTRUCTION_THRESHOLD_TYPES.EQUALS_TO,
    WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_EQUALS_TO,
  ],
  [WORK_INSTRUCTION_REPORT_TYPES.SINGLE_SELECT_DROPDOWN]: [],
};

export const PRODUCT_MODEL_FILE_SOURCES = {
  I_CONNECT_ACCESS: 'iConnect Access',
  WEB_PRINT: 'Web Print',
  OTHER: 'Other',
};

export const CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS = {
  PRINTABLE: 'printable',
  NOT_PRINTABLE: 'not-printable',
  PRINTABLE_WITH_SUPPORT: 'printable-with-supports',
};

export const USER_HIDE_INFO_TYPES = {
  FINANCIAL: 'financial',
};

export const EXTERNAL_PRINT_ANALYSIS_VENDOR = {
  NEBUMIND: 'nebumind',
};

export const QUOTE_PREVIEW_MODAL_FIELDS = {
  LABOR: 'Labor',
  WORKSTATION_TIME: 'Workstation Time',
  PIECE_OVERHEAD_COST: 'Piece Overhead Cost',
  RUN_OVERHEAD_COST: 'Run Overhead Cost',
};
