import _replace from 'lodash/replace';
import _forEach from 'lodash/forEach';
import { ROUTES } from 'rapidfab/constants';

export default function getRouteURI(route, params, queryParams, removeHashSymbol) {
  /* As we are using React Router 6, switching route with "#" symbol does not work
     and there is a string concat, for example:
      Current Route: /#/orders
      Desired Route: /#/runs
      Result: /#/orders/#/runs

      So, in case we are using old "window.location" (in class components), we need the "#" symbol
      to be applied, in case we are using React Router routing function as navigate ->
      we do not need it.

      ****As the result, added removeHashSymbol as an additional param taking into account we would
      continue using React Router instead of window.location.
      If you'd like to create RouteURI for navigate(), use removeHashSymbol = true as the last param**.

      */
  let selectedRoute = route;
  if (params) {
    _forEach(params, (value, key) => {
      selectedRoute = _replace(selectedRoute, `:${key}`, encodeURIComponent(value));
    });
  }

  if (queryParams) {
    const queryParamsString = new URLSearchParams(queryParams).toString();
    selectedRoute = `${selectedRoute}?${queryParamsString}`;
  }

  return removeHashSymbol ? selectedRoute : `#${selectedRoute}`;
}

export const routeURIContains = route => window.location.hash.includes(route);

export const getCustomDDWLibraryUri = (name, restricted) => {
  if (!name) {
    return null;
  }

  // Replace all non-alphanumeric characters (except spaces) with an empty string
  const stringWithoutSymbols = name.replace(/[^\d\sA-Za-z]/g, '');

  // Replace all spaces with hyphens
  const stringWithHyphens = stringWithoutSymbols.replace(/\s+/g, '-');

  const path = restricted ?
    ROUTES.DIGITAL_DESIGN_WAREHOUSE_RESTRICTED :
    ROUTES.DIGITAL_DESIGN_WAREHOUSE;
  return `${path}/${stringWithHyphens
    .toLowerCase()}`;
};
