import React from 'react';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';
import _sortBy from 'lodash/sortBy';
import _last from 'lodash/last';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { RUN_STATUSES } from '../../../constants';
import Tooltip from '../../Tooltip';

const ICON_COLORS = {
  WHITE: '#ffffff',
  ORANGE: '#ff9902',
  GREEN: '#009F10',
  RED: '#CF2A27',
};

const ICON_NAMES = {
  QUESTION: 'question-circle',
  ARROW_RIGHT: 'arrow-circle-right',
  CALENDAR_X: 'calendar-times-o',
  CALENDAR_CHECKED: 'calendar-check-o',
};

const OrderProgressTooltip = ({
  runs,
  orderRunsEstimates,
  piecesCount,
  piecesScheduledCount,
  orderDueDate,
  trigger,
}) => {
  const intl = useIntl();
  const runsCompleteCount = runs.filter(run => run.status === RUN_STATUSES.COMPLETE).length;
  const runsCount = runs ? runs.length : 0;
  const runsAfterOrderDueDate = runs.filter(run => run.finish && dayjs(run.finish) > dayjs(orderDueDate));
  let iconColor;
  let iconName;
  let tooltipMessage;
  if (runsCount === 0) {
    iconColor = ICON_COLORS.WHITE;
    iconName = ICON_NAMES.QUESTION;
    tooltipMessage = intl.formatMessage({
      id: 'orderProgress.notAllConfirmed',
      defaultMessage: 'Not all confirmed',
    });
  } else if (piecesScheduledCount < piecesCount) {
    iconColor = ICON_COLORS.ORANGE;
    iconName = ICON_NAMES.QUESTION;
    tooltipMessage = intl.formatMessage({
      id: 'orderProgress.notAllScheduled',
      defaultMessage: 'Not all scheduled',
    });
  } else if (piecesScheduledCount === piecesCount && runsCompleteCount < runsCount) {
    iconName = ICON_NAMES.ARROW_RIGHT;
    iconColor = ICON_COLORS.GREEN;
    const lastRun = _last(_sortBy(runs, run => new Date(run.updated).getTime()));
    tooltipMessage = intl.formatMessage(
      {
        id: 'orderProgress.lastRunUpdate',
        defaultMessage: 'Last Run Update {datetime}',
      },
      {
        datetime: dayjs(lastRun.updated)
          .format('YYYY-MM-DD of hh:mm'),
      },
    );
    if (runsAfterOrderDueDate.length > 0) {
      iconColor = ICON_COLORS.RED;
      const lastRunAfterDueDate = _last(_sortBy(runsAfterOrderDueDate, run => new Date(run.updated).getTime()));
      tooltipMessage = intl.formatMessage(
        {
          id: 'orderProgress.actualForRun',
          defaultMessage: 'Actual for Run {runName} Ends {date} after Order Due Date',
        },
        {
          runName: lastRunAfterDueDate.name,
          date: dayjs(lastRunAfterDueDate.updated)
            .format('YYYY-MM-DD'),
        },
      );
    } else {
      const runEstimate = orderRunsEstimates.find(re => dayjs(re.estimates.end) > dayjs(orderDueDate));
      if (runEstimate) {
        iconColor = ICON_COLORS.ORANGE;
        const runByRunEstimate = runs.find(run => run.uri === runEstimate.run);
        tooltipMessage = intl.formatMessage(
          {
            id: 'orderProgress.scheduleForRun',
            defaultMessage: 'Schedule for Run {runName} Ends {date} after Order Due Date',
          },
          {
            runName: runByRunEstimate.name,
            date: dayjs(runEstimate.estimates.end)
              .format('YYYY-MM-DD'),
          },
        );
      }
    }
  } else if (piecesScheduledCount === piecesCount && runsCount === runsCompleteCount) {
    iconName = !!orderDueDate && runsAfterOrderDueDate.length ? ICON_NAMES.CALENDAR_X : ICON_NAMES.CALENDAR_CHECKED;
    iconColor = !!orderDueDate && runsAfterOrderDueDate.length ? ICON_COLORS.RED : ICON_COLORS.GREEN;
    const tooltipMessageAfterDeadline = intl.formatMessage({
      id: 'orderProgress.runsDeliveredAfterDeadline',
      defaultMessage: 'Runs delivered after deadline',
    });
    const tooltipMessageOnTime = intl.formatMessage({
      id: 'orderProgress.runsDeliveredOnTime',
      defaultMessage: 'All runs delivered on time',
    });
    tooltipMessage = !!orderDueDate && runsAfterOrderDueDate.length ?
      tooltipMessageAfterDeadline : tooltipMessageOnTime;
  } else {
    iconName = ICON_NAMES.QUESTION;
    iconColor = ICON_COLORS.WHITE;
    tooltipMessage = '';
  }
  const iconStyle = {
    fontSize: '26px',
    marginLeft: '10px',
    color: iconColor,
  };
  return (
    <Tooltip id="order-progress" placement="bottom" trigger={trigger || <Fa style={iconStyle} name={iconName} />}>
      <span>{tooltipMessage}</span>
    </Tooltip>
  );
};

OrderProgressTooltip.defaultProps = {
  trigger: null,
  orderDueDate: null,
};

OrderProgressTooltip.propTypes = {
  runs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orderRunsEstimates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  piecesCount: PropTypes.number.isRequired,
  piecesScheduledCount: PropTypes.number.isRequired,
  orderDueDate: PropTypes.string,
  trigger: PropTypes.node,
};

export default OrderProgressTooltip;
