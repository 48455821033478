import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import { FormattedDateTime } from 'rapidfab/i18n';
import getQRAppUri from 'rapidfab/utils/getQRAppUri';
import QRSticker from 'rapidfab/components/qr/_wrapper';

const Container = ({ lotUri, materialName, container }) => {
  const containerShortUUID = getShortUUID(container.uri);
  const lotShortUUID = getShortUUID(lotUri);
  return (
    <QRSticker>
      <div>Material Container: {containerShortUUID}</div>
      <div>Material Type: {materialName}</div>

      <div className="qr">
        <a href={getQRAppUri(container.uri)} target="_blank" rel="noopener noreferrer">
          <QRCode value={getQRAppUri(container.uri)} renderAs="svg" />
        </a>
      </div>

      <div>Material Lot: {lotShortUUID}</div>
      <div>Created: <FormattedDateTime value={container.created} /></div>
    </QRSticker>
  );
};

Container.propTypes = {
  lotUri: PropTypes.string.isRequired,
  materialName: PropTypes.string.isRequired,
  container: PropTypes.shape({
    uuid: PropTypes.string,
    uri: PropTypes.string,
    created: PropTypes.string,
  }).isRequired,
};

export default Container;
