export const DATETIME_DEFAULT_FORMAT = 'MMMM DD YYYY, h:mm:ss a';

export const DESIGN_REVIEW_MATERIAL_NAME = 'Design Review Only';

export const ANALYTICS_REPORTS_URL = 'v1/analytic-report';

export const XEROX_LOGIN_ROUTE_PREFIX = 'xerox-elem';

export const XEROX_ENV = 'xerox';

export const STANLEY_X_ENV = 'stanley-x';

export const AUTHENTISE_ENV = 'authentise';

export const XEROX_HAWKING_BRANDING = 'hawking-branding';

export const HAWKING_CLASS_NAME = 'hawking';

export const AUTHENTISE_PDM_CLASS_NAME = 'authentise-pdm';

export const STANLEY_X_BRANDING = 'stanley-x-branding';

export const STANLEY_X_CLASS_NAME = 'stanley-x';

export const DANFOSS_DDW_ENV = 'danfoss-ddw';

export const DANFOSS_DDW_BRANDING = 'danfoss-ddw-branding';

export const DANFOSS_DDW_CLASS_NAME = 'danfoss-ddw';

export const XEROX_COMMON_DOMAIN = '.corp.xerox.com';

export const XEROX_BUSINESS_DOMAIN = '.business.xerox.com';

export const TOS_XEROX_URL = 'http://docs.elem.business.xerox.com/#page/ElemNavigator/terms.html#';

export const ZENDESK_MATERIAL_MANAGEMENT_URL = 'https://authentise.zendesk.com/hc/en-us/articles/360049516712-Step-by-Step-User-Guide-Material-Management';
