import React, { useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { ROUTES, PAGINATION_IGNORE_DEFAULT_LIMIT, API_RESOURCES, FEATURES } from 'rapidfab/constants';

import EditOrder from 'rapidfab/components/records/order/edit/EditOrder';
import Loading from 'rapidfab/components/Loading';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import _map from 'lodash/map';
import { loadLineItemQuotes } from 'rapidfab/dispatchers/lineItem';
import { useNavigate } from 'react-router-dom';
import OrderPackingSlipPrintContainer from './OrderPackingSlipPrintContainer';

const OrderContainer = () => {
  const routeUUID = useSelector(Selectors.getRouteUUID);
  const bureau = useSelector(Selectors.getBureauUri);
  const isRestrictedUser = useSelector(Selectors.isCurrentUserRestricted);
  const order = useSelector(state => Selectors.getUUIDResource(state, routeUUID));

  const isGuidelineEngineFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.GUIDELINES_ENGINE,
  ));

  const lineItems = useSelector(state => Selectors.getLineItemsForOrder(state, order));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onInitialize = () => {
    // Fetch order and related resources
    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(routeUUID)).then(() => {
      dispatch(Actions.Api.nautilus[API_RESOURCES.OAUTH_TOKEN_AUTODESK_FORGE].get(''));
    });

    // Fetch resource options for input selections
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER].list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.INFILL_STRATEGY].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUPPORT_STRATEGY].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list());

    if (isGuidelineEngineFeatureEnabled) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list());
    }
  };

  useEffect(() => onInitialize(routeUUID), [routeUUID]);

  useEffect(() => {
    if (order?.quote_required && lineItems?.length) {
      // Load all Line Item Quote details when `Order Quote Required` checkbox was set
      loadLineItemQuotes(dispatch, _map(lineItems, 'uri'));
    }
  }, [order?.quote_required, JSON.stringify(_map(lineItems, 'uri'))]);
  const loading = !order || !routeUUID;

  // For restricted user it's another endpoint and another form
  if (!loading && isRestrictedUser) {
    navigate(getRouteURI(ROUTES.REVIEW_RESTRICTED_ORDER, { uuid: routeUUID }, {}, true));
  }
  if (loading) return <Loading />;
  return (
    <div>
      <OrderPackingSlipPrintContainer orderUuid={order.uuid} />
      <div className="hide-on-print">
        <EditOrder />
      </div>
    </div>
  );
};
export default memo(OrderContainer);
