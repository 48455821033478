import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { API_RESOURCES, TRACEABILITY_REPORT_STATUS } from 'rapidfab/constants';
import Actions from 'rapidfab/actions';
import extractUuid from 'rapidfab/utils/extractUuid';
import * as Selectors from 'rapidfab/selectors';
import { ExportButton } from 'rapidfab/components/records/piece/TraceabilityReport';

class TraceabilityReportExportButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /**
       * loadingReport means that report object is created, but
       * not generated yet (in background process)
       */
      loadingReport: false,
      /**
       * isNewReportCreated means that report has
       * been created in current component lifecycle
       */
      isNewReportCreated: false,
    };

    this.onExport = this.onExport.bind(this);
  }

  componentDidUpdate() {
    const { loadingReport } = this.state;
    const { download, traceabilityReport } = this.props;

    if (
      loadingReport &&
      traceabilityReport &&
      traceabilityReport.status === TRACEABILITY_REPORT_STATUS.COMPLETE
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loadingReport: false,
        isNewReportCreated: true,
      });

      if (download) {
        window.location = download;
      }
    }
  }

  onExport() {
    /**
     * Generates request for new material batch traceability report object
     * and then performs get request to add this object to store
     */

    const { dispatch, batch } = this.props;

    this.setState({
      loadingReport: true,
    });

    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH_TRACEABILITY_REPORT].post({
      material_batch: batch.uri,
    })).then(response => {
      const { location } = response.headers;
      const uuid = extractUuid(location);
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH_TRACEABILITY_REPORT].get(uuid, true));
    });
  }

  render() {
    const { onExport } = this;
    const { download } = this.props;
    const { loadingReport, isNewReportCreated } = this.state;

    // TODO 'none' is magic string of ExportButton component.
    //  Rename it to more suitable on refactor
    const downloadContent = (isNewReportCreated && download) || 'none';

    return (
      <ExportButton
        asBlock
        download={downloadContent}
        loadingReport={loadingReport}
        onExport={() => onExport()}
      />
    );
  }
}

TraceabilityReportExportButton.defaultProps = {
  download: null,
  traceabilityReport: null,
};

TraceabilityReportExportButton.propTypes = {
  batch: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  download: PropTypes.string,
  traceabilityReport: PropTypes.shape({
    status: PropTypes.string,
  }),
};

const mapStateToProps = (state, ownProps) => {
  const batchUUID = extractUuid(ownProps.batch.uri);
  const batch = batchUUID && state.resources[batchUUID];

  const traceabilityReport = Selectors.getTraceabilityReportForBatch(state, batch);
  const download = (traceabilityReport && traceabilityReport.content);

  return {
    batch,
    download,
    traceabilityReport,
  };
};

export default connect(mapStateToProps)(TraceabilityReportExportButton);
