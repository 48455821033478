import React from 'react';
import PropTypes from 'prop-types';
import {
  IP_SENSITIVITY_MAP,
} from 'rapidfab/mappings';

import { FEATURES } from 'rapidfab/constants';
import Feature from 'rapidfab/components/Feature';
import DataRow from 'rapidfab/components/records/order/DataRow';
import { FormattedMessage } from 'rapidfab/i18n';
import getOrderFormOptions, { ORDER_FORM_TYPES } from 'rapidfab/utils/orderFormOptions';

const ReviewPreviewOrder = (
  {
    fields,
    locationsAvailable,
    isRestricted,
    isOrderBusinessSegmentFeatureEnabled,
    is3MOrderFieldsFeatureEnabled,
    isBoeingOrderFieldsFeatureEnabled,
  },
) => {
  const selectedLocation = fields.location && locationsAvailable[fields.location];

  const isNormalUser = !isRestricted;
  const isDanfossUser = isOrderBusinessSegmentFeatureEnabled;
  const is3MUser = is3MOrderFieldsFeatureEnabled;
  const isBoeingUser = isBoeingOrderFieldsFeatureEnabled;

  const options = getOrderFormOptions({
    isNormalUser,
    isDanfossUser,
    is3MUser,
    isBoeingUser,
  }, ORDER_FORM_TYPES.EDIT);

  return (
    <div>
      <DataRow id="orderName" defaultMessage="Order Name">
        {fields.name || '-'}
      </DataRow>

      {options.shippingName.show && (
        <Feature featureName={FEATURES.ORDER_BUSINESS_SEGMENT} isInverted>
          <DataRow id="field.shippingName" defaultMessage="Shipping Name">
            {fields.shipping.name || '-'}
          </DataRow>
        </Feature>
      )}

      {options.customerEmail.show && (
        <DataRow id="field.customer_email" defaultMessage="Customer Email">
          {fields.customer_email || '-'}
        </DataRow>
      )}

      {options.customerName.show && (
        <DataRow id={options.customerName.id} defaultMessage={options.customerName.defaultMessage}>
          {fields.customer_name || '-'}
        </DataRow>
      )}

      <Feature featureNames={FEATURES.ORDER_LOCATION}>
        <DataRow id="field.location" defaultMessage="Location">
          {selectedLocation ? selectedLocation.name : <FormattedMessage id="field.any" defaultMessage="Any" />}
        </DataRow>
      </Feature>

      {options.ipSensitivity.show && (
        <Feature featureName={FEATURES.IP_SENSITIVITY}>
          <DataRow id="field.ipSensitivity" defaultMessage="IP Sensitivity">
            {IP_SENSITIVITY_MAP[fields.ip_sensitivity] ? IP_SENSITIVITY_MAP[fields.ip_sensitivity].defaultMessage : '-'}
          </DataRow>
        </Feature>
      )}

      <Feature featureName={FEATURES.ORDER_DUE_DATE}>
        <DataRow id="field.dueDate" defaultMessage="Due Date">
          {fields.due_date || '-'}
        </DataRow>
      </Feature>

      {
        // Using isDanfossUser as an edge-case here instead of orderFormOptions.
        // No need to show this field here for other bureaus
        // mentioned in `show` property of orderFormOptions
        isDanfossUser && (
          <DataRow id="field.trackingNumber" defaultMessage="Shipping/Tracking #">
            { fields.shipping.tracking || '-' }
          </DataRow>
        )
      }

    </div>
  );
};

ReviewPreviewOrder.propTypes = {
  fields: PropTypes.shape({
    location: PropTypes.string.isRequired,
    shipping: PropTypes.shape({
      name: PropTypes.string.isRequired,
      tracking: PropTypes.string.isRequired,
    }),
    name: PropTypes.string.isRequired,
    customer_po: PropTypes.string.isRequired,
    quote_number: PropTypes.string.isRequired,
    customer_email: PropTypes.string.isRequired,
    customer_name: PropTypes.string.isRequired,
    ip_sensitivity: PropTypes.string.isRequired,
    order_type: PropTypes.string.isRequired,
    due_date: PropTypes.string.isRequired,
  }).isRequired,
  locationsAvailable: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isRestricted: PropTypes.bool.isRequired,
  isOrderBusinessSegmentFeatureEnabled: PropTypes.bool.isRequired,
  is3MOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  isBoeingOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
};

export default ReviewPreviewOrder;
