import React from 'react';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import extractUuid from 'rapidfab/utils/extractUuid';
import { MATERIAL_BATCH_ACTION_TYPES, ROUTES } from 'rapidfab/constants';
import PropTypes from 'prop-types';

const MaterialBatchMetadataColumn = ({ rowData }) => {
  const { action_type: actionType, metadata, notes } = rowData;
  let batchUri = '';

  if (metadata) {
    batchUri = metadata.resulting_batch || metadata.split_off_batch || null;
  }

  switch (actionType) {
    case (MATERIAL_BATCH_ACTION_TYPES.CREATE_INITIAL_BATCH):
    case (MATERIAL_BATCH_ACTION_TYPES.SPLIT_BATCH):
      return (
        <>
          <span>New Batch: </span>
          <a href={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(batchUri) })}>
            {getShortUUID(batchUri)}
          </a>
        </>
      );
    case (MATERIAL_BATCH_ACTION_TYPES.EDIT_BATCH_QUANTITY):
      return (
        <>
          <span>Notes: </span>{notes}
        </>
      );
    default: return '';
  }
};

MaterialBatchMetadataColumn.propTypes = {
  rowData: PropTypes.shape({
    action_type: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      resulting_batch: PropTypes.string.isRequired,
      split_off_batch: PropTypes.string.isRequired,
    }).isRequired,
    notes: PropTypes.string.isRequired,
  }).isRequired,
};

export default MaterialBatchMetadataColumn;

MaterialBatchMetadataColumn.propTypes = {
  rowData: PropTypes.shape({
    notes: PropTypes.string,
    action_type: PropTypes.string,
    metadata: PropTypes.shape({
      resulting_batch: PropTypes.shape({}),
      split_off_batch: PropTypes.shape({}),
    }),
  }).isRequired,
};
