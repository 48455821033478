import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import {
  ButtonToolbar,
  Dropdown,
  SplitButton,
} from 'react-bootstrap';

import CancelOrDeleteModal from 'rapidfab/components/CancelOrDeleteModal';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import Loading from 'rapidfab/components/Loading';

const SaveDropdownButton = ({
  cancellable,
  handleConfirm,
  handleOpen,
  onSubmit,
  modalType,
  resourceName,
  disabled,
  showDuplicate,
  showDelete,
}) => (
  <ButtonToolbar className={['clearfix', 'order-summary-btn']}>
    <div className="pull-right">
      <span>
        <SplitButton
          id="actions"
          type="submit"
          variant="success"
          size="sm"
          menuRole="menu"
          title={disabled ? <Loading /> : <SaveButtonTitle resourceName={resourceName} />}
          onClick={onSubmit}
          disabled={disabled}
        >
          {cancellable && (
            <Dropdown.Item eventKey="0" onClick={() => handleOpen('cancel')}>
              <Fa name="ban" />
              {' '}
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
            </Dropdown.Item>
          )}
          {showDelete && (
            <Dropdown.Item eventKey="1" onClick={() => handleOpen('delete')}>
              <Fa name="times" />
              {' '}
              <FormattedMessage id="button.delete" defaultMessage="Delete" />
            </Dropdown.Item>
          )}
          {showDuplicate && (
            <Dropdown.Item eventKey="2" onClick={() => handleOpen('duplicate')}>
              <Fa name="clone" />
              {' '}
              <FormattedMessage id="button.duplicate" defaultMessage="Duplicate" />
            </Dropdown.Item>
          )}
        </SplitButton>
      </span>
    </div>
    {modalType && (
      <CancelOrDeleteModal
        modalType={modalType}
        handleConfirm={handleConfirm}
        handleOpen={handleOpen}
      />
    )}
  </ButtonToolbar>
);

SaveDropdownButton.defaultProps = {
  onSubmit: null,
  resourceName: null,
  disabled: false,
};

SaveDropdownButton.propTypes = {
  cancellable: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  modalType: PropTypes.string.isRequired,
  resourceName: PropTypes.string,
  showDuplicate: PropTypes.bool.isRequired,
  showDelete: PropTypes.bool.isRequired,
};

class SaveDropdownButtonContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { modalType: '' };
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleConfirm() {
    const { modalType } = this.state;

    switch (modalType) {
      case 'cancel':
        return this.props.onCancel();

      case 'delete':
        return this.props.onDelete();

      case 'duplicate':
        return this.props.onDuplicate();

      default:
        return null;
    }
  }

  handleOpen(modalType) {
    this.setState({ modalType });
  }

  render() {
    return (
      <SaveDropdownButton
        {...this.props}
        {...this.state}
        cancellable={!!this.props.onCancel}
        handleConfirm={this.handleConfirm}
        handleOpen={this.handleOpen}
        showDuplicate={!!this.props.onDuplicate}
        showDelete={!!this.props.onDelete}
      />
    );
  }
}

SaveDropdownButtonContainer.defaultProps = {
  onCancel: null,
  onSubmit: null,
  onDelete: null,
  onDuplicate: null,
  resourceName: null,
  disabled: false,
};

SaveDropdownButtonContainer.propTypes = {
  onCancel: PropTypes.func,
  onDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onDuplicate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onSubmit: PropTypes.func,
  resourceName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SaveDropdownButtonContainer;
