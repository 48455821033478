import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import Permissions from 'rapidfab/permissions';
import {
  NavDropdown,
  Nav,
} from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import Flag from 'rapidfab/components/flag';
import { MyProfile } from 'rapidfab/components/navbar/Titles';
import CurrencyMenu from 'rapidfab/components/navbar/CurrencyMenu';
import UnitsMenu from 'rapidfab/components/navbar/UnitsMenu';
import _get from 'lodash/get';
import NonHawkingFeature from 'rapidfab/components/hawking/NonHawkingFeature';
import { connect } from 'react-redux';
import Actions from 'rapidfab/actions';
import ContactUsModal from './ContactUsModal';
import { API_RESOURCES } from '../../constants/resources';
import Alert from '../../utils/alert';

const LanguageFlagMap = {
  da: 'dk',
  de: 'de',
  'en-US': 'us',
  ja: 'jp',
  fr: 'fr',
};

const NavProfile = ({
  currentUser,
  onChangeLocale,
  onLogout,
  onImpersonateStop,
  locale,
  session,
  isServiceProviderRole,
  isHawkingUser,
  fetchSupportRequestPayload,
  setShowChangeImpersonationModal,
}) => {
  const flag = LanguageFlagMap[locale];

  const shouldShowImpersonateStop = _get(session, 'currentUser.impersonating');

  const shouldShowAdmin = Permissions.has(
    'nautilus',
    'administrate.group',
    session,
  );

  const shouldShowImpersonate = Permissions.has(
    'nautilus',
    'impersonation',
    session,
  );

  const shouldShowProfile = !isServiceProviderRole;
  const shouldShowLanguage = !isServiceProviderRole;
  const shouldShowCurrency = !isServiceProviderRole;
  const shouldShowUnits = !isServiceProviderRole;

  const documentationPage = session => {
    const XEROX_DOCUMENTATION_PAGE = 'http://docs.elem.business.xerox.com/';
    const AUTHENTISE_DOCUMENTATION_PAGE = 'https://authentise.zendesk.com/hc/en-us';

    if (isHawkingUser) return XEROX_DOCUMENTATION_PAGE;
    if (session.isStanleyXUser) return AUTHENTISE_DOCUMENTATION_PAGE;
    if (session.isDanfossUser) return AUTHENTISE_DOCUMENTATION_PAGE;

    return AUTHENTISE_DOCUMENTATION_PAGE;
  };

  const [showContactUsModal, setShowContactUsModal] = useState(false);

  return (
    <>
      <ContactUsModal
        currentUser={currentUser}
        show={showContactUsModal}
        onClose={() => {
          setShowContactUsModal(false);
        }}
        onSubmit={payload => {
          if (payload.support_request_payload.length !== 0) {
            return fetchSupportRequestPayload(payload.support_request_payload, () => setShowContactUsModal(false));
          }
          return Alert.error(<FormattedMessage
            id="toaster.error.supportReview.messageCannotBeEmpty"
            defaultMessage="Message cannot be empty."
          />);
        }}
      />
      <Nav className="flexed-pull-right">
        <NavDropdown
          // eventKey={1}
          title={(
            <>
              <MyProfile currentUser={currentUser} />
              <span className="nav-dropdown-caret" />
            </>

          )}
          id="uxNavProfile"
        >
          <NavDropdown.Item className="no-link-menu-item">
            {currentUser ? currentUser.name || currentUser.username : '...'}
          </NavDropdown.Item>
          <NavDropdown.Divider />
          {shouldShowProfile && (
            <NavDropdown.Item eventKey={1.1} href={getRouteURI(ROUTES.PROFILE)}>
              <Fa name="user" />{' '}
              <FormattedMessage id="myProfile" defaultMessage="My Profile" />
            </NavDropdown.Item>
          )}
          {shouldShowImpersonate && !shouldShowImpersonateStop && (
            <NavDropdown.Item
              eventKey={1.2}
              href={getRouteURI(ROUTES.ADMIN_IMPERSONATION)}
            >
              <div>
                <Fa name="user-secret" />{' '}
                <FormattedMessage
                  id="impersonate"
                  defaultMessage="Impersonate"
                />
              </div>
            </NavDropdown.Item>
          )}
          {shouldShowImpersonateStop && (
            <NavDropdown.Item eventKey={1.6} onClick={() => setShowChangeImpersonationModal(true)}>
              <div>
                <p>
                  <Fa name="arrow-circle-right" />{' '}
                  Change Impersonation
                </p>
              </div>
            </NavDropdown.Item>
          )}
          {shouldShowImpersonateStop && (
            <NavDropdown.Item eventKey={1.3} onClick={() => onImpersonateStop()}>
              <div>
                <Fa name="user-secret" />{' '}
                <FormattedMessage
                  id="endImpersonation"
                  defaultMessage="End Impersonation"
                />
              </div>
            </NavDropdown.Item>
          )}
          {shouldShowAdmin && (
            <NavDropdown.Item eventKey={1.4} href={getRouteURI(ROUTES.ADMIN)}>
              <div>
                <Fa name="users" />{' '}
                <FormattedMessage id="admin" defaultMessage="Administration" />
              </div>
            </NavDropdown.Item>
          )}
          <NavDropdown.Divider style={{ display: 'none' }} />
          <NavDropdown.Item eventKey={1.5} onClick={() => onLogout()}>
            <Fa name="sign-out" />{' '}
            <FormattedMessage id="logout" defaultMessage="Logout" />
          </NavDropdown.Item>
        </NavDropdown>
        <NonHawkingFeature>
          {shouldShowLanguage && (
            <NavDropdown
              // eventKey={2}
              title={<Flag type={flag} />}
              id="uxNavLocale"
              aria-label="Choose locale"
            >
              <NavDropdown.Item
                eventKey={2.1}
                onClick={() => onChangeLocale(locale, 'en-US')}
              >
                <Fa
                  name="check"
                  style={{ visibility: flag === 'us' ? null : 'hidden' }}
                />{' '}
                <Flag type="us" /> English
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={2.2}
                onClick={() => onChangeLocale(locale, 'de')}
              >
                <Fa
                  name="check"
                  style={{ visibility: flag === 'de' ? null : 'hidden' }}
                />{' '}
                <Flag type="de" /> German
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={2.3}
                onClick={() => onChangeLocale(locale, 'da')}
              >
                <Fa
                  name="check"
                  style={{ visibility: flag === 'dk' ? null : 'hidden' }}
                />{' '}
                <Flag type="dk" /> Danish
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={2.4}
                onClick={() => onChangeLocale(locale, 'ja')}
              >
                <Fa
                  name="check"
                  style={{ visibility: flag === 'jp' ? null : 'hidden' }}
                />{' '}
                <Flag type="jp" /> 日本語
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={2.5}
                onClick={() => onChangeLocale(locale, 'fr')}
              >
                <Fa
                  name="check"
                  style={{ visibility: flag === 'fr' ? null : 'hidden' }}
                />{' '}
                <Flag type="fr" /> French
              </NavDropdown.Item>
            </NavDropdown>
          )}
          {shouldShowCurrency && <CurrencyMenu />}
          {shouldShowUnits && <UnitsMenu />}
        </NonHawkingFeature>
        {/* NavItem component not used here because href is not working in react-bootstrap for NavItem */}
        {isHawkingUser ? (
          <li style={{ cursor: 'pointer' }} onClick={() => setShowContactUsModal(true)} role="presentation" className="ml15 mr30">
            {/* Redundant href below to omit the eslint error */}
            <a href={() => setShowContactUsModal(true)}>
              <Fa name="envelope-o" style={{ color: '#cfd2da' }} title="Help" />
            </a>
          </li>
        ) : null}
        <li role="presentation" className="mr15 ml15">
          <a
            href={documentationPage(session)}
            target="_blank"
            rel="noreferrer"
          >
            <Fa name="question-circle-o" title="Help" />
          </a>
        </li>
      </Nav>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  fetchSupportRequestPayload: (support_request_payload, close) => {
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.CONTACT_US].post(
        { support_request_payload },
      ),
    ).then(() => {
      Alert.success(
        <FormattedMessage
          id="toaster.supportReview"
          defaultMessage="Thanks! The Elem Support Team will review your issue and respond as quickly as possible."
        />);
      close();
    }).catch(() => {
      Alert.error(<FormattedMessage
        id="toaster.error.supportReview.messageNotSent"
        defaultMessage="Message couldn't be sent."
      />);
    });
  },
  setShowChangeImpersonationModal: value => {
    dispatch(Actions.ChangeImpersonationModal.setChangeImpersonationModalState(value));
  },
});

NavProfile.propTypes = {
  fetchSupportRequestPayload: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    // Name is empty for Service Provider users
    // https://app.clubhouse.io/authentise/story/28430
    name: PropTypes.string,
    username: PropTypes.string.isRequired,
  }).isRequired,
  locale: PropTypes.string.isRequired,
  onChangeLocale: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onImpersonateStop: PropTypes.func.isRequired,
  session: PropTypes.shape({
    isStanleyXUser: PropTypes.bool,
    isDanfossUser: PropTypes.bool,
  }).isRequired,
  isServiceProviderRole: PropTypes.bool,
  isHawkingUser: PropTypes.bool,
  setShowChangeImpersonationModal: PropTypes.func.isRequired,
};

NavProfile.defaultProps = {
  isServiceProviderRole: false,
  isHawkingUser: false,
};

export default connect(null, mapDispatchToProps)(NavProfile);
