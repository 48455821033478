import { useEffect, useState } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useIsPageReloading = () => {
  const [isPageReloading, setIsPageReloading] = useState(false);
  useEffect(() => {
    window.onbeforeunload = () => setIsPageReloading(true);
    return () => {
      window.onbeforeunload = null;
      setIsPageReloading(false);
    };
  }, []);
  return isPageReloading;
};
