import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import {
  API_RESOURCES,
  COMMENT_RELATED_TABLE_NAMES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  USER_ROLES,
} from 'rapidfab/constants';

import Comments from 'rapidfab/components/comments/Comments';
import { uniq } from 'lodash/array';
import { getCurrentUserRole } from 'rapidfab/selectors';

const CommentsContainer = ({ resourceUUID, resourceTableName, bgInverse }) => {
  const resource = useSelector(state =>
    Selectors.getUUIDResource(state, resourceUUID));
  const comments = useSelector(state =>
    Selectors.getCommentsForResource(state, resource));
  const usersByUsername = useSelector(Selectors.getUsersByUsername);
  const isLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.COMMENT].list.fetching ||
    state.ui.nautilus[API_RESOURCES.USERS].list.fetching);
  const isSubmitting = useSelector(state => state.ui.nautilus[API_RESOURCES.COMMENT].post.fetching);
  const usersFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.USERS].list.fetching);
  const [usersFetched, setUsersFetched] = useState(false);

  const currentUserRole = useSelector(getCurrentUserRole);
  const isManager = currentUserRole === USER_ROLES.MANAGER;

  const dispatch = useDispatch();

  // eslint-disable-next-line no-shadow
  const listComments = currentResourceUUID => Actions.Api.nautilus[API_RESOURCES.COMMENT].list({
    related_table_name: resourceTableName,
    related_uuid: currentResourceUUID,
  }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT });

  // eslint-disable-next-line no-shadow
  const onInitialize = currentResourceUUID => {
    dispatch(listComments(currentResourceUUID)).then(response => {
      const comments = response?.json?.resources || [];
      const usersByComments = uniq(comments.map(comment => comment.user)).filter(Boolean);
      if (usersByComments.length) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({
          uri: usersByComments,
        }));
      }
    });
  };

  const fetchAllUsers = React.useCallback(() => {
    if (!usersFetched && !usersFetching) {
      return dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list(
        (isManager ? { archived: false } : {}), { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      ))
        .then(response => {
          setUsersFetched(true);
          return response?.json?.resources;
        });
    }
    return usersByUsername;
  }, [usersByUsername, usersFetched, usersFetching]);

  const onSubmit = async payload => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.COMMENT].post({
      related_table_name: resourceTableName,
      related_uuid: resourceUUID,
      ...payload,
    }));
    dispatch(listComments(resourceUUID));
  };

  useEffect(() => {
    onInitialize(resourceUUID);
  }, [resourceUUID]);

  return (
    <Comments
      bgInverse={bgInverse}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      comments={comments}
      usersByUsername={usersByUsername}
      onSubmit={payload => onSubmit(payload)}
      fetchAllUsers={fetchAllUsers}
    />
  );
};
CommentsContainer.propTypes = {
  bgInverse: PropTypes.bool.isRequired,
  resourceUUID: PropTypes.string.isRequired,
  usersByUsername: PropTypes.shape({}).isRequired,
  // Eslint does not see usage of props inside of `mapDispatchToProps`
  // eslint-disable-next-line react/no-unused-prop-types
  resourceTableName: PropTypes.oneOf(
    Object.values(COMMENT_RELATED_TABLE_NAMES),
  ).isRequired,
};

export default CommentsContainer;
