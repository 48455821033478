import extractUuid from 'rapidfab/utils/extractUuid';

export default function getShortUUID(originalUUID) {
  let activeUUID = originalUUID;

  // It's possibly can be uri, let's try to extract uuid from URI
  const extractedUUID = extractUuid(originalUUID);
  if (extractedUUID !== null) {
    activeUUID = extractedUUID;
  }

  return activeUUID?.toString().slice(0, 8);
}
