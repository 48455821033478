import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Tooltip, OverlayTrigger, Modal, Button } from 'react-bootstrap';
import { FormattedMessage, FormattedMessageMappingOption } from 'rapidfab/i18n';
import { PRIORITIES_MAP } from 'rapidfab/mappings';
import Fa from 'react-fontawesome';
import FormRow from 'rapidfab/components/FormRow';

const OrderPrioritySelector = ({ value, onChange, disabled }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Priority Calculation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <FormattedMessage
              id="priority_selector.calculation_paragraph_1"
              defaultMessage="Authentise calculates priority according to the needs of our clients. At its simplest, we use the value entered into the Order Priority field to influence the production schedule of runs within your bureau."
            />
          </p>
          <p>
            <FormattedMessage
              id="priority_selector.calculation_paragraph_2"
              defaultMessage="When scheduling runs, we know the priority of each piece within that run (as specified in their respective Order's Priority field) and use it to calculate a Run Priority. We then use the Run Priority to determine where the newly created Run is inserted into the schedule, amidst the existing Runs. The standard configuration for Priority Scheduling calculates the priorities for each piece based off of the value entered into their Order's Priority field. We calculate a Run's Priority by taking the maximum priority of the pieces within that run. We can customize the algorithms used to establish Piece Priorities and Run Priorities to ensure our Priority Scheduling aligns with your bureau's specific needs. Please contact support@authentise.com to learn more about this configuration."
            />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setIsModalOpen(false)}>
            <FormattedMessage id="button.ok" defaultMessage="OK" />
          </Button>
        </Modal.Footer>
      </Modal>
      <FormRow id="field.priority" defaultMessage="Priority" isRequired>
        <div className="d-flex align-items-center">
          <FormControl
            value={value}
            onChange={onChange}
            className="d-flex flex-1"
            as="select"
            disabled={disabled}
          >
            {Object.keys(PRIORITIES_MAP).map(priority => (
              <FormattedMessageMappingOption
                key={priority}
                mapping={PRIORITIES_MAP}
                value={priority}
              />
            ))}
          </FormControl>
          <OverlayTrigger
            placement="bottom"
            overlay={(
              <Tooltip id="prioritySelectorTooltip">
                <FormattedMessage
                  id="priority_selector.tooltip"
                  defaultMessage="The order priority will be applied for all objects (Assemblies, Line Items, and Pieces) produced as a part of the order."
                />
              </Tooltip>
            )}
          >
            <Fa name="question-circle" className="d-flex ml30 mr30" />
          </OverlayTrigger>
          <Button size="sm" variant="default" onClick={() => setIsModalOpen(true)}>
            <Fa name="info" className="spacer-right" />
            How we calculate priority
          </Button>
        </div>
      </FormRow>
    </>
  );
};

OrderPrioritySelector.defaultProps = {
  disabled: false,
};

OrderPrioritySelector.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default OrderPrioritySelector;
